import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize(serialized) {
    return serialized
      ? JSON.parse(serialized)
      : serialized;
  },

  serialize(deserialized) {
    return deserialized
      ? JSON.stringify(deserialized)
      : null;
  },
});
