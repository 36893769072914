import FilesLoader from './files-loader';

export default FilesLoader.extend({
  layoutName: 'components/files-loader',

  files: [],

  fileUrl: '',

  fileName: '',

  fileSize: 0,

  multipleFiles: false,

  init(...args) {
    this._super(...args);
    this.set('files', []);
  },

  addFile(responseFile, inputFile) {
    const file = {
      name: inputFile.name,
      url: responseFile.file_url,
      size: inputFile.size,
    };
    this._super(responseFile, inputFile);

    this.set('fileUrl', file.url);
    this.set('fileName', file.name);
    this.set('fileSize', file.size);
  },
});
