import { Factory } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  cp() {
    return faker.random.number();
  },

  createdAt() {
    return new Date();
  },

  email() {
    return faker.internet.email();
  },

  eula() {
    return true;
  },

  name() {
    return faker.name.firstName();
  },
});
