import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class extends Route {
  @service can

  @service pageHeader

  beforeModel() {
    if (!this.can.can('create staff-member')) {
      this.transitionTo('index');
    }

    set(
      this,
      'pageHeader.title',
      i18n.t('title.header.configuration.staff-members'),
    );
  }
}
