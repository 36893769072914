import keepOnlyChanged from 'ember-data-change-tracker/mixins/keep-only-changed';

import Serializer from './application';

export default class extends Serializer.extend(keepOnlyChanged, {}) {
  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);
    if (
      json.data.attributes
      && (!json.data.attributes.pass
      || !json.data.attributes['pass-confirmation'])
    ) {
      delete json.data.attributes.pass;
      delete json.data.attributes['pass-confirmation'];
    }
    return json;
  }
}
