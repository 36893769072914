/* eslint no-bitwise: 0 */
import fileExtensions from 'neuro-frontend/enums/file-extensions';

export default function fileExtension() {
  return fileExtensions;
}

export function getExtensionLabel(extension) {
  const ret = fileExtension().find((ext) => ext.value === extension);

  if (!ret) return '';
  return ret.label;
}

export function isValidExtension(extension) {
  return fileExtension().some((ext) => ext.value === extension);
}

export function getFileExtension(url) {
  if (!url || 'string' !== typeof url) {
    return '';
  }

  return url.slice((~-url.lastIndexOf('.') >>> 0) + 2).toLowerCase();
}
// @DEPRECATED
export function getExtensionUrl(url) {
  return getFileExtension(url);
}

export function hasValidExtension(url) {
  return isValidExtension(getExtensionUrl(url));
}

export function isImage(url) {
  return fileExtensions.filterBy('type', 'image')
    .map((file) => file.value)
    .some((ext) => getExtensionUrl(url) === ext);
}
// @DEPRECATED
export function isImageExtension(url) {
  return isImage(url);
}

export function isDocExtension(url) {
  return ['doc', 'docx', 'odt'].includes(getExtensionUrl(url));
}

export function isExcelExtension(url) {
  return ['xlsx', 'xls', 'ods'].includes(getExtensionUrl(url));
}

export function isPdfExtension(url) {
  return 'pdf' === getExtensionUrl(url);
}

export function isPowerpointExtension(url) {
  return ['pptx', 'ppt', 'odp'].includes(getExtensionUrl(url));
}
