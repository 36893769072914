import Model from 'ember-data/model';
import { attr, hasMany, belongsTo } from '@ember-data/model';
import { get, computed } from '@ember/object';

import { inject as service } from '@ember/service';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class extends Model.extend({
  languages: fragmentArray('categoryLanguage'),
}) {
  @service auth

  @attr('number') level

  @attr('trimmed-string') name

  @attr('number') priority

  @hasMany('center') centers

  @hasMany('commerce') commerces

  @belongsTo('category', { inverse: null }) parent

  @belongsTo('category', { inverse: null }) root

  @computed('defaultLanguage', 'parent')
  get fullRoute() {
    const name = get(this, 'defaultLanguage.name');
    let fullRoute = name;
    let parent = get(this, 'parent');

    while (get(parent, 'id')) {
      fullRoute = `${get(parent, 'name')} > ${fullRoute}`;
      parent = get(parent, 'parent');
    }
    return fullRoute;
  }

  @computed('languages', 'auth.center.defaultLanguage')
  get defaultLanguage() {
    const {
      auth: {
        center: {
          defaultLanguage: centerLanguage,
        },
      },
      languages,
      name,
    } = this;
    let defaultLanguage;
    if (languages && languages.length) {
      defaultLanguage = languages.findBy('code', navigator.language.substring(0, 2))
        || languages.findBy('code', centerLanguage.code);
    }
    return defaultLanguage || { name }; // return only attributes available to be translated
  }
}
