export default Object.freeze([
  {
    label: i18n.t('center'),
    value: 'center',
  },
  {
    label: i18n.t('store'),
    value: 'commerce',
  },
]);
