import { computed, get } from '@ember/object';

import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('string') name

  // Relationships

  @belongsTo('lineitem') lineitem

  @hasMany('supplier', { inverse: 'services' }) suppliers

  @computed()
  get validations() {
    return {
      name: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('name') }),
        },
      },

      lineitem: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('lineitem') }),
        },
      },
    };
  }
}
