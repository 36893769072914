export default Object.freeze([
  {
    label: i18n.t('cms.proposal.draft'),
    value: 'draft',
    color: '#8c9eff',
  },
  {
    label: i18n.t('cms.proposal.pending'),
    value: 'pending',
    color: '#fbc02d ',
  },
  {
    label: i18n.t('cms.proposal.scheduled'),
    value: 'scheduled',
    color: '#2477b5',
  },
  {
    label: i18n.t('cms.proposal.active'),
    value: 'active',
    color: '#4db6ac',
  },
  {
    label: i18n.t('cms.proposal.expired'),
    value: 'expired',
    color: '#E57373',
  },
]);
