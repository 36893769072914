import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

import Serializer from './application';

export default class ProposalSerializer extends Serializer {
  @service auth

  intlCategories = [
    'calendar',
    'dossiers',
    'generalTerms',
    'paymentPlan',
    'qualitySpecifications',
  ]

  @alias('auth.center.defaultLanguage.code') languageCode

  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);

    if (json.data.attributes?.languages?.length) {
      const language = json.data.attributes.languages.findBy('code', this.languageCode);

      json.data.attributes.notes = language?.notes;

      json.data.attributes.files = [
        ...json.data.attributes.files.filter((f) => !this.intlCategories.includes(f.category)),
        ...language
          ? language.files.filter((f) => this.intlCategories.includes(f.category))
          : [],
      ];
    }

    return json;
  }
}
