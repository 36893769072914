import FilterOperation from '../filter-operation';

export default class FilterBoolean extends FilterOperation {
  baseValues = []

  options = [
    {
      label: i18n.t('yes'),
      value: '1',
    },
    {
      label: i18n.t('no'),
      value: '0',
    },
  ]
}
