import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';

@classNames('dashboard-crm__overview')
export default class extends Component {
  @computed('activeUsers.day', 'registeredUsers.day', 'headers')
  get activeUsersChart() {
    const {
      activeUsers: {
        day: activeUsers,
      },
      headers,
      registeredUsers: {
        day: registeredUsers,
      },
    } = this;

    if (!activeUsers.length || !registeredUsers.length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.active-users'), ...this.parseData(activeUsers, headers, 'active_users')],
      [i18n.t('dashboards.registered-users'), ...this.parseData(registeredUsers, headers, 'new_registered_users')],
    ];
  }

  @computed('appUsers.month')
  get appTotalUsers() {
    return {
      value: this.getTotal(this.appUsers.month, this.selectedMonth, 'count'),
      lastMonth: this.getTotal(this.appUsers.month, this.prevMonth, 'count'),
      prevYearMonth: this.getTotal(this.appUsers.month, this.prevYearMonth, 'count'),
    };
  }

  @computed('otherUsers.month')
  get otherTotalUsers() {
    return {
      value: this.getTotal(this.otherUsers.month, this.selectedMonth, 'count'),
      lastMonth: this.getTotal(this.otherUsers.month, this.prevMonth, 'count'),
      prevYearMonth: this.getTotal(this.otherUsers.month, this.prevYearMonth, 'count'),
    };
  }

  @computed('playCenterUsers.month')
  get playCenterTotalUsers() {
    return {
      value: this.getTotal(this.playCenterUsers.month, this.selectedMonth, 'count'),
      lastMonth: this.getTotal(this.playCenterUsers.month, this.prevMonth, 'count'),
      prevYearMonth: this.getTotal(this.playCenterUsers.month, this.prevYearMonth, 'count'),
    };
  }

  @computed('registeredUsers.month')
  get registeredTotalUsers() {
    return {
      value: this.getTotal(this.registeredUsers.month, this.selectedMonth, 'new_registered_users'),
      lastMonth: this.getTotal(this.registeredUsers.month, this.prevMonth, 'new_registered_users'),
      prevYearMonth: this.getTotal(this.registeredUsers.month, this.prevYearMonth, 'new_registered_users'),
    };
  }

  @computed('socialUsers.month')
  get socialTotalUsers() {
    return {
      value: this.getTotal(this.socialUsers.month, this.selectedMonth, 'count'),
      lastMonth: this.getTotal(this.socialUsers.month, this.prevMonth, 'count'),
      prevYearMonth: this.getTotal(this.socialUsers.month, this.prevYearMonth, 'count'),
    };
  }

  @computed('webUsers.month')
  get webTotalUsers() {
    return {
      value: this.getTotal(this.webUsers.month, this.selectedMonth, 'count'),
      lastMonth: this.getTotal(this.webUsers.month, this.prevMonth, 'count'),
      prevYearMonth: this.getTotal(this.webUsers.month, this.prevYearMonth, 'count'),
    };
  }

  getTotal(data, month, attr) {
    const monthData = data.find((elem) => (month.startDate === (elem.month || elem.date)));

    return !monthData
      ? '0'
      : monthData[attr];
  }

  parseData(data, tick, attr) {
    return tick.map((day) => data
      .filter((elem) => day === (elem.day || elem.date))
      .reduce((sum, elem) => ('object' !== typeof elem[attr] ? sum + elem[attr] : elem[attr]), 0));
  }
}
