import { helper } from '@ember/component/helper';

// Eternal love to StackOverflow.
// source:
// http://stackoverflow.com/questions/34189233/ember-2-truncate-text-and-add-ellipses
export function truncate(params, hash) {
  const value = params[0];
  const len = hash.limit;
  let out = '';

  if (value !== undefined) {
    out = value.substr(0, len);

    if (value.length > len) {
      out += '...';
    }
  } else {
    out = '';
  }

  return out;
}

export default helper(truncate);
