/* global Inputmask */
// @DEPRECATED use `nf-input-currency` instead
import CurrencyInput from 'ember-inputmask/components/currency-input';
import { computed } from '@ember/object';

Inputmask.extendAliases({
  EUR: {
    alias: 'currency',
    prefix: '',
    suffix: ' €',
  },
  USD: {
    alias: 'currency',
    prefix: '$ ',
    suffix: '',
  },
  JPY: {
    alias: 'currency',
    prefix: '¥ ',
    suffix: '',
  },
});

export default class extends CurrencyInput {
  @computed('inputMask')
  get mask() {
    const {
      inputMask,
    } = this;
    return inputMask || 'EUR';
  }
}
