import Cell from 'ember-light-table/components/cells/base';
import { set, action } from '@ember/object';

import { alias } from '@ember/object/computed';

export default class extends Cell {
  @alias('column.disabled') disabled;

  @action
  changeValue() {
    set(this.row.content, this.column.valuePath, this.value);
  }
}
