import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  center: association(),

  code: faker.random.word,

  endDate: faker.random.date,

  enabled: true,

  name() {
    return faker.random.word();
  },

  startDate: faker.random.date,
});
