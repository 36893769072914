export default {
    "Channel": "Canal",
    "Image": "Imagem",
    "KYC": "CSC",
    "Update": "Atualizar",
    "User type": "Tipo de utilizador",
    "accept": "Aceitar",
    "access_data": "Aceder a dados",
    "access_date": "Aceder \u00e0 data",
    "access_head": "Respons\u00e1vel pelo acesso",
    "achievement": "Realiza\u00e7\u00e3o",
    "action.check_as_done": "Marcar a\u00e7\u00e3o como conclu\u00edda",
    "actions.action_type": "Tipo de a\u00e7\u00e3o",
    "actions.add": "Adicionar a\u00e7\u00e3o",
    "actions.call": "Ligar",
    "actions.date": "Data",
    "actions.datetime": "Data",
    "actions.edit": "Editar a\u00e7\u00e3o",
    "actions.email": "E-mail",
    "actions.form": "Formul\u00e1rio",
    "actions.new": "Nova a\u00e7\u00e3o",
    "actions.notes": "Notas",
    "actions.rejection": "Rejei\u00e7\u00e3o",
    "actions.rejection_title": "Oferta rejeitada",
    "actions.reservation": "Reserva",
    "actions.reservation_title": "Propriedade reservada",
    "actions.select_action_type": "Selecionar tipo de a\u00e7\u00e3o",
    "actions.sent_marketing_info": "Informa\u00e7\u00f5es de marketing enviadas",
    "actions.sent_marketing_info_title": "Informa\u00e7\u00f5es enviadas",
    "actions.signed": "Assinado",
    "actions.signed_title": "Propriedade assinada",
    "actions.staff": "Equipa",
    "actions.title": "Cargo",
    "actions.visit": "Visita",
    "active.users": "Utilizadores ativos",
    "add": "Adicionar",
    "add-file": "Adicionar ficheiro",
    "add-files": "Adicionar um ou mais ficheiros",
    "address": "Endere\u00e7o",
    "admin": "Administrador",
    "advanced_search": "Pesquisa avan\u00e7ada",
    "age": "Idade",
    "alert-type": "Tipo de alerta",
    "all": "todos",
    "amount": "Montante",
    "analytics.no-dashboard": "N\u00e3o existem pain\u00e9is dispon\u00edveis",
    "and": "e",
    "answer": "Resposta",
    "any-of": "qualquer de",
    "api-error": "Ocorreu um erro que n\u00e3o \u00e9 da sua responsabilidade. Contacte o apoio e transmita as seguintes informa\u00e7\u00f5es:",
    "app-image": "Imagem da aplica\u00e7\u00e3o",
    "app-notifications": "Notifica\u00e7\u00f5es da aplica\u00e7\u00e3o",
    "apply_filters": "Aplicar filtros",
    "april": "abril",
    "april-short": "abr",
    "are_you_sure": "Tem a certeza?",
    "attach_files": "Anexar ficheiros",
    "august": "agosto",
    "august-short": "ago",
    "average": "M\u00e9dia",
    "average.visit.time": "Tempo de perman\u00eancia",
    "back": "Voltar",
    "bank-account-number": "IBAN",
    "bdate": "Anivers\u00e1rio",
    "between": "entre",
    "bime": "BIME",
    "bime_dashboard.not_found": "Painel Bime n\u00e3o encontrado",
    "bime_dashboard.type_find": "Digite para procurar pain\u00e9is Bime",
    "bonus-request": "Pedido b\u00f3nus",
    "bonus-shop.created-at": "Data",
    "bonusRequest.bonus-percentage": "Pedido desconto renda (%)",
    "bonusRequest.bonus-period": "Dura\u00e7\u00e3o do desconto de renda (meses)",
    "bonusRequest.bonus-shop": "Marca",
    "bonusRequest.confirm-bonus-request": "Confirmo que o pedido de b\u00f3nus \u00e9 v\u00e1lido.",
    "bonusRequest.costs-data": "Informa\u00e7\u00f5es de custos",
    "bonusRequest.depreciation": "Desvaloriza\u00e7\u00e3o",
    "bonusRequest.edit": "Editar",
    "bonusRequest.employee-cost": "Custo com empregados por ano",
    "bonusRequest.employee-hours": "Horas de empregados",
    "bonusRequest.employee-hours-year": "Horas de empregados por ano",
    "bonusRequest.exercise": "Per\u00edodo contabil\u00edstico do exerc\u00edcio",
    "bonusRequest.franchisee": "Franqueado",
    "bonusRequest.franchisee-expenses": "Despesas do franqueado",
    "bonusRequest.month": "M\u00eas",
    "bonusRequest.month-label": "Per\u00edodo entre janeiro e...",
    "bonusRequest.new": "Novo",
    "bonusRequest.other-expenses": "Outras despesas",
    "bonusRequest.sale-cost": "Custo de venda",
    "bonusRequest.sales": "Vendas",
    "bonusRequest.sales-tooltip": "Vendas do exerc\u00edcio (p.ex., 2019)",
    "bonusRequest.select-exercise": "Selecionar ano",
    "bonusRequest.select-month": "Selecionar m\u00eas",
    "bonusRequest.shops": "Marca",
    "bonusRequest.shops-search": "Procurar uma marca",
    "bonusRequest.supplies": "Abastecimentos",
    "bonusRequest.surface": "\u00c1rea(m2)",
    "bonusRequests.exercise": "Per\u00edodo contabil\u00edstico do exerc\u00edcio",
    "bookable": "Reserv\u00e1vel",
    "booking-contract": "Contrato de reserva",
    "booking-price": "Pre\u00e7o de reserva",
    "both": "Ambos",
    "boy": "Rapaz",
    "breadcrumbs.main": "Principal",
    "building-state": "Estado do edif\u00edcio",
    "built-surface": "\u00c1rea constru\u00edda",
    "bus": "Transporte p\u00fablico",
    "cadastral-reference": "Refer\u00eancia cadastral",
    "calendar": "Calend\u00e1rio",
    "calibrator": "Calibrador",
    "call-to-action": "Apelo \u00e0 a\u00e7\u00e3o",
    "campaign": "Campanha",
    "campaign-action": "A\u00e7\u00e3o",
    "campaign-action.new": "Nova a\u00e7\u00e3o de campanha",
    "campaign-goal.kpi": "KPI",
    "campaign-goal.new": "Novo objetivo de campanha",
    "campaign-goal.value": "Valor",
    "campaign-report.all-shops": "Todas as lojas",
    "campaign-report.campaign-actions": "A\u00e7\u00f5es de campanha",
    "campaign-report.campaign-cost": "Custo de campanha",
    "campaign-report.campaign-goals": "Objetivos de campanha",
    "campaign-report.campaign-kpi": "KPI de campanha",
    "campaign-report.campaign-type": "Tipo de campanha",
    "campaign-report.description": "Descri\u00e7\u00e3o",
    "campaign-report.estimated-cost": "Custo estimado",
    "campaign-report.final-cost": "Custo final",
    "campaign-report.final-cost-in-estimated": "Dentro do or\u00e7amento",
    "campaign-report.final-cost-over-estimated": "Acima do or\u00e7amento",
    "campaign-report.more-shops": "+ {{shops}} lojas",
    "campaign-report.notes": "Notas",
    "campaign-report.rating": "Avalia\u00e7\u00e3o de campanha",
    "campaign-report.reference-period": "Per\u00edodo de refer\u00eancia",
    "campaign-report.select-visible-kpis": "Selecionar KPIs vis\u00edveis",
    "campaign-report.shops": "Lojas",
    "campaign-report.suppliers-rating": "Avalia\u00e7\u00e3o de fornecedores",
    "campaign-rule.label": "Etiqueta",
    "campaign-rule.new": "Nova regra de campanha",
    "campaign-rule.ticket-amount": "Quantidade de bilhetes",
    "campaign.close": "Fechar campanha",
    "campaign.date-range": "Intervalo de datas",
    "campaign.edit": "Editar campanha",
    "campaign.filters": "Filtros",
    "campaign.goal": "Objetivo",
    "campaign.new": "Nova campanha",
    "campaign.reference": "Refer\u00eancia",
    "campaign.reference-type": "Tipo de refer\u00eancia",
    "campaign.segment.list": "Lista de segmentos",
    "campaign.segments": "Segmentos",
    "campaign.status.active": "Ativas",
    "campaign.status.closed": "Fechadas",
    "campaign.status.finished": "Conclu\u00eddas",
    "campaign.status.planned": "Planeadas",
    "campaign_id": "Campanha",
    "campaigns": "Campanhas",
    "campaigns.new": "Nova campanha",
    "campaigntype": "Tipo de campanha",
    "campaigntype.edit": "Editar tipo de campanha",
    "campaigntype.new": "Novo tipo de campanha",
    "cancel": "Cancelar",
    "capacity": "Capacidade",
    "cars": "Autom\u00f3veis",
    "categories": "Categorias",
    "category": "Categoria",
    "cbre-contact": "Contrato CBRE",
    "center": "Centro",
    "center-id": "ID centro",
    "center.not_found": "Centros n\u00e3o encontrados",
    "center.select_one": "Selecione um centro",
    "centers": "Centros",
    "centers-required": "O nome do centro \u00e9 obrigat\u00f3rio",
    "change-password.confirm-password": "Confirme a nova palavra-passe",
    "change-password.new-password": "Nova palavra-passe",
    "change-password.old-password": "Palavra-passe atual",
    "change-password.title": "Alterar palavra-passe",
    "channel-required": "O canal \u00e9 obrigat\u00f3rio",
    "channel_id": "Canal",
    "channels": "Canais",
    "channels.app": "Aplica\u00e7\u00e3o",
    "channels.other": "Outro",
    "channels.play-center": "Centro de jogos",
    "channels.social-wifi": "Wi-fi social",
    "channels.web": "Web",
    "check-in": "Entrada",
    "child": "Crian\u00e7a",
    "child.add-user-dni": "Ir para o perfil de utilizador",
    "child.bdate": "Data de nascimento",
    "child.check-out": "Sa\u00edda",
    "child.checkin-success-message": "Registe devidamente a crian\u00e7a",
    "child.checking-out": "A sair",
    "child.checkn": "Entrar",
    "child.checkout-success-message": "Sa\u00edda da crian\u00e7a confirmada",
    "child.edit": "Editar",
    "child.last-name": "Apelido",
    "child.name": "Nome",
    "child.new": "Nome",
    "child.not-dni-error-message": "N\u00e3o \u00e9 permitido utilizar o centro de jogos sem introduzir um n\u00famero de identifica\u00e7\u00e3o fiscal. V\u00e1 ao seu perfil de utilizador para inseri-lo",
    "child.notes": "Notas",
    "child.register-entry": "Entrada no registo",
    "child.relationship": "Relacionamento",
    "child.select-relationship": "Selecionar relacionamento",
    "child.select-sex": "Selecionar sexo",
    "child.sex": "Sexo",
    "children": "Filhos",
    "children-count": "{{count}} filho",
    "children-count_plural": "{{count}} filhos",
    "children-number": "N\u00famero de filhos",
    "children_age": "Idade dos filhos",
    "children_number": "N\u00famero de filhos",
    "cif": "CIF",
    "cinemas": "Cinemas",
    "city": "Cidade",
    "clear": "Limpar",
    "clear_filters": "Limpar",
    "close": "Fechar",
    "close-month.button.confirm": "Confirmar fecho de m\u00eas",
    "close-month.button.reject": "Rejeitar fecho de m\u00eas",
    "close-month.message.pending-request": "Existe um pedido de fecho de m\u00eas pendente para este m\u00eas",
    "close_month": "Fechar m\u00eas",
    "closed": "Fechado",
    "closed_month_pending": "Pendente",
    "cms.commerce-access-information.sent-error": "Ocorreu um erro ao tentar enviar as informa\u00e7\u00f5es solicitadas; tente novamente mais tarde",
    "cms.commerce-access-information.sent-success": "As informa\u00e7\u00f5es de acesso solicitadas foram devidamente enviadas para o seu e-mail; poder\u00e1 demorar alguns minutos at\u00e9 chegar \u00e0 sua caixa de entrada",
    "cms.commerce-stay-alert.correction": "Corre\u00e7\u00e3o",
    "cms.commerce-stay-alert.stay_full": "Continua cheio",
    "cms.commerce-stay-alert.stays-full": "Continua cheio",
    "cms.commerce.disabled-sales": "Vendas desativadas",
    "cms.commerce.extra-attributes": "Atributos extra",
    "cms.commerce.general-data": "Dados gen\u00e9ricos",
    "cms.commerce.hide-commerce": "Ocultar loja",
    "cms.commerce.physical-store": "Loja f\u00edsica",
    "cms.commerces.qr": "QR de acesso",
    "cms.commerces.qr-error": "Ocorreu um erro ao tentar transferir as informa\u00e7\u00f5es solicitadas; tente novamente mais tarde",
    "cms.proposal.active": "Ativa",
    "cms.proposal.draft": "Vers\u00e3o provis\u00f3ria",
    "cms.proposal.expired": "Expirada",
    "cms.proposal.extra-attributes": "Atributos extra",
    "cms.proposal.general-data": "Dados gerais",
    "cms.proposal.pending": "Pendente",
    "cms.proposal.scheduled": "Agendado",
    "cms.proposals.expire-error": "Erro ao caducar a proposta",
    "cms.proposals.expire-success": "A proposta foi devidamente caducada",
    "cms.proposals.proposal-duplicated": "A proposta foi devidamente duplicada",
    "cms.proposals.proposal-duplicated-error": "Erro ao duplicar proposta",
    "cms.proposals.publish-error": "Erro ao publicar proposta",
    "cms.proposals.publish-success": "A proposta foi devidamente publicada",
    "cms.proposals.reject-publication-error": "Erro ao rejeitar a publica\u00e7\u00e3o da proposta",
    "cms.proposals.reject-publication-success": "A publica\u00e7\u00e3o da proposta foi devidamente rejeitada",
    "cms.proposals.request-error": "Erro ao solicitar a publica\u00e7\u00e3o da proposta",
    "cms.proposals.request-success": "Foi solicitada a devida publica\u00e7\u00e3o da proposta",
    "cms.proposals.unpublish-error": "Erro ao cancelar a publica\u00e7\u00e3o da proposta",
    "cms.proposals.unpublish-success": "A publica\u00e7\u00e3o da proposta foi devidamente cancelada",
    "cms.push-notification.general-data": "Dados gerais",
    "cms.push-notification.want-publish": "Pretende publicar a notifica\u00e7\u00e3o push?",
    "comments": "Coment\u00e1rios",
    "commerce": "Loja",
    "commerce-stay-alert": "Estabelecimento comercial em alerta",
    "commerce-stay-alert.alert-date": "Data de alerta",
    "commerce-stay-alert.alert-type": "Tipo de alerta",
    "commerce-stay-alert.filters": "Filtros",
    "commerce.capacity-data": "Dados de capacidade",
    "commerce.categories": "Categorias",
    "commerce.edit": "Editar loja",
    "commerce.extra.address": "Atributos adicionais de endere\u00e7o",
    "commerce.extra.image": "Atributos extra da imagem",
    "commerce.extra.text": "Atributos adicionais de texto",
    "commerce.extra.url": "Atributos adicionais de URL",
    "commerce.general-data": "Dados gerais",
    "commerce.html": "Atributos adicionais de HTML",
    "commerce.new": "Nova loja",
    "commerce.personalized-target": "Objetivo personalizado",
    "commerce.store-data": "Dados da loja",
    "commerce.whatsapp-message": "Mensagem WhatsApp",
    "commerce.whatsapp-phone": "Telefone WhatsApp",
    "commerce.whatsapp-phone-format": "Um n\u00famero de telefone destinado ao WhatsApp tem de ter o indicativo da zona antes do n\u00famero, p.ex.: +346xxxxxxxx",
    "commerce.yardi-code": "C\u00f3digo Yardi",
    "commerces": "Lojas",
    "commerces.new": "Novas lojas",
    "commercialization": "Comercializa\u00e7\u00e3o",
    "commercialization-start": "In\u00edcio da comercializa\u00e7\u00e3o",
    "common-zones-surface": "\u00c1rea zonas comuns",
    "communication.label.to_roles": "Para",
    "communication.message": "Mensagem",
    "communication.new": "Nova comunica\u00e7\u00e3o",
    "communication.reject-close-month.subject": "Rejeitado o fecho de {{month}} de {{shopName}}",
    "communication.roles.commerce": "Loja",
    "communication.roles.management": "Gest\u00e3o",
    "communication.roles.services": "Servi\u00e7os",
    "communication.state.accepted": "Aceites",
    "communication.state.pending": "Pendentes",
    "communication.state.rejected": "Rejeitado\/a",
    "communication.state.solved": "Resolvidos",
    "communication.subject": "Assunto",
    "communication.subtype": "Subtipo",
    "communication.to_commerces": "Para lojas",
    "communication.type": "Tipo",
    "communication.type.access": "Acesso",
    "communication.type.issues": "Quest\u00f5es",
    "communication.type.marketing": "Marketing",
    "communication.type.news": "Not\u00edcias",
    "communication.type.tabs.inbox": "Caixa de entrada",
    "communication.type.tabs.sent": "Enviado",
    "communications.creator": "Criador",
    "communications.datetime": "Data\/hora",
    "communications.state": "Estado",
    "communications.subject": "Assunto",
    "communications.type": "Tipo",
    "community-expenses": "Despesas comunit\u00e1rias",
    "companion.add": "Adicionar acompanhante",
    "companion.vat_number": "N\u00famero de identifica\u00e7\u00e3o",
    "companion_name": "Nome do acompanhante",
    "companions": "Acompanhantes",
    "company": "Empresa",
    "concepts.airplane_tickets": "Bilhetes de avi\u00e3o",
    "concepts.bar": "Bar",
    "concepts.cancellations": "Cancelamentos",
    "concepts.cinema_tickets": "Bilhetes de cinema",
    "concepts.coffee": "Caf\u00e9",
    "concepts.drones": "Drones",
    "concepts.equipment": "Maquinaria (equipamento)",
    "concepts.fees_delivery": "Comiss\u00f5es de entrega",
    "concepts.free_devices": "Dispositivos livres",
    "concepts.game": "Jogo",
    "concepts.general": "Geral",
    "concepts.general_variable": "Renda vari\u00e1vel de mercadorias gen\u00e9ricas",
    "concepts.gm1": "Mercadorias gen\u00e9ricas 1",
    "concepts.gm2": "Mercadorias gen\u00e9ricas 2",
    "concepts.magazines": "Jornais e revistas",
    "concepts.online_sales": "Vendas on-line",
    "concepts.phonecard": "Cart\u00e3o telef\u00f3nico",
    "concepts.prescription": "Prescri\u00e7\u00e3o",
    "concepts.pub": "Bar",
    "concepts.radio-modelism": "Radiomodelismo",
    "concepts.receipts": "Carregamentos\/Faturas",
    "concepts.rent_car_services": "Servi\u00e7os de aluguer de autom\u00f3veis",
    "concepts.school_mat": "Material escolar",
    "concepts.stamps_and_values": "selos e valores",
    "concepts.telecommunications": "Telecomunica\u00e7\u00f5es de servi\u00e7os",
    "concepts.tobacco": "Tabaco",
    "concepts.tobacco_game": "Tabaco e jogos",
    "concepts.trans_currency": "Transfer\u00eancia de moeda",
    "concepts.uploads_of_credit": "Carregamentos de cr\u00e9dito",
    "configuration.staff-member.notification": "Pretendo receber comunica\u00e7\u00f5es da intranet por e-mail",
    "confirm": "Confirmar",
    "confirm-modal.cancel": "Cancelar",
    "confirm-modal.ok": "Aceitar",
    "confirm-password": "Confirmar palavra-passe",
    "contactable": "Contact\u00e1vel",
    "contains": "cont\u00e9m",
    "contract-end-date": "Data de fim do contrato",
    "contract-start-date": "Data de in\u00edcio do contrato",
    "countries": "Pa\u00edses",
    "country": "Pa\u00eds",
    "country-required": "O pa\u00eds \u00e9 obrigat\u00f3rio",
    "couple": "Com parceiro",
    "coupon-used": "Cup\u00e3o utilizado",
    "cp": "C\u00f3digo postal",
    "create": "Criar",
    "create-new": "Criar",
    "created_at": "Data de registo",
    "crm-manager": "Gestor de CRM",
    "crm-operator": "Operador de CRM",
    "crm.children.add": "Adicionar filhos",
    "crm.children.bdate": "Data de anivers\u00e1rio",
    "crm.children.gender": "Sexo",
    "crm.children.name": "Nome",
    "crm.filter.type_find": "Digite para encontrar filtros",
    "crm.filters-section.demographic": "Demogr\u00e1fico",
    "crm.filters-section.requests": "Pedidos",
    "crm.filters-section.stats": "Estat\u00edsticas",
    "crm.segments": "Segmentos",
    "crm.user.remove-email": "Enviar um email ao contacto para notificar que foi removido do sistema",
    "crm.users.bdate-month-day": "Data de nascimento (intervalo)",
    "crm.users.bdate-today": "Data de nascimento (hoje)",
    "crm.users.children-bdate-month-day": "Anivers\u00e1rio dos filhos (intervalo)",
    "crm.users.children-list": "Lista de filhos",
    "crm.users.code": "C\u00f3digo",
    "crm.users.email": "E-mail",
    "crm.users.go-to-users-list": "Ir para a lista de utilizadores",
    "crm.users.last-action": "\u00daltima a\u00e7\u00e3o",
    "crm.users.last-action-datetime": "Data da \u00faltima a\u00e7\u00e3o",
    "crm.users.last_name": "Apelido",
    "crm.users.name": "Nome",
    "crm.users.not-email": "N\u00e3o tem e-mail",
    "crm.users.phone": "Telefone",
    "crm.users.state": "Estado",
    "crm.users.user-saved": "O utilizador foi devidamente guardado!",
    "crm.users.vat-number": "N\u00famero de IVA",
    "csv": "CSV",
    "currency": "Moeda",
    "current-pass-required": "O passe atual \u00e9 obrigat\u00f3rio",
    "current-value": "Valor atual",
    "currently-in-playcenter": "Atualmente no centro de jogos",
    "dashboard": "Painel",
    "dashboard-cms": "Painel CMS",
    "dashboard-crm": "Painel CRM",
    "dashboard-list.dashboards.not-found": "Pain\u00e9is n\u00e3o encontrados",
    "dashboard-list.title.active-graphs": "Gr\u00e1ficos ativos",
    "dashboard-panel": "Painel",
    "dashboard.category.general": "Geral",
    "dashboard.category.home": "In\u00edcio",
    "dashboard.category.not_found": "Categorias n\u00e3o encontradas",
    "dashboard.category.traffic": "Tr\u00e1fego",
    "dashboard.error.not-found-bime": "Painel Bime n\u00e3o encontrado",
    "dashboard.index.no-home-dashboard": "Bem-vindo ao Back Office Neuromobile",
    "dashboards": "Pain\u00e9is",
    "dashboards.0-17": "< 18",
    "dashboards.18-24": "18-24",
    "dashboards.25-34": "25-34",
    "dashboards.35-44": "35-44",
    "dashboards.45-99": "> 45",
    "dashboards.active-users": "Utilizadores ativos",
    "dashboards.active-users-chart": "Utilizadores ativos vs. % utilizadores ativos (m\u00eas)",
    "dashboards.active-vs-registered-users-chart": "Utilizadores ativos vs. Utilizadores registados",
    "dashboards.age-range": "Faixa et\u00e1ria",
    "dashboards.app-users": "Utilizadores da aplica\u00e7\u00e3o",
    "dashboards.channels.mail-notifications": "Utilizadores registados com notifica\u00e7\u00f5es por e-mail ativas",
    "dashboards.channels.verified-mail": "Utilizadores registados com mail confirmado",
    "dashboards.couple": "Casal",
    "dashboards.dwell-chart": "Tempo de perman\u00eancia vs. Competi\u00e7\u00e3o (M\u00eas)",
    "dashboards.dwell-time": "Tempo de perman\u00eancia",
    "dashboards.email": "E-mail v\u00e1lido",
    "dashboards.email-notifications": "Notifica\u00e7\u00f5es por e-mail",
    "dashboards.family": "Fam\u00edlia",
    "dashboards.female": "Feminino",
    "dashboards.frequency-chart": "Frequ\u00eancia de visitas vs. Competi\u00e7\u00e3o (M\u00eas)",
    "dashboards.gender": "Sexo",
    "dashboards.influence-zone": "Zona de influ\u00eancia",
    "dashboards.influence-zones": "Zonas de influ\u00eancia",
    "dashboards.mail-notif-consent": "Notifica\u00e7\u00f5es por e-mail",
    "dashboards.mail-partner-consent": "Notifica\u00e7\u00f5es de terceiros",
    "dashboards.male": "Masculino",
    "dashboards.marital-status": "Estado civil",
    "dashboards.men": "Homem",
    "dashboards.new-app-users": "Utilizadores registados na aplica\u00e7\u00e3o",
    "dashboards.new-registered-users": "Utilizadores registados",
    "dashboards.none": "Nenhum",
    "dashboards.other": "Outro",
    "dashboards.other-users": "Outros utilizadores",
    "dashboards.per-active-users": "% utilizadores ativos",
    "dashboards.phone": "Telefone",
    "dashboards.play-center-users": "Utilizadores do play center",
    "dashboards.quality-data-chart": "Qualidade dos dados de utilizador",
    "dashboards.recurrence": "Frequ\u00eancia de visitas",
    "dashboards.registered-app-users": "Utilizadores registados na aplica\u00e7\u00e3o",
    "dashboards.registered-total-users-chart": "Registos aplica\u00e7\u00e3o vs. Registos totais (agregados)",
    "dashboards.registered-users": "Total de utilizadores registados",
    "dashboards.registered-users-chart": "Registos em aplica\u00e7\u00f5es vs. Registos totais (M\u00eas)",
    "dashboards.registered-users-total": "Total de utilizadores registados",
    "dashboards.registers-per-day": "Registos mensais por dia",
    "dashboards.registration-rate": "Taxa de registo",
    "dashboards.registration-rate-chart": "Taxa de registo (M\u00eas)",
    "dashboards.registration-vs-consent-chart": "Registo vs Consentimento",
    "dashboards.rsocial-users": "Utilizadores de wi-fi social",
    "dashboards.single": "Solteiro",
    "dashboards.thirt-party-notifications": "Notifica\u00e7\u00f5es de terceiros",
    "dashboards.total-app-users": "Total de utilizadores da aplica\u00e7\u00e3o registados",
    "dashboards.total-registered-users": "Total de utilizadores registados",
    "dashboards.user-profiles-age-chart": "Idade",
    "dashboards.user-profiles-catchment-area-chart": "\u00c1rea de capta\u00e7\u00e3o",
    "dashboards.user-profiles-family-chart": "Fam\u00edlia",
    "dashboards.user-profiles-gender-chart": "Sexo",
    "dashboards.vat-number": "N\u00famero de IVA",
    "dashboards.visits": "Visitas",
    "dashboards.visits-competition-chart": "Visitas vs. Competi\u00e7\u00e3o (M\u00eas)",
    "dashboards.visits-users-chart": "Visitas vs. Utilizadores ativos",
    "dashboards.web-users": "Utilizadores web",
    "dashboards.weekly.database-increase": "Novos utilizadores registados por canal",
    "dashboards.weekly.downloads": "Transfer\u00eancias",
    "dashboards.weekly.downloads-chart": "Transfer\u00eancias (Semana)",
    "dashboards.weekly.dwell-time-chart": "Tempo de perman\u00eancia (Semana)",
    "dashboards.weekly.last-eight-weeks-chart": "Utilizadores registados nas \u00faltimas 8 semanas por canal",
    "dashboards.weekly.registered-users-channel": "Utilizadores registados por canal",
    "dashboards.weekly.total-registers": "Total de utilizadores registados",
    "dashboards.weekly.user-profile-selected-week": "Perfil de utilizador para a semana selecionada",
    "dashboards.weekly.user-profile-year-average": "Idade m\u00e9dia do perfil de utilizador",
    "dashboards.weekly.year-average": "M\u00e9dia do ano",
    "dashboards.with-family": "Com fam\u00edlia",
    "dashboards.with-partner": "Com parceiro",
    "dashboards.women": "Mulher",
    "dashboards.zip-code": "C\u00f3digo postal",
    "dashboards.zone1": "Zona 1",
    "dashboards.zone2": "Zona 2",
    "dashboards.zone3": "Zona 3",
    "dashboards.zone4": "Zona 4",
    "date": "Data",
    "date.from": "De",
    "date.from-to": "De {{from}} a {{to}}",
    "date.to": "A",
    "days": "Dias",
    "days-ago": "dias",
    "december": "dezembro",
    "december-short": "dez",
    "default-building": "Edif\u00edcio predefinido",
    "default-building-required": "\u00c9 obrigat\u00f3rio um edif\u00edcio predefinido",
    "delete": "Eliminar",
    "resore": "Restaurar",
    "delivery-date": "Data de entrega",
    "demographic": "Dados demogr\u00e1ficos",
    "description": "Descri\u00e7\u00e3o",
    "developer-contact": "Contacto do promotor",
    "development": "Projeto",
    "development-maps": "Planta do projeto",
    "development.built_surface": "\u00c1rea constru\u00edda",
    "development.city": "Cidade",
    "development.edit": "Editar projeto",
    "development.name": "Projeto",
    "development.new": "Novo projeto",
    "development.useful_surface": "\u00c1rea \u00fatil",
    "deviation": "Desvio",
    "directory.edit": "Editar diretoria",
    "directory.name": "Nome",
    "directory.new": "Nova diretoria",
    "discharge-date": "Data de quita\u00e7\u00e3o",
    "dmr-weekly": "DMR semanal",
    "dmr-weekly.select-dates": "Selecionar intervalo de datas",
    "dmr-weekly.select-week": "Selecionar semana",
    "dni": "DNI",
    "doc": "Doc",
    "document": "Documento",
    "documentation": "Documenta\u00e7\u00e3o",
    "dollars": "D\u00f3lares $",
    "done": "Conclu\u00eddo",
    "download": "Transferir",
    "download-files": "Transferir ficheiros",
    "downloads": "Transfer\u00eancias",
    "draft": "Vers\u00e3o provis\u00f3ria",
    "draft-bookable": "Vers\u00e3o provis\u00f3ria\/reserv\u00e1vel",
    "duplicate": "Duplicar",
    "east": "Este",
    "ecop": "ECOP",
    "edit": "Editar",
    "edit-staff": "Editar equipa",
    "email": "E-mail",
    "email-campaign": "Campanha por e-mail",
    "email-campaign.active": "Ativo",
    "email-campaign.archived": "Arquivado",
    "email-campaign.campaign.list": "Campanhas",
    "email-campaign.daily": "Diariamente",
    "email-campaign.date": "Data",
    "email-campaign.draft": "Vers\u00e3o provis\u00f3ria",
    "email-campaign.edit": "Editar campanha por e-mail",
    "email-campaign.end-date": "Data final",
    "email-campaign.form.desing_template": "Design",
    "email-campaign.form.general_info": "Informa\u00e7\u00f5es gerais",
    "email-campaign.form.summary": "Resumo",
    "email-campaign.frequency-sending": "Frequ\u00eancia de envio",
    "email-campaign.hourly": "Hor\u00e1rio",
    "email-campaign.htmlBody.design": "Conceba o seu e-mail",
    "email-campaign.htmlBody.file_import": "Importar modelo",
    "email-campaign.htmlBody.init_greeting": "Edite aqui o seu e-mail :)",
    "email-campaign.htmlBody.params": "Par\u00e2metros de e-mail",
    "email-campaign.htmlBody.preview": "Pr\u00e9-visualizar",
    "email-campaign.name": "Nome de campanha",
    "email-campaign.new": "Nova campanha por e-mail",
    "email-campaign.not_found": "N\u00e3o foram encontradas campanhas por e-mail",
    "email-campaign.params.help.html": "Insira estes c\u00f3digos para adicionar vari\u00e1veis ao modelo HTML",
    "email-campaign.params.help.subject": "Insira estes c\u00f3digos para adicionar vari\u00e1veis ao assunto do e-mail",
    "email-campaign.params.html.center_name": "Adicionar o nome do centro",
    "email-campaign.params.html.unsub-link": "Adicionar a liga\u00e7\u00e3o para cancelar subscri\u00e7\u00e3o",
    "email-campaign.params.html.user_email": "Adicionar o e-mail do utilizador",
    "email-campaign.params.html.user_name": "Adicionar o nome do utilizador",
    "email-campaign.params.subject.barcode_image": "Adicionar a imagem de c\u00f3digo de barras",
    "email-campaign.params.subject.center_name": "Adicionar o nome do centro",
    "email-campaign.params.subject.qr_image": "Adicionar a imagem qr",
    "email-campaign.params.subject.reward-code": "Adicionar o c\u00f3digo do pr\u00e9mio",
    "email-campaign.params.subject.user_code": "Adicionar o c\u00f3digo do utilizador",
    "email-campaign.params.subject.user_email": "Adicionar o e-mail do utilizador",
    "email-campaign.params.subject.user_name": "Adicionar o nome do utilizador",
    "email-campaign.scheduled": "Agendado",
    "email-campaign.segment": "Segmento",
    "email-campaign.segment.list": "Segmentos",
    "email-campaign.segment.segmentName": "Nome do segmento",
    "email-campaign.send.save_and_test": "Guardar e enviar teste",
    "email-campaign.sending-date": "Data para envio",
    "email-campaign.sentDatetime": "Data de envio",
    "email-campaign.single-sending": "Envio \u00fanico",
    "email-campaign.start-date": "Data de in\u00edcio",
    "email-campaign.statistic.blocked": "Bloqueado",
    "email-campaign.statistic.bounced": "Devolvido",
    "email-campaign.statistic.clicked": "Clicado",
    "email-campaign.statistic.delivered": "Entregue",
    "email-campaign.statistic.opened": "Aberto",
    "email-campaign.statistic.spam": "Spam",
    "email-campaign.subject": "Assunto",
    "email-campaign.warning-save-draft": "Antes de enviar um e-mail de teste, a campanha ser\u00e1 guardada numa vers\u00e3o provis\u00f3ria",
    "email-campaign.warning-unsub-link": "Aviso! O modelo atual n\u00e3o possui uma liga\u00e7\u00e3o para cancelar subscri\u00e7\u00e3o. Adicione uma antes de publicar a campanha.",
    "email-campaign.weekly": "Semanal",
    "email-required": "O e-mail \u00e9 obrigat\u00f3rio",
    "email-template.not_found": "Modelos n\u00e3o encontrados",
    "email-template.select_one": "Selecione um modelo",
    "email-template.send.select_mail": "Digite o endere\u00e7o de e-mail para onde pretende enviar o e-mail de teste",
    "email-template.send.test": "Enviar e-mail de teste",
    "email-template.type.send_contact": "Mensagem de aplica\u00e7\u00e3o m\u00f3vel",
    "email-template.type.send_intranet_communication_email": "Notifica\u00e7\u00e3o de comunica\u00e7\u00e3o intranet",
    "email-template.type.send_proposals_report": "Relat\u00f3rio de cup\u00f5es",
    "email-template.type.send_reprint_tickets": "Notifica\u00e7\u00e3o de reimpress\u00e3o de bilhetes",
    "email-template.type.send_staff_connection_warning": "Aviso de liga\u00e7\u00e3o de equipa",
    "email-template.type.send_staff_reset_password_email": "Lembrete para reinicializa\u00e7\u00e3o da palavra-passe do pessoal",
    "email-template.type.send_user_activate_email": "Mensagem de ativa\u00e7\u00e3o para utilizador da aplica\u00e7\u00e3o m\u00f3vel",
    "email-template.type.send_user_child_birthday_reminder": "Lembrete do anivers\u00e1rio do filho do cliente",
    "email-template.type.send_user_child_birthday_visit_ack": "Agradecimento de visita pelo anivers\u00e1rio do filho do cliente",
    "email-template.type.send_user_registration_email": "Mensagem de inscri\u00e7\u00e3o de cliente",
    "email-template.type.send_user_reset_password_email": "Mensagem de reinicializa\u00e7\u00e3o da palavra-passe para utilizador da aplica\u00e7\u00e3o m\u00f3vel",
    "email-template.type.send_user_unsubscribe_email": "Cancelamento de e-mail de contacto ",
    "email.not-exist": "O e-mail n\u00e3o existe",
    "email.recovery-sent": "O e-mail para recupera\u00e7\u00e3o da palavra-passe foi devidamente enviado",
    "emailing-campaign.filters": "Filtros",
    "emailing.campaign.import-template": "Importar modelo",
    "emailing.campaigns.copy-of": "Copiar de",
    "emailing.campaigns.duplicate-error": "Erro ao duplicar campanha",
    "end-date": "Data final",
    "energy-certification": "Certifica\u00e7\u00e3o energ\u00e9tica",
    "entry-time": "Hora de entrada",
    "error.channels.required": "Os canais s\u00e3o obrigat\u00f3rios",
    "error.child.bdate.required": "A data de anivers\u00e1rio \u00e9 obrigat\u00f3ria",
    "error.child.name.required": "O nome \u00e9 obrigat\u00f3rio",
    "error.child.relationship.required": "O relacionamento \u00e9 obrigat\u00f3rio",
    "error.child.sex.required": "O sexo \u00e9 obrigat\u00f3rio",
    "error.commerce.categories.required": "\u00c9 obrigat\u00f3ria uma categoria",
    "error.commerce.gender.required": "\u00c9 obrigat\u00f3rio um sexo",
    "error.commerce.imageUrl.required": "O url da imagem \u00e9 obrigat\u00f3rio",
    "error.commerce.name.required": "O nome \u00e9 obrigat\u00f3rio",
    "error.commerce.whatsapp-message.required": "\u00c9 obrigat\u00f3rio uma Mensagem WhatsApp",
    "error.commerce.whatsapp-phone.format": "O telefone WhatsApp n\u00e3o tem c\u00f3digo de \u00e1rea. Dever\u00e1 seguir o formato +346xxxxxxxx.",
    "error.commerce.zone.required": "A zona \u00e9 obrigat\u00f3ria",
    "error.communication.commerce.required": "Selecione pelo menos uma loja",
    "error.communication.companion.required": "Alguns acompanhantes t\u00eam dados por preencher",
    "error.coupon.commerces.required": "A loja \u00e9 obrigat\u00f3ria",
    "error.coupon.text.required": "O texto \u00e9 obrigat\u00f3rio",
    "error.coupon.usageLimit.required": "O limite de cup\u00e3o \u00e9 obrigat\u00f3rio",
    "error.crm.children.entryTime.required": "A hora de entrada \u00e9 obrigat\u00f3ria",
    "error.crm.children.expectedExitTime.required": "A hora esperada de sa\u00edda \u00e9 obrigat\u00f3ria",
    "error.dashboard.bimeId.required": "O painel Bime \u00e9 obrigat\u00f3rio",
    "error.dashboard.category.required": "A categoria \u00e9 obrigat\u00f3ria",
    "error.dashboard.name.required": "O nome \u00e9 obrigat\u00f3rio",
    "error.dashboard.panel.required": "O painel \u00e9 obrigat\u00f3rio",
    "error.development.required": "O desenvolvimento \u00e9 obrigat\u00f3rio",
    "error.email-campaign-template.htmlBody.required": "O modelo de campanha n\u00e3o pode estar por preencher",
    "error.email-campaign-template.name.required": "O nome n\u00e3o pode estar por preencher",
    "error.email-campaign.date.invalid_past": "A data de in\u00edcio n\u00e3o pode ser uma data passada",
    "error.email-campaign.date.required": "A data n\u00e3o pode estar por preencher",
    "error.email-campaign.endDatetime.post_startDatetime": "A data de fim tem de ser posterior \u00e0 data de in\u00edcio",
    "error.email-campaign.htmlBody.required": "O modelo de campanha n\u00e3o pode estar por preencher",
    "error.email-campaign.name.required": "O nome n\u00e3o pode estar por preencher",
    "error.email-campaign.segment.required": "O campo do segmento n\u00e3o pode estar por preencher",
    "error.email-campaign.subject.required": "O assunto do e-mail n\u00e3o pode estar por preencher",
    "error.email.invalid": "Endere\u00e7o de e-mail inv\u00e1lido",
    "error.file.commerces.required": "Selecione pelo menos uma loja",
    "error.file.name.required": "O nome n\u00e3o pode estar por preencher",
    "error.file.not_allowed": "Ficheiro n\u00e3o permitido",
    "error.file.oversize": "O ficheiro excede o tamanho m\u00e1ximo permitido para os ficheiros",
    "error.file.roles.required": "Selecione uma fun\u00e7\u00e3o",
    "error.file.type.not_allowed": "Tipo de ficheiro n\u00e3o permitido",
    "error.file.url.required": "O ficheiro \u00e9 obrigat\u00f3rio",
    "error.proposal.category.required": "A categoria \u00e9 obrigat\u00f3ria",
    "error.proposal.channel.required": "Tem de selecionar pelo menos um canal",
    "error.proposal.description.required": "A descri\u00e7\u00e3o \u00e9 obrigat\u00f3ria",
    "error.proposal.end-date.after": "A data de fim n\u00e3o pode ser anterior \u00e0 data de in\u00edcio",
    "error.proposal.end-date.required": "A data de fim \u00e9 obrigat\u00f3ria",
    "error.proposal.event-end-date.after": "A data de fim do evento n\u00e3o pode ser anterior \u00e0 data de in\u00edcio do evento",
    "error.proposal.event-end-date.required": "A data de fim de evento \u00e9 obrigat\u00f3ria",
    "error.proposal.eventStartDate.required": "A data de in\u00edcio de evento \u00e9 obrigat\u00f3ria",
    "error.proposal.gender.required": "O sexo \u00e9 obrigat\u00f3rio",
    "error.proposal.maximumAge.required": "A idade m\u00e1xima \u00e9 obrigat\u00f3ria",
    "error.proposal.minimumAge.required": "A idade m\u00ednima \u00e9 obrigat\u00f3ria",
    "error.proposal.name.required": "O nome \u00e9 obrigat\u00f3rio",
    "error.proposal.proposalType.required": "O tipo \u00e9 obrigat\u00f3rio",
    "error.proposal.startDate.required": "A data inicial \u00e9 obrigat\u00f3ria",
    "error.push-notification.event-end-date.after": "Depois",
    "error.residential.owner.code.required": "O c\u00f3digo \u00e9 obrigat\u00f3rio",
    "error.residential.owner.name.required": "O nome \u00e9 obrigat\u00f3rio",
    "error.sale.date.closed-month-error": "N\u00e3o pode adicionar vendas a meses fechados",
    "error.sale.date.invalid_future": "N\u00e3o \u00e9 poss\u00edvel adicionar vendas de datas futuras",
    "error.server": "Erro do servidor",
    "error.signature.not_exist": "O utilizador n\u00e3o disp\u00f5e de assinatura",
    "error.tickets.commerce.required": "A loja \u00e9 obrigat\u00f3ria",
    "error.tickets.date.required": "A data \u00e9 obrigat\u00f3ria",
    "error.user.cp.required": "O c\u00f3digo postal \u00e9 obrigat\u00f3rio",
    "error.user.email.required": "O e-mail \u00e9 obrigat\u00f3rio",
    "error.user.gdpr": "O RGPD tem de ser aceite",
    "error.user.lastName.required": "O apelido \u00e9 obrigat\u00f3rio",
    "error.user.name.required": "O nome \u00e9 obrigat\u00f3rio",
    "error.user.personType.required": "O tipo de pessoa \u00e9 obrigat\u00f3rio",
    "error.user.phone.required": "O n\u00famero de telefone \u00e9 obrigat\u00f3rio",
    "error.user.registration-date.required": "A data de inscri\u00e7\u00e3o \u00e9 obrigat\u00f3ria",
    "error_browser_iframe": "O seu navegador n\u00e3o \u00e9 compat\u00edvel com iframes",
    "estate": "Propriedade",
    "estimated-cost": "Custo estimado",
    "estimated-stay-time": "Tempo de perman\u00eancia estimado (HH:MM)",
    "eula": "CLUF",
    "euros": "Euros \u20ac",
    "excel": "Excel",
    "exit-time": "Hora de sa\u00edda",
    "expire": "Caducar",
    "export": "Exportar",
    "external-api": "API Externa",
    "extra-info": "Informa\u00e7\u00f5es adicionais",
    "family": "Com a fam\u00edlia",
    "featured": "Destaques",
    "february": "fevereiro",
    "february-short": "fev",
    "file": "Ficheiro",
    "file.creator_id": "Criador",
    "file.description-optional": "Descri\u00e7\u00e3o opcional",
    "file.edit": "Editar ficheiro",
    "file.name": "Nome",
    "file.new": "Novo ficheiro",
    "file.roles.all": "Todos",
    "file.roles.commerce": "Loja",
    "file.roles.management": "Gest\u00e3o",
    "file.roles_with_access": "Fun\u00e7\u00f5es com acesso",
    "file.shop_with_access": "Lojas com acesso",
    "file.size": "Tamanho",
    "file.type": "Tipo",
    "file.updated_at": "Data",
    "file.visibility": "Visibilidade",
    "file_max_allowed": "O tamanho m\u00e1ximo permitido \u00e9 {{size}}",
    "files": "Ficheiros",
    "files.add-folder": "Adicionar pasta",
    "filter.select": "Selecione um filtro",
    "final-cost": "Custo final",
    "first-property": "Primeira propriedade",
    "floor": "Ch\u00e3o",
    "floor-plans": "Plantas",
    "flows.date": "Data",
    "flows.upload-xlsx-error": "O ficheiro que carregou n\u00e3o se encontra num formato v\u00e1lido. Verifique o ficheiro e tente novamente.",
    "flows.upload-xlsx-success": "O ficheiro XLSX foi devidamente carregado",
    "folder": "Pasta",
    "folder.add": "Criar pasta",
    "folder.new.current_dir": "Criar uma nova pasta",
    "folder.success-created": "Cria\u00e7\u00e3o bem-sucedida",
    "folder_name": "Nome da pasta",
    "following": "os seguintes",
    "footfall": "Footfall",
    "footfall.cars": "Autom\u00f3veis",
    "footfall.cinemas": "Cinemas",
    "footfall.import-xlsx": "Importar XLSX",
    "footfall.info": "Informa\u00e7\u00f5es",
    "footfall.xlsx-format": "O XLSX deve ter o seguinte formato:",
    "footfall.xlsx.columns": "Deve ter quatro colunas, com a data na primeira coluna, footfall na segunda coluna, autom\u00f3veis na terceira e cinemas na quarta. Na primeira linha deve constar o nome da coluna.",
    "footfall.xlsx.date-format": "A data deve estar definida no Excel com o tipo \"data\".",
    "footfall.xlsx.download-example": "Transferir modelo",
    "footfall.xlsx.values-format": "Footfall, autom\u00f3veis e cinemas devem estar definidos no Excel com o tipo \"n\u00famero\".",
    "forgot-password": "Esqueceu-se da palavra-passe?",
    "form-dashboard.bime-dashboard": "Painel Bime",
    "form-dashboard.button.clear": "Limpar",
    "form-dashboard.button.submit": "Enviar",
    "form-dashboard.category": "Categoria",
    "form-dashboard.header.available_graphs": "Gr\u00e1ficos dispon\u00edveis",
    "form-dashboard.name": "Nome do painel",
    "form-dashboard.parameters": "Par\u00e2metros",
    "form-email-campaign.error.import_file": "O ficheiro tem de estar no formato HTML",
    "form-email-campaign.warning.import-template": "O modelo atual ser\u00e1 substitu\u00eddo. Tem a certeza?",
    "friday": "sexta",
    "friday-short": "sex",
    "from-unit-delivery-date": "A partir da data de entrega da unidade",
    "from-unit-opening-date": "A partir da data de abertura da unidade",
    "gantt": "Gr\u00e1fico Gantt",
    "garage-plan": "Plantas de garagem",
    "garages-number": "N\u00famero de garagem",
    "gender": "Sexo",
    "general-data": "Dados gerais",
    "general-info": "Informa\u00e7\u00f5es gerais",
    "general-terms-of-online-contracting": "Condi\u00e7\u00f5es gerais da contrata\u00e7\u00e3o on-line",
    "girl": "Rapariga",
    "goal-value": "Valor do objetivo",
    "grandchild": "Neto",
    "guard": "Guarda",
    "guard.chose_staff_member": "Escolha um membro da equipa...",
    "guard.no_assigned_this_day": "N\u00e3o existe guarda atribu\u00eddo para este dia",
    "has-email": "Tem e-mail",
    "header": "Cabe\u00e7alho",
    "how-to-arrive-plan": "Planear \"Como chegar\"",
    "id": "id",
    "image": "Imagem",
    "image-required": "A imagem \u00e9 obrigat\u00f3ria",
    "images": "Imagens",
    "import": "Importar",
    "influence-zone": "\u00c1reas de influ\u00eancia",
    "info.default.error.message": "Ocorreu um erro. Tente novamente mais tarde",
    "info.default.error.title": "Erro",
    "info.default.succes.title": "\u00caxito",
    "info.default.success.message": "A opera\u00e7\u00e3o foi devidamente executada.",
    "informative-message.change-password.success.message": "A sua palavra-passe foi alterada. Ser\u00e1 redirecionado para o painel de in\u00edcio de sess\u00e3o.",
    "informative-message.close-month.error.message": "Ocorreu um erro ao fechar o m\u00eas. Tente novamente mais tarde.",
    "informative-message.close-month.success.message": "O m\u00eas foi devidamente fechado.",
    "informative-message.export-crm-csv.success.message": "Receber\u00e1 um e-mail com o ficheiro CSV",
    "informative-message.reject-close-month.error.message": "Ocorreu um erro ao rejeitar o fecho de m\u00eas. Tente novamente mais tarde",
    "informative-message.reject-close-month.success.message": "O fecho de m\u00eas foi devidamente rejeitado. Foi enviada uma comunica\u00e7\u00e3o \u00e0 loja",
    "informative-message.upload-yardi.error": "O ficheiro Yardi n\u00e3o foi devidamente carregado. Tente novamente ou contacte um administrador.",
    "informative-message.upload-yardi.success": "O ficheiro Yardi foi devidamente carregado.",
    "insert-email": "Introduza o seu endere\u00e7o de e-mail",
    "installed.apps": "Aplica\u00e7\u00f5es registadas",
    "internal": "Interno",
    "intranet-sales-year": "Demonstra\u00e7\u00e3o anual de vendas",
    "intranet.close_month-message": "Est\u00e1 prestes a encerrar os registos de vendas de {{shop}} relativos a {{month}}.",
    "intranet.communications.accept-eula": "Sim, pretendo receber comunica\u00e7\u00f5es eletr\u00f3nicas de terceiros.",
    "intranet.communications.contact-it": "Ocorreu um erro ao aceitar comunica\u00e7\u00f5es de terceiros. Contacte a Inform\u00e1tica e comunique-lhes o seguinte erro de rastreio:",
    "intranet.communications.error": "Erro ao atualizar um membro da equipa",
    "intranet.communications.eula-communications-description": "Antes de usar o sistema de comunica\u00e7\u00e3o da intranet, precisa de aceitar os termos e condi\u00e7\u00f5es.",
    "intranet.communications.not-contactable-staff": "Existem membros da equipa dispon\u00edveis no sistema que n\u00e3o est\u00e3o contact\u00e1veis. Atualize pelo menos um dos membros da equipa de modo a ficar contact\u00e1vel.",
    "intranet.communications.resent-success": "A comunica\u00e7\u00e3o foi devidamente reenviada.",
    "intranet.communications.terms-and-conditions": "Termos e condi\u00e7\u00f5es",
    "intranet.communications.title-eula": "Comunica\u00e7\u00f5es por intranet - Aceita\u00e7\u00e3o RGPD",
    "intranet.communicatios.resend-staff-members": "Selecione os membros da equipa externa que devem receber a comunica\u00e7\u00e3o",
    "intranet.flow.total-flows": "Total do m\u00eas",
    "intranet.footfall.import": "Importar",
    "intranet.footfall.import-footfall": "Importar footfall",
    "intranet.sales-year.button-show": "Mostrar demonstra\u00e7\u00e3o anual de vendas",
    "intranet.sales-year.disclaimer": "Certificado das vendas l\u00edquidas (sem IVA) realizadas na loja de refer\u00eancia durante o per\u00edodo temporal indicado, que correspondam devidamente aos documentos comprovativos e \u00e0s notas contabil\u00edsticas dos registos da empresa. Vendas l\u00edquidas (IVA n\u00e3o inclu\u00eddo)",
    "intranet.sales-year.validate.acceptable": "Desvio aceit\u00e1vel",
    "intranet.sales-year.validate.invalid": "A demonstra\u00e7\u00e3o n\u00e3o \u00e9 v\u00e1lida",
    "intranet.sales-year.validate.valid": "A demonstra\u00e7\u00e3o est\u00e1 correta",
    "intranet.sales-year.warn-pending-review": "O administrador est\u00e1 a rever a demonstra\u00e7\u00e3o anual de vendas e esta n\u00e3o pode ser alterada de momento.",
    "intranet.sales-years.declared": "Vendas declaradas",
    "intranet.sales-years.deviation-level": "N\u00edvel do desvio",
    "intranet.sales-years.disable-sales-button": "Desativar demonstra\u00e7\u00e3o anual de vendas {{ano}}",
    "intranet.sales-years.disable-sales-years-confirmation": "Quando confirmar, o processo da declara\u00e7\u00e3o anual de vendas ser\u00e1 conclu\u00eddo. Depois disto, as lojas n\u00e3o poder\u00e3o modificar a sua demonstra\u00e7\u00e3o anual de vendas e as suas vendas para o ano {{year}}.",
    "intranet.sales-years.dmi": "DMI",
    "intranet.sales-years.enable-sales-button": "Ativar demonstra\u00e7\u00e3o anual de vendas {{year}}",
    "intranet.sales-years.enable-sales-years-confirmation": "Quando confirmar, ser\u00e1 iniciado o processo de declara\u00e7\u00e3o anual de vendas. As lojas podem criar a sua demonstra\u00e7\u00e3o anual de vendas e podem modificar as suas vendas para o ano {{year}}.",
    "intranet.sales-years.invalid-sales": "A declara\u00e7\u00e3o anual de vendas n\u00e3o pode ser iniciada porque as seguintes lojas t\u00eam meses por validar:",
    "intranet.sales-years.reject-communication-message": "A sua demonstra\u00e7\u00e3o anual de vendas apresenta diferen\u00e7as relativas \u00e0s vendas declaradas mensalmente. Verifique o montante declarado e volte a confirm\u00e1-lo para os meses seguintes: ",
    "intranet.sales-years.reject-communication-subject": "Declara\u00e7\u00e3o anual de vendas rejeitada, ano ",
    "intranet.sales-years.reject-sale": "Rejeitar",
    "intranet.sales-years.status.pending": " Pendente",
    "intranet.sales-years.status.rejected": "Rejeitado\/a",
    "intranet.sales-years.status.unrequested": "N\u00e3o solicitado",
    "intranet.sales-years.status.validated": "Validado",
    "intranet.sales-years.update-dmi-error-message": "Ocorreu um problema com a atualiza\u00e7\u00e3o e o valor DMI n\u00e3o foi atualizado. Contacte a equipa de apoio.",
    "intranet.sales-years.update-dmi-success-message": "O valor DMI foi devidamente atualizado.",
    "intranet.sales-years.update-dmi-value": "Atualizar valor DMI",
    "intranet.sales-years.update-yardi": "Pretendo carregar o ficheiro Yardi (pode demorar alguns minutos)",
    "intranet.sales-years.validate-sale": "Validar",
    "intranet.sales.declare-yearly-sales": "Declarar demonstra\u00e7\u00e3o anual de vendas",
    "intranet.sales.download-csv": "Transferir CSV",
    "intranet.sales.download-sales": "Transferir vendas",
    "intranet.sales.import": "Importar",
    "intranet.sales.import-sales": "Importar vendas",
    "intranet.sales.select-dates": "Selecionar intervalo de dados",
    "intranet.update_dmi-message": "Vai atualizar o valor DMI de {{shop}} para {{month}}.",
    "invalid-token": "O c\u00f3digo n\u00e3o \u00e9 v\u00e1lido",
    "investor": "Investidor",
    "is": "est\u00e1",
    "is-in-play-center": "est\u00e1 no centro de jogos",
    "is_not": "n\u00e3o est\u00e1",
    "january": "janeiro",
    "january-short": "jan",
    "job-title": "Cargo",
    "job-title-required": "O cargo \u00e9 obrigat\u00f3rio",
    "july": "julho",
    "july-short": "jul",
    "june": "junho",
    "june-short": "jun",
    "kpi": "KPI",
    "kpi-tech": "KPI Tech",
    "kpi.tooltip.active-users": "N\u00famero de utilizadores que t\u00eam a aplica\u00e7\u00e3o ativa no per\u00edodo selecionado",
    "kpi.tooltip.app-users": "N\u00famero de utilizadores registados atrav\u00e9s do canal da aplica\u00e7\u00e3o no per\u00edodo selecionado",
    "kpi.tooltip.average-visit-time": "Tempo m\u00e9dio de perman\u00eancia no centro comercial no per\u00edodo selecionado",
    "kpi.tooltip.downloads": "Transfer\u00eancias de aplica\u00e7\u00f5es no per\u00edodo selecionado",
    "kpi.tooltip.dwell-time": "Tempo m\u00e9dio de perman\u00eancia no centro comercial no per\u00edodo selecionado",
    "kpi.tooltip.installed-apps": "N\u00famero de aplica\u00e7\u00f5es registadas no per\u00edodo selecionado",
    "kpi.tooltip.play-center-users": "N\u00famero de utilizadores registados atrav\u00e9s do canal play center no per\u00edodo selecionado",
    "kpi.tooltip.recurrence": "N\u00famero m\u00e9dio de vezes que os utilizadores visitam o centro comercial no per\u00edodo selecionado",
    "kpi.tooltip.registered-app-users": "Novos utilizadores registados pelo canal da aplica\u00e7\u00e3o no per\u00edodo selecionado",
    "kpi.tooltip.registered-users-total": "N\u00famero de utilizadores registados no per\u00edodo selecionado",
    "kpi.tooltip.rother-users": "N\u00famero de utilizadores registados atrav\u00e9s de outros canais no per\u00edodo selecionado",
    "kpi.tooltip.social-users": "N\u00famero de utilizadores registados atrav\u00e9s de canal de wi-fi social no per\u00edodo selecionado",
    "kpi.tooltip.visits": "N\u00famero de visitas ao centro comercial no per\u00edodo selecionado",
    "kpi.tooltip.web-users": "N\u00famero de utilizadores registados atrav\u00e9s do canal web no per\u00edodo selecionado",
    "kpi.vs-last-month": "VS M\u00eas passado",
    "kpi.vs-same-month-last-year": "VS Mesmo m\u00eas do ano passado",
    "kyc": "CSC",
    "label": "Etiqueta",
    "last-activity": "\u00daltima atividade",
    "last-name": "Apelido",
    "last-name-required": "O apelido \u00e9 obrigat\u00f3rio",
    "last-units": "\u00daltimas unidades",
    "leasing": "Loca\u00e7\u00e3o\/aluguer",
    "leasing.accessories": "Acess\u00f3rios",
    "leasing.books": "Livros",
    "leasing.bowling": "Bowling",
    "leasing.center.attach-logo": "Anexar log\u00f3tipo (png, jpeg, gif ou jpg)",
    "leasing.childrens-apparel": "Vestu\u00e1rio infantil",
    "leasing.cinema": "Cinema",
    "leasing.coffeeshop-icecream": "Caf\u00e9 e gelados",
    "leasing.electronics": "Eletr\u00f3nica",
    "leasing.fast-food": "Comida r\u00e1pida",
    "leasing.food": "Alimenta\u00e7\u00e3o",
    "leasing.furniture": "M\u00f3veis",
    "leasing.hairdressers-and-beauty-services": "Cabeleireiros e servi\u00e7os de beleza",
    "leasing.handbags": "Malas",
    "leasing.home": "Lar",
    "leasing.home-apparel": "T\u00eaxteis para o lar",
    "leasing.jewelry-watches": "Joias e rel\u00f3gios",
    "leasing.lingerie": "Lingerie",
    "leasing.mens-apparel": "Vestu\u00e1rio masculino",
    "leasing.opticians-farmacy": "Lojas de \u00f3tica",
    "leasing.other-leisure": "Outro lazer",
    "leasing.other-services": "Outros servi\u00e7os",
    "leasing.outlet": "Estabelecimento",
    "leasing.perfumery-beauty-store": "Perfumaria e artigos de beleza",
    "leasing.pets": "Animais de estima\u00e7\u00e3o",
    "leasing.phone-photo-games": "Telefones fotografia e jogos",
    "leasing.restaurants": "Restaurantes",
    "leasing.shoes": "Sapatarias",
    "leasing.special-food": "Restaura\u00e7\u00e3o especializada",
    "leasing.speciality-gift": "Prendas e brindes",
    "leasing.sports": "Desporto",
    "leasing.supermarket": "Supermercado",
    "leasing.toys": "Brinquedos",
    "leasing.travels": "Viagens",
    "leasing.womens-apparel": "Vestu\u00e1rio feminino",
    "leasing.womens-mens-apparel": "Vestu\u00e1rio feminino e masculino",
    "leasing.young-apparel": "Vestu\u00e1rio para jovens",
    "legal": "Legal",
    "legal-person": "Pessoa jur\u00eddica",
    "level": "N\u00edvel",
    "level-required": "O n\u00edvel \u00e9 obrigat\u00f3rio",
    "lineitem": "Rubrica",
    "lineitem.edit": "Editar rubrica",
    "lineitem.new": "Nova rubrica",
    "location": "Localiza\u00e7\u00e3o",
    "lock": "Bloco",
    "loyalty": "Fideliza\u00e7\u00e3o",
    "loyalty-card": "Cart\u00e3o de fideliza\u00e7\u00e3o",
    "loyalty-card.campaign": "Campanha",
    "loyalty-card.stamps": "Selos",
    "loyalty-card.stamps.redeemable-stamp": "Selo resgat\u00e1vel",
    "loyalty-card.stamps.value": "Valor",
    "loyalty-cards": "Cart\u00f5es de fideliza\u00e7\u00e3o",
    "loyalty-cards.can-not-generate-message": "O utilizador n\u00e3o disp\u00f5e de selos que possam ser resgatados",
    "loyalty-cards.create-historic-stamps": "Criar selos",
    "loyalty-cards.error-stamp-number": "N\u00famero de selos (m\u00e1x: {{max}} selos)",
    "loyalty-cards.generate-error-message": "Ocorreu um problema ao gerar os cart\u00f5es de fideliza\u00e7\u00e3o, e estes n\u00e3o foram criados. Contacte a equipa de apoio.",
    "loyalty-cards.generate-success-message": "Os cart\u00f5es de fideliza\u00e7\u00e3o foram devidamente gerados.",
    "loyalty-cards.not-enough-stamps": "O cliente n\u00e3o disp\u00f5e de selos suficientes para um cart\u00e3o de fideliza\u00e7\u00e3o.",
    "loyalty-cards.preview-message": "Resumo de cart\u00f5es de fideliza\u00e7\u00e3o",
    "loyalty-cards.select-campaign": "Selecionar campanha",
    "loyalty-cards.stamp-number": "N\u00famero do selo",
    "loyalty-cards.stamps": "Selos",
    "loyalty.exchange-stamps": "Resgatar selos",
    "mail-notifications": "Comunica\u00e7\u00f5es eletr\u00f3nicas comerciais",
    "mail_notifications": "Comunica\u00e7\u00f5es eletr\u00f3nicas comerciais",
    "mail_partners_notifications": "Comunica\u00e7\u00f5es eletr\u00f3nicas comerciais de terceiros",
    "man": "Homem",
    "management": "Gest\u00e3o",
    "manager": "Gestor",
    "march": "mar\u00e7o",
    "march-short": "mar",
    "marketing": "Marketing",
    "marketing-analytics.campaign.active-crm": "Ativa no CRM",
    "marketing-analytics.campaign.attach-image": "Anexar imagem (png, jpeg, gif ou jpg)",
    "marketing-analytics.campaign.closing-notes": "Notas",
    "marketing-analytics.campaign.closure.actions": "A\u00e7\u00f5es",
    "marketing-analytics.campaign.closure.attr-required": "Os seguintes atributos s\u00e3o obrigat\u00f3rios: {{requiredAttrs}}",
    "marketing-analytics.campaign.closure.campaign": "Campanha",
    "marketing-analytics.campaign.closure.is-goal": "\u00e9 objetivo",
    "marketing-analytics.campaign.closure.manual-kpis": "KPIs manuais",
    "marketing-analytics.campaign.closure.suppliers": "Fornecedores",
    "marketing-analytics.campaign.files": "Imagens de campanha",
    "marketing-analytics.campaign.generic-reward": "Recompensa gen\u00e9rica",
    "marketing-analytics.campaign.loyalty.amount": "Montante",
    "marketing-analytics.campaign.loyalty.welcome-stamps": "Selos de boas-vindas",
    "marketing-analytics.campaign.max-capacity": "Capacidade m\u00e1xima",
    "marketing-analytics.campaign.raffle.block-raffle-info": "N\u00e3o poder\u00e1 modificar as configura\u00e7\u00f5es do sorteio depois da gera\u00e7\u00e3o do primeiro bilhete. Tenha isso presente quando gravar e lan\u00e7ar esta campanha.",
    "marketing-analytics.campaign.raffle.general_data": "Dados gerais",
    "marketing-analytics.campaign.raffle.loyalty-code": "C\u00f3digo de fideliza\u00e7\u00e3o",
    "marketing-analytics.campaign.raffle.rules": "Exce\u00e7\u00f5es ao sorteio",
    "marketing-analytics.campaign.rating": "Avalia\u00e7\u00e3o de campanha",
    "marketing-analytics.campaign.search-campaign": "Procurar campanha",
    "marketing-analytics.campaign.select-campaign": "Selecionar campanha",
    "marketing-analytics.campaign.submit-image": "Enviar imagem",
    "marketing-analytics.campaign.without-remains": "Sem trocos",
    "marketing-analytics.campaigns.actions": "A\u00e7\u00f5es de campanha",
    "marketing-analytics.campaigns.gantt": "Gr\u00e1fico Gantt",
    "marketing-analytics.campaigns.goals": "Objetivos de campanha",
    "marketing-analytics.campaigns.kpi": "KPI",
    "marketing-analytics.campaigns.loyalty": "Fideliza\u00e7\u00e3o",
    "marketing-analytics.kpi.active-users": "DMI- Utilizadores ativos",
    "marketing-analytics.kpi.attendant-number": "Campanha - Assistente",
    "marketing-analytics.kpi.contestants": "Campanha- Concorrentes",
    "marketing-analytics.kpi.cost-per-contestant": "Campanha- Custo por concorrente",
    "marketing-analytics.kpi.coupon-exchanges": "DMI- Utiliza\u00e7\u00e3o de cup\u00f5es",
    "marketing-analytics.kpi.coupon-percent": "DMI- Cup\u00e3o (%)",
    "marketing-analytics.kpi.eca-number": "Campanha- Publicidade",
    "marketing-analytics.kpi.impressions-number": "DMI- Impress\u00f5es",
    "marketing-analytics.kpi.manual-kpis": "KPIs manuais",
    "marketing-analytics.kpi.new-registered-users": "Campanha- Novos utilizadores registados",
    "marketing-analytics.kpi.new-registered-users-percent": "Campanha- Novos utilizadores registados (%)",
    "marketing-analytics.kpi.previous-registered-users": "Campanha- Utilizadores anteriormente registados",
    "marketing-analytics.kpi.receipt-average-price": "Campanha- Pre\u00e7o do recibo m\u00e9dio",
    "marketing-analytics.kpi.tooltip.active-users": "Quantidade de utilizadores ativos na aplica\u00e7\u00e3o m\u00f3vel durante o per\u00edodo de campanha",
    "marketing-analytics.kpi.tooltip.attendant": "N\u00famero total de pessoas que participaram no evento",
    "marketing-analytics.kpi.tooltip.contestants": "N\u00famero total de pessoas que participaram no evento",
    "marketing-analytics.kpi.tooltip.cost-per-contestant": "Valor m\u00e9dio do custo da campanha por participante",
    "marketing-analytics.kpi.tooltip.coupon-exchanges": "Quantidade de cup\u00f5es resgatados",
    "marketing-analytics.kpi.tooltip.coupon-percent": "Cup\u00f5es resgatados vs. total de cup\u00f5es",
    "marketing-analytics.kpi.tooltip.eca": "Valor de publicidade n\u00e3o pago da campanha",
    "marketing-analytics.kpi.tooltip.eceipt-average-price": "Valor m\u00e9dio dos recibos durante a campanha por concorrente",
    "marketing-analytics.kpi.tooltip.impressions-number": "Quantidade de impress\u00f5es na aplica\u00e7\u00e3o m\u00f3vel durante o per\u00edodo de campanha",
    "marketing-analytics.kpi.tooltip.new-registered-users": "Novos utilizadores registados durante a campanha",
    "marketing-analytics.kpi.tooltip.new-registered-users-percent": "Novos utilizadores registados durante a campanha vs. utilizadores anteriormente registados",
    "marketing-analytics.kpi.tooltip.previous-registered-users": "Quantidade de utilizadores anteriormente registados que participaram na campanha",
    "marketing-analytics.kpi.tooltip.total-receipts": "Quantidade de recibos registados e valor total",
    "marketing-analytics.kpi.tooltip.total-receipts-value": "Valor total de recibos registados",
    "marketing-analytics.kpi.tooltip.visits": "Quantidade de visitas a centros comerciais de clientes com a aplica\u00e7\u00e3o m\u00f3vel",
    "marketing-analytics.kpi.total-receipts": "Campanha- Recibos",
    "marketing-analytics.kpi.total-receipts-value": "Campanha- Valor dos recibos",
    "marketing-analytics.kpi.visits": "DMI- Visitas",
    "marketing-analytics.kpis.reference-campaign": "O valor de refer\u00eancia s\u00e3o os dados da campanha {{campaign}} que decorreu entre {{startDate}} e {{endDate}}",
    "marketing-analytics.kpis.reference-dates": "O valor de refer\u00eancia s\u00e3o os dados entre {{startDate}} e {{endDate}}",
    "marketing-analytics.report": "Relat\u00f3rio",
    "marketing-analytics.start-date-tooltip": "O intervalo de datas de campanha inclui os eventos durante a campanha e as a\u00e7\u00f5es anteriores.",
    "marketing-dossier": "Dossier de marketing",
    "match-pass": "Os campos de palavra-passe n\u00e3o coincidem",
    "max-image-size-1440-800": "O tamanho recomendado para a imagem do cabe\u00e7alho \u00e9 1440 x 800px.",
    "max-image-size-300-232": "O tamanho recomendado para a imagem da miniatura \u00e9 300 x 232px.",
    "maximumAge": "Idade m\u00e1xima",
    "may": "maio",
    "may-short": "maio",
    "menu.navbar.analytics": "An\u00e1lises",
    "menu.navbar.bi": "An\u00e1lises de mercado",
    "menu.navbar.bi.campaign.index": "Campanhas",
    "menu.navbar.bi.lineitem.index": "Rubricas",
    "menu.navbar.bi.service.index": "Servi\u00e7os",
    "menu.navbar.bi.supplier.index": "Fornecedores",
    "menu.navbar.bonus-form": "Formul\u00e1rio de b\u00f3nus",
    "menu.navbar.bonus-requests": "Pedidos de b\u00f3nus",
    "menu.navbar.category.new": "Nova categoria",
    "menu.navbar.category.show": "Categorias",
    "menu.navbar.center.new": "Novo centro",
    "menu.navbar.channel.show": "Canais",
    "menu.navbar.cms": "CMS",
    "menu.navbar.cms.commerce-stay-alert.index": "Estabelecimento comercial em alerta",
    "menu.navbar.configuration": "Configura\u00e7\u00e3o",
    "menu.navbar.configuration.catalog": "Cat\u00e1logo",
    "menu.navbar.configuration.center": "Informa\u00e7\u00f5es de centro",
    "menu.navbar.configuration.dashboards": "Pain\u00e9is",
    "menu.navbar.configuration.extra": "Atributos adicionais",
    "menu.navbar.configuration.influencezone": "Zonas de influ\u00eancia",
    "menu.navbar.configuration.module": "M\u00f3dulos",
    "menu.navbar.configuration.password": "Alterar palavra-passe",
    "menu.navbar.configuration.shop.import": "Importar lojas",
    "menu.navbar.configuration.zone": "Zonas",
    "menu.navbar.consumption.show": "Consumo",
    "menu.navbar.coupon.report": "Cup\u00f5es",
    "menu.navbar.crm": "CRM",
    "menu.navbar.customer.import": "Importar clientes",
    "menu.navbar.customer.show": "Clientes",
    "menu.navbar.customertype.show": "Tipos de cliente",
    "menu.navbar.desktop": "Ambiente de trabalho",
    "menu.navbar.email-template.show": "Modelos de e-mail",
    "menu.navbar.emailing": "Mailing",
    "menu.navbar.emailing.campaign.index": "Campanhas por e-mail",
    "menu.navbar.footfall": "Footfall",
    "menu.navbar.intranet": "Intranet",
    "menu.navbar.intranet.communication.index": "Comunica\u00e7\u00f5es",
    "menu.navbar.intranet.file.index": "Ficheiros",
    "menu.navbar.intranet.guard.index": "Guardas",
    "menu.navbar.intranet.sale.index": "Vendas",
    "menu.navbar.intranet.sales-year.index": "Demonstra\u00e7\u00f5es anuais de vendas",
    "menu.navbar.intranet.subtype.index": "Subtipos de comunica\u00e7\u00e3o",
    "menu.navbar.logout": "Terminar sess\u00e3o",
    "menu.navbar.marketing-analytics": "An\u00e1lises de marketing",
    "menu.navbar.marketing-analytics.campaign.index": "Campanhas",
    "menu.navbar.marketing-analytics.campaigntype.index": "Tipos de campanha",
    "menu.navbar.marketing-analytics.lineitem.index": "Rubricas",
    "menu.navbar.marketing-analytics.service.index": "Servi\u00e7os",
    "menu.navbar.marketing-analytics.supplier.index": "Fornecedores",
    "menu.navbar.playcenter.show": "Centro de jogos",
    "menu.navbar.proposal.new": "Nova proposta",
    "menu.navbar.proposal.show": "Propostas",
    "menu.navbar.proposal.show.active": "Propostas ativas",
    "menu.navbar.proposal.show.draft": "Propostas provis\u00f3rias",
    "menu.navbar.proposal.show.expired": "Propostas caducadas",
    "menu.navbar.proposal.show.pending": "Propostas pendentes",
    "menu.navbar.proposal.show.scheduled": "Propostas planeadas",
    "menu.navbar.push-notification.index": "Notifica\u00e7\u00f5es push",
    "menu.navbar.recommendation.show": "Recomenda\u00e7\u00f5es",
    "menu.navbar.residential": "Residencial",
    "menu.navbar.residential.campaigns.index": "Campanhas",
    "menu.navbar.residential.contacts": "Contactos",
    "menu.navbar.residential.developments.index": "Desenvolvimentos",
    "menu.navbar.residential.owners.index": "Propriet\u00e1rios",
    "menu.navbar.residential.properties.index": "Propriedades",
    "menu.navbar.shop.show": "Lista de lojas",
    "menu.navbar.staff.new": "Novo utilizador",
    "menu.navbar.staff.show": "Utilizadores",
    "menu.navbar.traffic.blacklist": "Utilizadores sem acompanhamento",
    "menu.navbar.traffic.show": "Tr\u00e1fego",
    "minimumAge": "Idade m\u00ednima",
    "monday": "segunda-feira",
    "monday-short": "seg",
    "month": "M\u00eas",
    "month.state": "Estado do m\u00eas",
    "monthly-report": "Relat\u00f3rio mensal",
    "mstatus": "Estado civil",
    "multi_family": "Multifam\u00edlia",
    "multimedia": "Multim\u00e9dia",
    "multimedia-files": "Ficheiros multimedia",
    "name": "Nome",
    "name-required": "O nome \u00e9 obrigat\u00f3rio",
    "name_required": "O nome n\u00e3o pode estar por preencher",
    "nationality": "Nacionalidade",
    "nationality_id": "Nacionalidade",
    "nephew": "Sobrinho",
    "new-staff": "Nova equipa",
    "new_folder": "Nova pasta",
    "nif": "NIF",
    "no": "N\u00e3o",
    "no-data": "N\u00e3o existem dados dispon\u00edveis para os filtros selecionados",
    "none": "Nenhum",
    "north": "Norte",
    "not-found": "N\u00e3o encontrado",
    "not_between": "n\u00e3o est\u00e1 entre",
    "not_contains": "n\u00e3o cont\u00e9m",
    "november": "novembro",
    "november-short": "nov",
    "occupation-percentage": "Percentagem de ocupa\u00e7\u00e3o",
    "october": "outubro",
    "october-short": "out",
    "on-signing-date": "Na data da celebra\u00e7\u00e3o",
    "open": "Aberto",
    "options_not_found": "Op\u00e7\u00f5es n\u00e3o encontradas",
    "or_less": "ou menos",
    "or_more": "ou mais",
    "other": "Outro",
    "other-data": "Outros dados",
    "others": "Outros",
    "overview": "Vis\u00e3o geral",
    "owner": "Propriet\u00e1rio",
    "owner-code": "C\u00f3digo do propriet\u00e1rio",
    "pager.out_of": "dentro de",
    "pass": "Palavra-passe",
    "pass-format": "Utilize 10 ou mais letras, com pelo menos 1 n\u00famero, 1 letra mai\u00fascula e 1 letra min\u00fascula",
    "pass-required": "Utilize 10 ou mais letras, com pelo menos 1 n\u00famero, 1 letra mai\u00fascula e 1 letra min\u00fascula",
    "passport": "Passaporte",
    "password": "Palavra-passe",
    "password-format": "Escolha uma palavra-passe forte que contenha pelo menos 10 caracteres, uma letra mai\u00fascula, uma letra min\u00fascula e um n\u00famero",
    "payment-plan": "Plano de pagamentos",
    "payslip": "Comprovativo de sal\u00e1rio",
    "pdf": "PDF",
    "pending": "Pendente",
    "person-type": "Tipo de pessoa",
    "phone": "Telefone",
    "physical-person": "Pessoa f\u00edsica",
    "pin": "PIN",
    "play-center-stays": "Centro de jogos",
    "play-center.time": "Hora",
    "play-center.tutor": "Tutor",
    "position": "Cargo",
    "postal-code": "C\u00f3digo postal",
    "powerpoint": "Powerpoint",
    "presentation": "Apresenta\u00e7\u00e3o",
    "properties": "Propriedades",
    "properties-number": "N\u00famero de propriedades",
    "property": "Propriedade",
    "property-filter.bathrooms_number": "Casas de banho",
    "property-filter.bedrooms_number": "Quartos",
    "property-filter.built_surface": "\u00c1rea constru\u00edda",
    "property-filter.city": "Cidade",
    "property-filter.price": "Pre\u00e7o",
    "property-filter.property_type": "Tipo de propriedade",
    "property-filter.province": "Prov\u00edncia",
    "property-filter.residential_development": "Projeto",
    "property-filter.toilets_number": "Sanit\u00e1rios",
    "property-filter.useful_surface": "\u00c1rea \u00fatil",
    "property-types": "tipos",
    "property.bathrooms-number": "Casas de banho",
    "property.bedrooms-number": "Quartos de dormir",
    "property.block": "Bloco",
    "property.built-surface": "\u00c1rea constru\u00edda",
    "property.buyer": "Comprador",
    "property.buyer-search": "Pesquisa de compradores",
    "property.cadastral-reference": "Refer\u00eancia cadastral",
    "property.common-zones-surface": "\u00c1rea de zonas comuns",
    "property.contact": "Contacto",
    "property.data": "Dados da propriedade",
    "property.detailed-spaces": "Espa\u00e7os detalhados",
    "property.doorway": "Entrada",
    "property.estate": "Propriedade",
    "property.floor": "Andar",
    "property.garages-number": "Garagens",
    "property.ladder": "Escada",
    "property.letter": "Letra",
    "property.name": "Nome da propriedade",
    "property.orientation": "Orienta\u00e7\u00e3o",
    "property.price": "Pre\u00e7o",
    "property.propertyType": "Tipo de propriedade",
    "property.reference": "Refer\u00eancia",
    "property.rental": "Pre\u00e7o do arrendamento",
    "property.reserved-date": "Data de reserva",
    "property.room.name": "Nome da sala",
    "property.room.select_type": "Selecione um tipo de sala",
    "property.room.surface": "\u00c1rea",
    "property.room.type": "Tipo",
    "property.select_orientation": "Selecionar...",
    "property.select_propertyState": "Selecione um estado de propriedade",
    "property.select_propertyType": "Selecione um tipo de propriedade",
    "property.select_residential-development": "Selecione um projeto",
    "property.state": "Estado",
    "property.storage-rooms-number": "Arrecada\u00e7\u00f5es",
    "property.technical-data": "Dados t\u00e9cnicos ",
    "property.terrace": "Terra\u00e7o\/Jardim",
    "property.toilets-number": "Sanit\u00e1rios",
    "property.useful-surface": "\u00c1rea \u00fatil",
    "property.user": "Utilizador",
    "proposal": "Proposta",
    "proposal.app-image": "Imagem da aplica\u00e7\u00e3o",
    "proposal.coupon": "Cup\u00e3o",
    "proposal.coupon-exchanges": "Trocas de cup\u00f5es",
    "proposal.coupon.limit": "Limite de cup\u00f5es",
    "proposal.custom-target": "Alvo personalizado",
    "proposal.edit": "Editar proposta",
    "proposal.end-date": "Data final",
    "proposal.end-datetime": "Data\/hora final",
    "proposal.event": "Evento",
    "proposal.event-end-event-datetime": "Data\/hora final do evento",
    "proposal.event-start-event-datetime": "Data\/hora inicial do evento",
    "proposal.export-feedback": "Selecionar tipo de coment\u00e1rio",
    "proposal.extra.text": "Texto",
    "proposal.featured": "Destaques",
    "proposal.filters": "Filtros",
    "proposal.gender": "Sexo",
    "proposal.general-data": "Dados gerais",
    "proposal.hide-home": "Ocultar casa",
    "proposal.new": "Nova proposta",
    "proposal.publish-on-app": "Publicar na Aplica\u00e7\u00e3o",
    "proposal.publish-on-web": "Publicar na Web",
    "proposal.segment-all": "Todos os utilizadores",
    "proposal.segment.list": "Lista de segmentos",
    "proposal.segments": "Segmentos",
    "proposal.settings": "Configura\u00e7\u00f5es",
    "proposal.start-date": "Data de in\u00edcio",
    "proposal.start-datetime": "Data\/hora de in\u00edcio",
    "proposal.store-search": "Procurar loja",
    "proposal.type": "Tipo",
    "proposal.web-image": "Imagem web",
    "province": "Prov\u00edncia",
    "province_id": "Prov\u00edncia",
    "publish": "Publicar",
    "published": "Publicado",
    "purchase-agreement": "Contrato de compra",
    "purchaser-type": "Tipo de comprador",
    "qr": "QR",
    "push-notification": "Notifica\u00e7\u00e3o push",
    "push-notification.action-data.proposal": "Proposta",
    "push-notification.action-type": "Tipo de a\u00e7\u00e3o",
    "push-notification.action-type.default": "Predefini\u00e7\u00e3o",
    "push-notification.action-type.proposal": "Proposta",
    "push-notification.active": "Ativa",
    "push-notification.active-app-users": "utilizadores ativos",
    "push-notification.advanced-options": "Op\u00e7\u00f5es avan\u00e7adas",
    "push-notification.archived": "Arquivado",
    "push-notification.campaign": "Campanha",
    "push-notification.daily": "Di\u00e1rio",
    "push-notification.date": "Data",
    "push-notification.draft": "Vers\u00e3o provis\u00f3ria",
    "push-notification.edit": "Editar",
    "push-notification.end-date": "Data final",
    "push-notification.end-datetime": "Data final",
    "push-notification.filters": "Filtros",
    "push-notification.frequency": "Frequ\u00eancia",
    "push-notification.general-data": "Dados gerais",
    "push-notification.hourly": "Hor\u00e1rio",
    "push-notification.image": "Imagem",
    "push-notification.image.info": "Esta imagem s\u00f3 ser\u00e1 mostrada na notifica\u00e7\u00e3o push se o tipo de a\u00e7\u00e3o selecionado for \"predefinido\".",
    "push-notification.message": "Mensagem",
    "push-notification.name": "Nome",
    "push-notification.new": "Nova notifica\u00e7\u00e3o push",
    "push-notification.on-end-visit": "No fim da visita",
    "push-notification.on-start-visit": "No in\u00edcio da visita",
    "push-notification.proposal-image.info": "As imagens das notifica\u00e7\u00f5es push n\u00e3o podem ter mais de 1 MB. Verifique o tamanho da imagem antes de publicar a notifica\u00e7\u00e3o push.",
    "push-notification.proposal-image.warning": "A imagem da proposta tem mais de 1 MB. Reduza o tamanho da imagem antes de publicar a notifica\u00e7\u00e3o push.",
    "push-notification.proposals": "Propostas",
    "push-notification.proposals-search": "Procurar proposta",
    "push-notification.scheduled": "Agendado",
    "push-notification.segment": "Segmento",
    "push-notification.segmentation": "Segmenta\u00e7\u00e3o",
    "push-notification.single-sending": "Envio \u00fanico",
    "push-notification.start-date": "Data de in\u00edcio",
    "push-notification.start-datetime": "Data de in\u00edcio",
    "push-notification.state": "Estado",
    "push-notification.status": "Estado",
    "push-notification.subject": "Assunto",
    "push-notification.weekly": "Semanal",
    "quality-specifications": "Especifica\u00e7\u00f5es de qualidade",
    "raffle": "Sorteio",
    "rating": "Avalia\u00e7\u00e3o",
    "receipt": "Recibo",
    "receipt.campaign": "Campanha",
    "receipt.create-receipt": "Criar recibo",
    "receipt.pending-balance": "Saldo pendente",
    "receipt.save-message": "O recibo foi devidamente guardado",
    "receipt.select-campaign": "Selecionar campanha",
    "receipt.select-campaigns": "Selecionar campanha",
    "receipt.stamps": "Selos",
    "receipt.stamps-number": "N\u00famero de selos",
    "receipt.tickets": "Bilhetes",
    "receipts": "Recibos",
    "receipts.can-not-generate-message": "O cliente n\u00e3o tem recibos resgat\u00e1veis",
    "receipts.redeem-receipt": "Resgatar recibos",
    "reference": "Refer\u00eancia",
    "reference-campaign": "Referenciar campanha",
    "reference-date": "Data de refer\u00eancia",
    "reference-value": "Valor de refer\u00eancia",
    "refresh": "Atualizar",
    "register-date": "Data de registo",
    "reject": "Rejeitar",
    "reject-close-month-modal.specify-reason": "Especifique o motivo para a loja",
    "reject-close-month-modal.title": "Rejei\u00e7\u00e3o do fecho de m\u00eas",
    "reject-publishing": "Rejeitar a publica\u00e7\u00e3o",
    "rejectPublish": "Rejeitar a publica\u00e7\u00e3o",
    "remove": "Eliminar",
    "rent": "Renda",
    "rent-price": "Pre\u00e7o da renda",
    "rental": "Arrendamento",
    "report": "Relat\u00f3rio",
    "request": "Pedido",
    "required": "{{attr}} \u00e9 obrigat\u00f3rio",
    "resend": "Reenviar",
    "reset-password": "Reinicializar palavra-passe",
    "residence_card": "Cart\u00e3o de resid\u00eancia",
    "residential-development": "Projeto residencial",
    "residential-development.development": "Projeto",
    "residential-development.multilanguage-files": "Ficheiros multi-idioma",
    "residential-documentation-sent.deed-warning-text": "Depois de introduzir a data da escritura, a oferta ser\u00e1 encerrada. J\u00e1 n\u00e3o poder\u00e1 modificar qualquer data",
    "residential-offer-close.rejected-common-zones": "Zonas comuns",
    "residential-offer-close.rejected-delivery-date": "Data de entrega",
    "residential-offer-close.rejected-other": "Outros",
    "residential-offer-close.rejected-other-reason": "Outros motivos n\u00e3o presentes na lista",
    "residential-offer-close.rejected-pay-form": "Formul\u00e1rio de pagamento",
    "residential-offer-close.rejected-price": "Pre\u00e7o",
    "residential-offer-close.rejected-project": "Projeto",
    "residential-offer-close.rejected-reasons": "Motivos para a rejei\u00e7\u00e3o",
    "residential-offer.accepted": "Aceite",
    "residential-offer.closing-notes": "Notas de encerramento",
    "residential-offer.closingDate": "Data de encerramento",
    "residential-offer.notes": "Notas",
    "residential-offer.open": "Abrir",
    "residential-offer.openingDate": "Data de abertura",
    "residential-offer.pending": "Pendente",
    "residential-offer.price": "Pre\u00e7o",
    "residential-offer.rejected": "Rejeitado\/a",
    "residential-offer.select_new_state": "Selecionar novo estado",
    "residential-offer.sent_info_dossier": "Dossi\u00ea",
    "residential-offer.sent_info_property_map": "Mapa da propriedade",
    "residential-offer.sent_info_qualities_note": "Nota de qualidades",
    "residential-offer.signed": "Assinado",
    "residential-offer.unique": "Unica",
    "residential-offers.price": "Pre\u00e7o",
    "residential-owner": "Propriet\u00e1rio residencial",
    "residential-properties.bedrooms": "Quartos",
    "residential-properties.location": "Localiza\u00e7\u00e3o",
    "residential-properties.price": "Pre\u00e7o",
    "residential-properties.property": "Propriedade",
    "residential-properties.rooms": "Quartos",
    "residential-properties.surface": "\u00c1rea",
    "residential-properties.type": "Tipo",
    "residential-property.booking-documentation": "Documenta\u00e7\u00e3o de reserva",
    "residential-property.booking-payment": "Pagamento de reserva",
    "residential-property.deed": "Escritura",
    "residential-property.edit": "Editar propriedade",
    "residential-property.monthly-payment-system": "Sistema de pagamento mensal",
    "residential-property.new": "Nova propriedade",
    "residential-property.state.blocked": "Bloqueada",
    "residential-property.state.free": "Dispon\u00edvel",
    "residential-property.state.reserved": "Reservada",
    "residential-property.state.sold": "Vendida",
    "residential-property.trading": "Em negocia\u00e7\u00e3o",
    "residential-proposal.close_offer": "Fechar oferta",
    "residential-request": "Pedido",
    "residential-room.type.bathroom": "Quarto de banho",
    "residential-room.type.bedroom": "Quarto",
    "residential-room.type.dressing-room": "Quarto de vestir",
    "residential-room.type.garage": "Garagem",
    "residential-room.type.hall": "Entrada",
    "residential-room.type.kitchen": "Cozinha",
    "residential-room.type.laundry-room": "Lavandaria",
    "residential-room.type.living-room": "Sala de estar",
    "residential-room.type.lobby": "Vest\u00edbulo",
    "residential-room.type.storage": "Arrecada\u00e7\u00e3o",
    "residential-room.type.toilet": "Sanit\u00e1rios",
    "residential-rooms-required": "O nome e o tipo de cada sala s\u00e3o obrigat\u00f3rios",
    "residential-sale-process.document_sent": "Documenta\u00e7\u00e3o enviada",
    "residential-user-state.accepted": "Aceite",
    "residential-user-state.new": "Novo",
    "residential-user-state.open": "Abrir",
    "residential-user-state.rejected": "Rejeitado\/a",
    "residential-user-state.request": "Pedido",
    "residential-user-state.signed": "Assinado",
    "residential.add_files_button": "Adicionar ficheiros",
    "residential.add_files_title": "Adicionar ficheiros?",
    "residential.add_link_button": "Adicionar liga\u00e7\u00e3o",
    "residential.add_video_link": "Adicionar URL de v\u00eddeo",
    "residential.campaign.edit": "Editar campanha",
    "residential.campaign.name": "Nome",
    "residential.campaign.new": "Nova campanha",
    "residential.community_expenses": "Despesas comunit\u00e1rias",
    "residential.development": "Projeto",
    "residential.development.buildingState": "Estado do edif\u00edcio",
    "residential.development.country-search": "Procurar por pa\u00eds",
    "residential.development.name": "Nome",
    "residential.development.owner": "Propriet\u00e1rio",
    "residential.development.province-search": "Procurar por prov\u00edncia",
    "residential.development.reference": "Refer\u00eancia de desenvolvimento",
    "residential.development.tu": "T.P.",
    "residential.development.zone": "Zona",
    "residential.development_maps": "Planta do projeto",
    "residential.developments": "Projetos",
    "residential.documentation": "Documenta\u00e7\u00e3o",
    "residential.dossiers": "Dossi\u00ea de marketing",
    "residential.end_of_construction": "Fim de constru\u00e7\u00e3o",
    "residential.energy_certification.a": "A",
    "residential.energy_certification.b": "B",
    "residential.energy_certification.c": "C",
    "residential.energy_certification.d": "D",
    "residential.energy_certification.e": "E",
    "residential.energy_certification.f": "F",
    "residential.energy_certification.g": "G",
    "residential.how_to_arrive_map": "Indica\u00e7\u00f5es \"Como chegar\"",
    "residential.images": "Imagens",
    "residential.in_construction": "Em constru\u00e7\u00e3o",
    "residential.maps": "Plantas",
    "residential.multimedia": "Ficheiros e multimedia",
    "residential.owner.code": "C\u00f3digo",
    "residential.owner.edit": "Editar propriet\u00e1rio residencial",
    "residential.owner.fiscal-name": "Nome fiscal",
    "residential.owner.logo": "Log\u00f3tipo",
    "residential.owner.name": "Nome",
    "residential.owner.new": "Novo propriet\u00e1rio residencial",
    "residential.owner.vat-number": "N\u00famero de IVA",
    "residential.post_sale": "P\u00f3s-venda",
    "residential.presale": "Pr\u00e9-venda",
    "residential.properties": "Propriedades",
    "residential.property": "Propriedade",
    "residential.property.development_documentation": "Documenta\u00e7\u00e3o do projeto",
    "residential.property.development_multimedia_files": "Ficheiros multimedia de desenvolvimento",
    "residential.property.reserved-date": "Data reservada",
    "residential.property.reserved-notes": "Notas",
    "residential.property_maps": "Plantas da propriedade",
    "residential.proposal.send-info": "Enviar informa\u00e7\u00f5es",
    "residential.proposal.send-info.error-sending-message": "Ocorreu um erro ao enviar o e-mail de informa\u00e7\u00f5es. Tente novamente mais tarde",
    "residential.proposal.send-info.error-updating-checks-message": "A proposta n\u00e3o foi devidamente carregada",
    "residential.proposal.send-info.success-message": "O e-mail de informa\u00e7\u00f5es foi devidamente enviado",
    "residential.rooms": "Salas",
    "residential.sale": "Venda",
    "residential.start_work": "In\u00edcio do trabalho",
    "residential.zone_services": "Servi\u00e7os de zona",
    "residential_offers.closing": "\u00daltima oferta",
    "residential_offers.documentation-procedure": "Procedimento de documenta\u00e7\u00e3o",
    "residential_offers.new": "Nova oferta",
    "residential_property": "Propriedade",
    "residential_proposal": "Proposta",
    "response": "Resposta",
    "reward": "Recompensa",
    "role": "Fun\u00e7\u00e3o",
    "sale": "Venda",
    "sale-process.add-request": "Adicionar pedido",
    "sale-process.closing": "A fechar",
    "sale-process.proposal": "Proposta",
    "sale-process.proposals": "Propostas",
    "sale-process.request.date": "Data",
    "sale-process.request.name": "Nome",
    "sale-process.request.properties-available": "Dispon\u00edvel",
    "sale-process.request.properties-blocked": "Bloqueado",
    "sale-process.requests": "Pedidos",
    "sale.amount": "Quantidade",
    "sale.concept": "Venda conceptual",
    "sale.date": "Data",
    "sale.new": "Nova venda",
    "sales": "vendas",
    "sales-year.new": "Nova demonstra\u00e7\u00e3o anual de vendas",
    "sales-year.show": "Mostrar demonstra\u00e7\u00e3o anual de vendas",
    "sales-years.confirm-shop-sales-years": "Confirmo que a demonstra\u00e7\u00e3o anual de vendas \u00e9 v\u00e1lida.",
    "sales.csv": "CSV",
    "sales.import-xlsx": "Importar XLSX",
    "sales.info": "Informa\u00e7\u00f5es",
    "sales.sale-concepts": "Conceitos de venda",
    "sales.upload-xlsx-error": "O ficheiro que carregou n\u00e3o se encontra num formato v\u00e1lido. Verifique o ficheiro e tente novamente.",
    "sales.upload-xlsx-success": "O XLSX foi devidamente carregado!",
    "sales.xlsx": "Xlsx",
    "sales.xlsx-format": "O XLSX deve ter o seguinte formato:",
    "sales.xlsx.amount-format": "O montante deve estar definido no Excel com o tipo \"moeda\".",
    "sales.xlsx.columns": "Deve ter tr\u00eas colunas, com a data na primeira coluna, a rubrica de vendas na segunda coluna e o montante na terceira. Na primeira linha deve constar o nome da coluna.",
    "sales.xlsx.concept-format": "A rubrica \u00e9 identificada pelo seu c\u00f3digo Yardi. Por defini\u00e7\u00e3o, este valor dever\u00e1 ser \"gm\".",
    "sales.xlsx.date-format": "A data deve estar definida no Excel com o tipo \"data\".",
    "sales.xlsx.download-example": "Transferir modelo",
    "same-period-last": "Mesmo per\u00edodo do \u00faltimo {{period}}",
    "saturday": "s\u00e1bado",
    "saturday-short": "s\u00e1b",
    "save": "Guardar {{modelName}}",
    "scmanager": "Gestor SC",
    "search": "Procurar",
    "second-property": "Segunda propriedade",
    "segment": "Segmento",
    "segment.filters-message-first-part": "Encontrar clientes que cumpram",
    "segment.filters-message-second-part": "as condi\u00e7\u00f5es seguintes:",
    "segment.not_found": "Segmentos n\u00e3o encontrados",
    "segment.total_users_reached": "Total de utilizadores alcan\u00e7ados",
    "segment.type_find": "Digite para encontrar segmentos",
    "segment.warning_update_users": "A campanha ser\u00e1 enviada diariamente aos novos utilizadores que verifiquem as condi\u00e7\u00f5es do segmento",
    "segments.filters_needed": "\u00c9 necess\u00e1rio pelo menos um dos filtros seguintes:",
    "segments.save_as": "Guardar como {{modelName}}",
    "segments.save_changes": "Guardar altera\u00e7\u00f5es",
    "segments.save_new": "Guardar como novo {{modelName}}",
    "segments.type_name": "Digite o nome de {{modelName}}",
    "segments.warning": "Aviso!",
    "select": "Selecionar",
    "select-building": "Selecionar edif\u00edcio",
    "select-center": "Selecionar centro",
    "select-channel": "Selecionar canal",
    "select-country": "Selecionar pa\u00eds",
    "select-level": "Selecionar n\u00edvel",
    "select-shop": "Selecionar loja",
    "select-state": "Selecionar estado",
    "select_all": "Selecionar tudo",
    "select_communication_required_attention": "Verificar sempre que a comunica\u00e7\u00e3o obrigue a atendimento urgente",
    "select_one_option": "Selecione uma op\u00e7\u00e3o",
    "sell-price": "Pre\u00e7o de venda",
    "send": "Enviar",
    "send-email-template.help.multipleEmails": "Introduza v\u00e1rios endere\u00e7os de e-mail separados por v\u00edrgulas",
    "send_economic_proposal": "Enviar proposta econ\u00f3mica",
    "send_email": "Enviar e-mail",
    "september": "setembro",
    "september-short": "set",
    "service": "Servi\u00e7o",
    "service.edit": "Editar servi\u00e7o",
    "service.new": "Novo servi\u00e7o",
    "services": "Servi\u00e7os",
    "services-and-qualities": "Servi\u00e7os e qualidades",
    "sex": "Sexo",
    "shop": "Loja",
    "shop-property": "Propriedade da loja",
    "shop-search": "Procurar loja",
    "shop.all": "Todos",
    "shopMonth.state.closed": "Fechados",
    "shopMonth.state.unclosed": "Abertos",
    "shopMonth.state.validated": "Validados",
    "shopMonth.state.without_sales": "Sem vendas",
    "shops": "Lojas",
    "shops.not_found": "N\u00e3o foram encontradas lojas",
    "show-children": "Mostrar filhos",
    "show-details": "Mostrar detalhes",
    "show-development": "Mostrar projeto",
    "show-price": "Mostrar pre\u00e7o",
    "since_last_visit": "Desde a \u00faltima visita (dias)",
    "single": "Single",
    "single_family": "Fam\u00edlia \u00fanica",
    "sold": "Vendido",
    "soluciona_errores": "Solu\u00e7\u00f5es de erros",
    "south": "Sul",
    "spending_receipts": "Recibos de gastos",
    "spreadsheet": "Folha de c\u00e1lculo",
    "staff-level-sales": "Vendas",
    "staff-level-services": "Servi\u00e7os",
    "staff-manager": "Gestores de equipa",
    "staff-member": "Membro da equipa",
    "staff-members": "Utilizadores",
    "staff-members.new": "Novo membro da equipa",
    "staff.level.admin": "Administrador",
    "staff.level.commerce": "Loja",
    "staff.level.crmmanager": "Gestor de CRM",
    "staff.level.crmoperator": "Operador de CRM",
    "staff.level.manager": "Gestor",
    "staff.level.owner": "Propriet\u00e1rio",
    "staff.level.scmanager": "Gestor SC",
    "staff.level.storeManager": "Gestor de loja",
    "staff.level.weboperator": "Operador web",
    "stamps": "Selos",
    "stamps.not-enough-stamps": "O cliente n\u00e3o disp\u00f5e de recibos suficientes para resgatar por selos",
    "stamps.preview-message": "Resumo de selos",
    "stars": "estrelas",
    "start-date": "Data de in\u00edcio",
    "state": "Estado",
    "status": "Estado",
    "storage-plan": "Planos para armazenamento",
    "storage-rooms-number": "N\u00famero de arrecada\u00e7\u00f5es",
    "store": "Loja",
    "store-manager": "Gestor de loja",
    "subtype": "Subtipo",
    "subtype.edit": "Editar subtipo",
    "subtype.name": "Nome",
    "subtype.new": "Novo subtipo",
    "subtype.type": "Tipo",
    "subway": "Metropolitano",
    "sunday": "domingo",
    "sunday-short": "dom",
    "supplier": "Fornecedor",
    "supplier-contact.filters": "Filtros",
    "supplier-contact.new": "Novo contacto do fornecedor",
    "supplier.edit": "Editar fornecedor",
    "supplier.new": "Novo fornecedor",
    "supplierRating": "Avalia\u00e7\u00e3o do fornecedor",
    "suppliers.active": "Ativo",
    "suppliers.filters": "Filtros",
    "suppliers.local": "Local",
    "suppliers.national": "Nacional",
    "suppliers.rating": "Avalia\u00e7\u00e3o",
    "surface": "\u00c1rea (m2)",
    "table-list.not_found": "N\u00e3o h\u00e1 elementos dispon\u00edveis",
    "technical-data": "Dados t\u00e9cnicos",
    "thecnical-data": "Dados t\u00e9cnicos",
    "threshold_active_check_in": "Limite para espera ativa (min)",
    "thumbnail": "Miniatura",
    "thursday": "quinta-feira",
    "thursday-short": "qui",
    "ticket-amount": "Montante",
    "ticket.campaign": "Campanha",
    "ticket.campaign-name": "Campanha",
    "ticket.code": "C\u00f3digo",
    "ticket.created-at": "Criado a",
    "ticket.generate-error-message": "Ocorreu um problema ao gerar os bilhetes, e estes n\u00e3o foram criados. Contacte a equipa de apoio.",
    "ticket.generate-success-message": "Os bilhetes foram devidamente gerados.",
    "ticket.label": "Regra",
    "ticket.loyalty-card": "Cart\u00e3o de fideliza\u00e7\u00e3o",
    "ticket.pending-balance": "Saldo pendente",
    "ticket.preview-message": "Resumo de bilhetes",
    "ticket.raffle": "Sorteio",
    "ticket.rule": "Regra",
    "ticket.tickets-number": "Bilhetes",
    "tickets.amount": "Montante",
    "tickets.code": "C\u00f3digo",
    "tickets.date": "Data",
    "tickets.not-enough-tickets": "O cliente n\u00e3o disp\u00f5e de recibos suficientes para resgatar por bilhetes",
    "tickets.resume": "Resumo",
    "tickets.salesman": "Vendedor",
    "tickets.shop-search": "Procurar loja",
    "tickets.shop-search.no-shops": "N\u00e3o foram encontradas lojas nas campanhas selecionadas",
    "title.communication.subtypes": "Subtipos de comunica\u00e7\u00e3o",
    "title.header.bonus-requests": "Solicita\u00e7\u00f5es de b\u00f3nus",
    "title.header.cms.commerce-stay-alerts": "Estabelecimento comercial em alerta",
    "title.header.cms.commerces": "Lojas",
    "title.header.communication": "Comunica\u00e7\u00f5es",
    "title.header.configuration": "Configura\u00e7\u00e3o",
    "title.header.configuration.dashboard": "Configura\u00e7\u00e3o do painel",
    "title.header.configuration.staff-members": "Membros da equipa",
    "title.header.crm.email-templates": "Modelos de e-mail",
    "title.header.crm.users": "Clientes",
    "title.header.dashboards.show": "Painel",
    "title.header.desktop": "\u00c1rea de trabalho",
    "title.header.emailing.campaigns": "Campanhas por e-mail",
    "title.header.files": "Gest\u00e3o de ficheiros",
    "title.header.footfall": "Footfall",
    "title.header.guards": "Guardas",
    "title.header.intranet-sale": "Vendas",
    "title.header.intranet.sales-years": "Demonstra\u00e7\u00e3o anual de vendas",
    "title.header.marketing-analytics.campaignActions": "A\u00e7\u00f5es de campanha",
    "title.header.marketing-analytics.campaignGoals": "Objetivos de campanha",
    "title.header.marketing-analytics.campaignRules": "Exce\u00e7\u00f5es ao sorteio",
    "title.header.marketing-analytics.campaigns": "Campanhas",
    "title.header.marketing-analytics.campaigntypes": "Tipos de campanha",
    "title.header.marketing-analytics.lineitems": "Rubricas",
    "title.header.marketing-analytics.services": "Servi\u00e7os",
    "title.header.marketing-analytics.suppliercontacts": "Contactos de fornecedores",
    "title.header.marketing-analytics.suppliers": "Fornecedores",
    "title.header.proposals": "Propostas",
    "title.header.push-notifications": "Notifica\u00e7\u00f5es push",
    "title.header.residential.campaigns": "Campanhas",
    "title.header.residential.developments": "Projetos",
    "title.header.residential.owners": "Propriet\u00e1rios",
    "title.header.residential.properties": "Propriedades",
    "title.header.sales": "Gest\u00e3o de vendas",
    "title.header.subtypes": "Tipos de comunica\u00e7\u00e3o",
    "title.header.testing": "Cabe\u00e7alho de teste",
    "token": "C\u00f3digo",
    "total": "Total",
    "total_sales": "Vendas totais",
    "train": "Comboio",
    "tuesday": "ter\u00e7a-feira",
    "tuesday-short": "ter",
    "type": "Tipo",
    "unit-type": "Tipo de unidade",
    "unpublish": "Cancelar a publica\u00e7\u00e3o",
    "upcoming-commercialization": "Comercializa\u00e7\u00e3o futura",
    "update-password.server-problem": "Ocorreu um problema com o servidor. Contacte a equipa de apoio",
    "upload": "Carregar",
    "urgent": "Urgente",
    "useful-surface": "\u00c1rea \u00fatil",
    "user": "Utilizador",
    "user-error": "Algo correu mal. Verifique os seguintes erros: ",
    "user.actions": "A\u00e7\u00f5es",
    "user.address": "Endere\u00e7o",
    "user.bday-days": "Data de anivers\u00e1rio (dias)",
    "user.birthdate": "Data de nascimento",
    "user.campaigns": "Campanhas",
    "user.can-access-bonus-request": "Pode aceder ao pedido de b\u00f3nus",
    "user.channels": "Canais",
    "user.child-bday": "Anivers\u00e1rio dos filhos (dias)",
    "user.children_number": "N\u00famero de filhos",
    "user.city": "Cidade",
    "user.code": "C\u00f3digo de cliente",
    "user.company": "Empresa",
    "user.countries": "Pa\u00edses",
    "user.cp": "C\u00f3digo postal",
    "user.created_at": "Data de inscri\u00e7\u00e3o{{mandatory}}",
    "user.dni": "N\u00famero de IVA",
    "user.document_type": "Tipo de documento",
    "user.electronic_communications_phrase": "Pretendo receber comunica\u00e7\u00f5es eletr\u00f3nicas comerciais",
    "user.email": "E-mail",
    "user.gdpr_phrase": "Concordo com o RGPD",
    "user.general_data": "Dados gerais",
    "user.in-campaign": "Em campanha",
    "user.large-family": "Fam\u00edlia numerosa",
    "user.last_name": "Apelido",
    "user.manual-campaigns": "Campanhas manuais",
    "user.marital_status": "Estado civil",
    "user.marketing_data": "Marketing",
    "user.most-purchased-subfamily": "Subfam\u00edlia mais adquirida (dias)",
    "user.name": "Nome",
    "user.nationality": "Nacionalidade",
    "user.nationality-search": "Procurar nacionalidade",
    "user.no-assigned-campaign-message": "N\u00e3o existem campanhas segmentadas atribu\u00eddas",
    "user.no-assigned-segment-message": "N\u00e3o existem segmentos atribu\u00eddos",
    "user.notes": "Notas",
    "user.number-receipt-days": "N\u00famero de recibos (\u00faltimos dias)",
    "user.other_data": "Outros dados",
    "user.partners_electronic_communications_phrase": "Pretendo receber comunica\u00e7\u00f5es eletr\u00f3nicas comerciais de terceiros",
    "user.person_type": "Tipo de pessoa",
    "user.phone": "Telefone",
    "user.province": "Prov\u00edncia",
    "user.province-search": "Procurar prov\u00edncia",
    "user.purchaser-type": "Tipo de comprador",
    "user.redeemable-stamps": "Selos resgat\u00e1veis",
    "user.redeemable-stamps-range": "Selos resgat\u00e1veis (intervalo)",
    "user.reference-building": "Edif\u00edcio de refer\u00eancia",
    "user.reference-commerce": "Loja de refer\u00eancia",
    "user.secondary_phone": "Telefone secund\u00e1rio",
    "user.segment": "Segmento",
    "user.segmented-campaigns": "Campanhas segmentadas atribu\u00eddas automaticamente",
    "user.select_document_type": "Selecionar tipo de documento",
    "user.select_marital_status": "Selecionar estado civil",
    "user.select_sex": "Selecionar sexo",
    "user.seniority": "Selecionar antiguidade",
    "user.sex": "Sexo",
    "user.spending-receipt-days": "Recibos de gastos (\u00faltimos dias)",
    "user.user-segments": "Segmentos de utilizador",
    "user.user-types": "Tipos de utilizador",
    "user_types": "Tipos de utilizador",
    "users.amount": "Montante",
    "users.campaigns": "Campanhas",
    "users.code": "C\u00f3digo",
    "users.commerce": "Loja",
    "users.date": "Data",
    "users.edit": "Editar cliente",
    "users.general_data": "Dados gerais",
    "users.loyalty": "Fideliza\u00e7\u00e3o",
    "users.new": "Novo cliente",
    "users.not-has-email-warning-message": "\u00c9 recomend\u00e1vel adicionar o e-mail do utilizador",
    "users.receipts": "Recibos",
    "users.redeemable-receipt": "O recibo \u00e9 resgat\u00e1vel",
    "users.sale_process": "Processo de venda",
    "users.warning": "Aviso",
    "usertypes": "Tipos de utilizador",
    "value": "Valor",
    "vat-number": "N\u00famero de IVA",
    "vat_number": "N\u00famero de identifica\u00e7\u00e3o",
    "video": "V\u00eddeo",
    "visibility": "Visibilidade",
    "visit_building": "Visitar edif\u00edcio",
    "visits": "Visitas",
    "visits.recurrence": "Frequ\u00eancia",
    "want_continue": "Confirma que pretende continuar?",
    "want_duplicate": "Confirma que pretende duplicar o {{modelName}} {{name}}?",
    "want_expire": "Confirma que pretende cessar o {{modelName}} {{name}}?",
    "want_publish": "Confirma que pretende publicar este {{modelName}} {{name}}?",
    "want_reject_publish": "Confirma que pretende rejeitar a publica\u00e7\u00e3o deste {{modelName}} {{name}}?",
    "want_remove": "Confirma que pretende eliminar este {{modelName}}?",
    "want_request": "Confirma que pretende solicitar a publica\u00e7\u00e3o deste {{modelName}} {{name}}?",
    "want_save_without_gdpr_or_electronic_communications": "N\u00e3o selecionou os termos e condi\u00e7\u00f5es nem as caixas relativas a comunica\u00e7\u00f5es eletr\u00f3nicas; pretende continuar?",
    "want_unpublish": "Confirma que pretende anular a publica\u00e7\u00e3o deste {{modelName}} {{name}}?",
    "warning": "Aviso",
    "warning-residential-change-text": "As altera\u00e7\u00f5es que vai efetuar podem afetar servi\u00e7os externos. Confirma que pretende continuar?",
    "waterkmark": "Marca de \u00e1gua",
    "web": "Web",
    "web-image": "Imagem web",
    "web-operator": "Operador web",
    "wednesday": "quarta-feira",
    "wednesday-short": "qua",
    "week": "Semana",
    "weekday.friday": "sexta-feira",
    "weekday.monday": "segunda-feira",
    "weekday.saturday": "s\u00e1bado",
    "weekday.sunday": "domingo",
    "weekday.thursday": "quinta-feira",
    "weekday.tuesday": "ter\u00e7a-feira",
    "weekday.wednesday": "quarta-feira",
    "west": "Oeste",
    "wizard.next_step": "Pr\u00f3ximo",
    "wizard.prev_step": "Anterior",
    "woman": "Mulher",
    "yardi": "Yardi",
    "yardi-code": "C\u00f3digo Yardi",
    "yardi-upload": "Carregar no Yardi",
    "yardi.continue_export": "Continuar a exporta\u00e7\u00e3o",
    "yardi.shop_property_error": "A loja precisa de estar vinculada a uma propriedade.",
    "yardi.tenant_code_error": "A loja precisa de um c\u00f3digo de inquilino espec\u00edfico.",
    "yardi.warn_errors_exporting": "Existem erros na exporta\u00e7\u00e3o. Consulte-os antes de continuar.",
    "year": "Ano",
    "yen": "Ienes \u00a5",
    "yes": "Sim",
    "youtube.url": "URL YouTube",
    "zip-code": "C\u00f3digo postal",
    "zone": "Zona",
    "zone-1": "Zona 1",
    "zone-2": "Zona 2",
    "zone-3": "Zona 3",
    "zone-4": "Zona 4",
    "zone-services": "Servi\u00e7os zonais",
    "select_month": "Selecione um m\u00eas",
    "select_year": "Selecione um ano",
    "legal_person": "Pessoa jur\u00eddica",
    "residential.multimedia_files": "Ficheiros multimedia",
    "segments.save_new_segment": "Guardar como novo segmento",
    "save_segment": "Guardar segmento",
    "segments.save_as_segment": "Guardar como segmento",
    "map-description": "Descri\u00e7\u00e3o do mapa",
    "costa": "Costa",
    "available-premise": "Loja dispon\u00edvel",
    "user.age": "Idade",
    "users.age.under-35": "Menos de 35",
    "users.age.35-45": "35-45",
    "users.age.50-65": "50-65",
    "users.age.over-65": "Mais de 65",
    "user.select_age": "Selecionar idade",
    "users.nationality.spanish": "Espanhol",
    "users.nationality.foreigner": "Estrangeiro",
    "user.select_nationality": "Selecionar nacionalidade",
    "user.civil_status": "Estado civil",
    "user.select_civil_status": "Selecionar estado civil",
    "users.civil-status.single": "Solteiro",
    "users.civil-status.married": "Casado",
    "users.civil-status.divorced": "Divorciado",
    "users.civil-status.widower": "Vi\u00favo",
    "user.foreign_nationality": "Nacionalidade estrangeira",
    "user.select_foreign_nationality": "Selecionar nacionalidade estrangeira",
    "users.foreign-nationality.french": "Franc\u00eas",
    "users.foreign-nationality.portuguese": "Portugu\u00eas",
    "users.foreign-nationality.italian": "Italiano",
    "users.foreign-nationality.united-kingdom": "Reino Unido",
    "users.foreign-nationality.german": "Alem\u00e3o",
    "users.foreign-nationality.mexican": "Mexicano",
    "users.foreign-nationality.brazilian": "Brasileiro",
    "user.lead_origin": "Origem do lead",
    "user.select_lead_origin": "Selecionar origem do lead",
    "users.lead-origin.web_cbre": "Web CBRE",
    "users.lead-origin.landing_origami": "Landing promoção",
    "users.lead-origin.valla": "Valla",
    "users.lead-origin.referenced": "Referenciado",
    "users.lead-origin.event": "Evento",
    "users.lead-origin.social_network": "Rede social",
    "users.lead-origin.emailing": "E-mailing",
    "users.lead-origin.developer_website": "Site do promotor",
    "users.lead-origin.press": "Imprensa",
    "users.lead-origin.selling_point": "Ponto de venda",
    "users.lead-origin.idealista": "Idealista",
    "user.typology": "Tipologia",
    "user.select_typology": "Selecionar tipologia",
    "users.typology.multi-family": "Muitas famílias",
    "users.typology.single-family": "Família única",
    "user.reason_for_purchase": "Motivo da compra",
    "user.select_reason_for_purchase": "Selecionar motivo da compra",
    "users.reason-for-purchase.first-home": "Primeira casa",
    "users.reason-for-purchase.second-home": "Segunda casa",
    "users.reason-for-purchase.investment": "Investimento",
    "users.reason-for-purchase.replacement": "Substituição",
    "user.state": "Estado",
    "user.select_state": "Selecionar estado",
    "users.state.monitoring": "Monitorização",
    "users.state.discarded": "Descartado",
    "users.state.pre-registration": "Pré-registo",
    "users.state.incorporation": "Incorporação",
    "users.state.writing": "Escrita",
    "user.visit": "Visita",
    "user.source_of_funds": "Fonte de fundos",
    "user.reason_for_discard": "Motivo de descarte",
    "user.select_reason_for_discard": "Selecionar motivo de descarte",
    "users.reason-for-discard.location": "Localização",
    "users.reason-for-discard.price": "Preço",
    "users.reason-for-discard.surface": "Superfície",
    "users.reason-for-discard.deadline": "Prazo",
    "users.reason-for-discard.buy_competition": "Concorrência de compra",
    "users.reason-for-discard.other": "Outro",
    "users.source-of-funds.own": "Próprio",
    "users.source-of-funds.loan": "Empréstimo",
    "user.select_source_of_funds": "Selecionar fonte de fundos",
    "user.number_of_rooms": "Número de quartos",
    "user.select_number_of_rooms": "Selecionar número de quartos",
    "users.rooms.one": "1",
    "users.rooms.two": "2",
    "users.rooms.tree": "3",
    "users.rooms.four": "4",
    "users.rooms.five": "5",
    "property.add_residential-development": "Adicionar projeto residencial",
    "crm.users.created-at": "Criado a",
}
;