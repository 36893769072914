import template from 'neuro-frontend/templates/components/light-table/cells/material-icon';
import { alias } from '@ember/object/computed';
import { layout } from '@ember-decorators/component';
import {
  computed, get, defineProperty, computed as computedFunc,
} from '@ember/object';

import MaterialIcon from './material-icon';

@layout(template)
export default class extends MaterialIcon {
  classNames = ['nf-table__tooltip']

  @alias('materialIcon') icon

  @computed('column.tooltip', 'row.content')
  get tooltip() {
    return get(this, `row.content.${get(this, 'column.tooltip')}`);
  }

  @computed
  get value() {
    return null;
  }

  init(...args) {
    super.init(...args);

    defineProperty(
      this,
      'hide',
      computedFunc('tooltip', function () {
        return !this.tooltip;
      }),
    );
  }
}
