export default Object.freeze([
  {
    color: '#ADD8E6',
    label: i18n.t('file.roles.all'),
    levels: ['admin', 'manager', 'scmanager'],
    value: 'all',
  },
  {
    color: '#80cbc4',
    label: i18n.t('file.roles.commerce'),
    levels: ['admin', 'manager', 'scmanager', 'commerce'],
    value: 'commerce',
  },
  {
    color: '#EF9A9A',
    label: i18n.t('file.roles.management'),
    levels: ['admin', 'manager', 'scmanager'],
    value: 'management',
  },
]);
