import { hash } from 'rsvp';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

import FormRoute from '../../form';

export default class extends FormRoute {
  @service auth

  templateName = 'configuration/staff-members/new'

  async model({ 'staff-member_id': staffMemberId }) {
    const store = get(this, 'store');
    const staffMember = await staffMemberId
      ? store.findRecord(
        'staffMember',
        staffMemberId,
      )
      : store.createRecord('staffMember');

    return hash({
      buildings: store.findAll('building').catch(() => []),
      centers: store.findAll('center'),
      channels: store.findAll('channel').catch(() => []),
      commerces: [],
      countries: store.findAll('country'),
      dashboards: store.findAll('dashboard'),
      staffMember,
    });
  }

  setupController(ctrl, model) {
    super.setupController(ctrl, model);

    if (!model.staffMember.isNew) {
      set(ctrl, 'selectedCommerces', model.staffMember.commerces.toArray());
    }
  }
}
