import { get, computed } from '@ember/object';
import ModuleResidential from './module-residential';

export default ModuleResidential.extend({
  canCreate: computed('auth.user.level', function () {
    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(get(this, 'auth.user.level'));
  }),

  canDelete: computed('auth.user.level', function () {
    return get(this, 'canAccess')
      && ['admin', 'manager'].includes(get(this, 'auth.user.level'));
  }),

  canRead: computed('auth.user.level', function () {
    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(get(this, 'auth.user.level'));
  }),
});
