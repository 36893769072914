import { get, computed } from '@ember/object';

import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
    @attr('date') bdate

    @attr('boolean') isInPlayCenter

    @attr('string', { defaultValue: '' }) lastName

    @attr('string') name

    @attr('string') notes

    @attr('string') relationship

    @attr('string') sex

    @belongsTo('childStay') lastChildStay

    @belongsTo('user') user

    @computed('name', 'lastName')
    get fullName() {
      return `${get(this, 'name')} ${get(this, 'lastName') || ''}`;
    }

    @computed('user.dni')
    get hasVatNumber() {
      return Boolean(get(this, 'user.dni'));
    }

    @computed()
    get validations() {
      return {
        name: {
          presence: {
            message: get(this, 'i18n').t('error.child.name.required'),
          },
        },
        relationship: {
          presence: {
            message: get(this, 'i18n').t('error.child.relationship.required'),
          },
        },
        sex: {
          presence: {
            message: get(this, 'i18n').t('error.child.sex.required'),
          },
        },
        bdate: {
          presence: {
            message: get(this, 'i18n').t('error.child.bdate.required'),
          },
        },
      };
    }
}
