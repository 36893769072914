import Model from 'ember-data/model';
import { get, computed } from '@ember/object';
import { attr, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('string') actionType

  @attr('datetime') doneDatetime

  @attr('string') notes

  @attr('datetime') scheduledDatetime

  @attr('string') title

  @belongsTo('staffMember') staff

  @belongsTo('user') user

  @computed('scheduledDatetime', 'doneDatetime')
  get datetime() {
    const doneDatetime = get(this, 'doneDatetime');
    const scheduledDatetime = get(this, 'scheduledDatetime');

    return doneDatetime || scheduledDatetime || null;
  }

  @computed('doneDatetime')
  get isDone() {
    return !!get(this, 'doneDatetime');
  }

  @computed()
  get validations() {
    return {
      actionType: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('actions.action_type') }),
        },
      },

      title: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('actions.title') }),
        },
      },
    };
  }
}
