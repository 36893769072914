import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default class extends Component {
  classNames = ['nf-kpi']

  @computed('value')
  get mainValue() {
    const value = get(this, 'value');
    if (undefined !== value) {
      return Number.isFinite(value)
        // We're using this instead of toFixed cause it adds decimal number even if value is integer
        ? `${Math.round(value * 100) / 100}`
        : value;
    }
    return '--';
  }
}
