import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  activeEmailUsers() {
    return faker.random.number();
  },

  registeredEmailUsers() {
    return faker.random.number();
  },

  registeredUsers() {
    return faker.random.number();
  },

  segmentName() {
    return faker.random.word();
  },
  segmentType() {
    return faker.random.arrayElement([
      'and',
      'or',
    ]);
  },

  visible() {
    return faker.random.boolean();
  },

  center: association(),

});
