import { computed, get } from '@ember/object';

import ModulePlaycenter from './module-playcenter';

export default class extends ModulePlaycenter {
  @computed('model.isInPlayCenter', 'model.hasVatNumber')
  get canCheckIn() {
    const {
      model,
    } = this;

    return get(model, 'hasVatNumber') && !model.isInPlayCenter;
  }

  @computed('model.isInPlayCenter', 'model.hasVatNumber')
  get canCheckOut() {
    const {
      model,
    } = this;

    return get(model, 'hasVatNumber') && model.isInPlayCenter;
  }

  @computed('model.isInPlayCenter', 'model.user', 'model.user.dni')
  get canShow() {
    const {
      model,
    } = this;

    return model.isInPlayCenter;
  }
}
