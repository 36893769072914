import { computed, get } from '@ember/object';

import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('string') description

  @attr('string') email

  @attr('string') name

  @attr('string') phone

  @attr('string') role

  // Relationships

  @belongsTo('supplier') supplier

  @computed()
  get validations() {
    return {
      email: {
        presence: {
          if: () => !get(this, 'phone'),
          message: get(this, 'i18n').t('required', { attr: i18n.t('email') }),
        },
        email: {
          if: () => get(this, 'email'),
        },
      },

      phone: {
        presence: {
          if: () => !get(this, 'email'),
          message: get(this, 'i18n').t('required', { attr: i18n.t('phone') }),
        },
      },

      name: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('name') }),
        },
      },

      supplier: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('supplier') }),
        },
      },
    };
  }
}
