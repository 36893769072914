import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  pageHeader: service(),

  beforeModel() {
    // Changes title header of the page
    this.get('pageHeader').set(
      'title',
      this.get('i18n').t('title.header.configuration'),
    );
  },
});
