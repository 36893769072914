export default Object.freeze([
  {
    label: i18n.t('residential-room.type.bedroom'),
    value: 'bedroom',
  },
  {
    label: i18n.t('residential-room.type.toilet'),
    value: 'toilet',
  },
  {
    label: i18n.t('residential-room.type.bathroom'),
    value: 'bathroom',
  },
  {
    label: i18n.t('residential-room.type.lobby'),
    value: 'lobby',
  },
  {
    label: i18n.t('residential-room.type.hall'),
    value: 'hall',
  },
  {
    label: i18n.t('residential-room.type.dressing-room'),
    value: 'dressing_room',
  },
  {
    label: i18n.t('residential-room.type.living-room'),
    value: 'living_room',
  },
  {
    label: i18n.t('residential-room.type.kitchen'),
    value: 'kitchen',
  },
  {
    label: i18n.t('residential-room.type.laundry-room'),
    value: 'laundry_room',
  },
  {
    label: i18n.t('residential-room.type.garage'),
    value: 'garage',
  },
  {
    label: i18n.t('residential-room.type.storage'),
    value: 'storage',
  },
]);
