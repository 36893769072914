export default Object.freeze([
  {
    label: i18n.t('residential.presale'),
    value: 'presale',
  },
  {
    label: i18n.t('residential.sale'),
    value: 'sale',
  },
  {
    label: i18n.t('residential.post_sale'),
    value: 'post_sale',
  },
]);
