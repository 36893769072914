import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  name() {
    return faker.company.companyName();
  },

  residentialOwner: association(),

  city() {
    return faker.address.city();
  },

  province: association(),

  buildingState() {
    return faker.random.arrayElement([
      'previous',
      'start_work',
      'in_construction',
      'end_of_construction',
    ]);
  },

  propertiesNumber() {
    return faker.random.number();
  },
});
