import { set } from '@ember/object';

import ENV from '../config/environment';
//
// Needed to make ember-cli-mirage work and have a mocked backend.
//
// I know it's a batched job, but it works.
//
export function initialize() {
  set(
    window,
    'i18n',
    {
      t(str) {
        return str;
      },
    },
  );
}

export default {
  before: ENV['ember-cli-mirage'].enabled ? 'ember-cli-mirage' : null,
  name: 'mock-i18n',
  initialize,
};
