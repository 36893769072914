const templates = [
  {
    type: 'send_user_registration_email',
    label: i18n.t('email-template.type.send_user_registration_email'),
  },
  {
    type: 'send_user_reset_password_email',
    label: i18n.t('email-template.type.send_user_reset_password_email'),
  },
  {
    type: 'send_user_activate_email',
    label: i18n.t('email-template.type.send_user_activate_email'),
  },
  {
    type: 'send_user_child_birthday_reminder',
    label: i18n.t('email-template.type.send_user_child_birthday_reminder'),
  },
  {
    type: 'send_reprint_tickets',
    label: i18n.t('email-template.type.send_reprint_tickets'),
  },
  {
    type: 'send_proposals_report',
    label: i18n.t('email-template.type.send_proposals_report'),
  },
  {
    type: 'send_user_child_birthday_visit_ack',
    label: i18n.t('email-template.type.send_user_child_birthday_visit_ack'),
  },
  {
    type: 'send_contact',
    label: i18n.t('email-template.type.send_contact'),
  },
  {
    type: 'send_intranet_communication_email',
    label: i18n.t('email-template.type.send_intranet_communication_email'),
  },
  {
    type: 'send_staff_connection_warning',
    label: i18n.t('email-template.type.send_staff_connection_warning'),
  },
  {
    type: 'send_staff_reset_password_email',
    label: i18n.t('email-template.type.send_staff_reset_password_email'),
  },
  {
    type: 'send_user_unsubscribe_email',
    label: i18n.t('email-template.type.send_user_unsubscribe_email'),
  },

];

export default function getLabelMailTemplate(emailType) {
  if ('string' !== typeof emailType) {
    throw new TypeError(`getLabelMailTemplate(): param 'emailType' with value ${emailType} invalid`);
  }
  const template = templates.find((tpl) => emailType === tpl.type);
  return (template ? template.label : i18n.t(emailType)).toString();
}
