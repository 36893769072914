import { get, computed } from '@ember/object';

import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { Ability } from 'ember-can';

export default class extends Ability {
  @service auth

  @alias('auth.user') user

  @computed('user.level', 'user.dashboards')
  get canAccess() {
    if ('admin' === get(this, 'user.level')) {
      return true;
    }

    return get(this, 'user.dashboards')
      && 0 < get(this, 'user.dashboards.length');
  }
}
