import Table from 'ember-light-table';
import {
  action, computed, get, set,
} from '@ember/object';

import residentialOfferStates from 'neuro-frontend/enums/residential-offer-states';

import TableList from './table-list';

export default class extends TableList {
  classNames = ['table-list', 'table-sale-process']

  // @TODO set in table-list component
  didUpdateAttrs() {
    this._super();

    set(
      this,
      'table',
      new Table(
        get(this, 'table.columns'),
        get(this, 'model'),
        {
          enableSync: true,
        },
      ),
    );
  }

  @computed
  get columns() {
    return [
      {
        label: get(this, 'i18n').t('residential-properties.location'),
        valuePath: 'residentialProperty.residentialDevelopment.city',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('residential-development.development'),
        valuePath: 'residentialProperty.residentialDevelopment.name',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('residential-properties.property'),
        valuePath: 'residentialProperty.block',
        sortable: false,
      },
      {
        valuePath: 'residentialProperty.floor',
        sortable: false,
      },
      {
        valuePath: 'residentialProperty.letter',
        sortable: false,
      },
      {
        cellType: 'surface',
        label: get(this, 'i18n').t('residential-properties.surface'),
        valuePath: 'residentialProperty.usefulSurface',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('residential-properties.rooms'),
        valuePath: 'residentialProperty.residentialRooms.length',
        sortable: false,
      },
      {
        cellType: 'amount',
        currency: get(this, 'currency'),
        label: get(this, 'i18n').t('residential-properties.price'),
        valuePath: 'residentialProperty.price',
        sortable: false,
      },
      {
        cellType: 'tag',
        enum: residentialOfferStates,
        valuePath: 'state',
      },
      {
        align: 'center',
        cellAction: 'createOffer',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'monetization_on',
        resource: 'residential-proposal',
        sortable: false,
        tooltip: get(this, 'i18n').t('send_economic_proposal'),
        width: '4%',
      },
      {
        align: 'center',
        cellAction: 'closeOffer',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'flag',
        resource: 'residential-proposal',
        sortable: false,
        tooltip: get(this, 'i18n').t('residential-proposal.close_offer'),
        width: '4%',
      },
    ];
  }

  @computed
  get tableActions() {
    return {
      closeOffer: (row) => {
        const onShowCloseOffer = get(this, 'onShowCloseOffer');

        if ('function' === typeof onShowCloseOffer) {
          onShowCloseOffer(row.content);
        }
      },

      createOffer: (...args) => {
        this.send('showCreateOffer', ...args);
      },
    };
  }

  @action
  showCreateOffer(row) {
    const onShowCreateOffer = get(this, 'onShowCreateOffer');

    if ('function' === typeof onShowCreateOffer) {
      onShowCreateOffer(row.content);
    }
  }
}
