import { computed, get } from '@ember/object';
import Cell from 'ember-light-table/components/cells/base';

import shopMonthStates from 'neuro-frontend/enums/shop-month-states';

export default Cell.extend({
  elem: computed('row.content', 'column.month', function () {
    const model = get(this, 'row.content');
    const month = get(this, 'column.month');
    const monthState = model && month
      ? model.getMonthState(month)
      : 'closed';

    return shopMonthStates.find((state) => monthState === get(state, 'value'));
  }),
});
