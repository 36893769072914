import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set, get, computed } from '@ember/object';
import Table from 'ember-light-table';

export default Component.extend({
  i18n: service(),

  classNames: ['table-list'],

  layoutName: 'components/table-list',

  isOpenModal: false,

  isStriped: true,

  rowToDelete: false,

  showPaginator: true,

  multiSelect: false,

  canSelect: true,

  tableActions: computed('onEdit', 'extraActions', function () {
    return {
      delete: (...args) => {
        this.send('showDeleteModal', ...args);
      },
      edit: this.onEdit,
      ...get(this, 'extraActions'),
    };
  }),

  table: computed('model', 'columns', function () {
    const model = get(this, 'model');
    const columns = get(this, 'columns');

    return new Table(
      columns,
      model,
      {
        enableSync: true,
      },
    );
  }),

  actions: {
    actionToSelected() {
      if ('function' === typeof get(this, 'onActionToSelected')) {
        const models = get(this, 'table.selectedRows').map((row) => row.content);
        get(this, 'onActionToSelected')(models);
      }
    },

    columnClicked(column) {
      if (column.sorted) {
        get(this, 'onColumnClicked')({
          sort: `${column.ascending ? '' : '-'}${get(column, 'valuePath').dasherize()}`,
        });
      }
    },

    confirmDelete() {
      if (!this.get('deleting')) {
        this.set('deleting', true);

        this.delete(this.get('rowToDelete'))
          .then(() => {
            this.toggleModal();
          })
          .finally(() => {
            this.set('deleting', false);
          });
      }
    },

    handleRowClick(row) {
      if ('function' === typeof get(this, 'onSelectRow')
          && !!row
          && 'object' === typeof row) {
        get(this, 'onSelectRow')(row.content);
      }
    },

    showDeleteModal(row) {
      set(this, 'rowToDelete', row);
      this.toggleModal();
    },
  },

  delete(row) {
    const elem = get(this, 'model').findBy('id', get(row, 'id'));
    return get(this, 'onDelete')(elem).then(() => get(this, 'table').removeRow(row));
  },

  toggleModal() {
    this.toggleProperty('isOpenModal');
  },
});
