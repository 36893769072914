import Component from '@ember/component';
import { set, action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';

import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { alias } from '@ember/object/computed';

import enumKpis from 'neuro-frontend/enums/campaign-kpis';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Week from 'neuro-frontend/utils/week';
import Day from 'neuro-frontend/utils/day';
import formatDate from 'neuro-frontend/utils/dates/format-date';

@classNames('campaign-report')
export default class extends Component.extend({
    campaignActions: [],
  }) {
  @service campaignStats

  campaign = {}

  resume = {
    data: [],
  }

  maxShops = 30

  @alias('campaignShops.length') hasShops

  @alias('campaign.closed') isClosed

  @computed('campaignActions')
  get campaignCostColumns() {
    return [
      {
        cellType: 'amount',
        label: i18n.t('campaign-report.estimated-cost'),
        valuePath: 'estimatedCost',
        sortable: false,
        width: '30%',
      },
      {
        cellType: 'amount',
        label: i18n.t('campaign-report.final-cost'),
        valuePath: 'finalCost',
        sortable: false,
        width: '30%',
      },
      {
        cellType: 'tag',
        enum: [
          {
            color: '#028a3d',
            label: i18n.t('campaign-report.final-cost-in-estimated'),
            value: 'valid',
          },
          {
            color: '#e80042',
            label: i18n.t('campaign-report.final-cost-over-estimated'),
            value: 'invalid',
          },
        ],
        sortable: false,
        valuePath: 'state',
        width: '40%',
      },
    ];
  }

  @computed('resume.data', 'isLoading')
  get campaignGoalsColumns() {
    return [
      {
        cellType: 'enum',
        enum: enumKpis,
        label: i18n.t('kpi'),
        sortable: false,
        valuePath: 'kpi',
        width: '40%',
      },
      {
        cellType: 'kpi',
        list: this.resume.data,
        label: i18n.t('achievement'),
        percent: true,
        sortable: false,
        valuePath: 'value',
        width: '20%',
      },
      {
        cellType: 'kpi',
        label: i18n.t('current-value'),
        list: this.resume.data,
        percent: false,
        sortable: false,
        width: '20%',
      },
      {
        label: i18n.t('goal-value'),
        sortable: false,
        valuePath: 'value',
        width: '20%',
      },
    ];
  }

  @computed('shops.[]', 'campaign.commerces.[].name')
  get campaignShops() {
    const {
      campaign,
      maxShops,
      shops,
    } = this;
    const sort = (a, b, attr) => {
      switch (true) {
        case (a[attr] > b[attr]):
          return 1;
        case (b[attr] > a[attr]):
          return -1;
        default:
          return 0;
      }
    };

    if (!campaign || !campaign.commerces?.length) {
      return [];
    }

    if (shops && shops.length === campaign.commerces.length) {
      return [i18n.t('campaign-report.all-shops')];
    }

    // EmberArray.sortBy doesn't accepts descendant sorting :(
    // Idea:
    // * sortBy('surface:desc', 'name')
    const campaignShops = campaign.commerces
      .toArray()
      .sort((a, b) => sort(b, a, 'surface') || sort(a, b, 'name'))
      .map((commerce) => commerce.name);

    if (campaignShops.length > maxShops) {
      return [
        ...campaignShops.slice(0, maxShops),
        i18n.t('campaign-report.more-shops', { shops: campaignShops.length - maxShops }),
      ];
    }

    return campaignShops;
  }

  @computed('supplierRatings')
  get closureCampaignSuppliersColumns() {
    return [
      {
        label: i18n.t('name'),
        valuePath: 'supplier.name',
        sortable: false,
      },
      {
        cellType: 'collection',
        label: i18n.t('marketing-analytics.campaigns.actions'),
        field: 'name',
        sortable: false,
        valuePath: 'supplier.campaignActions',
        width: '24%',
      },
      {
        cellType: 'rating',
        label: i18n.t('marketing-analytics.campaign.rating'),
        sortable: false,
        valuePath: 'rating',
        disabled: true,
      },
    ];
  }

  @computed('campaign.endDate', 'campaign.startDate')
  get dates() {
    const {
      campaign: {
        endDate,
        startDate,
      },
    } = this;

    return `(${startDate} - ${endDate})`;
  }

  @computed('campaignActions')
  get campaignCosts() {
    const estimatedCost = this.campaignActions?.reduce((acc, val) => acc + val.estimatedCost, 0);
    const finalCost = this.campaignActions?.reduce((acc, val) => acc + val.finalCost, 0);

    return [{
      estimatedCost,
      finalCost,
      state: finalCost > estimatedCost ? 'invalid' : 'valid',
    }];
  }

  @computed('resume.data.@each.show')
  get hiddenKPIs() {
    return !this.resume.data.some((kpi) => kpi.show);
  }

  @computed('campaign.startDate', 'campaign.endDate')
  get lastYearPeriod() {
    const startDate = new Date(formatDate(this.campaign.startDate, 'DD/MM/YYYY', 'YYYY-MM-DD'));
    const endDate = new Date(formatDate(this.campaign.endDate, 'DD/MM/YYYY', 'YYYY-MM-DD'));

    const weekNumbers = [
      Number(new Week(startDate).weekNumber.substr(4)),
      Number(new Week(endDate).weekNumber.substr(4)),
    ];

    const lastYearDates = [
      new Week(startDate).subtract(1, 'year').getWeekYear(weekNumbers[0]),
      new Week(endDate).subtract(1, 'year').getWeekYear(weekNumbers[1]),
    ];

    const startWeekDay = new Day(startDate).weekDay;
    const endWeekDay = new Day(endDate).weekDay;

    return {
      startDate: formatDate(lastYearDates[0].getWeekDay(startWeekDay), 'Y-m-dTH:i:sP', 'DD/MM/YYYY'),
      endDate: formatDate(lastYearDates[1].getWeekDay(endWeekDay), 'Y-m-dTH:i:sP', 'DD/MM/YYYY'),
    };
  }

  @computed('campaign.referenceType', 'campaign.referenceDates', 'campaign.referenceCampaign')
  get referredComparison() {
    const {
      campaign: {
        referenceStartDate,
        referenceEndDate,
        referenceType,
      },
      referenceCampaign,
    } = this;

    const formatReferenceDate = (startDate, endDate) => ({
      data: {
        startDate,
        endDate,
      },
      label: `${startDate} - ${endDate}`,
    });

    if (!this.campaign.startDate || !this.campaign.endDate) {
      return {
        data: {},
        label: '',
      };
    }

    switch (referenceType) {
      case 'date':
        return (null == referenceStartDate || null == referenceEndDate)
          ? formatReferenceDate(this.lastYearPeriod.startDate, this.lastYearPeriod.endDate)
          : formatReferenceDate(referenceStartDate, referenceEndDate);
      case 'campaign':
        return {
          data: referenceCampaign,
          label: referenceCampaign.name,
        };
      default:
        return formatReferenceDate(this.lastYearPeriod.startDate, this.lastYearPeriod.endDate);
    }
  }

  init(...args) {
    super.init(...args);
    set(this, 'isLoading', true);
    this.fetchData.perform();
  }

  @restartableTask({
    withTestWaiter: true, // Force tests to wait for the calls to finish
  })
  fetchData = function* () {
    const {
      campaignStats,
      campaign,
      referredComparison,
    } = this;

    set(this, 'isLoading', true);

    this.resume.data.clear();

    const referenceData = yield campaignStats.fetchStats(referredComparison?.data);
    const campaignData = yield campaignStats.fetchStats(campaign);

    this.resume.data.pushObjects(
      campaignStats
        .formatResponse(referenceData, campaignData)
        ?.map((kpi) => {
          const state = this.getState(kpi);
          const color = this.getColorByState(state);
          return {
            ...kpi,
            formattedValue: kpi.currentValue,
            currentValue: Number(kpi.currentValue.toString().replace(/€|%/, '')),
            color,
            show: (this.isClosed && 'undetermined' !== state),
            state,
          };
        }),
    );

    set(this, 'isLoading', false);
  }

  @action
  async createPDF() {
    set(this, 'isLoadingPDF', true);

    const doc = new JsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4',
    });
    const sections = document.querySelectorAll('.js-pdf__section');

    const savePDF = () => {
      doc.save(`report-${this.campaign.name}.pdf`);
      set(this, 'isLoadingPDF', false);
    };

    let height = 0;

    const canvasses = await Promise.all(Array.from(sections).map((section) => html2canvas(
      section,
      {
        allowTaint: true,
        height: section.offsetHeight,
        scale: '5',
        x: section.offsetLeft,
        y: section.offsetTop,
        width: section.offsetWidth,
      },
    ).then((canvas) => canvas)));

    canvasses.forEach((section) => {
      if (!section.width) {
        return;
      }

      const width = doc.internal.pageSize.getWidth();
      const img = section.toDataURL('image/jpeg');
      const sectionHeight = (section.height * width) / section.width;

      if (doc.internal.pageSize.getHeight() <= (height + sectionHeight)) {
        height = 0;

        doc.addPage();
      }

      doc.addImage(
        img,
        'JPG',
        0,
        height,
        width,
        sectionHeight,
        '',
        'FAST',
      );

      height += sectionHeight;
    });

    return savePDF();
  }

  getColorByState(state) {
    switch (state) {
      case 'valid':
        return '#028a3d';
      case 'acceptable':
        return '#f8b827';
      case 'invalid':
        return '#e80042';
      default:
        return '#96a0a4';
    }
  }

  getState(kpi) {
    const currentValue = Number(kpi.currentValue.toString().replace(/€|%/, ''));
    const referenceValue = Number(kpi.referenceValue.toString().replace(/€|%/, ''));
    const percent = (currentValue * 100) / referenceValue;

    switch (true) {
      case 100 <= percent:
        return 'valid';
      case 90 < percent:
        return 'acceptable';
      case 90 > percent:
        return 'invalid';
      default:
        return 'undetermined';
    }
  }
}
