import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('date') endDate

  @attr('number') estimatedCost

  @attr('number') finalCost

  @attr('string') name

  @attr('number') rating

  @attr('date') startDate

  // Relationships
  @belongsTo('campaign') campaign

  @belongsTo('service') service

  @belongsTo('supplier') supplier

  @computed()
  get validations() {
    return {
      endDate: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('end-date') }),
        },
      },

      estimatedCost: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('estimated-cost') }),
        },
      },

      finalCost: {
        numericality: {
          greaterThan: 0,
          message: i18n.t('required', { attr: i18n.t('final-cost') }),
        },
      },

      name: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('name') }),
        },
      },

      startDate: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('start-date') }),
        },
      },

      service: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('service') }),
        },
      },

      supplier: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('supplier') }),
        },
      },
    };
  }
}
