/**
 * @module    nf
 * @submodule nf-utils
 */

function parseJsonApiErrors(errors) {
  const messages = {};
  if (errors && errors.forEach) {
    errors.forEach((error) => {
      const sourceSplit = error.source.pointer.split('/');
      const attribute = sourceSplit[sourceSplit.length - 1];
      messages[attribute.camelize()] = [error.detail];
    });
  }
  return messages;
}

function parseValidationErrors(errors) {
  const messages = {};

  if (errors && errors.forEach) {
    errors.forEach((error) => {
      const attrs = error.attribute.split('.');

      if (1 < attrs.length) {
        let obj;

        for (let i = attrs.length - 1; 0 < i; i -= 1) {
          const aux = {};

          aux[attrs[i]] = obj || error.message;

          obj = aux;
        }

        messages[attrs[0]] = obj;
      } else {
        messages[error.attribute] = error.message;
      }
    });
  }

  return messages;
}

/**
 * parseErrorsArrayToErrorsObject
 * --
 *
 * This function takes an Ember.Errors array and transforms it into a plain
 * object with a property per error name in the form.
 *
 * This allows to use the internal errors system in the components of the
 * library Ember-cli-materialize that uses a different approach. This can be
 * a bug in the library, so this function is our workaround to merge both
 * behaviours.
 *
 * @param  {Ember.Errors} errors  An array of errors.
 * @return {Object}               Object with an attribute per parameter with
 *                                errors.
 */

export default function parseErrorsArrayToErrorsObject(errors) {
  return errors && errors.errors
    ? parseJsonApiErrors(errors.errors)
    : parseValidationErrors(errors);
}
