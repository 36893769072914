import { inject as service } from '@ember/service';
import { isArray } from '@ember/array';
import { set, get, action } from '@ember/object';

import { hash } from 'rsvp';

import Route from 'neuro-frontend/routes/base';

export default class extends Route {
  @service ajax

  @service apiEmailCampaigns

  @service auth

  @service can

  templateName = 'emailing/campaigns/new'

  beforeModel() {
    if (!this.can.can('access to module-mailing')) {
      this.transitionTo('index');
    }
  }

  model({ 'email-campaign_id': campaignId }) {
    const {
      auth: {
        center,
      },
      store,
    } = this;
    const defaultHtmlBody = i18n.t('email-campaign.htmlBody.init_greeting');
    const emailCampaign = campaignId
      ? store.findRecord('emailCampaign', campaignId)
      : store.createRecord('emailCampaign', {
        name: '',
        subject: '',
        state: 'draft',
        htmlBody: `<body style="background-color:#e0e0e0">
          <h1>${defaultHtmlBody}</h1>
        </body>`,
        center,
      });

    return hash({
      campaign: emailCampaign,

      segments: get(this, 'store').query('segment', {
        filter: {
          center_id: center.id,
        },
        sort: 'segment-name',
      }),
      campaigns: get(this, 'store').query('campaign', {
        filter: {
          center: center.id,
          status: [
            {
              op: 'is_not',
              value: 'closed',
            },
            {
              op: 'is_not',
              value: 'finished',
            },
          ],
        },
      }),
    });
  }

  validateEmailList(emailList) {
    if (!isArray(emailList)) {
      return false;
    }
    let isValid = true;

    emailList.forEach((email) => {
      if ('string' !== typeof email) {
        isValid = false;
      }
    });

    return isValid;
  }

  didSaveModel(record) {
    return new Promise((resolve) => {
      resolve(get(this, 'apiEmailCampaigns').sendSchedule(get(record, 'id')));
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'segmentId', undefined);
      set(controller, 'currentStep', 1);
    }
  }

  @action
  sendTestEmail(emailList) {
    const recordCampaign = get(this, 'controller.model.campaign');

    if (!this.validateEmailList(emailList)) {
      throw new TypeError('The e-mail list is not a array with e-mails.');
    }

    return recordCampaign.save().then((campaign) => get(this, 'apiEmailCampaigns').sendTest(emailList, get(campaign, 'id')));
  }
}
