import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { set, get } from '@ember/object';

export default Component.extend({
  classNames: ['send-email-template-modal'],

  i18n: service(),

  multipleSend: false,

  emailList: '',

  title: i18n.t('email-template.send.test'),

  isOpen: false,

  isLoading: false,

  errors: {},

  isValidEmail(email) {
    const re = /(.+)@(.+){2,}\.(.+){2,}/;
    return re.test(email);
  },

  validateEmails(emailList) {
    const valid = emailList.some((email) => {
      if (this.isValidEmail(email)) {
        return true;
      }
      set(this, 'errors.receiverEmail', email);
      return false;
    });
    return valid;
  },

  formatEmails(emailList) {
    const formated = emailList.split(' ').join('');

    if (!get(this, 'multipleSend')) {
      return [formated];
    }
    return formated.split(',').filter((email) => '' !== email);
  },

  actions: {
    toggleModal() {
      this.toggleProperty('isOpen');
    },

    sendEmail() {
      const receivers = this.formatEmails(get(this, 'emailList'));

      set(this, 'isLoading', true);
      set(this, 'errors', {});

      if (!this.validateEmails(receivers)) {
        set(this, 'errors.receiver', true);
        set(this, 'isLoading', false);
        return;
      }

      this.get('onSendEmail')(receivers)
        .then(() => {
          this.toggleProperty('isOpen');
        })
        .catch((errors) => {
          if (get(errors, 'errors.0.detail.email')) {
            set(this, 'errors.receiver', true);
          } else {
            set(this, 'errors.server', true);
          }
        })
        .finally(() => {
          set(this, 'isLoading', false);
        });
    },
  },
});
