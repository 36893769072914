import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

export default class extends Model {
  @attr('datetime') bookingDocumentDatetime

  @attr('datetime') bookingPaymentDatetime

  @attr('date') closingDate

  @attr('string') closingNotes

  @attr('datetime') deedDatetime

  @attr({ defaultValue() { return []; } }) files

  @attr('datetime') kycDatetime

  @attr('datetime') monthlyPaymentSystemDatetime

  @attr('string') notes

  @attr('date') openingDate

  @attr('number') price

  @attr('boolean') rejectedCommonZones

  @attr('boolean') rejectedDeliveryDate

  @attr('string') rejectedOtherReasons

  @attr('boolean') rejectedPayForm

  @attr('boolean') rejectedPrice

  @attr('boolean') rejectedProject

  @attr('datetime') salaryDatetime

  @attr('string') state

  @attr('datetime') tradingDatetime

  @attr('datetime') vatNumberDatetime

  @belongsTo('residentialProposal') residentialProposal
}
