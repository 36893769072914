import { computed, get } from '@ember/object';

import { inject as service } from '@ember/service';

import ModuleCRM from './module-crm';

export default class extends ModuleCRM {
  @service auth

  @computed('auth.user.level', 'model.isDeletable')
  get canDelete() {
    const {
      model: {
        isDeletable,
      },
    } = this;

    return get(this, 'canAccess') && isDeletable;
  }
}
