import { computed } from '@ember/object';

import ModuleTickets from './module-tickets';

export default class extends ModuleTickets {
  @computed('model.isRedeemed')
  get canDelete() {
    const {
      model,
    } = this;

    return !model.isRedeemed;
  }

  @computed('model.isRedeemed')
  get canEdit() {
    const {
      model,
    } = this;

    return !model.isRedeemed;
  }

  @computed('model.isRedeemed')
  get canRedeem() {
    const {
      model,
    } = this;

    return !model.isRedeemed;
  }
}
