export default Object.freeze([
  {
    label: i18n.t('on-signing-date'),
    value: 'on-signing-date',
  },
  {
    label: i18n.t('from-unit-delivery-date'),
    value: 'from-unit-delivery-date',
  },
  {
    label: i18n.t('from-unit-opening-date'),
    value: 'from-unit-opening-date',
  },
]);
