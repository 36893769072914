import Mixin from '@ember/object/mixin';
import { get, set } from '@ember/object';

import Filter from 'neuro-frontend/utils/filter';
import parseFilters from 'neuro-frontend/utils/parse-filters';

/**
 * @require mixins/list
 */
export default Mixin.create({
  init() {
    this._super();
    this.initializeFilters();
  },

  initializeFilters() {
    set(
      this,
      'filters',
      [
        Filter.create(),
      ],
    );
  },

  actions: {
    addFilter() {
      get(this, 'filters').pushObject(Filter.create());
    },

    applyFilters(...args) {
      const selectedSegment = get(this, 'selectedSegment');
      this.send('changeFilters', selectedSegment ? {
        ...parseFilters(args),
        type: selectedSegment.segmentType,
      } : {});
    },

    clearFilters() {
      // cleans array of filters
      this.initializeFilters();
      // uses defaultFilter instead the empty array
      this.resetFilters();
      // updates the collection
      this.loadCollection();
    },

    removeFilter(filter) {
      get(this, 'filters').removeObject(filter);
    },

    // @TODO refactor to admit segment instead of a row
    selectSegment(row) {
      if (!row || 'object' !== typeof row) {
        throw new TypeError(`selectSegment: ${row} is not a valid Row`);
      }

      const segment = row.content;
      const isSelecting = (get(this, 'selectedSegment') !== segment);
      const filters = segment.getFilters();

      set(this, 'showAdvancedSearch', isSelecting);
      set(this, 'selectedSegment', isSelecting ? segment : null);
      set(this, 'filters', isSelecting && filters.length ? filters : [Filter.create()]);
      this.actions.applyFilters.call(this, ...filters);
      segment.save();
    },
  },
});
