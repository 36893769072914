import { association, Factory } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  notes() {
    return faker.random.words();
  },

  sentInfo() {
    return faker.random.boolean();
  },

  residentialProperty: association(),
});
