import { computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';

import Cell from './base';

function getValue(value, str) {
  return str && value
    ? value.reduce((acc, val) => acc + val[str], 0)
    : 0;
}

@classNames('calendar-month__flow')
export default class CalendarMonthCellsFlowComponent extends Cell {
  tagName = 'ul'

  @computed('value')
  get cars() {
    return getValue(this.value, 'cars');
  }

  @computed('value')
  get cinemas() {
    return getValue(this.value, 'cinemas');
  }

  @computed('value')
  get footfall() {
    return getValue(this.value, 'footfall');
  }
}
