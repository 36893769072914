import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({

  name() {
    return faker.random.word();
  },

  center: association(),
  shopProperty: association(),

  closedMonths: [],
});
