import Controller from '@ember/controller';
import { computed } from '@ember/object';

import ListMixin from 'neuro-frontend/mixins/nf-list';
import dashboardCategories from 'neuro-frontend/enums/dashboard-categories';
import dashboardPanels from 'neuro-frontend/enums/dashboard-panels';

const MixinController = Controller.extend(ListMixin, {});

export default class extends MixinController {
  editRoute = 'configuration.dashboards.edit'

  modelName = 'dashboard'

  @computed
  get columns() {
    return [
      {
        label: i18n.t('name'),
        valuePath: 'name',
        width: '30%',
      },
      {
        cellType: 'enum',
        enum: dashboardCategories,
        label: i18n.t('category'),
        sortable: false,
        valuePath: 'category',
      },
      {
        label: i18n.t('type'),
        cellType: 'tag',
        enum: [
          {
            color: '#f8b827',
            label: i18n.t('internal'),
            value: 'internal',
          },
          {
            color: '#96a0a4',
            label: i18n.t('external-url'),
            value: 'url',
          },
        ],
        sortable: false,
        valuePath: 'type',
      },
      {
        cellType: 'enum',
        enum: dashboardPanels,
        label: i18n.t('panel'),
        sortable: false,
        valuePath: 'panel',
      },
      {
        align: 'right',
        cellClassNames: 'qa-button-group cell-button-group',
        cellType: 'button-group',
        label: '',
        buttons: [{
          action: 'edit',
          icon: 'edit',
          tooltip: i18n.t('edit'),
        },
        {
          action: 'delete',
          icon: 'delete',
          tooltip: i18n.t('delete'),
        }],
        sortable: false,
        width: '12%',
      },
    ];
  }
}
