import { computed, get } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import Validator from 'ember-model-validator/mixins/model-validator';
import { belongsTo } from 'ember-data/relationships';

export default Model.extend(Validator, {
  code: attr('string'),

  vatNumber: attr('string', { defaultValue: '' }),

  name: attr('string'),

  fiscalName: attr('string', { defaultValue: '' }),

  logo: attr('string', { defaultValue: '' }),

  center: belongsTo('center'),

  validations: computed(function () {
    return {
      code: {
        presence: {
          message: get(this, 'i18n').t('error.residential.owner.code.required'),
        },
      },

      name: {
        presence: {
          message: get(this, 'i18n').t('error.residential.owner.name.required'),
        },
      },

      center: {
        presence: true,
      },
    };
  }),
});
