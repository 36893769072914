import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { action } from '@ember/object';

@classNames('nf-dashboard__header')
export default class NfDashboardHeaderComponent extends Component {
  @action
  print() {
    if (!this.isLoading) {
      window.print();
    }
  }

  @action
  refresh() {
    if ('function' === typeof this.onRefresh) {
      this.onRefresh();
    }
  }
}
