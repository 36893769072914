export default Object.freeze([
  {
    label: i18n.t('marketing-analytics.kpi.coupon-percent'),
    value: 'coupon-percent',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.coupon-percent'),
    icon: 'redeem',
  },
  {
    label: i18n.t('marketing-analytics.kpi.coupon-exchanges'),
    value: 'coupon-exchanges',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.coupon-exchanges'),
    icon: 'redeem',
  },
  {
    label: i18n.t('marketing-analytics.kpi.impressions-number'),
    value: 'impressions-number',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.impressions-number'),
    icon: 'stay_current_portrait',
  },
  {
    label: i18n.t('marketing-analytics.kpi.active-users'),
    value: 'active-users',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.active-users'),
    icon: 'people',
  },
  {
    label: i18n.t('marketing-analytics.kpi.visits'),
    value: 'visits',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.visits'),
    icon: 'directions_walk',
  },
  {
    label: i18n.t('marketing-analytics.kpi.attendant-number'),
    value: 'attendant',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.attendant'),
    icon: 'group_add',
  },
  {
    label: i18n.t('marketing-analytics.kpi.new-registered-users'),
    value: 'new-registered-users',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.new-registered-users'),
    icon: 'group_add',
  },
  {
    label: i18n.t('marketing-analytics.kpi.previous-registered-users'),
    value: 'previous-registered-users',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.previous-registered-users'),
    icon: 'group_add',
  },
  {
    label: i18n.t('marketing-analytics.kpi.contestants'),
    value: 'contestants',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.contestants'),
    icon: 'group_add',
  },
  {
    label: i18n.t('marketing-analytics.kpi.new-registered-users-percent'),
    value: 'new-registered-users-percent',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.new-registered-users-percent'),
    icon: 'group_add',
  },
  {
    label: i18n.t('marketing-analytics.kpi.cost-per-contestant'),
    value: 'cost-per-contestant',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.cost-per-contestant'),
    icon: 'monetization_on',
  },
  {
    label: i18n.t('marketing-analytics.kpi.receipt-average-price'),
    value: 'receipt-average-price',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.eceipt-average-price'),
    icon: 'receipt',
  },
  {
    label: i18n.t('marketing-analytics.kpi.total-receipts'),
    value: 'total-receipts',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.total-receipts'),
    icon: 'receipt',
  },
  {
    label: i18n.t('marketing-analytics.kpi.total-receipts-value'),
    value: 'total-receipts-value',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.total-receipts-value'),
    icon: 'receipt',
  },
  {
    label: i18n.t('marketing-analytics.kpi.eca-number'),
    value: 'eca',
    tooltip: i18n.t('marketing-analytics.kpi.tooltip.eca'),
    icon: 'radio',
  },
]);
