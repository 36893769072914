import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import Validator from 'ember-model-validator/mixins/model-validator';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class extends Model.extend(Validator, {
  config: fragment('dashboardConfig'),
}) {
  @service auth

  @attr('string') category;

  @attr('string') name;

  @attr('string') panel;

  @attr('string', { defaultValue: '' }) parameters;

  @computed('parameters')
  get externalUrl() {
    let url = this.parameters;

    const {
      centerId,
      user: { id: staffId },
    } = this.auth;

    if (url.includes('{center_id}') && centerId) url = url.replace('{center_id}', centerId);
    if (url.includes('{staff_id}') && staffId) url = url.replace('{staff_id}', staffId);

    return url;
  }

  @computed('panel')
  get type() {
    if ('url' === get(this, 'panel')) {
      return 'url';
    }
    return 'internal';
  }

  @computed('panel')
  get validations() {
    return {
      name: {
        presence: {
          message: i18n.t('error.dashboard.name.required'),
        },
      },

      category: {
        presence: {
          message: i18n.t('error.dashboard.category.required'),
        },
      },
      panel: {
        presence: {
          message: i18n.t('error.dashboard.panel.required'),
        },
      },

      parameters: {
        presence: {
          if: () => 'url' === get(this, 'panel') && '' === get(this, 'parameters'),
          message: i18n.t('error.dashboard.url.required'),
        },
      },
    };
  }
}
