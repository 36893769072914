import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  code() {
    return faker.random.word();
  },
  description() {
    return faker.lorem.text();
  },
  floor() {
    return faker.random.number();
  },
  mapPoints() {
    return faker.random.word();
  },
  type() {
    return faker.random.word();
  },

  building: association(),

  afterCreate(_zone, server) {
    const zone = _zone;
    zone.commerces = server.createList('commerce', 2);
  },
});
