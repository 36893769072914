import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';

import { classNames } from '@ember-decorators/component';
import Table from 'ember-light-table';

@classNames('nf-table')
export default class NfTableComponent extends Component.extend({
    extraActions: {},

    meta: {
      page: {},
    },

    showPaginator: true,
  }) {
  canSelect = false

  isOpenModal = false

  layoutName = 'components/nf-table'

  multiSelect = false

  rowToDelete = false

  @computed('extraActions')
  get tableActions() {
    return {
      delete: (row) => {
        this.showDeleteModal(row);
      },
      edit: (model) => {
        this.onEdit(model.content);
      },
      restore: (model) => {
        this.onRestore(model.content);
      },
      ...this.extraActions,
    };
  }

  init(...args) {
    super.init(...args);

    const table = new Table(get(this, 'columns'), get(this, 'model'), { enableSync: true });
    set(this, 'table', table);
  }

  @action
  selectMultiple() {
    if ('function' === typeof this.onSelectMultiple) {
      const models = get(this, 'table.selectedRows').map((row) => row.content);
      this.onSelectMultiple(models);
    }
  }

  @action
  columnClicked(column) {
    if (column.sorted && this.onColumnClicked) {
      this.onColumnClicked({
        sort: `${column.ascending ? '' : '-'}${get(column, 'valuePath').dasherize()}`,
      });
    }
  }

  @action
  confirmDelete() {
    if (!this.isDeleting) {
      set(this, 'isDeleting', true);

      this.delete(this.rowToDelete)
        .then(() => {
          this.toggleDeleteModal();
        })
        .finally(() => {
          set(this, 'isDeleting', false);
        });
    }
  }

  @action
  handleRowClick(row) {
    if ('function' === typeof get(this, 'onSelectRow')
        && !!row
        && 'object' === typeof row) {
      get(this, 'onSelectRow')(row.content);
    }
  }

  @action
  showDeleteModal(row) {
    set(this, 'rowToDelete', row);
    this.toggleDeleteModal();
  }

  @action
  cancelDelete() {
    this.toggleDeleteModal();
  }

  delete(row) {
    const elem = get(this, 'model').findBy('id', get(row, 'id'));
    return get(this, 'onDelete')(elem).then(() => get(this, 'table').removeRow(row));
  }

  toggleDeleteModal() {
    this.toggleProperty('isOpenModal');
  }
}
