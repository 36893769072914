import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  name() {
    return faker.random.word();
  },
  yardiCode() {
    return faker.random.alphaNumeric();
  },

  center: association(),
});
