import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import {
  action, computed, get, set, setProperties,
} from '@ember/object';

import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { restartableTask } from 'ember-concurrency-decorators';
import fetch from 'fetch';

import Year from 'neuro-frontend/utils/year';
import Week from 'neuro-frontend/utils/week';
import formatDate from 'neuro-frontend/utils/dates/format-date';

@classNames('dmr-weekly')
export default class extends Component {
  @service auth

  @service api

  @service stats

  data = {
    registeredUsers: {},
    visits: {},
    userChannels: {},
    userProfile: {},
    downloads: {},
  }

  userChannels = {
    week: {
      appUsers: [],
      otherUsers: [],
      playCenterUsers: [],
      socialUsers: [],
      webUsers: [],
    },

    annualAverage: {
      appUsers: [],
      otherUsers: [],
      playCenterUsers: [],
      socialUsers: [],
      webUsers: [],
    },

    pastWeeks: {
      headers: [],
      appUsers: [],
      otherUsers: [],
      playCenterUsers: [],
      socialUsers: [],
      webUsers: [],
    },
  }

  registeredUsers = {
    week: [],
    annualAverage: [],
  }

  appDownloads = {
    week: [],
    annual: [],
  }

  userProfile = {
    ageRange: {
      week: [],
      annualAverage: [],
    },
    gender: {
      week: [],
      annualAverage: [],
    },
    influenceZones: {
      week: [],
      annualAverage: [],
    },
    maritalStatus: {
      week: [],
      annualAverage: [],
    },
  }

  dwellTime = {
    annualAverage: [],
    weekly: [],
    annual: [],
    headers: [],
  }

  ageColors = ['#B06B87', '#D59CCF', '#A490C0', '#51657A', '#BCC4DB', '#96A0A4'];

  downloadsColors = ['#2fa095'];

  dwellChartColor = ['#5bc490'];

  influenceZonesColors = ['#0095C3', '#736BAE', '#965095', '#A63165'];

  maritalStatusColors = ['#8DCBC5', '#837DB5', '#3C4F76', '#96A0A4'];

  genderColors = ['#367C86', '#5BC490', '#4DD0E1', '#96A0A4'];

  userChannelsColors = ['#4DD0E1', '#2FA095', '#85779A', '#696E8C', '#C489A1'];

  @computed(
    'fetchUserChannels.isRunning',
    'fetchWeeklyDownloads.isRunning',
    'fetchRegisteredUsers.isRunning',
    'fetchVisits.isRunning',
    'fetchWeeklyUserProfile.isRunning',
  )
  get isLoading() {
    return this.fetchUserChannels.isRunning
    || this.fetchWeeklyDownloads.isRunning
    || this.fetchRegisteredUsers.isRunning
    || this.fetchVisits.isRunning
    || this.fetchWeeklyUserProfile.isRunning;
  }

  @computed(
    'userChannels.week.appUsers',
    'userChannels.week.otherUsers',
    'userChannels.week.playCenterUsers',
    'userChannels.week.socialUsers',
    'userChannels.week.webUsers',
  )
  get weeklyUserChannels() {
    const data = get(this, 'userChannels.week');
    const total = data.appUsers + data.otherUsers
      + data.playCenterUsers + data.socialUsers
      + data.webUsers;

    return (!total) ? null : [{
      category: '',
      [i18n.t('channels.app')]: this.toPercentage(data.appUsers, total),
      [i18n.t('channels.play-center')]: this.toPercentage(data.playCenterUsers, total),
      [i18n.t('channels.web')]: this.toPercentage(data.webUsers, total),
      [i18n.t('channels.social-wifi')]: this.toPercentage(data.socialUsers, total),
      [i18n.t('channels.other')]: this.toPercentage(data.otherUsers, total),
    }];
  }

  @computed(
    'userChannels.pastWeeks.headers',
    'userChannels.pastWeeks.appUsers',
    'userChannels.pastWeeks.webUsers',
    'userChannels.pastWeeks.playCenterUsers',
    'userChannels.pastWeeks.socialUsers',
    'userChannels.pastWeeks.otherUsers',
  )
  get pastWeeksUserChannelsChart() {
    const {
      userChannels: {
        pastWeeks: {
          headers,
          appUsers,
          webUsers,
          playCenterUsers,
          socialUsers,
          otherUsers,
        },
      },
    } = this;

    if (!headers.length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('channels.app'), ...this.setZeroEmptyData(appUsers, headers, ['week.weekNumber', 'count'])],
      [i18n.t('channels.play-center'), ...this.setZeroEmptyData(playCenterUsers, headers, ['week.weekNumber', 'count'])],
      [i18n.t('channels.web'), ...this.setZeroEmptyData(webUsers, headers, ['week.weekNumber', 'count'])],
      [i18n.t('channels.social-wifi'), ...this.setZeroEmptyData(socialUsers, headers, ['week.weekNumber', 'count'])],
      [i18n.t('channels.other'), ...this.setZeroEmptyData(otherUsers, headers, ['week.weekNumber', 'count'])],
    ];
  }

  @computed('userProfile.ageRange.week', 'userProfile.ageRange.annualAverage')
  get ageRange() {
    const {
      userProfile: {
        ageRange: {
          week: weekAgeRange,
          annualAverage: annualAgeRange,
        },
      },
    } = this;

    const chartFormat = (data) => {
      if (data && Object.entries(data).length) {
        const total = data['0-17'] + data['18-24'] + data['25-34'] + data['35-44'] + data['45-99'] + data.none;
        return (!total) ? null : [{
          category: '',
          [i18n.t('dashboards.0-17')]: this.toPercentage(data['0-17'], total),
          [i18n.t('dashboards.18-24')]: this.toPercentage(data['18-24'], total),
          [i18n.t('dashboards.25-34')]: this.toPercentage(data['25-34'], total),
          [i18n.t('dashboards.35-44')]: this.toPercentage(data['35-44'], total),
          [i18n.t('dashboards.45-99')]: this.toPercentage(data['45-99'], total),
          [i18n.t('dashboards.none')]: this.toPercentage(data.none, total),
        }];
      }
      return null;
    };

    return {
      week: chartFormat(weekAgeRange),
      annualAverage: chartFormat(annualAgeRange),
    };
  }

  @computed('userProfile.gender.week', 'userProfile.gender.annualAverage')
  get gender() {
    const {
      userProfile: {
        gender: {
          week: weekGender,
          annualAverage: annualGender,
        },
      },
    } = this;

    const chartFormat = (data) => {
      if (data && Object.entries(data).length) {
        const total = data.H + data.M + data.O + data.none;
        return (!total) ? null : [{
          category: '',
          [i18n.t('dashboards.men')]: this.toPercentage(data.H, total),
          [i18n.t('dashboards.women')]: this.toPercentage(data.M, total),
          [i18n.t('dashboards.other')]: this.toPercentage(data.O, total),
          [i18n.t('dashboards.none')]: this.toPercentage(data.none, total),
        }];
      }
      return null;
    };

    return {
      week: chartFormat(weekGender),
      annualAverage: chartFormat(annualGender),
    };
  }

  @computed('userProfile.influenceZones.week', 'userProfile.influenceZones.annualAverage')
  get influenceZones() {
    const {
      userProfile: {
        influenceZones: {
          week: weekinfluenceZones,
          annualAverage: annualinfluenceZones,
        },
      },
    } = this;

    const chartFormat = (data) => {
      if (data && Object.entries(data).length) {
        const total = data['1'] + data['2'] + data['3'] + data['4'];
        return (!total) ? null : [{
          category: '',
          [i18n.t('dashboards.1')]: this.toPercentage(data['1'], total),
          [i18n.t('dashboards.2')]: this.toPercentage(data['2'], total),
          [i18n.t('dashboards.3')]: this.toPercentage(data['3'], total),
          [i18n.t('dashboards.4')]: this.toPercentage(data['4'], total),
        }];
      }
      return null;
    };

    return {
      week: chartFormat(weekinfluenceZones),
      annualAverage: chartFormat(annualinfluenceZones),
    };
  }

  @computed('userProfile.maritalStatus.week', 'userProfile.maritalStatus.annualAverage')
  get maritalStatus() {
    const {
      userProfile: {
        maritalStatus: {
          week: weekMaritalStatus,
          annualAverage: annualMaritalStatus,
        },
      },
    } = this;

    const chartFormat = (data) => {
      if (data && Object.entries(data).length) {
        const total = data.P + data.F + data.S + data.none;
        return (!data) ? null : [{
          category: '',
          [i18n.t('dashboards.family')]: this.toPercentage(data.F, total),
          [i18n.t('dashboards.couple')]: this.toPercentage(data.P, total),
          [i18n.t('dashboards.single')]: this.toPercentage(data.S, total),
          [i18n.t('dashboards.none')]: this.toPercentage(data.none, total),
        }];
      }
      return null;
    };
    return {
      week: chartFormat(weekMaritalStatus),
      annualAverage: chartFormat(annualMaritalStatus),
    };
  }

  @computed('dwellTime.annual')
  get dwellTimeChart() {
    const {
      dwellTime: {
        annual,
        headers,
      },
    } = this;

    if (!headers.length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.dwell-time'), ...this.setZeroEmptyData(annual, headers, ['week.weekNumber', 'average'])],
    ];
  }

  didReceiveAttrs() {
    const week = new Week();

    setProperties(
      this,
      {
        startDate: new Date(week.startDate),
        endDate: new Date(week.endDate),
        startOfYear: new Date((new Week(new Date((new Year(new Date())).startDate))).startDate),
        endOfYear: new Date((new Week(new Date((new Year(new Date())).endDate))).endDate),
      },
    );

    this.fetchData();
  }

  @action
  changeFilters(startDate, endDate) {
    setProperties(
      this,
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        startOfYear: new Date((new Week(new Date((new Year(startDate)).startDate))).startDate),
        endOfYear: new Date((new Week(new Date((new Year(startDate)).endDate))).endDate),
      },
    );
    this.fetchData();
  }

  @computed('appDownloads.annual')
  get anualDownloadsChart() {
    const {
      appDownloads: {
        annual: appDownloads,
      },
    } = this;
    if (!appDownloads || !appDownloads.length) {
      return null;
    }
    const headers = appDownloads.map((data) => data.week.weekNumber);
    return [
      ['x', ...headers],
      [i18n.t('dashboards.weekly.downloads'), ...appDownloads.map((data) => data.downloads)],
    ];
  }

  @restartableTask
  fetchWeeklyDownloads = function* () {
    if (this.shouldFetch(this.data.downloads)) {
      const centerId = get(this, 'auth.centerId');
      const stats = get(this, 'stats');
      const formattedDate = formatDate(this.startOfYear, 'Y-m-dTH:i:sP', 'YYYY-MM-DD');
      const getQuery = (date, so) => ({
        break_down: 'date',
        start_date: date,
        center_id: Number(centerId),
        building_type: ['main'],
        platform: so,
      });
      const dataAndroid = yield hash({
        sales: stats.getData(
          'appannie/sales',
          getQuery(formattedDate, 'android'),
        ),
      });
      const dataIos = yield hash({
        sales: stats.getData(
          'appannie/sales',
          getQuery(formattedDate, 'ios'),
        ),
      });

      set(
        this,
        'data.downloads',
        this.getWeeklyDownloads(dataAndroid, dataIos),
      );
    }

    this.formatWeeklyDownloads(this.data.downloads);
  }

  @restartableTask
  fetchWeeklyUserProfile = function* () {
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(get(this, 'auth.centerId'))],
      group_by: ['week'],
    });

    const {
      startOfYear,
      endOfYear,
    } = this;

    const query = getQuery(formatDate(startOfYear), formatDate(endOfYear));

    if (this.shouldFetch(this.data.userProfile)) {
      set(
        this,
        'data.userProfile',
        this.formatWeeklyData(
          yield fetch(`${get(this, 'api.host')}/api/v4/stats/user_profiles?${$.param(query)}`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${get(this, 'auth.token')}`,
              'Content-Type': 'application/json',
            },
          }).then((response) => response.json()),
          'week',
        ),
      );
    }

    this.formatUserProfile(this.data.userProfile);
  }

  @restartableTask
  fetchUserChannels = function* () {
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(get(this, 'auth.centerId'))],
      group_by: ['week'],
    });

    const {
      startOfYear,
      endOfYear,
      startDate,
    } = this;

    const eightWeeksAgo = (new Week(startDate)).subtract(8, 'weeks');

    if (this.shouldFetch(this.data.userChannels)
      || eightWeeksAgo.isBefore(new Week(startOfYear))) {
      const query = (eightWeeksAgo.isBefore(new Week(startOfYear)))
        ? getQuery(
          formatDate(get(eightWeeksAgo, 'startDate')),
          formatDate(endOfYear),
        ) // From 8 weeks ago to current date
        : getQuery(
          formatDate(startOfYear),
          formatDate(endOfYear),
        ); // From this year's start date to current date

      set(
        this,
        'data.userChannels',
        this.formatWeeklyData(
          yield fetch(
            `${get(this, 'api.host')}/api/v4/stats/user_channels?${$.param(query)}`,
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${get(this, 'auth.token')}`,
                'Content-Type': 'application/json',
              },
            },
          ).then((response) => response.json()),
          'week',
        ),
      );
    }

    const week = new Week(startDate);

    this.formatUserChannels(this.data.userChannels, week);

    this.formatAnnualAverageUserChannels(
      this.data.userChannels,
      new Week(new Date(startOfYear)),
    );

    this.formatPastWeeksUserChannels(
      this.data.userChannels,
      week.subtract(7, 'weeks'),
      week,
    );
  }

  @restartableTask
  fetchVisits = function* () {
    const {
      stats,
      startOfYear,
      endOfYear,
      startDate,
    } = this;

    const centerId = get(this, 'auth.centerId');

    if (this.shouldFetch(this.data.visits)) {
      set(
        this,
        'data.visits',
        this.formatWeeklyData(
          yield stats.getData(
            'statsactiva/visits',
            {
              start_date: startOfYear,
              end_date: formatDate(endOfYear),
              center_id: [Number(centerId)],
              building_type: ['main'],
              group_by: ['week'],
            },
          ),
          'date',
        ),
      );
    }

    this.formatDwellTime(
      this.data.visits,
      new Week(startDate),
      new Week(startOfYear),
    );
  }

  @restartableTask
  fetchRegisteredUsers = function* () {
    const {
      startOfYear,
      endOfYear,
      startDate,
    } = this;

    const query = {
      center_id: [get(this, 'auth.centerId')],
      end_date: formatDate(endOfYear),
      start_date: formatDate(startOfYear),
      group_by: [
        'week',
      ],
    };

    if (this.shouldFetch(this.data.registeredUsers)) {
      set(
        this,
        'data.registeredUsers',
        this.formatWeeklyData(
          yield fetch(
            `${get(this, 'api.host')}/api/v4/stats/registered_users?${$.param(query)}`,
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${get(this, 'auth.token')}`,
                'Content-Type': 'application/json',
              },
            },
          ).then((response) => response.json()),
          'week',
        ),
      );
    }

    this.formatRegisteredUsers(
      this.data.registeredUsers,
      new Week(startDate),
      new Week(startOfYear),
    );
  }

  formatUserChannels(data, week) {
    let appUsers = [];
    let playCenterUsers = [];
    let socialUsers = [];
    let webUsers = [];
    let otherUsers = [];

    const filteredData = data.filter((elem) => elem.week.isSame(week));

    filteredData.forEach((userRegister) => {
      switch (userRegister.channel) {
        case 'app':
          appUsers = userRegister.count;
          break;
        case 'play-center':
          playCenterUsers = userRegister.count;
          break;
        case 'social-wifi':
          socialUsers = userRegister.count;
          break;
        case 'web':
          webUsers = userRegister.count;
          break;
        default:
          otherUsers = userRegister.count;
          break;
      }
    });
    setProperties(this, {
      'userChannels.week.appUsers': (filteredData.length) ? appUsers : 0,
      'userChannels.week.playCenterUsers': (filteredData.length) ? playCenterUsers : 0,
      'userChannels.week.socialUsers': (filteredData.length) ? socialUsers : 0,
      'userChannels.week.webUsers': (filteredData.length) ? webUsers : 0,
      'userChannels.week.otherUsers': (filteredData.length) ? otherUsers : 0,
    });
  }

  formatAnnualAverageUserChannels(data, firstWeekOfYear) {
    const channels = ['app', 'web', 'social-wifi', 'play-center', 'other'];

    const weeks = Number(new Week().weekNumber.slice(4, 6)) - 1;

    const filteredData = data.filter((elem) => elem.week.yearNumber === firstWeekOfYear.yearNumber
        && !elem.week.isSame(new Week()));

    channels.forEach((channel) => {
      const sum = (filteredData.length)
        ? filteredData
          .filter((elem) => elem.channel === channel)
          .reduce((acc, current) => acc + current.count, 0) / weeks
        : 0;
      switch (channel) {
        case 'app':
          set(this, 'userChannels.annualAverage.appUsers', sum);
          break;
        case 'play-center':
          set(this, 'userChannels.annualAverage.playCenterUsers', sum);
          break;
        case 'social-wifi':
          set(this, 'userChannels.annualAverage.socialUsers', sum);
          break;
        case 'web':
          set(this, 'userChannels.annualAverage.webUsers', sum);
          break;
        default:
          set(this, 'userChannels.annualAverage.otherUsers', sum);
          break;
      }
    });
  }

  formatUserProfile(userProfileData) {
    const {
      startDate,
    } = this;

    const selectedWeekUserProfile = userProfileData
      .find((data) => data.week.isSame(new Week(startDate))) || [];

    const getAverage = (userProfile, attr) => {
      const average = {};
      const categories = (userProfile.length) ? Object.keys(userProfile[0][attr]) : [];
      const data = userProfile.filter((e) => !e.week.isSame(new Week())).map((d) => d[attr]);
      categories.forEach((category) => {
        Object.assign(average, {
          [category]: data.reduce((acc, current) => acc + current[category], 0) / (data.length),
        });
      });
      return average;
    };
    setProperties(this, {
      'userProfile.ageRange.week': selectedWeekUserProfile.age_range,
      'userProfile.maritalStatus.week': selectedWeekUserProfile.marital_status,
      'userProfile.gender.week': selectedWeekUserProfile.gender,
      'userProfile.influenceZones.week': selectedWeekUserProfile.influence_zone,
    });
    setProperties(this, {
      'userProfile.ageRange.annualAverage': getAverage(userProfileData, 'age_range'),
      'userProfile.maritalStatus.annualAverage': getAverage(userProfileData, 'marital_status'),
      'userProfile.gender.annualAverage': getAverage(userProfileData, 'gender'),
      'userProfile.influenceZones.annualAverage': getAverage(userProfileData, 'influence_zone'),
    });
  }

  formatPastWeeksUserChannels(data, firstWeek, lastWeek) {
    const appUsers = [];
    const playCenterUsers = [];
    const socialUsers = [];
    const webUsers = [];
    const otherUsers = [];

    const filteredData = data.filter((elem) => {
      const { weekYear } = elem.week;
      // TODO Refactor logic expression
      return weekYear < Number(lastWeek.yearNumber)
      || ((elem.week.isSame(lastWeek) || elem.week.isBefore(lastWeek))
        && (elem.week.isSame(firstWeek) || !elem.week.isBefore(firstWeek)))
      || (weekYear !== Number(firstWeek.yearNumber)
        && (elem.week.isSame(lastWeek) || elem.week.isBefore(lastWeek)));
    });

    const headers = (filteredData && filteredData.length)
      ? this.getHeaders(firstWeek, lastWeek)
      : [];

    filteredData.forEach((userRegister) => {
      switch (userRegister.channel) {
        case 'app':
          appUsers.push(userRegister);
          break;
        case 'play-center':
          playCenterUsers.push(userRegister);
          break;
        case 'social-wifi':
          socialUsers.push(userRegister);
          break;
        case 'web':
          webUsers.push(userRegister);
          break;
        default:
          otherUsers.push(userRegister);
          break;
      }
    });

    setProperties(this, {
      'userChannels.pastWeeks.appUsers': appUsers,
      'userChannels.pastWeeks.playCenterUsers': playCenterUsers,
      'userChannels.pastWeeks.socialUsers': socialUsers,
      'userChannels.pastWeeks.webUsers': webUsers,
      'userChannels.pastWeeks.otherUsers': otherUsers,
      'userChannels.pastWeeks.headers': headers,
    });
  }

  formatDwellTime(data, currentWeek, firstWeekOfYear) {
    const currentWeekData = data.find((elem) => elem.week.isSame(currentWeek));

    const headers = (data && data.length)
      ? this.getHeaders(firstWeekOfYear, currentWeek)
      : [];

    const weeks = Number(new Week().weekNumber.slice(4, 6)) - 1;

    const annualAverage = (data.length && 0 < weeks)
      ? data
        .filter((elem) => !elem.week.isSame(new Week()))
        .reduce((acc, val) => acc + val.average, 0) / weeks
      : 0;

    const filteredData = data
      .filter((elem) => elem.week.isBefore(new Week(this.startDate))
        || elem.week.isSame(new Week(this.startDate)));

    setProperties(this, {
      'dwellTime.annualAverage': annualAverage,
      'dwellTime.weekly': currentWeekData ? currentWeekData.average : null,
      'dwellTime.annual': filteredData,
      'dwellTime.headers': headers,
    });
  }

  formatRegisteredUsers(data, week, firstWeekOfYear) {
    const total = (data.length)
      ? data.find((elem) => elem.week.isSame(week))
      : 0;

    const headers = (data && data.length)
      ? this.getHeaders(firstWeekOfYear, week)
      : [];

    const annualAverage = (data.length)
      ? data
        .filter((elem) => !elem.week.isSame(new Week()))
        .reduce((acc, val) => acc + val.new_registered_users, 0)
      / (headers.length - 1)
      : 0;

    set(this, 'registeredUsers.week', (total) ? total.new_registered_users : 0);
    set(this, 'registeredUsers.annualAverage', annualAverage);
  }

  fetchData() {
    this.fetchUserChannels.perform();
    this.fetchWeeklyDownloads.perform();
    this.fetchWeeklyUserProfile.perform();
    this.fetchVisits.perform();
    this.fetchRegisteredUsers.perform();
  }

  formatWeeklyDownloads(weeklyDownloads) {
    const {
      startDate,
    } = this;
    const selectedWeek = weeklyDownloads.find((data) => data.week.weekNumber === get(new Week(startDate), 'weekNumber'));
    const selectedWeekDownloads = selectedWeek ? selectedWeek.downloads : null;

    const averageDownloads = Math.round(weeklyDownloads.slice(0, -1)
      .reduce((acc, current) => acc + current.downloads, 0) / (weeklyDownloads.length - 1));

    const filteredDownloads = weeklyDownloads
      .filter((data) => data.week.isBefore(new Week(startDate))
        || data.week.isSame(new Week(startDate)));

    setProperties(this, {
      'appDownloads.week':
        {
          current: selectedWeekDownloads,
          average: averageDownloads,
        },
      'appDownloads.annual': filteredDownloads,
    });
  }

  getHeaders(firstWeek, lastWeek) {
    const headers = [];
    let currentWeek = firstWeek;
    while (currentWeek.isBefore(lastWeek) || currentWeek.isSame(lastWeek)) {
      headers.push(currentWeek.weekNumber);
      currentWeek = currentWeek.add(1, 'week');
    }
    return headers;
  }

  getWeeklyDownloads(dataAndroid, dataIos) {
    const androidDownloads = (dataAndroid.sales.sales_list)
      ? dataAndroid.sales.sales_list.map((data) => ({
        date: data.date,
        downloads: data.units.product.downloads,
      }))
      : [];
    const iosDownloads = (dataIos.sales.sales_list) ? dataIos.sales.sales_list.map((data) => ({
      date: data.date,
      downloads: data.units.product.downloads,
    }))
      : [];

    const firstData = (androidDownloads.length >= iosDownloads.length)
      ? androidDownloads : iosDownloads;
    const secondData = (androidDownloads.length >= iosDownloads.length)
      ? iosDownloads : androidDownloads;

    const totalDownloadsByDay = firstData.map((first) => {
      const secondDownloads = secondData
        .find((second) => second.date === first.date) || { downloads: 0 };

      return { week: first.date, downloads: secondDownloads.downloads + first.downloads };
    });

    let currentWeek;
    const weeks = [];
    totalDownloadsByDay.forEach((data) => {
      if (!currentWeek || !currentWeek.week.isInWeek(data.week)) {
        currentWeek = { week: new Week(new Date(data.week)), downloads: data.downloads };
        weeks.push(currentWeek);
      } else {
        currentWeek.downloads += data.downloads;
      }
    });
    return weeks;
  }

  formatWeeklyData(data, attr) {
    const weekler = (week) => new Week(new Date(formatDate(week, '%YYYY%W')));

    return (data.length)
      ? data.map((elem) => ({
        ...elem,
        week: weekler(elem[attr]),
      }))
      : [];
  }

  setZeroEmptyData(data, tick, attr) {
    return tick.map((e) => {
      const date = attr[0];
      const value = attr[1];
      const d = data.find((elem) => e === String(get(elem, `${date}`)));
      return d ? d[value] : 0;
    });
  }

  toPercentage(value, total) {
    return (value / total) * 100;
  }

  shouldFetch(data) {
    return !data.length
    || data[0].week.yearNumber !== (new Week(this.startDate)).yearNumber
    || data[0].week.yearNumber !== data[data.length - 1].week.yearNumber;
  }
}
