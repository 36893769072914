import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';

import { inject as service } from '@ember/service';
import fetch from 'fetch';

import ListMixin from 'neuro-frontend/mixins/nf-list';

export default class extends Component.extend(ListMixin, {
  campaigns: [],

  model: {
    tickets: [],
  },

  resume: {
    data: [],
    isLoading: false,
  },
}) {
  @service api

  @service auth

  modelName = 'ticket'

  sort = '-updated-at'

  @computed
  get columns() {
    return [
      {
        label: i18n.t('ticket.code'),
        valuePath: 'code',
        sortable: false,
      },
      {
        label: i18n.t('ticket.campaign-name'),
        valuePath: 'campaignrule.campaign.name',
        sortable: false,
      },
      {
        label: i18n.t('ticket.label'),
        valuePath: 'campaignrule.label',
        sortable: false,
      },
      {
        cellType: 'date',
        label: i18n.t('ticket.created-at'),
        sortable: false,
        valuePath: 'createdAt',
      },
    ];
  }

  @computed
  get resumeColumns() {
    return [
      {
        label: i18n.t('ticket.campaign'),
        valuePath: 'campaign',
        sortable: false,
      },
      {
        label: i18n.t('ticket.rule'),
        valuePath: 'rule_label',
        sortable: false,
      },
      {
        label: i18n.t('ticket.tickets-number'),
        valuePath: 'tickets',
        sortable: false,
      },
      {
        cellType: 'amount',
        label: i18n.t('ticket.pending-balance'),
        valuePath: 'pending_balance',
        sortable: false,
      },
    ];
  }

  init(...args) {
    super.init(...args);

    set(
      this,
      'fixedFilters',
      {
        user: get(this, 'user.id'),
      },
    );

    set(
      this,
      'defaultFilter',
      {
        'campaign.is-loyalty-card': 0,
        'campaign.is-raffle': 1,
      },
    );

    this.resetFilters();
  }

  // doing loadCollection in didInsertElement
  // this avoids "infinite rendering invalidation detected" problem
  async willInsertElement(...args) {
    super.willInsertElement(...args);

    await this.fetchData();
  }

  @action
  async changeCampaign(campaign) {
    set(this, 'isLoading', true);

    if (campaign) {
      set(
        this,
        'filter',
        {
          campaign: campaign.id,
        },
      );
    } else {
      this.resetFilters();
    }

    await this.fetchData();

    set(this, 'isLoading', false);
  }

  fetchData() {
    return Promise.all([
      this.fetchTicketsResume(),
      this.loadCollection(),
    ]);
  }

  async fetchTicketsResume() {
    if (!get(this, 'user.isNew')) {
      set(this, 'resume.isLoading', true);

      let resume = await fetch(
        `${this.get('api.host')}/api/v4/user_create_tickets/${get(this, 'user.id')}`,
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${get(this, 'auth.token')}`,
          },
          mode: 'cors',
        },
      ).then((response) => response.json());
      const campaigns = await this.filterRaffleCampaigns(resume);
      resume = this.resumeRaffleCampaigns(resume, campaigns);

      if (!(get(this, 'isDestroyed') || get(this, 'isDestroying'))) {
        this.resume.data.clear();
        this.resume.data.pushObjects(resume.map((value) => ({ ...value, campaign: campaigns.findBy('id', String(value.campaign_id)).name })));

        set(this, 'resume.isLoading', false);
      }
    }
  }

  async filterRaffleCampaigns(resume) {
    const {
      store,
    } = this;

    const allCampaigns = await Promise.all([
      ...new Set(resume.map((value) => value.campaign_id)),
    ].map((campaign) => store.findRecord('campaign', campaign)));

    return allCampaigns.filter((campaign) => !campaign.isLoyaltyCard && campaign.isRaffle);
  }

  resumeRaffleCampaigns(resume, campaigns) {
    const campaignsId = campaigns.map((campaign) => Number(campaign.id));
    return resume.filter((elem) => campaignsId.includes(elem.campaign_id));
  }
}
