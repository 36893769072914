import { get, computed } from '@ember/object';

import Cell from './base';

export default Cell.extend({
  classNames: ['calendar-month__cell--sales'],

  amount: computed('value', function () {
    return get(this, 'value').reduce((acc, val) => acc + get(val, 'amount'), 0);
  }),
});
