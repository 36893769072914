import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject } from '@ember/service';

export default class extends Component {
  classNames = ['properties-list-header']

  i18n = inject()

  @computed('byRoomCount')
  get propertiesByRooms() {
    const byRoomCount = get(this, 'byRoomCount');
    const properties = [
      {
        label: '1',
        count: 0,
      },
      {
        label: '2',
        count: 0,
      },
      {
        label: '3',
        count: 0,
      },
      {
        label: '4',
        count: 0,
      },
      {
        label: '5+',
        count: 0,
      },
    ];

    if (byRoomCount) {
      byRoomCount.forEach((roomCount) => {
        switch (true) {
          case [0, null].includes(roomCount['rooms-count']):
            break;
          case [1, 2, 3, 4, 5].includes(roomCount['rooms-count']):
            properties[roomCount['rooms-count'] - 1].count += roomCount.properties;
            break;
          default:
            properties[4].count += roomCount.properties;
            break;
        }
      });
    }

    return properties;
  }

  @computed('totalProperties', 'soldProperties')
  get soldPercentage() {
    const totalProperties = get(this, 'totalProperties');
    const soldProperties = get(this, 'soldProperties');
    let percentage = 0;
    if (totalProperties && soldProperties) {
      percentage = Number(Math.floor((soldProperties / totalProperties) * 100));
    }

    return `${percentage}%`;
  }
}
