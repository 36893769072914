import { computed, get } from '@ember/object';

import Model from 'ember-data/model';
import { attr, hasMany, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('boolean', { defaultValue: true }) active

  @attr('string') address

  @attr('string') city

  @attr('string') comments

  @attr('string') country

  @attr({ defaultValue() { return []; } }) files

  @attr('boolean') local

  @attr('string') name

  @attr('string') province

  @attr('number') rating

  @attr('string') vatNumber

  @attr('string') web

  @attr('string') yardi

  @attr('string') zipCode

  // Relationships

  @belongsTo('center') center

  @hasMany('service') services

  @hasMany('campaignAction', { inverse: 'supplier' }) campaignActions

  @computed()
  get validations() {
    return {
      name: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('name') }),
        },
      },

      services: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('service') }),
        },
      },

      vatNumber: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('vat-number') }),
        },
      },
    };
  }
}
