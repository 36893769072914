export default Object.freeze([
  {
    label: i18n.t('residential.start_work'),
    value: 'start_work',
  },
  {
    label: i18n.t('residential.in_construction'),
    value: 'in_construction',
  },
  {
    label: i18n.t('residential.end_of_construction'),
    value: 'end_of_construction',
  },
]);
