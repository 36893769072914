import Component from '@ember/component';
import { set, action } from '@ember/object';
import { inject as service } from '@ember/service';

import { alias } from '@ember/object/computed';

export default class EulaCommunicationsModalComponent extends Component {
  @service auth

  @service router

  @alias('auth.center') center

  init(...args) {
    super.init(...args);
    const {
      auth: {
        user,
      },
    } = this;

    if (user && !user.eula) {
      set(this, 'isOpenEulaModal', true);
    }
  }

  @action
  cancel() {
    this.router.transitionTo('index');
  }

  @action
  close() {
    // do nothing, it's only for avoiding errors
  }

  @action
  async submit() {
    const {
      auth: {
        user,
      },
    } = this;

    if (this.hasEula) {
      set(this, 'isLoading', true);

      set(user, 'eula', true);

      try {
        await user.save();
        set(this, 'isOpenEulaModal', false);
      } catch (e) {
        set(this, 'errorMessage', e.message);
      } finally {
        set(this, 'isLoading', false);
      }
    }
  }
}
