import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';
import moment from 'moment';

export default Factory.extend({

  entryTime() {
    return moment(faker.date.recent()).format('YYYY-MM-DDTHH:mm:ss');
  },

  expectedExitTime() {
    return moment(faker.date.recent()).format('YYYY-MM-DDTHH:mm:ss');
  },

  exitTime() {
    return moment(faker.date.recent()).format('YYYY-MM-DDTHH:mm:ss');
  },

  notes() {
    return faker.random.words();
  },

  child: association(),
});
