import ArrayProxy from '@ember/array/proxy';
import Service, { inject as service } from '@ember/service';

import { get, set, computed } from '@ember/object';

import Month from 'neuro-frontend/utils/month';
import Guid from 'ember-cli-guid';
import diacritics from 'diacritics';
import formatDate from 'neuro-frontend/utils/dates/format-date';
import ExportCSV from 'neuro-frontend/mixins/export-csv';

export default class extends Service.extend(ExportCSV, {}) {
  @service auth

  @service store

  selectedMonth = new Month()

  isLoading = false

  errorMessage = ''

  successMessage = ''

  yardiColumns = [
    {
      label: 'property_code',
      valuePath: 'propertyCode',
    },
    {
      label: 'lease_code',
      valuePath: 'leaseCode',
    },
    {
      label: 'sales_type_code',
      valuePath: 'salesTypeCode',
    },
    {
      label: 'sales_date_from',
      valuePath: 'salesDateFrom',
    },
    {
      label: 'sales_date_to',
      valuePath: 'salesDateTo',
    },
    {
      label: 'actual_amount',
      valuePath: 'amount',
    },
    {
      label: 'locked',
      valuePath: 'locked',
    },
    {
      label: 'sales_reported',
      valuePath: 'salesReported',
    },
  ]

  @computed('auth.center.name', 'selectedMonth')
  get yardiFileName() {
    const centerName = get(this, 'auth.center.name');
    const selectedMonth = get(this, 'selectedMonth');
    const center = (centerName)
      ? `-${centerName}`
      : '';
    const month = (selectedMonth)
      ? `-${selectedMonth}`
      : '';
    return diacritics.remove(`YardiSales${center}${month}.csv`.split(' ').join('_'));
  }

  @computed('state', 'message')
  get yardiResponse() {
    const {
      state,
      message,
    } = this;

    return {
      state,
      message,
    };
  }

  async createYardi(selectedMonth) {
    set(this, 'selectedMonth', selectedMonth);
    const columns = get(this, 'yardiColumns');

    return this.uploadYardi(
      this.createCSV(
        new ArrayProxy({
          content: await this.getYardiSales(),
        }),
        {
          columns,
          extra: ['CommSalesDatas'],
        },
      ),
      get(this, 'yardiFileName'),
    ).then(() => {
      set(this, 'state', 'success');
      set(this, 'message', i18n.t('informative-message.upload-yardi.success'));
    })
      .catch(() => {
        set(this, 'state', 'error');
        set(this, 'message', i18n.t('informative-message.upload-yardi.error'));
      });
  }

  async exportYardi(selectedMonth) {
    set(this, 'selectedMonth', selectedMonth);
    const columns = get(this, 'yardiColumns');
    this.exportCSV(
      new ArrayProxy({
        content: await this.getYardiSales(),
      }),
      get(this, 'yardiFileName'), {
        columns,
        extra: ['CommSalesDatas'],
      },
    );
  }

  async getYardiSales() {
    const sales = [];
    const {
      store,
      selectedMonth: {
        startDate,
        endDate,
      },
      auth: {
        centerId,
      },
    } = this;

    const intranetSales = await store.query('intranetSale', {
      filter: {
        center: centerId,
        date: [{
          start_date: startDate,
          end_date: endDate,
          op: 'between',
        }],
      },
    });

    intranetSales.forEach((sale) => {
      const saleObject = sales.find((elem) => get(elem, 'commerceId') === get(sale, 'commerce.id')
        && get(elem, 'salesTypeCode') === get(sale, 'saleConcept.yardiCode'));

      if (!saleObject) {
        sales.push({
          amount: get(sale, 'amount'),
          commerceId: get(sale, 'commerce.id'),
          leaseCode: get(sale, 'commerce.tenantCode'),
          propertyCode: get(sale, 'commerce.shopProperty.yardiCode'),
          salesTypeCode: get(sale, 'saleConcept.yardiCode'),
          locked: 0,
          salesReported: -1,
          salesDateFrom: formatDate(new Date(startDate), 'Y-m-dTH:i:sP', 'DD/MM/YYYY'),
          salesDateTo: formatDate(new Date(endDate), 'Y-m-dTH:i:sP', 'DD/MM/YYYY'),
        });
      } else {
        // We convert amount into a number because toFixed return a string
        set(saleObject, 'amount', Number((get(saleObject, 'amount') + (get(sale, 'amount'))).toFixed(2)));
      }
    });

    return sales;
  }

  uploadYardi(csv, fileName) {
    const store = get(this, 'store');
    const model = store.createRecord(
      'yardi-upload',
      {
        id: Guid.create(),
        file: csv,
        name: fileName,
        centerId: get(this, 'auth.centerId'),
      },
    );

    return model.save();
  }
}
