import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service can

  beforeModel() {
    if (
      !this.can.can('access to module-intranet')
      && !this.can.can('access to intranet-sale')
    ) {
      this.transitionTo('index');
    }
  }
}
