import { attr, belongsTo } from '@ember-data/model';
import Model from 'ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('datetime') createdAt

  // Relationships
  @belongsTo('campaign') campaign

  @belongsTo('user') user
}
