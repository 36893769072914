import { computed } from '@ember/object';
import { Column } from 'ember-light-table';
import Cell from 'ember-light-table/components/cells/base';
import { getRoleByLevel } from '../../../utils/communication-roles';

export default Cell.extend({
  column: new Column(),

  role: computed('value.level', function () {
    try {
      return getRoleByLevel(this.get('value.level'));
    } catch (e) {
      return '';
    }
  }),
});
