import { set, get } from '@ember/object';
import config from '../../config/environment';

const {
  i18n: {
    defaultLocale,
  },
} = config;

/**
* Function that define the message in a missing tranlation.
*   If the translation exists in the default locale, it returns
*   the translation.
*
* @param locale     locale code from tranlation
* @param key        key code used to make the translation
* @return String    A string with the error message or the translation in
*                   the default language.
*/
const missingMessage = function (locale, key, data) {
  if (locale === defaultLocale) {
    return key;
  }
  const i18nService = this;
  const backLocale = get(i18nService, 'locale');
  let result = key;

  set(i18nService, 'locale', defaultLocale);

  result = i18nService.t(key, data);

  set(i18nService, 'locale', backLocale);

  return result;
};

export default missingMessage;
