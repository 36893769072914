export default Object.freeze([
  {
    label: i18n.t('sale'),
    value: 'sale',
  },
  {
    label: i18n.t('rental'),
    value: 'rental',
  },
  {
    label: i18n.t('both'),
    value: 'both',
  },
]);
