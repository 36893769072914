import { task } from 'ember-concurrency-decorators';
import fetch from 'fetch';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

import FormElement from 'neuro-frontend/components/base/form-element';

export default class extends FormElement {
  @service api

  @task
  resetPassword = function* () {
    set(this, 'didError', false);

    const {
      status,
    } = yield fetch(
      `${get(this, 'api.host')}/api/v4/staff_members/reset_password`,
      {
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify({
          data: {
            type: 'staff_members',
            attributes: {
              username: get(this, 'email'),
            },
          },
        }),
        method: 'POST',
      },
    );

    set(this, (204 === status) ? 'didSuccess' : 'didError', true);
  }
}
