export default Object.freeze([
  {
    color: '#b388ff',
    label: i18n.t('residential-property.state.free'),
    value: 'free',
  },
  {
    color: '#fbc02d',
    label: i18n.t('residential-property.state.reserved'),
    value: 'reserved',
  },
  {
    color: '#E57373',
    label: i18n.t('residential-property.state.blocked'),
    value: 'blocked',
  },
  {
    color: '#4db6ac',
    label: i18n.t('residential-property.state.sold'),
    value: 'sold',
  },
]);
