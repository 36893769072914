import Controller from '@ember/controller';
import {
  set, get, action, computed,
} from '@ember/object';

import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

import propertyStates from 'neuro-frontend/enums/residential-property-states';
import orientations from 'neuro-frontend/enums/orientations';
import visibilities from 'neuro-frontend/enums/residential-property-visibilities';

export default class ResidentialPropertiesNewController extends Controller {
  @service apiFiles

  @service auth

  isReserved = false

  orientations = orientations

  propertyStates = propertyStates

  visibilities = visibilities

  @alias('model.residentialDevelopments') residentialDevelopments

  @alias('model.residentialProperty') residentialProperty

  @alias('model.propertyTypes') propertyTypes

  @computed()
  get tabs() {
    return [
      {
        icon: 'location_city',
        label: i18n.t('property.data'),
        name: 'general',
      },
      {
        icon: 'airline_seat_individual_suite',
        label: i18n.t('property.detailed-spaces'),
        name: 'rooms',
      },
      {
        icon: 'photo_library',
        label: i18n.t('residential.multimedia'),
        name: 'multimedia',
      },
      {
        icon: 'language',
        label: i18n.t('web'),
        name: 'web',
      },
    ];
  }

  @action
  attachFiles(files, category = '') {
    const {
      model,
    } = this;

    if (!(files instanceof Array)) {
      throw new TypeError(`attachFiles: ${files} should be of type Array`);
    }

    const newFiles = files.map((file) => {
      set(file, 'category', category);

      return file;
    });

    if (!(model.residentialProperty.files instanceof Array)) {
      set(model, 'residentialProperty.files', newFiles);
    } else {
      model.residentialProperty.files.pushObjects(newFiles);
    }
  }

  @action
  removeFile(file) {
    get(this, 'model.residentialProperty.files').removeObject(file);
  }

  @action
  searchUsers(term) {
    const {
      auth: {
        centerId,
      },
      store,
    } = this;

    return store.query('user', {
      filter: {
        q: term,
        center_id: centerId,
      },
    });
  }

  @action
  async saveProperty(save) {
    const {
      model: {
        residentialProperty: property,
      },
    } = this;

    set(this, 'isLoading', true);

    if (['sold', 'reserved'].includes(property.state)) {
      await this.sellProperty();
    }

    return save()
      .then(() => set(this, 'isOpenModal', false))
      .finally(() => set(this, 'isLoading', false));
  }

  @action
  submitProperty(model) {
    return new Promise((resolve, reject) => {
      const errors = get(this, 'errors');

      // This attr errors are checked here and not in model because they are attrs of offer model
      if (['sold', 'reserved'].includes(model.state)) {
        ['user', 'reservedDate'].forEach((attr) => {
          const translations = {
            reservedDate: i18n.t('property.reserved-date'),
            user: i18n.t('property.user'),
          };

          if (!this[attr]) {
            errors[attr] = [i18n.t('required', { attr: translations[attr] })];
          }
        });
      }

      if (Object.keys(errors).length) {
        reject(errors);
      } else {
        set(this, 'isOpenModal', true);
        resolve(false);
      }
    });
  }

  @action
  async transformPdf(files, category = '') {
    this.attachFiles(
      await Promise.all([
        ...files,
        ...files.filter((file) => file.url.match(/pdf$/gi))
          .map((file) => this.apiFiles.transformPdfToJpg(file)),
      ]),
      category,
    );
  }

  async sellProperty() {
    const {
      model: {
        residentialProperty: property,
      },
      reservedDate,
      store,
    } = this;
    const development = await property.residentialDevelopment;

    const request = store.createRecord(
      'residentialRequest',
      {
        filters: {
          residential_development: [{
            op: 'is',
            value: development.id,
          }],
        },
        name: development.name,
        staff: this.auth.user,
        requestType: 'and',
        user: this.user,
      },
    );

    await request.save();

    const proposal = store.createRecord(
      'residentialProposal',
      {
        residentialProperty: property,
        residentialRequest: request,
      },
    );

    await proposal.save();

    const offer = store.createRecord(
      'residentialOffer',
      {
        notes: get(this, 'notes'),
        openingDate: reservedDate,
        price: property.price,
        residentialProposal: proposal,
        state: ('sold' === property.state) ? 'signed' : 'accepted',
      },
    );

    await offer.save();
  }
}
