import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

export default Component.extend({
  classNames: ['nf-check-input'],

  checked: computed('value', function () {
    return null !== get(this, 'value') && undefined !== get(this, 'value');
  }),

  actions: {
    cleanValue() {
      const checked = get(this, 'checked');
      if (!checked) {
        set(this, 'value', null);
      }
    },
  },
});
