import Component from '@ember/component';
import fetch from 'fetch';
import {
  get, set, setProperties, action, computed,
} from '@ember/object';
import { classNames } from '@ember-decorators/component';

import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

import { getLastYearsMonths } from 'neuro-frontend/utils/get-last-months';
import Day from 'neuro-frontend/utils/day';
import Month from 'neuro-frontend/utils/month';
import Year from 'neuro-frontend/utils/year';
import formatDate from 'neuro-frontend/utils/dates/format-date';

@classNames('dashboard-crm')
export default class extends Component.extend({
    selectedYear: new Year(),
    lastRequestedYear: new Year(),
    refreshing: false,
  }) {
  @service api

  @service auth

  @service stats

  data = {
    userChannels: {},
    monthlyUserChannels: {},
    userMail: {},
  }

  userChannels = {
    appUsers: [],
    otherUsers: [],
    playCenterUsers: [],
    socialUsers: [],
    webUsers: [],
    headers: [],
  }

  userMail = {
    app: {},
    'play-center': {},
    'social-wifi': {},
    web: {},
    other: {},
  }

  prevYearUserMail = {
    app: {},
    'play-center': {},
    'social-wifi': {},
    web: {},
    other: {},
  }

  prevMonthUserMail = {
    app: {},
    'play-center': {},
    'social-wifi': {},
    web: {},
    other: {},
  }

  activeUsers = {
    day: [],
  }

  appUsers = {
    month: [],
  }

  months = getLastYearsMonths(1)

  otherUsers = {
    month: [],
  }

  playCenterUsers = {
    month: [],
  }

  registeredUsers = {
    month: [],
    day: [],
  }

  selectedMonth = new Month();

  socialUsers = {
    month: [],
  }

  userProfiles = {
    month: [],
    day: [],
  }

  webUsers = {
    month: [],
  }

  @computed(
    'fetchActiveUsersDay.isRunning',
    'fetchRegisteredUsersDay.isRunning',
    'fetchRegisteredUsersMonth.isRunning',
    'fetchUsersPerChannelMonth.isRunning',
    'fetchUserProfilesDay.isRunning',
  )
  get isLoading() {
    return this.fetchActiveUsersDay.isRunning
      || this.fetchRegisteredUsersDay.isRunning
      || this.fetchRegisteredUsersMonth.isRunning
      || this.fetchUserProfilesDay.isRunning;
  }

  @computed(
    'fetchUserChannels.isRunning',
    'fetchUsersPerChannelMonth.isRunning',
  )
  get isLoadingOptimized() {
    return this.fetchUserChannels.isRunning
    || this.fetchUsersPerChannelMonth.isRunning;
  }

  @computed('selectedMonth')
  get prevMonth() {
    const {
      selectedMonth,
    } = this;

    if (!selectedMonth) {
      return null;
    }

    const prevMonth = new Month(new Date(selectedMonth.startDate));
    prevMonth.addMonths(-1);

    return prevMonth;
  }

  @computed('selectedMonth')
  get prevYearMonth() {
    const {
      selectedMonth,
    } = this;

    if (!selectedMonth) {
      return null;
    }

    const prevYearMonth = new Month(new Date(selectedMonth.startDate));
    prevYearMonth.addYears(-1);

    return prevYearMonth;
  }

  @computed
  get tabs() {
    return [
      {
        icon: 'insert_chart',
        label: i18n.t('overview'),
        name: 'overview',
      },
      {
        icon: 'dashboard',
        label: i18n.t('channels'),
        name: 'channels',
      },
      {
        icon: 'group_work',
        label: i18n.t('demographic'),
        name: 'demographic',
      },
    ];
  }

  @computed('selectedMonth')
  get headers() {
    const {
      selectedMonth,
    } = this;

    let currentDate = new Day(new Date(selectedMonth.startDate));
    const endDate = new Day(new Date(selectedMonth.endDate));
    const headers = [];

    while (!currentDate.isAfter(endDate)) {
      headers.push(currentDate.value);
      currentDate = currentDate.getTomorrow();
    }

    return headers;
  }

  didReceiveAttrs() {
    this.fetchData();
  }

  @action
  changeFilters() {
    const {
      selectedYear,
    } = this;

    set(this, 'lastRequestedYear', selectedYear);
    set(this, 'selectedYear', new Year(new Date(this.selectedMonth.endDate)));

    this.fetchData();
  }

  @restartableTask
  fetchActiveUsersDay = function* () {
    const { stats } = this;
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(get(this, 'auth.centerId'))],
      group_by: ['day'],
    });

    const data = yield stats.getData(
      'statsactiva/user_activity',
      getQuery(this.selectedMonth.startDatetime, this.selectedMonth.endDatetime),
    );

    set(this, 'activeUsers.day', data.sort((elem, next) => (elem.date > next.date ? 1 : -1)));
  }

  @restartableTask
  fetchRegisteredUsersDay = function* () {
    const getQuery = (startDate, endDate) => ({
      center_id: [get(this, 'auth.centerId')],
      end_date: endDate,
      group_by: [
        'day',
      ],
      start_date: startDate,
    });
    const query = getQuery(this.selectedMonth.startDatetime, this.selectedMonth.endDatetime);
    const data = yield fetch(
      `${get(this, 'api.host')}/api/v4/stats/registered_users?${$.param(query)}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${get(this, 'auth.token')}`,
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json());
    set(this, 'registeredUsers.day', data);
  }

  @restartableTask
  fetchRegisteredUsersMonth = function* () {
    const getQuery = (startDate, endDate) => ({
      center_id: [get(this, 'auth.centerId')],
      end_date: endDate,
      group_by: [
        'month',
      ],
      start_date: startDate,
    });
    const query = getQuery(this.prevYearMonth.startDatetime, this.selectedMonth.endDatetime);
    const data = yield fetch(
      `${get(this, 'api.host')}/api/v4/stats/registered_users?${$.param(query)}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${get(this, 'auth.token')}`,
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json());
    set(this, 'registeredUsers.month', data);
  }

  @restartableTask
  fetchUsersPerChannelMonth = function* () {
    const getQuery = (startDate, endDate) => ({
      center_id: [get(this, 'auth.centerId')],
      end_date: endDate,
      group_by: [
        'month',
      ],
      start_date: startDate,
    });
    const query = getQuery(
      this.prevYearMonth.startDatetime,
      this.selectedMonth.endDatetime,
    );

    if (this.shouldFetch() || this.refreshing) {
      set(
        this,
        'data.monthlyUserChannels',
        yield fetch(
          `${get(this, 'api.host')}/api/v4/stats/user_channels?${$.param(query)}`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${get(this, 'auth.token')}`,
              'Content-Type': 'application/json',
            },
          },
        ).then((response) => response.json()),
      );
    }

    this.formatMonthlyUserChannels(this.data.monthlyUserChannels);
  }

  @restartableTask
  fetchUserProfilesMonth = function* () {
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(get(this, 'auth.centerId'))],
      group_by: ['month'],
    });
    const query = getQuery(this.selectedMonth.startDatetime, this.selectedMonth.endDatetime);

    const data = yield fetch(`${get(this, 'api.host')}/api/v4/stats/user_profiles?${$.param(query)}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${get(this, 'auth.token')}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

    set(this, 'userProfiles.month', data);
  }

  @restartableTask
  fetchUserProfilesDay = function* () {
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(get(this, 'auth.centerId'))],
      group_by: ['day'],
    });
    const query = getQuery(this.selectedMonth.startDatetime, this.selectedMonth.endDatetime);

    const data = yield fetch(`${get(this, 'api.host')}/api/v4/stats/user_profiles?${$.param(query)}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${get(this, 'auth.token')}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

    set(this, 'userProfiles.day', data);
  }

  @restartableTask
  fetchUserChannels = function* () {
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(get(this, 'auth.centerId'))],
      group_by: ['day'],
    });

    const {
      selectedYear,
    } = this;

    const query = getQuery(formatDate(selectedYear.startDate), formatDate(selectedYear.endDate));

    if (this.shouldFetch() || this.refreshing) {
      set(
        this,
        'data.userChannels',
        this.formatDailyData(
          yield fetch(`${get(this, 'api.host')}/api/v4/stats/user_channels?${$.param(query)}`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${get(this, 'auth.token')}`,
              'Content-Type': 'application/json',
            },
          }).then((response) => response.json()),
          'day',
        ),
      );
    }

    this.formatUserChannelsPerDay(this.data.userChannels);
  }

  @restartableTask
  fetchMailNotifications = function* () {
    const getQuery = (startDate, endDate) => ({
      center_id: [get(this, 'auth.centerId')],
      end_date: endDate,
      group_by: [
        'mail_notifications',
        'has_email',
        'month',
      ],
      start_date: startDate,
    });

    if (this.shouldFetch() || this.refreshing) {
      const query = getQuery(this.prevYearMonth.startDatetime, this.selectedMonth.endDatetime);
      set(
        this,
        'data.userMail',
        yield fetch(
          `${get(this, 'api.host')}/api/v4/stats/user_channels?${$.param(query)}`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${get(this, 'auth.token')}`,
              'Content-Type': 'application/json',
            },
          },
        ).then((response) => response.json()),
      );
      this.data.userMail.push({
        channel: 'social-wifi',
        count: 7,
        has_email: 0,
        mail_notifications: 1,
        month: '2019-12-01',
      });
      this.data.userMail.push({
        channel: 'play-center',
        count: 7,
        has_email: 0,
        mail_notifications: 1,
        month: '2019-12-01',
      });
    }

    setProperties(this, {
      userMail:
        this.formatMailNotifications(this.data.userMail, this.selectedMonth),
      prevMonthUserMail:
        this.formatMailNotifications(this.data.userMail, this.prevMonth),
      prevYearUserMail:
        this.formatMailNotifications(this.data.userMail, this.prevYearMonth),
    });
  }

  @action
  refresh() {
    set(this, 'refreshing', true);
    this.fetchData();
    set(this, 'refreshing', false);
  }

  fetchData() {
    this.fetchActiveUsersDay.perform();
    this.fetchRegisteredUsersDay.perform();
    this.fetchRegisteredUsersMonth.perform();
    this.fetchUserChannels.perform();
    this.fetchUsersPerChannelMonth.perform();
    this.fetchUserProfilesMonth.perform();
    this.fetchUserProfilesDay.perform();
    this.fetchMailNotifications.perform();
  }

  formatDailyData(data, attr) {
    return (data.length)
      ? data.map((elem) => ({
        ...elem,
        day: new Day(new Date(elem[attr])),
      }))
      : [];
  }

  shouldFetch() {
    const {
      selectedYear,
      lastRequestedYear,
      data: {
        userChannels,
        monthlyUserChannels,
      },
    } = this;

    return !userChannels.length
      || !monthlyUserChannels.length
      || !selectedYear.isSame(lastRequestedYear);
  }

  formatUserChannelsPerDay(data) {
    const appUsers = [];
    const playCenterUsers = [];
    const socialUsers = [];
    const webUsers = [];
    const otherUsers = [];

    const {
      selectedMonth,
      headers,
    } = this;

    if (data.length) {
      data.forEach((userChannel) => {
        const currentMonth = get(userChannel.day, 'month');

        if (currentMonth.isSame(selectedMonth)) {
          switch (userChannel.channel) {
            case 'app':
              appUsers.push(userChannel);
              break;
            case 'play-center':
              playCenterUsers.push(userChannel);
              break;
            case 'social-wifi':
              socialUsers.push(userChannel);
              break;
            case 'web':
              webUsers.push(userChannel);
              break;
            default:
              otherUsers.push(userChannel);
              break;
          }
        }
      });
    }

    setProperties(this, {
      'userChannels.appUsers': appUsers,
      'userChannels.playCenterUsers': playCenterUsers,
      'userChannels.socialUsers': socialUsers,
      'userChannels.webUsers': webUsers,
      'userChannels.otherUsers': otherUsers,
      'userChannels.headers': headers,
    });
  }

  formatMonthlyUserChannels(data) {
    const appUsers = [];
    const playCenterUsers = [];
    const socialUsers = [];
    const webUsers = [];
    const otherUsers = [];

    if (data.length) {
      data.forEach((userRegister) => {
        const currentMonth = new Month(new Date(userRegister.month));

        if (currentMonth.isAfter(this.prevYearMonth)
          || currentMonth.isSame(this.prevYearMonth)) {
          switch (userRegister.channel) {
            case 'app':
              appUsers.push(userRegister);
              break;
            case 'play-center':
              playCenterUsers.push(userRegister);
              break;
            case 'social-wifi':
              socialUsers.push(userRegister);
              break;
            case 'web':
              webUsers.push(userRegister);
              break;
            default:
              otherUsers.push(userRegister);
              break;
          }
        }
      });
    }

    setProperties(this, {
      'appUsers.month': appUsers,
      'playCenterUsers.month': playCenterUsers,
      'socialUsers.month': socialUsers,
      'webUsers.month': webUsers,
      'otherUsers.month': otherUsers,
    });
  }

  formatMailNotifications(data, month) {
    const userMail = {
      app: {
        hasEmail: 0,
        mailNotifications: 0,
        total: 0,
      },
      'play-center': {
        hasEmail: 0,
        mailNotifications: 0,
        total: 0,
      },
      'social-wifi': {
        hasEmail: 0,
        mailNotifications: 0,
        total: 0,
      },
      web: {
        hasEmail: 0,
        mailNotifications: 0,
        total: 0,
      },
      other: {
        hasEmail: 0,
        mailNotifications: 0,
        total: 0,
      },
    };

    if (data.length) {
      const formattedData = data
        .filter((userRegister) => (new Month(new Date(userRegister.month)).isSame(month)));

      formattedData.forEach((userRegister) => {
        this.sumMailData(userMail, userRegister, userRegister.channel);
      });
    }

    return userMail;
  }

  sumMailData(data, register, channel) {
    const channelData = get(data, channel);

    if (register.mail_notifications) {
      channelData.mailNotifications += register.count;
    }

    if (register.has_email) {
      channelData.hasEmail += register.count;
    }

    channelData.total += register.count;
    set(data, channel, channelData);

    return data;
  }
}
