export default Object.freeze([
  {
    label: i18n.t('admin'),
    value: 'admin',
    creators: ['admin'],
  },
  {
    label: i18n.t('calibrator'),
    value: 'calibrator',
    creators: ['admin'],
  },
  {
    label: i18n.t('commerce'),
    value: 'commerce',
    creators: ['admin', 'manager'],
  },
  {
    label: i18n.t('crm-manager'),
    value: 'crmmanager',
    creators: ['admin', 'manager'],
  },
  {
    label: i18n.t('crm-operator'),
    value: 'crmoperator',
    creators: ['admin', 'manager'],
  },
  {
    label: i18n.t('external-api'),
    value: 'external',
    creators: ['admin'],
  },
  {
    label: i18n.t('manager'),
    value: 'manager',
    creators: ['admin', 'manager'],
  },
  {
    label: i18n.t('owner'),
    value: 'owner',
    creators: ['admin'],
  },
  {
    label: i18n.t('scmanager'),
    value: 'scmanager',
    creators: ['admin'],
  },
  {
    label: i18n.t('store-manager'),
    value: 'storeManager',
    creators: [],
  },
  {
    label: i18n.t('web-operator'),
    value: 'weboperator',
    creators: ['admin', 'manager'],
  },
  {
    label: i18n.t('staff-level-sales'),
    value: 'sales',
    creators: ['admin', 'manager'],
  },
  {
    label: i18n.t('staff-level-services'),
    value: 'services',
    creators: ['admin', 'manager'],
  },
]);
