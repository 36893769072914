import { Factory } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  level() {
    return faker.random.number();
  },

  name() {
    return faker.random.word();
  },

  priority() {
    return faker.random.number();
  },
});
