import Controller from '@ember/controller';
import {
  action, computed, get, set, setProperties,
} from '@ember/object';
import { inject as service } from '@ember/service';

import { classify } from '@ember/string';

import formatDate from 'neuro-frontend/utils/dates/format-date';
import parseErrors from 'neuro-frontend/utils/parse-errors-array-to-errors-object';
import frequencyTypes from 'neuro-frontend/enums/email-campaign-frequency-types';

import moment from 'moment';

export default class EmailingCampaignsNewController extends Controller {
  @service apiEmailCampaigns

  currentStep = 1

  emailList = ''

  frequencyTypes = frequencyTypes

  @computed('currentStep')
  get activeSendEmailTest() {
    return 2 === this.currentStep;
  }

  @computed('currentStep')
  get disableSubmit() {
    return 3 !== this.currentStep;
  }

  @computed('model.campaign.endDatetime')
  get endDatetimeFormatted() {
    const date = get(this, 'model.campaign.endDatetime');
    return formatDate(date, 'Y-m-dTH:i:sP', 'DD-MM-YYYY HH:mm');
  }

  @computed('model.campaign.startDatetime', 'model.campaign.endDatetime')
  get isUniqueSent() {
    return moment(get(this, 'model.campaign.endDatetime'))
      .isSame(get(this, 'model.campaign.startDatetime'));
  }

  @computed('model.campaign.startDatetime')
  get startDatetimeFormatted() {
    const date = get(this, 'model.campaign.startDatetime');
    return formatDate(date, 'Y-m-dTH:i:sP', 'DD-MM-YYYY HH:mm');
  }

  @computed
  get stepsInfo() {
    return [{
      stepId: 1,
      label: i18n.t('email-campaign.form.general_info'),
      validateAttrs: ['name', 'subject', 'segment', 'startDatetime', 'endDatetime'],
    }, {
      isDisabled: true,
      stepId: 2,
      label: i18n.t('email-campaign.form.desing_template'),
      validateAttrs: ['htmlBody'],
    }, {
      isDisabled: true,
      stepId: 3,
      label: i18n.t('email-campaign.form.summary'),
      validateAttrs: null,
    }];
  }

  @action
  changeStep(newStep) {
    const newStepIndex = this.getStepIndex(newStep);

    if (0 > newStepIndex) {
      return;
    }

    if (this.isValidModel()) {
      set(this, 'currentStep', newStep);
    }
  }

  @action
  clearEndDatetime() {
    setProperties(
      this.model.campaign, {
        startDatetime: null,
        endDatetime: null,
      },
    );
  }

  @action
  importTemplate() {
    const template = get(this, 'importTemplateHtmlBody');
    if (template) {
      set(this, 'model.campaign.htmlBody', template);
    }
    this.toggleProperty('isOpenImportTemplateModal');
  }

  @action
  openModal(type) {
    set(this, 'error', null);
    this.toggleProperty(`isOpen${classify(type)}Modal`);
  }

  @action
  readImportedHtml(file) {
    const reader = new FileReader();
    if ('text/html' !== file.type) {
      set(this, 'importTemplateError', true);
      return;
    }
    set(this, 'importTemplateLoading', true);

    reader.onload = (ev) => {
      set(this, 'importTemplateHtmlBody', ev.target.result);
      set(this, 'importTemplateError', false);
      set(this, 'importTemplateLoading', false);
      set(this, 'isOpenConfirmImport', true);
    };
    reader.onerror = () => {
      set(this, 'importTemplateError', true);
      set(this, 'importTemplateLoading', false);
    };
    reader.readAsText(file, 'UTF-8');
  }

  @action
  async sendCampaign() {
    await this.apiEmailCampaigns.sendSchedule(this.model.campaign.id);

    this.transitionToRoute('emailing.campaigns');
  }

  getStepIndex(stepId) {
    return this.stepsInfo.findIndex((step) => stepId === step.stepId);
  }

  isValidModel() {
    const currentStepIndex = this.getStepIndex(get(this, 'currentStep'));
    const attrs = get(this, `stepInfo.${currentStepIndex}.validateAttrs`);
    if ('single-sending' === get(this, 'model.campaign.frequency')) {
      set(this, 'model.campaign.endDatetime', get(this, 'model.campaign.startDatetime'));
    }
    const isValid = get(this, 'model.campaign').validate(attrs ? { only: attrs } : {});
    if (!isValid) {
      set(this, 'errors', parseErrors(get(this, 'model.campaign.errors')));
      return isValid;
    }

    set(this, 'errors', {});
    return isValid;
  }
}
