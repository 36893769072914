import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';

import { Column } from 'ember-light-table';
import Cell from 'ember-light-table/components/cells/base';
import moment from 'moment';

export default class extends Cell {
  column = new Column()

  @alias('row') emailCampaign

  @computed('emailCampaign.startDatetime', 'emailCampaign.endDatetime')
  get isUniqueSent() {
    return moment(get(this, 'emailCampaign.endDatetime'))
      .isSame(get(this, 'emailCampaign.startDatetime'));
  }
}
