import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import { CanMixin } from 'ember-can';

export default Route.extend(CanMixin, {
  pageHeader: service(),

  beforeModel() {
    set(
      this,
      'pageHeader.title',
      get(this, 'i18n').t('title.header.crm.users'),
    );
  },
});
