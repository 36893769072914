export default Object.freeze([
  {
    label: i18n.t('folder'),
    type: 'folder',
    value: 'assets/images/ic_folder.svg',
  },
  {
    label: i18n.t('doc'),
    type: 'word',
    value: 'assets/images/ic_file_doc.svg',
  },
  {
    label: i18n.t('spreadsheet'),
    type: 'spreadsheet',
    value: 'assets/images/ic_file_xsl.svg',
  },
  {
    label: i18n.t('pdf'),
    type: 'pdf',
    value: 'assets/images/ic_file_pdf.svg',
  },
  {
    label: i18n.t('presentation'),
    type: 'presentation',
    value: 'assets/images/ic_file_ppt.svg',
  },
]);
