import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { CanMixin } from 'ember-can';

import Month from 'neuro-frontend/utils/month';
import FormRoute from 'neuro-frontend/routes/form';
import formatDate from 'neuro-frontend/utils/dates/format-date';

const Route = FormRoute.extend(CanMixin, {});

export default class extends Route {
  @service auth

  templateName = 'residential/campaigns/new'

  beforeModel() {
    if (!this.can('access to module-residential')) {
      this.transitionTo('index');
    }
  }

  async model({ campaign_id: campaignId }) {
    const store = get(this, 'store');
    const month = new Month();
    const campaignTypes = await store.query('campaigntype', {
      filter: {
        center_id: this.auth.centerId,
      },
    });
    const campaign = campaignId
      ? store.findRecord(
        'campaign',
        campaignId,
      )
      : store.createRecord(
        'campaign',
        {
          center: get(this, 'auth.center'),
          campaigntype: campaignTypes.firstObject,
          enabled: true,
          endDate: formatDate(month.endDate, 'YYYY-MM-DD', 'DD/MM/YYYY'),
          startDate: formatDate(month.startDate, 'YYYY-MM-DD', 'DD/MM/YYYY'),
        },
      );

    return RSVP.hash({
      campaign,
    });
  }
}
