import { get, set, computed } from '@ember/object';

import moment from 'moment';

import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';
import Month from 'neuro-frontend/utils/month';
import Year from 'neuro-frontend/utils/year';
import formatDate from 'neuro-frontend/utils/dates/format-date';
/**
 * @module    nf
 * @submodule nf-utils
 */
export default class Day {
  value = ''

  @computed('value')
  get date() {
    return Number(this.value.split('-')[2]);
  }

  @computed('value')
  get month() {
    return new Month(new Date(this.value));
  }

  @computed('value')
  get weekDay() {
    return moment(this.value).isoWeekday();
  }

  @computed('value')
  get year() {
    return new Year(new Date(this.value));
  }

  constructor(date = new Date()) {
    if (!(date instanceof Date)) {
      throw new TypeError(`${date} isn't of type Date`);
    }

    if (Number.isNaN(date.getTime())) {
      throw new IllegalArgumentError('param "date" is an invalid Date');
    }

    set(this, 'value', moment(date).format('YYYY-MM-DD'));
  }

  addMonths(months) {
    if (!Number.isInteger(months)) {
      throw new TypeError(`addMonths: ${months} is not a Number`);
    }
    const newValue = moment(this.value).add(months, 'month').format('YYYY-MM-DD');
    set(this, 'value', newValue);

    return newValue;
  }

  belongsToMonth(month) {
    if (!(month instanceof Month)) {
      throw new TypeError(`belongsToMonth: ${month} is not of type Month`);
    }

    return get(month, 'value') === get(this, 'month.value');
  }

  format(pattern) {
    return formatDate(get(this, 'value'), 'YYYY-MM-DD', pattern);
  }

  getTomorrow() {
    const date = moment(this.value).add(1, 'days');

    return new Day(new Date(date.format('YYYY-MM-DD')));
  }

  isSame(anotherDay) {
    const {
      value,
    } = this;

    return moment(value).isSame(anotherDay.value, 'day');
  }

  isAfter(anotherDay) {
    const {
      value,
    } = this;

    return moment(value).isAfter(anotherDay.value, 'day');
  }

  add(n, str) {
    const {
      value,
    } = this;

    return new Day(new Date(moment(value, 'YYYY-MM-DD').add(n, str)));
  }

  subtract(n, str) {
    const {
      value,
    } = this;

    return new Day(new Date(moment(value, 'YYYY-MM-DD').subtract(n, str)));
  }
}
