import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import campaignStatus from './campaign-status';

export default [
  {
    groupName: i18n.t('campaign.filters'),
    options: [
      {
        label: i18n.t('campaigntype'),
        model: 'campaigntypes',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'campaigntype',
      },

      {
        label: i18n.t('status'),
        model: campaignStatus,
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'status',
      },

      {
        label: i18n.t('date'),
        operation: new FilterBetween({
          appendDates: 'Datetime',
        }),
        value: 'date',
      },
    ],
  },
];
