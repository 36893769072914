import Component from '@ember/component';
import { get, computed } from '@ember/object';
import shopMonthStates from 'neuro-frontend/enums/shop-month-states';

export default class extends Component {
  classNames = ['shop-month']

  classNameBindings = [
    'isClosed:shop-month--is_closed',
    'isUnclosed:shop-month--is_open',
    'isValidated:shop-month--is_validated',
    'isWithoutSales:shop-month--is_without_sales',
  ]

  @computed('sales', 'shop')
  get filteredSales() {
    const {
      sales,
      shop,
    } = this;

    return sales ? sales.filterBy('commerce_id.id', shop.id) : [];
  }

  @computed('state.value')
  get isClosed() {
    const value = get(this, 'state.value');

    return 'closed' === value;
  }

  @computed('state.value')
  get isUnclosed() {
    const value = get(this, 'state.value');

    return 'unclosed' === value;
  }

  @computed('state.value')
  get isValidated() {
    const value = get(this, 'state.value');

    return 'validated' === value;
  }

  @computed('state.value')
  get isWithoutSales() {
    const value = get(this, 'state.value');

    return 'without_sales' === value;
  }

  @computed('month', 'filteredSales', 'shop')
  get state() {
    const {
      month,
      shop,
    } = this;

    return shopMonthStates.find((state) => get(state, 'value') === shop.getMonthState(month));
  }

  @computed('filteredSales')
  get totalSales() {
    const sales = get(this, 'filteredSales');

    if (!sales) {
      return 0;
    }

    return sales.reduce(
      (prev, sale) => prev + get(sale, 'amount'),
      0,
    );
  }
}
