import Component from '@ember/component';

export default Component.extend({
  classNames: ['show-email-template'],

  model: null,

  isOpenEmailModal: false,

  actions: {
    openSendEmailModal() {
      this.set('isOpenEmailModal', 'true');
    },
  },
});
