/**
 * @module    nf
 * @submodule nf-utils
 */
/**
 * ### communicationRoles
 *
 * We differ between *level* and *communicationRole* in the different staff
 * members. That's because a *communicationRole* can be making reference to
 * some different *levels* in the system.
 *
 * i.e., the communication role of *management* makes reference to staff members
 * that are *admins* or *managers* at the same time.
 *
 * This function associates a communication roles with the different internal
 * levels of the staff members.
 *
 * @method communicationRoles
 * @namespace communication-roles
 * @static
 * @public
 * @return {Array[Object]} A list of the different roles of the communication
 *                         system.
 *                         Every role must have the following format:
 *                         ```
 *                         {
 *                           label: String,
 *                           levels: [String],
 *                           value: String
 *                         }
 *                         ```
 */
export default function communicationRoles() {
  return [
    {
      label: i18n.t('communication.roles.commerce'),
      levels: ['commerce'],
      value: 'commerce',
      from_roles: ['admin', 'manager', 'scmanager', 'services'],
    },
    {
      label: i18n.t('communication.roles.management'),
      levels: ['admin', 'manager', 'scmanager'],
      value: 'management',
      from_roles: ['commerce', 'services'],
    },
    {
      label: i18n.t('communication.roles.services'),
      levels: ['services'],
      value: 'services',
      from_roles: ['admin', 'manager', 'scmanager', 'commerce', 'services'],
    },
  ];
}
// @TODO we need to refactor the permissions for every module in NF
/**
 * It checks if a specific staff member level has access to the communications
 * panel.
 *
 * @method  hasAccessToCommunications
 * @param  {String}  level Internal database value of the actual role of the
 *                         staff member.
 * @return {Boolean}       True if the level hass access.
 */
export function hasAccessToCommunications(level) {
  return ['admin', 'manager', 'commerce', 'services', 'scmanager'].includes(level);
}
/**
 *
 * It returns a list of roles which the `level` can contact through the module
 * of communications.
 *
 * @method  getToRolesByLevel
 * @param  {String} level   Internal database value of the actual role of the
 *                          staff member.
 * @return {Array[Object]}  An Array of Objects with the available roles.
 * @throws {Error}          If `level` isn't a valid type or it doesn't have
 *                          access to the communications list.
 */
export function getToRolesByLevel(level) {
  if (!hasAccessToCommunications(level)) {
    throw new Error(`getToRolesByLevel(): parameter 'level' with value ${level} is not a`
      + 'valid staff level');
  }

  return communicationRoles().filter((role) => role.from_roles.includes(level));
}
/**
 * Retrieve the communication role of a specific level
 *
 * @method  getRoleByLevel
 * @param  {String} level Internal database value of the actual role of the
 *                          staff member.
 * @return {String}       A String with the equivalent role inside the
 *                        communications system.
 * @throws {Error}        If `level` isn't a valid type or it doesn't have
 *                        access to the communications list.
 */
export function getRoleByLevel(level) {
  let result;

  if (!hasAccessToCommunications(level)) {
    throw new Error(`getRoleByLevel(): parameter 'level' with value ${level} is not a valid staff level`);
  }

  communicationRoles().some((role) => {
    if (role.levels.includes(level)) {
      result = role.value;

      return true;
    }

    return false;
  });

  return result;
}
