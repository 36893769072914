import { get, computed } from '@ember/object';
import Model from 'ember-data/model';
import Copyable from 'ember-data-copyable';
import Validator from 'ember-model-validator/mixins/model-validator';

import { attr, belongsTo, hasMany } from '@ember-data/model';

const MixinModel = Model.extend(Validator, Copyable, {});

export default class extends MixinModel {
  @attr('number', { defaultValue: 0 }) bathroomsNumber

  @attr('number', { defaultValue: 0 }) bedroomsNumber

  @attr('string') block

  @attr('number') builtSurface

  @attr('string') cadastralReference

  @attr('number') commonZonesSurface

  @attr('string') doorway

  @attr('string') estate

  @attr({ defaultValue() { return []; } }) files

  @attr('number') floor

  @attr('number', { defaultValue: 0 }) garagesNumber

  @attr('string') ladder

  @attr('string', { defaultValue: '' }) letter

  @attr('string') name

  @attr('string') orientation

  @attr('number', { defaultValue: 0 }) price

  @attr('string') reference

  @attr('number') rental

  @attr('residential-rooms', { defaultValue() { return []; } }) residentialRooms

  @attr('string') state

  @attr('number', { defaultValue: 0 }) storageRoomsNumber

  @attr('boolean') terrace

  @attr('number', { defaultValue: 0 }) toiletsNumber

  @attr('number') usefulSurface

  @attr('string', { defaultValue: 'published' }) visibility

  // Relationships
  @belongsTo('residentialDevelopment') residentialDevelopment

  @belongsTo('residentialPropertyType') residentialPropertyType

  @hasMany('residentialProposal') residentialProposals

  // Computed
  @computed('residentialDevelopment')
  get isForRenting() {
    const residentialDevelopment = get(this, 'residentialDevelopment');

    return ['both', 'rental'].includes(get(residentialDevelopment, 'management'));
  }

  @computed()
  get validations() {
    return {
      floor: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('property.floor') }),
        },
      },

      letter: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('property.letter') }),
        },
      },

      name: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('property.name') }),
        },
      },

      rental: {
        presence: {
          if: (key, value, _this) => get(_this, 'isForRenting'),
          message: get(this, 'i18n').t('required', { attr: i18n.t('property.rental') }),
        },
      },

      residentialDevelopment: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('residential.development') }),
        },
      },

      residentialPropertyType: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('property.propertyType') }),
        },
      },

      residentialRooms: {
        custom: {
          validation(key, value) {
            return value.every((room) => (!room.name && !room.type && !room.surface)
              || (room.name && room.type));
          },
          message: get(this, 'i18n').t('residential-rooms-required'),
        },
      },

      state: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('property.state') }),
        },
      },
    };
  }
}
