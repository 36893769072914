import { Factory } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  code() {
    return faker.random.alphaNumeric();
  },

  name() {
    return faker.company.companyName();
  },

  afterCreate(_owner, server) {
    const owner = _owner;
    owner.center = owner.center || server.schema.centers.first();

    owner.save();
  },
});
