import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';

export default class extends Component {
  @service apiFiles

  @reads('fetchImage.last.value') _src

  attributeBindings = [
    'alt',
    'role',
    'style',
    '_src:src',
  ]

  role = 'button'

  tagName = 'img'

  click() {
    if (this.onClick) {
      this.onClick();
    }
  }

  didReceiveAttrs() {
    super.didReceiveAttrs();

    this.fetchImage.perform();
  }

  @restartableTask
  fetchImage = function* () {
    return this.src
      ? yield this.apiFiles.getImageBlob(this.src)
      : '';
  }
}
