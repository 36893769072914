import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import {
  isImageExtension,
  isDocExtension,
  isExcelExtension,
  isPdfExtension,
  isPowerpointExtension,
} from '../utils/file-extension';

export default Component.extend({

  url: alias('value'),

  isImage: computed('url', function () {
    return isImageExtension(this.get('url'));
  }),

  isFolder: computed('url', function () {
    return null === this.get('url') || '' === this.get('url');
  }),

  isDoc: computed('url', function () {
    return isDocExtension(this.get('url'));
  }),

  isExcel: computed('url', function () {
    return isExcelExtension(this.get('url'));
  }),

  isPdf: computed('url', function () {
    return isPdfExtension(this.get('url'));
  }),

  isPowerpoint: computed('url', function () {
    return isPowerpointExtension(this.get('url'));
  }),
});
