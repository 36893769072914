import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default class extends Component {
  @service pageHeader

  classNames = ['page-header-block']

  @alias('pageHeader.action') action

  @alias('pageHeader.title') title

  @alias('pageHeader.tooltip') tooltip
}
