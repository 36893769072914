import { Ability } from 'ember-can';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class extends Ability {
  @service auth

  roles = []

  // disabled for Residential and CORT
  @computed('auth.center', 'auth.user.role')
  get canCreate() {
    const {
      auth: {
        center,
        user: {
          role,
        },
      },
    } = this;

    return center
      && role
      && 'admin' === role
      && !center.hasResidential
      && !center.hasLeasing
      && !center.hasKPITech
      && !center.hasECOP;
  }
}
