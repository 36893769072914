import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize(serialized) {
    return String(i18n.t(serialized));
  },

  serialize(deserialized) {
    return deserialized;
  },
});
