import residentialPropertyStates from 'neuro-frontend/enums/residential-property-states';

export default function residentialPropertiesColumns(view, currency) {
  switch (view) {
    case 'sale-process/requests':
      return [
        {
          label: i18n.t('development.city'),
          valuePath: 'residentialDevelopment.city',
          sortable: false,
        },
        {
          label: i18n.t('development.name'),
          valuePath: 'residentialDevelopment.name',
          sortable: false,
        },
        {
          label: i18n.t('residential-properties.property'),
          sortable: false,
          valuePath: 'doorway',
          width: '5%',
        },
        {
          valuePath: 'block',
          sortable: false,
          width: '5%',
        },
        {
          valuePath: 'floor',
          sortable: false,
          width: '5%',
        },
        {
          valuePath: 'letter',
          sortable: false,
          width: '5%',
        },
        {
          label: i18n.t('residential-properties.type'),
          valuePath: 'residentialPropertyType.name',
          sortable: false,
        },
        {
          cellType: 'surface',
          label: i18n.t('residential-properties.surface'),
          valuePath: 'usefulSurface',
          sortable: false,
        },
        {
          label: i18n.t('residential-properties.bedrooms'),
          valuePath: 'bedroomsNumber',
          sortable: false,
        },
        {
          cellType: 'amount',
          currency,
          label: i18n.t('residential-properties.price'),
          valuePath: 'price',
          sortable: false,
        },
        {
          label: '',
          link: 'residential.properties.edit',
          cellType: 'new-tab',
          sortable: false,
          align: 'center',
          width: '4%',
        },
      ];

    case 'development/properties':
      return [
        {
          label: i18n.t('residential-properties.property'),
          sortable: false,
          valuePath: 'doorway',
          width: '5%',
        },
        {
          valuePath: 'block',
          width: '5%',
        },
        {
          valuePath: 'floor',
          width: '5%',
        },
        {
          valuePath: 'letter',
          width: '5%',
        },
        {
          label: i18n.t('residential-properties.type'),
          valuePath: 'residentialPropertyType.name',
          sortable: false,
        },
        {
          cellType: 'surface',
          label: i18n.t('residential-properties.surface'),
          valuePath: 'usefulSurface',
        },
        {
          label: i18n.t('residential-properties.bedrooms'),
          valuePath: 'bedroomsNumber',
        },
        {
          cellType: 'amount',
          currency,
          label: i18n.t('residential-properties.price'),
          valuePath: 'price',
        },
        {
          cellType: 'tag',
          enum: residentialPropertyStates,
          valuePath: 'state',
        },
        {
          label: '',
          cellAction: 'block',
          cellType: 'material-icon',
          materialIcon: 'lock_open',
          tooltip: i18n.t('lock'),
          resource: 'residential-property',
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellType: 'material-icon-residential-property-visibility',
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellAction: 'edit',
          cellType: 'material-icon',
          materialIcon: 'edit',
          tooltip: i18n.t('edit'),
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellAction: 'delete',
          cellType: 'material-icon',
          materialIcon: 'delete',
          tooltip: i18n.t('delete'),
          resource: 'residential-property',
          sortable: false,
          align: 'center',
          width: '4%',
        },
      ];

    default:
      return [
        {
          label: i18n.t('development.name'),
          valuePath: 'residentialDevelopment.name',
          sortable: false,
          width: '10%',
          cellClassNames: ['table-list__cell--ellipsis'],
        },
        {
          label: i18n.t('property.name'),
          valuePath: 'name',
          width: '10%',
        },
        {
          label: i18n.t('residential-properties.type'),
          valuePath: 'residentialPropertyType.name',
          sortable: false,
        },
        {
          cellType: 'surface',
          label: i18n.t('residential-properties.surface'),
          valuePath: 'usefulSurface',
        },
        {
          label: i18n.t('residential-properties.bedrooms'),
          valuePath: 'bedroomsNumber',
        },
        {
          cellType: 'amount',
          currency,
          label: i18n.t('residential-properties.price'),
          valuePath: 'price',
        },
        {
          cellType: 'tag',
          enum: residentialPropertyStates,
          valuePath: 'state',
        },
        {
          label: '',
          cellAction: 'block',
          cellType: 'material-icon',
          materialIcon: 'lock_open',
          cellClassNames: 'qa-block-icon',
          tooltip: i18n.t('lock'),
          resource: 'residential-property',
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellType: 'material-icon-residential-property-visibility',
          cellClassNames: 'qa-visibility-icon',
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellAction: 'edit',
          cellType: 'material-icon',
          materialIcon: 'edit',
          tooltip: i18n.t('edit'),
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellAction: 'duplicate',
          cellType: 'material-icon',
          cellClassNames: 'qa-duplicate-icon',
          materialIcon: 'content_copy',
          tooltip: i18n.t('duplicate'),
          sortable: false,
          align: 'center',
          width: '4%',
        },
        {
          label: '',
          cellAction: 'delete',
          cellType: 'material-icon',
          materialIcon: 'delete',
          tooltip: i18n.t('delete'),
          resource: 'residential-property',
          sortable: false,
          align: 'center',
          width: '4%',
        },
      ];
  }
}
