import { set, get } from '@ember/object';
import d3 from 'd3';
import moment from 'moment';
import config from 'neuro-frontend/config/environment';
import { debug } from '@ember/debug';

const {
  i18n: {
    defaultLocale,
  },
} = config;

function calculateLocale(availableLocales) {
  const localeNav = navigator.language.toLowerCase();
  const navLocalesSplitted = localeNav.split('-');

  if (availableLocales.includes(localeNav)) {
    return localeNav;
  }
  if (0 < navLocalesSplitted.length && availableLocales.includes(navLocalesSplitted[0])) {
    return navLocalesSplitted[0];
  }
  return defaultLocale;
}

function initializeLocales() {
  return d3.timeFormatDefaultLocale({
    decimal: ',',
    thousands: '.',
    grouping: [3],
    currency: ['€', ''],
    dateTime: '%a %b %e %X %Y',
    date: '%d/%m/%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: [i18n.t('sunday'), i18n.t('monday'), i18n.t('tuesday'), i18n.t('wednesday'), i18n.t('thursday'), i18n.t('friday'), i18n.t('saturday')],
    shortDays: [i18n.t('sunday-short'), i18n.t('monday-short'), i18n.t('tuesday-short'), i18n.t('wednesday-short'), i18n.t('thursday-short'), i18n.t('friday-short'), i18n.t('saturday-short')],
    months: [i18n.t('january'), i18n.t('february'), i18n.t('march'), i18n.t('april'), i18n.t('may'), i18n.t('june'), i18n.t('july'), i18n.t('august'), i18n.t('september'), i18n.t('october'), i18n.t('november'), i18n.t('december')],
    shortMonths: [i18n.t('january-short'), i18n.t('february-short'), i18n.t('march-short'), i18n.t('april-short'), i18n.t('may-short'), i18n.t('june-short'), i18n.t('july-short'), i18n.t('august-short'), i18n.t('september-short'), i18n.t('october-short'), i18n.t('november-short'), i18n.t('december-short')],
  });
}

function initializeMoment(currentLocale) {
  const regionlessLocale = currentLocale.split('-')[0];
  moment.locale(regionlessLocale);
}

export function initialize(appInstance) {
  const i18n = appInstance.lookup('service:i18n');
  const availableLocales = get(i18n, 'locales');
  const currentLocale = calculateLocale(availableLocales);

  const {
    t,
  } = i18n;

  i18n.t = (...args) => {
    const str = t.call(i18n, ...args);

    return (str && str.toString) ? str.toString() : str;
  };

  i18n.set('locale', currentLocale);
  initializeMoment(currentLocale);

  i18n.on('missing', (locale, key) => {
    debug(`Missing translation: '${key}'. Locale: '${locale}'.`);
  });

  // export the i18n service to be used from the utils functions
  set(window, 'i18n', i18n);

  i18n.d3Locale = initializeLocales();
}

export default {
  name: 'i18n',
  initialize,
};
