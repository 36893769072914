import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

export default class CommerceExtrasModel extends Model {
  @attr('string') value

  @belongsTo('commerce') commerce

  @belongsTo('extra') extra

  @belongsTo('language') language

  extraError = ''
}
