import Component from '@ember/component';
import {
  get, getProperties, action, computed,
} from '@ember/object';
import { classNames } from '@ember-decorators/component';

import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';

@classNames('nf-table__pager')
export default class NfTablePagerComponent extends Component.extend({
    page: {},
  }) {
  @computed('page.current-page', 'page.total', 'page.last-page')
  get isNextDisabled() {
    return !get(this, 'page.total')
      || get(this, 'page.current-page') === get(this, 'page.last-page');
  }

  @computed('page.current-page', 'page.total')
  get isPreviousDisabled() {
    return !get(this, 'page.total') || 1 >= get(this, 'page.current-page');
  }

  @computed('page.from', 'page.to', 'page.total')
  get pageResults() {
    const {
      'page.from': from,
      'page.to': to,
      'page.total': total,
    } = getProperties(this, ['page.from', 'page.to', 'page.total']);

    return `${from || 0}-${to || 0} ${i18n.t('pager.out_of')} ${total}`;
  }

  @action
  nextPage(currentPage) {
    this.changePage(currentPage + 1);
  }

  @action
  previousPage(currentPage) {
    this.changePage(currentPage - 1);
  }

  changePage(number) {
    if ('number' !== typeof number) {
      throw new TypeError('parameter `page` must be of type Number');
    }

    if (Number.isNaN(number) || 0 >= number) {
      throw new IllegalArgumentError(`parameter 'page' value ${number} isn't a valid Number`);
    }
    get(this, 'onChangedPage')({
      page: {
        number,
        size: get(this, 'page.per-page'),
      },
    });
  }
}
