import Mixin from '@ember/object/mixin';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';

import IllegalStateError from 'neuro-frontend/errors/illegal-state-error';

// decorators doesn't work with ES5 object models :(
export default Mixin.create({
  router: service(),

  store: service(),

  defaultFilter: {},

  defaultSort: '',

  editRoute: '',

  filter: {},

  fixedFilters: {},

  isLoading: false,

  model: {},

  modelName: '',

  page: {
    number: 1,
    size: 15,
  },

  sort: '',

  query: computed('fixedFilters', 'filter', 'page', 'sort', function () {
    const {
      filter,
      page,
      sort,
    } = this;
    const filters = {
      filter: {
        ...this.fixedFilters,
        ...filter,
      },
      page,
    };

    if (sort) {
      filters.sort = sort;
    }

    return filters;
  }),

  init() {
    this._super();
    set(this, 'defaultSort', this.sort);
  },

  actions: {
    changeFilters(params) {
      this.resetFilters();

      set(this, 'filter', params);

      this.loadCollection();
    },

    changePage(params) {
      set(this, 'page', get(params, 'page'));

      this.loadCollection();
    },

    changeSort(params) {
      set(this, 'sort', get(params, 'sort'));

      this.loadCollection();
    },

    edit(model) {
      if (!model || !get(model, 'id')) {
        throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
      }

      this.router.transitionTo(get(this, 'editRoute'), get(model, 'id'));
    },

    delete(model) {
      if (!model || !get(model, 'id')) {
        throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
      }

      return model.destroyRecord();
    },
  },

  hasFilters() {
    return !!Object.keys(get(this, 'filter')).length;
  },

  loadCollection() {
    const modelName = get(this, 'modelName');

    if (!modelName) {
      throw new IllegalStateError('loadCollection: "modelName" is false. When using a ListMixin, "modelName" should be set to an existant model');
    }

    const model = get(this, `model.${pluralize(modelName)}`);

    set(this, 'isLoading', true);

    if (model) {
      model.clear();
    }

    return get(this, 'store')
      .query(modelName, get(this, 'query'))
      .then((collection) => {
        if (!(get(this, 'isDestroyed') || get(this, 'isDestroying'))) {
          const arrayCollection = collection.toArray();
          const collectionName = pluralize(modelName);

          set(this, 'meta', get(collection, 'meta'));

          this.fillModel(model, arrayCollection, collectionName);

          set(this, 'isLoading', false);

          return arrayCollection;
        }
        return [];
      });
  },

  // Private method which is used inside loadCollection
  fillModel(model, arrayCollection, collectionName) {
    if (model) {
      model.pushObjects(arrayCollection);
    } else if (get(this, 'model')) {
      set(this, `model.${collectionName}`, arrayCollection);
    } else {
      // fix a problem when components override model attribute
      // could it be a way to make some attributes of a component private?
      set(this, 'model', { [collectionName]: arrayCollection });
    }
  },

  resetFilters() {
    set(this, 'filter', get(this, 'defaultFilter'));
    set(this, 'page.number', 1);
    set(this, 'sort', this.defaultSort);
  },
});
