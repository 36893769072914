import { get, action } from '@ember/object';
import { CanMixin } from 'ember-can';

import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

import Route from '@ember/routing/route';

export default class extends Route.extend(CanMixin, {}) {
  @service auth

  queryParams = false

  beforeModel() {
    if (!this.can('access of module-residential')) {
      this.transitionTo('index');
    }
  }

  model() {
    const ctrl = this.controllerFor('residential.campaigns.index');

    return hash({
      campaigns: ctrl.loadCollection(),
    });
  }

  @action
  deleteResidentialCampaign(model) {
    if (!model || !get(model, 'id')) {
      throw new TypeError('`model` is not a valid residential-campaign');
    }

    return model.destroyRecord();
  }

  @action
  editResidentialCampaign(model) {
    if (!model || !get(model, 'id')) {
      throw new TypeError('`model` is not a valid residential-campaign');
    }

    this.transitionTo('residential.campaigns.edit', get(model, 'id'));
  }
}
