/* eslint max-statements: "off" */
import Response from 'ember-cli-mirage/response';

export default function () {
  const config = {
    api: '',
    base_url: 'http://webactivalocal.nat.compraactiva.me',
    env: 'local',
    namespace: '',
    headwayapp: { account: 'xd8bEx' },
  };
  let session;
  // These comments are here to help you get started. Feel free to delete them.
  /*
    Config (with defaults).

    Note: these only affect routes defined *after* them!
  */
  // this.passthrough('config.json');
  this.passthrough('/write-coverage');
  this.passthrough('**/assets/**');
  this.passthrough('https://in.hotjar.com/api/v1/client/sites/**', ['post']);
  this.passthrough('https://vc.hotjar.io/views/**', ['post']);

  this.urlPrefix = ''; // make this `http://localhost:8080`, for example, if your API is on a different server
  this.namespace = ''; // make this `api`, for example, if your API is namespaced
  // this.timing = 400;      // delay for each request, automatically set to 0 during testing
  document.cookie = 'tkn=eyJpZCI6IjEiLCJ2ZXJzaW9uIjoxLCJzaWduIjoiMjQwNTZmZ'
  + 'GE5OTFkM2IxZTA2Yzk2MWNjYzBmZjk4MjNlNmY3NzRhNiJ9; path=/; max-age=3600';
  document.cookie = 'cid=1;path=/';

  // refresh localStorage before using session with mirage
  delete localStorage['ember_simple_auth-session'];

  this.get(
    'https://nipplealert.com/the-fappening/jennifer-lawrence-nude.jpg',
    () => [200, { 'Content-Type': 'image/jpeg' }, 'jennifer'],
  );
  this.get(
    'https://nipplealert.com/the-fappening/keanu-reeves-nude.jpg',
    () => [200, { 'Content-Type': 'image/jpeg' }, 'keanu'],
  );

  // TODO: Put this routes in api-files-test.js
  this.get('**/downfilebadrequest', { message: 'Error message' }, 400);
  this.get('**/downfileok', { message: 'Ok message' }, 200);
  /*
    Shorthand cheatsheet:

    this.get('/posts');
    this.post('/posts');
    this.get('/posts/:id');
    this.put('/posts/:id'); // or this.patch
    this.del('/posts/:id');

    http://www.ember-cli-mirage.com/docs/v0.2.x/shorthands/
  */
  this.get('**/config.json', () => config);

  this.get('**/actions');
  this.post('**/actions');

  this.get('**/bonus_requests');

  this.get('**/buildings');

  this.get('**/bonus_shops');
  this.get('**/bonus_shops/:id');

  this.get('**/bonus_requests');
  this.get('**/bonus_requests/:id');
  this.post('**/bonus_requests');
  this.patch('**/bonus_requests/:id');
  this.del('**/bonus_requests/:id');

  this.get('**/campaigns');
  this.post('**/campaigns');
  this.get(
    '**/campaigns/:id',
    (schema, request) => schema.campaigns.find(request.params.id),
  );
  this.patch('**/campaigns/:id');

  this.get('**/campaign_actions');
  this.post('**/campaign_actions');

  this.get('**/campaign_goals');
  this.post('**/campaign_goals');

  this.get('**/campaignrules');
  this.get(
    '**/campaignrules/:id',
    (schema, request) => schema.campaignrules.find(request.params.id),
  );
  this.post('**/campaignrules');
  this.patch('**/campaignrules/:id');

  this.get('**/campaigntypes');
  this.post('**/campaigntypes');

  this.get('**/categories');
  this.get(
    '**/categories/:id',
    (schema, request) => schema.categories.find(request.params.id),
  );
  this.post('**/categories');

  this.get('**/centers');
  this.get(
    '**/centers/:id',
    (schema, request) => schema.centers.find(request.params.id),
  );

  this.get('**/channels');

  this.get('**/children');

  this.get('**/child_stays');
  this.post('**/child_stays');

  this.get('**/commerces');
  this.get(
    '**/commerces/:id',
    (schema, request) => schema.commerces.find(request.params.id),
  );
  this.post('**/commerces');
  this.patch('**/commerces/:id');

  this.get('**/commerces_access_information', [], 200);

  this.get('**/commerce_extras');

  this.get('**/countries');
  this.get(
    '**/countries/:id',
    (schema, request) => schema.countries.find(request.params.id),
  );

  this.get('**/coupons');
  this.post('**/coupons');
  this.get(
    '**/coupons/:id',
    (schema, request) => schema.coupons.find(request.params.id),
  );
  this.patch('**/coupons/:id');

  this.get('**/dashboards');
  this.get(
    '**/dashboards/:id',
    (schema, request) => schema.dashboards.find(request.params.id),
  );
  this.post('**/dashboards');
  this.patch('**/dashboards/:id');

  this.patch(
    '**/email_campaign_send/:id',
    (schema, request) => {
      // @TODO check current date and campaign dates to set real state (scheduled or active)
      schema.db.emailCampaigns.update(request.params.id, { state: 'scheduled' });

      return schema.emailCampaigns.find(request.params.id);
    },
  );

  this.get('**/email_campaigns');
  this.post('**/email_campaigns');
  this.get(
    '**/email_campaigns/:id',
    (schema, request) => schema.emailCampaigns.find(request.params.id),
  );
  this.patch('**/email_campaigns/:id');

  // TODO: this is a sloppy job, it should be in api-files-test.js
  this.post(
    '**/files',
    (schema, request) => ({
      file_url: 'https://nipplealert.com/the-fappening/keanu-reeves-nude.jpg',
      image_quality: request.requestBody.get('image_quality'),
    }),
    200,
  );

  this.get('**/extras');
  this.get(
    '**/extras/:id',
    (schema, request) => schema.extras.find(request.params.id),
  );
  // TODO: this is a sloppy job, it should be in api-files-test.js
  this.post(
    '**/images',
    () => ({
      image_url: 'https://nipplealert.com/the-fappening/keanu-reeves-nude.jpg',
    }),
    200,
  );

  this.get('**/intranet_communications');
  this.get(
    '**/intranet_communications/:id',
    (schema, request) => schema.intranetCommunications.find(request.params.id),
  );
  this.post('**/intranet_communications', (schema, request) => {
    const { intranet_communication: params } = JSON.parse(request.requestBody);
    params.creator_id = schema.staffMembers.first();
    params.to_commerces = [schema.commerces.first()];
    const communication = schema.intranetCommunications.create(params);

    return JSON.stringify({ intranet_communication: communication.toJSON() });
  });

  this.get('**/intranet_files');
  this.post('**/intranet_files');
  this.get(
    '**/intranet_files/:id',
    (schema, request) => schema.intranetFiles.find(request.params.id),
  );
  this.patch('**/intranet_files/:id');
  this.del('**/intranet_files/:id');

  this.get('**/intranet_flows');
  this.post('**/intranet_flows');

  this.get('**/intranet_guards');
  this.post('**/intranet_guards');

  this.get('**/intranet_sales');
  this.get(
    '**/intranet_sales/:id',
    (schema, request) => schema.intranetSales.find(request.params.id),
  );
  this.post('**/intranet_sales');

  this.get('**/intranet_sales_years');
  this.get(
    '**/intranet_sales_years/:id',
    (schema, request) => schema.intranetSalesYears.find(request.params.id),
  );
  this.post('**/intranet_sales_years');
  this.patch('**/intranet_sales_years/:id');

  this.post(
    '**/intranet_sales_year_activate',
    (schema) => {
      schema.commerces.forEach((commerce) => {
        schema.create('intranetSalesYear', {
          commerce,
        });
      });
    },
  );

  this.get('**/intranet_subtypes');
  this.post('**/intranet_subtypes');

  this.get('**/languages');

  this.get('**/lineitems');
  this.post('**/lineitems');

  this.get('**/loyalty_cards');

  this.get('**/owners');
  this.get(
    '**/owners/:id',
    (schema, request) => schema.owners.find(request.params.id),
  );

  this.get('**/proposals');
  this.get(
    '**/proposals/:id',
    (schema, request) => schema.proposals.find(request.params.id),
  );
  this.post('**/proposals');
  this.patch('**/proposals/:id');

  this.get('**/proposal_extras');
  this.get(
    '**/proposal_extras/:id',
    (schema, request) => schema.proposalExtras.find(request.params.id),
  );
  this.post('**/proposal_extras');
  this.patch('**/proposal_extras/:id');

  this.get('**/provinces');
  this.get(
    '**/provinces/:id',
    (schema, request) => schema.provinces.find(request.params.id),
  );

  this.get('**/push_notifications');
  this.get(
    '**/push_notifications/:id',
    (schema, request) => schema.pushNotifications.find(request.params.id),
  );
  this.post('**/push_notifications');
  this.patch(
    '**/push_notifications/:id',
    function (schema, request) {
      const attrs = this.normalizedRequestAttrs();

      // @TODO check current date and campaign dates to set real state (scheduled or active)
      schema.db.pushNotifications.update(request.params.id, attrs);

      if ('send' === attrs.pushNotificationAction) {
        schema.db.pushNotifications.update(request.params.id, { state: 'active' });
      }

      return schema.pushNotifications.find(request.params.id);
    },
  );

  this.get('**/receipts');
  this.post('**/receipts');

  this.get('**/residential_developments');
  this.get(
    '**/residential_developments/:id',
    (schema, request) => schema.residentialDevelopments.find(request.params.id),
  );
  this.post('**/residential_developments');
  this.del('**/residential_developments/:id');

  this.get('**/residential_offers');
  this.post('**/residential_offers');

  this.get('**/residential_owners');
  this.get(
    '**/residential_owners/:id',
    (schema, request) => schema.residentialOwners.find(request.params.id),
  );
  this.post('**/residential_owners');
  this.patch('**/residential_owners/:id');
  this.del('**/residential_owners/:id');

  this.post('**/residential_properties');
  this.get('**/residential_properties');
  this.get(
    '**/residential_properties/:id',
    (schema, request) => schema.residentialProperties.find(request.params.id),
  );
  this.patch('**/residential_properties/:id');

  this.get('**/residential_property_types');

  this.get('**/residential_proposals');

  this.get('**/residential_qualities');

  this.get('**/residential_requests');
  this.post('**/residential_requests');

  this.get('**/residential_services');

  this.get('**/retail_product_subfamilies');

  this.get('**/sale_concepts');

  this.get('**/segments');
  this.get(
    '**/segments/:id',
    (schema, request) => schema.segments.find(request.params.id),
  );
  this.patch('**/segments/:id');
  this.post('**/segments');
  this.del('**/segments/:id');

  this.get('**/services');
  this.post('**/services');
  this.get(
    '**/services/:id',
    (schema, request) => schema.services.find(request.params.id),
  );

  this.get('**/staff_members');
  this.get(
    '**/staff_members/:id',
    (schema, request) => schema.staffMembers.find(request.params.id),
  );
  this.get(
    '**/staff_members/me',
    (schema) => session || schema.staffMembers.first(),
  );
  this.post('**/staff_members');
  this.post(
    '**/staff_members/refresh',
    () => ({
      data: {
        attributes: {
          session: 'updated_token',
        },
      },
    }),
  );
  this.post(
    '**/staff_members/reset_password',
    (schema, request) => {
      const params = JSON.parse(request.requestBody);

      if (
        params.data
        && params.data.attributes
        && 'soluciones@neuromobilemarketing.com' === params.data.attributes.username
      ) {
        return new Response(204);
      }

      return new Response(409);
    },
  );
  this.patch('**/staff_members/:id');

  this.post(
    '**/staff_members/session',
    (schema, request) => {
      const params = JSON.parse(request.requestBody);

      if (
        params.data?.attributes?.username
      ) {
        const name = params.data.attributes.username;

        session = schema.staffMembers.findBy((staff) => name === staff.email);

        session['expires-in'] = 24 * 60 * 60;

        return session;
      }

      return new Response(409);
    },
  );

  this.get('*/shop_properties');

  this.get('**/suppliers');
  this.post('**/suppliers');
  this.get(
    '**/suppliers/:id',
    (schema, request) => schema.suppliers.find(request.params.id),
  );

  this.get('**/supplier_contacts');
  this.post('**/supplier_contacts');

  this.get('**/tickets');
  this.post('**/tickets');

  this.get('**/users');
  this.get(
    '**/users/:id',
    (schema, request) => schema.users.find(request.params.id),
  );
  this.post('**/users');
  this.patch('**/users/:id');
  this.del('**/users/:id');

  // it send an email to user before contact has been removed
  this.post('**/users_unsubscribe_send', [], 200);

  this.get('**/user_types');

  this.get('**/user_types');

  this.post('**/yardi_upload');

  this.get('**/zones');

  // Actions

  // it creates at least one loyalty-card
  // @TODO improve to create loyaltyCards according the data
  // in the database
  this.patch(
    '**/user_create_loyalty_cards/:id',
    (schema, request) => {
      const params = JSON.parse(request.requestBody);

      if (!params.data.attributes.preview) {
        schema.loyaltyCards.create();
      }

      return JSON.stringify([{
        campaign_id: schema.campaigns.first().id,
        loyalty_cards: 1,
        stamps_left: 8,
      }]);
    },
  );
  this.get('**/user_create_tickets/**', [], 200);

  // Stats
  // -------
  // Neurostats

  this.post('**/neurostats/v1/statsactiva/visits', [], 200);
  this.post('**/neurostats/v1/statsactiva/user_activity', [], 200);
  this.post('**/neurostats/v1/statsactiva/impressions', [], 200);
  this.post('**/neurostats/v1/appannie/sales', [], 200);

  // Migrations
  this.get('**/stats/registered_users', [], 200);
  this.get('**/stats/user_channels', [], 200);
  this.get('**/stats/user_profiles', [], 200);
  this.get('**/stats/campaigns/:id', () => JSON.stringify(this._config.fixtures.campaignKpis), 200);
  this.get('**/stats/campaigns/:id?group_by=day', () => JSON.stringify(this._config.fixtures.campaignKpis.map((elem) => ({
    ...elem,
    day: new Date(),
  }))), 200);
  this.get('**/stats_reports/**', [], 200);
}
