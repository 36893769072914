import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import Cell from 'ember-light-table/components/cells/base';

export default class extends Cell {
  @alias('column.enum') enum

  @alias('elem.label') label

  @computed('value', 'enum')
  get elem() {
    const value = get(this, 'value');
    const enumerator = get(this, 'enum');

    return enumerator.findBy('value', value);
  }
}
