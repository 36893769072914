define("neuro-frontend/components/md-fixed-btn", ["exports", "ember-cli-materialize/components/md-fixed-btn"], function (_exports, _mdFixedBtn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdFixedBtn.default;
    }
  });
});
