import Component from '@ember/component';

export default class extends Component.extend({
  // sizes: tiny, small, medium, large
  size: 'tiny',
}) {
  classNameBindings = ['tooltip::hide']

  classNames = ['nf-tooltip']

  tagName = 'span'
}
