import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';

import { inject as service } from '@ember/service';

import { getFileExtension, hasValidExtension } from 'neuro-frontend/utils/file-extension';

// @TODO refactor and use nf-input-file in the template
export default class NfFileLoader extends Component.extend({
  label: i18n.t('attach_files'),

  multipleFiles: false,
}) {
  @service apiFiles

  classNames = ['nf-file-loader']

  errorExtension = false

  isLoading = false

  preview = false

  @computed('fileTypes')
  get validExtensions() {
    if (this.fileTypes && 'string' === typeof this.fileTypes) {
      return this.fileTypes.split(',').filter((ext) => ext);
    }

    return [];
  }

  didInsertElement() {
    let inputFile;

    this.$()[0].onchange = (event) => {
      if (event.target.files) {
        ({
          target: {
            files: [inputFile],
          },
        } = event);

        if (this.hasValidExtension(inputFile.name)) {
          set(this, 'isLoading', true);
          get(this, 'apiFiles')
            .uploadFile(inputFile)
            .then((responseFile) => {
              set(this, 'isLoading', false);
              this.addFile(responseFile, inputFile);
            });
          set(this, 'errorExtension', false);
        } else {
          set(this, 'errorExtension', true);
        }
      }
    };
  }

  @action
  downloadFile(file) {
    return get(this, 'apiFiles').downloadFile(get(file, 'url'), get(file, 'name'));
  }

  @action
  removeFile(file) {
    get(this, 'files').removeObject(file);

    document.getElementById('fileInput').value = '';
    document.getElementById('fileExtensionInput').value = '';
  }

  addFile(responseFile, inputFile) {
    if (!get(this, 'multipleFiles')) {
      get(this, 'files').clear();
    }
    get(this, 'files').pushObject({
      name: inputFile.name,
      url: responseFile.file_url,
      category: get(this, 'category'),
    });
  }

  hasValidExtension(url) {
    return hasValidExtension(url)
    && (
      !this.validExtensions.length
      || this.validExtensions.includes(getFileExtension(url))
    );
  }
}
