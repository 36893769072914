import { computed, get } from '@ember/object';

import Day from 'neuro-frontend/utils/day';

import ModuleCms from './module-cms';

export default class extends ModuleCms {
  @computed('auth.user.level')
  get canCreate() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccessNewMenu')
      && ['admin', 'manager', 'commerce', 'storeManager'].includes(level);
  }

  @computed('model.state', 'auth.user.level')
  get canEdit() {
    const state = get(this, 'model.state');
    const level = get(this, 'auth.user.level');

    return (get(this, 'canAccessNewMenu') && ('expired' !== state)
      && ['admin', 'manager', 'commerce', 'storeManager'].includes(level))
      && !('active' === state && ['commerce', 'storeManager'].includes(level));
  }

  @computed('auth.user.level', 'model.state')
  get canDuplicate() {
    const state = get(this, 'model.state');

    return get(this, 'canCreate') && ('expired' === state);
  }

  @computed('auth.user.level', 'model.state')
  get canDelete() {
    const state = get(this, 'model.state');
    return get(this, 'canEdit') && ('expired' !== state);
  }

  @computed('model.state', 'model.endDate', 'auth.user.level')
  get canPublish() {
    const state = get(this, 'model.state');
    const endDate = get(this, 'model.endDate');
    const today = new Date(new Day().value);
    const level = get(this, 'auth.user.level');

    return get(this, 'canEdit') && ['admin', 'manager'].includes(level) && ('draft' === state || 'pending' === state) && (today < endDate);
  }

  @computed('model.state', 'auth.user.level')
  get canExpire() {
    const state = get(this, 'model.state');
    const level = get(this, 'auth.user.level');

    return get(this, 'canEdit') && 'active' === state && ['admin', 'manager'].includes(level);
  }

  @computed('auth.user.level', 'model.state')
  get canUnpublish() {
    const state = get(this, 'model.state');
    const level = get(this, 'auth.user.level');

    return get(this, 'canEdit') && ('active' === state) && ['admin', 'manager'].includes(level);
  }

  @computed('auth.user.level', 'model.state')
  get canRequestPublish() {
    const state = get(this, 'model.state');
    const level = get(this, 'auth.user.level');
    const endDate = get(this, 'model.endDate');
    const today = new Date(new Day().value);

    return get(this, 'canEdit') && ['commerce', 'storeManager'].includes(level) && ('draft' === state) && (today < endDate);
  }

  @computed('auth.user.level', 'model.state')
  get canRejectPublication() {
    const state = get(this, 'model.state');
    const level = get(this, 'auth.user.level');

    return get(this, 'canEdit') && ('pending' === state) && ['admin', 'manager'].includes(level);
  }

  @computed('auth.user.level')
  get canCreateCenter() {
    const level = get(this, 'auth.user.level');
    return get(this, 'canAccessNewMenu') && get(this, 'canCreate') && ['admin', 'manager'].includes(level);
  }
}
