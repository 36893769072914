import DS from 'ember-data';
import moment from 'moment';

export default DS.Transform.extend({
  deserialize(serialized) {
    const date = moment(serialized);

    // with 'undefined' as parameter it returns current date and... that's kind
    // of not correct :/
    //
    // the same happens with an object without any information (i.e. an empty object)
    if (
      !serialized
      || 'object' === typeof serialized
      || !date.isValid()
    ) {
      return null;
    }

    return date.format('DD/MM/YYYY');
  },

  serialize(deserialized) {
    // it fallbacks to the offset of the browser but it's bad practice to
    // create a new datetime without setting this parameter
    const date = (!deserialized || 'string' !== typeof deserialized)
      ? null
      : moment(deserialized, 'DD/MM/YYYY');

    if (!date || !date.isValid()) {
      return null;
    }

    return date.format('YYYY-MM-DD');
  },
});
