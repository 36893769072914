import { inject as service } from '@ember/service';
import Adapter from './application';

export default class ChannelAdapter extends Adapter {
  @service auth

  // @TODO this should be moved to adapter/application
  urlForFindAll(...args) {
    const {
      centerId,
    } = this.auth;

    return `${super.urlForFindAll(...args)}?filter[center_id]=${centerId}`;
  }
}
