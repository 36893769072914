import { computed, get } from '@ember/object';

import MaterialIcon from './material-icon';

export default class extends MaterialIcon {
  @computed('row.content')
  get action() {
    const content = get(this, 'row.content');
    return this.can.can('publish residential-property', content) ? 'publish' : 'unpublish';
  }

  @computed('row.content')
  get hide() {
    const content = get(this, 'row.content');
    return !this.can.can('publish residential-property', content) && !this.can.can('unpublish residential-property', content);
  }

  @computed('row.content')
  get isDisabled() {
    const content = get(this, 'row.content');
    return this.can.can('publish residential-property', content);
  }

  @computed('row.content')
  get materialIcon() {
    const content = get(this, 'row.content');
    return this.can.can('publish residential-property', content) ? 'visibility_off' : 'visibility';
  }

  @computed('row.content')
  get tooltip() {
    const content = get(this, 'row.content');
    return this.can.can('publish residential-property', content) ? i18n.t('publish') : i18n.t('unpublish');
  }
}
