define("neuro-frontend/components/ivy-codemirror", ["exports", "ivy-codemirror/components/ivy-codemirror"], function (_exports, _ivyCodemirror) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ivyCodemirror.default;
    }
  });
});
