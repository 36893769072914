import { get, computed } from '@ember/object';

import AbilityModule from './module';

export default class extends AbilityModule {
  moduleName = 'CMS'

  roles = [
    'admin',
    'manager',
    'storeManager',
    'commerce',
    'crmmanager',
  ]

  @computed('auth.center', 'auth.user.role')
  get canAccessNewMenu() {
    const center = get(this, 'auth.center');

    return this.canAccess
      && center?.hasNewCMS;
  }

  @computed('auth.center', 'auth.user.role')
  get canAccessOldMenu() {
    const center = get(this, 'auth.center');

    return this.canAccess
      && center
      && !center.hasNewCMS;
  }

  @computed('auth.center', 'auth.user.role')
  get canAccessNewMenuProposal() {
    return this.canAccessNewMenu;
  }

  @computed('auth.center', 'auth.user.role')
  get canAccessOldMenuProposal() {
    return this.canAccess
      && !this.canAccessNewMenu;
  }
}
