import {
  action, get, getProperties, set,
} from '@ember/object';

import { hash } from 'rsvp';
import parseErrors from 'neuro-frontend/utils/parse-errors-array-to-errors-object';
import { inject as service } from '@ember/service';

import FormRoute from 'neuro-frontend/routes/form';

export default class extends FormRoute {
  @service can

  models = [
    'country',
    'province',
  ]

  templateName = 'residential/users/new'

  async model({ user_id: userId }) {
    const store = get(this, 'store');
    const user = userId
      ? await store.findRecord(
        'user',
        userId,
      )
      : store.createRecord(
        'user',
        {
          createdAt: new Date(),
          center: get(this, 'auth.center'),
          staff: get(this, 'auth.user'),
        },
      );

    if (userId) {
      user.set('extraFields', user.get('extraFields') || {});
    }

    return hash({
      buildings: store.findAll('building').then((collection) => collection.filter((building) => 'main' === building.type)),

      campaigns: store.findAll('campaign')
        .then((collection) => collection.filter((campaign) => campaign.crm).sortBy('name')),

      channels: store.findAll('channel'),

      commerces: store.findAll('commerce').then((collection) => collection.sortBy('name')),

      user,

      userTypes: store.findAll('userType').then((collection) => collection.sortBy('label')),
    });
  }

  afterValidate(model, returnRoute) {
    const {
      // hasNotifications,
      isNew,
    } = getProperties(this.controller.model.user, ['isNew', 'hasNotifications']);
    const hasResidential = this.can.can('access module-residential');

    set(this, 'returnRoute', returnRoute);

    // if (!hasNotifications) {
    //   this.controller.toggleProperty('isOpenNotificationsModal');

    //   return true;
    // }

    if (hasResidential && isNew) {
      this.controller.toggleProperty('isOpenModal');

      return true;
    }

    return super.afterValidate(model, returnRoute);
  }

  async setupController(ctrl, model) {
    super.setupController(ctrl, model);

    const store = get(this, 'store');
    const filter = {
      filter: {},
    };

    set(ctrl, 'isOpenModal', false);

    if (this.can.can('access module-residential')) {
      if (this.can.can('read all residential-development')) {
        set(filter, 'filter.center_id', get(this, 'auth.centerId'));
      } else {
        set(filter, 'filter.staff_id', get(this, 'auth.user.id'));
      }
      store.query(
        'residentialDevelopment',
        filter,
      ).then(async (residentialDevelopments) => {
        set(
          ctrl,
          'model.residentialDevelopments',
          residentialDevelopments,
        );
        const selectedResidentialDevelopments = await this.getSelectedResidentialDevelopments(model.user.get('id'), store, residentialDevelopments);
        set(ctrl, 'model.selectedResidentialDevelopments', selectedResidentialDevelopments);
      });
    }
  }

  @action
  saveUser() {
    const hasResidential = this.can.can('access module-residential');
    const editing = !get(this.controller, 'model.user.isNew');

    if (!hasResidential || editing) {
      set(this.controller, 'loading', true);
      const user = get(this.controller, 'model.user');

      return user.save().then((record) => {
        set(this.controller, 'loading', false);
        set(this.controller, 'isOpenNotificationsModal', false);
        this.didSaveModel(record, get(this, 'returnRoute'));
      }).catch((errors) => {
        set(this.controller, 'errors', parseErrors(errors));
        set(this.controller, 'loading', false);
        set(this.controller, 'isOpenNotificationsModal', false);
      });
    }

    set(this.controller, 'isOpenNotificationsModal', false);
    this.controller.toggleProperty('isOpenModal');
    set(this, 'returnRoute', get(this, 'returnRoute'));

    return true;
  }

  @action
  saveUserAndRequest(residentialDevelopments) {
    set(this.controller, 'loading', true);
    const user = get(this.controller, 'model.user');

    if (!residentialDevelopments || !residentialDevelopments.length) {
      set(this, 'controller.errorSelectedDevelopment', get(this, 'i18n').t('error.development.required'));
      set(this.controller, 'loading', false);
      return Promise.resolve();
    }
    return user.save().then((record) => {
      const residentialRequests = residentialDevelopments.map((dev) => get(this, 'store').createRecord(
        'residential-request',
        {
          requestType: 'and',
          user: record,
          staff: get(this, 'auth.user'),
          name: get(dev, 'name'),
          filters: {
            residential_development: [
              {
                op: 'is',
                value: get(dev, 'id'),
              },
            ],
          },
        },
      ));

      return Promise.all(residentialRequests.map((req) => req.save())).then(() => {
        set(this.controller, 'loading', false);
        set(this, 'controller.errorSelectedDevelopment', null);
        set(this.controller, 'isOpenModal', false);
        set(this.controller, 'model.selectedResidentialDevelopments', [...this.controller.model.selectedResidentialDevelopments, ...residentialDevelopments]);
        set(this.controller, 'selectedResidentialDevelopments', null);
        this.didSaveModel(record, get(this, 'returnRoute'));
      });
    }).catch((errors) => {
      set(this.controller, 'errors', parseErrors(errors));
      set(this.controller, 'loading', false);
      set(this.controller, 'isOpenModal', false);
    });
  }

  @action
  saveRequest(form) {
    const residentialRequest = get(this, 'store').createRecord(
      'residential-request',
      {
        filters: get(form, 'filters'),
        name: get(form, 'name'),
        requestType: 'and',
        user: get(this, 'controller.model.user'),
        staff: get(this, 'auth.user'),
      },
    );

    return residentialRequest.save().then(() => {
      const residentialRequests = get(this, 'controller.residentialRequests').slice();
      residentialRequests.unshift(residentialRequest);
      set(this, 'controller.residentialRequests', residentialRequests);
      set(this, 'controller.showAdvancedSearch', false);
      set(this, 'controller.selectedSegment', null);
    });
  }

  // TODO avoid to use route-actions: it doesn't fits with ES6 Class routes
  @action
  createProposals(...args) {
    return this.createProposals(...args);
  }

  @action
  handleSelectedRequest(...args) {
    return this.handleSelectedRequest(...args);
  }

  @action
  saveRequestChanges(...args) {
    return this.saveRequestChanges(...args);
  }

  didSaveModel() {
    return new Promise((resolve) => {
      set(this.controller, 'successMessage', i18n.t('crm.users.user-saved'));
      set(this.controller, 'state', 'success');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      resolve();
    });
  }

  getSelectedResidentialDevelopments(userId, store, residentialDevelopments) {
    return new Promise((resolve) => {
      const filter = {
        filter: {
          user_id: userId,
        },
      };
      store.query(
        'residential-request',
        filter,
      ).then((residentialRequests) => {
        const residentialDevelopmentIds = residentialRequests
          .map((request) => request.filters.residential_development[0].value);
        const selectedDevelopments = residentialDevelopments
          .filter((development) => residentialDevelopmentIds.includes(development.id));
        resolve(selectedDevelopments);
      });
    });
  }
}
