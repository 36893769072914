import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  endDatetime() {
    return faker.date.recent();
  },

  name() {
    return faker.random.word();
  },

  subject() {
    return faker.random.word();
  },

  startDatetime() {
    return faker.date.recent();
  },
});
