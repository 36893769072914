import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

export default class extends Model {
  @attr('string') name

  @attr('string') tag

  @belongsTo('center') center
}
