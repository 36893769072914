import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import Serializer from './application';

function sanitizeChannels(channels) {
  if (!channels) {
    return null;
  }

  return channels.filter((elem) => elem['image-url']);
}

export default class ProposalSerializer extends Serializer {
  @service auth

  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);

    const lenguageCode = get(this.auth.center, 'defaultLanguage.code');

    if (
      json.data.attributes
      && json.data.attributes.languages
      && json.data.attributes.languages.length
    ) {
      const language = json.data.attributes.languages.findBy('code', lenguageCode);
      json.data.attributes.name = language?.name;
      json.data.attributes.description = language?.description;
      json.data.attributes.channels = sanitizeChannels(language?.channels);
    }

    return json;
  }
}
