import Component from '@ember/component';
import EmberObject, { get } from '@ember/object';
import { sort } from '@ember/object/computed';

import roomTypes from 'neuro-frontend/enums/residential-room-types';

export default Component.extend({
  rooms: [],

  roomTypes,

  classNames: ['residential-property-rooms-list'],

  sortedRoomTypes: sort('roomTypes', (a, b) => {
    if (get(a, 'label') > get(b, 'label')) {
      return 1;
    } if (get(a, 'label') < get(b, 'label')) {
      return -1;
    }

    return 0;
  }),

  didRender() {
    this._super();

    if (!get(this, 'rooms.length') && (get(this, 'rooms') instanceof Array)) {
      get(this, 'rooms').pushObject(new EmberObject());
    }
  },

  actions: {
    addRoom() {
      get(this, 'rooms').pushObject(new EmberObject());
    },

    deleteRoom(room) {
      get(this, 'rooms').removeObject(room);
    },
  },
});
