import { get, computed } from '@ember/object';

import ModuleResidential from './module-residential';

export default class extends ModuleResidential {
  @computed('auth.user.level')
  get canCreate() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(level);
  }

  @computed('auth.user.level')
  get canRead() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(level);
  }

  @computed('auth.user.level', 'canRead')
  get canReadAll() {
    const level = get(this, 'auth.user.level');
    const canRead = get(this, 'canRead');

    return canRead && ['admin', 'manager'].includes(level);
  }

  @computed('auth.user.level')
  get canDelete() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(level);
  }

  @computed('auth.user.level')
  get canModifyStaff() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess')
      && ['admin', 'manager'].includes(level);
  }
}
