/* eslint babel/camelcase: "off" */
import { computed, get } from '@ember/object';
import Model from 'ember-data/model';
import { attr, hasMany } from '@ember-data/model';

export default class extends Model {
  // @TODO change to "country" and belongsTo after being migrated to v4
  @attr('boolean') active_sales_year_statement

  @attr('number') country_id

  @attr('string') intranet_policies_url

  @attr('array', { defaultValue() { return []; } }) languages

  @attr('json') modules

  @attr('string') name

  // added to solve an association problem in acceptance tests
  // it isn't needed in the rest of the code
  @hasMany('staffMember') staffMembers

  @computed('modules')
  get categoryLevel() {
    const modules = get(this, 'modules');

    return modules && get(this, 'hasMultilevel') && get(modules, 'multilevel.category_level')
      ? Number(get(modules, 'multilevel.category_level')) : 0;
  }

  @computed('languages')
  get defaultLanguage() {
    return get(this, 'languages').findBy('default', true);
  }

  @computed('modules')
  get hasBI() {
    return this.has('bi');
  }

  @computed('modules')
  get hasCMS() {
    return this.has('cms');
  }

  @computed('modules')
  get hasCRM() {
    return this.has('crm');
  }

  @computed('modules')
  get hasExternalSales() {
    return this.has('external_sales');
  }

  @computed('modules')
  get hasExternalSalesYardi() {
    return this.has('external_sales.yardi');
  }

  @computed('modules')
  get hasFlows() {
    return this.has('intranet.flows');
  }

  @computed('modules')
  get hasIntranet() {
    return this.has('intranet');
  }

  @computed('modules')
  get hasIntranetYardi() {
    return this.has('intranet.yardi');
  }

  @computed('modules')
  get hasLoyaltyCards() {
    return this.has('crm.loyalty_cards');
  }

  @computed('modules')
  get hasMailing() {
    return this.has('marketing.mailing');
  }

  @computed('modules')
  get hasMarketingAnalytics() {
    return this.has('marketing_analytics');
  }

  @computed('modules')
  get hasMultilevel() {
    return this.has('multilevel');
  }

  // this property should be removed after we have migrate all the CMS ecosystem
  @computed('modules')
  get hasNewCMS() {
    return this.has('cms.new-panel');
  }

  @computed('modules')
  get hasPlaycenter() {
    return this.has('crm.play_center');
  }

  @computed('modules')
  get hasResidential() {
    return this.has('residential');
  }

  @computed('modules')
  get hasTickets() {
    return this.has('crm.tickets');
  }

  @computed('modules')
  get hasRewards() {
    return this.has('crm.rewards');
  }

  @computed('modules')
  get userRequired() {
    const requiredAttrs = get(this, 'modules.crm.user_required');

    return requiredAttrs ? requiredAttrs.split(',') : [];
  }

  has(module) {
    const {
      modules,
    } = this;

    return modules && get(modules, module);
  }
}
