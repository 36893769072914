// Inspirated in Google's Guava
// https://github.com/google/guava/wiki/PreconditionsExplained
function IllegalStateError(message) {
  this.name = 'IllegalStateError';
  this.message = (message || 'Precondition failed.');
  this.stack = new Error().stack;
}

IllegalStateError.prototype = Object.create(Error.prototype);

export default IllegalStateError;
