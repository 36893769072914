import Service from 'ember-can/services/can';

export default class CanService extends Service {
  filterByModules(arr) {
    if (!arr || !arr.length) {
      return [];
    }

    return arr.filter((elem) => elem.includes.some((module) => this.can(`access to module-${module}`)));
  }
}
