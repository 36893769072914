/**
 * @module    nf
 * @submodule nf-utils
 */
import moment from 'moment';

import IllegalArgumentError from '../errors/illegal-argument-error';
import Month from './month';
import Year from './year';

const subtractMonth = (date) => moment(date).subtract(1, 'M').toDate();

const diffMonths = (date, currentDate) => {
  if (Number.isNaN(date.getTime())) {
    throw new IllegalArgumentError('param "n" is an invalid Date');
  }

  const dateWithoutDay = moment(date).format('YYYY-MM');
  const currentDateWithoutDay = moment(currentDate).format('YYYY-MM');

  return moment(currentDateWithoutDay).diff(moment(dateWithoutDay), 'months');
};
/**
 * getLastMonths
 * --
 *
 * It retrieves a collection of months to the current Date. It allows to specify
 * the number of last months to retrieve or using an specific date. The collection also
 * adds the current month and the month used as entry.
 *
 * @param   {Number|Date} n   Months to retrieve from a Date or number of months.
 * @return  {Array[Month]}    An Array of Months from current date.
 */
export default function getLastMonths(n) {
  let date = new Date();
  const months = [];

  if (('number' !== typeof n) && !(n instanceof Date)) {
    throw new TypeError(`type of n is ${typeof n} and it should be a Number or an instance of Date`);
  }

  let numMonths = (n instanceof Date) ? diffMonths(n, date) : n;

  while (0 <= numMonths) {
    months.push(new Month(date));
    date = subtractMonth(date);
    numMonths -= 1;
  }

  return months;
}
/**
 * getLastYearsMonths
 * --
 *
 * It retrieves a collection of months to the current Date. It allows to specify
 * the number of years to retrieve.
 *
 * @param   {Number} n        Number of years before to get months.
 * @return  {Array[Month]}    An Array of Months from current date.
 */
export function getLastYearsMonths(n) {
  if ('number' !== typeof n) {
    throw new TypeError(`getLastYearsMonths: type of n is ${typeof n} and it should be a Number`);
  }

  if (0 > n) {
    return [];
  }

  const year = new Year(new Date());

  return getLastMonths(diffMonths(new Date(Number(year.value) - n, 0), new Date()));
}
