import MdInput from 'ember-cli-materialize/components/md-input';
import { action, set } from '@ember/object';

export default class extends MdInput {
@action
  onupdate(unmasked, masked) {
    set(this, 'unmasked', unmasked);
    set(this, 'masked', masked);
    set(this, 'value', masked);
  }
}
