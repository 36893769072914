export default Object.freeze([
  {
    label: i18n.t('man'),
    value: 'H',
  },
  {
    label: i18n.t('woman'),
    value: 'M',
  },
  {
    label: i18n.t('other'),
    value: 'O',
  },
]);
