export default Object.freeze([
  {
    label: i18n.t('all'),
    value: 'and',
  },
  {
    label: i18n.t('any-of'),
    value: 'or',
  },
]);
