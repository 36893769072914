import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';
import moment from 'moment';

import { keepLatestTask } from 'ember-concurrency-decorators';

import Month from 'neuro-frontend/utils/month';
import Day from 'neuro-frontend/utils/day';

const calculateWeek = (date, arr) => {
  const week = [];

  for (let i = 0; 7 > i; i += 1) {
    const day = new Day(date);
    // @TODO refactor dates
    const model = arr.filterBy('date', day.value);

    week.push({ day, model });
    date.setDate(date.getDate() + 1);
  }

  return week;
};
/**
 * ## CalendarMonthComponent
 *
 * ```hbs
 * {{calendar-month
 *   cellType=<String>
 *   selectedDay=<Day>
 *   model=<Array[Model]>
 *   valuePath=<String>
 *   onChangedDay=<Action(Day)>
 *   onChangedMonth=<Action(Range)>
 * }}
 * ```
 *
 * - Range: {
 *    start_date: String('YYYY-MM-DD'),
 *    end_date: String('YYYY-MM-DD')
 * }
 *
 * @class CalendarMonthComponent
 * @namespace NF
 * @extends Ember.Component
 * @public
 */
export default class extends Component.extend({
  isLoading: false,

  selectedMonth: null,
}) {
  classNames = ['calendar-month']

  @computed('selectedMonth', 'model')
  get days() {
    const date = new Date(get(this, 'selectedMonth.startDate'));
    const endDate = new Date(get(this, 'selectedMonth.endDate'));
    const diffDays = (date.getDay() ? date.getDay() : 7) - 1;
    const days = [];

    // first day to show
    date.setDate(date.getDate() - diffDays);

    while (moment(date).isSameOrBefore(moment(endDate))) {
      days.push(calculateWeek(date, get(this, 'model')));
    }

    return days;
  }

  @computed('model', 'selectedDay')
  get selected() {
    return get(this, 'model').filterBy('date', get(this, 'selectedDay.value'));
  }

  init(...args) {
    super.init(...args);

    this.resetSelectedMonth();
  }

  @keepLatestTask
  notifyMonthChange = function* () {
    set(this, 'isLoading', true);
    this.notifyPropertyChange('selectedMonth');

    yield this.onChangedMonth({
      start_date: moment(get(this, 'selectedMonth.value')).startOf('month').format('YYYY-MM-DD'),
      end_date: moment(get(this, 'selectedMonth.value')).endOf('month').format('YYYY-MM-DD'),
    });

    set(this, 'isLoading', false);
  }

  @action
  nextMonth() {
    get(this, 'selectedMonth')
      .addMonths(1);
    this.notifyMonthChange.perform();
  }

  @action
  prevMonth() {
    get(this, 'selectedMonth')
      .addMonths(-1);
    this.notifyMonthChange.perform();
  }

  @action
  selectDay(tuple) {
    if (!(tuple.day instanceof Day)) {
      throw new TypeError(`actions.selectDay: ${tuple.day} is not of type Day`);
    }

    if (tuple.model && !(tuple.model instanceof Array)) {
      throw new TypeError(`actions.selectDay: ${tuple.model} is not of type Array`);
    }

    set(this, 'selectedDay', tuple.day);

    if (!tuple.day.belongsToMonth(get(this, 'selectedMonth'))) {
      set(this, 'selectedMonth', get(tuple.day, 'month'));
      this.notifyMonthChange.perform().then(() => this.onChangedDay(tuple.day));
    } else {
      this.onChangedDay(tuple.day);
    }
  }

  resetSelectedMonth() {
    set(this, 'selectedMonth', new Month());
  }
}
