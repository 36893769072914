define("neuro-frontend/components/gantt-chart", ["exports", "@kockpit/ember-gantt/components/gantt-chart"], function (_exports, _ganttChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ganttChart.default;
    }
  });
});
