import Model from 'ember-data/model';
import { set, computed } from '@ember/object';
import { attr, belongsTo } from '@ember-data/model';

import Validator from 'ember-model-validator/mixins/model-validator';

export default class PushNotificationModel extends Model.extend(Validator, {}) {
  @attr('json', { defaultValue() { return {}; } }) actionData

  @attr('string', { defaultValue: 'default' }) actionType

  @attr('datetime') createdAt

  @attr('datetime') endDatetime

  @attr('string', { defaultValue: 'single-sending' }) frequency

  @attr('string') imageUrl

  @attr('string') message

  @attr('string') name

  @attr('string') pushNotificationAction

  @attr('datetime') startDatetime

  @attr('string', { defaultValue: 'draft' }) state

  @attr('string') subject

  @attr('datetime') updatedAt

  @belongsTo('campaign') campaign

  @belongsTo('center') center

  @belongsTo('segment') segment

  @computed('actionType', 'frequency')
  get validations() {
    return {
      actionData: {
        presence: {
          if: () => 'proposal' === this.actionType,
          message: i18n.t('required', { attr: i18n.t('push-notification.action-data.proposal') }),
        },

        custom: {
          if: () => 'proposal' === this.actionType,
          message: i18n.t('required', { attr: i18n.t('push-notification.action-data.proposal') }),
          validation: () => this.actionData.proposal_id,
        },
      },

      actionType: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('push-notification.action-type') }),
        },
      },

      endDatetime: {
        presence: {
          if: () => 'single-sending' !== this.frequency,
          message: i18n.t('required', { attr: i18n.t('push-notification.end-datetime') }),
        },

        date: {
          if: () => 'single-sending' !== this.frequency,
          after: this.startDatetime,
          message: i18n.t('error.push-notification.event-end-date.after'),
        },
      },

      frequency: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('push-notification.frequency') }),
        },
      },

      message: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('push-notification.message') }),
        },
      },

      segment: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('push-notification.segment') }),
        },
      },

      startDatetime: {
        presence: {
          if: () => 'single-sending' !== this.frequency,
          message: i18n.t('required', { attr: i18n.t('push-notification.start-datetime') }),
        },
      },

      subject: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('push-notification.subject') }),
        },
      },
    };
  }

  async publish() {
    set(this, 'pushNotificationAction', 'send');

    await this.save();

    set(this, 'pushNotificationAction', null);
  }
}
