import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  showConfirm: service('show-confirm-deletion'),

  isOpen: oneWay('showConfirm.isConfirming'),

  extraWarning: null,

  closeModal() {
    this.get('showConfirm').hide();
  },

  actions: {
    closeModal() {
      this.closeModal();
    },

    confirmDelete() {
      if (!this.get('deleting')) {
        this.set('deleting', true);

        this.get('onConfirm')(this.get('showConfirm.modelToDelete'))
          .then(() => {
            this.closeModal();
          })
          .finally(() => {
            this.set('deleting', false);
          });
      }
    },
  },
});
