import Model from 'ember-data/model';

import { attr, belongsTo } from '@ember-data/model';

export default class extends Model {
  @attr('string') alertType

  @attr('number') correction

  @attr('datetime') createdAt

  // Relationships

  @belongsTo('commerce') commerce

  @belongsTo('staffMember') staff
}
