import Controller from '@ember/controller';
import {
  action, computed, get, set,
} from '@ember/object';

import { isArray } from '@ember/array';
import { inject as service } from '@ember/service';

import levels from 'neuro-frontend/enums/staff-levels';

export default class extends Controller {
  @service auth

  selectedCommerces = []

  staffLevels = levels

  @computed('auth.centersAvailable', 'auth.user.level', 'model.centers')
  get allowedCenters() {
    const centers = get(this, 'model.centers');
    const availableCenters = get(this, 'auth.centersAvailable');
    const level = get(this, 'auth.user.level');

    return 'admin' === level ? centers : availableCenters;
  }

  @computed('staffLevels', 'auth.user.role')
  get allowedLevels() {
    const staffLevels = get(this, 'staffLevels');
    return staffLevels.filter((level) => level.creators.includes(get(this, 'auth.user.role')));
  }

  @computed('model.staffMember.centers', 'model.buildings')
  get filteredBuildings() {
    const {
      buildings,
      staffMember: {
        centers,
      },
    } = this.model;

    if (!centers.length) {
      return [];
    }

    return buildings.filterBy('center.id', get(centers.firstObject, 'id'));
  }

  @computed('model.staffMember.centers', 'model.channels')
  get filteredChannels() {
    const {
      staffMember: {
        centers,
      },
    } = this.model;

    if (!centers.length) {
      return [];
    }
    return get(this, 'store').query(
      'channel',
      {
        filter: {
          center_id: get(centers.firstObject, 'id'),
        },
      },
    );
  }

  @computed('model.staffMember.isNew', 'model.staffMember.pass')
  get rules() {
    const isNew = get(this, 'model.staffMember.isNew');
    const pass = get(this, 'model.staffMember.pass');
    const staffMember = get(this, 'model.staffMember');
    const hasEmailChanged = Object.keys(staffMember.changedAttributes()).includes('email');

    return (isNew || hasEmailChanged || pass)
      ? { except: ['passOld'] }
      : { except: ['passOld', 'pass', 'passConfirmation'] };
  }

  @computed
  get tabs() {
    return [
      {
        icon: 'perm_identity',
        label: i18n.t('general-data'),
        name: 'general',
      },
    ];
  }

  @action
  addCommerces(model) {
    if ('commerce' === model.level) {
      model.commerces.clear();
      model.commerces.pushObjects(this.selectedCommerces);
    }

    return true;
  }

  @action
  changeCenters() {
    const centers = get(this, 'model.staffMember.centers');

    set(this.model, 'defaultBuilding', null);

    if (centers.length) {
      set(this, 'selectedCommerces', this.selectedCommerces.filter((commerce) => centers.map((value) => get(value, 'id')).includes(get(commerce.center, 'id'))));
    } else {
      this.selectedCommerces.clear();
    }
  }

  @action
  searchCommerces(centers, term) {
    if (isArray(centers) && term && 2 <= term.length) {
      return get(this, 'store').query('commerce', {
        filter: {
          name: term,
          center_id: centers.map((value) => get(value, 'id')),
        },
      });
    }
    return [];
  }
}
