import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterBoolean from 'neuro-frontend/utils/filter-operation/filter-boolean';
import FilterContains from 'neuro-frontend/utils/filter-operation/filter-contains';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

import maritalStatus from './marital-status';
import personTypes from './person-types';
import purchaserTypes from './purchaser-types';
import sexes from './sexes';
import propertyTypes from './residential-unit-types';
import residentialStates from './residential-user-states';

export default Object.freeze([
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('created_at'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'created_dates',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('name'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterContains(),
    value: 'name',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('last-name'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterContains(),
    value: 'last_name',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('dni'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterContains(),
    value: 'dni',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('person-type'),
    model: personTypes,
    modules: ['residential'],
    operation: new FilterIs(),
    value: 'person_type',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('purchaser-type'),
    model: purchaserTypes,
    modules: ['residential'],
    operation: new FilterIs(),
    value: 'purchaser_type',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('sex'),
    model: sexes,
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterIs(),
    value: 'sex',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('age'),
    modules: ['CRM', 'residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'age',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('province_id'),
    model: 'provinces',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterIs(),
    value: 'provinces',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('city'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterContains(),
    value: 'city',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('cp'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterContains(),
    value: 'cp',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('has-email'),
    modules: ['CMS'],
    operation: new FilterBoolean(),
    value: 'has_email',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('email'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterContains(),
    value: 'email',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('mail_notifications'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterBoolean(),
    value: 'mail_notifications',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('mail_partners_notifications'),
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterBoolean(),
    value: 'mail_partners_notifications',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('mstatus'),
    model: maritalStatus,
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterIs(),
    value: 'mstatus',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('bdate'),
    modules: ['CRM', 'residential'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'bdate',
  },
  {
    format: 'MM-DD',
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('crm.users.children-bdate-month-day'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'date',
          value: 'start',
        },
        {
          type: 'date',
          value: 'end',
        },
      ],
    }),
    value: 'children_birthday_month_day',
  },
  {
    format: 'MM-DD',
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('crm.users.bdate-month-day'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'date',
          value: 'start',
        },
        {
          type: 'date',
          value: 'end',
        },
      ],
    }),
    value: 'bdate_month_day',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('crm.users.bdate-today'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'bdate_today',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('user.bday-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: i18n.t('following'),
          type: 'static-text',
        },
        {
          label: i18n.t('value'),
          type: 'number',
          value: 'min',
        },
        {
          label: i18n.t('and'),
          type: 'static-text',
        },
        {
          label: i18n.t('value'),
          type: 'number',
          value: 'max',
        },
        {
          label: i18n.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'birthday_days',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('nationality_id'),
    model: 'countries',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM', 'residential', 'offices'],
    operation: new FilterIs(),
    value: 'nationalities',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('company'),
    modules: ['residential', 'offices'],
    operation: new FilterContains(),
    value: 'company',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('channel_id'),
    model: 'channels',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM', 'residential'],
    operation: new FilterIs(),
    value: 'channels',
  },
  {
    groupName: i18n.t('crm.filters-section.demographic'),
    label: i18n.t('campaign_id'),
    model: 'campaigns',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM', 'residential'],
    operation: new FilterIs(),
    value: 'campaigns',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('user_types'),
    modules: ['CMS'],
    model: 'userTypes',
    modelLabel: 'label',
    modelValue: 'id',
    operation: new FilterIs(),
    value: 'usertypes',
  },
  {
    groupName: i18n.t('crm.filters-section.crm'),
    label: i18n.t('user.segment'),
    model: 'segments',
    modelLabel: 'segmentName',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterIs(),
    value: 'segments',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.province'),
    model: 'provinces',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    value: 'property.province',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.city'),
    modules: ['residential'],
    operation: new FilterContains({
      onlyPositive: true,
    }),
    value: 'property.city',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.price'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'property.price',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.built_surface'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'property.built_surface',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.useful_surface'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'property.useful_surface',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.property_type'),
    model: propertyTypes,
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    value: 'property.residential_property_type',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.bedrooms_number'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'property.bedrooms_number',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.bathrooms_number'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'property.bathrooms_number',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.toilets_number'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'property.toilets_number',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property-filter.residential_development'),
    model: 'residentialDevelopments',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    value: 'property.residential_development',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('crm.users.state'),
    model: residentialStates,
    modules: ['residential'],
    operation: new FilterIs(),
    value: 'residential_state',
  },
]);
