import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import FormRoute from 'neuro-frontend/routes/form';

export default class extends FormRoute {
  @service auth

  @service can

  templateName = 'residential/properties/new'

  model({ 'residential-property_id': propertyId }) {
    const store = get(this, 'store');
    const residentialProperty = propertyId
      ? store.findRecord(
        'residentialProperty',
        propertyId,
      )
      : store.createRecord(
        'residentialProperty',
        {},
      );
    const residentialDevelopments = this.can.can('read all residential-development')
      ? store.findAll('residentialDevelopment')
      : store.query(
        'residentialDevelopment',
        {
          filter: {
            staff_id: get(this, 'auth.user.id'),
          },
        },
      );
    const queries = {
      residentialProperty,

      propertyTypes: store.findAll('residentialPropertyType'),

      residentialDevelopments,
    };

    if (propertyId) {
      queries.residentialOffers = store.query(
        'residentialOffer',
        {
          filter: {
            'residential-proposal.residential-property': [{
              op: 'is',
              value: propertyId,
            }],
          },
        },
      );
    }

    return RSVP.hash(queries);
  }

  resetController(ctrl) {
    set(ctrl, 'errorMessages', []);
    set(ctrl, 'isReserved', false);
    set(ctrl, 'user', null);
    set(ctrl, 'reservedDate', null);
    set(ctrl, 'notes', null);
  }

  setupController(ctrl, model) {
    const {
      residentialOffers: offers,
    } = model;
    const activeOffers = offers && offers.filter((offer) => ['accepted', 'signed'].includes(offer.state));

    super.setupController(ctrl, model);

    if (activeOffers && activeOffers.length) {
      const offer = activeOffers.firstObject;

      set(ctrl, 'isReserved', true);

      offer.residentialProposal.then((proposal) => proposal.residentialRequest)
        .then((request) => request.user)
        .then((user) => set(ctrl, 'user', user));

      set(ctrl, 'notes', offer.notes);
      set(ctrl, 'reservedDate', offer.openingDate);
    }
  }
}
