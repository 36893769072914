export default Object.freeze([
  {
    label: i18n.t('none'),
    value: 'none',
    module: '',
  },
  {
    label: i18n.t('raffle'),
    value: 'raffle',
    module: 'module-tickets',
  },
  {
    label: i18n.t('loyalty-card'),
    value: 'loyalty-card',
    module: 'module-loyalty-cards',
  },
  {
    label: i18n.t('reward'),
    value: 'reward',
    module: 'module-rewards',
  },
]);
