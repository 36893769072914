import Component from '@ember/component';
import { computed } from '@ember/object';

export default class extends Component {
  classNames = ['nf-kpi__comparative-data']

  @computed('mainValue', 'value')
  get trendIcon() {
    const {
      mainValue,
      value,
    } = this;
    return this.getIcon(mainValue, value);
  }

  @computed('mainValue', 'value')
  get percentage() {
    const {
      mainValue,
      value,
    } = this;
    return this.getPercentage(mainValue, value);
  }

  getIcon(value, prevValue) {
    if (value === prevValue) {
      return '=';
    }
    return (value > prevValue)
      ? 'trending_up'
      : 'trending_down';
  }

  getPercentage(value, prevValue) {
    if (value && Number.isFinite(value)) {
      return (0 !== prevValue && undefined !== prevValue) ? `${Math.round(Math.abs(((value * 100) / prevValue) - 100))}%`
        : 'INF%';
    } return '--';
  }
}
