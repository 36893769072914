import Controller from '@ember/controller';
import {
  get, set, action, computed,
} from '@ember/object';
import { inject as service } from '@ember/service';

import ListMixin from 'neuro-frontend/mixins/nf-list';
import SegmentFiltersMixin from 'neuro-frontend/mixins/segment-filters';
import ResidentialProperty from 'neuro-frontend/models/residential-property';
import propertyFilters from 'neuro-frontend/enums/property-filters';
import beautifyFilters from 'neuro-frontend/utils/beautify-filters';

import residentialPropertiesColumns from 'neuro-frontend/utils/residential-properties-columns';

const MixinController = Controller.extend(ListMixin, SegmentFiltersMixin, {});

export default class extends MixinController {
  @service auth

  @service can

  modelName = 'residentialProperty'

  sort = '-updated-at'

  editRoute = 'residential.properties.edit'

  init(...args) {
    super.init(...args);

    set(
      this,
      'fixedFilters',
      {
        center_id: get(this, 'auth.centerId'),
      },
    );
  }

  @computed('view', 'currency')
  get columns() {
    const {
      currency,
      view,
    } = this;
    return residentialPropertiesColumns(view, currency);
  }

  @computed('auth.center')
  get modelFilters() {
    const center = get(this, 'auth.center');
    return beautifyFilters(propertyFilters, center);
  }

  @action
  block(row) {
    const property = row ? get(row, 'content') : undefined;

    if (!(property instanceof ResidentialProperty)) {
      throw new TypeError(`actions.block: ${property} is not of type Residential Property`);
    }

    set(property, 'state', 'blocked');

    property.save().then(() => {
      this.notifyPropertyChange('model.residentialProperties');
      this.loadCollection();
    });
  }

  @action
  async duplicate(row) {
    const property = row ? get(row, 'content') : undefined;
    let newProperty = null;

    if (!(property instanceof ResidentialProperty)) {
      throw new TypeError(`actions.duplicate: ${property} is not of type Residential Property`);
    }

    newProperty = await property.copy();
    newProperty.save().then((model) => {
      if ('function' === typeof get(this, 'onDuplicate')) {
        get(this, 'onDuplicate')(model);
      }
    });
    this.transitionToRoute('residential.properties.edit', get(property, 'id'));
  }

  @action
  publish(row) {
    const property = row ? get(row, 'content') : undefined;

    set(property, 'visibility', 'published');

    property.save().then(() => {
      this.notifyPropertyChange('model.residentialProperties');
      this.loadCollection();
    });
  }

  @action
  unpublish(row) {
    const property = row ? get(row, 'content') : undefined;

    set(property, 'visibility', 'draft');

    property.save().then(() => {
      this.notifyPropertyChange('model.residentialProperties');
      this.loadCollection();
    });
  }

  loadCollection() {
    const canReadAll = this.can.can('read all residential-development');
    const query = get(this, 'query');
    const hasDevelopmentFilter = (q) => get(q, 'filter.residential_development');

    if (!canReadAll && !hasDevelopmentFilter(query)) {
      set(this, 'model.residentialProperties', []);
      set(this, 'isLoading', false);
      return [];
    }

    return super.loadCollection();
  }
}
