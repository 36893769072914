import { get, computed } from '@ember/object';
import Cell from './base';

export default Cell.extend({
  classNames: ['calendar-month__cell--staff_member'],

  email: computed('value', function () {
    return get(this, 'value.firstObject.email') || '';
  }),
});
