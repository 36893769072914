import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { set, get } from '@ember/object';
import Route from '@ember/routing/route';

export default class extends Route {
  @service auth

  @service can

  templateName = 'residential/developments/new'

  afterValidate() {
    this.controller.toggleProperty('isOpenModal');
  }

  async model({ 'residential-development_id': developmentId }) {
    const centerId = get(this, 'auth.centerId');
    const store = get(this, 'store');
    const development = developmentId
      ? await store.findRecord(
        'residentialDevelopment',
        developmentId,
      )
      : store.createRecord(
        'residentialDevelopment',
        {},
      );

    const languages = await store.query('language', {
      filter: {
        center_id: get(this, 'auth.centerId'),
      },
    });

    // @TODO research for a way to add this behaviour in the model
    // Would it work an 'i18n-model' mixin and overriding a model hook?
    if (development.isNew || languages.length !== development.languages.length) {
      languages
        .filter((l) => !development.languages.findBy('code', l.code))
        .forEach((l) => {
          development.languages.pushObject({
            code: l.code,
          });
        });
    }

    return hash({
      development,

      countries: store.findAll('country'),

      languages,

      owners: store.query(
        'residentialOwner',
        {
          filter: {
            center_id: centerId,
          },
        },
      ),

      provinces: store.findAll('province'),

      residentialPropertyTypes: store.findAll('residentialPropertyType'),

      residentialQualities: store.findAll('residentialQuality'),

      residentialServices: store.findAll('residentialService'),
    });
  }

  async setupController(ctrl, model) {
    const store = get(this, 'store');
    const centerId = get(this, 'auth.centerId');

    super.setupController(ctrl, model);

    set(
      ctrl,
      'propertiesParams.filter.residential_development_id',
      model.development.id,
    );

    set(
      ctrl,
      'model.properties',
      !model.development.isNew
        ? store.query(
          'residentialProperty',
          get(ctrl, 'propertiesParams'),
        )
        : [],
    );

    if (this.can.can('modify staff residential-development')) {
      set(
        ctrl,
        'model.staffMembers',
        await store.query(
          'staffMember',
          {
            filter: {
              center_id: centerId,
            },
          },
        ),
      );
    }
  }
}
