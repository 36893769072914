import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  address() {
    return faker.address.streetAddress();
  },
  city() {
    return faker.address.city();
  },
  name() {
    return faker.company.companyName();
  },
  type() {
    return faker.random.word();
  },
  zipCode() {
    return faker.address.zipCode();
  },

  center: association(),
  province: association(),

  afterCreate(_building, server) {
    const building = _building;
    building.commerces = server.createList('commerce', 2);
  },
});
