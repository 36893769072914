/* eslint max-statements: "off" */
import ENV from 'neuro-frontend/config/environment';
import cms from './cms';
import crm from './crm';
import ma from './ma';

const scenarios = {
  cms,
  crm,
  ma,
};

const activeScenario = ENV.MIRAGE_SCENARIO;

export default function (server) {
  /*
    Seed your development database using your factories.
    This data will not be loaded in your tests.

    Make sure to define a factory for each model you want to create.
  */
  const scenario = scenarios[activeScenario];

  // return the created centers in every new scenario
  const centers = scenario
    ? scenario(server)
    : server.createList('center', 1);

  server.createList('building', 10);
  server.createList('category', 100);

  // create superadmin user
  server.create(
    'staffMember',
    {
      email: 'soluciones@neuromobilemarketing.com',
      level: 'admin',
      centers,
    },
  );

  server.create(
    'staffMember',
    {
      email: 'manager@cbre.com',
      level: 'manager',
      centers,
    },
  );

  server.createList('channel', 5);
  server.createList('commerce', 20);
  server.createList('country', 10);
  server.createList('staffMember', 30, { centers });
  server.loadFixtures('campaignKpis');
}
