import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { CanMixin } from 'ember-can';

export default Route.extend(CanMixin, {
  pageHeader: service(),

  beforeModel() {
    if (!this.can('read residential-owner')) {
      this.transitionTo('index');
    }
    // Changes title header of the page
    this.get('pageHeader').set('title', this.get('i18n').t('title.header.residential.owners'));
  },
});
