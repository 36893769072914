import { get, action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

import Cell from 'ember-light-table/components/cells/base';

export default class extends Cell {
  @service can

  @alias('column.buttons') buttons

  @computed('buttons')
  get visibleButtons() {
    const buttons = get(this, 'buttons');

    return buttons
      ? buttons.filter((button) => !button.resource
          || this.can.can(`${button.action} ${button.resource}`, get(this, 'row.content')))
      : [];
  }

  @action
  cellAction(buttonAction) {
    const cellAction = buttonAction && this.tableActions[buttonAction];

    if ('function' === typeof cellAction) {
      cellAction(this.row);
    }
  }
}
