export default function staffLevels() {
  return [
    {
      label: i18n.t('staff.level.commerce'),
      value: 'commerce',
    },
    {
      label: i18n.t('staff.level.manager'),
      value: 'manager',
    },
    {
      label: i18n.t('staff.level.owner'),
      value: 'owner',
    },
    {
      label: i18n.t('staff.level.admin'),
      value: 'admin',
    },
    {
      label: i18n.t('staff.level.crmmanager'),
      value: 'crmmanager',
    },
    {
      label: i18n.t('staff.level.crmoperator'),
      value: 'crmoperator',
    },
    {
      label: i18n.t('staff.level.storeManager'),
      value: 'storeManager',
    },
    {
      label: i18n.t('staff.level.weboperator'),
      value: 'weboperator',
    },
    {
      label: i18n.t('staff.level.scmanager'),
      value: 'scmanager',
    },
    {
      label: i18n.t('staff-level-sales'),
      value: 'sales',
    },
    {
      label: i18n.t('staff-level-services'),
      value: 'services',
    },
  ];
}
