import { assign } from '@ember/polyfills';
import Mixin from '@ember/object/mixin';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';

import IllegalStateError from 'neuro-frontend/errors/illegal-state-error';

// decorators doesn't work with ES5 object models :(
export default Mixin.create({
  store: service(),

  defaultFilter: {},

  filter: {},

  fixedFilters: {},

  isLoading: true,

  model: {},

  modelName: '',

  page: {
    number: 1,
    size: 15,
  },

  sort: '',

  init() {
    this._super();
    set(this, 'editRoute', '');
  },

  query: computed('fixedFilters', 'filter', 'page', 'sort', function () {
    const {
      filter,
      page,
      sort,
    } = this;
    const filters = {
      filter: assign({}, get(this, 'fixedFilters'), filter),
      page,
    };

    if (sort) {
      filters.sort = sort;
    }

    return filters;
  }),

  actions: {
    changeFilters(params) {
      this.resetFilters();

      set(this, 'filter', params);

      this.loadCollection();
    },

    changePage(params) {
      set(this, 'page', get(params, 'page'));

      this.loadCollection();
    },

    changeSort(params) {
      set(this, 'sort', get(params, 'sort'));

      this.loadCollection();
    },

    edit(model) {
      if (!model || !get(model, 'id')) {
        throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
      }

      this.transitionToRoute(get(this, 'editRoute'), get(model, 'id'));
    },

    delete(model) {
      if (!model || !get(model, 'id')) {
        throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
      }

      return model.destroyRecord();
    },
  },

  hasFilters() {
    return !!Object.keys(get(this, 'filter')).length;
  },

  loadCollection() {
    const modelName = get(this, 'modelName');

    if (!modelName) {
      throw new IllegalStateError('loadCollection: "modelName" is false. When using a ListMixin, "modelName" should be set to an existant model');
    }

    set(this, 'isLoading', true);

    return get(this, 'store')
      .query(modelName, get(this, 'query'))
      .then((collection) => {
        set(this, `model.${pluralize(modelName)}`, collection);
        set(this, 'isLoading', false);
        return collection;
      });
  },

  resetFilters() {
    set(this, 'filter', get(this, 'defaultFilter'));
    set(this, 'page.number', 1);
    set(this, 'page.size', 15);
    set(this, 'sort', '');
  },
});
