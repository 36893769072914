import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';

import Copyable from 'ember-data-copyable';
import Validator from 'ember-model-validator/mixins/model-validator';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

const setGender = (sex, gender, state) => {
  if (state) {
    if (!sex.includes(gender)) {
      sex.push(gender);
      return sex;
    }
  } else {
    const updatedSex = sex.filter((s) => !s.includes(gender));
    return updatedSex;
  }
  return [];
};

const MixinModel = Model.extend(Validator, Copyable, {});
export default class extends MixinModel.extend({
  languages: fragmentArray('proposalLanguage'),
}) {
  @attr('datetime') endDate

  @attr('datetime') eventEndDate

  @attr('datetime') eventStartDate

  @attr('boolean') featured

  @attr('boolean') hideHome

  @attr('boolean') isEvent

  @attr('number') maximumAge

  @attr('number') minimumAge

  @attr('boolean') publicationRequested

  @attr('boolean') published

  @attr('string', { defaultValue: 'center' }) proposalType

  @attr('array', { defaultValue() { return []; } }) sex

  @attr('datetime') startDate

  @belongsTo('campaign') campaign

  @belongsTo('category') category

  @belongsTo('center') center

  @belongsTo('commerce') commerce

  @belongsTo('coupon') coupon

  @hasMany('extra') extras

  @hasMany('proposal-extra') proposalExtras

  @hasMany('segment') segments

  @belongsTo('staffMember') staffMember

  @alias('defaultLanguage.appImage') appImage

  @alias('defaultLanguage.description') description

  @alias('defaultLanguage.name') name

  @alias('defaultLanguage.webImage') webImage

  @computed('segments')
  get segment() {
    return this.segments.firstObject;
  }

  set segment(segment) {
    const {
      segments,
    } = this;
    segments.clear();
    segments.pushObject(segment);
  }

  @computed('languages')
  get defaultLanguage() {
    return this.languages.findBy('code', get(this.center, 'defaultLanguage.code'));
  }

  @computed('appImage', 'webImage')
  get image() {
    return this.appImage || this.webImage;
  }

  @computed('coupon.exchanges', 'coupon.usageLimit')
  get couponData() {
    const {
      coupon,
    } = this;

    if (!get(coupon, 'usageLimit')) {
      return '-/-';
    }

    return `${get(coupon, 'exchanges') || 0} / ${get(coupon, 'usageLimit')}`;
  }

  @computed('sex')
  get isMan() {
    const {
      sex,
    } = this;
    return sex.includes('H');
  }

  set isMan(isMan) {
    const {
      sex,
    } = this;

    set(this, 'sex', setGender(sex, 'H', isMan));
  }

  @computed('sex')
  get isWoman() {
    const {
      sex,
    } = this;
    return sex.includes('M');
  }

  set isWoman(isWoman) {
    const {
      sex,
    } = this;

    set(this, 'sex', setGender(sex, 'M', isWoman));
  }

  @computed('published', 'startDate', 'endDate', 'publicationRequested')
  get state() {
    const {
      published,
      startDate,
      endDate,
      publicationRequested,
    } = this;
    const today = new Date();

    switch (true) {
      case !published && publicationRequested:
        return 'pending';
      case !published && !publicationRequested:
        return 'draft';
      case startDate > today:
        return 'scheduled';
      case startDate <= today && today <= endDate:
        return 'active';
      case today > endDate:
        return 'expired';
      default:
        return '';
    }
  }

  @computed('startDate', 'eventStartDate', 'isMan', 'isWoman', 'appImage', 'channels', 'webImage', 'proposalType')
  get validations() {
    return {
      category: {
        presence: {
          message: i18n.t('error.proposal.category.required'),
        },
      },
      description: {
        presence: {
          message: i18n.t('error.proposal.description.required'),
        },
      },
      channels: {
        presence:
          {
            message: i18n.t('error.proposal.channel.required'),
          },
      },
      appImage: {
        presence: {
          if: (key, value, _this) => !get(_this, 'appImage'),
          message: i18n.t('required', { attr: i18n.t('app-image') }),
        },
      },
      webImage: {
        presence: {
          if: (key, value, _this) => !get(_this, 'webImage'),
          message: i18n.t('required', { attr: i18n.t('web-image') }),
        },
      },
      commerce: {
        presence: {
          if: (key, value, _this) => 'commerce' === get(_this, 'proposalType'),
          message: i18n.t('required', { attr: i18n.t('commerce') }),
        },
      },
      endDate: {
        presence: {
          message: i18n.t('error.proposal.end-date.required'),
        },
        date: {
          if: (key, value, _this) => _this.endDate && _this.startDate,
          after: this.startDate,
          message: i18n.t('error.proposal.end-date.after'),
        },
      },
      eventStartDate: {
        presence: {
          if: (key, value, _this) => _this.isEvent,
          message: i18n.t('error.proposal.eventStartDate.required'),
        },
      },
      eventEndDate: {
        presence: {
          if: (key, value, _this) => _this.isEvent,
          message: i18n.t('error.proposal.event-end-date.required'),
        },

        date: {
          if: (key, value, _this) => _this.eventEndDate && _this.eventStartDate,
          after: this.eventStartDate,
          message: i18n.t('error.proposal.event-end-date.after'),
        },
      },
      gender: {
        presence: {
          if: (key, value, _this) => !_this.isMan && !_this.isWoman,
          message: i18n.t('error.proposal.gender.required'),
        },
      },
      maximumAge: {
        presence: {
          message: i18n.t('error.proposal.maximumAge.required'),
        },
      },
      minimumAge: {
        presence: {
          message: i18n.t('error.proposal.minimumAge.required'),
        },
      },
      name: {
        presence: {
          message: i18n.t('error.proposal.name.required'),
        },
      },
      proposalType: {
        presence: {
          message: i18n.t('error.proposal.proposalType.required'),
        },
      },
      startDate: {
        presence: {
          message: i18n.t('error.proposal.startDate.required'),
        },
      },
    };
  }
}
