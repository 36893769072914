import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

export default class ProposalExtraModel extends Model {
  @attr('string') value

  @belongsTo('proposal') proposal

  @belongsTo('extra') extra

  @belongsTo('language') language

  extraError = ''
}
