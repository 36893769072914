import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import Validator from 'ember-model-validator/mixins/model-validator';

export default Model.extend(Validator, {
  center_id: attr('number'),
  name: attr('string'),
  type: attr('string', { defaultValue: 'issue' }),

  validations: {
    name: {
      presence: true,
    },
  },
});
