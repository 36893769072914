import { computed, get } from '@ember/object';

import { inject as service } from '@ember/service';

import ModuleIntranet from './module-intranet';

export default class extends ModuleIntranet {
  @service auth

  roles = [
    'admin',
    'manager',
    'scmanager',
  ]

  @computed('auth.user.level')
  get canRead() {
    const {
      roles,
      auth: {
        user: {
          level,
        },
      },
    } = this;

    return roles.includes(level) && get(this, 'canAccess');
  }

  @computed('auth.user.role')
  get canCreate() {
    const {
      auth: {
        user: {
          role,
        },
      },
    } = this;

    return get(this, 'canRead') || 'storeManager' === role;
  }

  @computed('auth.user.role', 'auth.center.active_sales_year_statement', 'model.state')
  get canEdit() {
    const {
      auth: {
        center: {
          active_sales_year_statement: isActive,
        },
        user: {
          role,
        },
      },
      model: {
        state,
      },
      roles,
    } = this;

    return isActive
      && (
        (roles.includes(role) && 'pending' === state)
        || (('storeManager' === role) && ['unrequested', 'rejected'].includes(state))
      );
  }

  @computed('auth.user.level')
  get canDelete() {
    return get(this, 'canCreate');
  }

  @computed('totalSales.[]')
  get canValidate() {
    const {
      totalSales,
    } = this;

    const total = totalSales.lastObject;

    return totalSales.slice(0, -1).every((month) => {
      const {
        state,
      } = month;
      return 'invalid' !== state;
    }) || 'invalid' !== total.state;
  }

  @computed('totalSales.[]')
  get canReject() {
    const {
      totalSales,
    } = this;

    return totalSales.some((month) => {
      const {
        state,
      } = month;
      return 'invalid' === state;
    });
  }

  @computed('model.state', 'model.label')
  get canUpdateDMI() {
    const {
      model: {
        state,
      },
      model: {
        label,
      },
    } = this;
    return 'invalid' === state && 'total' !== label.toLowerCase();
  }
}
