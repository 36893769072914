import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';

import fetch from 'fetch';

export default class extends Service {
  @service api

  @service auth

  async getData(url, query) {
    return fetch(`${get(this, 'api.host')}/api/v4/neurostats/v1/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${get(this, 'auth.token')}`,
      },
      body: JSON.stringify(query),
      method: 'POST',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => data);
  }
}
