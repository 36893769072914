import { get, action, computed } from '@ember/object';

import TableList from './table-list';

export default class extends TableList {
  classNames = ['table-segments']

  @computed('onEdit', 'onSendEmail')
  get tableActions() {
    const onEdit = get(this, 'onEdit');
    const onSendEmail = get(this, 'onSendEmail');

    return {
      delete: (...args) => {
        this.send('showDeleteModal', ...args);
      },
      edit: onEdit,
      sendEmail: onSendEmail,
    };
  }

  @computed()
  get columns() {
    return [
      {
        label: get(this, 'i18n').t('crm.segments'),
        valuePath: 'segmentName',
        sortable: false,
        cellClassNames: ['table-list__cell--ellipsis'],
      },
      {
        label: '',
        valuePath: 'registeredUsers',
        width: '15%',
        sortable: false,
      },
      {
        label: '',
        cellAction: 'sendEmail',
        cellType: 'material-icon',
        materialIcon: 'drafts',
        tooltip: get(this, 'i18n').t('send_email'),
        sortable: false,
        align: 'center',
        width: '10%',
        hidden: true,
      },
      {
        label: '',
        cellAction: 'edit',
        cellClassNames: ['qa-edit-segment-icon'],
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: get(this, 'i18n').t('edit'),
        sortable: false,
        align: 'center',
        width: '10%',
      },
      {
        label: '',
        cellAction: 'delete',
        cellClassNames: ['qa-delete-segment-icon'],
        cellType: 'material-icon',
        materialIcon: 'delete',
        resource: 'segment',
        tooltip: get(this, 'i18n').t('delete'),
        sortable: false,
        align: 'center',
        width: '10%',
      },
    ];
  }

  @action
  handleRowClick(row) {
    if ('function' === typeof get(this, 'onSelectRow')) {
      get(this, 'onSelectRow')(row);
    }
  }
}
