import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import config from '../config/environment';

const {
  APP: {
    version,
  },
} = config;
const releases = [
  {
    name: 'abra',
    version: '3.19',
  },
  {
    name: 'bulbasaur',
    version: '3.20',
  },
  {
    name: 'charmander',
    version: '4.0',
  },
];

export default Component.extend({
  currentPattern: '',

  pattern: '4815162342Enter',

  release: computed('version', () => {
    const currentVersion = version.match(/^(\d+\.)(\d+)/)[0];
    let currentRelease;

    releases.some((release) => {
      if (currentVersion === release.version) {
        currentRelease = release;
        currentRelease.url = `assets/images/about/version/${release.name}.png`;

        return true;
      }

      return false;
    });

    return currentRelease;
  }),

  version,

  didInsertElement() {
    document.addEventListener('keypress', (ev) => {
      set(this, 'currentPattern', get(this, 'currentPattern') + ev.key);

      if (!get(this, 'pattern').includes(get(this, 'currentPattern'))) {
        set(this, 'currentPattern', '');
      }

      if (get(this, 'pattern') === get(this, 'currentPattern')) {
        set(this, 'currentPattern', '');
        set(this, 'isOpen', true);
      }
    });
  },

  actions: {
    closeModal() {
      set(this, 'isOpen', false);
    },
  },
});
