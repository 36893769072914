import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { hash } from 'rsvp';

import enumKpis from 'neuro-frontend/enums/campaign-kpis';
import formatDate from 'neuro-frontend/utils/dates/format-date';

export default class extends Service {
  @service api

  @service auth

  @service stats

  @service store

  createRows(referenceData, currentData) {
    return enumKpis.map((kpi) => ({
      icon: kpi.icon,
      kpi: kpi.value,
      name: kpi.label,
      referenceValue: referenceData[kpi.value],
      currentValue: currentData[kpi.value],
      tooltip: kpi.tooltip,
    }));
  }

  async fetchStats({
    id, startDate, endDate, attendant, eca,
  }, groupBy) {
    const centerId = get(this, 'auth.centerId');
    const stats = get(this, 'stats');

    if (!startDate || !endDate) {
      return {};
    }

    const query = {
      start_date: formatDate(startDate, 'DD/MM/YYYY'),
      end_date: formatDate(endDate, 'DD/MM/YYYY'),
      center_id: [Number(centerId)],
      group_by: groupBy || [],
    };

    const statsAdditionalQuery = groupBy
      ? `?group_by=${groupBy}`
      : '';

    const {
      activeUsers,
      campaignCost,
      data,
      impressions,
      visits,
    } = await hash({
      activeUsers: stats.getData(
        'statsactiva/user_activity',
        {
          ...query,
          building_type: ['main'],
        },
      ),

      campaignCost: id
        ? this.store.query('campaignAction', {
          filter: {
            campaign: id,
          },
        }).then((collection) => collection.toArray().reduce((acc, val) => acc + val.finalCost, 0))
        : null, // to avoid zero and false data

      data: id
        ? fetch(
          `${get(this, 'api.host')}/api/v4/stats/campaigns/${id}${statsAdditionalQuery}`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${get(this, 'auth.token')}`,
              'Content-Type': 'application/json',
            },
          },
        ).then((response) => response.json())
        : [],

      impressions: stats.getData(
        'statsactiva/impressions',
        query,
      ),

      visits: stats.getData(
        'statsactiva/visits',
        {
          ...query,
          building_type: ['main'],
        },
      ),
    });

    if (groupBy) {
      return {
        data,
        activeUsers,
        impressions,
        visits,
        campaignCost,
        attendant,
        eca,
      };
    }

    return {
      ...data?.firstObject,
      ...activeUsers?.firstObject,
      ...impressions?.firstObject,
      ...visits?.firstObject,
      campaignCost,
      attendant,
      eca,
    };
  }

  formatResponse(referenceData, currentData) {
    return this.createRows(
      this.formatData(this.calculateData(referenceData)),
      this.formatData(this.calculateData(currentData)),
    );
  }

  calculateData(data) {
    const couponPercent = (data?.coupon_exchanges * 100) / Number(data?.total_coupons);
    const contestants = data?.new_registered_users + data?.previous_registered_users;
    const percentNewRegisteredUsers = (data?.new_registered_users * 100)
      / contestants;
    const totalReceiptsValue = Number(data?.average_price_receipt) * data?.total_receipts;
    const costPerContestant = data?.campaignCost / contestants;

    return {
      'active-users': data?.active_users,
      attendant: data?.attendant,
      contestants,
      'cost-per-contestant': costPerContestant.toFixed(2),
      'coupon-percent': couponPercent.toFixed(2),
      eca: data?.eca,
      'impressions-number': data?.impressions,
      'new-registered-users': data?.new_registered_users,
      'new-registered-users-percent': percentNewRegisteredUsers.toFixed(2),
      'previous-registered-users': data?.previous_registered_users,
      'receipt-average-price': Number.isFinite(data?.average_price_receipt) ? data?.average_price_receipt?.toFixed(2) : data?.average_price_receipt,
      'coupon-exchanges': data?.coupon_exchanges,
      'total-receipts': data?.total_receipts,
      'total-receipts-value': totalReceiptsValue.toFixed(2),
      visits: data?.visits,
    };
  }

  formatData(data) {
    return {
      'active-users': Number.isFinite(data?.active_users) ? data?.active_users : '--',
      attendant: Number.isFinite(data?.attendant) ? data?.attendant : '--',
      contestants: Number.isFinite(data.contestants) ? data.contestants : '--',
      'cost-per-contestant': Number.isFinite(data['cost-per-contestant']) ? `${data['cost-per-contestant']} €` : '--',
      'coupon-percent': Number.isFinite(data['coupon-percent']) ? `${data['coupon-percent']} %` : '--',
      eca: Number.isFinite(data?.eca) ? data?.eca : '--',
      'impressions-number': Number.isFinite(data?.impressions) ? data?.impressions : '--',
      'new-registered-users': Number.isFinite(data?.new_registered_users) ? data?.new_registered_users : '--',
      'new-registered-users-percent': Number.isFinite(data['new-registered-users-percent']) ? `${data['new-registered-users-percent']} %` : '--',
      'previous-registered-users': Number.isFinite(data?.previous_registered_users) ? data?.previous_registered_users : '--',
      'receipt-average-price': Number.isFinite(Number(data?.average_price_receipt)) ? `${Number(data?.average_price_receipt)} €` : '--',
      'coupon-exchanges': Number.isFinite(data?.coupon_exchanges) ? data?.coupon_exchanges : '--',
      'total-receipts': Number.isFinite(data?.total_receipts) ? data?.total_receipts : '--',
      'total-receipts-value': Number.isFinite(data['total-receipts-value']) ? `${data['total-receipts-value']} €` : '--',
      visits: Number.isFinite(data?.visits) ? data?.visits : '--',
    };
  }
}
