import Component from '@ember/component';
import { action, get, set } from '@ember/object';

export default class extends Component {
  classNames = ['search-container']

  isExporting = false

  @action
  applyFilters(q) {
    get(this, 'onApplyFilters')(
      {
        q,
      },
      'filter',
    );
  }

  @action
  exportCsv() {
    set(this, 'isExporting', true);
    get(this, 'onExportClicked')()
      .then(() => {
        set(this, 'state', 'success');
      })
      .catch(() => {
        set(this, 'state', 'error');
      })
      .finally(() => {
        set(this, 'isExporting', false);
      });
  }
}
