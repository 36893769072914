import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';

@classNames('nf-add')
export default class extends Component.extend({
    icon: 'add',
  }) {
  @computed('route', 'id', 'queryParams')
  get params() {
    const {
      route,
      id,
      queryParams,
    } = this;
    const params = [];

    params.push(route);

    if (id) {
      params.push(id);
    }

    if (queryParams) {
      params.push({
        isQueryParams: true,
        values: queryParams,
      });
    }

    return params;
  }
}
