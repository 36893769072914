import Component from '@ember/component';
import {
  action, computed, get, set, setProperties,
} from '@ember/object';

import { inject as service } from '@ember/service';
import formatDate from 'neuro-frontend/utils/dates/format-date';
import receiptStatus from 'neuro-frontend/enums/receipt-status';
import fetch from 'fetch';

import ListMixin from 'neuro-frontend/mixins/nf-list';
import SegmentFiltersMixin from 'neuro-frontend/mixins/segment-filters';

export default class extends Component.extend(ListMixin, SegmentFiltersMixin, {
  campaigns: [],

  model: {
    receipts: [],
  },

  modelName: 'receipt',

  page: {
    number: 1,
    size: 10,
  },

  resume: {
    data: [],
    isLoading: false,
  },

  sort: '-updated-at',

  stampsPreview: {
    data: [],
    isEnabledSend: false,
    isLoading: false,
    hasStamps: false,
  },

  ticketsPreview: {
    data: [],
    isEnabledSend: false,
    isLoading: false,
    hasTickets: false,
  },
}) {
  @service api

  @service auth

  @service store

  @computed
  get columns() {
    return [
      {
        label: i18n.t('users.code'),
        valuePath: 'code',
        sortable: false,
        width: '10%',
      },
      {
        label: i18n.t('users.commerce'),
        valuePath: 'commerce.name',
      },
      {
        cellType: 'amount',
        label: i18n.t('users.amount'),
        valuePath: 'price',
      },
      {
        label: i18n.t('users.date'),
        valuePath: 'date',
      },
      {
        cellType: 'collection',
        label: i18n.t('users.campaigns'),
        field: 'name',
        sortable: false,
        valuePath: 'campaigns',
        width: '24%',
      },
      {
        label: i18n.t('status'),
        cellType: 'tag',
        enum: receiptStatus,
        resource: 'receipt',
        sortable: false,
        valuePath: 'state',
      },

      {
        align: 'center',
        cellAction: 'edit',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'edit',
        resource: 'receipt',
        sortable: false,
        tooltip: i18n.t('edit'),
        width: '7%',
      },
      {
        align: 'center',
        cellAction: 'delete',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'delete',
        resource: 'receipt',
        sortable: false,
        tooltip: i18n.t('delete'),
        width: '7%',
      },
    ];
  }

  @computed('receipt.date', 'campaigns', 'user.campaigns')
  get activeCampaigns() {
    const {
      campaigns,
      receipt,
    } = this;

    const date = receipt && receipt.date;

    if (!date || !this.user) {
      return [];
    }

    const userCampaigns = this.user.campaigns;

    const manualCampaigns = campaigns.filter((campaign) => !campaign.belongsTo('segment').id());
    const segmentedCampaigns = userCampaigns.filter((campaign) => campaign.belongsTo('segment').id());
    const totalCampaigns = [...manualCampaigns, ...segmentedCampaigns];

    return totalCampaigns.filter((campaign) => (formatDate(campaign.startDate, 'DD/MM/YYYY') <= formatDate(date, 'DD/MM/YYYY'))
      && formatDate(campaign.endDate, 'DD/MM/YYYY') >= formatDate(date, 'DD/MM/YYYY'));
  }

  @computed('user.id')
  get fixedFilters() {
    return {
      user: this.user ? this.user.id : null,
      state: [{
        op: 'is_not',
        value: 'draft',
      }],
    };
  }

  @computed
  get stampsPreviewColumns() {
    return [
      {
        label: i18n.t('receipt.campaign'),
        valuePath: 'campaign',
        sortable: false,
      },
      {
        label: i18n.t('receipt.campaign.accumulative-amount'),
        sortable: false,
        valuePath: 'withAccumulativeAmount',
      },
      {
        label: i18n.t('receipt.stamps-number'),
        valuePath: 'tickets',
        sortable: false,
      },
      {
        cellType: 'amount',
        label: i18n.t('receipt.campaign.accumulated-balance'),
        sortable: false,
        valuePath: 'accumulated_balance',
      },
      {
        cellType: 'amount',
        label: i18n.t('receipt.pending-balance'),
        valuePath: 'pending_balance',
        sortable: false,
      },
    ];
  }

  @computed
  get ticketsPreviewColumns() {
    return [
      {
        label: i18n.t('ticket.campaign'),
        valuePath: 'campaign',
        sortable: false,
      },
      {
        label: i18n.t('ticket.rule'),
        valuePath: 'rule_label',
        sortable: false,
      },
      {
        label: i18n.t('ticket.tickets-number'),
        valuePath: 'tickets',
        sortable: false,
      },
      {
        cellType: 'amount',
        label: i18n.t('ticket.pending-balance'),
        valuePath: 'pending_balance',
        sortable: false,
      },
    ];
  }

  @computed('receipt.commerce', 'receipt.campaigns')
  get receiptCommerce() {
    let commerce = get(this, 'receipt.commerce.content');

    if (commerce) {
      if (!this.belongsToAllCampaigns(commerce)) {
        commerce = null;
      }
    }

    return commerce;
  }

  set receiptCommerce(selectedCommerce) {
    set(this, 'receipt.commerce', selectedCommerce);
  }

  init(...args) {
    super.init(...args);
    this.fetchData();
    this.resetReceipt();
  }

  @action
  cancelReceipt() {
    this.receipt.rollbackAttributes();
    this.resetReceipt();
  }

  @action
  async changeCampaign(campaign) {
    set(this, 'isLoading', true);

    set(
      this,
      'filter',
      {
        campaign: campaign ? campaign.id : null,
      },
    );

    await this.fetchData();

    set(this, 'isLoading', false);
  }

  @action
  changeDate() {
    const raffles = this.activeCampaigns.filter((campaign) => campaign.isRaffle);
    set(this.receipt, 'campaigns', raffles);
  }

  @action
  edit(receipt) {
    if (!receipt || !get(receipt, 'id')) {
      throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
    }
    set(this, 'receipt', receipt);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  @action
  async generateTickets() {
    set(this, 'ticketsPreview.isLoading', true);
    try {
      await this.createTickets(false);
    } catch (e) {
      this.showMessage('error', i18n.t('ticket.generate-error-message'));
    }
    this.resetReceipt();
    this.fetchData();

    set(this, 'ticketsPreview.isLoading', false);
    set(this, 'isOpenGenerateTicketsModal', false);
    this.showMessage('success', i18n.t('ticket.generate-success-message'));
  }

  @action
  async searchCommerces(term) {
    if (term && 2 <= term.length) {
      const commerces = this.commerces
        .filter((commerce) => commerce.name.toLowerCase().includes(term.toLowerCase()));

      return (this.receipt.campaigns.length)
        ? commerces.filter((commerce) => this.belongsToAllCampaigns(commerce))
        : commerces;
    }
    return [];
  }

  @action
  async showTicketsPreview() {
    setProperties(
      this,
      {
        'ticketsPreview.hasTickets': false,
        'ticketsPreview.isLoading': true,
        'stampsPreview.hasStamps': false,
        'stampsPreview.isLoading': true,
        generateTicketState: null,
        isOpenGenerateTicketsModal: true,
      },
    );
    let ticketsPreview = [];
    ticketsPreview = await this.createTickets(true);
    if (!ticketsPreview) {
      this.ticketsPreview.data.clear();
    } else {
      this.prepareTicketsList(ticketsPreview);
    }
    set(this, 'ticketsPreview.isLoading', false);
    set(this, 'stampsPreview.isLoading', false);
  }

  @action
  async submitReceipt(receipt) {
    return new Promise((resolve, reject) => {
      if (receipt.validate()) {
        resolve(receipt.save().then(() => {
          this.user.campaigns.pushObjects(receipt.campaigns);
          this.resetReceipt();
          this.loadCollection();
          set(this, 'successMessage', i18n.t('receipt.save-message'));
          set(this, 'state', 'success');
        }));
      } else {
        set(this, 'state', '');
        reject(get(receipt, 'errors'));
      }
    });
  }

  checkQuantity(previewData) {
    if (!previewData.length) {
      return false;
    }
    const numTickets = previewData.reduce((acc, campaign) => acc + campaign.tickets, 0);
    return (0 !== numTickets);
  }

  createTickets(preview) {
    const query = {
      data: {
        id: get(this, 'user.id'),
        type: 'user_create_tickets',
        attributes: {
          preview,
        },
      },
    };
    return fetch(
      `${this.get('api.host')}/api/v4/user_create_tickets/${get(this, 'user.id')}`,
      {
        body: JSON.stringify(query),
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${get(this, 'auth.token')}`,
        },
        method: 'PATCH',
        mode: 'cors',
      },
    ).then((response) => response.json());
  }

  fetchData() {
    return Promise.all([
      this.loadCollection(),
    ]);
  }

  async prepareTicketsList(receipts) {
    const {
      store,
    } = this;
    this.stampsPreview.data.clear();
    this.ticketsPreview.data.clear();

    const campaigns = await Promise.all([
      ...new Set(receipts.map((value) => value.campaign_id)),
    ].map((campaign) => store.findRecord('campaign', campaign)));

    receipts.forEach((receipt) => {
      const campaign = campaigns.findBy('id', String(receipt.campaign_id));
      const campaignData = {
        campaign: campaign.name,
        withAccumulativeAmount: campaign.withoutRemains ? i18n.t('no') : i18n.t('yes'),

      };
      return campaign.isLoyaltyCard
        ? this.stampsPreview.data.pushObject({
          ...receipt,
          ...campaignData,
        })
        : this.ticketsPreview.data.pushObject(
          {
            ...receipt,
            ...campaignData,
          },
        );
    });
    setProperties(
      this,
      {
        'ticketsPreview.isEnabledSend': this.checkQuantity(this.ticketsPreview.data),
        'ticketsPreview.hasTickets': this.checkQuantity(this.ticketsPreview.data),
        'stampsPreview.isEnabledSend': this.checkQuantity(this.stampsPreview.data),
        'stampsPreview.hasStamps': this.checkQuantity(this.stampsPreview.data),
      },
    );
  }

  resetReceipt() {
    const store = get(this, 'store');
    const date = formatDate(new Date(), '', 'DD/MM/YYYY');
    const receipt = store.createRecord(
      'receipt',
      {
        date,
        user: this.user,
      },
    );
    set(this, 'receipt', receipt);

    // needs to wait until the computed property has been updated
    set(this, 'receipt.campaigns', this.activeCampaigns.filter((campaign) => 'none' !== campaign.loyalty));
  }

  showMessage(state, message) {
    if ('success' === state) {
      set(this, 'generateTicketSuccessMessage', message);
    } else if ('error' === state) {
      set(this, 'generateTicketErrorMessage', message);
    }
    set(this, 'generateTicketState', state);
  }

  belongsToAllCampaigns(commerce) {
    const {
      receipt: {
        campaigns,
      },
    } = this;

    return campaigns
      .every((campaign) => {
        const commercesIds = campaign.commerces.map((c) => c.id).flat();
        return commercesIds
          .some((id) => id === commerce.id);
      });
  }
}
