import { computed, get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';

import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import fetch from 'fetch';
import Base from 'ember-simple-auth/authenticators/base';
import RSVP from 'rsvp';

export default class extends Base {
  @service ajax

  @service auth

  @service api

  refreshTokenTimeout = null

  tokenRefreshOffset = () => {
    const min = 5;
    const max = 10;

    return (Math.floor(Math.random() * (max - min)) + min) * 1000;
  }

  @computed('api.host')
  get tokenEndpoint() {
    const host = get(this, 'api.host');

    return `${host}/api/v4/staff_members/session`;
  }

  authenticate(email, pass) {
    const requestData = {
      data: {
        type: 'staff_members',
        attributes: {
          username: email,
          password: pass,
        },
      },
    };

    return new RSVP.Promise((resolve, reject) => {
      fetch(get(this, 'tokenEndpoint'), {
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(requestData),
        method: 'POST',
      })
        .then((response) => {
          if (!response.ok) {
            response.json().then((error) => reject(error));
          } else {
            response.json().then(async (json) => {
              // TODO legacy code deprecate when panel is migrated
              set(this, 'auth.centerId', get(json.data, 'relationships.centers.data.firstObject.id'));
              document.cookie = `tkn=${json.data.attributes.session}; path=/; max-age=86400;`;

              const expiresIn = json.data.attributes['expires-in'];
              const expiresAt = this.absolutizeExpirationTime(expiresIn);
              const data = {
                expiresIn,
                expiresAt,
                token: json.data.attributes.session,
                host: get(this, 'api.host'),
              };

              this.scheduleAccessTokenRefresh(data);

              resolve(data);
            });
          }
        });
    });
  }

  invalidate(data) {
    run.cancel(this.refreshTokenTimeout);
    delete this.refreshTokenTimeout;
    return RSVP.resolve(data);
  }

  restore(data) {
    return new RSVP.Promise((resolve, reject) => {
      const now = (new Date()).getTime();
      if (!isEmpty(data.expiresAt) && data.expiresAt < now) {
        this.refreshAccessToken(data.expiresAt, data.token, data.host).then(resolve, reject);
      } else if (!this.validate(data)) {
        reject();
      } else {
        this.scheduleAccessTokenRefresh(data);
        resolve(data);
      }
    });
  }

  absolutizeExpirationTime(expiresIn) {
    if (!isEmpty(expiresIn)) {
      return new Date((new Date().getTime()) + expiresIn * 1000).getTime();
    }
    return null;
  }

  refreshAccessToken(expiresIn, token, host) {
    return new RSVP.Promise((resolve, reject) => {
      fetch(`${host}/api/v4/staff_members/refresh`, {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data: { type: 'staff_members' } }),
        method: 'POST',
        mode: 'cors',
      })
        .then((response) => {
          if (!response.ok) {
            response.json().then((error) => reject(error)).catch(() => reject());
          } else {
            response.json().then(async (json) => {
              // TODO legacy code deprecate when panel is migrated
              if (!this.auth.centerId) {
                set(this, 'auth.centerId', get(json.data, 'relationships.centers.data.firstObject.id'));
              }
              document.cookie = `tkn=${json.data.attributes.session}; path=/; max-age=86400;`;

              const newExpiresIn = json.data.attributes['expires-in'] || expiresIn;
              const newExpiresAt = this.absolutizeExpirationTime(newExpiresIn);
              const newToken = json.data.attributes.session || token;
              const data = {
                expiresIn: newExpiresIn,
                expiresAt: newExpiresAt,
                token: newToken,
                host,
              };

              this.scheduleAccessTokenRefresh({ ...data, expiresAt: null });

              this.trigger('sessionDataUpdated', data);

              resolve(data);
            });
          }
        });
    });
  }

  scheduleAccessTokenRefresh(data) {
    const { expiresIn, token, host } = data;
    let { expiresAt } = data;
    const now = (new Date()).getTime();
    if (isEmpty(expiresAt) && !isEmpty(expiresIn)) {
      expiresAt = new Date(now + expiresIn * 1000).getTime();
    }
    const offset = this.get('tokenRefreshOffset')();

    if (!isEmpty(token) && !isEmpty(expiresAt) && expiresAt > now - offset) {
      run.cancel(this.refreshTokenTimeout);
      delete this.refreshTokenTimeout;
      this.refreshTokenTimeout = run.later(this,
        this.refreshAccessToken, expiresIn, token, host, expiresAt - now - offset);
    }
  }

  validate(data) {
    return !isEmpty(data.token);
  }
}
