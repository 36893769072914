import Controller from '@ember/controller';
import { computed, action, get } from '@ember/object';
import staffLevels from 'neuro-frontend/enums/staff-levels';
import ListMixin from 'neuro-frontend/mixins/nf-list';
import { inject as service } from '@ember/service';

export default class extends Controller.extend(ListMixin, {}) {
  @service api;

  @service auth;

  editRoute = 'configuration.staff-members.edit'

  modelName = 'staffMember'

  sort = '-updated-at'

  @computed
  get columns() {
    const buttons = [
      {
        action: 'edit',
        icon: 'edit',
        resource: 'staffMember',
        tooltip: i18n.t('edit'),
      },
      {
        action: 'delete',
        icon: 'delete',
        resource: 'staffMember',
        tooltip: i18n.t('delete'),
      },
      {
        action: 'restore',
        icon: 'restore',
        resource: 'staffMember',
        tooltip: i18n.t('restore'),
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: i18n.t('edit'),
        sortable: false,
        resource: 'staffMember',
        align: 'center',
        width: '4%',
      },
      {
        label: '',
        cellAction: 'delete',
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: i18n.t('delete'),
        sortable: false,
        resource: 'staffMember',
        align: 'center',
        width: '4%',
      },
      {
        label: '',
        cellAction: 'restore',
        cellType: 'material-icon',
        materialIcon: 'restore',
        tooltip: i18n.t('restore'),
        sortable: false,
        resource: 'staffMember',
        align: 'center',
        width: '4%',
      },
    ];

    return [
      {
        label: i18n.t('name'),
        valuePath: 'name',
      },
      {
        label: i18n.t('email'),
        valuePath: 'email',
      },
      {
        cellType: 'enum',
        enum: staffLevels,
        label: i18n.t('level'),
        valuePath: 'level',
      },
      {
        align: 'right',
        cellClassNames: 'qa-button-group cell-button-group',
        cellType: 'button-group',
        label: '',
        buttons,
        sortable: false,
        width: '5%',
      },
    ];
  }

  @action
  async restore(model) {
    const modelId = get(model, 'id');
    if (!model || !modelId) {
      throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
    }
    await fetch(`${this.api.host}/api/v4/staff_members/restore/${modelId}`, {
      headers: {
        'content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${this.auth.token}`,
      },
      method: 'POST',
      mode: 'cors',
    });

    return this.loadCollection();
  }

  @action
  async delete(model) {
    const modelId = get(model, 'id');
    if (!model || !modelId) {
      throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
    }
    await fetch(`${this.api.host}/api/v4/staff_members/${modelId}`, {
      headers: {
        'content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${this.auth.token}`,
      },
      method: 'DELETE',
      mode: 'cors',
    });
    return this.loadCollection();
  }
}
