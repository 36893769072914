import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { get, defineProperty, computed } from '@ember/object';
import { Column } from 'ember-light-table';
import Cell from 'ember-light-table/components/cells/base';

export default Cell.extend({
  can: service(),

  classNames: ['material-icon'],

  column: new Column(),

  hide: true,

  isDisabled: false,

  action: alias('column.cellAction'),

  materialIcon: alias('column.materialIcon'),

  resource: alias('column.resource'),

  position: computed('column.position', function () {
    const position = get(this, 'column.position');

    return position || 'bottom';
  }),

  tooltip: computed('column.tooltip', 'row.content', function () {
    const tooltip = get(this, 'column.tooltip');
    const model = get(this, 'row.content');

    return tooltip
      ? get(model, tooltip) || tooltip
      : '';
  }),

  init(...args) {
    this._super(...args);

    // not the best code around, think about solutions of how to refactor this
    const hideObservers = ['action', 'resource'];
    const {
      column: {
        extraObservers,
      },
    } = this;

    if (extraObservers) {
      hideObservers.push(...extraObservers.map((str) => `row.content.${str}`));
    }

    defineProperty(
      this,
      'hide',
      computed(...hideObservers, function () {
        const cellAction = get(this, 'action');
        const resource = get(this, 'resource');

        if (!cellAction || !resource) {
          return false;
        }

        return !this.can.can(`${cellAction} ${resource}`, get(this, 'row.content'));
      }),
    );
  },

  actions: {
    cellAction() {
      const cellAction = get(this, 'action');

      if (
        cellAction
        && 'function' === typeof get(this, `tableActions.${cellAction}`)
      ) {
        get(this, `tableActions.${cellAction}`)(get(this, 'row'));
      }
    },
  },
});
