import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';

export default class extends Component.extend({
  files: [],

  maxFilesNumber: 0,

  tempFiles: [],
}) {
  classNames = ['file-gallery']

  @computed('readOnly', 'maxFilesNumber', 'files.length')
  get isHiddenAddButton() {
    const {
      readOnly,
      maxFilesNumber,
      files: {
        length,
      },
    } = this;
    return readOnly || (maxFilesNumber && length >= maxFilesNumber);
  }

  didInsertElement() {
    document.addEventListener('keydown', (ev) => {
      if (get(this, 'selectedFile')) {
        if ('Escape' === ev.key) {
          set(this, 'selectedFile', null);
        }

        if (['ArrowLeft', 'ArrowRight'].includes(ev.key)) {
          this.changeSelectedFile('ArrowLeft' === ev.key ? -1 : 1);
        }
      }
    });
  }

  changeSelectedFile(change) {
    const max = get(this, 'files.length') - 1;
    const index = get(this, 'files').indexOf(get(this, 'selectedFile'));
    let newIndex = index + change;

    if (newIndex > max) {
      newIndex = 0;
    }

    if (0 > newIndex) {
      newIndex = max;
    }

    set(this, 'selectedFile', get(this, 'files').objectAt(newIndex));
  }

  @action
  async attachFiles() {
    get(
      this,
      'files',
    ).pushObjects(get(this, 'tempFiles'));
    set(this, 'isLoading', true);

    if ('function' === typeof this.onAttach) {
      // @TODO expect errors
      await this.onAttach(get(this, 'tempFiles'), get(this, 'category'));
    }

    set(this, 'isLoading', false);
    set(this, 'modalIsOpen', false);
  }

  @action
  closeModal() {
    set(
      this,
      'modalIsOpen',
      false,
    );
  }

  @action
  delete(elem) {
    get(this, 'files').removeObject(elem);

    if ('function' === typeof get(this, 'onRemove')) {
      get(this, 'onRemove')(elem);
    }
  }

  @action
  openModal() {
    get(this, 'tempFiles').clear();

    set(
      this,
      'modalIsOpen',
      true,
    );
  }
}
