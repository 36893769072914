import Component from '@ember/component';

export default Component.extend({
  classNames: ['communications-tabs'],

  actions: {
    changeType(type) {
      this.get('onChange')(type);
    },
  },
});
