/* eslint no-useless-escape: "off" */
import {
  action, computed, get, set,
} from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';

import Route from './base';

const Promise = EmberPromise;
/**
 * @deprecated
 * Use nf-form component instead.
 */
export default Route.extend({
  auth: service(),

  raven: service(),

  rules: {},

  callbackPath: computed(function () {
    // ESLint error with no-useless-escape, fix as soon as we can
    return this.get('routeName')
      ? this.get('routeName').replace(/\.([^\..*]+)$/, '')
      : '';
  }),

  doTransitionTo(returnRoute) {
    const callbackPath = returnRoute && returnRoute.path
      ? returnRoute.path
      : this.get('callbackPath');
    const callbackId = returnRoute ? returnRoute.id : null;

    if (callbackPath) {
      if (callbackId) {
        this.transitionTo(callbackPath, callbackId);
      } else {
        this.transitionTo(callbackPath);
      }
    }
  },

  didSaveModel(record, returnRoute) {
    return new Promise((resolve) => {
      resolve(this.doTransitionTo(returnRoute));
    });
  },

  afterValidate(model, returnRoute) {
    return model
      .save()
      .then((record) => this.didSaveModel(record, returnRoute))
      .catch((response) => {
        get(this, 'raven').callRaven('setExtraContext', { model, response });
        get(this, 'raven').callRaven('captureMessage', 'Error: bad request when creating or updating a resource');
        set(this, 'errorMessage', response.errors[0].detail);
        throw response;
      });
  },

  @action
  saveModel(model, returnRoute) {
    // Exception and Promises: throw errors before creating the Promise
    // source:
    // http://www.2ality.com/2016/03/promise-rejections-vs-exceptions.html
    if (!model || !model.validate || !model.save) {
      throw new Error('saveModel(): parameter \'model\' is not a valid DS.Model');
    }

    if (returnRoute && 'string' !== typeof returnRoute.path) {
      throw new Error('saveModel(): parameter \'returnRoute\' is not valid');
    }

    return new Promise((resolve, reject) => {
      if (model.validate(get(this, 'rules'))) {
        resolve(this.afterValidate(model, returnRoute));
      } else {
        reject(model.get('errors'));
      }
    });
  },

  @action
  cancelModel(model, returnRoute) {
    if (!model || !model.validate || !model.save) {
      throw new Error('cancelModel(): parameter \'model\' is not a valid DS.Model');
    }

    if (returnRoute && 'string' !== typeof returnRoute.path) {
      throw new Error('cancelModel(): parameter \'returnRoute\' is not valid');
    }

    this.doTransitionTo(returnRoute);
    model.rollbackAttributes();
  },
});
