import Model from 'ember-data/model';
import { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import Validator from 'ember-model-validator/mixins/model-validator';

export default class extends Model.extend(Validator, {}) {
  @attr('string') code

  @attr('trimmed-string') description

  @attr('number') floor

  @attr('string') mapPoints

  @attr('trimmed-string') type

  @hasMany('commerce') commerces

  @belongsTo('building') building

  @computed('code', 'description')
  get name() {
    return `${this.code} - ${this.description}`;
  }
}
