import { get, computed } from '@ember/object';
import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';

export default class extends Model {
  @attr('string') notes

  @attr('boolean') sentInfo

  @attr('boolean') sentInfoDossier

  @attr('boolean') sentInfoPropertyMap

  @attr('boolean') sentInfoQualitiesNote

  @belongsTo('residentialRequest') residentialRequest

  @belongsTo('residentialProperty') residentialProperty

  @hasMany('residentialOffers') residentialOffers

  @computed('residentialOffers.firstObject')
  get state() {
    const residentialOffer = get(this, 'residentialOffers.firstObject');

    return residentialOffer ? get(residentialOffer, 'state') : 'pending';
  }
}
