import Application from '@ember/application';
import Ember from 'ember';
import loadInitializers from 'ember-load-initializers';
import EmberObject from '@ember/object';
import defineModifier from 'ember-concurrency-test-waiter/define-modifier';

import Resolver from './resolver';
import config from './config/environment';
import './models/custom-inflector-rules';

defineModifier();

Ember.MODEL_FACTORY_INJECTIONS = true;

// fix: https://github.com/emberjs/ember.js/issues/17042
EmberObject.extend = function (...args) {
  class Class extends this {}
  Class.reopen(...args);

  return Class;
};

const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver,
});

loadInitializers(App, config.modulePrefix);

export default App;
