import { computed, get } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';
import Validator from 'ember-model-validator/mixins/model-validator';
/**
 * @module    nf
 * @submodule nf-models
 */
const isAccess = (key, value, _this) => 'access' === _this.get('type');
const isToCommerces = (key, value, _this) => 'commerce' === _this.get('to_role');
/**
 * IntranetCommunication
 * --
 *
 * @class     IntranetCommunication
 * @namespace NF
 * @extends   Ember.Model
 * @uses      ModelValidator
 * @public
 */
export default Model.extend(Validator, {
  center_id: attr('number'),
  confirmed: attr('boolean'),
  from_role: attr('string'),
  is_urgent: attr('boolean'),
  message: attr('string'),

  state: attr('string', { defaultValue: 'pending' }),
  subject: attr('string'),
  subtype_id: attr('number'),
  type: attr('string', { defaultValue: 'news' }),
  to_role: attr('string'),
  viewed: attr('boolean'),

  total_confirmed: attr('number', { defaultValue: 0 }),
  total_views: attr('number', { defaultValue: 0 }),

  created_at: attr('datetime'),
  updated_at: attr('datetime'),

  access_companions: attr('companions', { defaultValue() { return []; } }),
  access_name: attr('string'),
  access_phone: attr('string'),
  access_date: attr('datetime'),

  files: attr({ defaultValue() { return []; } }),

  children: hasMany('intranet-communication', { inverse: 'parent_id' }),
  creator_id: belongsTo('staff-member'),
  from_commerce_id: belongsTo('commerce'),
  parent_id: belongsTo('intranet-communication', { defaultValue: null, inverse: 'children' }),
  root_id: belongsTo('intranet-communication', { defaultValue: null, inverse: null }),
  to_commerces: hasMany('commerce'),
  /**
   * canBeConfirmed
   * --
   *
   * Every user who has access to the module Intranet can confirm a communication
   * made by other role, except the communications of type 'respond'.
   *
   * A communication that is confirmed means that the user did not only read the
   * communication, he alse agrees with the content of the message. So the user only
   * has one restriction: that the communication hadn't been confirmed previously.
   *
   * @property canBeConfirmed
   * @type Boolean
   * @public
   */
  canBeConfirmed: computed('confirmed', function () {
    return !this.get('confirmed');
  }),

  hasState: computed(
    'type',
    function () {
      return 'access' === this.get('type')
        || 'issue' === this.get('type');
    },
  ),

  validations: computed(function () {
    return {
      message: {
        presence: true,
      },

      subject: {
        presence: true,
      },

      // type access
      access_date: {
        presence: {
          if: isAccess,
        },
      },

      access_name: {
        presence: {
          if: isAccess,
        },
      },

      access_phone: {
        presence: {
          if: isAccess,
        },
      },

      access_companions: {
        custom: {
          validation: (key, value) => value
            .every((c) => 1 !== (Boolean(c.name) + Boolean(c.vat_number))),

          if: isAccess,

          message: get(this, 'i18n').t('error.communication.companion.required'),
        },
      },

      to_commerces: {
        presence: {
          if: isToCommerces,
          relations: ['hasMany'],
          message: get(this, 'i18n').t('error.communication.commerce.required'),
        },
      },
    };
  }),

});
