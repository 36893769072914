import Model from 'ember-data/model';
import { get, computed } from '@ember/object';

import { inject as service } from '@ember/service';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

import Month from 'neuro-frontend/utils/month';
import formatDate from 'neuro-frontend/utils/dates/format-date';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel.extend({
  closedMonths: fragmentArray('closed-month'),
}) {
  // ChangeTracker is an addon to track dirty relationships
  changeTracker = { auto: true }

  @service can

  @attr('trimmed-string') companyReferenceNumber

  @attr('boolean') disabledSales

  @attr('number', { defaultValue: 0 }) capacity

  @attr('date') contractStartDate

  @attr('date') contractEndDate

  @attr('trimmed-string') email

  @attr('trimmed-string') fiscalName

  @attr('string') imageUrl

  @attr('boolean') isMan

  @attr('boolean') isWoman

  @attr('boolean') hidden

  @attr('number', { defaultValue: 0 }) minimumAge

  @attr('number', { defaultValue: 99 }) maximumAge

  @attr('trimmed-string') name

  @attr('trimmed-string') phone

  @attr('number') pin

  @attr('number') rent

  @attr('number') surface

  @attr('trimmed-string') tenantCode

  @attr('number', { defaultValue: 60 }) thresholdActiveCheckIn

  @attr('trimmed-string') vatNumber

  @attr('string') whatsappMessage

  @attr('string') whatsappPhone

  @attr('string') webUrl

  @hasMany('category') categories

  @belongsTo('center') center

  @hasMany('intranetSale', { async: false }) intranetSales

  @belongsTo('staffMember') manager

  @belongsTo('shopProperty') shopProperty

  @hasMany('staffMember', { inverse: 'commerces' }) staffMembers

  @belongsTo('zone') zone

  @computed('minimumAge', 'maximumAge')
  get age() {
    return i18n.t('date.from-to', {
      from: this.minimumAge,
      to: this.maximumAge,
    });
  }

  @computed('name')
  get commerceWithCenter() {
    const name = get(this, 'name');

    return `${get(this, 'center.name')} > ${name}`;
  }

  @computed('isWoman', 'isMan')
  get gender() {
    const genders = [];
    const {
      isMan,
      isWoman,
    } = this;

    if (isMan) {
      genders.push({
        label: i18n.t('man'),
      });
    }

    if (isWoman) {
      genders.push({
        label: i18n.t('woman'),
      });
    }
    return genders;
  }

  @computed()
  get validations() {
    return {
      categories: {
        presence: {
          message: i18n.t('error.commerce.categories.required'),
        },
      },

      gender: {
        presence: {
          if: () => !get(this, 'isMan') && !get(this, 'isWoman'),
          message: i18n.t('error.commerce.gender.required'),
        },
      },

      imageUrl: {
        presence: {
          message: i18n.t('error.commerce.imageUrl.required'),
        },
      },

      name: {
        presence: {
          message: i18n.t('error.commerce.name.required'),
        },
      },

      whatsappMessage: {
        presence: {
          if: () => this.whatsappPhone,
          message: i18n.t('error.commerce.whatsapp-message.required'),
        },
      },

      whatsappPhone: {
        format: {
          if: () => this.whatsappPhone,
          message: i18n.t('error.commerce.whatsapp-phone.format'),
          with: /^\+.+/,
        },
      },

      zone: {
        presence: {
          message: i18n.t('error.commerce.zone.required'),
        },
      },
    };
  }

  getMonthState(month) {
    if (!(month instanceof Month)) {
      throw new TypeError(`getMonthState: ${month} should be of type Month`);
    }

    const closedMonth = get(this, 'closedMonths').findBy('month', get(month, 'value'));

    if (closedMonth) {
      return get(closedMonth, 'validated')
        ? 'validated' : 'closed';
    }

    return get(this, 'intranetSales')
      .any((sale) => sale.date && formatDate(sale.date, 'DD/MM/YYYY').startsWith(get(month, 'value')))
      ? 'unclosed'
      : 'without_sales';
  }
}
