import Component from '@ember/component';
import {
  set, get, computed, action,
} from '@ember/object';

export default class extends Component {
  classNames = ['nf-file-manager']

  // https://medium.com/@isaacezer/neat-facts-about-javascript-ember-2a3c4b3bb40b
  init(...args) {
    super.init(...args);

    set(this, 'files', get(this, 'files') || []);
  }

  @computed('files.[]', 'category')
  get tempFiles() {
    const files = get(this, 'files');
    const {
      category,
    } = this;

    return files.filterBy('category', category);
  }

  @action
  addNewFiles() {
    const files = get(this, 'files').filter((file) => get(file, 'category') !== get(this, 'category'));
    set(this, 'files', files.concat(get(this, 'tempFiles')));
  }
}
