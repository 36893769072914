import { get, computed } from '@ember/object';
import Model from 'ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';
import { attr, belongsTo } from '@ember-data/model';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('datetime') entryTime

  @attr('datetime') expectedExitTime

  @attr('datetime') exitTime

  @attr('string') notes

  @belongsTo('child') child

  @computed()
  get validations() {
    return {
      entryTime: {
        presence: {
          message: get(this, 'i18n').t('error.crm.children.entryTime.required'),
        },
      },
      expectedExitTime: {
        presence: {
          message: get(this, 'i18n').t('error.crm.children.expectedExitTime.required'),
        },
      },
    };
  }
}
