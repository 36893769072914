import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import formatSeconds from 'neuro-frontend/utils/dates/format-seconds';
import d3 from 'd3';

@classNames('nf-chart-timeseries')
export default class extends Component.extend({
    xFormat: 'day',
    yFormat: 'number',
    type: 'spline',
  }) {
  grid = {
    x: {
      show: true,
    },
    y: {
      show: true,
    },
    y2: {
      show: true,
    },
  }

  size = {
    height: 288,
  }

  @computed('xFormat')
  get axis() {
    const {
      xFormat,
      yFormat,
      y2Format,
    } = this;

    const axis = {
      x: {
        type: 'timeseries',
        tick: {
          format: this.getFormat(xFormat || '%d-%m-%Y'),
        },
        padding: {
          top: 0,
          bottom: 0,
        },
      },
      y: {
        tick: {
          format: this.getFormat(yFormat || 'number'),
        },
        padding: {
          bottom: 0,
        },
      },
      y2: {
        show: Boolean(y2Format),
        tick: {
          format: this.getFormat(y2Format),
        },
        padding: {
          bottom: 0,
        },
      },
    };

    return axis;
  }

  @computed('colors')
  get color() {
    const { colors } = this;
    const color = {
      pattern: colors || ['#876695', '#678AE0'],
    };
    return color;
  }

  @computed('zoomEnabled')
  get zoom() {
    const { zoomEnabled } = this;
    const zoom = {
      enabled: zoomEnabled || false,
    };
    return zoom;
  }

  @computed('chartData', 'axis', 'xFormat')
  get data() {
    const {
      chartData,
      axis,
      xFormat,
    } = this;

    if (!chartData.length) {
      return {};
    }

    return {
      x: 'x',
      columns: chartData,
      type: this.type,
      axis,
      ...'week' === xFormat ? {
        xFormat: '%Y%V',
      }
        : {},
    };
  }

  // @TODO refactor to achieve:
  // - adapt numbers to browsers in other languages
  //
  // We are not using d3 to format numbers because d3 does not allow a
  // varible number of decimal digits

  getFormat(expected = 'number') {
    let format;
    switch (expected) {
      case 'month':
        format = i18n.d3Locale.format('%b-%Y');
        break;
      case 'day':
        format = '%d-%m-%Y';
        break;
      case 'week':
        format = '%-V';
        break;
      case '%':
        format = function (num) {
          const formattedNum = Number(num * 100).toLocaleString('es-ES', { maximumFractionDigits: 2 });
          return formattedNum.concat('%');
        };
        break;
      case 'time':
        format = formatSeconds;
        break;
      case 'number':
        format = function (num) {
          const formattedNum = Number(num).toLocaleString('es-ES', { maximumFractionDigits: 2 });
          return formattedNum;
        };
        break;
      case 'integer':
        format = function (d) {
          return (parseInt(d, 10) === d) ? d : null;
        };
        break;
      default:
        format = d3.format(expected);
        break;
    }
    return format;
  }
}
