/* global Inputmask */
import CurrencyInput from 'ember-inputmask/components/currency-input';
import { computed } from '@ember/object';

Inputmask.extendAliases({
  PER: {
    alias: 'percentage',
    prefix: '',
    suffix: ' %',
  },
});

export default class extends CurrencyInput {
  @computed('inputMask')
  get mask() {
    const {
      inputMask,
    } = this;
    return inputMask || 'PER';
  }
}
