import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';

export default class extends MF.Fragment {
  @attr('string') code

  @attr('array', { defaultValue() { return []; } }) files

  @attr('string') notes

  // eslint-disable-next-line babel/camelcase
  @attr('string') map_description
}
