import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';

import { inject as service } from '@ember/service';
import fetch from 'fetch';

import ListMixin from 'neuro-frontend/mixins/nf-list';

export default class extends Component.extend(ListMixin, {
  loyaltyCards: {
    isLoading: false,

    meta: {},

    page: {
      number: 1,
      size: 5,
    },
  },

  model: {
    loyaltyCards: [],
    tickets: [],
  },
}) {
  @service api

  @service auth

  stamps = {
    amount: 0,
    campaign: null,
  }

  defaultFilter = {
    'campaign.is-loyalty-card': 1,
  }

  filter = {
    'campaign.is-loyalty-card': 1,
  }

  loyaltyCardsPreview = {
    data: [],
    hasLoyaltyCards: false,
    isLoading: false,
  }

  modelName = 'ticket'

  sort = 'is-redeemed'

  @computed
  get columns() {
    return [
      {
        label: i18n.t('ticket.code'),
        valuePath: 'code',
        sortable: false,
      },
      {
        label: i18n.t('ticket.campaign-name'),
        valuePath: 'campaignrule.campaign.name',
        sortable: false,
      },
      {
        cellType: 'date',
        label: i18n.t('ticket.created-at'),
        sortable: false,
        valuePath: 'createdAt',
      },
      {
        align: 'center',
        cellAction: 'redeem',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'redeem',
        resource: 'ticket',
        sortable: false,
        tooltip: i18n.t('loyalty-card.stamps.redeemable-stamp'),
        width: '7%',
      },
    ];
  }

  @computed
  get loyaltyCardColumns() {
    return [
      {
        label: i18n.t('loyalty-card.campaign'),
        valuePath: 'campaign.name',
        sortable: false,
      },
      {
        label: i18n.t('loyalty-card.stamps.value'),
        valuePath: 'campaign.stamps',
        sortable: false,
      },
      {
        cellType: 'date',
        label: i18n.t('ticket.created-at'),
        sortable: false,
        valuePath: 'createdAt',
      },
    ];
  }

  @computed
  get loyaltyCardsPreviewColumns() {
    return [
      {
        label: i18n.t('loyalty-card.campaign'),
        valuePath: 'campaign',
        sortable: false,
      },
      {
        label: i18n.t('loyalty-cards'),
        valuePath: 'loyalty_cards',
        sortable: false,
      },
      {
        label: i18n.t('loyalty-cards.stamps'),
        valuePath: 'stamps_left',
        sortable: false,
      },
    ];
  }

  init(...args) {
    super.init(...args);

    set(
      this,
      'fixedFilters',
      {
        user: get(this, 'user.id'),
      },
    );
  }

  // doing loadCollection in didInsertElement
  // this avoids "infinite rendering invalidation detected" problem
  async willInsertElement(...args) {
    super.willInsertElement(...args);

    await this.fetchData();
  }

  @action
  async changeCampaign(campaign) {
    set(this, 'isLoading', true);

    if (campaign) {
      set(
        this,
        'filter',
        {
          campaign: campaign.id,
        },
      );
    } else {
      this.resetFilters();
    }

    await this.fetchData();

    set(this, 'isLoading', false);
  }

  @action
  async createStamps() {
    const {
      stamps: {
        amount,
        campaign,
      },
      store,
    } = this;
    const queries = [];

    set(this, 'isCreatingStamps', true);

    if (this.validateStampNumber(amount, campaign)) {
      for (let i = 0; i < amount; i += 1) {
        queries.push(store.createRecord(
          'ticket',
          {
            campaignrule: campaign.campaignrules.firstObject,
            code: '',
            counter: i,
            prints: 0,
            user: this.user,
          },
        ));
      }

      await Promise.all(queries.map((query) => query.save()));
      await this.fetchData();

      set(this, 'isOpenCreateStampsModal', false);
    }

    set(this, 'isCreatingStamps', false);
  }

  @action
  async exchangeStamps() {
    set(this, 'loyaltyCardsPreview.isLoading', true);
    try {
      await this.createLoyaltyCards(false);
    } catch (e) {
      this.showMessage('error', i18n.t('loyalty-cards.generate-error-message'));
    }

    this.fetchData();

    set(this, 'loyaltyCardsPreview.isLoading', false);
    set(this, 'isOpenExchangeStampsModal', false);
    this.showMessage('success', i18n.t('loyalty-cards.generate-success-message'));
  }

  @action
  openCreateStampsModal() {
    set(this, 'errorStampNumber', false);
    set(this, 'stamps.amount', 0);
    set(this, 'stamps.campaign', null);
    set(this, 'isOpenCreateStampsModal', true);
  }

  @action
  async showExchangeStampsModal() {
    set(this, 'loyaltyCardsPreview.hasLoyaltyCards', false);
    set(this, 'loyaltyCardsPreview.isLoading', true);
    set(this, 'generateLoyaltyCardsState', null);
    set(this, 'isOpenExchangeStampsModal', true);

    let loyaltyCardsPreview = [];
    loyaltyCardsPreview = await this.createLoyaltyCards(true);
    if (!loyaltyCardsPreview) {
      this.loyaltyCardsPreview.data.clear();
    } else {
      this.prepareLoyaltyCardsList(loyaltyCardsPreview);
    }

    set(this, 'loyaltyCardsPreview.isLoading', false);
  }

  checkLoyaltyCardsQuantity() {
    if (!this.loyaltyCardsPreview.data.length) {
      return false;
    }

    const total = this.loyaltyCardsPreview.data
      .reduce((cur, campaign) => cur + campaign.loyalty_cards, 0);

    return (0 !== total);
  }

  createLoyaltyCards(preview) {
    const query = {
      data: {
        id: get(this, 'user.id'),
        type: 'user_create_loyalty_cards',
        attributes: {
          preview,
        },
      },
    };

    return fetch(
      `${this.get('api.host')}/api/v4/user_create_loyalty_cards/${get(this, 'user.id')}`,
      {
        body: JSON.stringify(query),
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${get(this, 'auth.token')}`,
        },
        method: 'PATCH',
        mode: 'cors',
      },
    ).then((response) => response.json());
  }

  fetchCampaignrules() {
    if (this.campaigns && this.campaigns.length) {
      return Promise.all(this.campaigns
        .map((campaign) => this.store.query(
          'campaignrule',
          {
            filter: {
              campaign: campaign.id,
            },
          },
        )));
    }

    return Promise.resolve();
  }

  fetchData() {
    return Promise.all([
      this.fetchCampaignrules(),
      this.fetchLoyaltyCards(),
      this.loadCollection(),
    ]);
  }

  // @TODO refactor: it should use nf-list but how?
  fetchLoyaltyCards() {
    const {
      store,
    } = this;
    const loyaltyCards = get(this.model, 'loyaltyCards');
    const page = get(this, 'loyaltyCards.page');
    const query = {
      ...this.query,
      sort: '-created-at',
      page,
      filter: {
        campaign: this.filter.campaign,
        user: get(this, 'user.id'),
      },
    };

    set(this, 'loyaltyCards.isLoading', true);

    if (loyaltyCards) {
      loyaltyCards.clear();
    }

    return store
      .query('loyalty-card', query)
      .then((collection) => {
        const arrayCollection = collection.toArray();
        if (!(get(this, 'isDestroyed') || get(this, 'isDestroying'))) {
          set(this, 'loyaltyCards.meta', get(collection, 'meta'));

          if (loyaltyCards) {
            loyaltyCards.pushObjects(arrayCollection);
          } else if (get(this, 'model')) {
            set(this, 'model.loyaltyCards', arrayCollection);
          }

          set(this, 'loyaltyCards.isLoading', false);
        }

        return arrayCollection;
      });
  }

  async prepareLoyaltyCardsList(loyaltyCardsPreview) {
    const {
      store,
    } = this;
    const campaigns = await Promise.all([
      ...new Set(loyaltyCardsPreview.map((value) => value.campaign_id)),
    ].map((campaign) => store.findRecord('campaign', campaign)));

    this.loyaltyCardsPreview.data.clear();
    this.loyaltyCardsPreview.data.pushObjects(loyaltyCardsPreview.map((value) => ({ ...value, campaign: campaigns.findBy('id', String(value.campaign_id)).name })));
    set(this, 'loyaltyCardsPreview.hasLoyaltyCards', this.checkLoyaltyCardsQuantity());
  }

  showMessage(state, message) {
    if ('success' === state) {
      set(this, 'exchangeLoyaltyCardsSuccessMessage', message);
    } else if ('error' === state) {
      set(this, 'exchangeLoyaltyCardsErrorMessage', message);
    }
    set(this, 'exchangeLoyaltyCardsState', state);
  }

  validateStampNumber(amount, campaign) {
    set(this, 'errorStampNumber', false);

    if (amount > campaign.stamps) {
      set(this, 'errorStampNumber', [i18n.t('loyalty-cards.error-stamp-number')]);

      return false;
    }

    return true;
  }
}
