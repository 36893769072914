import Controller from '@ember/controller';
import {
  get, set, computed, action,
} from '@ember/object';

import { inject as service } from '@ember/service';

import actionTypes from 'neuro-frontend/enums/action-types';
import beautifyFilters from 'neuro-frontend/utils/beautify-filters';
import userFilters from 'neuro-frontend/enums/user-filters';
import residentialUserStates from 'neuro-frontend/enums/residential-user-states';

import ListMixin from 'neuro-frontend/mixins/nf-list';
import SegmentFiltersMixin from 'neuro-frontend/mixins/segment-filters';

const MixinController = Controller.extend(ListMixin, SegmentFiltersMixin, {});
// best solution according RFC-240:
// https://github.com/emberjs/rfcs/blob/master/text/0240-es-classes.md#mixins
export default class extends MixinController {
  @service auth

  @service api

  @service can

  editRoute = 'residential.users.edit'

  modelName = 'user'

  sort = '-updated-at'

  isDeleting = false

  init(...args) {
    super.init(...args);
    set(this, 'sendRemoveEmail', true);
    set(
      this,
      'fixedFilters',
      {
        center_id: get(this, 'auth.centerId'),
      },
    );
  }

  loadCollection() {
    const length = get(this, 'model.residentialDevelopments.length');

    if (this.can.can('access to module-residential') && !length && !this.can.can('read all residential-development')) {
      return new Promise((resolve) => {
        const collection = [];

        set(this, 'isLoading', false);
        set(this, 'model.users', collection);

        resolve(collection);
      });
    }

    return super.loadCollection();
  }

  @computed
  get columns() {
    const baseColumns = [
      {
        label: get(this, 'i18n').t('crm.users.code'),
        valuePath: 'code',
        cellClassNames: ['table-list__cell--ellipsis'],
        hidden: this.can.can('access to module-residential'),
      },
      {
        label: get(this, 'i18n').t('crm.users.name'),
        valuePath: 'name',
        cellClassNames: ['table-list__cell--ellipsis'],
      },
      {
        label: get(this, 'i18n').t('crm.users.last_name'),
        valuePath: 'lastName',
        cellClassNames: ['table-list__cell--ellipsis'],
        align: 'center',
      },
      {
        label: get(this, 'i18n').t('crm.users.email'),
        valuePath: 'email',
        width: '20%',
        cellClassNames: ['table-list__cell--ellipsis'],
        align: 'center',
      },
      {
        label: get(this, 'i18n').t('crm.users.vat-number'),
        valuePath: 'dni',
        hidden: this.can.can('access to module-residential'),
        align: 'center',
      },
      {
        label: get(this, 'i18n').t('crm.users.phone'),
        valuePath: 'phone',
        align: 'center',
      },
    ];

    const residentialColumns = [
      {
        label: get(this, 'i18n').t('crm.users.state'),
        sortable: false,
        valuePath: 'extraFields.state',
        cellType: 'enum',
        enum: residentialUserStates,
        align: 'center',
      },
      {
        label: get(this, 'i18n').t('crm.users.last-action'),
        sortable: false,
        cellType: 'enum',
        valuePath: 'lastAction.actionType',
        enum: actionTypes,
        cellClassNames: ['table-list__cell--ellipsis'],
        classNames: ['table-list__cell--ellipsis'],
        align: 'center',
      },
      {
        label: get(this, 'i18n').t('crm.users.last-action-datetime'),
        cellType: 'date',
        valuePath: 'lastActionDate',
        align: 'center',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('crm.users.created-at'),
        cellType: 'datetime',
        valuePath: 'createdAt',
        align: 'center',
        sortable: true,
      },
    ];

    const actions = [
      {
        label: '',
        cellAction: 'showChildren',
        cellClassNames: ['qa-children-icon'],
        cellType: 'material-icon',
        materialIcon: 'face',
        tooltip: get(this, 'i18n').t('show-children'),
        sortable: false,
        align: 'center',
        width: '4%',
        hidden: this.can.can('access to module-residential'),
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: get(this, 'i18n').t('edit'),
        sortable: false,
        align: 'center',
        width: '4%',
      },
      {
        label: '',
        cellAction: 'delete',
        cellClassNames: ['qa-delete-icon'],
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: get(this, 'i18n').t('delete'),
        sortable: false,
        align: 'center',
        width: '4%',
      },
    ];
    return this.can.can('access to module-residential') ? baseColumns.concat(residentialColumns, actions) : baseColumns.concat(actions);
  }

  @computed('auth.center')
  get modelFilters() {
    const center = get(this, 'auth.center');

    return beautifyFilters(userFilters, center);
  }

  @action
  showChildren(model) {
    if (!model || !model.get('id')) {
      throw new TypeError('`model` is not a valid user');
    }
  }

  resetFilters() {
    super.resetFilters();

    set(this, 'sort', '-updated-at');
  }

  @action
  delete(model) {
    const userId = get(model, 'id');

    if (!model || !userId) {
      throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
    }

    return model.destroyRecord().then(() => {
      if (this.sendRemoveEmail) {
        const query = {
          data: {
            type: 'users_unsubscribe_send',
            attributes: {
              user: userId,
            },
          },
        };
        fetch(
          `${this.get('api.host')}/api/v4/users_unsubscribe_send`,
          {
            body: JSON.stringify(query),
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Authorization: `Bearer ${get(this, 'auth.token')}`,
            },
            method: 'POST',
            mode: 'cors',
          },
        );
      }
    })
      .finally(() => {
        set(this, 'sendRemoveEmail', true);
      });
  }
}
