import Component from '@ember/component';
import { action, get } from '@ember/object';

export default class extends Component {
  classNames = ['quick-search']

  @action
  search(...args) {
    if ('function' === typeof get(this, 'onSearch')) {
      get(this, 'onSearch')(...args);
    }
  }
}
