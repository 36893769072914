import MdInput from 'ember-cli-materialize/components/md-input';
import { alias } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { computed, action, set } from '@ember/object';

import Day from 'neuro-frontend/utils/day';
import formatDate from 'neuro-frontend/utils/dates/format-date';

@classNames('nf-input-date', 'input-field', 'nf-input')
export default class extends MdInput.extend({
    format: 'DD/MM/YYYY',
  }) {
  classNameBindings = ['isDisabled:nf-input-date--is-disabled']

  @alias('disabled') isDisabled

  @computed('value')
  get date() {
    const {
      value,
    } = this;

    return value
      ? new Date(formatDate(value, this.format))
      : null;
  }

  @computed('format')
  get dateFormat() {
    const {
      format,
    } = this;

    // @TODO refactor and add tests
    return format && format.includes('Y')
      ? 'd/m/Y'
      : 'd/m';
  }

  @action
  changeDate(selectedDate) {
    set(this, 'value', new Day(selectedDate[0]).format(this.format));
  }

  @action
  close() {
    const picker = this.get('inputDate');
    this.changeDate([new Date(formatDate(picker.input.value, 'DD/MM/YYYY'))]);
  }
}
