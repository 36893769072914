import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});
export default class IntranetSalesYearModel extends MixinModel {
  // chronological order instead alphabetical order
  @attr('number', { defaultValue: 0 }) january

  @attr('number', { defaultValue: 0 }) february

  @attr('number', { defaultValue: 0 }) march

  @attr('number', { defaultValue: 0 }) april

  @attr('number', { defaultValue: 0 }) may

  @attr('number', { defaultValue: 0 }) june

  @attr('number', { defaultValue: 0 }) july

  @attr('number', { defaultValue: 0 }) august

  @attr('number', { defaultValue: 0 }) september

  @attr('number', { defaultValue: 0 }) october

  @attr('number', { defaultValue: 0 }) november

  @attr('number', { defaultValue: 0 }) december

  @attr('number') snapshotJanuary

  @attr('number') snapshotFebruary

  @attr('number') snapshotMarch

  @attr('number') snapshotApril

  @attr('number') snapshotMay

  @attr('number') snapshotJune

  @attr('number') snapshotJuly

  @attr('number') snapshotAugust

  @attr('number') snapshotSeptember

  @attr('number') snapshotOctober

  @attr('number') snapshotNovember

  @attr('number') snapshotDecember

  @attr('string') state

  @attr('string') year

  @belongsTo('commerce') commerce
}
