export default Object.freeze([
  {
    label: i18n.t('single'),
    value: 'S',
  },
  {
    label: i18n.t('couple'),
    value: 'P',
  },
  {
    label: i18n.t('family'),
    value: 'F',
  },
]);
