import Component from '@ember/component';

export default Component.extend({
  classNames: ['communications-sidebar-filter'],

  actions: {
    optionClicked(option) {
      this.set('selectedOption', this.get('selectedOption') === option ? '' : option);

      this.get('onFilterTypeChanged')(this.get('selectedOption'));
    },
  },
});
