import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
  classNames: ['row', 'tab-content', 'nf-tabs__section'],

  classNameBindings: ['isActive::hide'],

  isActive: computed('selection', 'name', function () {
    const selection = get(this, 'selection');
    const name = get(this, 'name');

    return selection
      && name
      && selection.name === name;
  }),
}).reopenClass({
  positionalParams: ['name'],
});
