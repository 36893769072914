import Component from '@ember/component';
import { set, action } from '@ember/object';

import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { debug } from '@ember/debug';

import parseErrors from 'neuro-frontend/utils/parse-errors-array-to-errors-object';

@classNames('nf-form')
export default class NfFormComponent extends Component.extend({
    rules: {},
  }) {
  @service router

  isLoading = false

  @action
  cancel() {
    if (this.onCancel) {
      this.onCancel(this.model);
    } else {
      if (this.model) {
        this.model.rollbackAttributes();
      }

      window.history.back();
    }
  }

  @action
  save() {
    set(this, 'isLoading', true);

    return this.model.save()
      .then(() => this.afterSaved())
      .catch((adapterError) => {
        set(this, 'errorDetails', adapterError.errors.map((e) => e.detail));
        if ('4' === adapterError.errors[0].status.charAt()) {
          set(this, 'errorMessage', i18n.t('user-error'));
        } else {
          set(this, 'errorMessage', i18n.t('api-error'));
        }

        // @TODO this can work right, but we're scrolling to top of the website
        // instead going to the actual position of the element, so there can be
        // cases where this doesn't work (like forms inside other components):
        //
        // We could improve this situation by developing this code inside the component
        // nf-info when it throws an error <3.
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })
      .finally(() => this.toggleLoading());
  }

  @action
  async submit() {
    if (!this.isLoading) {
      set(this, 'isLoading', true);
      set(this, 'errors', {});

      if (!this.model.validate(this.rules)) {
        set(this, 'errors', parseErrors(this.model.errors));
      }

      debug(this.model.errors && this.model.errors.reduce((cur, value) => (cur ? `${value.attribute}, ${cur}` : value.attribute), ''));

      const willSave = await this.checkErrors(() => (this.onSubmit
        ? this.onSubmit(this.model)
        : true
      ));

      if (!Object.keys(this.errors).length && willSave) {
        await this.checkErrors(this.save.bind(this));
      }

      this.toggleLoading();
    }
  }

  afterSaved() {
    if (this.onSaved) {
      return this.onSaved();
    } if (this.returnRoute) {
      this.router.transitionTo(this.returnRoute);
    } else {
      window.history.back(); // @TODO avoid history.back, it's buggy
    }

    return Promise.resolve();
  }

  async checkErrors(callback) {
    let willSave = true;

    try {
      willSave = await callback();
    } catch (errors) {
      set(this, 'errors', { ...this.errors, ...errors });
    }

    return willSave;
  }

  toggleLoading() {
    if (!this.isDestroyed) {
      set(this, 'isLoading', false);
    }
  }
}
