import {
  get, set, action, computed,
} from '@ember/object';
import { hash } from 'rsvp';

import { inject as service } from '@ember/service';
import { assign } from '@ember/polyfills';

import fetch from 'fetch';
import Filter from 'neuro-frontend/utils/filter';
import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';
import parseFilters from 'neuro-frontend/utils/parse-filters';
import Route from 'neuro-frontend/routes/base';
import userColumns from 'neuro-frontend/enums/user-columns';

export default class extends Route {
  @service ajax

  @service api

  @service auth

  @service can

  models = [
    'country',
    'province',
  ]

  @computed
  get defaultColumns() {
    return this.can.filterByModules(userColumns).map((elem) => elem.value);
  }

  async model() {
    const store = get(this, 'store');
    const campaigns = await store.findAll('campaign').then((collection) => collection.sortBy('name'));
    const loyaltyCampaigns = campaigns.filter((campaign) => campaign.isLoyaltyCard);
    const rewardCampaigns = campaigns.filter((campaign) => campaign.isReward);
    return hash({
      buildings: store.findAll('building'),

      campaigns,

      channels: store.findAll('channel'),

      commerces: store.findAll('commerce').then((collection) => collection.sortBy('name')),

      coupons: store.findAll('coupon').then((collection) => collection.sortBy('text')),

      loyaltyCampaigns,

      mainBuildings: store.findAll('building').then((collection) => collection.filter((building) => 'main' === building.type)),

      residentialDevelopments: this.can.can('read all residential-development')
        ? store.findAll('residentialDevelopment')
        : store.query(
          'residentialDevelopment',
          {
            filter: {
              staff_id: get(this, 'auth.user.id'),
            },
          },
        ),

      retailProductSubfamilies: store.findAll('retailProductSubfamily'),

      rewardCampaigns,

      users: [],

      userTypes: store.findAll('userType').then((collection) => collection.sortBy('label')),
    });
  }

  setupController(ctrl, model) {
    const store = get(this, 'store');
    const centerId = get(this, 'auth.centerId');
    const residentialDevelopmentsIds = get(model, 'residentialDevelopments')
      .map((residentialDevelopment) => get(residentialDevelopment, 'id'));

    super.setupController(ctrl, model);

    ctrl.loadCollection();

    store.query(
      'segment',
      {
        filter: {
          center_id: centerId,
        },
      },
    ).then((segments) => {
      const filteredSegments = segments.filter((segment) => {
        let canReadSegment = true;
        const filters = segment.getFilters();
        filters.forEach((filter) => {
          if ('property.residential_development' === get(filter, 'type.value') && !residentialDevelopmentsIds.includes(get(filter, 'values.value'))) {
            canReadSegment = false;
          }
        });
        return canReadSegment;
      });

      set(ctrl, 'model.segments', filteredSegments);
    });

    if (!ctrl.hasFilters()) {
      if (this.can.can('access to module-residential') && !this.can.can('read all residential-development')) {
        set(
          ctrl,
          'fixedfilters',
          assign(
            get(this.controller, 'fixedFilters'),
            parseFilters([new Filter({
              operation: 'in',
              type: {
                value: 'property.residential_development',
              },
              values: {
                value: residentialDevelopmentsIds.reduce(
                  (prev, id) => (prev ? `${prev},${id}` : id),
                  '',
                ),
              },
            })]),
            {
              type: 'and',
            },
          ),
        );
      }

      ctrl.resetFilters();
    }
  }

  @action
  handleDelete(model) {
    if (!model || !model.get('id')) {
      throw new TypeError('`model` is not valid');
    }

    return model.destroyRecord().then(() => {
      if (get(this, 'controller.selectedSegment') === model) {
        set(this, 'controller.selectedSegment', null);
      }
    });
  }

  @action
  handleExportCsv() {
    const attributes = {
      filters: JSON.stringify(get(this, 'controller.query.filter')),
    };
    attributes['csv-fields'] = this.defaultColumns;
    attributes.type = 'and';

    const relationships = {
      center: {
        data: {
          type: 'centers',
          id: get(this, 'auth.centerId'),
        },
      },
    };

    const data = {
      data: {
        type: 'users_export_csv',
        attributes,
        relationships,
      },
    };

    return fetch(`${get(this, 'api.host')}/api/v4/users_export_csv`, {
      headers: {
        'content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${get(this, 'auth.token')}`,
      },
      body: JSON.stringify(data),
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
    });
  }

  @action
  saveChanges(segment) {
    const selectedSegment = get(this, 'controller.selectedSegment');

    if (!selectedSegment) {
      throw new IllegalArgumentError(`saveChanges: ${selectedSegment} must be set before saving it`);
    }

    Object.entries(segment).forEach(([key, value]) => set(selectedSegment, key, value));

    return selectedSegment.save();
  }

  @action
  saveSegment(form) {
    const segment = {
      filters: get(form, 'filters'),
      segmentName: get(form, 'name'),
      segmentType: get(form, 'type'),
      visible: true,
      center: get(this, 'auth.center'),
    };

    const newSegment = get(this, 'store').createRecord(
      'segment',
      segment,
    );

    return newSegment.save().then(() => {
      get(this, 'controller.model.segments').pushObject(newSegment);
      set(this, 'controller.selectedSegment', newSegment);
    });
  }

  @action
  handleSendEmail(segment) {
    if (!segment || !segment.get('id')) {
      throw new TypeError('`model` is not a valid segment');
    }

    this.transitionTo('emailing.campaigns.new', { queryParams: { segmentId: get(segment, 'id') } });
  }
}
