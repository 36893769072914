import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  lineitem: association(),

  name() {
    return faker.random.word();
  },
});
