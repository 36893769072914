import { get } from '@ember/object';
import { compare } from '@ember/utils';

function filterByModules(filters, center) {
  return filters
    .filter((filter) => get(filter, 'modules').some((module) => center && get(center, `has${(module).capitalize()}`)));
}

function groupByName(filters) {
  return filters.reduce(
    (groups, filter) => {
      let group = groups.findBy('groupName', get(filter, 'groupName').toString());
      if (!group) {
        group = {
          groupName: get(filter, 'groupName').toString(),
          options: [],
        };
        groups.push(group);
      }
      group.options.push(filter);
      return groups;
    },
    [],
  );
}

function sortGroups(filters) {
  return filters.map((group) => {
    group.options.sort((a, b) => {
      const propA = get(a, 'label').toString();
      const propB = get(b, 'label').toString();
      const compareValue = compare(propA, propB);

      return compareValue || 0;
    });
    return group;
  });
}

export default function beautifyFilters(filters, center) {
  let beautyFilters = filterByModules(filters, center);
  beautyFilters = groupByName(beautyFilters);
  return sortGroups(beautyFilters);
}
