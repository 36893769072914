import { computed, get } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import Validator from 'ember-model-validator/mixins/model-validator';
import getLabelMailTemplate from '../utils/get-label-mail-template';

export default Model.extend(Validator, {
  center_id: attr('number'),

  email_type: attr('trimmed-string', { defaultValue: '' }),

  template: attr('string'),

  label: computed('email_type', function () {
    return getLabelMailTemplate(get(this, 'email_type'));
  }),
});
