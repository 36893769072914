export default Object.freeze([
  {
    label: 'PDF',
    type: 'pdf',
    value: 'pdf',
  },
  {
    label: 'JPG',
    type: 'image',
    value: 'jpg',
  },
  {
    label: 'JPEG',
    type: 'image',
    value: 'jpeg',
  },
  {
    label: 'GIF',
    type: 'image',
    value: 'gif',
  },
  {
    label: 'PNG',
    type: 'image',
    value: 'png',
  },
  {
    label: 'Excel',
    type: 'spreadsheet',
    value: 'xls',
  },
  {
    label: 'Excel',
    type: 'spreadsheet',
    value: 'ods',
  },
  {
    label: 'Excel',
    type: 'spreadsheet',
    value: 'xlsx',
  },
  {
    label: 'Word',
    type: 'word',
    value: 'doc',
  },
  {
    label: 'Word',
    type: 'word',
    value: 'docx',
  },
  {
    label: 'Word',
    type: 'word',
    value: 'odt',
  },
  {
    label: 'Powerpoint',
    type: 'presentation',
    value: 'ppt',
  },
  {
    label: 'Powerpoint',
    type: 'presentation',
    value: 'pptx',
  },
  {
    label: 'Powerpoint',
    type: 'presentation',
    value: 'odp',
  },
  {
    label: 'RAR',
    type: 'compressed',
    value: 'rar',
  },
]);
