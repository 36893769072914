import Component from '@ember/component';

export default class extends Component {
  classNames = ['nf-check']

  change() {
    if ('function' === typeof this.onChange) {
      this.onChange(this.checked);
    }
  }
}
