import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';

import Day from 'neuro-frontend/utils/day';

function translate(key) {
  const o = {
    age_range: i18n.t('dashboards.age-range'),
    email: i18n.t('dashboards.email'),
    gender: i18n.t('dashboards.gender'),
    mail_notifications: i18n.t('dashboards.email-notifications'),
    mail_partners_notifications: i18n.t('dashboards.thirt-party-notifications'),
    marital_status: i18n.t('dashboards.marital-status'),
    phone: i18n.t('dashboards.phone'),
    vat_number: i18n.t('dashboards.vat-number'),
    zip_code: i18n.t('dashboards.zip-code'),
  };
  return o[key];
}

@classNames('dashboard-crm__demographic')
export default class extends Component {
  consentChartColors = [
    '#367c86',
    '#5bc490',
    '#4DD0E1',
  ]

  qualityColors = [
    '#5bc490',
    '#367c86',
  ]

  typologyColors = [
    '#B06B87',
    '#D59CCF',
    '#A490C0',
    '#51657A',
    '#BCC4DB',
  ]

  @computed('userProfiles.month')
  get ageRange() {
    const {
      userProfiles,
    } = this;

    if (!userProfiles || !userProfiles.month.length) {
      return [];
    }

    // right, the data is in the first element
    // it may be necessary to filter with the selected month in the future
    const data = userProfiles.month[0].age_range;
    const total = data['0-17'] + data['18-24'] + data['25-34'] + data['35-44'] + data['45-99'];

    return (!total) ? null : [{
      category: '',
      [i18n.t('dashboards.0-17')]: this.toPercentage(data['0-17'], total),
      [i18n.t('dashboards.18-24')]: this.toPercentage(data['18-24'], total),
      [i18n.t('dashboards.25-34')]: this.toPercentage(data['25-34'], total),
      [i18n.t('dashboards.35-44')]: this.toPercentage(data['35-44'], total),
      [i18n.t('dashboards.45-99')]: this.toPercentage(data['45-99'], total),
    }];
  }

  @computed('userProfiles.day', 'registeredUsers.day', 'headers')
  get consentChart() {
    const {
      userProfiles: {
        day: userProfiles,
      },
      headers,
      registeredUsers: {
        day: registeredUsers,
      },
    } = this;

    if (!userProfiles.length || !registeredUsers.length) {
      return null;
    }

    const consentData = [];
    userProfiles.forEach((el) => {
      consentData.push({
        day: el.day,
        mailNotifications: el.mail_notifications.exists,
        mailPartners: el.mail_partners_notifications.exists,
      });
    });

    return [
      ['x', ...headers],
      [i18n.t('dashboards.registered-users'), ...this.parseData(registeredUsers, headers, 'new_registered_users')],
      [i18n.t('dashboards.mail-notif-consent'), ...this.parseData(consentData, headers, 'mailNotifications')],
      [i18n.t('dashboards.mail-partner-consent'), ...this.parseData(consentData, headers, 'mailPartners')],
    ];
  }

  @computed('userProfiles.month')
  get gender() {
    const {
      userProfiles,
    } = this;

    if (!userProfiles || !userProfiles.month.length) {
      return [];
    }

    // right, the data is in the first element
    // it may be necessary to filter with the selected month in the future
    const data = userProfiles.month[0].gender;
    const total = data.H + data.M + data.O;

    return (!total) ? null : [{
      category: '',
      [i18n.t('dashboards.men')]: this.toPercentage(data.H, total),
      [i18n.t('dashboards.women')]: this.toPercentage(data.M, total),
      [i18n.t('dashboards.other')]: this.toPercentage(data.O, total),
    }];
  }

  @computed('selectedMonth')
  get headers() {
    const date = new Date(this.selectedMonth.endDate);
    const month = date.getMonth();
    const year = date.getFullYear();
    const lastDay = date.getDate();
    const days = [];

    for (let i = 1; lastDay >= i; i += 1) {
      days.push(new Day(new Date(year, month, i)).format('YYYY-MM-DD'));
    }

    return days;
  }

  @computed('userProfiles.month')
  get influenceZone() {
    const {
      userProfiles,
    } = this;

    if (!userProfiles || !userProfiles.month.length) {
      return [];
    }

    // right, the data is in the first element
    // it may be necessary to filter with the selected month in the future
    const data = userProfiles.month[0].influence_zone;
    const total = data['1'] + data['2'] + data['3'] + data['4'];

    return (!total) ? null : [{
      category: '',
      [i18n.t('dashboards.1')]: this.toPercentage(data['1'], total),
      [i18n.t('dashboards.2')]: this.toPercentage(data['2'], total),
      [i18n.t('dashboards.3')]: this.toPercentage(data['3'], total),
      [i18n.t('dashboards.4')]: this.toPercentage(data['4'], total),
    }];
  }

  @computed('userProfiles.month')
  get maritalStatus() {
    const {
      userProfiles,
    } = this;

    if (!userProfiles || !userProfiles.month.length) {
      return [];
    }

    // right, the data is in the first element
    // it may be necessary to filter with the selected month in the future
    const data = userProfiles.month[0].marital_status;
    const total = data.P + data.F + data.S;

    return (!total) ? null : [{
      category: '',
      [i18n.t('dashboards.couple')]: this.toPercentage(data.P, total),
      [i18n.t('dashboards.family')]: this.toPercentage(data.F, total),
      [i18n.t('dashboards.single')]: this.toPercentage(data.S, total),
    }];
  }

  @computed('userProfiles.month')
  get qualityData() {
    const {
      userProfiles,
    } = this;

    if (!userProfiles || !userProfiles.month.length) {
      return [];
    }
    const dataQuality = [];

    Object.entries(userProfiles.month[0]).forEach(([key, value]) => {
      if ([
        'age_range',
        'gender',
        'martial_status',
        'email',
        'mail_notifications',
        'mail_partners_notifications',
        'phone',
        'vat_number',
        'zip_code',
      ].includes(key)) {
        const total = Object.values(value).reduce((prev, act) => prev + act, 0);
        const yes = total - value.none;

        dataQuality.push({
          category: translate(key),
          [i18n.t('yes')]: this.toPercentage(yes, total),
          [i18n.t('no')]: this.toPercentage(value.none, total),
        });
      }
    });
    return dataQuality;
  }

  parseData(data, tick, attr) {
    return tick.map((day) => data
      .filter((elem) => day === (elem.day || elem.date))
      .reduce((sum, elem) => ('object' !== typeof elem[attr] ? sum + elem[attr] : elem[attr]), 0));
  }

  toPercentage(value, total) {
    return (value / total) * 100;
  }
}
