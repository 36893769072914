import { get, computed } from '@ember/object';

import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  // ChangeTracker is an addon to track dirty relationships
  changeTracker = { auto: true }

  @attr('boolean') canAccessBonusRequest

  @attr('boolean') contactable

  @attr('number') creator

  @attr('trimmed-string') email

  @attr('boolean') eula

  @attr('string') imageUrl

  @attr('string') jobTitle

  @attr('trimmed-string') lastName

  @attr('string') level

  @attr('trimmed-string') name

  @attr('string') pass

  @attr('string') passConfirmation

  @attr('string') passOld

  @attr('trimmed-string') phone

  @attr('datetime') registerDate

  @attr('string') token

  @attr('string') deletedAt;

  @attr('boolean', { defaultValue: true }) intranetCommunications

  @belongsTo('channel') channel

  @belongsTo('building') defaultBuilding

  @belongsTo('country') country

  @hasMany('center') centers

  @hasMany('commerce', { inverse: 'staffMembers' }) commerces

  @hasMany('dashboard') dashboards

  @computed('centers')
  get center() {
    return this.centers.firstObject;
  }

  set center(center) {
    this.centers.clear();
    this.centers.pushObject(center);
  }

  @computed('commerces.[]')
  get commerce() {
    return this.commerces.firstObject;
  }

  set commerce(commerce) {
    this.commerces.clear();
    if (commerce) {
      this.commerces.pushObject(commerce);
    }
  }

  // TODO isPending is a bad practice. Fix it migrating the role store manager
  @computed('level', 'commerces', 'commerces.isPending')
  get role() {
    const commerces = get(this, 'commerces');
    const level = get(this, 'level');

    if (
      commerces
        .toArray()
        .some((commerce) => commerce.belongsTo('manager').id() === get(this, 'id'))
    ) {
      return 'storeManager';
    }
    return level;
  }

  @computed()
  get validations() {
    return {
      centers: {
        presence: {
          message: get(this, 'i18n').t('centers-required'),
        },
      },
      channel: {
        presence: {
          if: (key, value, _this) => (
            'external' === get(_this, 'level')),
          message: get(this, 'i18n').t('channel-required'),
        },
      },
      country: {
        presence: {
          message: get(this, 'i18n').t('country-required'),
        },
      },
      defaultBuilding: {
        presence: {
          if: (key, value, _this) => (
            'calibrator' === get(_this, 'level')),
          message: get(this, 'i18n').t('default-building-required'),
        },
      },
      email: {
        presence: {
          message: get(this, 'i18n').t('email-required'),
        },
      },
      imageUrl: {
        custom: {
          validation(key, value, _this) {
            return value || !get(_this, 'contactable');
          },
          message: get(this, 'i18n').t('image-required'),
        },
      },
      jobTitle: {
        custom: {
          validation(key, value, _this) {
            return value || !get(_this, 'contactable');
          },
          message: get(this, 'i18n').t('job-title-required'),
        },
      },
      lastName: {
        presence: {
          message: get(this, 'i18n').t('last-name-required'),
        },
      },
      level: {
        presence: {
          message: get(this, 'i18n').t('level-required'),
        },
      },
      name: {
        presence: {
          message: get(this, 'i18n').t('name-required'),
        },
      },
      pass: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('pass') }),
        },
        format: {
          with: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,}$/,
          message: i18n.t('pass-format'),
        },
      },
      passConfirmation: {
        match: {
          attr: 'pass',
          message: get(this, 'i18n').t('match-pass'),
        },
      },
      passOld: {
        presence: {
          message: get(this, 'i18n').t('current-pass-required'),
        },
      },
      eula: {
        custom: {
          validation(key, value, model) {
            return get(model, 'eula');
          },
          message: i18n.t('error.user.gdpr'),
        },
      },
    };
  }

  isEqual(other) { return Number(this.get('id')) === Number(other.get('id')); }
}
