export default {
  "error.residential.property.reference.required": "Reference is required",
  "leasing.centers": "Leasing centers",
  "development.energeticCertification": "Energetic certification",
  "management.files": "Files management",
  "acces_head": "Head of access",
  "error.file.is_dir.required": "",
  "add": "Add",
  "sale.name": "Name",
  "mail-template.type.sendContact": "",
  "mail-template.type.sendProposalsReport": "",
  "mail-template.type.sendReprintTickets": "",
  "mail-template.type.sendUserActivateEmail": "",
  "mail-template.type.sendUserChildBirthdayReminder": "",
  "mail-template.type.sendUserChildBirthdayVisitAck": "",
  "mail-template.type.sendUserRegistrationEmail": "",
  "mail-template.type.sendUserResetPasswordEmail": "",
  "email-template.select_template": "",
  "mail-template.type.send_contact": "",
  "mail-template.type.send_intranet_communication_email": "",
  "mail-template.type.send_proposals_report": "",
  "mail-template.type.send_reprint_tickets": "",
  "mail-template.type.send_user_activate_email": "",
  "mail-template.type.send_user_child_birthday_reminder": "",
  "mail-template.type.send_user_child_birthday_visit_ack": "",
  "mail-template.type.send_user_registration_email": "",
  "mail-template.type.send_user_reset_password_email": "",
  "day": "Day",
  "hour": "Hour",
  "minute": "Minute",
  "inbox": "Inbox",
  "sent": "Sent",
  "center.not_found": "Centres not found",
  "center.select_one": "Select one centre",
  "ok": "putabida",
  "segment.segmentName": "",
  "emailCampaign.endDatetime": "End datetime",
  "emailCampaign.name": "Name",
  "emailCampaign.not_found": "No email campaigns found",
  "emailCampaign.segment.segmentName": "Segment name",
  "emailCampaign.sentUsers": "Sent to users",
  "emailCampaign.startDatetime": "Start datetime",
  "emailCampaign.subject": "Subject",
  "date": "Date",
  "sale.commerce": "Shop",
  "sale.edit": "Edit sales",
  "sale.new": "New sale",
  "type_find_commerce": "Type to filter by shop...",
  "sales.not_found": "No shops found",
  "sales.shop_name": "",
  "communication.type.tabs.inbox": "Inbox",
  "communication.type.tabs.sent": "Sent",
  "concepts.airplane_tickets": "Airplane tickets",
  "concepts.coffee": "Coffee",
  "concepts.equipment": "Machinery (equipment)",
  "concepts.free_devices": "Free Devices",
  "concepts.general": "General",
  "concepts.magazines": "Newspaper and magazines",
  "concepts.pub": "Pub",
  "concepts.receipts": "Uploads / Invoices",
  "concepts.school_mat": "School Material",
  "concepts.telecommunications": "Services Telecommunications",
  "concepts.tobacco_game": "Tobacco and Game",
  "concepts.trans_currency": "Trans. Currency",
  "date.from": "From",
  "date.from-to": "From {{from}} to {{to}}",
  "date.to": "To",
  "email-campaign.date": "Date",
  "email-campaign.date.is-unique-sent": "Is unique sent",
  "email-campaign.edit": "Edit e-mail campaign",
  "email-campaign.end-date": "End date",
  "email-campaign.filter.active": "Active",
  "email-campaign.filter.archived": "Sent",
  "email-campaign.filter.draft": "Draft",
  "email-campaign.filter.scheduled": "Scheduled",
  "email-campaign.form.desing_template": "Design",
  "email-campaign.form.general_info": "General info",
  "email-campaign.form.summary": "Summary",
  "email-campaign.htmlBody.design": "Design your e-mail",
  "email-campaign.htmlBody.init_greeting": "Edit your e-mail here :)",
  "email-campaign.htmlBody.params": "E-mail params",
  "email-campaign.htmlBody.preview": "Preview",
  "email-campaign.name": "Name",
  "email-campaign.new": "New e-mail campaign",
  "email-campaign.not_found": "E-mail campaigns not found",
  "email-campaign.params.help.html": "Insert this codes to add vars in HTML template",
  "email-campaign.params.help.subject": "Insert this codes to add vars in e-mail subject",
  "email-campaign.params.html.center_name": "Add the centre name",
  "email-campaign.params.html.unsub_link": "Add a link to allow the user stop the notifications",
  "email-campaign.params.html.user_email": "Add the user e-mail",
  "email-campaign.params.html.user_name": "Add the user name",
  "email-campaign.params.subject.center_name": "Add the centre name",
  "email-campaign.params.subject.user_email": "Add the user e-mail",
  "email-campaign.params.subject.user_name": "Add the user name",
  "email-campaign.segment": "Segment",
  "email-campaign.segment.list": "Segment list",
  "email-campaign.segment.segmentName": "Segment name",
  "email-campaign.send.save_and_test": "Save and send test",
  "email-campaign.sentDatetime": "Sent date",
  "email-campaign.start-date": "Start date",
  "email-campaign.statistic.blocked": "Blocked",
  "email-campaign.statistic.bounced": "Bounced",
  "email-campaign.statistic.clicked": "Clicked",
  "email-campaign.statistic.delivered": "Delivered",
  "email-campaign.statistic.opened": "Opened",
  "email-campaign.statistic.spam": "Spam",
  "email-campaign.subject": "Subject",
  "error.email-campaign-template.htmlBody.required": "Campaign template can't be blank",
  "error.email-campaign-template.name.required": "Name can't be blank",
  "error.email-campaign.date.invalid_past": "Start date can't be in the past",
  "error.email-campaign.date.required": "Date can't be blank",
  "error.email-campaign.endDatetime.post_startDatetime": "End date must be after start date",
  "error.email-campaign.htmlBody.required": "Campaign template can't be blank",
  "error.email-campaign.name.required": "Name can't be blank",
  "error.email-campaign.segment.required": "Segment field can't be blank",
  "error.email-campaign.subject.required": "E-mail subject can't be blank",
  "menu.navbar.emailing": "Mailing",
  "menu.navbar.emailing.campaign.index": "Email campaigns",
  "menu.navbar.logout": "Logout",
  "sale.concept": "Concept sale",
  "save.datetime.cancel": "Cancel",
  "save.datetime.ok": "OK",
  "segment.not_found": "Segments not found",
  "segment.total_users_reached": "Total users reached",
  "segment.type_find": "Type to find segments",
  "segment.warning_update_users": "The campaign will be daily sent to new users that match the segment conditions",
  "send-email-template.help.multipleEmails": "Enter multiple e-mail addresses separated by commas",
  "tittle.header.emailing.campaigns": "Email campaigns",
  "email-campaign.endDatetime": "End date",
  "email-campaign.startDatetime": "Start date",
  "error.email-campaign.startDatetime.invalid_past": "",
  "email-campaign.scheduled": "",
  "email-campaign.sent": "",
  "error.email-campaign.segments.required": "",
  "segment.toal_users_reached": "",
  "sale.sale_concept": "",
  "confirm-modal.cancel": "Cancel",
  "confirm-modal.ok": "Accept",
  "email-campaign": "E-mail campaign",
  "email-campaign.htmlBody.file_import": "Import template",
  "form-email-campaign.error.import_file": "File format must be HTML",
  "form-email-campaign.warning.import-template": "You will replace the current template. Are you sure?",
  "wizard.next_step": "Next",
  "wizard.prev_step": "Previous",
  "tittle.header.crm.email-templates": "E-mail templates",
  "tittle.header.files": "Files management",
  "tittle.header.guards": "Guards",
  "tittle.header.sales": "Sales management",
  "tittle.header.subtypes": "Communication types",
  "tittle.communication.subtypes": "Communications subtypes",
  "Rechazar cierre de mes": "",
  "Especifica al responsable de tienda el motivo": "",
  "open.new-window.message": "Open in a new tab",
  "informative-message.upload-yardi.${state}": "",
  "menu.navbar.dashboard": "Dashboard",
  "end_of_construction": "End of construction",
  "in_construction": "In construction",
  "presale": "Presale",
  "previous": "Previous",
  "residential.provinces": "Provinces",
  "start_work": "Start work",
  "error.residential.property.price.required": "Price is required",
  "residential.qualities": "Qualities",
  "residential.services": "Services",
  "property.room.tag": "Tag",
  "open.new-tab.message": "Open in a new tab",
  "residential.development_multimedia_files": "",
  "user.address": "",
  "user.birthday": "Birthday",
  "user.children_number": "",
  "user.city": "",
  "user.code": "",
  "user.company": "",
  "user.countries": "",
  "user.cp": "",
  "user.created_at": "Registration date{{mandatory}}",
  "user.dni": "",
  "user.document_type": "",
  "user.email": "",
  "user.general_data": "",
  "user.last_name": "",
  "user.lopd_phrase": "I agree with the LOPD",
  "user.marital_status": "",
  "user.nacionality": "Nacionality",
  "user.name": "",
  "user.other_data": "",
  "user.person_type": "",
  "user.phone": "",
  "user.province": "",
  "user.select_document_type": "",
  "user.select_marital_status": "",
  "user.select_nacionality": "Select nacionality",
  "user.select_person_type": "",
  "user.select_province": "Select province",
  "user.select_sex": "",
  "user.sex": "",
  "users.general_data": "",
  "users.new": "",
  "users.other_data": "Other data",
  "crm.users.mstatus": "Marital status",
  "crm.users.person_type": "Person type",
  "crm.users.postal_code": "Postal code",
  "crm.users.sex": "Sex",
  "married": "Married",
  "user.created_at*": "",
  "user.campaign": "Campaign",
  "user.channel": "Channel",
  "user.select_campaign": "",
  "user.select_channel": "Select channel",
  "not_result_found": "No results found",
  "sale-process.add-interest": "Add request",
  "segment.save_segment": "Save segment",
  "segments.type_segment_name": "Type segment name...",
  "sale-process.interests": "Interests",
  "sale-process.negotiation": "Negotiation",
  "filter.not_found": "",
  "filter.type_find": "",
  "residential.responsible": "Responsible ",
  "residential.documentation-procedure": "Documentation procedure",
  "residential_offers.document-sent": "Documentation sent",
  "URL": "Url",
  "development.energyCertification": "Energy certification",
  "residential.select_certification": "",
  "address": "Address",
  "building": "Building",
  "categories": "Categories",
  "companies": "Companies",
  "company-reference-number": "Company reference number",
  "email": "Email",
  "error.commerce.building.required": "The building is required",
  "error.commerce.categories.required": "At least a category is required",
  "error.commerce.email.required": "Email is required",
  "error.commerce.fiscalName.required": "Fiscal name is required",
  "error.commerce.imageUrl.required": "Image url is required",
  "error.commerce.name.required": "Name is required",
  "error.commerce.required": "Commerce is required",
  "error.commerce.vatNumber.required": "Vat number is required",
  "error.commerce.zone.required": "Zone is required",
  "error.document-type.required": "Document type is required",
  "error.job-title.required": "Job title is required",
  "error.vat-number.required": "Vat number is required",
  "fiscal-name": "Fiscal name",
  "job-title": "Job title",
  "logo": "Logo",
  "menu.navbar.companies.show": "Companies",
  "new-company": "New company",
  "province": "Province",
  "select-building": "Select building",
  "select-company": "Select company",
  "select-zones": "Select zones",
  "user.valid_datetime": "Valid datetime",
  "vat-number": "Vat number",
  "zip-code": "Zip code",
  "zone": "Zone",
  "error.address.required": "Address is required",
  "error.building-state.required": "Building state is required",
  "error.built-surface.required": "Built surface is required",
  "error.city.required": "City is required",
  "error.commercialization.required": "Commercialization is required",
  "error.common-zones-surface.required": "Common zones surface is required",
  "error.currency.required": "Currency is required",
  "error.discharge-date.required": "Discharge date is required",
  "error.management.required": "Management is required",
  "error.name.required": "Name is required",
  "error.postal-code.required": "Postal code is required",
  "error.properties-number.required": "Properties number is required",
  "error.province.required": "Province is name_required",
  "error.residential-owner.required": "Owner is required",
  "error.unit-type.required": "Unit type is required",
  "error.useful-surface.required": "Useful surface is required",
  "error.visibility.required": "Visibility is required",
  "buildingState": "",
  "builtSurface": "",
  "commonZonesSurface": "",
  "dischargeDate": "",
  "postalCode": "",
  "propertiesNumber": "",
  "residentialOwner": "",
  "unitType": "",
  "usefulSurface": "",
  "attr.required": "Atribute required",
  "center": "Center",
  "combo": "Combo",
  "division": "Division",
  "error.leasing.unit.area.required": "Area is required",
  "error.leasing.unit.code.required": "Code is required",
  "error.leasing.unit.erv.required": "ERV is required",
  "error.leasing.unit.leasing-center.required": "Center is required",
  "error.leasing.unit.required": "Unit is required",
  "error.leasing.unit.service-charge.required": "Service charge is required",
  "error.leasing.unit.type.required": "Type is required",
  "error.leasing.units.2.units.required": "Units are required",
  "erv": "ERV",
  "lack-of-founds": "Lack of founds",
  "lack-of-franchisee": "Lack of franchisee",
  "leasing-opeartion.edit": "Edit",
  "leasing-opeartion.new": "New",
  "leasing-unit": "Unit",
  "leasing-unit.edit": "Edit leasing unit",
  "leasing-unit.new": "New leasing unit",
  "leasing-unit.select-type": "Select a type",
  "leasing-unit.type": "Type",
  "leasing-units": "Units",
  "leasing.advanced-negotiation": "Advanced negotiation",
  "leasing.agent": "Agent",
  "leasing.new-lease": "New lease",
  "leasing.operation": "Operation",
  "leasing.operation.approval-date": "Approval date",
  "leasing.operation.approved": "Approved",
  "leasing.operation.brochureSent": "Brochure sent?",
  "leasing.operation.comments": "Comments",
  "leasing.operation.first-contact-date": "First contact date",
  "leasing.operation.inspectionDone": "Inspection done",
  "leasing.operation.landlord-comments": "Landlord comments",
  "leasing.operation.last-contact-date": "Last contact date",
  "leasing.operation.leasing-brand": "Brand",
  "leasing.operation.leasing-center": "Center",
  "leasing.operation.leasing-unit": "Unit",
  "leasing.operation.negotiationType": "Negotiation type",
  "leasing.operation.rejection-reason": "Rejection reason",
  "leasing.operation.rent": "Rent",
  "leasing.operation.rentFreePeriodOnlyRent": "Rent free period (only rent)",
  "leasing.operation.rentFreePeriodRentSch": "Rent free period (Rent + Sch)",
  "leasing.operation.rentReduction": "Rent reduction",
  "leasing.operation.rentReductionYear1": "Year 1",
  "leasing.operation.rentReductionYear2": "Year 2",
  "leasing.operation.rentReductionYear3": "Year 3",
  "leasing.operation.rentReductionYear4": "Year 4",
  "leasing.operation.reporting-status": "Reporting status",
  "leasing.operation.select-leasing-brand": "Select brand",
  "leasing.operation.select-leasing-center": "Select center",
  "leasing.operation.select-leasing-unit": "Select unit",
  "leasing.operation.select-negotiationType": "Select negotiation type",
  "leasing.operation.select-rejection-reason": "Select rejection reason",
  "leasing.operation.select-reporting-status": "Select reporting status",
  "leasing.operation.select-staff-member": "Select leasing agent",
  "leasing.operation.select-status": "Select status",
  "leasing.operation.sendLandlord": "Send to landlord?",
  "leasing.operation.shoppingCenterVisited": "Shopping center visited",
  "leasing.operation.signing-date": "Signing date",
  "leasing.operation.staff-member": "Leasing agent",
  "leasing.operation.status": "Status",
  "leasing.operation.turn-over-rent": "Turn over rent",
  "leasing.unit": "Unit",
  "leasing.unit.area": "Area",
  "leasing.unit.center": "Center",
  "leasing.unit.code": "Code",
  "leasing.unit.country": "Country",
  "leasing.unit.erv": "ERV",
  "leasing.unit.filters": "Filters",
  "leasing.unit.leasing-center": "Leasing center",
  "leasing.unit.real-state-tax": "Real state tax",
  "leasing.unit.select_leasing_center": "Select leasing center",
  "leasing.unit.service-charge": "Service charge",
  "leasing.unit.type": "Type",
  "m2": "m2",
  "menu.navbar.leasing.operations": "Operations",
  "menu.navbar.leasing.units": "Units",
  "not-found": "Not found",
  "original": "Original",
  "rent": "Rent",
  "segments.save_new": "Save as new",
  "status": "Status",
  "title.header.leasing.units": "Units",
  "type-find": "Find type",
  "unit": "Unit",
  "unit-or-location-rejected": "Unit or location rejected",
  "unit-type": "Unit type",
  "dashboards-required": "Dashboards is required",
  "admin": "Admin",
  "centers": "Centers",
  "centers-required": "Centers is required",
  "confirm-password": "Confirm password",
  "contactable": "Contactable",
  "country-required": "Country is required",
  "create-staff-message": "SC Manager, Calibration and Operator should be created in",
  "crm-manager": "CRM Manager",
  "crm-operator": "CRM Operator",
  "dashboards": "Dashboards",
  "email-required": "Email is required",
  "external-api": "External API",
  "extra-info": "Extra info",
  "general-data": "General data",
  "last-name": "Last name",
  "lastName-required": "Last name is required",
  "level": "Level",
  "level-required": "Level is required",
  "manager": "Manager",
  "match-pass": "The password fields do not match",
  "name-required": "Name is required",
  "new-staff": "New staff",
  "owner": "Owner",
  "pass-required": "Password is required",
  "password": "Password",
  "residential-development.not_found": "Residential developments not found",
  "sc-manager": "SC Manager",
  "select-level": "Select level",
  "store-manager": "Store Manager",
  "warning-title": "Warning",
  "web-operator": "Web Operator",
  "error.leasing.center.country.required": "Country is required",
  "error.leasing.center.gla.required": "GLA is required",
  "error.leasing.center.name.required": "Name is required",
  "error.leasing.center.staff.required": "Porfolio manager is required",
  "error.leasing.center.type.required": "Type is required",
  "leasing.center.country": "Country",
  "leasing.center.name": "Name",
  "leasing.center.portfolio-manager": "Portfolio manager",
  "leasing.center.select_country": "Select a country",
  "leasing.center.select_manager": "Select a manager",
  "select-leasing-center-type": "Select center type",
  "booking_contract": "Booking contract",
  "development.address": "Address",
  "development.cadastral_reference": "Cadastral reference",
  "development.common_zones_surface": "Common zones Sur.",
  "development.estate": "Estate",
  "development.garages_number": "Garages number",
  "development.notes": "Description",
  "development.postalCode": "Postal code",
  "development.properties_number": "Properties number",
  "development.reference": "Reference",
  "development.responsible_email": "Email",
  "development.responsible_name": "Name",
  "development.responsible_position": "Position",
  "development.staff": "Staff managers",
  "development.storage_rooms_number": "Storage rooms number",
  "general_terms_of_online_contracting": "General terms of online contracting",
  "leasing.centers.name": "Name",
  "leasing.centers.total_units": "Total units",
  "payment_plan": "Payment plan",
  "purchaseAgreement": "Purchase agreement",
  "quality_specifications": "Quality specifications",
  "residential.building_state": "Building state",
  "residential.commercialization": "Commercialization",
  "residential.countries": "Countries",
  "residential.currency": "Currency",
  "residential.delivery_date": "Deilvery date",
  "residential.developer-contact": "Developer contact",
  "residential.development.location": "Location",
  "residential.discharge_date": "Discharge date",
  "residential.energy_certification": "Energy certification",
  "residential.general_data": "General data",
  "residential.general_info": "General info",
  "residential.location": "Location",
  "residential.management": "Management",
  "residential.other_data": "Other data",
  "residential.owner": "Owner",
  "residential.owner_code": "Owner code",
  "residential.property-types": "Types",
  "residential.province": "Province",
  "residential.select_building_state": "Select building state",
  "residential.select_commercialization": "Select commercialization",
  "residential.select_currency": "Select currency",
  "residential.select_energy_certification": "Select an energy certification",
  "residential.select_management": "Select management",
  "residential.select_owner": "Select owner",
  "residential.select_province": "Select province",
  "residential.select_unit_type": "Select unit type",
  "residential.services_and_qualities": "Services and qualities",
  "residential.technical_data": "Techical data",
  "residential.unit_type": "Unit type",
  "residential.video": "Video",
  "select-visibility": "Select visibility",
  ".estate": "",
  "dossiers": "Dossiers",
  "garage-map": "Garage plans",
  "how-to-arrive-map": "Plan \"How to arrive\"",
  "maps": "Maps",
  "notes": "Description",
  "storage-map": "Storage plans",
  "leasing.country": "Country",
  "error.user.lopd": "The LOPD should be accepted",
  "informative-message.change-password.error.message": "Invalid password or invalid format",
  "error.residential.property.name.required": "Name is required",
  "error.residential.property.rental.required": "Rental price is required",
  "error.residential.property.residentialDevelopment.required": "Development is required",
  "error.residential.property.residentialPropertyType.required": "Type is required",
  "error.residential.property.state.required": "State is required",
  "generic.error.message": "An error has occurred",
  "other-tabs-error-message": "There are errors in detailed spaces",
  "error.user.gdpr": "The GPDR should be accepted",
  "want_save_without_electronic_communications": "Do you really want to save the user without allowing electronic communications?",
  "user.electronic_communications_phrase": "I wish to receive electronic commercial communications",
  "want_save_without_gdpr_or_electronic_communications": "The terms and conditions or the electronic communications checks aren’t checked, do you wish to continue?",
  "want_save_without_gdpr_or_partners_electronic_communications": "The terms and conditions or the electronic communications from thirds checks aren't checked, do you wish to continue?",
  "from-signing-date": "From signing date",
  "ECOP": "",
  "release-notes": "Release notes",
  "leasing.activity": "Activity",
  "residential-owner.not_found": "Residential owners not found",
  "available-in": "Available in",
  "ecop": "ECOP",
  "email-template.type.send_staff_reset_password_email": "Reset staff's password reminder",
  "leasing": "Leasing",
  "reset-password": "Reset password",
  "menu.navbar.leasing": "Leasing",
  "title.header.leasing.works": "Works",
  "tittle.header.configuration": "Configuration",
  "tittle.header.configuration.dashboard": "Dashboard configuration",
  "visits": "Visits",
  "no-data": "",
  "select-fit-out-coordinator": "Select fit-out coordinator",
  "tittle.header.dashboards.show": "Dashboard",
  "invoices": "Invoices",
  "title.header.crm.children": "Children",
  "property.user": "User",
  "property.${dasherize()}": "",
  "attach_image": "Attach image",
  "history": "History",
  "menu.navbar.leasing-ecop": "Leasing / ECOP",
  "children.exit-notes": "Exit notes",
  "children.register-exit": "Register exit",
  "entry-modal.register": "Register",
  "last-year-period": "Same period last year",
  "check-out": "",
  "property": "Property",
  "residential_property": "Property",
  "news": "News",
  "exit-children": "Exit children",
  "dashboards.frequency": "Visit Recurrence",
  "informative-message.default.error.message": "An error occurred. Please try again later",
  "informative-message.default.error.title": "Error",
  "informative-message.default.succes.title": "Success",
  "informative-message.default.success.message": "The operation was executed succesfully.",
  "person_type": "Person type",
  "physical_person": "Physical person",
  "residential_proposal": "Proposal",
  "dashboards.competition-dwell-time": "Dwell Time Competition",
  "dashboards.competition-recurrence": "Visit Recurrence Competition",
  "dashboards.competition-visits": "Visits Competition",
  "leasing.brands.see-contacts": "Go to contacts",
  "leasing.center.logo": "Logo",
  "leasing.center.attach-logo": "Attach logo (png, jpeg, gif or jpg)",
  "user.user-types": "User types",
  "error.child.last-name.required": "Last name is required",
  "dashboards.age": "Age",
  "dashboards.mail-notifications": "Mail notifications",
  "dashboards.postal-code": "Postal code",
  "dashboards.user-typology": "User typology",
  "dashboard.print-instructions": "Recommended: use Landscape layout, enable background graphics and disable margins to better output",
  "dashboard.print-loading-charts": "Loading charts...",
  "title.header.marketing-analytics.campaign-types": "Campaign types",
  "menu.navbar.marketing-analytics.campaingtype.index": "Campaign type",
  "users.receipt": "",
  "receipt.date": "",
  "error.tickets.code.required": "Code is required",
  "Webactiva": "WebActiva",
  "leasing.operations.create-work": "Create work",
  "select-default-building": "Select a default building",
  "enabled": "Enabled",
  "close": "Close",
  "next_month": "Next month",
  "previous_month": "Previous month",
  "today": "Today",
  "close_months": "Close months",
  "communication.type.offers": "Offers",
  "residential-offer.info_sent": "Info sent",
  "residential-offer.sent_info_other": "Other",
  "closed_previous_month": "Close all previous months",
  "communication.not_found": "No communications found",
  "error.leasing.brand.country.required": "Country is required",
  "leasing.brand.country": "Country",
  "leasing.brand.select-country": "Select a country",
  "services-charges": "Services charges",
  "GLA-food-court-terraces": "GLA food court terraces",
  "GLA-hiper/supermarket": "GLA ",
  "GLA-leisure": "GLA leisure",
  "GLA-offices": "GLA office",
  "GLA-others": "GLA others",
  "GLA-restaurants": "GLA restaurants",
  "GLA-retail": " GLA retail",
  "GLA-terraces": "GLA terraces",
  "GLA-warehouse": "GLA warehouse",
  "Others": "Others",
  "P1": "P1",
  "P2": "P2",
  "P3": "P3",
  "P4": "P4",
  "P5": "P5",
  "P6": "P6",
  "access-tariff": "Access tariff",
  "accesses": "accesses",
  "asset": "Asset",
  "bicycle": "Bicycle",
  "brand/model": "Brand/model",
  "breastfeeding-rooms": "Breastfeeding rooms",
  "breeam-certification": "Breeam certification",
  "cbre-people": "CBRE people",
  "cctv": "CCTV",
  "cctv-cameras": "CCTV cameras",
  "cctv-ip-cameras": "CCTV ip cameras",
  "certificactions": "Certifications",
  "complaints-filed-to-police": "Field to police",
  "compost": "Compost",
  "contract-number": "Contract number",
  "conventional": "Conventional",
  "covered": "Covered",
  "cups": "Cups",
  "distributor": "Distributor",
  "disturbance": "Disturbance",
  "doors": "Doors",
  "electric-supply": "Electric supply",
  "energy-performance-certificate-epc": "Energy performance certificate (EPC)",
  "escalators": "Escalators",
  "expiration-date": "Expiration date",
  "expulsions": "Expulsions",
  "family-boxes": "Family boxes",
  "footfall": "Footfall",
  "free-parking": "Free parking",
  "gas-supply": "Gas supply",
  "glass": "Glass",
  "green-areas": "Green areas",
  "green-energy-100-certified": "Green energy 100 certified",
  "handicapped": "Handicapped",
  "handicapped-boxes": "Handicapped boxes",
  "iso-14001-certification": "ISO 14001 certification",
  "iso-22320-certification": "ISO 22320 certification",
  "iso-9001-certifcation": "ISO 9001 certification",
  "kiosks": "Kiosks",
  "led-lighting": "Led lighting",
  "lifts": "Lifts",
  "lighting-indoor-common-areas": "Lighting indoor common areas",
  "lighting-outdoor-common-areas": "Lighting outdoor common areas",
  "lighting-outdoor-parking": "Lighting outdoor parking",
  "lighting-underground-parking": "Lighting underground parking",
  "loading-docks": "loading docks",
  "management-team-on-site": "Management team on site",
  "men-boxes-urinaries": "Men boxes urinaries",
  "meter-number": "Meter number",
  "motorcycle": "",
  "number": "Number",
  "number-opening-days": "",
  "organic": "",
  "oshas-180001-certifcation": "",
  "other-certificates": "",
  "other-special": "",
  "other-vertical-transportation-devices": "",
  "outdoor-parking": "",
  "outlet-full-price": "",
  "panoramic-lifts": "",
  "paperboard": "",
  "parking": "",
  "parking-canopies": "",
  "parking-slots": "",
  "pedestrian": "",
  "pedestrians": "",
  "plastic": "",
  "power-indoor-common-areas": "",
  "product": "",
  "pruning": "",
  "recycled-waste": "Recycled waste",
  "recycledWaste": "Recycled waste",
  "revolving": "Revolving",
  "roof": "Roof",
  "seccurity-incidents": "Seccurity incidents",
  "select-product": "Select product",
  "select-smartcx": "Select Smartcx",
  "service-lifts": "Service lifts",
  "sliding": "Sliding",
  "smart-parking": "Smart parking",
  "smartcx": "Smartcx",
  "solid-urban-waste": "Solid urban waste",
  "specialty-leasing": "Specialty leasing",
  "supply-pressure": "Supply pressure",
  "supply-voltage": "Supply voltage",
  "surface": "Surface (m2)",
  "surfaces": "Surfaces",
  "tesla-electric-charger": "Tesla electric charger",
  "thefts": "Thefts",
  "tilting": "Tilting",
  "toilets": "Toilets",
  "toilets-number": "Toilets number",
  "toilets-surface": "Toilets surface",
  "total-automatic": "Total automatic",
  "total-car": "Total car",
  "total-common-areas": "Total common areas",
  "total-gla": "Total GLA",
  "total-parking-areas": "Total parking areas",
  "total-people": "Total people",
  "total-security-incidents": "Total security incidents",
  "total-vertical-transportation-devices": "Total vertical transportation devices",
  "total-water": "Total water",
  "tourism": "Tourism",
  "travelators": "Travelators",
  "uncovered": "Uncovered",
  "underground-parking": "Underground Parking",
  "une-170001-certification": "UNE 170001 certification",
  "units": "Units",
  "universal-electric-charger": "Universal electric charger",
  "vandalism": "Vandalism",
  "vehicles": "Vehicles",
  "vertical-transportation": "Vertical transportation",
  "waste-management": "Waste management",
  "water-common-areas": "Water common areas",
  "water-fountains": "Water fountains",
  "water-hvac": "Water HVAC",
  "water-irrigation": "Water irrigation",
  "water-others": "Water others",
  "water-supply": "Water supply",
  "women-boxes": "Women boxes",
  "wood": "Wood",
  "user.userTypes": "User types",
  "end_date": "End date",
  "end_datetime": "End datetime",
  "marketing-analytics.kpi.average-price-receipt": "Event - Average price receipt",
  "marketing-analytics.kpi.average_price_receipt.tooltip": "Average value of the expenses recorded in receipts during the campaign for each contestant",
  "marketing-analytics.kpi.contestants.tooltip": "Number of contestants at the event",
  "marketing-analytics.kpi.coupon-percent.tooltip": "Redeemed coupons percentage of the offered coupon",
  "marketing-analytics.kpi.new_registered_users.tooltip": "Number of new registered users",
  "marketing-analytics.kpi.percent-new-registered-users": "Event - New Registered Users(%)",
  "marketing-analytics.kpi.percent-new-registered-users.tooltip": "New registered users percentage over previous registered users",
  "marketing-analytics.kpi.previous_registered_users.tooltip": "Number of previous registered users",
  "marketing-analytics.kpi.total_coupons.tooltip": "Number of redeemed coupons",
  "marketing-analytics.kpi.total_receipts.tooltip": "Number of registered receipts",
  "max": "Max",
  "min": "Min",
  "start_date": "Start date",
  "start_datetime": "Start datetime",
  "kpitech.annual-amount": "Annual amount",
  "pass": "Password",
  "pass-modify": "You need to change the password because you changed the email",
  "cms.commerce.general_data": "General data",
  "marketing-analytics.campaign.image": "Campaign image",
  "user.select_nationality": "Select Nationality",
  "campaign-rule.label": "Label",
  "campaign-rule.new": "New campaign rule",
  "campaign-rule.ticket-amount": "Ticket amount",
  "marketing-analytics.campaign.raffle.digital": "Digital",
  "marketing-analytics.campaign.raffle.general_data": "General data",
  "marketing-analytics.campaign.raffle.raffle-code": "Raffle code",
  "marketing-analytics.campaign.raffle.rules": "Raffle exceptions",
  "marketing-analytics.campaign.raffle.ticket-amount": "Ticket amount",
  "title.header.marketing-analytics.campaignRules": "Raffle exceptions",
  "edit-company": "Edit company",
  "add kpis": "Manual Kpis",
  "leasing.operations.generate-contract": "Generate contract",
  "marketing-analytics.kpi.kpis-manuales": "Add Kpis Manuales",
  "sales.back": "Back",
  "sales.csv-error-format": "The file doesn't have a valid format. Please, review the file in order to follow the instructions.",
  "sales.csv-format": "The CSV should have the same format than the csv exported. It should follow the next format:",
  "sales.csv-sales-with-errors": "Some of the sales in the CSV didn't get imported. Please, review the CSV data.",
  "sales.csv.amount-format": "The amount should be a number with a comma (',') to separate the decimals",
  "sales.csv.columns": "It should have four columns, with the date on the first column and amount on the fourth",
  "sales.csv.date-format": "The date should be year-month-day with numbers",
  "sales.csv.separators": "The columns should be separted by commas (',')",
  "sales.error": "Error",
  "sales.import-csv": "Select CSV",
  "sales.net": "* Net sales (VAT not included)",
  "sales.new": "Add new sales",
  "sales.select_a_shop": "Select a shop to add a new sale",
  "sales.select_valid_date": "You can't add sales for future dates. Please, select a valid date in the calendar",
  "sales.shops_not_found": "Select a shop to add a new sale",
  "warning_enter_sales": "Certificate of net sales (without VAT) made in the reference shop during the indicated period of time, which accurately match the supporting documents and accounting notes in the company records",
  "marketing-analytics.campaign.submit-image": "Submit image",
  "porposal.app-channel-image": "Add app channel image",
  "porposal.web-channel-image": "Add web channel image",
  "error.proposal.afterDate": "AfterDate is required",
  "error.proposal.endDate.required": "EndDate is required",
  "error.proposal.eventEndDate.required": "Event endDate is required",
  "residential.development..province-search": "Search province",
  "sale.not_found": "No sales found",
  "error.proposal.title.required": "Title is required",
  "title": "title",
  "flows.cars": "Cars",
  "flows.cinemas": "Cinemas",
  "flows.footfall": "Footfall",
  "flows.import-xlsx": "Import XLSX",
  "flows.info": "Info",
  "flows.xlsx-format": "The XLSX should have the following format:",
  "flows.xlsx.columns": "It should have four columns, with the date on the first column, footfall in the second column, cars on the third, and cinemas on the fourth. In the first row it should be the name of the column.",
  "flows.xlsx.date-format": "The date should be set in Excel with type 'date'.",
  "flows.xlsx.download-example": "Download Template",
  "flows.xlsx.values-format": "The date should be set in Excel with type 'number'.",
  "intranet.flows.import": "Import",
  "intranet.flows.import-flows": "Import flows",
  "title.header.flows": "Flows management",
  "menu.navbar.flows": "Flows",
  "receipt.campaigns": "Campaigns",
  "receipt.receipts": "Receipts",
  "users.tickets": "Tickets",
  "submit-image": "Campaign Image",
  "marketing-analytics.campaign.is-loyalty-card": "Is loyalty card",
  "marketing-analytics.campaign.is-raffle": "Is raffle",
  "leasing.contact.country": "Country",
  "leasing.contact.select_country": "Select a country",
  "receipts.generate-tickets": "Generate tickets",
  "tickets.can-not-generate-message": "The user doesn't have any receipt that can be redeemed.",
  "receipt.stamps-value": "Stamps value",
  "marketing-analytics.campaigns.raffle": "Loyalty",
  "error.file.size.invalid": "File size is not valid",
  "error.file.type.extension-allowed": "File type not allowed. Extensions allowed: jpg, jpeg, gif, png, xls, xlsx, doc, docx and pdf",
  "file": "File",
  "file.description_optional": "Description (optional)",
  "file.not_found": "No files found",
  "folder.warning_delete": "Deleting this folder will remove all elements in it",
  "new_name": "New name",
  "Center": "Center",
  "Store": "Store",
  "proposal.app-channel": "App channel",
  "proposal.channel": "Channel",
  "proposal.channel-web": "Channel web",
  "proposal.configuration": "Configuration",
  "proposal.personalized-target": "Personalized target",
  "dashboards.weekly.app-downloads": "App Downloads",
  "error.tickets.price.required": "Price is required",
  "concepts.medical-receipt": "Medical receipts",
  "title.header.cms.commerces": "Stores",
  "code": "Code",
  "dashboards.weekly.visitor-profile": "Visitor profile",
  "title.header.commerce": "Commerces",
  "title.header.cms.commerce": "Commerce",
  "dashboards.weekly.annual-downloads": "Annual downloads (Week)",
  "dashboards.weekly.dwell-chart": "Annual dwell time (Week)",
  "dashboards.weekly.dwell-time": "Dwell time",
  "dashboards.weekly.user-profile-average": "Year average",
  "dashboards.weekly.user-profile-week": "Selected week",
  "dashboards.weekly.year-comparative": "Annual average",
  "file.roles.owner": "Owner",
  "proposal.app-channel-image": "Add app channel image",
  "proposal.web-channel-image": "Add web channel image",
  "tickets.price": "Price",
  "users.price": "Price",
  "cms-proposal.active": "Active",
  "cms-proposal.draft": "Draft",
  "cms-proposal.expired": "Expired",
  "cms-proposal.pending": "Pending",
  "cms-proposal.scheduled": "Scheduled",
  "error.proposal.gender.required": "Gender is required",
  "proposal.custom-target": "Custom target",
  "proposal.other-data": "Other data",
  "crm.users": "Costumers",
  "crm.user.delete-success-message": "The contact has been deleted correctly",
  "child.add-user-dni": "Go to user profile",
  "menu.navbar.customer.new": "New customer",
  "GLA": "GLA",
  "High Street": "High Street",
  "Logistics": "Logistics",
  "Offices": "Office",
  "Outlet centre": "Outlet center",
  "Retail Park": "Retail Park",
  "Shopping centre": "Shopping center",
  "TPWC": "TPWC (Team for Private Works Coordination)",
  "additional-guarantee": "Additional guarantee",
  "approved-date": "Approved date",
  "approved-documentation": "Approved documentation",
  "apr": "Apr",
  "as-built-documentation": "As built documentation",
  "aug": "Aug",
  "before-starting-works": "Before starting works",
  "billing": "Billing",
  "brand": "Brand",
  "budget": "Budget",
  "business-civilliability-insurance": "Business civilliability insurance",
  "business-license": "Business license",
  "business-license-approval": "Business license approval",
  "business-license-request": "Business license request",
  "car-insurance": "C.A.R. insurance",
  "ceiling": "Ceiling",
  "civilliability-insurance": "Civilliability insurance",
  "client": "Client",
  "contact-people": "Contact people",
  "contract-Type": "Contract Type",
  "contractor": "Contractor",
  "convenience": "Convenience",
  "data-sheet": "Data sheet",
  "dec": "Dec",
  "delivery": "Delivery",
  "economic-conditions": "Economic conditions",
  "ecop-work.edit": "Edit work",
  "ecop-work.new": "New work",
  "ecop.work": "Work",
  "electricity": "Electricity",
  "enclosure-work": "Enclosure of work",
  "error.commerce.kpiTechServices.required": "A service is required",
  "error.leasing.brand.activity.required": "Activity is required",
  "error.leasing.brand.name.required": "Name is required",
  "error.leasing.brand.optimal-area.required": "Optimal area is required",
  "error.leasing.contact.brand.required": "Brand is required",
  "error.leasing.contact.country.required": "Country is required",
  "error.leasing.contact.email.required": "Email is required",
  "error.leasing.contact.last-name.required": "Last name is required",
  "error.leasing.contact.mobile-phone.required": "Mobile phone is required",
  "error.leasing.contact.name.required": "Name is required",
  "error.leasing.unit.category.required": "Category is required",
  "expansion-process-stopped": "Expansion process stopped",
  "experience": "Experience",
  "external-collaborator-fee": "External collaborator fee",
  "facade-template-approval-date": "Facade template approval date",
  "facade-template-delivery-date": "Facade template delivey date",
  "factory-outlet": "Factory outlet",
  "family-&-fun": "Family & fun",
  "feb": "Feb",
  "final-audit": "Final audit",
  "fire-detection": "Fire detection",
  "fire-extinction": "Fire extinction",
  "first-invoice-amount": "First invoice amount",
  "first-invoice-date": "First invoice date",
  "fit-out-coordinator": "Fit-out coordinator",
  "fit-out-coordinator-cbre": "fit-out CBRE coordinator",
  "fit-out-coordinator-external": "fit-out external coordinator",
  "fit-out-guarantee": "Fit out guarantee",
  "fit-out-guarantee-return": "Fit-out guarantee return",
  "forecast-date": "Forecast date",
  "from-starting-date": "From starting date",
  "full-price": "Full price",
  "general": "General",
  "gla": "GLA",
  "hotel": "Hotel",
  "hs-plan": "H & S plan",
  "hvac": "HVAC",
  "invoice-amount": "Invoice amount",
  "jan": "Jan",
  "jul": "Jul",
  "jun": "Jun",
  "kpi-tech-supervisor": "KPI Tech supervisor",
  "kpi-tech-user": "KPI Tech user",
  "kpi-tech.change-year-warning": "Unsaved changes will be lost, are you sure you want to continue?",
  "kpi-tech.close-month-warning": "kpis and services charges of the selected month will be closed",
  "kpi-tech.see-data-sheet": "Data sheet",
  "kpis": "KPIs",
  "kpitech.GLA": "GLA",
  "kpitech.GLA-food-court-terraces": "GLA food court terraces",
  "kpitech.GLA-hiper/supermarket": "GLA hiper/supermarket",
  "kpitech.GLA-leisure": "GLA leisure",
  "kpitech.GLA-offices": "GLA offices",
  "kpitech.GLA-others": "GLA others",
  "kpitech.GLA-restaurants": "GLA restaurants",
  "kpitech.GLA-retail": "GLA retail",
  "kpitech.GLA-terraces": "GLA terraces",
  "kpitech.GLA-warehouse": "GLA warehouse",
  "kpitech.Others": "Others",
  "kpitech.P1": "P1",
  "kpitech.P2": "P2",
  "kpitech.P3": "P3",
  "kpitech.P4": "P4",
  "kpitech.P5": "P5",
  "kpitech.P6": "P6",
  "kpitech.access-tariff": "Access tariff",
  "kpitech.accesses": "Accesses",
  "kpitech.annual-ammount": "Annual amount",
  "kpitech.asset": "asset",
  "kpitech.automatic-door-maintenance": "Automatic door maintenance",
  "kpitech.bicycle": "Bicycle",
  "kpitech.bms": "BMS",
  "kpitech.boilers": "Boilers",
  "kpitech.brand/model": "Brand/model",
  "kpitech.breastfeeding-rooms": "Breastfeedign rooms",
  "kpitech.breeam-certification": "Breeam certification",
  "kpitech.business-activity-coordination": "Business activity coordination",
  "kpitech.cbre-people": "CBRE people",
  "kpitech.cctv": "CCTV",
  "kpitech.cctv-cameras": "CCTV cameras",
  "kpitech.cctv-ip-cameras": "CCTV ip cameras",
  "kpitech.certificactions": "Certifications",
  "kpitech.city": "City",
  "kpitech.cleaning": "Cleaning",
  "kpitech.cluster": "Cluster",
  "kpitech.cmms": "CMMS",
  "kpitech.co2-detection-maintenance": "CO2 Detection maintenance",
  "kpitech.complaints-filed-to-police": "Complaints field to police",
  "kpitech.compost": "Compost",
  "kpitech.consumables": "Consumables",
  "kpitech.contract-number": "Contract number",
  "kpitech.contractor": "Contractors/Vendors",
  "kpitech.contractor.edit": "Edit",
  "kpitech.contractor.new": "New",
  "kpitech.contractor.services": "Services",
  "kpitech.conventional": "Conventional",
  "kpitech.country": "Country",
  "kpitech.covered": "Covered",
  "kpitech.crane-maintenance": "Crane maintenance",
  "kpitech.cups": "Cups",
  "kpitech.distributor": "Distributor",
  "kpitech.disturbance": "Disturbance",
  "kpitech.doors": "Doors",
  "kpitech.ducts-cleaning": "Ducts cleaning",
  "kpitech.electric-supply": "Electric supply",
  "kpitech.emergency-drill": "Emergency drill",
  "kpitech.ems": "EMS",
  "kpitech.energy-performance-certificate-epc": "Energy performance certificate EPC",
  "kpitech.escalators": "Escalators",
  "kpitech.evacuation-drill": "Evacuation drill",
  "kpitech.expiration-date": "Expiration date",
  "kpitech.expulsions": "Expulsions",
  "kpitech.family-boxes": "Family boxes",
  "kpitech.fire-detection-maintenance": "Fire detection maintenance",
  "kpitech.fire-protection": "Fire protection",
  "kpitech.fire-protection-maintenance": "Fire protection maintenance",
  "kpitech.footfall": "Footfall",
  "kpitech.footfall-maintenance": "Footfall maintenance",
  "kpitech.fountains-maintenance": "Fountains maintenance",
  "kpitech.free-parking": "Free parking",
  "kpitech.gardening": "Gardening",
  "kpitech.gardening-special-works": "Gardening special works",
  "kpitech.gas-supply": "Gas supply",
  "kpitech.general-maintenance": "General maintenance",
  "kpitech.gla-error": "GLA Must match with GLA field of Asset section",
  "kpitech.glass": "Glass",
  "kpitech.green-areas": "Green areas",
  "kpitech.green-energy-100-certified": "Green energy 100 certified",
  "kpitech.handicapped": "Handicapped",
  "kpitech.handicapped-boxes": "Handicapped boxes",
  "kpitech.high-voltage": "High voltage",
  "kpitech.high-voltage-maintenance": "High voltage maintenance",
  "kpitech.hours-per-year": "Hours per year",
  "kpitech.hours-per-year-remote-security-center": "Hours per year remote security center",
  "kpitech.hours-per-year-security-assistants": "Hours per year security assistants",
  "kpitech.hours-per-year-security-guards": "Hours per year security guards",
  "kpitech.hvac": "HVAC",
  "kpitech.hvac-maintenance": "HVAC maintenance",
  "kpitech.ibi": "IBI",
  "kpitech.iso-14001-certification": "ISO 14001 certification",
  "kpitech.iso-22320-certification": "ISO 22320 certification",
  "kpitech.iso-9001-certifcation": "ISO 9001 certification",
  "kpitech.kW-power": "KW power",
  "kpitech.kiosks": "Kiosks",
  "kpitech.led-lighting": "Led lighting",
  "kpitech.legal-maintenance-expiring-dates": "Legal maintenance expiring dates",
  "kpitech.legionella-maintenance": "Legionella maintenance",
  "kpitech.lifelines-maintenance": "Lifelines maintenance",
  "kpitech.lifts": "Lifts",
  "kpitech.lighting-indoor-common-areas": "Lighting indoor common areas",
  "kpitech.lighting-outdoor-common-areas": "Lighting outdoor common areas",
  "kpitech.lighting-outdoor-parking": "Lighting outdoor parking",
  "kpitech.lighting-rods-maintenance": "Lighting rods maintenance",
  "kpitech.lighting-underground-parking": "Lighting underground parking",
  "kpitech.loading-docks": "Loading docks",
  "kpitech.loading-docks-maintenance": "Loading docks maintenance",
  "kpitech.low-voltage": "Low voltage",
  "kpitech.lrp": "LRP",
  "kpitech.machinery": "Machinery",
  "kpitech.maintenance": "Maintenance",
  "kpitech.management-team-on-site": "Management team on site",
  "kpitech.maximum-number-of-cleaners-per-shift": "Maximum number of cleaners per shift",
  "kpitech.maximum-number-of-gardeners-per-shift": "Maximum number of gardeners per shift",
  "kpitech.maximum-number-of-security-assistants-per-shift": "Maximum number of security assistants per shift",
  "kpitech.maximum-number-of-security-guards-per-shift": "Maximum number of security guards per shift",
  "kpitech.maximum-number-of-technicians-per-shift": "Maximum number of technicians per shift ",
  "kpitech.men-boxes-urinaries": "Men boxes urinaries",
  "kpitech.meter-number": "Meter number",
  "kpitech.motorcycle": "Motorcycle",
  "kpitech.multiannual-plan": "Multiannual plan",
  "kpitech.name": "Name",
  "kpitech.no-parking-sign": "No parking sign",
  "kpitech.notice-date": "Notice date",
  "kpitech.number": "Number",
  "kpitech.number-of-cleaners": "Number of cleaners",
  "kpitech.number-of-gardeners": "Number of gardeners",
  "kpitech.number-of-security-assistants": "Number of security assistants",
  "kpitech.number-of-security-guards": "Number of security guards",
  "kpitech.number-of-technicians": "Number of technicians",
  "kpitech.number-opening-days": "Number opening days",
  "kpitech.organic": "Organic",
  "kpitech.oshas-180001-certifcation": "Oshas 180001 certification",
  "kpitech.other-certificates": "Other certificates",
  "kpitech.other-special": "Other special",
  "kpitech.other-vertical-transportation-devices": "Other vertical transportation devices",
  "kpitech.others": "Others",
  "kpitech.outdoor-parking": "Outdoor parking",
  "kpitech.outlet-full-price": "Outlet full price",
  "kpitech.owner": "Owner",
  "kpitech.pa-system-maintenance": "PA system maintenance",
  "kpitech.panoramic-lifts": "Panoramic lifts",
  "kpitech.paperboard": "Paperboard",
  "kpitech.parking": "Parking",
  "kpitech.parking-access-control-system-maintenance": "Parking access control system maintenance",
  "kpitech.parking-canopies": "Parking canopies",
  "kpitech.parking-slots": "Parking slots",
  "kpitech.pedestrian": "Pedestrian",
  "kpitech.pedestrians": "Pedestrians",
  "kpitech.performance": "Performance",
  "kpitech.pest-control": "Pest control",
  "kpitech.photovoltaic-solar-system-maintenance": "Photovoltaic solar system maintenance",
  "kpitech.plants-replacement": "Plants replacement",
  "kpitech.plastic": "Plastic",
  "kpitech.power-generator-maintenance": "Power generator maintenance",
  "kpitech.power-indoor-common-areas": "Power indoor common areas",
  "kpitech.product": "Product",
  "kpitech.pruning": "Pruning",
  "kpitech.recycled-waste": "Recycled waste",
  "kpitech.recycledWaste": "Recycled waste",
  "kpitech.remote-alarm-center": "Remote alarm center",
  "kpitech.remote-security-center": "Remote security center",
  "kpitech.revolving": "Revolving",
  "kpitech.roof": "Roof",
  "kpitech.seccurity-incidents": "Security incidents",
  "kpitech.security": "Security",
  "kpitech.security-assistants": "Security assistants",
  "kpitech.security-facilities-maintenance": "Security facilities maintenance",
  "kpitech.security-guards": "Security guards",
  "kpitech.security-technical-equipment": "Security technical equipment",
  "kpitech.select": "Select",
  "kpitech.select-performance": "Select performance",
  "kpitech.select-product": "Product",
  "kpitech.select-smartcx": "Smartcx",
  "kpitech.self-protection-plan": "Self protection plan",
  "kpitech.service-charges": "Service charges ",
  "kpitech.service-lifts": "Service lifts",
  "kpitech.sewer-cleaning": "Swerer cleaning",
  "kpitech.sliding": "Sliding",
  "kpitech.smart-parking": "Smart parking",
  "kpitech.smart-parking-maintenance": "Smart parking maintenance",
  "kpitech.smoke-evacuation-system-maintenance": "Smoke evacuation system maintenance",
  "kpitech.solid-urban-waste": "Solid urban waste",
  "kpitech.special-cleanings": "Special cleanings",
  "kpitech.specialty-leasing": "Specialty leasing",
  "kpitech.supplier": "Supplier",
  "kpitech.supply-pressure": "Supply pressure",
  "kpitech.supply-voltage": "Supply voltage",
  "kpitech.surface": "Surface",
  "kpitech.surfaces": "Surfaces",
  "kpitech.system": "System",
  "kpitech.taxes": "Taxes",
  "kpitech.tesla-electric-charger": "Tesla electric charger",
  "kpitech.thefts": "Thefts",
  "kpitech.thermal-solar-system-maintenance": "Thermal solar system maintenance",
  "kpitech.tilting": "Tilting",
  "kpitech.toilets": "Toilets",
  "kpitech.toilets-number": "Toilets number",
  "kpitech.toilets-surface": "Toilets surface",
  "kpitech.total": "Total",
  "kpitech.total-annual-ammount": "Total annual ammount",
  "kpitech.total-automatic": "Total automatic",
  "kpitech.total-common-areas": "Total common areas",
  "kpitech.total-gla": "Total GLA",
  "kpitech.total-parking-areas": "Total parking areas",
  "kpitech.total-people": "Total people",
  "kpitech.total-security-amount": "Total security amount",
  "kpitech.total-security-cameras": "Total security cameras",
  "kpitech.total-security-incidents": "Total security incidents",
  "kpitech.total-vertical-transportation-devices": "Total vertical transportation devices",
  "kpitech.total-water": "Total water",
  "kpitech.tourism": "Tourism",
  "kpitech.travelators": "Travelators",
  "kpitech.uncovered": "Uncovered",
  "kpitech.underground-parking": "Underground parking",
  "kpitech.une-170001-certification": "UNE 170001 certification",
  "kpitech.units": "Units",
  "kpitech.universal-electric-charger": "Universal electric charger",
  "kpitech.ups-maintenance": "UPS maintenance",
  "kpitech.urban": "Urban",
  "kpitech.vandalism": "Vandalism",
  "kpitech.vehicles": "Vehicles",
  "kpitech.vertical-transportation": "Vertical transportation",
  "kpitech.vertical-transportation-maintenance": "Vertical transportation maintenance",
  "kpitech.waste-management": "Waste management",
  "kpitech.waste-rate": "Waste rate",
  "kpitech.water-common-areas": "Water common areas",
  "kpitech.water-fountains": "Water fountains",
  "kpitech.water-hvac": "Water HVAC",
  "kpitech.water-irrigation": "Water irrigation",
  "kpitech.water-others": "Water others",
  "kpitech.water-supply": "Water supply",
  "kpitech.wifi-maintenance": "WiFi maintenance",
  "kpitech.women-boxes": "Women boxes",
  "kpitech.wood": "Wood",
  "kpitech.workforce": "Workforce",
  "kpitech.year": "Year",
  "last-contact-date": "Last contact date",
  "last-month-closed": "Last closed month",
  "last-year": "Last year",
  "lease-payments": "Lease payments",
  "leasing-agent": "Leasing agent",
  "leasing-brand.edit": "Edit",
  "leasing-brand.new": "New",
  "leasing-center-type": "Type",
  "leasing-center.edit": "Edit",
  "leasing-center.new": "New leasing center",
  "leasing-commercial-proposal": "Lease economic proposal",
  "leasing-contact.edit": "Edit",
  "leasing-contact.new": "New",
  "leasing-operation.edit": "Edit",
  "leasing-operation.new": "New",
  "leasing-operations.term.invalid-format": "Invalid format for term. It must be N + N + N. E.g. 7 + 8 +",
  "leasing-owner": "Owner",
  "leasing-owner.edit": "Edit",
  "leasing-owner.new": "New",
  "leasing-unit-storage-room": "Storage room",
  "leasing-unit-terrace": "Terrace",
  "leasing.brand": "Brand",
  "leasing.brand.activity": "Activity",
  "leasing.brand.filters": "Filters",
  "leasing.brand.name": "Name",
  "leasing.brand.optimal-area": "Optimal area",
  "leasing.brand.select-activity": "Select an activity",
  "leasing.brand.select-optimal-area": "Select an optimal area",
  "leasing.center": "Center",
  "leasing.center.CBRE-legal-service": "CBRE Legal Service",
  "leasing.center.contractType": "Contract Type",
  "leasing.center.group-data": "Group data",
  "leasing.centers.filters": "Filters",
  "leasing.contact": "Contact",
  "leasing.contact.brand": "Brand",
  "leasing.contact.brands": "Brands",
  "leasing.contact.email": "Email",
  "leasing.contact.last-name": "Last name",
  "leasing.contact.mobile-phone": "Mobile phone",
  "leasing.contact.name": "Name",
  "leasing.contact.office-phone": "Office phone",
  "leasing.contact.postalAddress": "Postal address",
  "leasing.contact.title": "Title",
  "leasing.contacts.filters": "Filters",
  "leasing.counter-offer": "Counter offer",
  "leasing.economic-proposal-sent": "Economic proposal sent",
  "leasing.emea-report": "Export EMEA report",
  "leasing.first-contact": "First contact",
  "leasing.initial-proposal": "Initial proposal",
  "leasing.last-offer": "Last offer",
  "leasing.operation.current-passing-rent": "Current/Passing rent",
  "leasing.operation.fee": "Fee",
  "leasing.operation.general-info": "General info",
  "leasing.operation.mutualBreak": "Mutual break",
  "leasing.operation.netRent": "Net rent",
  "leasing.operation.occupancy-cost": "Occupancy cost (last 12m)",
  "leasing.operation.portfolio-average-area": "Portfolio average area",
  "leasing.operation.portfolio-sales-density": "Portfolio sales density",
  "leasing.operation.precedentConditionClause": "Precedent condition clause",
  "leasing.operation.rent-storage-room": "Storage room rent",
  "leasing.operation.rent-terrace": "Terrace rent",
  "leasing.operation.rentFreePeriodStart": "Rent free period start",
  "leasing.operation.rentReview": "Rent Review: (IPC+...)",
  "leasing.operation.sales-density": "Sales density (last 12m)",
  "leasing.operation.schCap": "Sch Cap",
  "leasing.operation.select-rentFreePeriodStart": "Select period",
  "leasing.operation.tenant-occupancy-cost": "Tenant occupancy cost",
  "leasing.operation.tenant-performance": "Tenant performance",
  "leasing.operation.tennant-annual-sales-density": "Tenant annual sales density",
  "leasing.operation.term": "Term",
  "leasing.operation.unit-size-proportion": "Unit size proportion",
  "leasing.operation.unitDelivered": "Unit delivered",
  "leasing.operation.units": "Units",
  "leasing.operations.error-create-work": "There was an error while generating a new work. Please, contact with support to solve your problem",
  "leasing.operations.error-generate-contract": "There was an error while generating a new Work. Please, contact with support to solve your problem",
  "leasing.operations.warning-create-work": "It is going to create an ECOP work before saving the operation. \n Are you sure you want to continue?",
  "leasing.operations.warning-generate-contract": "The operation is going to be save before creating a new contract. Are you sure you want to continue?",
  "leasing.owner.vat-number": "VAT number",
  "leasing.pending-approval": "Pending approval",
  "leasing.rejected": "Rejected",
  "leasing.renewal": "Renewal",
  "leasing.signed": "Signed",
  "leasing.storage-room": "Storage room",
  "leasing.terrace": "Terrace",
  "leasing.under-signing-process": "Under signing process",
  "leasing.unit.area-storage-room": "Storage room area",
  "leasing.unit.area-terrace": "Terrace area",
  "leasing.unit.category": "Category",
  "legal-deposit": "Legal deposit",
  "letting-agreement-date": "Letting agreement date",
  "letting-agreement-signing-date": "Letting agreement signing date",
  "letting-data": "Letting data",
  "logistics-warehouse": "Logistics warehouse",
  "maintenance-contracts": "Maintenance contracts",
  "mall": "Mall",
  "managed-states": "Managed states",
  "mar": "Mar",
  "masonry": "Masonry",
  "materials-sample-approval-date": "Materials sample approval date",
  "materials-sample-delivery-date": "Materials sample delivery date",
  "menu.navbar.ecop": "ECOP",
  "menu.navbar.kpi-tech": "KPI Tech",
  "menu.navbar.kpi-tech.centers": "Centers",
  "menu.navbar.kpi-tech.contractors": "Contractors/Vendors",
  "menu.navbar.leasing.brands": "Brands",
  "menu.navbar.leasing.centers": "Centers",
  "menu.navbar.leasing.contacts": "Contacts",
  "menu.navbar.leasing.owners": "Owners",
  "menu.navbar.leasing.works": "Works",
  "months": "Months",
  "new-lease-form": "Lease form",
  "nov": "Nov",
  "oct": "Oct",
  "office": "Office",
  "ongoing": "Ongoing",
  "opening": "Opening",
  "opening-guarantee": "Opening guarantee",
  "operation-analysis": "Operation analysis",
  "operation-analysis.warning": "Operation analysis fields will be shown from Economic proposal sent status",
  "optimal-area": "Optimal area",
  "other-documentation": "Other documentation",
  "outlet": "Outlet",
  "owner-find": "Find owner",
  "poor-image-quality": "Poor image quality",
  "portfolio-manager": "Portfolio manager",
  "premises-authorizations": "Premises authorizations",
  "process": "Process",
  "project-approval": "Project approval",
  "project-approval-shopfront": "Project approval shopfront",
  "project-approval-unit": "Project approval unit",
  "projects-delivery-unit-and-shopfront": "Projects delivery (unit & shopfront)",
  "real-date": "Real date",
  "real-shopfront-date": "Real shopfront date",
  "real-unit-date": "Real unit date",
  "regional": "Regional",
  "relocation": "Relocation",
  "renting-agreement-date": "Renting date",
  "retail-park": "Retail park",
  "retail-space": "Retail space",
  "sanitation": "Sanitation",
  "second-invoice-amount": "Second invoice amount",
  "second-invoice-date": "Second invoice date",
  "select-brand": "Select brand",
  "select-category": "Select a category",
  "select-fit-out-coordinator-cbre": "select fit-out CBRE coordinator",
  "select-fit-out-coordinator-external": "select fit-out external coordinator",
  "sep": "Sep",
  "service-charges": "Service charges",
  "shopfront": "Shopfront",
  "sign": "Sign",
  "signing-date": "Signing date",
  "staff": "Staff",
  "supply-contracts": "Supply contracts",
  "technical-direction": "Technical direction",
  "temporary-hoarding": "Temporary hoarding",
  "terrace-template-approval-date": "Terrace template approval date",
  "terrace-template-delivery-date": "Terrace template delivery date",
  "terrace-template-reviews": "Terrace template reviews",
  "third-invoice-amount": "Thrid invoice amount",
  "third-invoice-date": "Third invoice date",
  "title.header.ecop.works": "ECOP Works",
  "title.header.kpi-tech.centers": "Centers",
  "title.header.kpi-tech.years": "KPI Tech Data Sheet",
  "title.header.leasing.brands": "Brands",
  "title.header.leasing.centers": "Centers",
  "title.header.leasing.contacts": "Contacts",
  "title.header.leasing.operations": "Operations",
  "title.header.leasing.owners": "Owners",
  "total": "Total",
  "total-costs": "Total costs",
  "total-units": "Total units",
  "transportHub": "Transport Hub",
  "trendy-&-lifestyle": "Trendy & lifestyle",
  "unassigned": "Unassigned",
  "unit-handover": "Unit handover",
  "upsize-downsize": "Upsize-Downsize",
  "urban": "Urban",
  "ventilation": "Ventilation",
  "vinyl": "Vinyl",
  "vinyl-design": "Vinyl design",
  "want_export": "Select additional data to export {{modelName}}",
  "without-vat": "Without vat",
  "work-budget": "Work budget",
  "work-permit": "Work permit",
  "work-permit-approval": "Work permit approval",
  "work-permit-request": "Work permit request",
  "work-planning": "Work planning",
  "work-start": "Work start",
  "workplace-permit": "Workplace permit",
  "marketing-analytics.campaign.loyalty.wellcome-stamps": "Welcome stamps",
  "intranet.sales-year.error-select-commerce": "It is required to select at least one commerce",
  "intranet.sales-year.warn-open-months": "You can't state annual sales because there are months open or not validated by a manager. Please, review the months or contact with the manager to continue.",
  "sales-year.edit": "Edit annual sales statement",
  "crm.filter.not_found": "Filter not found",
  "select-range": "Select range",
  "cms.proposals.proposal-unPublished": "Proposal Unpublished successfully",
  "cms.proposals.proposal-unPublished-error": "Error on unpublishing proposal",
  "unPublish": "Unpublish",
  "cms.commerce.store": "Store",
  "initial-value": "Initial value",
  "cms.proposals.proposal-expired": "Proposal expired successfully",
  "cms.proposals.proposal-expired-error": "Error on expiring proposal",
  "cms.proposals.proposal-published": "Proposal published successfully",
  "cms.proposals.proposal-published-error": "Error on publishing proposal",
  "cms.proposals.proposal-unpublished": "Proposal unpublished successfully",
  "cms.proposals.proposal-unpublished-error": "Error on unpublishing proposal",
  "marketing-analytics.kpi.tooltip.total-coupons": "Amount of redeemed coupons",
  "marketing-analytics.kpi.total-coupons": "DMI - Coupon use",
  "marketing-analytics.kpis.reference-title": "Reference KPIs",
  "cms.proposals.reject-error": "Error on rejecting the publication of the proposal",
  "cms.proposals.reject-success": "The publication of the proposal has been rejected successfully",
  "text": "text",
  "marketing-analytics.campaign.closure": "Close campaign",
  "days-until-birthday": "days until birthday",
  "menu.navbar.marketing-analytics.supplier-contacts.index": "Supplier contacts",
  "supplier-contact": "Supplier contact",
  "supplier-contact.edit": "Edit supplier contact",
  "User type": "User type",
  "Segment": "Segment",
  "yardi-upload": "Upload to Yardi",
  "qr": "QR",
  "cms.commerce-stay-alert.stay_full": "Stays full",
  "cms.commerce.qr": "Qr",
  "taxpayer_number": "Taxpayer number",
  "bonusRequest.q-in-study": "Q in Study",
  "bonusRequest.q-sales": "Q sales",
  "bonusRequest.q-sales-tooltip": "Sales of all quarters until the request date (e.g. If you want to request the bonus in July you must add Q1 and Q2)",
  "bonusRequest.select-q": "Select quarter",
  "bonusRequest.q1": "Q1",
  "bonusRequest.q2": "Q2",
  "bonusRequest.q3": "Q3",
  "bonusRequest.q4": "Q4",
  "communication.roles.owner": "Owner",
  "menu.navbar.shop.new": "New store",
  "percent": "Percent",
  "campaign.segment-all": "All users",
  "segmented campaigns": "automatically assigned segmented campaigns",
  "campaign-report.deviation-level": "Deviation level",
  "marketing-analytics.campaign.available-crm": "Available on CRM",
  "marketing-analytics.campaign.public": "Public campaign",
  "marketing-analytics.campaign.share-cbre": "Share with CBRE",
  "user.loyalty-card-campaign": "Loyalty card campaign",
  "error.tickets.price.greatherThanZero": "Price has to be greather than zero",
  "menu.navbar.message.new": "New message",
  "menu.navbar.message.show": "Message list",
  "users.redeemable-receipt": "Receipt is redeemable",
  "gantt": "Gantt Chart",
  "marketing-analytics.campaigns.gantt": "Gantt chart",
  "marketing-analytics.campaigns.goal.description-kpi-not-selected": "Please, select a kpi to watch its description",
  "crm.filters-section.stats": "Statistics",
  "coupon-used": "Coupon used",
  "crm.filters-section.loyalty": "Loyalty",
  "crm.filters-section.sales": "Sales",
  "dashboard-cms": "Dashboard cms",
  "dashboard-crm": "Dashboard crm",
  "days-ago": "days ago",
  "dmr-weekly": "DMR weekly",
  "influence-zone": "Zones of influence",
  "menu.navbar.intranet.sales-year.index": "Annual sales statements",
  "monthly-report": "Monthly report",
  "since_last_visit": "Since last visit(days)",
  "spending_receipts": "Spending receipts",
  "user.child-bday": "Children birthday (days)",
  "user.child-in-play-center-days": "children in play center (days)",
  "user.days-until-campaign-expiration": "days until expiration of the campaign",
  "user.in-campaign": "In campaign",
  "user.large-family": "Large family",
  "user.most-purchased-subfamily": "Most purchased subfamily (days)",
  "user.number-receipt-days": "Receipts number (last days)",
  "user.redeemable-stamps": "Redeemable stamps",
  "user.redeemable-stamps-range": "Redeemable stamps (range)",
  "user.redeemed-loyalty-cards": "Redeemed loyalty cards",
  "user.redeemed-loyalty-cards-range": "Redeemed loyalty cards (range)",
  "user.redeemed-reward-days": "redeemable rewards (range)",
  "user.redeemed-rewards": "Redeemed rewards",
  "user.redeemed-rewards-range": "Redeemed rewards (range)",
  "user.seniority": "Seniority",
  "user.spending-receipt-days": "Spending receipts (last days)",
  "visit_building": "Visit building",
  "bime": "BIME",
  "bime-panel": "Bime panel",
  "bime_dashboard.not_found": "Bime dashboard not found",
  "bime_dashboard.type_find": "Type to find bime dashboards",
  "dashboard-panel": "Dashboard panel",
  "dashboard.category.not_found": "Categories not found",
  "dashboard.error.not-found-bime": "Bime dashboard not found",
  "error.dashboard.bimeId.required": "Bime dashboard is required",
  "form-dashboard.bime-dashboard": "Bime dashboard",
  "form-dashboard.button.clear": "Clear",
  "form-dashboard.button.submit": "Submit",
  "form-dashboard.category": "Category",
  "form-dashboard.header.available_graphs": "Available graphs",
  "form-dashboard.name": "Dashboard name",
  "form-dashboard.parameters": "Parameters",
  "Image": "Image",
  "Update": "Update",
  "access_data": "Access data",
  "access_date": "Access date",
  "access_head": "Head of access",
  "answer": "Answer",
  "are_you_sure": "Are you sure?",
  "bonus-request": "Bonus request",
  "bonus-shop.created-at": "Date",
  "bonusRequest.bonus-percentage": "Rent discount request (%)",
  "bonusRequest.bonus-period": "Rent discount duration (months)",
  "bonusRequest.confirm-bonus-request": "I confirm the bonus request is valid.",
  "bonusRequest.costs-data": "Costs data",
  "bonusRequest.edit": "Edit",
  "bonusRequest.employee-hours-year": "Employee hours per year",
  "bonusRequest.month-label": "Period from January to...",
  "bonusRequest.new": "New",
  "bonusRequest.other-expenses": "Other expenses",
  "bonusRequest.sales-tooltip": "Exercise sales (e.g. 2019)",
  "bonusRequest.select-exercise": "Select year",
  "bonusRequest.select-month": "Select month",
  "bonusRequest.shops": "Brand",
  "bonusRequest.shops-search": "Search a brand",
  "bonusRequest.surface": "Surface(m2)",
  "bonusRequests.exercise": "Exercise Accounting Period",
  "campaign-action": "Action",
  "campaign-action.new": "New campaign action",
  "campaign-goal.kpi": "Kpi",
  "campaign-goal.new": "New campaign goal",
  "campaign-goal.value": "Value",
  "campaign.close": "Close campaign",
  "campaign.date-range": "Date range",
  "campaign.edit": "Edit campaign",
  "campaign.goal": "Goal",
  "campaign.new": "New campaign",
  "campaign.reference": "Reference",
  "campaign.reference-type": "Reference type",
  "campaign.segment.list": "Segment list",
  "campaign.segments": "Segments",
  "campaigntype.edit": "Edit campaign type",
  "campaigntype.new": "New campaign type",
  "capacity": "Capacity",
  "cars": "Cars",
  "child.bdate": "Birthdate",
  "child.checkin-success-message": "Child register correctly",
  "child.checking-out": "Checking out",
  "child.checkn": "check In",
  "child.checkout-success-message": "Child checkout confirmed",
  "child.edit": "Edit",
  "child.last-name": "Last name",
  "child.name": "Name",
  "child.new": "New",
  "child.not-dni-error-message": "It isn't allowed to use the play center without entering a VAT number. Please, go to user profile to set it",
  "child.notes": "Notes",
  "child.register-entry": "register Entry",
  "child.relationship": "Relationship",
  "child.select-relationship": "Select relationship",
  "child.select-sex": "Select gender",
  "child.sex": "Gender",
  "cinemas": "Cinemas",
  "close-month.button.confirm": "Confirm close month",
  "close-month.button.reject": "Reject close month",
  "close-month.message.pending-request": "There is a close month pending request for this month",
  "close_month": "Close month",
  "closed_month_pending": "Pending",
  "cms.commerce-access-information.sent-error": "There was an error trying to send the information requested, please try again later",
  "cms.commerce-access-information.sent-success": "The access information requested has been sent successfully to your email, it may take a few minutes to arrive to your inbox",
  "cms.commerce.disabled-sales": "Disabled sales",
  "cms.commerce.extra-attributes": "Extra attributes",
  "cms.commerce.general-data": "General Data",
  "cms.commerce.hide-commerce": "Hide store",
  "cms.commerce.physical-store": "Physical Store",
  "cms.commerces.qr": "Access QR",
  "cms.commerces.qr-error": "There was an error trying to download the information requested, please try again later",
  "cms.proposal.extra-attributes": "Extra attributes",
  "cms.proposal.general-data": "General data",
  "cms.proposals.expire-error": "Error on expiring proposal",
  "cms.proposals.expire-success": "The proposal has been expired successfully",
  "cms.proposals.proposal-duplicated": "The proposal has been duplicated successfully",
  "cms.proposals.proposal-duplicated-error": "Error on duplicating proposal",
  "cms.proposals.publish-error": "Error on publishing proposal",
  "cms.proposals.publish-success": "The proposal has been published successfully",
  "cms.proposals.reject-publication-error": "Error on rejecting the publication of the proposal",
  "cms.proposals.reject-publication-success": "The proposal has been reject to publish successfully",
  "cms.proposals.request-error": "Error on requesting the publication of the proposal",
  "cms.proposals.request-success": "The proposal has been requested to publish successfully",
  "cms.proposals.unpublish-error": "Error on unpublishing proposal",
  "cms.proposals.unpublish-success": "The proposal has been unpublished successfully",
  "cms.push-notification.general-data": "General data",
  "cms.push-notification.want-publish": "Do you want to publish the push notification?",
  "comments": "Comments",
  "commerce-stay-alert": "Commerce stay alert",
  "commerce-stay-alert.alert-date": "Alert date",
  "commerce-stay-alert.alert-type": "Alert type",
  "commerce.capacity-data": "Capacity data",
  "commerce.categories": "Categories",
  "commerce.edit": "Edit store",
  "commerce.extra.address": "Address extra attributes",
  "commerce.extra.image": "Image extra attributes",
  "commerce.extra.text": "Text extra attributes",
  "commerce.extra.url": "URL extra attributes",
  "commerce.general-data": "General data",
  "commerce.html": "HTML extra attributes",
  "commerce.new": "New store",
  "commerce.personalized-target": "Custom target",
  "commerce.store-data": "Store data",
  "commerce.whatsapp-message": "WhatsApp Message",
  "commerce.whatsapp-phone": "WhatsApp Phone",
  "commerce.whatsapp-phone-format": "A phone number for WhatsApp should have the code area before the number. E.g. +346xxxxxxxx",
  "commerce.yardi-code": "Yardi Code",
  "commerces.new": "New stores",
  "communication.label.to_roles": "To",
  "communication.message": "Message",
  "communication.new": "New communication",
  "communication.reject-close-month.subject": "{{shopName}} closing {{month}} rejected",
  "communication.subject": "Subject",
  "communication.subtype": "Subtype",
  "communication.to_commerces": "To stores",
  "communication.type": "Type",
  "communications.creator": "Creator",
  "communications.datetime": "Datetime",
  "communications.state": "State",
  "communications.subject": "Subject",
  "communications.type": "Type",
  "companion.add": "Add companion",
  "companion.vat_number": "ID number",
  "companion_name": "Companion name",
  "companions": "Companions",
  "confirm": "Confirm",
  "contract-end-date": "Contract end date",
  "contract-start-date": "Contract start date",
  "crm.campaign.active-crm": "Active on CRM",
  "crm.campaign.attach-image": "Attach image (png, jpeg, gif or jpg)",
  "crm.campaign.generic-reward": "Generic Reward",
  "crm.campaign.loyalty-campaign": "Loyalty card campaign",
  "crm.campaign.loyalty-campaign-info-message": "Selecting a loyalty card campaign will generate a reward when loyalty card is achieved",
  "crm.campaign.loyalty.amount": "Amount",
  "crm.campaign.loyalty.general_data": "General data",
  "crm.campaign.loyalty.receipts-redemption-days": "Receipts redemption days",
  "crm.campaign.loyalty.welcome-stamps": "Welcome stamps",
  "crm.campaign.raffle.block-raffle-info": "Loyalty settings should not be changed after the first receipt has been redeemed. Keep this in mind when saving and launching this campaign.",
  "crm.campaign.raffle.loyalty-code": "Loyalty code",
  "crm.campaign.raffle.rules": "Raffle exceptions",
  "crm.campaign.reward.other-options": "Other options",
  "crm.campaign.reward.valid-days": "Valid days",
  "crm.campaign.search-campaign": "Search campaign",
  "crm.campaign.select-campaign": "Select campaign",
  "crm.campaign.share-campaign": "Share campaign",
  "crm.campaign.submit-image": "Submit image",
  "crm.campaign.without-remains": "Without remains",
  "crm.campaigns.loyalty": "Loyalty",
  "crm.children.add": "Add children",
  "deviation": "Deviation",
  "directory.edit": "Edit directory",
  "directory.name": "Name",
  "directory.new": "New directory",
  "dmr-weekly.select-dates": "Select range dates",
  "email-template.not_found": "Templates not found",
  "estimated-stay-time": "Estimated stay time (HH:MM)",
  "expire": "Expire",
  "export": "Export",
  "file.creator_id": "Creator",
  "file.description-optional": "Optional description",
  "file.edit": "Edit file",
  "file.name": "Name",
  "file.new": "New file",
  "file.roles_with_access": "Roles with access",
  "file.shop_with_access": "Stores with access",
  "file.size": "Size",
  "file.type": "Type",
  "file.updated_at": "Date",
  "file.visibility": "Visibility",
  "files": "Files",
  "files.add-folder": "Add folder",
  "flows.date": "Date",
  "flows.upload-xlsx-error": "The file you have uploaded is not a valid format. Please, check the file and try again.",
  "flows.upload-xlsx-success": "The XLSX file is uploaded successfully",
  "folder.success-created": "Success created",
  "footfall.cars": "Cars",
  "footfall.cinemas": "Cinemas",
  "footfall.import-xlsx": "Import XLSX",
  "footfall.info": "Info",
  "footfall.xlsx-format": "The XLSX should have the following format:",
  "footfall.xlsx.columns": "It should have four columns, with the date on the first column, footfall in the second column, cars on the third, and cinemas on the fourth. In the first row it should be the name of the column.",
  "footfall.xlsx.date-format": "The date should be set in Excel with type 'date'.",
  "footfall.xlsx.download-example": "Download Template",
  "footfall.xlsx.values-format": "Footfall, cars and cinemas should be set in Excel with type 'number'.",
  "gender": "Gender",
  "growth": "Growth",
  "guard": "Guard",
  "guard.chose_staff_member": "Choose a staff member...",
  "guard.no_assigned_this_day": "There is no assigned guard for this day",
  "informative-message.close-month.error.message": "An error occurred while closing the month. Please try again later",
  "informative-message.close-month.success.message": "The month was closed succesfully.",
  "informative-message.reject-close-month.error.message": "An error occurred while rejecting to close the month. Please try again later",
  "informative-message.reject-close-month.success.message": "The close month was rejected succesfully. A communication was sent to the store",
  "intranet-sales-year": "Annual sales statement",
  "intranet.close_month-message": "You're going to close {{shop}}'s selling registers of {{month}}.",
  "intranet.communications.not-contactable-staff": "There are not contactable staff members available in the system. Please, update at least one staff member to be contactable.",
  "intranet.communications.resent-success": "The communication has been resent successfully.",
  "intranet.communicatios.resend-staff-members": "Select external staff members to receive the communication",
  "intranet.flow.total-flows": "Total month",
  "intranet.footfall.import": "Import",
  "intranet.footfall.import-footfall": "Import footfall",
  "intranet.sales-year.button-show": "Show annual sales statement",
  "intranet.sales-year.disclaimer": "Certificate of net sales (without VAT) made in the reference store during the indicated period of time, which accurately match the supporting documents and accounting notes in the company records. Net sales (VAT not included)",
  "intranet.sales-year.validate.acceptable": "Acceptable deviation",
  "intranet.sales-year.validate.invalid": "The statement is not valid",
  "intranet.sales-year.validate.valid": "The statement is correct",
  "intranet.sales-year.warn-pending-review": "The admin is reviewing the annual sales statement and it can't be changed right now.",
  "intranet.sales-years.declared": "Sales declared",
  "intranet.sales-years.deviation-level": "Deviation level",
  "intranet.sales-years.disable-sales-button": "Disable annual sales statement {{year}}",
  "intranet.sales-years.disable-sales-years-confirmation": "Upon confirming, the annual sales declaration process will be finished. \nAfter this, stores will not be able to modify their annual sales statement and their sales for the year {{year}}.",
  "intranet.sales-years.dmi": "DMI",
  "intranet.sales-years.enable-sales-button": "Enable annual sales statement {{year}}",
  "intranet.sales-years.enable-sales-years-confirmation": "Upon confirming, the annual sales declaration process will begin. \nStores may create their annual sales statement and may modify their sales for the year {{year}}.",
  "intranet.sales-years.invalid-sales": "The annual sales statement cannot start because the following stores have months without validation:",
  "intranet.sales-years.reject-communication-message": "Your annual sales statement presents differences with regard to the sales declared monthly. Please check the amount declared and confirm them again for the following months: ",
  "intranet.sales-years.reject-communication-subject": "Annual sales statement rejected, year ",
  "intranet.sales-years.reject-sale": "Reject",
  "intranet.sales-years.update-dmi-error-message": "There was a problem with the updating and the DMI value has not be updated. Please, contact with the support team.",
  "intranet.sales-years.update-dmi-success-message": "The DMI value has been updated correctly.",
  "intranet.sales-years.update-dmi-value": "Update DMI value",
  "intranet.sales-years.update-yardi": "I want to upload the Yardi file (it may take a few minutes)",
  "intranet.sales-years.validate-sale": "Validate",
  "intranet.sales.declare-yearly-sales": "Declare annual sales statement",
  "intranet.sales.download-csv": "Download CSV",
  "intranet.sales.download-sales": "Download Sales",
  "intranet.sales.import": "Import",
  "intranet.sales.import-sales": "Import Sales",
  "intranet.sales.select-dates": "Select date range",
  "intranet.update_dmi-message": "You're going to update {{shop}}'s DMI value of {{month}}.",
  "is-in-play-center": "Is in play center",
  "lineitem.edit": "Edit line item",
  "lineitem.new": "New line item",
  "loyalty": "Loyalty",
  "marketing-analytics.campaign.active-crm": "Active on CRM",
  "marketing-analytics.campaign.attach-image": "Attach image (png, jpeg, gif or jpg)",
  "marketing-analytics.campaign.closing-notes": "Notes",
  "marketing-analytics.campaign.closure.actions": "Actions",
  "marketing-analytics.campaign.closure.attr-required": "The following attributes are required: {{requiredAttrs}}",
  "marketing-analytics.campaign.closure.campaign": "Campaign",
  "marketing-analytics.campaign.closure.is-goal": "Is goal",
  "marketing-analytics.campaign.closure.manual-kpis": "Manual KPIs",
  "marketing-analytics.campaign.closure.suppliers": "Suppliers",
  "marketing-analytics.campaign.date-helper": "Remember to review the actions dates if you change the date range of the campaign",
  "marketing-analytics.campaign.files": "Campaign images",
  "marketing-analytics.campaign.generic-reward": "Generic Reward",
  "marketing-analytics.campaign.loyalty-campaign": "Loyalty card campaign",
  "marketing-analytics.campaign.loyalty-campaign-info-message": "Selecting a loyalty card campaign will generate a reward when loyalty card is achieved",
  "marketing-analytics.campaign.loyalty.amount": "Amount",
  "marketing-analytics.campaign.loyalty.receipts-redemption-days": "Receipts redemption days",
  "marketing-analytics.campaign.loyalty.welcome-stamps": "Welcome stamps",
  "marketing-analytics.campaign.max-capacity": "Maximum capacity",
  "marketing-analytics.campaign.raffle.block-raffle-info": "Loyalty settings should not be changed after the first receipt has been redeemed. Keep this in mind when saving and launching this campaign.",
  "marketing-analytics.campaign.raffle.loyalty-code": "Loyalty code",
  "marketing-analytics.campaign.reward.other-options": "Other options",
  "marketing-analytics.campaign.reward.valid-days": "Valid days",
  "marketing-analytics.campaign.search-campaign": "Search campaign",
  "marketing-analytics.campaign.select-campaign": "Select campaign",
  "marketing-analytics.campaign.share-campaign": "Share campaign",
  "marketing-analytics.campaign.without-remains": "Without remains",
  "marketing-analytics.campaigns.follow-up": "Follow-up",
  "marketing-analytics.campaigns.goal-chart": "Goal kpi follow-up",
  "marketing-analytics.campaigns.goals": "Campaign goals",
  "marketing-analytics.campaigns.kpi": "Kpi",
  "marketing-analytics.campaigns.loyalty": "Loyalty",
  "marketing-analytics.kpi.manual-kpis": "Manual KPIs",
  "marketing-analytics.kpis.reference-campaign": "The reference value is the data from the campaign {{campaign}} which lasted from {{startDate}} to {{endDate}}",
  "marketing-analytics.kpis.reference-dates": "The reference value is the data from {{startDate}} to {{endDate}}",
  "marketing-analytics.report": "Report",
  "marketing-analytics.start-date-tooltip": "The campaign date range includes both the events during the campaign and the previous actions.",
  "maximumAge": "Maximum age",
  "menu.navbar.bonus-form": "Bonus form",
  "menu.navbar.bonus-requests": "Bonus requests",
  "menu.navbar.channel.show": "Channels",
  "menu.navbar.crm.campaign.index": "Campaigns",
  "menu.navbar.customer.import": "Import customers",
  "menu.navbar.customertype.show": "Customer types",
  "menu.navbar.email-template.show": "E-mail templates",
  "menu.navbar.footfall": "Footfall",
  "menu.navbar.intranet": "Intranet",
  "menu.navbar.intranet.communication.index": "Communications",
  "menu.navbar.intranet.file.index": "Files",
  "menu.navbar.intranet.guard.index": "Guards",
  "menu.navbar.intranet.sale.index": "Sales",
  "menu.navbar.intranet.subtype.index": "Communication subtypes",
  "menu.navbar.marketing-analytics": "Marketing Analytics",
  "menu.navbar.marketing-analytics.campaign.index": "Campaigns",
  "menu.navbar.marketing-analytics.campaigntype.index": "Campaign types",
  "menu.navbar.marketing-analytics.lineitem.index": "Line items",
  "menu.navbar.marketing-analytics.service.index": "Services",
  "menu.navbar.marketing-analytics.supplier.index": "Suppliers",
  "menu.navbar.playcenter.show": "Play center",
  "minimumAge": "Minimum age",
  "pin": "Pin",
  "play-center-stays": "Play center",
  "play-center.time": "Time",
  "play-center.tutor": "Tutor",
  "proposal": "Proposal",
  "proposal.app-image": "App Image",
  "proposal.coupon": "Coupon",
  "proposal.coupon-exchanges": "Coupon exchanges",
  "proposal.coupon.limit": "Coupon limit",
  "proposal.edit": "Edit proposal",
  "proposal.end-date": "End date",
  "proposal.end-datetime": "End datetime",
  "proposal.event": "Event",
  "proposal.event-end-event-datetime": "Event end datetime",
  "proposal.event-start-event-datetime": "Event start datetime",
  "proposal.export-feedback": "Select feedback type",
  "proposal.extra.text": "Text",
  "proposal.featured": "Featured",
  "proposal.gender": "Gender",
  "proposal.general-data": "General data",
  "proposal.hide-home": "Hide home",
  "proposal.new": "New proposal",
  "proposal.publish-on-app": "Publish on App",
  "proposal.publish-on-web": "Publish on Web",
  "proposal.segment-all": "All users",
  "proposal.segment.list": "Segments list",
  "proposal.segments": "Segments",
  "proposal.settings": "Settings",
  "proposal.start-date": "Start date",
  "proposal.start-datetime": "Start datetime",
  "proposal.store-search": "Search store",
  "proposal.web-image": "Web Image",
  "push-notification": "Push notification",
  "push-notification.active-app-users": "active users",
  "push-notification.advanced-options": "Advanced options",
  "push-notification.campaign": "Campaign",
  "push-notification.date": "Date",
  "push-notification.edit": "Edit",
  "push-notification.end-date": "End date",
  "push-notification.filters": "Filters",
  "push-notification.general-data": "General data",
  "push-notification.image": "Image",
  "push-notification.image.info": "This image would be only shown in the notification push when the action type selected is \"default\".",
  "push-notification.name": "Name",
  "push-notification.new": "New push notification",
  "push-notification.proposal-image.info": "The images of the push notifications can't be larger than 1 MB. Please, check the image's size before publishing the push notification.",
  "push-notification.proposal-image.warning": "The image of the proposal is larger than 1 MB. Please, reduce the size of the image before publishing the push notification.",
  "push-notification.proposals": "Proposals",
  "push-notification.proposals-search": "Search proposal",
  "push-notification.segmentation": "Segmentation",
  "push-notification.start-date": "Start date",
  "push-notification.state": "State",
  "push-notification.status": "Status",
  "reference-value": "Reference value",
  "reject": "Reject",
  "reject-close-month-modal.specify-reason": "Specify the reason to the store",
  "reject-close-month-modal.title": "Close month rejection",
  "reject-publishing": "Reject the publication",
  "rejectPublish": "Reject the publication",
  "remove": "Delete",
  "report": "Report",
  "request": "Request",
  "resend": "Resend",
  "response": "Response",
  "role": "Role",
  "sale.amount": "Amount",
  "sale.date": "Date",
  "sales-year.new": "New annual sales statement",
  "sales-year.show": "Show annual sales statement",
  "sales-years.confirm-shop-sales-years": "I confirm the annual sales statement is valid.",
  "sales.csv": "CSV",
  "sales.import-xlsx": "Import XLSX",
  "sales.info": "Info",
  "sales.sale-concepts": "Sale Concepts",
  "sales.upload-xlsx-error": "The file you have uploaded is not a valid format. Please, check the file and try again.",
  "sales.upload-xlsx-success": "XLSX has been uploaded with sucess!",
  "sales.xlsx": "Xlsx",
  "sales.xlsx-format": "The XLSX should have the following format:",
  "sales.xlsx.amount-format": "The amount should be set in Excel with type 'currency'.",
  "sales.xlsx.columns": "It should have three columns, with the date on the first column, sale concept in the second column and amount on the third. In the first row it should be the name of the column.",
  "sales.xlsx.concept-format": "The concept is identified by its Yardi code. By default, this value should be 'gm'.",
  "sales.xlsx.date-format": "The date should be set in Excel with type 'date'.",
  "sales.xlsx.download-example": "Downlad Template",
  "select-shop": "Select store",
  "select-state": "Select state",
  "select_communication_required_attention": "Check when the communication requires urgent attention",
  "service.edit": "Edit service",
  "service.new": "New service",
  "services": "Services",
  "shop-property": "Store Property",
  "shop.all": "All",
  "staff-members": "Users",
  "subtype": "Subtype",
  "subtype.edit": "Edit subtype",
  "subtype.name": "Name",
  "subtype.new": "New subtype",
  "subtype.type": "Type",
  "supplier-contact.new": "New supplier contact",
  "supplier.edit": "Edit supplier",
  "supplier.new": "New supplier",
  "suppliers.active": "Active",
  "suppliers.local": "Local",
  "suppliers.national": "National",
  "suppliers.rating": "Rating",
  "threshold_active_check_in": "Threshold active wait (min)",
  "ticket-amount": "Amount",
  "title.communication.subtypes": "Communications subtypes",
  "title.header.bonus-requests": "Bonus requests",
  "title.header.cms.commerce-stay-alerts": "Commerce stay alert",
  "title.header.communication": "Communications",
  "title.header.crm.campaignRules": "Raffle exceptions",
  "title.header.crm.campaigns": "Campaigns",
  "title.header.crm.email-templates": "E-mail templates",
  "title.header.files": "Files management",
  "title.header.footfall": "Footfall",
  "title.header.guards": "Guards",
  "title.header.intranet-sale": "Sales",
  "title.header.intranet.sales-years": "Annual sales statement",
  "title.header.marketing-analytics.campaignActions": "Campaign actions",
  "title.header.marketing-analytics.campaignGoals": "Campaign goals",
  "title.header.marketing-analytics.campaigns": "Campaigns",
  "title.header.marketing-analytics.campaigntypes": "Campaign types",
  "title.header.marketing-analytics.lineitems": "Line items",
  "title.header.marketing-analytics.services": "Services",
  "title.header.marketing-analytics.suppliercontacts": "Supplier contacts",
  "title.header.marketing-analytics.suppliers": "Suppliers",
  "title.header.proposals": "Proposals",
  "title.header.push-notifications": "Push notifications",
  "title.header.sales": "Sales management",
  "title.header.subtypes": "Communication types",
  "urgent": "Urgent",
  "vat_number": "ID number",
  "want_continue": "Do you really want to continue?",
  "want_expire": "Do you really want to expire the {{modelName}} {{name}}?",
  "want_publish": "Do you really want to publish this {{modelName}} {{name}}?",
  "want_reject_publish": "Do you really want to reject the publication of this {{modelName}} {{name}}?",
  "want_request": "Do you really want to request the publication of this {{modelName}} {{name}}?",
  "want_unpublish": "Do you really want to unpublish this {{modelName}} {{name}}?",
  "yardi": "Yardi",
  "yardi-code": "Yardi code",
  "crm.users.children-list": "Children list",
  "menu.navbar.crm": "CRM",
  "menu.navbar.residential.contacts": "Contacts",
  "segments.warning": "Warning!"
}
;