import Component from '@ember/component';
import { computed } from '@ember/object';

import parseErrors from 'neuro-frontend/utils/parse-errors-array-to-errors-object';
// @DEPRECATED
export default Component.extend({
  classNames: ['form-element'],

  loading: false,

  disableSubmit: false,

  returnRoute: computed('returnPath', function () {
    const returnPath = this.get('returnPath');

    if ('string' === typeof returnPath) {
      return {
        path: returnPath,
        id: null,
      };
    }
    if (returnPath && returnPath.get('path')) {
      return {
        path: returnPath.get('path'),
        id: returnPath.get('id'),
      };
    }
    return {
      path: '',
      id: null,
    };
  }),

  actions: {
    submit() {
      if (!this.get('loading')) {
        this.set('loading', true);
        this.set('errors', {});

        this.get('onSubmit')(this.get('model'), this.get('returnRoute'))
          .catch((errors) => {
            this.set('errors', parseErrors(errors));
          })
          .finally(() => {
            this.set('loading', false);
          });
      }
    },

    cancel() {
      this.get('onCancel')(this.get('model'), this.get('returnRoute'));
    },
  },
});
