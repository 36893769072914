import Adapter from './application';

export default Adapter.extend({
  urlForQueryRecord(...args) {
    const query = args.length ? args[0] : {};

    if (query.me) {
      delete query.me;
      return `${this._super(...args)}/me`;
    }

    return this._super(...args);
  },
});
