import Ember from 'ember';
import layout from
  'neuro-frontend/templates/components/light-table/cells/material-icon';

import MaterialIcon from './material-icon';

const { get, computed, computed: { alias } } = Ember;

export default MaterialIcon.extend({
  layout,

  residentialOffer: alias('row'),

  hide: computed('residentialOffer.state', function () {
    return 'signed' === get(this, 'residentialOffer.state');
  }),
});
