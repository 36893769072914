import nfKpi from 'neuro-frontend/components/nf-kpi';
import { computed, get } from '@ember/object';

import moment from 'moment';

export default class extends nfKpi {
  layoutName = 'components/nf-kpi'

  @computed('value')
  get mainValue() {
    const value = get(this, 'value');
    const secNum = parseInt(value, 10);
    const hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - (hours * 3600)) / 60);
    let seconds = secNum - (hours * 3600) - (minutes * 60);
    minutes = (10 > minutes) ? `0${minutes}` : minutes;
    seconds = (10 > seconds) ? `0${seconds}` : seconds;
    return moment({ hour: hours, minute: minutes, second: seconds }).isValid()
      ? `${hours}:${minutes}:${seconds}`
      : '--';
  }
}
