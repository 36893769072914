import Row from 'neuro-frontend/components/nf-tabs/row';
import { action, get, set } from '@ember/object';

import { inject as service } from '@ember/service';

export default class extends Row.extend({
  classNames: ['form-user__row-actions'],

  userActions: [],
}) {
  @service auth

  @action
  createAction() {
    // @TODO refactor: it should be far away from row-actions, make a component for the button
    get(
      this,
      'onCreateAction',
    )(
      'action',
      {
        scheduledDatetime: new Date(),
        staff: get(this, 'auth.user'),
        user: get(this, 'user'),
      },
      'userAction',
    );
  }

  @action
  deleteAction(model) {
    return model.destroyRecord();
  }

  @action
  editAction(row) {
    set(this, 'userAction', row.content);
  }

  @action
  saveAction() {
    const userAction = get(this, 'userAction');
    const isNotValid = !userAction.validate();
    if (isNotValid) {
      return Promise.resolve((resolve, reject) => reject());
    }
    return userAction.save().then(() => {
      if ('function' === typeof get(this, 'onSaveAction')) {
        get(this, 'onSaveAction')(userAction);
      }
      set(this, 'userAction', null);
    });
  }
}
