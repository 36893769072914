export default function formatSeconds(value) {
  if (Number.isNaN(Number(value))) {
    throw new TypeError(`formatSeconds: ${value} is not a number`);
  }
  const secNum = parseInt(value, 10);
  const hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - (hours * 3600)) / 60);
  let seconds = secNum - (hours * 3600) - (minutes * 60);
  minutes = (10 > minutes) ? `0${minutes}` : minutes;
  seconds = (10 > seconds) ? `0${seconds}` : seconds;
  return `${hours}:${minutes}:${seconds}`;
}
