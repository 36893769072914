import { get } from '@ember/object';

import FilterOperation from '../filter-operation';

export default class FilterContains extends FilterOperation {
  options = [
    {
      label: i18n.t('contains'),
      value: 'contains',
    },
    {
      label: i18n.t('not_contains'),
      value: 'not_contains',
    },
  ]

  constructor(params) {
    super(params);

    if (get(this, 'onlyPositive')) {
      get(this, 'options').pop();
    }
  }
}
