import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { canCreateFolder } from '../utils/file-roles';

export default Component.extend({
  auth: service(),

  i18n: service(),

  classNames: ['add-folder-modal'],

  isOpen: false,

  _name: computed(function () {
    return get(this, 'i18n').t('new_folder').toString();
  }),

  fileName: '',

  errors: null,

  willInsertElement() {
    if (get(this, 'nameIsEmpty')) {
      set(this, 'fileName', get(this, '_name'));
    }
  },

  closeModal() {
    set(this, 'isOpen', false);
  },

  canAddFolder: computed('auth.user.level', function () {
    return canCreateFolder(get(this, 'auth.user.level'));
  }),

  nameIsEmpty: computed('fileName', function () {
    return '' === String(get(this, 'fileName')).trim();
  }),

  actions: {
    addFolder() {
      set(this, 'isLoading', true);
      if (get(this, 'nameIsEmpty')) {
        set(this, 'isLoading', false);
        return;
      }

      get(this, 'onAddFolder')(get(this, 'fileName'))
        .then((folder) => {
          set(this, 'fileName', get(this, '_name'));
          get(this, 'onUpdateTable')(folder);
        })
        .catch((errors) => {
          set(this, 'errors', errors);
        })
        .finally(() => {
          set(this, 'isLoading', false);
          set(this, 'isOpen', false);
        });
    },

    toggleModal() {
      set(this, 'fileName', get(this, '_name'));
      this.toggleProperty('isOpen');
    },
  },
});
