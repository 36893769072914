import { get, set } from '@ember/object';

import FilterOperation from '../filter-operation';

export default class FilterBetween extends FilterOperation.extend({
  baseValues: [
    {
      type: 'number',
      value: 'min',
    },
    {
      type: 'number',
      value: 'max',
    },
  ],
}) {
  options = [
    {
      label: i18n.t('between'),
      value: 'between',
    },
    {
      label: i18n.t('not_between'),
      value: 'not_between',
    },
  ]

  constructor(params) {
    super(params);

    if (get(this, 'appendDates')) {
      set(this, 'values', []);
    }

    if (get(this, 'onlyPositive')) {
      get(this, 'options').pop();
    }
  }
}
