import { Factory } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  name() {
    return faker.random.word();
  },

  modules() {
    return '{"crm":true, "intranet":true, "external_sales":true, "residential":true, "multilevel":{"category_level":"1"}}';
  },

  active_sales_year_statement: false,
});
