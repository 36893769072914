import { get, computed } from '@ember/object';
import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

import { getExtensionUrl } from 'neuro-frontend/utils/file-extension';

const MixinModel = Model.extend(Validator, {});

/**
 * @module    nf
 * @submodule nf-models
 */
const isNotDirectory = (key, value, _this) => !_this.get('isDir');
const isToCommerces = (key, value, _this) => isNotDirectory(key, value, _this) && 'commerce' === _this.get('roles');

/**
 * IntranetCommunication
 * --
 *
 * @class     IntranetCommunication
 * @namespace NF
 * @extends   Ember.Model
 * @uses      ModelValidator
 * @public
 */
export default class extends MixinModel {
  changeTracker = { auto: true }

  @attr('datetime') createdAt

  @attr('string') description

  @attr('string') fileUrl

  @attr('number') fileSize

  @attr('boolean', { defaultValue: false }) isDir

  @attr('trimmed-string', { defaultValue: '' }) name

  @attr('string') roles

  @attr('datetime') updatedAt

  @belongsTo('center') center

  @hasMany('commerce') commerces

  @belongsTo('staff-member') creator

  @belongsTo('intranet-file', { inverse: null }) parent

  @belongsTo('intranet-file', { inverse: null }) root

  @computed('fileUrl')
  get fileType() {
    return getExtensionUrl(get(this, 'fileUrl'));
  }

  @computed('isDir', 'roles')
  get validations() {
    return {
      name: {
        presence: {
          message: i18n.t('error.file.name.required'),
        },
      },

      fileUrl: {
        presence: {
          if: isNotDirectory,
          message: i18n.t('error.file.url.required'),
        },
      },

      roles: {
        presence: true,
        inclusion: {
          in: ['management', 'commerce', 'owner', 'all'],
          message: i18n.t('error.file.roles.required'),
        },
      },

      commerces: {
        presence: {
          if: isToCommerces,
          relations: ['hasMany'],
          message: i18n.t('error.file.commerces.required'),
        },
      },
    };
  }
}
