import { Factory } from 'ember-cli-mirage';
import faker from 'faker';
import moment from 'moment';

export default Factory.extend({
  actionType() {
    return faker.random.arrayElement([
      'call',
      'visit',
      'email',
      'form',
      'sent_marketing_info',
      'reservation',
      'rejection',
    ]);
  },

  doneDatetime() {
    return moment(faker.date.recent()).format('YYYY-MM-DDTHH:mm:ss');
  },

  notes() {
    return faker.random.words();
  },

  scheduledDatetime() {
    return moment(faker.date.recent()).format('YYYY-MM-DDTHH:mm:ss');
  },

  title() {
    return faker.random.words();
  },
});
