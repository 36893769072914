import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import proposalStatus from './proposal-status';
import proposalTypes from './proposal-types';

export default [
  {
    groupName: i18n.t('proposal.filters'),
    options: [
      {
        label: i18n.t('status'),
        model: proposalStatus,
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'state',
      },
      {
        label: i18n.t('proposal.type'),
        model: proposalTypes,
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'proposal-type',
      },
      {
        label: i18n.t('category'),
        model: 'categories',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'category',
      },
      {
        label: i18n.t('store'),
        model: 'commerces',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'commerce',
      },
    ],
  },
];
