import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['nf-paginator'],

  firstElement: computed('model', function () {
    return this.get('model.total')
      ? (this.get('model.per_page') * (this.get('model.page') - 1)) + 1
      : 0;
  }),

  lastElement: computed('model', function () {
    if (!this.get('model.total')) {
      return 0;
    }

    return this.get('model.per_page') * this.get('model.page') > this.get('model.total')
      ? this.get('model.total')
      : this.get('model.per_page') * this.get('model.page');
  }),

  actions: {
    nextPage(page) {
      this.get('onChangedPage')(page + 1);
    },
    previousPage(page) {
      this.get('onChangedPage')(page - 1);
    },
  },
});
