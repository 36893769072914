import Service, { inject as service } from '@ember/service';

import { get } from '@ember/object';
import fetch from 'fetch'; // Using polyfill for testing purposes

export default class extends Service {
  @service api

  @service auth

  testModelName = 'email_campaign_test'

  scheduleModelName = 'email_campaign_send'

  _makeRouteApi(modelName, id) {
    return `${get(this, 'api.host')}/api/v4/${modelName}/${id}`;
  }

  _sendRequest(modelName, id, attributes = {}) {
    const route = this._makeRouteApi(modelName, id);
    const data = {
      id,
      type: modelName,
      attributes,
    };

    return fetch(
      route,
      {
        body: JSON.stringify({ data }),
        method: 'PATCH',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${get(this, 'auth.token')}`,
          'Content-Type': 'application/vnd.api+json',
        },
      },
    ).then((response) => response.json());
  }

  sendTest(emailList, campaignId) {
    const modelName = get(this, 'testModelName');
    const attributes = {
      'to-email': emailList,
    };
    return this._sendRequest(modelName, campaignId, attributes);
  }

  sendSchedule(campaignId) {
    const modelName = get(this, 'scheduleModelName');
    return this._sendRequest(modelName, campaignId);
  }
}
