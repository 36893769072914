import { Ability } from 'ember-can';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Ability {
  @service auth

  levels = [
    'admin',
    'manager',
    'commerce',
  ]

  @computed('auth.user.level')
  get canCreate() {
    const {
      auth: {
        user: {
          level,
          canAccessBonusRequest,
        },
      },
    } = this;
    // it is necessary to filter auth.env in order to show bonus request only in cbre enviroment.
    const isCBRE = get(this, 'auth.env').includes('cbre');
    const isDEV = get(this, 'auth.env').includes('dev');
    return ((isCBRE || isDEV) && (['admin', 'manager'].includes(level) || (['commerce'].includes(level) && canAccessBonusRequest)));
  }

  @computed('canCreate', 'auth.user.level')
  get canExport() {
    const {
      auth: {
        user: {
          level,
        },
      },
    } = this;

    return this.canCreate
    && ['admin', 'manager'].includes(level);
  }
}
