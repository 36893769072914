import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class extends Route {
  @service auth

  @service can

  @service pageHeader

  beforeModel() {
    set(
      this,
      'pageHeader.title',
      i18n.t('title.header.dashboards.show'),
    );
  }

  async model(params) {
    const {
      store,
    } = this;
    const id = params.dashboard_id;
    const { dashboard } = await hash({
      dashboard: store.findRecord('dashboard', id),
    });

    if (!this.can.can('read dashboard', null, { dashboardId: id })) {
      this.transitionTo('index');
    }

    set(this, 'pageHeader.isHidden', 'url' === dashboard.type);

    return hash({
      dashboard,
    });
  }

  deactivate() {
    set(this, 'pageHeader.isHidden', false);
  }
}
