import { get, set, action } from '@ember/object';
import { CanMixin } from 'ember-can';

import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

import Route from 'neuro-frontend/routes/base';

export default class extends Route.extend(CanMixin, {}) {
  @service auth

  queryParams = false

  beforeModel() {
    if (!this.can('access of module-residential')) {
      this.transitionTo('index');
    }

    if (!this.can('read all residential-development')) {
      const controller = this.controllerFor('residential.developments.index');
      set(controller, 'fixedFilters', {
        staff_id: get(this, 'auth.user.id'),
      });
    }
  }

  model() {
    const ctrl = this.controllerFor('residential.developments.index');

    return hash({
      residentialDevelopments: ctrl.loadCollection(),
    });
  }

  @action
  deleteResidentialDevelopment(model) {
    if (!model || !get(model, 'id')) {
      throw new TypeError('`model` is not a valid residential-development');
    }

    return model.destroyRecord();
  }

  @action
  editResidentialDevelopment(model) {
    if (!model || !get(model, 'id')) {
      throw new TypeError('`model` is not a valid residential-development');
    }

    this.transitionTo('residential.developments.edit', get(model, 'id'));
  }
}
