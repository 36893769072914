import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, setProperties } from '@ember/object';

import Validator from 'ember-model-validator/mixins/model-validator';
import formatDate from 'neuro-frontend/utils/dates/format-date';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('number', { defaultValue: 0 }) attendant

  @attr('string') code

  @attr('boolean') closed

  @attr('string') closingNotes

  @attr('boolean') crm

  @attr('string') description

  @attr('boolean') digital

  @attr('number', { defaultValue: 0 }) eca // publicity

  @attr('boolean', { defaultValue: true }) enabled

  @attr('date') endDate

  @attr({ defaultValue() { return []; } }) files

  @attr('string') imageUrl

  @attr('boolean') isLoyaltyCard

  @attr('boolean') isRaffle

  @attr('boolean') isGenericReward

  @attr('boolean') isReward

  @attr('number') maxCapacity

  @attr('string') name

  @attr('boolean') printDocument

  @attr('boolean') printName

  @attr('boolean') printPhone

  @attr('boolean', { defaultValue: true }) public

  @attr('number') rating

  @attr('number') receiptsRedemptionDays

  @attr('string') referenceType

  @attr('date') referenceStartDate

  @attr('date') referenceEndDate

  @attr('number', { defaultValue: 12 }) stamps

  @attr('date') startDate

  @attr('string') status

  @attr('number') validDays

  @attr('number', { defaultValue: 0 }) welcomeStamps

  @attr('boolean') withoutRemains

  // Relationships
  @hasMany('campaignGoal') campaignGoals

  @hasMany('campaignrule') campaignrules

  @belongsTo('campaigntype') campaigntype

  @belongsTo('center') center

  @hasMany('commerce') commerces

  @belongsTo('campaign') referenceCampaign

  @belongsTo('campaign') loyaltyCampaign

  @hasMany('campaign', { inverse: 'referenceCampaign' }) referencedCampaigns

  @hasMany('campaign', { inverse: 'loyaltyCampaign' }) loyalyCampaigns

  @belongsTo('segment') segment

  @computed('isRaffle', 'isLoyaltyCard', 'isReward')
  get loyalty() {
    if (this.isRaffle) {
      return this.isLoyaltyCard
        ? 'loyalty-card'
        : 'raffle';
    }
    if (this.isReward) {
      return 'reward';
    }

    return 'none';
  }

  set loyalty(value) {
    switch (value) {
      case 'reward': {
        setProperties(
          this,
          {
            isLoyaltyCard: false,
            isRaffle: false,
            isReward: true,
          },
        );
        break;
      }
      case 'raffle': {
        setProperties(
          this,
          {
            isLoyaltyCard: false,
            isRaffle: true,
            isReward: false,
          },
        );
        break;
      }
      case 'loyalty-card': {
        setProperties(
          this,
          {
            isLoyaltyCard: true,
            isRaffle: true,
            isReward: false,
          },
        );
        break;
      }
      default: {
        setProperties(
          this,
          {
            isLoyaltyCard: false,
            isRaffle: false,
            isReward: false,
          },
        );
        break;
      }
    }
  }

  @computed('referenceStartDate', 'referenceEndDate')
  get referenceDates() {
    return [
      this.referenceStartDate,
      this.referenceEndDate,
    ];
  }

  set referenceDates(value) {
    setProperties(
      this,
      {
        referenceStartDate: formatDate(value[0], 'Y-m-dTH:i:sP', 'DD/MM/YYYY'),
        referenceEndDate: formatDate(value[1], 'Y-m-dTH:i:sP', 'DD/MM/YYYY'),
      },
    );
  }

  @computed()
  get validations() {
    return {
      campaigntype: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('campaigntype') }),
        },
      },

      name: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('name') }),
        },
      },

      endDate: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('end-date') }),
        },
      },

      rating: {
        presence: {
          if: () => this.closed,
          message: i18n.t('required', { attr: i18n.t('rating') }),
        },
      },

      referenceCampaign: {
        presence: {
          if: () => 'campaign' === this.referenceType,
          message: i18n.t('required', { attr: i18n.t('reference-campaign') }),
        },
      },

      referenceEndDate: {
        presence: {
          if: () => 'date' === this.referenceType,
          message: i18n.t('required', { attr: i18n.t('reference-date') }),
        },
      },

      referenceStartDate: {
        presence: {
          if: () => 'date' === this.referenceType,
          message: i18n.t('required', { attr: i18n.t('reference-date') }),
        },
      },

      stamps: {
        presence: {
          if: () => this.isLoyaltyCard,
          message: i18n.t('required', { attr: i18n.t('stamps') }),
        },
      },

      startDate: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('start-date') }),
        },
      },
    };
  }
}
