import { computed, get } from '@ember/object';

import ModuleResidential from './module-residential';

export default class extends ModuleResidential {
  @computed('auth.user.level')
  get canRead() {
    return get(this, 'canAccess');
  }

  @computed('auth.user.level')
  get canCreate() {
    return get(this, 'canRead');
  }

  @computed('auth.user.level', 'auth.centerId', 'model.center.id')
  get canEdit() {
    const centerId = get(this, 'model.center.id');
    return get(this, 'canCreate')
    && centerId === get(this, 'auth.centerId');
  }

  @computed('auth.user.level')
  get canDelete() {
    return get(this, 'canCreate');
  }
}
