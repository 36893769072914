import { htmlSafe } from '@ember/string';
import { computed, get } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import Validator from 'ember-model-validator/mixins/model-validator';

export default Model.extend(Validator, {
  name: attr('trimmed-string', { defaultValue: '' }),

  htmlBody: attr('string'),

  templateSafe: computed('htmlBody', function () {
    return htmlSafe(get(this, 'htmlBody'));
  }),

  validations: computed(function () {
    return {
      name: {
        presence: {
          message: get(this, 'i18n').t('error.email-campaign-template.name.required'),
        },
      },

      htmlBody: {
        presence: {
          message: get(this, 'i18n').t('error.email-campaign-template.htmlBody.required'),
        },
      },
    };
  }),
});
