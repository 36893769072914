import { Factory, trait } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  email() {
    return faker.internet.email();
  },

  name() {
    return faker.name.firstName();
  },

  level: 'admin',

  phone: null,

  admin: trait({
    level: 'admin',
  }),
});
