import { computed, get } from '@ember/object';

import TableList from './table-list';

export default class extends TableList {
  @computed
  get columns() {
    return [
      {
        label: get(this, 'i18n').t('crm.children.name'),
        valuePath: 'fullName',
      },
      {
        label: get(this, 'i18n').t('crm.children.bdate'),
        valuePath: 'bdate',
      },
      {
        label: get(this, 'i18n').t('crm.children.gender'),
        valuePath: 'sex',
      },
      {
        align: 'center',
        cellType: 'tooltip',
        label: '',
        materialIcon: 'chat',
        sortable: false,
        tooltip: 'notes',
        valuePath: 'notes',
      },
      {
        align: 'center',
        cellAction: 'checkIn',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'vertical_align_bottom',
        resource: 'child',
        sortable: false,
        tooltip: get(this, 'i18n').t('check-in'),
        width: '4%',
      },
      {
        align: 'center',
        cellAction: 'checkOut',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'vertical_align_top',
        resource: 'child',
        sortable: false,
        tooltip: get(this, 'i18n').t('child.check-out'),
        width: '4%',
      },
      {
        align: 'center',
        cellAction: 'edit',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'edit',
        sortable: false,
        tooltip: get(this, 'i18n').t('edit'),
        width: '4%',
      },
      {
        label: '',
        cellAction: 'delete',
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: get(this, 'i18n').t('delete'),
        sortable: false,
        align: 'center',
        width: '4%',
      },
    ];
  }

  @computed('onEdit')
  get tableActions() {
    return {
      checkIn: (row) => {
        const onOpenCheckInModal = get(this, 'onOpenCheckInModal');

        if ('function' === typeof onOpenCheckInModal) {
          onOpenCheckInModal(row);
        }
      },

      checkOut: (row) => {
        const onOpenCheckOutModal = get(this, 'onOpenCheckOutModal');

        if ('function' === typeof onOpenCheckOutModal) {
          onOpenCheckOutModal(row.content);
        }
      },
      delete: (...args) => {
        this.send('showDeleteModal', ...args);
      },
      edit: this.onEdit,
    };
  }
}
