import Component from '@ember/component';
import { computed, get } from '@ember/object';
import staffLevels from '../utils/staff-levels';

export default Component.extend({
  classNames: ['user-profile-header'],

  user: null,

  userName: computed('user.name', function () {
    return get(this, 'user.name') || '';
  }),

  userLastName: computed('user.lastname', function () {
    return get(this, 'user.lastname') || '';
  }),

  userFullName: computed('userName', 'userLastName', function () {
    return `${get(this, 'userName')} ${get(this, 'userLastName')}`;
  }),

  userLevel: computed('user.role', function () {
    const levelObj = staffLevels().findBy('value', get(this, 'user.role'));
    return levelObj ? levelObj.label : '';
  }),
});
