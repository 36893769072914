import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default class extends Component {
  classNames = ['month-state']

  @computed('commerce', 'month', 'commerce.closedMonths.[]')
  get isValidated() {
    const commerce = get(this, 'commerce');
    const month = get(this, 'month');

    return 'validated' === commerce.getMonthState(month);
  }

  @computed('commerce', 'month', 'commerce.closedMonths.[]')
  get isPending() {
    const commerce = get(this, 'commerce');
    const month = get(this, 'month');

    return 'closed' === commerce.getMonthState(month);
  }
}
