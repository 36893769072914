import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { CanMixin } from 'ember-can';

export default Route.extend(CanMixin, {
  auth: service(),

  queryParams: false,

  beforeModel() {
    if (!this.can('access of module-residential')) {
      this.transitionTo('index');
    }
  },

  model() {
    const controller = this.controllerFor('residential.owners.index');
    return hash({
      residentialOwners: controller.loadCollection(),
    });
  },
});
