import Route from '@ember/routing/route';
import { CanMixin } from 'ember-can';

export default Route.extend(CanMixin, {
  beforeModel() {
    if (!this.can('access to module-analytics')) {
      this.transitionTo('index');
    }
  },
});
