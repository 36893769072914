import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, get } from '@ember/object';

import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('number') amount

  @attr('string') label

  @attr('string') type

  // Relationships
  @belongsTo('campaign') campaign

  @hasMany('commerce') commerces

  @hasMany('tickets', { inverse: 'campaignrule' }) tickets

  @computed()
  get validations() {
    return {
      amount: {
        numericality: {
          greaterThan: 0,
          message: get(this, 'i18n').t('required', { attr: i18n.t('amount') }),
        },
      },

      commerces: {
        presence: {
          if: () => 'stores' === get(this, 'type'),
          message: get(this, 'i18n').t('required', { attr: i18n.t('commerces') }),
        },
      },

      label: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('label') }),
        },
      },

    };
  }
}
