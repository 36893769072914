import { computed } from '@ember/object';

import ModuleIntranet from './module-intranet';

export default class extends ModuleIntranet {
  moduleName = 'flows'

  roles = [
    'admin',
    'manager',
    'scmanager',
  ]

  @computed('auth.center', 'auth.user.role')
  get canRead() {
    return this.canAccess;
  }
}
