import Component from '@ember/component';

export default class NfButtonComponent extends Component {
  classNames = ['btn', 'waves-effect', 'waves-light', 'nf-button']

  classNameBindings = ['isDisabled:disabled']

  tagName = 'button'

  click() {
    if (
      !this.isLoading
      && !this.isDisabled
      && 'function' === typeof this.onClick
    ) {
      this.onClick();
    }
  }
}
