export default Object.freeze([
  {
    label: i18n.t('email-campaign.single-sending'),
    value: 'single-sending',
  },
  {
    label: i18n.t('email-campaign.hourly'),
    value: 'hourly',
  },
  {
    label: i18n.t('email-campaign.daily'),
    value: 'daily',
  },
  {
    label: i18n.t('email-campaign.weekly'),
    value: 'weekly',
  },
]);
