import { attr, hasMany } from '@ember-data/model';
import { computed, get } from '@ember/object';

import Model from 'ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('string') name

  @attr('string') description

  // Relationships

  @hasMany('service') services

  @computed()
  get validations() {
    return {
      name: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('name') }),
        },
      },

      description: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('description') }),
        },
      },
    };
  }
}
