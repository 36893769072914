export default Object.freeze([
  {
    label: i18n.t('campaign.status.not-redeemable'),
    value: 'not-redeemable',
    color: '#e80042',
  },
  {
    label: i18n.t('campaign.status.redeemable'),
    value: 'redeemable',
    color: '#028a3d',
  },
  {
    label: i18n.t('receipt.status.not-yet-redeemable'),
    value: 'not-yet-redeemable',
    color: '#f8b827',
  },
  {
    label: i18n.t('receipt.status.redeemed'),
    value: 'redeemed',
    color: '#2477b5',
  },
]);
