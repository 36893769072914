import { computed, get } from '@ember/object';

import AbilityModule, { canAccess } from './module';
import { roles as salesRoles } from './intranet-sale';

export default class extends AbilityModule {
  moduleName = 'intranet'

  roles = [
    'admin',
    'manager',
    'commerce',
    'storeManager',
    'scmanager',
    'sales',
    'services',
  ]

  @computed('canAccess', 'auth.center', 'auth.user.role')
  get canAccessMenu() {
    const {
      auth,
    } = this;

    const role = get(this, 'auth.user.role');

    return ((this.canAccess && 'sales' !== role) || canAccess(auth, salesRoles, 'externalSales'));
  }
}
