import Service, { inject as service } from '@ember/service';

import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { debug } from '@ember/debug';

export default class extends Service {
  @service googleMapsApi

  // Madriz
  defaultLocation = {
    lat: 40.416778,
    lng: -3.703790,
  }

  createMarker(address) {
    if (!isEmpty(address)) {
      return get(this, 'googleMapsApi.google')
        .then((google) => new google.maps.Geocoder())
        .then((geocodes) => new Promise((resolve) => geocodes.geocode(
          { address },
          (results, status) => {
            if ('OK' === status) {
              resolve({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              resolve(this.defaultLocation);
            }
          },
        )))
        .catch((err) => debug(err));
    }

    return Promise.resolve(this.defaultLocation);
  }
}
