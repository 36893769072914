import Component from '@ember/component';
import { get, action } from '@ember/object';
import { classNames } from '@ember-decorators/component';

@classNames('nf-chips')
export default class extends Component.extend({
    dropdownClass: '',

    label: '',

    options: [],

    propSelected: [],

    searchField: '',

    selectAllEnabled: true,
  }) {
  @action
  selectAllOptions() {
    get(this, 'propSelected').clear();
    get(this, 'propSelected').pushObjects(get(this, 'options'));
    if ('function' === typeof this.onChange) {
      this.onChange();
    }
  }

  @action
  deselectAllOptions() {
    get(this, 'propSelected').clear();
    if ('function' === typeof this.onChange) {
      this.onChange();
    }
  }
}
