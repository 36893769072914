import Controller from '@ember/controller';
import { get, set, getProperties } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import fetch from 'fetch';
import { inject as service } from '@ember/service';
// import { action } from '@ember/object';

export default class extends Controller {
  @service api

  validate() {
    const {
      password,
      passConfirmation,
    } = getProperties(this, 'password', 'passConfirmation');
    let valid = true;

    set(this, 'errors', {});

    if (!password || !password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,}$/)) {
      set(this, 'errors.password', [get(this, 'i18n').t('pass-required')]);
      valid = false;
    }

    if (passConfirmation !== password) {
      set(this, 'errors.passConfirmation', [get(this, 'i18n').t('match-pass')]);
      valid = false;
    }

    return valid;
  }

  @task
  updatePassword = function* () {
    set(this, 'didError', false);

    if (this.validate()) {
      const {
        ok,
      } = yield fetch(
        `${get(this, 'api.host')}/api/v4/staff_members/change_password/${get(this, 'model.staffMemberId')}`,
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
          },
          body: JSON.stringify({
            data: {
              id: get(this, 'model.staffMemberId'),
              type: 'staff_members',
              attributes: {
                'pass-old': get(this, 'model.token'),
                pass: get(this, 'password'),
                'pass-confirmation': get(this, 'passConfirmation'),
              },
            },
          }),
          method: 'PATCH',
        },
      );

      if (ok) {
        this.transitionToRoute('user.login');
      } else {
        set(this, 'didError', true);
      }
    }
  }
}
