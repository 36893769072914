import { computed, get } from '@ember/object';

import ModuleResidential from './module-residential';

export default class extends ModuleResidential {
  @computed('model.residentialOffers.length')
  get canCreateOffer() {
    return !get(this, 'model.residentialOffers.length');
  }

  @computed('canCreateOffer', 'model.residentialOffers.firstObject')
  get canCloseOffer() {
    const canCreateOffer = get(this, 'canCreateOffer');
    const offer = get(this, 'model.residentialOffers.firstObject');

    return !canCreateOffer && ('open' === get(offer, 'state'));
  }
}
