import AbilityModule from './module';

export default class extends AbilityModule {
  moduleName = 'tickets'

  roles = [
    'admin',
    'manager',
    'crmmanager',
    'crmoperator',
  ]
}
