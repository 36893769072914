import Component from '@ember/component';
import {
  get, set, computed, action,
} from '@ember/object';

import { inject as service } from '@ember/service';

import documentTypes from 'neuro-frontend/enums/document-types';
import maritalStatus from 'neuro-frontend/enums/marital-status';
import personTypes from 'neuro-frontend/enums/person-types';
import purchaserTypes from 'neuro-frontend/enums/purchaser-types';
import sexes from 'neuro-frontend/enums/sexes';
import residentialUserStates from 'neuro-frontend/enums/residential-user-states';

export default class extends Component {
  @service can

  @service pusher

  @service store

  @service auth

  classNames = ['form-user']

  documentTypes = documentTypes

  maritalStatus = maritalStatus

  personTypes = personTypes

  purchaserTypes = purchaserTypes

  sexes = sexes

  ages = [
    {
      label: i18n.t('users.age.under-35'),
      value: 'under-35',
    },
    {
      label: i18n.t('users.age.35-45'),
      value: '35-45',
    },
    {
      label: i18n.t('users.age.50-65'),
      value: '45-65',
    },
    {
      label: i18n.t('users.age.over-65'),
      value: 'over-65',
    },
  ]

  nationalities = [
    {
      label: i18n.t('users.nationality.spanish'),
      value: 'spanish',
    },
    {
      label: i18n.t('users.nationality.foreigner'),
      value: 'foreigner',
    },
  ]

  foreignNationalities = [
    {
      label: i18n.t('users.foreign-nationality.french'),
      value: 'french',
    },
    {
      label: i18n.t('users.foreign-nationality.portuguese'),
      value: 'portuguese',
    },
    {
      label: i18n.t('users.foreign-nationality.italian'),
      value: 'italian',
    },
    {
      label: i18n.t('users.foreign-nationality.united-kingdom'),
      value: 'united-kingdom',
    },
    {
      label: i18n.t('users.foreign-nationality.german'),
      value: 'german',
    },
    {
      label: i18n.t('users.foreign-nationality.mexican'),
      value: 'mexican',
    },
    {
      label: i18n.t('users.foreign-nationality.brazilian'),
      value: 'brazilian',
    },
  ]

  civilStatuses = [
    {
      label: i18n.t('users.civil-status.single'),
      value: 'single',
    },
    {
      label: i18n.t('users.civil-status.married'),
      value: 'married',
    },
    {
      label: i18n.t('users.civil-status.divorced'),
      value: 'divorced',
    },
    {
      label: i18n.t('users.civil-status.widower'),
      value: 'widower',
    },
  ]

  leadOrigins = [
    {
      label: i18n.t('users.lead-origin.web_cbre'),
      value: 'web_cbre',
    },
    {
      label: i18n.t('users.lead-origin.landing_origami'),
      value: 'landing_origami',
    },
    {
      label: i18n.t('users.lead-origin.valla'),
      value: 'valla',
    },
    {
      label: i18n.t('users.lead-origin.referenced'),
      value: 'referenced',
    },
    {
      label: i18n.t('users.lead-origin.event'),
      value: 'event',
    },
    {
      label: i18n.t('users.lead-origin.social_network'),
      value: 'social_network',
    },
    {
      label: i18n.t('users.lead-origin.idealista'),
      value: 'idealista',
    },
    {
      label: i18n.t('users.lead-origin.emailing'),
      value: 'emailing',
    },
    {
      label: i18n.t('users.lead-origin.developer_website'),
      value: 'developer_website',
    },
    {
      label: i18n.t('users.lead-origin.press'),
      value: 'press',
    },
    {
      label: i18n.t('users.lead-origin.selling_point'),
      value: 'selling_point',
    },

  ]

  typologies = [
    {
      label: i18n.t('users.typology.multi-family'),
      value: 'multi-family',
    },
    {
      label: i18n.t('users.typology.single-family'),
      value: 'single-family',
    },
  ]

  rooms = [
    {
      label: i18n.t('users.rooms.one'),
      value: '1',
    },
    {
      label: i18n.t('users.rooms.two'),
      value: '2',
    },
    {
      label: i18n.t('users.rooms.tree'),
      value: '3',
    },
    {
      label: i18n.t('users.rooms.four'),
      value: '4',
    },
    {
      label: i18n.t('users.rooms.five'),
      value: '5',
    },
  ]

  reasonsForPurchase = [
    {
      label: i18n.t('users.reason-for-purchase.first-home'),
      value: 'first-home',
    },
    {
      label: i18n.t('users.reason-for-purchase.second-home'),
      value: 'second-home',
    },
    {
      label: i18n.t('users.reason-for-purchase.investment'),
      value: 'investment',
    },
    {
      label: i18n.t('users.reason-for-purchase.replacement'),
      value: 'replacement',
    },
  ]

  states = residentialUserStates;

  reasonsForDiscard = [
    {
      label: i18n.t('users.reason-for-discard.location'),
      value: 'location',
    },
    {
      label: i18n.t('users.reason-for-discard.price'),
      value: 'price',
    },
    {
      label: i18n.t('users.reason-for-discard.surface'),
      value: 'surface',
    },
    {
      label: i18n.t('users.reason-for-discard.deadline'),
      value: 'deadline',
    },
    {
      label: i18n.t('users.reason-for-discard.buy_competition'),
      value: 'buy_competition',
    },
    {
      label: i18n.t('users.reason-for-discard.other'),
      value: 'other',
    },
  ]

  sourceOfFunds = [
    {
      label: i18n.t('users.source-of-funds.own'),
      value: 'own',
    },
    {
      label: i18n.t('users.source-of-funds.loan'),
      value: 'loan',
    },
  ]

  noCampaignsMessage = {
    label: i18n.t('user.no-assigned-campaign-message'),
    color: '#cccccc',
  }

  noSegmentsMessage = {
    label: i18n.t('user.no-assigned-segment-message'),
    color: '#cccccc',
  }

  @computed('auth.center.userRequired')
  get isRequiredCommerce() {
    // @TODO look for a general solution
    const userRequired = get(this, 'auth.center.userRequired');
    return userRequired ? userRequired.includes('commerce') : false;
  }

  @computed
  get showActions() {
    return get(this, 'model.id') && this.can.can('access to module-residential');
  }

  @computed('model.campaigns.[]')
  get segmentedCampaigns() {
    if (!this.model) {
      return [];
    }

    const {
      model: {
        campaigns,
      },
    } = this;

    return campaigns.filter((campaign) => campaign.belongsTo('segment').id());
  }

  @computed('campaigns')
  get manualCampaigns() {
    const {
      campaigns,
    } = this;

    if (!campaigns) {
      return [];
    }

    return campaigns.filter((campaign) => !campaign.belongsTo('segment').id());
  }

  @computed('model.campaigns.[]')
  get userManualCampaigns() {
    if (!this.model) {
      return [];
    }

    const {
      model: {
        campaigns,
      },
    } = this;

    return campaigns.filter((campaign) => !campaign.belongsTo('segment').id());
  }

  set userManualCampaigns(selectedCampaign) {
    set(this, 'model.campaigns', selectedCampaign);
  }

  @computed
  get tabs() {
    let tabs = [
      {
        icon: 'perm_identity',
        label: get(this, 'i18n').t('users.general_data'),
        name: 'general',
      },
    ];

    const resiTabs = [
      {
        icon: 'swap_horiz',
        label: get(this, 'i18n').t('users.sale_process'),
        name: 'sale_process',
      },
    ];

    const receiptsTabs = [
      {
        icon: 'receipt',
        label: get(this, 'i18n').t('users.receipts'),
        name: 'receipts',
      },
    ];

    const loyaltyTabs = [
      {
        icon: 'loyalty',
        label: get(this, 'i18n').t('users.loyalty'),
        name: 'loyalty',
      },
    ];

    tabs = get(this, 'model.id') && this.can.can('access to module-residential') ? tabs.concat(resiTabs) : tabs;
    tabs = get(this, 'model.id') && (this.can.can('access to module-tickets') || this.can.can('access to module-loyalty-cards')) ? tabs.concat(receiptsTabs) : tabs;
    tabs = get(this, 'model.id') && (this.can.can('access to module-tickets') || this.can.can('access to module-loyalty-cards')) ? tabs.concat(loyaltyTabs) : tabs;

    return tabs;
  }

  init(...args) {
    super.init(...args);
    set(this, 'errors', {});
    set(this, 'state', '');
    if (this.model) {
      get(this, 'pusher').connectToChannel(
        get(this.model, 'id'),
        (signatureUrl) => {
          set(this, 'model.signatureUrl', signatureUrl);
        },
      );
    }
  }

  willDestroyElement() {
    if (this.model) {
      this.pusher.disconnectToChannel(this.model.id);
    }
  }

  @action
  searchCountries(term) {
    set(get(this, 'model'), 'province', null);

    if (term && 2 <= term.length) {
      return get(this, 'store').query('country', {
        filter: {
          q: term,
        },
      });
    }
    return [];
  }

  @action
  searchProvince(term) {
    if (term && 2 <= term.length) {
      return get(this, 'store').query('province', {
        filter: {
          q: term,
          country_id: get(this, 'model.country.id'),
        },
      });
    }
    return [];
  }

  @action
  toggleHasEmail() {
    this.toggleProperty('model.hasEmail');
  }

  @action
  addResidentialDevelopment() {
    if (this.addDevelopments) {
      this.addDevelopments();
    }
  }
}
