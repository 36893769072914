import { computed, get } from '@ember/object';
import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});
export default class BonusRequestModel extends MixinModel {
  @attr('number') bonusPercentage

  @attr('number') bonusPeriod

  @attr('datetime') createdAt

  @attr('number') depreciation

  @attr('number') employeeCost

  @attr('number') employeeHours

  @attr('number') exercise

  @attr('boolean', { defaultValue: false }) franchisee

  @attr('number') franchiseeExpenses

  @attr('string') month

  @attr('number') otherExpenses

  @attr('number') qSales

  @attr('number') sales

  @attr('number') saleCost

  @attr('number') supplies

  @attr('number') surface

  // Relationships

  @belongsTo('center') center

  @belongsTo('bonusShop') bonusShop

  @computed()
  get validations() {
    return {
      bonusShop: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.bonus-shop') }),
        },
      },

      depreciation: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.depreciation') }),
        },
      },

      employeeCost: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.employee-cost') }),
        },
      },

      employeeHours: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.employee-hours') }),
        },
      },

      exercise: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.exercise') }),
        },
      },

      franchisee: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.franchisee') }),
        },
      },

      franchiseeExpenses: {
        presence: {
          if: (key, value, _this) => _this.franchisee,
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.franchisee-expenses') }),
        },
      },

      month: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.month') }),
        },
      },

      sales: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.sales') }),
        },
      },

      saleCost: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.sale-cost') }),
        },
      },

      supplies: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('bonusRequest.supplies') }),
        },
      },
    };
  }
}
