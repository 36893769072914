import { underscore } from '@ember/string';
import { alias } from '@ember/object/computed';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import RESTAdapter from 'ember-data/adapters/rest';

export default RESTAdapter.extend(DataAdapterMixin, {
  api: service(),

  host: alias('api.host'),

  namespace: alias('api.namespace'),

  authorize(xhr) {
    const accessToken = this.get('session.data.authenticated.token');
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
  },

  ajaxOptions(url, type, options = {}) {
    const hash = options;

    hash.crossDomain = true;
    hash.xhrFields = {
      withCredentials: true,
    };

    return this._super(url, type, hash);
  },

  pathForType(type) {
    return underscore(pluralize(type));
  },
});
