import Mixin from '@ember/object/mixin';
import ArrayProxy from '@ember/array/proxy';
import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';

export default Mixin.create({
  columns: [],

  csv: service(),
  /**
   * csvHeaderColumns
   * --
   *
   * It is an Array of Objects with the definition of the different columns
   * that we are going to have for the export of the CSV and a header row.
   *
   * The columns Array should have the following format:
   *
   * ```
   * [
   *  {
   *    label: String, // the text that is going to show in the CSV headers
   *    valuePath: String // the name of the attribute in the model
   *  }
   * ]
   * ```
   *
   * By default, it computes using the specific format that provides the
   * library Ember Light Table, but you can override this attribute with
   * a custom Array, only if you respect the format.
   *
   * Returns a object with attribute columns and a header.
   * By default, header is a empty array
   *
   * @property csvHeaderColumns
   * @type Array
   * @public
   */
  csvHeaderColumns: computed('columns', function () {
    if (!(get(this, 'columns') instanceof Array)) {
      throw new TypeError(`csvHeaderColumns: ${get(this, 'columns')} should be of type Array`);
    }

    return {
      extra: [],
      columns: get(this, 'columns').filter((column) => get(column, 'label') && get(column, 'valuePath')),
    };
  }),

  createCSV(model, headerColumns = get(this, 'csvHeaderColumns'), decimalSeparator = '.') {
    if (!(model instanceof ArrayProxy)) {
      throw new TypeError(`createCSV: ${model} should be of type Ember.ArrayProxy`);
    }

    if (!get(headerColumns, 'columns.length')) {
      throw new IllegalArgumentError(`createCSV: ${get(this, 'csvHeaderColumns')} shouldn't be empty`);
    }

    return get(this, 'csv').jsonToCsv(
      this.getCsvData(headerColumns, model, decimalSeparator),
      {
        separator: ',',
        withSeparator: false,
      },
    );
  },
  /**
  * getCsvData
  * ----
  *
  * Return a array of arrays where each internal array is the set of data
  * to be rendered for that. Additionally, it can contains a extra row (header).
  *
  */
  getCsvData(headerColumns, model, decimalSeparator = '.') {
    const { columns, extra } = headerColumns;
    const csvData = [
      columns.map((column) => get(column, 'label')),
    ]
      .concat(model
        .map((elem) => columns
          .map((column) => {
            const value = get(elem, get(column, 'valuePath'));
            return 'number' === typeof value
              ? value.toString().replace('.', decimalSeparator)
              : value;
          })));

    return (get(extra, 'length')) ? [extra].concat(csvData) : csvData;
  },

  exportCSV(model, fileName = 'export.csv', headerColumns = get(this, 'csvHeaderColumns')) {
    if (!(model instanceof ArrayProxy)) {
      throw new TypeError(`exportCSV: ${model} should be of type Ember.ArrayProxy`);
    }

    if (!get(headerColumns, 'columns.length')) {
      throw new IllegalArgumentError(`exportCSV: ${get(this, 'csvHeaderColumns')} shouldn't be empty`);
    }

    get(this, 'csv').export(
      this.getCsvData(headerColumns, model, ','),
      {
        fileName,
        withSeparator: false,
      },
    );
  },
});
