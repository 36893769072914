import { computed, get } from '@ember/object';

import { inject as service } from '@ember/service';

import ModuleCMS from './module-cms';

export default class extends ModuleCMS {
  @service auth

  roles = [
    'admin',
    'manager',
    'scmanager',
    'storeManager',
  ]

  @computed('auth.user.level')
  get canRead() {
    const {
      auth: {
        user: {
          role,
        },
      },
    } = this;

    return get(this, 'canAccess')
    && this.roles.includes(role);
  }

  @computed('auth.user.role')
  get canCreate() {
    const {
      auth: {
        user: {
          role,
        },
      },
    } = this;

    return get(this, 'canRead')
    && ['admin', 'manager', 'scmanager'].includes(role);
  }

  @computed('auth.user.role')
  get canEdit() {
    return get(this, 'canCreate');
  }

  @computed('auth.user.role')
  get canDelete() {
    return get(this, 'canCreate');
  }
}
