import {
  get, set, action, computed,
} from '@ember/object';

import { inject as service } from '@ember/service';

import ResidentialProperty from 'neuro-frontend/models/residential-property';
import residentialPropertiesColumns from 'neuro-frontend/utils/residential-properties-columns';
import propertyFilters from 'neuro-frontend/enums/property-filters';
import beautifyFilters from 'neuro-frontend/utils/beautify-filters';

import TableList from './table-list';

export default class extends TableList {
  @service auth

  @computed('view', 'currency')
  get columns() {
    const {
      currency,
      view,
    } = this;
    return residentialPropertiesColumns(view, currency);
  }

  @computed('auth.center')
  get modelFilters() {
    const center = get(this, 'auth.center');

    return beautifyFilters(propertyFilters, center);
  }

  @computed('onEdit')
  get tableActions() {
    const onEdit = get(this, 'onEdit');

    return {
      publish: (...args) => {
        this.send('publish', ...args);
      },
      unpublish: (...args) => {
        this.send('unpublish', ...args);
      },
      block: (...args) => {
        this.send('block', ...args);
      },
      duplicate: (...args) => {
        this.send('duplicate', ...args);
      },
      delete: (...args) => {
        this.send('showDeleteModal', ...args);
      },
      edit: onEdit,
    };
  }

  @action
  block(row) {
    const property = row ? get(row, 'content') : undefined;

    if (!(property instanceof ResidentialProperty)) {
      throw new TypeError(`actions.block: ${property} is not of type Residential Property`);
    }

    set(property, 'state', 'blocked');

    property.save().then(() => {
      this.notifyPropertyChange('model');
    });
  }

  @action
  async duplicate(row) {
    const property = row ? get(row, 'content') : undefined;
    let newProperty = null;

    if (!(property instanceof ResidentialProperty)) {
      throw new TypeError(`actions.duplicate: ${property} is not of type Residential Property`);
    }

    newProperty = await property.copy();
    newProperty.save().then((model) => {
      if ('function' === typeof get(this, 'onDuplicate')) {
        get(this, 'onDuplicate')(model);
      }
    });
  }

  @action
  publish(row) {
    const property = row ? get(row, 'content') : undefined;

    set(property, 'visibility', 'published');

    property.save().then(() => {
      this.notifyPropertyChange('model');
    });
  }

  @action
  unpublish(row) {
    const property = row ? get(row, 'content') : undefined;

    set(property, 'visibility', 'draft');

    property.save().then(() => {
      this.notifyPropertyChange('model');
    });
  }
}
