import { Factory } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  center_id: 1,

  default: false,

  name() {
    return faker.random.word();
  },
});
