import Service from '@ember/service';
import XLSX from 'xlsx';

export default class XlsxService extends Service {
  readFile(file) {
    // @TODO add reject case
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (f) => {
        const data = f.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        resolve(json);
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
