import { get, set } from '@ember/object';
import RSVP from 'rsvp';
import FormRoute from 'neuro-frontend/routes/form';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';

const Route = FormRoute.extend(UnauthenticatedRouteMixin, {});

export default class extends Route {
  model() {
    const store = get(this, 'store');

    return RSVP.hash({
      staff: store.createRecord('staff-member'),
    });
  }

  setupController(ctrl, model) {
    set(ctrl, 'apiErrors', []);
    super.setupController(ctrl, model);
  }
}
