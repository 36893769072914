import { get, computed } from '@ember/object';

import TableList from './table-list';

export default class extends TableList {
  classNames = ['table-list', 'table-sale-process']

  @computed
  get columns() {
    return [
      {
        label: get(this, 'i18n').t('sale-process.request.name'),
        valuePath: 'name',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('sale-process.request.date'),
        valuePath: 'createdAt',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('sale-process.request.properties-available'),
        valuePath: 'propertiesCount',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('sale-process.request.properties-blocked'),
        valuePath: 'blockedPropertiesCount',
        sortable: false,
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: get(this, 'i18n').t('edit'),
        sortable: false,
        align: 'center',
        width: '4%',
      },
    ];
  }
}
