import { computed, get } from '@ember/object';

import AbilityModule from './module';

const ADMIN = 'admin';
const MANAGER = 'manager';

export default class extends AbilityModule {
  @computed('auth.user.level')
  get canCreate() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess') && [ADMIN, MANAGER].includes(level);
  }

  @computed('auth.user.level', 'auth.user.id', 'model.creator', 'model.id', 'model.deletedAt')
  get canEdit() {
    const model = get(this, 'model');
    const modelId = Number(get(this, 'model.id'));
    const creatorId = get(this, 'model.creator');
    const userId = Number(get(this, 'auth.user.id'));
    const level = get(this, 'auth.user.level');

    return !model.deletedAt && get(this, 'canCreate')
      && (creatorId === userId || ADMIN === level || modelId === userId);
  }

  @computed('auth.user.level', 'auth.user.id', 'model.creator', 'model.deletedAt')
  get canDelete() {
    const model = get(this, 'model');
    return !model.deletedAt && get(this, 'canEdit');
  }

  @computed('auth.user.level', 'model.deletedAt')
  get canRestore() {
    const model = get(this, 'model');
    const level = get(this, 'auth.user.level');

    return model.deletedAt && [ADMIN].includes(level);
  }
}
