import Model from 'ember-data/model';
import { attr, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('string') name

  @attr('string') vatNumber

  @attr('string') yardiCode

  // Relationships
  @hasMany('leasingCenter') leasingCenter
}
