import { get, getProperties, computed } from '@ember/object';
import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import Validator from 'ember-model-validator/mixins/model-validator';

import { isEmpty } from '@ember/utils';

import { fragmentArray } from 'ember-data-model-fragments/attributes';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel.extend({
  languages: fragmentArray('residentialDevelopmentLanguage'),
}) {
  @attr('string') address

  @attr('string') bankAccountNumber

  @attr('number') bookingPrice

  @attr('string') buildingState

  @attr('number') builtSurface

  @attr('string') bus

  @attr('string', { defaultValue() { return ''; } }) cadastralReference

  @attr('string') callToAction

  @attr('string') city

  @attr('string') commercialization

  @attr('number') commonZonesSurface

  @attr('string', { defaultValue() { return ''; } }) contactName

  @attr('string', { defaultValue() { return ''; } }) contactPhone

  @attr('trimmed-string', { defaultValue() { return ''; } }) contactEmail

  @attr('string') currency

  @attr('date') deliveryDate

  @attr('date') dischargeDate

  @attr('string', { defaultValue() { return ''; } }) energeticCertification

  @attr('string', { defaultValue() { return ''; } }) estate

  @attr('boolean') featured

  @attr({ defaultValue() { return []; } }) files

  @attr('number') garagesNumber

  @attr('number') income

  @attr('number', { defaultValue() { return 0.0; } }) latitude

  @attr('number', { defaultValue() { return 0.0; } }) longitude

  @attr('string') management

  @attr('string') name

  @attr('number') occupationPercentage

  @attr('string') postalCode

  @attr('number') propertiesNumber

  @attr('string') qualification

  @attr('string') reference

  @attr('number') rentPrice

  @attr('number') sellPrice

  @attr('string', { defaultValue() { return ''; } }) responsibleEmail

  @attr('string', { defaultValue() { return ''; } }) responsibleName

  @attr('string', { defaultValue() { return ''; } }) responsiblePhone

  @attr('string', { defaultValue() { return ''; } }) responsiblePosition

  @attr('number') storageRoomsNumber

  @attr('string') subway

  @attr('string') train

  @attr('string') unitType

  @attr('number') usefulSurface

  @attr('string') videoUrl

  @attr('string') housingType

  @attr('string', { defaultValue() { return 'draft'; } }) visibility

  @attr('json', {
    defaultValue() {
      return {
        'residential-properties-show': true,
        'residential-properties-price-show': true,
        'residential-properties-details-show': true,
      };
    },
  }) webPermissions

  // Relationships
  @belongsTo('residential-owner') residentialOwner

  @belongsTo('country') country

  @belongsTo('province') province

  @hasMany('residential-property-type') residentialPropertyTypes

  @hasMany('residential-quality') residentialQualities

  @hasMany('residential-service') residentialServices

  @hasMany('staffMember') staff

  // Alias

  @alias('defaultLanguage.notes') notes

  @computed('address', 'postalCode', 'city', 'province')
  get completeAddress() {
    const {
      address,
      postalCode,
      city,
      province,
    } = getProperties(this, ['address', 'postalCode', 'city', 'province']);

    if (isEmpty(address) || isEmpty(postalCode) || isEmpty(city) || isEmpty(province)) {
      return null;
    }

    return `${address} ${postalCode} ${city} ${get(province, 'name')} ${get(province, 'country.name')}`;
  }

  @computed('languages')
  get defaultLanguage() {
    return this.languages.findBy('code', 'es');
  }

  @computed('visibility')
  get isBookable() {
    const visibility = get(this, 'visibility');

    return visibility.includes('bookable');
  }

  @computed('province.name', 'city')
  get zone() {
    const city = get(this, 'city');
    const name = get(this, 'province.name');

    return `${city}, ${name}`;
  }

  @computed()
  get validations() {
    const i18n = get(this, 'i18n');

    return {
      address: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('address') }),
        },
      },

      bookingPrice: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('booking-price') }),
          if: (key, value, _this) => get(_this, 'isBookable'),
        },
      },

      buildingState: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('building-state') }),
        },
      },

      builtSurface: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('built-surface') }),
        },
      },

      city: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('city') }),
        },
      },

      commercialization: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('commercialization') }),
        },
      },

      commonZonesSurface: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('common-zones-surface') }),
        },
      },

      currency: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('currency') }),
        },
      },

      dischargeDate: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('discharge-date') }),
        },
      },

      management: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('management') }),
        },
      },

      name: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('name') }),
        },
      },

      occupationPercentage: {
        custom: {
          validation(key, value, _this) {
            return value || 'sold' !== get(_this, 'callToAction');
          },
          message: i18n.t('required', { attr: i18n.t('occupation-percentage') }),
        },
      },

      postalCode: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('postal-code') }),
        },
      },

      propertiesNumber: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('properties-number') }),
        },
      },

      province: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('province') }),
        },
      },

      residentialOwner: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('residential-owner') }),
        },
      },

      residentialPropertyTypes: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('residential-properties.type') }),
        },
      },

      unitType: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('unit-type') }),
        },
      },

      usefulSurface: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('useful-surface') }),
        },
      },

      visibility: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('visibility') }),
        },
      },
    };
  }
}
