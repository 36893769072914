import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

export default class TicketModel extends Model {
  @attr('string') code

  @attr('number') counter

  @attr('datetime') createdAt

  @attr('boolean') isRedeemed

  @attr('number') prints

  @belongsTo('campaignrule') campaignrule

  @belongsTo('loyalty-card') loyaltyCard

  @belongsTo('user') user
}
