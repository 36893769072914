import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo } from 'ember-data/relationships';
/**
 * t('concepts.airplane_tickets')
 * t('concepts.bar')
 * t('concepts.cancellations')
 * t('concepts.cinema_tickets')
 * t('concepts.coffee')
 * t('concepts.drones')
 * t('concepts.equipment')
 * t('concepts.free_devices')
 * t('concepts.game')
 * t('concepts.general')
 * t('concepts.general_variable')
 * t('concepts.gm1')
 * t('concepts.gm2')
 * t('concepts.magazines')
 * t('concepts.phonecard')
 * t('concepts.prescription')
 * t('concepts.pub')
 * t('concepts.radio-modelism')
 * t('concepts.receipts')
 * t('concepts.school_mat')
 * t('concepts.stamps_and_values')
 * t('concepts.telecommunications')
 * t('concepts.tobacco')
 * t('concepts.tobacco_game')
 * t('concepts.trans_currency')
 * t('concepts.uploads_of_credit')
 * t('concepts.online_sales')
 * t('concepts.rent_car_services')
 * t('concepts.fees_delivery')
 *
 */
export default Model.extend({
  center: belongsTo('center'),

  default: attr('boolean'),

  name: attr('trans-string'),

  yardiCode: attr('string'),
});
