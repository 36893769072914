import { get, computed } from '@ember/object';

import TableList from './table-list';

export default class extends TableList {
  classNames = ['table-list', 'table-sale-process']

  @computed
  get columns() {
    return [
      {
        label: get(this, 'i18n').t('residential-properties.location'),
        valuePath: 'residentialProposal.residentialProperty.residentialDevelopment.city',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('residential-development.development'),
        valuePath: 'residentialProposal.residentialProperty.residentialDevelopment.name',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('residential-properties.property'),
        valuePath: 'residentialProposal.residentialProperty.block',
        sortable: false,
      },
      {
        valuePath: 'residentialProposal.residentialProperty.floor',
        sortable: false,
      },
      {
        valuePath: 'residentialProposal.residentialProperty.letter',
        sortable: false,
      },
      {
        valuePath: 'closingDate',
        sortable: false,
      },
      {
        cellType: 'amount',
        label: get(this, 'i18n').t('residential-offers.price'),
        valuePath: 'price',
        sortable: false,
      },
      {
        align: 'center',
        cellAction: 'showForm',
        cellType: 'material-icon',
        label: '',
        materialIcon: 'assignment',
        sortable: false,
        tooltip: get(this, 'i18n').t('residential-sale-process.document_sent'),
        width: '4%',
      },
      {
        align: 'center',
        cellAction: 'openClosingOfferModal',
        cellType: 'material-icon-close-offer',
        label: '',
        materialIcon: 'undo',
        sortable: false,
        tooltip: get(this, 'i18n').t('residential-proposal.close_offer'),
        width: '4%',
      },
    ];
  }

  @computed
  get tableActions() {
    return {
      openClosingOfferModal: (row) => {
        const onOpenClosingOfferModal = get(this, 'onOpenClosingOfferModal');

        if ('function' === typeof onOpenClosingOfferModal) {
          onOpenClosingOfferModal(row.content);
        }
      },

      showForm: (row) => {
        const onShowForm = get(this, 'onShowForm');

        if ('function' === typeof onShowForm) {
          onShowForm(row.content);
        }
      },
    };
  }
}
