import AbilityModule from './module';

export default class extends AbilityModule {
  moduleName = 'marketingAnalytics'

  roles = [
    'admin',
    'owner',
    'manager',
  ]
}
