import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import { computed, setProperties } from '@ember/object';

import Copyable from 'ember-data-copyable';
import Validator from 'ember-model-validator/mixins/model-validator';
import moment from 'moment';

export default class extends Model.extend(Validator, Copyable, {}) {
  @attr('number') blocked

  @attr('number') bounced

  @attr('number') clicked

  @attr('number') delivered

  @attr('string', { defaultValue: 'single-sending' }) frequency

  @attr('datetime') endDatetime

  @attr('string') htmlBody

  @attr('trimmed-string', { defaultValue: '' }) name

  @attr('number') opened

  @attr('number') spam

  @attr('datetime') startDatetime

  @attr('trimmed-string') state

  @attr('trimmed-string', { defaultValue: '' }) subject

  @belongsTo('campaign') campaign

  @belongsTo('center') center

  @belongsTo('segment') segment

  @computed('htmlBody')
  get hasUnsubLink() {
    return this.htmlBody && this.htmlBody.includes('[[UNSUB_LINK');
  }

  @computed('startDatetime')
  get startDate() {
    return this.startDatetime;
  }

  set startDate(value) {
    if ('single-sending' === this.frequency) {
      setProperties(
        this,
        {
          startDatetime: value,
          endDatetime: value,
        },
      );
    } else {
      setProperties(
        this,
        {
          startDatetime: value,
          endDatetime: null,
        },
      );
    }
  }

  @computed
  get validations() {
    return {
      name: {
        presence: {
          message: i18n.t('error.email-campaign.name.required'),
        },
      },

      subject: {
        presence: {
          message: i18n.t('error.email-campaign.subject.required'),
        },
      },

      htmlBody: {
        presence: {
          message: i18n.t('error.email-campaign.htmlBody.required'),
        },
      },

      segment: {
        presence: {
          relations: ['belongsTo'],
          message: i18n.t('error.email-campaign.segment.required'),
        },
      },

      center: {
        presence: true,
      },

      startDatetime: {
        custom: [{
          validation(key, value) {
            return !moment(value).isBefore(new Date());
          },
          message: i18n.t('error.email-campaign.date.invalid_past'),
        }, {
          validation(key, value) {
            return !!value;
          },
          message: i18n.t('error.email-campaign.date.required'),
        }],
      },

      endDatetime: {
        custom: [{
          validation(key, value, _this) {
            return !moment(value).isBefore(_this.get('startDatetime'));
          },
          message: i18n.t('error.email-campaign.endDatetime.post_startDatetime'),
        }, {
          validation(key, value) {
            return !!value;
          },
          message: i18n.t('error.email-campaign.date.required'),
        }],
      },
    };
  }
}
