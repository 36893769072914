import download from 'downloadjs';
import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';

import pdfJS from 'pdfjs-dist';
import fetch from 'fetch'; // Using polyfill for testing purposes

pdfJS.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

export default class extends Service {
  @service ajax

  @service api

  @service session

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (0 <= dataURI.split(',')[0].indexOf('base64')) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  download(blob, fileName) {
    return download(blob, fileName);
  }

  downloadFile(route, fileName) {
    if ('string' !== typeof route) {
      throw new TypeError(`downloadFile: ${route} is not of type String`);
    }

    if ('string' !== typeof fileName) {
      throw new TypeError(`downloadFile: ${fileName} is not of type String`);
    }
    const url = route.startsWith('https://') ? route : `${get(this, 'api.host')}${route}`;
    return fetch(
      url,
      {
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${get(this, 'session.data.authenticated.token')}`,
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          return response.text()
            .then((error) => {
              throw new Error(`downloadFile: ${response.statusText} (${response.status}) - ${error}`);
            });
        }
        return response.blob();
      })
      .then((blob) => this.download(blob, fileName));
  }

  async getImageBlob(url) {
    if (!url.includes(get(this, 'api.host'))) {
      return url;
    }

    const response = await fetch(
      url,
      {
        credentials: 'include',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${get(this, 'session.data.authenticated.token')}`,
        },
      },
    );
    const blob = await response.blob();

    return URL.createObjectURL(blob);
  }

  getImageSize(src = '') {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;

      img.src = src;
    });
  }

  async renderPdf(url) {
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);

    const blob = await this.getImageBlob(url);
    const pdf = await pdfJS.getDocument(blob).promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport(1.5);

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({ canvasContext: canvas.getContext('2d'), viewport });

    return canvas;
  }

  async transformPdfToJpg(file) {
    if (!file.url.match(/pdf$/gi)) {
      return file;
    }

    const canvas = await this.renderPdf(file.url);

    const uploadedFile = await this.uploadFile(this.dataURItoBlob(canvas.toDataURL('image/jpeg')));

    canvas.remove();

    return {
      ...file,
      name: file.name.replace(/pdf$/gi, 'jpg'),
      url: uploadedFile.file_url,
    };
  }

  uploadFile(file, isPublic = false) {
    const fd = new FormData();
    const size = file.size / 1024;

    fd.append(isPublic ? 'image' : 'file', file);
    if (300 < size) {
      fd.append('image_quality', 75);
    }

    return fetch(
      `${this.get('api.route')}/${isPublic ? 'images' : 'files'}`,
      {
        body: fd,
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${get(this, 'session.data.authenticated.token')}`,
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return { error: response.json() };
      });
  }
}
