import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';
import { filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import fetch from 'fetch';
import { all } from 'rsvp';

import offerStates from 'neuro-frontend/enums/residential-offer-states';
import ResidentialProposal from 'neuro-frontend/models/residential-proposal';
import ResidentialOffer from 'neuro-frontend/models/residential-offer';
import Day from 'neuro-frontend/utils/day';
import IllegalStateError from 'neuro-frontend/errors/illegal-state-error';

export default class extends Component {
  @service userActions

  @service api

  @service auth

  showAdvancedSearch = true

  classNames = ['residential-sale-process']

  errorMessage = '';

  sendInfoChecks = {
    dossier: false,
    qualitiesNote: false,
    propertyMap: false,
  }

  sendInfoState = '';

  successMessage = '';

  @computed('sendInfoChecks.dossier', 'sendInfoChecks.qualitiesNote', 'sendInfoChecks.propertyMap')
  get isEnabledSendInfo() {
    return get(this, 'sendInfoChecks.dossier') || get(this, 'sendInfoChecks.qualitiesNote')
    || get(this, 'sendInfoChecks.propertyMap');
  }

  @computed
  get stepsInfo() {
    return [{
      stepId: 1,
      label: get(this, 'i18n').t('sale-process.requests'),
    }, {
      stepId: 2,
      label: get(this, 'i18n').t('sale-process.proposals'),
    }, {
      stepId: 3,
      label: get(this, 'i18n').t('sale-process.closing'),
    },
    ];
  }

  @filter('residentialOffers', (offer) => ['accepted', 'signed'].includes(get(offer, 'state')))
  acceptedOffers

  @action
  closeOffer(onlyReject, model) {
    if (!(model instanceof ResidentialProposal) && !(model instanceof ResidentialOffer)) {
      throw new TypeError(`closeOffer: ${model} is not of type ResidentialProposal nor ResidentialOffer`);
    }

    if (model instanceof ResidentialProposal && !get(model, 'residentialOffers.length')) {
      throw new IllegalStateError(`closeOffer: residentialOffers (${get(model, 'residentialOffers')}) shouldn't be empty`);
    }

    const offer = model instanceof ResidentialOffer
      ? model
      : get(
        model,
        'residentialOffers.firstObject',
      );

    set(offer, 'onlyReject', onlyReject);

    set(
      offer,
      'closingDate',
      (new Day()).format('DD/MM/YYYY'),
    );

    set(
      this,
      'closingOffer',
      offer,
    );
  }

  @action
  createOffer(proposal) {
    if (!(proposal instanceof ResidentialProposal)) {
      throw new TypeError(`createOffer: ${proposal} is not a ResidentialProposal`);
    }

    get(this, 'onCreateOffer')({
      openingDate: (new Day()).format('DD/MM/YYYY'),
      price: get(proposal, 'residentialProperty.price'),
      residentialProposal: proposal,
      state: 'open',
    });
    set(
      this,
      'isOpenCreateOfferModal',
      true,
    );
  }

  @action
  saveOffer(model) {
    return model.save().finally(() => {
      get(this, 'residentialOffers').addObject(model);
      this.notifyPropertyChange('residentialProposals');
      this.notifyPropertyChange('residentialOffers');
      set(this, 'isOpenCreateOfferModal', false);
      set(this, 'closingOffer', null);

      if ('accepted' === get(model, 'state')) {
        set(this, 'currentStep', 3);

        // No need to save it, the BE does it
        const property = get(model, 'residentialProposal.residentialProperty');
        set(property, 'state', 'reserved');
      }

      get(this, 'userActions').create(
        get(offerStates.findBy('value', get(model, 'state')), 'actionType'),
        get(this, 'model.user'),
      ).then((obj) => get(this, 'onSaveAction')(obj));
    });
  }

  @action
  cancelInfo() {
    this.resetChecks();
    this.toggleProperty('isOpenSendInfoModal');
  }

  @action
  cancelOffer(model) {
    model.deleteRecord();
    this.toggleProperty('isOpenCreateOfferModal');
  }

  @action
  openInfoModal(proposals) {
    set(
      this,
      'selectedProposals',
      proposals,
    );
    set(
      this,
      'isOpenSendInfoModal',
      true,
    );
    set(
      this,
      'sendInfoState',
      '',
    );
  }

  @action
  sendInfo() {
    const proposals = get(this, 'selectedProposals');
    set(this, 'isLoading', true);

    const groups = new Map();
    const developments = [];

    proposals.forEach((proposal) => {
      const property = proposal.residentialProperty.content;
      const developmentId = get(property, 'residentialDevelopment.id');

      if (!groups[developmentId]) {
        groups[developmentId] = [];
        developments.push({ id: developmentId, proposals: groups[developmentId] });
      }
      groups[developmentId].push(proposal);
    });

    const promises = [];
    developments.forEach((development) => promises.push(this.postInfo(development)));
    all(promises).then(() => {
      set(this, 'sendInfoState', 'success');
      set(this, 'successMessage', i18n.t('residential.proposal.send-info.success-message'));
    }).catch((error) => {
      set(this, 'sendInfoState', 'error');
      set(
        this, 'errorMessage',
        error,
      );
    }).finally(() => {
      set(this, 'isOpenSendInfoModal', false);
      this.resetChecks();
      set(this, 'isLoading', false);
    });
  }

  @action
  showDocumentationSentForm(offer) {
    if (!(offer instanceof ResidentialOffer)) {
      throw new TypeError(`createOffer: ${offer} is not a ResidentialOffer`);
    }
    set(this, 'selectedOffer', offer);
  }

  @action
  saveValidation(offer) {
    if (get(offer, 'deedDatetime')) {
      set(offer, 'state', 'signed');
    }
    return offer.save().then(() => {
      set(this, 'selectedOffer', false);
    });
  }

  async postInfo(development) {
    const propertiesIds = development.proposals.map((proposal) => get(proposal, 'residentialProperty.id'));

    const query = {
      data: {
        type: 'residential_development_send',
        attributes: {
          contact: get(this.model, 'user.id'),
          'residential-development': development.id,
          'residential-properties': propertiesIds,
          'sent-info-dossier': this.sendInfoChecks.dossier,
          'sent-info-property-map': this.sendInfoChecks.qualitiesNote,
          'sent-info-qualities-note': this.sendInfoChecks.propertyMap,
        },
      },
    };

    await fetch(
      `${this.get('api.host')}/api/v4/residential_development_send`,
      {
        body: JSON.stringify(query),
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${get(this, 'auth.token')}`,
        },
        method: 'POST',
        mode: 'cors',
      },
    ).catch(() => {
      throw new Error(i18n.t('residential.proposal.send-info.error-sending-message'));
    });

    await all(development.proposals.map((proposal) => this.saveChecks(proposal).catch(() => {
      throw new Error(i18n.t('residential.proposal.send-info.error-updating-checks-message'));
    })));
  }

  resetChecks() {
    set(this, 'sendInfoChecks.dossier', false);
    set(this, 'sendInfoChecks.qualitiesNote', false);
    set(this, 'sendInfoChecks.propertyMap', false);
  }

  saveChecks(proposal) {
    set(proposal, 'sentInfoDossier', this.sendInfoChecks.dossier);
    set(proposal, 'sentInfoQualitiesNote', this.sendInfoChecks.qualitiesNote);
    set(proposal, 'sentInfoPropertyMap', this.sendInfoChecks.propertyMap);
    return proposal.save();
  }
}
