import moment from 'moment';

export default function diffHours(start, end) {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return `${String(duration.hours()).padStart(2, '0')}:${String(duration.minutes()).padStart(2, '0')}`;
}

export function addHours(date, h, m) {
  return moment(date).add(Number(h), 'hours')
    .add(Number(m), 'minutes').toDate();
}
