import { get, computed } from '@ember/object';
import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

import Validator from 'ember-model-validator/mixins/model-validator';
import moment from 'moment';
import Month from 'neuro-frontend/utils/month';

import formatDate from 'neuro-frontend/utils/dates/format-date';

export default class extends Model.extend(Validator, {}) {
  @service can

  @attr('number', { defaultValue: 0 }) amount

  @attr('date') date

  @belongsTo('commerce') commerce

  @belongsTo('sale-concept') saleConcept

  @computed('commerce.closedMonths', 'date', 'month')
  get closed() {
    const {
      commerce,
      month,
    } = this;
    const closedMonths = get(commerce, 'closedMonths');
    const months = closedMonths
      ? closedMonths.map((closedMonth) => get(closedMonth, 'month'))
      : [];

    return months.includes(month);
  }

  @computed('date')
  get month() {
    return formatDate(this.date, 'DD/MM/YYYY', 'Y-MM');
  }

  @computed()
  get validations() {
    return {
      amount: {
        presence: true,
      },

      date: {
        presence: true,
        custom: [
          {
            validation(key, value) {
              return !moment(formatDate(value, 'DD/MM/YYYY', 'YYYY-MM-DD'))
                .isAfter(new Date(), 'day');
            },
            message: get(this, 'i18n').t('error.sale.date.invalid_future'),
          },
          {
            validation(key, value, _this) {
              return _this.can.can('create intranet-sale', _this, { month: new Month(new Date(formatDate(_this.date, 'DD/MM/YYYY', 'YYYY-MM-DD'))), commerce: _this.commerce.content });
            },
            message: get(this, 'i18n').t('error.sale.date.closed-month-error'),
          },
        ],
      },
    };
  }
}
