import { get, computed } from '@ember/object';

import TableList from './table-list';

export default class extends TableList {
  @computed
  get columns() {
    return [
      {
        label: get(this, 'i18n').t('residential.campaign.name'),
        valuePath: 'name',
        sortable: true,
        sortOnClick: true,
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: get(this, 'i18n').t('edit'),
        sortable: false,
        align: 'center',
        width: '4%',
        cellClassNames: 'qa-edit-icon',
      },
      {
        label: '',
        cellAction: 'delete',
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: get(this, 'i18n').t('delete'),
        sortable: false,
        align: 'center',
        width: '4%',
        cellClassNames: 'qa-delete-icon',
      },
    ];
  }
}
