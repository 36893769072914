export default {
    "GLA": "GLA",
    "High Street": "F\u0151utca",
    "KYC": "KYC",
    "Outlet centre": "Outlet Center",
    "Retail Park": "Kiskereskedelmi park",
    "Shopping centre": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpont",
    "TPWC": "TPWC (K\u00fcls\u0151s munkav\u00e1llal\u00f3k eligaz\u00edt\u00e1s\u00e1\u00e9rt felel\u0151s csoport)",
    "Webactiva": "WebActiva",
    "accept": "Elfogad",
    "access_data": "Adathozz\u00e1f\u00e9r\u00e9s",
    "access_date": "Adathozz\u00e1f\u00e9r\u00e9s d\u00e1tuma",
    "access_head": "Hozz\u00e1f\u00e9r\u00e9s vezet\u0151",
    "action.check_as_done": "M\u0171velet elv\u00e9gzettk\u00e9nt megjel\u00f6l\u00e9se",
    "actions.action_type": "M\u0171velet t\u00edpusa",
    "actions.add": "M\u0171velet hozz\u00e1ad\u00e1sa",
    "actions.call": "H\u00edv\u00e1s",
    "actions.date": "D\u00e1tum",
    "actions.datetime": "D\u00e1tum",
    "actions.edit": "M\u0171velet szerkeszt\u00e9se",
    "actions.email": "Email c\u00edm",
    "actions.form": "Adatlap",
    "actions.new": "\u00daj m\u0171velet",
    "actions.notes": "Jegyzetek",
    "actions.rejection": "Elutas\u00edt\u00e1s",
    "actions.rejection_title": "Aj\u00e1nlat elutas\u00edtva",
    "actions.reservation": "Foglal\u00e1s",
    "actions.reservation_title": "Az ingatlan le van foglalva",
    "actions.select_action_type": "V\u00e1lassza ki a m\u0171velet t\u00edpus\u00e1t",
    "actions.sent_marketing_info": "Elk\u00fcld\u00f6tt marketing inform\u00e1ci\u00f3",
    "actions.sent_marketing_info_title": "Inform\u00e1ci\u00f3 elk\u00fcldve",
    "actions.signed": "Al\u00e1\u00edrva",
    "actions.signed_title": "Al\u00e1\u00edrt ingatlan",
    "actions.staff": "Szem\u00e9lyzet",
    "actions.title": "Megnevez\u00e9s",
    "actions.visit": "L\u00e1togat\u00e1s",
    "active.users": "Akt\u00edv felhaszn\u00e1l\u00f3k",
    "add": "Hozz\u00e1ad",
    "add files": "F\u00e1jlok hozz\u00e1ad\u00e1sa",
    "additional-guarantee": "Egy\u00e9b garancia",
    "address": "C\u00edm",
    "admin": "Rendszergazda",
    "advanced_search": "\u00d6sszetett keres\u00e9s",
    "age": "Kor",
    "all": "\u00d6sszes",
    "analytics.no-dashboard": "Nincs el\u00e9rhet\u0151 dashboard",
    "and": "\u00e9s",
    "answer": "V\u00e1lasz",
    "any-of": "b\u00e1rmelyik",
    "api-error": "Valami hiba t\u00f6rt\u00e9nt. K\u00e9rem, seg\u00edtse munk\u00e1nkat visszajelz\u00e9s\u00e9vel.",
    "app-notifications": "Alkalmaz\u00e1s \u00e9rtes\u00edt\u00e9sek",
    "apply_filters": "Filter alkalmaz\u00e1sa",
    "approved-date": "D\u00e1tum elfogadva",
    "approved-documentation": "Dokumentum elfogadva",
    "apr": "\u00c1pr",
    "april": "\u00c1prilis",
    "april-short": "\u00c1pr",
    "are_you_sure": "Biztosan folytatja?",
    "as-built-documentation": "As built dokument\u00e1ci\u00f3",
    "attach_files": "F\u00e1jlok csatol\u00e1sa",
    "attach_image": "K\u00e9p csatol\u00e1sa",
    "attr.required": "Jellemz\u0151 megad\u00e1sa k\u00f6telez\u0151",
    "aug": "Aug",
    "august": "Augusztus",
    "august-short": "Aug",
    "available-in": "El\u00e9rhet\u0151",
    "average": "\u00c1tlag",
    "average.visit.time": "Tart\u00f3zkod\u00e1s ideje",
    "back": "Vissza",
    "bank-account-number": "IBAN",
    "bdate": "Sz\u00fclet\u00e9snap",
    "before-starting-works": "Kezd\u00e9s el\u0151tti munk\u00e1k",
    "between": "k\u00f6z\u00f6tt",
    "billing": "Sz\u00e1ml\u00e1z\u00e1s",
    "bime": "BIME",
    "bime_dashboard.not_found": "BIME Dashboard nem tal\u00e1lhat\u00f3",
    "bime_dashboard.type_find": "Kezdjen el g\u00e9pelni a BIME Dashboard megtal\u00e1l\u00e1s\u00e1hoz",
    "bookable": "Lefoglalhat\u00f3",
    "booking-contract": "Szerz\u0151d\u00e9s k\u00f6nyvel\u00e9se",
    "booking-price": "K\u00f6nyvel\u00e9si \u00e1r",
    "booking_contract": "Szerz\u0151d\u00e9s k\u00f6nyvel\u00e9se",
    "both": "Mindkett\u0151",
    "boy": "Fi\u00fa",
    "brand": "M\u00e1rka",
    "breadcrumbs.main": "F\u0151",
    "budget": "K\u00f6lts\u00e9gvet\u00e9s",
    "building": "Kivitelez\u00e9s",
    "building-state": "Kivitelez\u00e9s \u00e1llapota",
    "built-surface": "Built sur.",
    "bus": "Busz",
    "business-civilliability-insurance": "\u00dczleti felel\u0151ss\u00e9g biztos\u00edt\u00e1s",
    "business-license": "M\u0171k\u00f6d\u00e9si enged\u00e9ly",
    "business-license-approval": "M\u0171k\u00f6d\u00e9si enged\u00e9ly elfogad\u00e1sa",
    "business-license-request": "K\u00e9relem m\u0171k\u00f6d\u00e9si enged\u00e9lyhez",
    "cadastral-reference": "Kataszteri referenciadatok",
    "calendar": "Napt\u00e1r",
    "calibrator": "Kalibr\u00e1tor",
    "call-to-action": "Felh\u00edv\u00e1s",
    "campaign": "Kamp\u00e1ny",
    "campaign.edit": "Kamp\u00e1ny szerkeszt\u00e9se",
    "campaign.filters": "Sz\u0171r\u0151k",
    "campaign.new": "\u00daj kamp\u00e1ny",
    "campaign.status.active": "Akt\u00edv",
    "campaign.status.closed": "Z\u00e1rva",
    "campaign.status.finished": "Befejezett",
    "campaign.status.planned": "Tervezett",
    "campaign_id": "Kamp\u00e1ny",
    "campaigns": "Kamp\u00e1nyok",
    "campaigntype": "Kamp\u00e1ny t\u00edpusa",
    "campaigntype.edit": "Kamp\u00e1ny t\u00edpus\u00e1nak szerkeszt\u00e9se",
    "campaigntype.new": "\u00daj kamp\u00e1ny t\u00edpus",
    "cancel": "M\u00e9gse",
    "car-insurance": "\u00c9p\u00edt\u00e9s\u2013Szerel\u00e9s Biztos\u00edt\u00e1s",
    "categories": "Kateg\u00f3ri\u00e1k",
    "category": "Kateg\u00f3ria",
    "cbre-contact": "CBRE kapcsolat",
    "ceiling": "Mennyezet",
    "center": "K\u00f6zpont",
    "center-id": "K\u00f6zpont azonos\u00edt\u00f3",
    "center.not_found": "K\u00f6zpont nem tal\u00e1lhat\u00f3",
    "center.select_one": "V\u00e1lasszon ki egy k\u00f6zpontot",
    "centers": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpontok",
    "centers-required": "A k\u00f6zpont megad\u00e1sa k\u00f6telez\u0151",
    "change-password.confirm-password": "Er\u0151s\u00edtse meg az \u00faj jelsz\u00f3t",
    "change-password.new-password": "\u00daj jelsz\u00f3",
    "change-password.old-password": "Jelenlegi jelsz\u00f3",
    "change-password.title": "Jelsz\u00f3 v\u00e1ltoztat\u00e1s",
    "channel_id": "Csatorna",
    "check-in": "Bejelentkez\u00e9s",
    "child": "Gyermek",
    "child.add-user-dni": "Menj a felhaszn\u00e1l\u00f3i profilra",
    "child.bdate": "Sz\u00fclet\u00e9si d\u00e1tum",
    "child.check-out": "Kijelentkez\u00e9s",
    "child.checking-out": "Kijelentkez\u00e9s",
    "child.checkn": "Bejelentkez\u00e9s",
    "child.edit": "Szerkeszt\u00e9s",
    "child.last-name": "Vezet\u00e9kn\u00e9v",
    "child.name": "N\u00e9v",
    "child.new": "\u00daj",
    "child.not-dni-error-message": "A j\u00e1tsz\u00f3h\u00e1zba val\u00f3 bel\u00e9p\u00e9shez k\u00e9rj\u00fck adja meg Szem\u00e9lyi Igazolv\u00e1ny sz\u00e1m\u00e1t. Ezt a felhaszn\u00e1l\u00f3i profilban tudja megadni",
    "child.notes": "Jegyzetek",
    "child.register-entry": "Bel\u00e9p\u00e9si regisztr\u00e1ci\u00f3",
    "child.relationship": "Csal\u00e1di \u00e1llapot",
    "child.select-relationship": "V\u00e1lassza ki a csal\u00e1di \u00e1llapot\u00e1t",
    "child.select-sex": "V\u00e1lassza ki a nem\u00e9t",
    "child.sex": "Neme",
    "children": "Gyerekek",
    "children-count": "{{count}} gyermek",
    "children-count_plural": "{{count}} gyermek",
    "children-number": "Gyermekek sz\u00e1ma",
    "children_number": "Gyermekek sz\u00e1ma",
    "cif": "Szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "city": "V\u00e1ros",
    "civilliability-insurance": "Felel\u0151ss\u00e9gbiztos\u00edt\u00e1s",
    "clear": "T\u00f6rl\u00e9s",
    "clear_filters": "T\u00f6rl\u00e9s",
    "client": "\u00dcgyf\u00e9l",
    "close": "Z\u00e1rva",
    "close-month.button.confirm": "Er\u0151s\u00edtse meg a h\u00f3napz\u00e1r\u00e1st",
    "close-month.button.reject": "H\u00f3napz\u00e1r\u00e1s elutas\u00edt\u00e1sa",
    "close-month.message.pending-request": "H\u00f3napz\u00e1r\u00e1sa f\u00fcgg\u0151ben van",
    "close_month": "H\u00f3nap z\u00e1r\u00e1sa",
    "closed": "Z\u00e1rva",
    "closed_month_pending": "F\u00fcgg\u0151ben l\u00e9v\u0151",
    "closed_previous_month": "El\u0151z\u0151 h\u00f3napok lez\u00e1r\u00e1sa",
    "combo": "Kombin\u00e1ci\u00f3",
    "comments": "Kommentek",
    "commerce": "\u00dczlet",
    "commerces": "\u00dczletek",
    "commercialization": "Commercialisation",
    "commercialization-start": "Commercialisation kezdete",
    "common-zones-surface": "Common zones sur.",
    "communication.label.to_roles": "Eddig",
    "communication.message": "\u00dczenet",
    "communication.new": "\u00daj kommunik\u00e1ci\u00f3",
    "communication.not_found": "\u00dczenet nem tal\u00e1lhat\u00f3",
    "communication.reject-close-month.subject": "{{shopName}} z\u00e1r\u00e1sa {{month}} elutas\u00edtva",
    "communication.roles.commerce": "\u00dczlet",
    "communication.roles.management": "Menedzsment",
    "communication.roles.owner": "Tulajdonos",
    "communication.state.accepted": "Elfogadott",
    "communication.state.pending": "F\u00fcgg\u0151ben",
    "communication.state.rejected": "Elutas\u00edtva",
    "communication.state.solved": "Megoldva",
    "communication.subject": "T\u00e1rgy",
    "communication.subtype": "Alt\u00edpus",
    "communication.to_commerces": "Az \u00fczleteknek",
    "communication.type": "T\u00edpus",
    "communication.type.access": "Hozz\u00e1f\u00e9r\u00e9s",
    "communication.type.issues": "T\u00e9m\u00e1k",
    "communication.type.marketing": "Marketing",
    "communication.type.news": "H\u00edrek",
    "communication.type.offers": "Aj\u00e1nlatok",
    "communication.type.tabs.inbox": "Be\u00e9rkezett \u00fczenetek",
    "communication.type.tabs.sent": "Elk\u00fcldve",
    "communications.creator": "L\u00e9trehoz\u00f3",
    "communications.datetime": "D\u00e1tum",
    "communications.state": "\u00c1llapot",
    "communications.subject": "T\u00e1rgy",
    "communications.type": "T\u00edpus",
    "community-expenses": "K\u00f6z\u00fczemi k\u00f6lts\u00e9gek",
    "companies": "V\u00e1llalatok",
    "companion.add": "T\u00e1rs hozz\u00e1ad\u00e1sa",
    "companion.vat_number": "szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "companion_name": "T\u00e1rs neve",
    "companions": "T\u00e1rsak",
    "company": "C\u00e9g",
    "company-reference-number": "C\u00e9gjegyz\u00e9ksz\u00e1m",
    "concepts.airplane_tickets": "Rep\u00fcl\u0151 jegyek",
    "concepts.cancellations": "Visszavon\u00e1sok",
    "concepts.coffee": "K\u00e1v\u00e9",
    "concepts.equipment": "G\u00e9p\u00e9szet (felszerel\u00e9s)",
    "concepts.free_devices": "El\u00e9rhet\u0151 eszk\u00f6z\u00f6k",
    "concepts.game": "J\u00e1t\u00e9k",
    "concepts.general": "\u00c1ltal\u00e1nos",
    "concepts.general_variable": "\u00c1ltal\u00e1nos \u00c1ru ut\u00e1ni v\u00e1ltoz\u00f3 b\u00e9rleti d\u00edj",
    "concepts.gm1": "\u00c1ltal\u00e1nos \u00c1ru 1",
    "concepts.gm2": "\u00c1ltal\u00e1nos \u00c1ru 2",
    "concepts.magazines": "\u00dajs\u00e1g \u00e9s magazinok",
    "concepts.phonecard": "Telefonk\u00e1rtya",
    "concepts.pub": "Pub",
    "concepts.receipts": "Felt\u00f6lt\u00e9sek\/ Sz\u00e1ml\u00e1k",
    "concepts.school_mat": "School Material",
    "concepts.stamps_and_values": "b\u00e9lyegek \u00e9s \u00e9rt\u00e9kek",
    "concepts.telecommunications": "Telekommunik\u00e1ci\u00f3s Szolg\u00e1ltat\u00e1sok",
    "concepts.tobacco": "Doh\u00e1nybolt",
    "concepts.tobacco_game": "Doh\u00e1ny\u00e1ru \u00e9s J\u00e1t\u00e9kok",
    "concepts.trans_currency": "Valuta v\u00e1lt\u00e1s",
    "concepts.uploads_of_credit": "Kreditek felt\u00f6lt\u00e9se",
    "confirm": "Meger\u0151s\u00edt\u00e9s",
    "confirm-modal.cancel": "M\u00e9gse",
    "confirm-modal.ok": "Elfogad",
    "confirm-password": "Jelsz\u00f3 meger\u0151s\u00edt\u00e9se",
    "contact-people": "Kapcsolattart\u00f3",
    "contactable": "Kapcsolat",
    "contains": "tartalmaz",
    "contract-Type": "Szerz\u0151d\u00e9s t\u00edpusa",
    "convenience": "K\u00e9nyelem",
    "countries": "Orsz\u00e1gok",
    "country": "Orsz\u00e1g",
    "country-required": "Adja meg az orsz\u00e1got",
    "couple": "P\u00e1rban",
    "coupon-used": "Felhaszn\u00e1lt kupon",
    "cp": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "create-new": "L\u00e9trehoz",
    "create-staff-message": "SC vezet\u0151, a Kalibr\u00e1l\u00e1s \u00e9s Oper\u00e1tor ebben ker\u00fclj\u00fcn l\u00e9trehoz\u00e1sra",
    "created_at": "Regisztr\u00e1ci\u00f3 d\u00e1tuma",
    "crm-manager": "CRM vezet\u0151",
    "crm-operator": "CRM ir\u00e1ny\u00edt\u00f3ja",
    "crm.children.add": "Gyermek hozz\u00e1ad\u00e1sa",
    "crm.children.bdate": "Sz\u00fclet\u00e9snap d\u00e1tuma",
    "crm.children.gender": "Neme",
    "crm.children.name": "N\u00e9v",
    "crm.filter.not_found": "Sz\u0171r\u0151 nem tal\u00e1lhat\u00f3",
    "crm.filter.type_find": "Sz\u0171k\u00edtse le keres\u00e9s\u00e9t",
    "crm.filters-section.demographic": "Demogr\u00e1fiai",
    "crm.filters-section.requests": "K\u00e9r\u00e9sek",
    "crm.filters-section.stats": "Statisztik\u00e1k",
    "crm.segments": "Szegmensek",
    "crm.users": "Fogyaszt\u00f3k",
    "crm.users.bdate-month-day": "Sz\u00fclet\u00e9snap (tartom\u00e1ny)",
    "crm.users.bdate-today": "Sz\u00fclet\u00e9snap (ma)",
    "crm.users.children-list": "Gyermekek list\u00e1ja",
    "crm.users.code": "K\u00f3d",
    "crm.users.action": "Tev\u00e9kenys\u00e9g",
    "crm.users.email": "E-mail c\u00edm",
    "crm.users.go-to-users-list": "Menjen a felhaszn\u00e1l\u00f3k list\u00e1j\u00e1ra",
    "crm.users.last-action": "Utols\u00f3 tev\u00e9kenys\u00e9g",
    "crm.users.last-action-datetime": "Utols\u00f3 tev\u00e9kenys\u00e9g d\u00e1tuma",
    "crm.users.last_name": "Vezet\u00e9kn\u00e9v",
    "crm.users.mstatus": "Csal\u00e1di \u00e1llapot",
    "crm.users.name": "N\u00e9v",
    "crm.users.not-email": "Nincs e-mail c\u00edm ",
    "crm.users.phone": "Telefon",
    "crm.users.state": "\u00c1llam",
    "crm.users.user-saved": "A felhaszn\u00e1l\u00f3t sikeresen elmentett\u00fck",
    "crm.users.vat-number": "Szem\u00e9lyi Igazolv\u00e1ny Sz\u00e1m",
    "crm.users.person_type": "Term\u00e9szetes vagy Jogi szem\u00e9lyis\u00e9g",
    "crm.users.postal_code": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "crm.users.sex": "Nem",
    "csv": "csv",
    "currency": "Valuta",
    "current-pass-required": "Jelenlegi f\u00e1zis megad\u00e1sa k\u00f6telez\u0151",
    "current-value": "Jelenlegi \u00e9rt\u00e9k",
    "currently-in-playcenter": "Jelenleg a j\u00e1tsz\u00f3h\u00e1zban tart\u00f3zkodik.",
    "dashboard": "Dashboard",
    "dashboard-cms": "CMS Dashboard",
    "dashboard-crm": "CRM Dashboard",
    "dashboard-list.dashboards.not-found": "Dashboard nem tal\u00e1lhat\u00f3",
    "dashboard-list.title.active-graphs": "Akt\u00edv grafikonok",
    "dashboard-panel": "Dashboard panel",
    "dashboard.category.general": "\u00c1ltal\u00e1nos",
    "dashboard.category.home": "F\u0151oldal",
    "dashboard.category.not_found": "Kateg\u00f3ria nem tal\u00e1lhat\u00f3",
    "dashboard.category.traffic": "K\u00f6zleked\u00e9s",
    "dashboard.error.not-found-bime": "BIME Dashboard nem tal\u00e1lhat\u00f3",
    "dashboard.index.no-home-dashboard": "\u00dcdv\u00f6zli a Neuromobile fel\u00fclete",
    "dashboard.print-instructions": "Javaslat: Fekv\u0151 elrendez\u00e9s, a h\u00e1tt\u00e9r grafika enged\u00e9lyez\u00e9se \u00e9s a marg\u00f3k letilt\u00e1sa a optim\u00e1lis megjelen\u00edt\u00e9s \u00e9rdek\u00e9ben.",
    "dashboard.print-loading-charts": "Grafikonok bet\u00f6lt\u00e9se",
    "dashboards": "Grafikonok",
    "dashboards.0-17": "<18",
    "dashboards.1": "1",
    "dashboards.18-24": "18-24",
    "dashboards.2": "2",
    "dashboards.25-34": "25-34",
    "dashboards.3": "3",
    "dashboards.35-44": "35-44",
    "dashboards.4": "4",
    "dashboards.45-99": ">45",
    "dashboards.active-users": "Akt\u00edv felhaszn\u00e1l\u00f3k",
    "dashboards.active-users-chart": "Akt\u00edv felhaszn\u00e1l\u00f3k vs % Akt\u00edv felhaszn\u00e1l\u00f3k (H\u00f3nap)",
    "dashboards.active-vs-registered-users-chart": "Akt\u00edv felhaszn\u00e1l\u00f3k vs Regisztr\u00e1lt felhaszn\u00e1l\u00f3k",
    "dashboards.age": "\u00c9letkor",
    "dashboards.age-range": "Koroszt\u00e1ly",
    "dashboards.app-users": "App felhaszn\u00e1l\u00f3k",
    "dashboards.couple": "P\u00e1r",
    "dashboards.competition-dwell-time": "Tart\u00f3zkod\u00e1si id\u0151 konkurencia",
    "dashboards.competition-recurrence": "Ism\u00e9tl\u0151d\u0151 l\u00e1togat\u00e1s konkurencia",
    "dashboards.competition-visits": "Konkurencia l\u00e1togat\u00e1s",
    "dashboards.dwell-chart": "Tart\u00f3zkod\u00e1si id\u0151 vs. Konkurencia (H\u00f3nap)",
    "dashboards.dwell-time": "Tart\u00f3zkod\u00e1s id\u0151tartama",
    "dashboards.email": "\u00c9rv\u00e9nyes e-mail c\u00edm",
    "dashboards.email-notifications": "E-mail \u00e9rtes\u00edt\u00e9sek",
    "dashboards.family": "Csal\u00e1d",
    "dashboards.frequency": "L\u00e1togat\u00e1sok sz\u00e1m\u00e1nak ism\u00e9tl\u0151d\u00e9se",
    "dashboards.frequency-chart": "L\u00e1togat\u00e1sok sz\u00e1m\u00e1nak ism\u00e9tl\u0151d\u00e9se vs Konkurencia (?) (H\u00f3nap)",
    "dashboards.gender": "Nem",
    "dashboards.influence-zone": "Hat\u00f3ter\u00fclet",
    "dashboards.mail-notif-consent": "E-mail \u00e9rtes\u00edt\u00e9sek",
    "dashboards.mail-partner-consent": "Harmadik f\u00e9les \u00e9rtes\u00edt\u00e9sek",
    "dashboards.mail-notifications": "E-mail \u00e9rtes\u00edt\u00e9sek",
    "dashboards.marital-status": "Csal\u00e1di \u00e1llapot",
    "dashboards.men": "F\u00e9rfi",
    "dashboards.new-app-users": "Regisztr\u00e1lt Applik\u00e1ci\u00f3 Felhaszn\u00e1l\u00f3k",
    "dashboards.new-registered-users": "Regisztr\u00e1lt felhaszn\u00e1l\u00f3k",
    "dashboards.other": "Egyebek",
    "dashboards.other-users": "M\u00e1s felhaszn\u00e1l\u00f3k",
    "dashboards.per-active-users": "% akt\u00edv felhaszn\u00e1l\u00f3k",
    "dashboards.phone": "telefon",
    "dashboards.play-center-users": "J\u00e1tsz\u00f3h\u00e1z felhaszn\u00e1l\u00f3i",
    "dashboards.postal-code": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "dashboards.quality-data-chart": "Felhaszn\u00e1l\u00f3i adatok min\u0151s\u00e9ge",
    "dashboards.recurrence": "L\u00e1togat\u00e1sok sz\u00e1m\u00e1nak ism\u00e9tl\u0151d\u00e9se",
    "dashboards.registered-app-users": "Regisztr\u00e1lt applik\u00e1ci\u00f3t haszn\u00e1l\u00f3k",
    "dashboards.registered-total-users-chart": "Applik\u00e1ci\u00f3n kereszt\u00fcl regisztr\u00e1ltak vs \u00d6sszes regisztr\u00e1ci\u00f3 (\u00f6sszesen)",
    "dashboards.registered-users": "\u00d6sszes regisztr\u00e1lt felhaszn\u00e1l\u00f3",
    "dashboards.registered-users-chart": "Applik\u00e1ci\u00f3n kereszt\u00fcl regisztr\u00e1ltak vs \u00d6sszes regisztr\u00e1ci\u00f3 (H\u00f3nap)",
    "dashboards.registered-users-total": "\u00d6sszes regisztr\u00e1lt felhaszn\u00e1l\u00f3",
    "dashboards.registration-rate": "Regisztr\u00e1ci\u00f3 ar\u00e1nya",
    "dashboards.registration-rate-chart": "Regisztr\u00e1ci\u00f3 ar\u00e1nya (H\u00f3nap)",
    "dashboards.registration-vs-consent-chart": "Regisztr\u00e1ci\u00f3 vs hozz\u00e1j\u00e1rul\u00e1s",
    "dashboards.rsocial-users": "Nyilv\u00e1nos wifi haszn\u00e1lata",
    "dashboards.single": "Egyed\u00fcl\u00e1ll\u00f3",
    "dashboards.thirt-party-notifications": "Harmadik f\u00e9l \u00e9rtes\u00edt\u00e9se",
    "dashboards.total-app-users": "\u00d6sszes regisztr\u00e1lt, applik\u00e1ci\u00f3t haszn\u00e1l\u00f3 fehaszn\u00e1l\u00f3",
    "dashboards.total-registered-users": "\u00d6sszes regisztr\u00e1lt fehaszn\u00e1l\u00f3",
    "dashboards.user-profiles-age-chart": "\u00c9letkor",
    "dashboards.user-profiles-catchment-area-chart": "Vonz\u00e1sk\u00f6rzet",
    "dashboards.user-profiles-family-chart": "Csal\u00e1d",
    "dashboards.user-profiles-gender-chart": "Nem",
    "dashboards.user-typology": "Felhaszn\u00e1l\u00f3k besorol\u00e1sa",
    "dashboards.vat-number": "Szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "dashboards.visits": "L\u00e1togat\u00e1sok",
    "dashboards.visits-competition-chart": "L\u00e1togat\u00e1sok sz\u00e1ma vs Versenyt\u00e1rsak (H\u00f3nap)",
    "dashboards.visits-users-chart": "L\u00e1togat\u00e1sok vs Akt\u00edv felhaszn\u00e1l\u00f3k",
    "dashboards.web-users": "Internetes felhaszn\u00e1l\u00f3k",
    "dashboards.women": "N\u0151",
    "dashboards.zip-code": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "data-sheet": "Adatlap",
    "dashboards-required": "Grafikon megad\u00e1sa k\u00f6telez\u0151",
    "date": "D\u00e1tum",
    "date.from": "Ett\u0151l",
    "date.from-to": "Ett\u0151l {{from}} eddig {{to}}",
    "date.to": "Eddig",
    "dec": "Dec",
    "december": "December",
    "december-short": "Dec",
    "default-building": "\u00c9p\u00fclet kiv\u00e1laszt\u00e1sa",
    "default-building-required": "Egy alap\u00e9rtelmezett \u00e9p\u00fclet sz\u00fcks\u00e9ges",
    "day": "Nap",
    "delete": "T\u00f6rl\u00e9s",
    "delivery": "K\u00e9zbes\u00edt\u00e9s",
    "delivery-date": "K\u00e9zbes\u00edt\u00e9s d\u00e1tuma",
    "demographic": "Demogr\u00e1fiai",
    "description": "Le\u00edr\u00e1s",
    "developer-contact": "Fejleszt\u0151i el\u00e9rhet\u0151s\u00e9g",
    "development": "Fejleszt\u00e9s",
    "development-maps": "Szintfejleszt\u00e9si tervez\/tervezet",
    "development.address": "C\u00edm",
    "development.built_surface": "Built sur.",
    "development.cadastral_reference": "Telekk\u00f6nyvi adatok",
    "development.city": "V\u00e1ros",
    "development.common_zones_surface": "Common zones Sur.",
    "development.edit": "Fejleszt\u00e9s szerkeszt\u00e9se",
    "development.energeticCertification": "Energetikai tanus\u00edtv\u00e1ny",
    "development.estate": "Telek",
    "development.garages_number": "Gar\u00e1zsok sz\u00e1ma",
    "development.name": "Fejleszt\u00e9s",
    "development.new": "\u00daj fejleszt\u00e9s",
    "development.notes": "Le\u00edr\u00e1s",
    "development.postalCode": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "development.properties_number": "Ingatlansz\u00e1mok",
    "development.reference": "Hivatkoz\u00e1s",
    "development.responsible_email": "E-mail c\u00edm",
    "development.responsible_name": "N\u00e9v",
    "development.responsible_position": "Poz\u00edci\u00f3",
    "development.staff": "Menedzsment",
    "development.storage_rooms_number": "T\u00e1rol\u00f3 helyis\u00e9gek sz\u00e1ma",
    "development.useful_surface": "Useful sur.",
    "discharge-date": "Elbocs\u00e1t\u00e1s ideje",
    "division": "Div\u00edzi\u00f3",
    "dni": "DNI",
    "doc": "Doc",
    "document": "Dokumentum",
    "documentation": "Dokument\u00e1ci\u00f3",
    "dollars": "Doll\u00e1rok $",
    "done": "K\u00e9sz",
    "download": "Let\u00f6lt\u00e9s",
    "downloads": "Let\u00f6lt\u00e9sek",
    "draft": "Tervezet",
    "draft-bookable": "Foglalhat\u00f3 tervezet",
    "duplicate": "M\u00e1sol",
    "east": "Kelet",
    "economic-conditions": "Gazdas\u00e1gi felt\u00e9telek",
    "ecop": "ECOP",
    "ecop-work.edit": "Munka szerkeszt\u00e9se",
    "ecop-work.new": "\u00daj munka",
    "ecop.work": "Munka",
    "edit": "Szerkeszt\u00e9s",
    "edit-company": "V\u00e1llalat szerkeszt\u00e9se",
    "edit-staff": "Alkalmazottak szerkeszt\u00e9se",
    "electricity": "Villamoss\u00e1g",
    "email": "E-mail",
    "email-campaign": "Kamp\u00e1ny e-mail",
    "email-campaign.date": "D\u00e1tum",
    "email-campaign.date.is-unique-sent": "Egyszeri kik\u00fcld\u00e9s",
    "email-campaign.edit": "Az e-mail form\u00e1j\u00fa kamp\u00e1ny szerkeszt\u00e9se",
    "email-campaign.end-date": "Z\u00e1r\u00f3 d\u00e1tum",
    "email-campaign.filter.active": "Akt\u00edv",
    "email-campaign.filter.archived": "Elk\u00fcldve",
    "email-campaign.filter.draft": "Piszkozat",
    "email-campaign.filter.scheduled": "Be\u00fctemezve",
    "email-campaign.form.desing_template": "Formatervez\u00e9s",
    "email-campaign.form.general_info": "\u00c1ltal\u00e1nos infrom\u00e1ci\u00f3",
    "email-campaign.form.summary": "\u00d6sszegz\u00e9s",
    "email-campaign.htmlBody.design": "Tervezze meg a saj\u00e1t e-mailj\u00e9t",
    "email-campaign.htmlBody.file_import": "Sablon import\u00e1l\u00e1sa",
    "email-campaign.htmlBody.init_greeting": "Itt szerkesztheti e-mailj\u00e9t :)",
    "email-campaign.htmlBody.params": "E-mail param\u00e9terei",
    "email-campaign.htmlBody.preview": "El\u0151n\u00e9zet",
    "email-campaign.name": "Kamp\u00e1ny neve",
    "email-campaign.new": "\u00daj kamp\u00e1ny e-mail",
    "email-campaign.not_found": "Kamp\u00e1ny e-mail nem tal\u00e1lhat\u00f3",
    "email-campaign.params.help.html": "Illesszbe be ezt a k\u00f3dot, hogy a vars v\u00e1ltoz\u00f3kat hozz\u00e1 tudja adni a HTML sablonhoz.",
    "email-campaign.params.help.subject": "Illesszbe be ezt a k\u00f3dot, hogy a vars v\u00e1ltoz\u00f3kat hozz\u00e1 tudja adni az e-mail t\u00e1rgy\u00e1hoz.",
    "email-campaign.params.html.center_name": "Adja meg a bev\u00e1s\u00e1rl\u00f3k\u00f6zpont nev\u00e9t",
    "email-campaign.params.html.unsub_link": "Adjon hozz\u00e1 egy linket, hogy enged\u00e9lyezze a felhaszn\u00e1l\u00f3nak az \u00e9rtes\u00edt\u00e9sek kikapcsol\u00e1s\u00e1t.",
    "email-campaign.params.html.user_email": "Adja meg a felhaszn\u00e1l\u00f3i fi\u00f3khoz tartoz\u00f3 e-mail c\u00edm\u00e9t.",
    "email-campaign.params.html.user_name": "Adja meg felhaszn\u00e1l\u00f3i nev\u00e9t",
    "email-campaign.params.subject.center_name": "Adja meg a bev\u00e1s\u00e1rl\u00f3k\u00f6zpont nev\u00e9t",
    "email-campaign.params.subject.user_code": "Adja meg a felhaszn\u00e1l\u00f3i k\u00f3dot",
    "email-campaign.params.subject.user_email": "Adja meg a felhaszn\u00e1l\u00f3i fi\u00f3khoz tartoz\u00f3 e-mail c\u00edm\u00e9t.",
    "email-campaign.params.subject.user_name": "Adja meg felhaszn\u00e1l\u00f3i nev\u00e9t",
    "email-campaign.segment": "Szegmens",
    "email-campaign.segment.list": "Szegmens lista",
    "email-campaign.segment.segmentName": "Szegmens n\u00e9v",
    "email-campaign.send.save_and_test": "Mentse el \u00e9s k\u00fcldje el a tesztet",
    "email-campaign.sentDatetime": "Elk\u00fcld\u00f6tt d\u00e1tum",
    "email-campaign.start-date": "Kezdeti d\u00e1tum",
    "email-campaign.statistic.blocked": "Blokkolva",
    "email-campaign.statistic.bounced": "Bounced",
    "email-campaign.statistic.clicked": "Clicked",
    "email-campaign.statistic.delivered": "K\u00e9zbes\u00edtve",
    "email-campaign.statistic.opened": "Megnyitva",
    "email-campaign.statistic.spam": "Spam",
    "email-campaign.subject": "T\u00e1rgy",
    "email-required": "K\u00f6telez\u0151 megadnia az e-mail c\u00edm\u00e9t",
    "email-template.not_found": "Nem tal\u00e1lhat\u00f3 sablon",
    "email-template.select_one": "V\u00e1lasszon ki egy sablont",
    "email-template.send.select_mail": "\u00cdrja be az e-mail c\u00edm\u00e9t, amire a teszt e-mailt k\u00e9ri",
    "email-template.send.test": "K\u00fcldj\u00f6n teszt e-mailt",
    "email-template.type.send_contact": "\u00dczenet a mobil alkalmaz\u00e1sr\u00f3l",
    "email-template.type.send_intranet_communication_email": "Intranetes kommunik\u00e1ci\u00f3s \u00e9rtes\u00edt\u00e9s",
    "email-template.type.send_proposals_report": "Kuponok jelent\u00e9se",
    "email-template.type.send_reprint_tickets": "\u00c9rtes\u00edt\u00e9s a jegyek \u00fajra nyomtat\u00e1s\u00e1r\u00f3l",
    "email-template.type.send_staff_connection_warning": "Munkav\u00e1llal\u00f3i kapcsolat figyelmeztet\u00e9s",
    "email-template.type.send_staff_reset_password_email": "Jelsz\u00f3eml\u00e9keztet\u0151 alaphelyzetbe \u00e1ll\u00edt\u00e1sa",
    "email-template.type.send_user_activate_email": "A mobil applik\u00e1ci\u00f3 felhaszn\u00e1l\u00f3i fi\u00f3kj\u00e1nak aktiv\u00e1l\u00e1sr\u00f3l k\u00fcld\u00f6tt \u00fczenet",
    "email-template.type.send_user_child_birthday_reminder": "A v\u00e1s\u00e1rl\u00f3k gyermekeinek a sz\u00fcletesnapj\u00e1val kapcsolatos eml\u00e9keztet\u0151",
    "email-template.type.send_user_child_birthday_visit_ack": "A v\u00e1s\u00e1rl\u00f3 gyermek\u00e9nek sz\u00fclet\u00e9snap ut\u00e1ni l\u00e1togat\u00e1s\u00e1nak igazol\u00e1sa",
    "email-template.type.send_user_registration_email": "\u00dcgyf\u00e9l regisztr\u00e1ci\u00f3s \u00fczenet",
    "email-template.type.send_user_reset_password_email": "\u00dczenet a mobil alkalmaz\u00e1s felhaszn\u00e1l\u00f3i jelszav\u00e1nak vissza\u00e1ll\u00edt\u00e1s\u00e1r\u00f3l",
    "email.not-exist": "Ez az e-mail c\u00edm nem l\u00e9tezik",
    "email.recovery-sent": "A jelsz\u00f3 helyre\u00e1ll\u00edt\u00e1s\u00e1t tartalmaz\u00f3 e-mail sikeresen el lett k\u00fcldve.",
    "enabled": "Enged\u00e9lyezett",
    "enclosure-work": "Munkater\u00fclet lez\u00e1r\u00e1sa",
    "end-date": "Z\u00e1r\u00e1s d\u00e1tuma",
    "end_date": "Z\u00e1r\u00e1s d\u00e1tuma",
    "end_datetime": "Z\u00e1r\u00e1s d\u00e1tumideje",
    "energy-certification": "Energia tanus\u00edtv\u00e1ny",
    "entry-time": "Bel\u00e9p\u00e9si id\u0151",
    "error.channels.required": "A csatorn\u00e1k megad\u00e1sa k\u00f6telez\u0151",
    "error.child.bdate.required": "A sz\u00fclet\u00e9snap megad\u00e1sa k\u00f6telez\u0151",
    "error.child.last-name.required": "Vezet\u00e9kn\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.child.name.required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.child.relationship.required": "A csal\u00e1di \u00e1llapot megad\u00e1sa k\u00f6telez\u0151",
    "error.child.sex.required": "A nem megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.building.required": "Az \u00e9p\u00fclet megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.categories.required": "A kateg\u00f3ria megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.email.required": "Az email megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.fiscalName.required": "A p\u00e9nz\u00fcgyi n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.imageUrl.required": "A k\u00e9p url-j\u00e9nek kit\u00f6lt\u00e9se k\u00f6telez\u0151",
    "error.commerce.name.required": "A n\u00e9v kit\u00f6lt\u00e9se k\u00f6telez\u0151",
    "error.commerce.required": "A forgalom megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.vatNumber.required": "A szem\u00e9lyi sz\u00e1m megad\u00e1sa k\u00f6telez\u0151",
    "error.commerce.zone.required": "A z\u00f3na megad\u00e1sa k\u00f6telez\u0151",
    "end_of_construction": "\u00c9p\u00edtkez\u00e9s befejez\u00e9se",
    "error.communication.commerce.required": "V\u00e1lasszon ki legal\u00e1bb egy \u00fczletet",
    "error.communication.companion.required": "N\u00e9h\u00e1ny v\u00e1llalatnak hi\u00e1nyoz\u00f3 adatai vannak",
    "error.crm.children.entryTime.required": "Adja meg bel\u00e9p\u00e9si idej\u00e9t",
    "error.crm.children.expectedExitTime.required": "Adja meg a v\u00e1rhat\u00f3 t\u00e1voz\u00e1si id\u0151pontot ",
    "error.dashboard.bimeId.required": "K\u00f6telez\u0151 a bime dashboardot megadni",
    "error.dashboard.category.required": "K\u00f6telez\u0151 kit\u00f6lteni a kateg\u00f3ri\u00e1t",
    "error.dashboard.name.required": "K\u00f6telez\u0151 megadnia a nev\u00e9t",
    "error.dashboard.panel.required": "K\u00f6telez\u0151 kit\u00f6lteni a dashboard t\u00e1bl\u00e1t",
    "error.development.required": "A Development megad\u00e1sa k\u00f6telez\u0151",
    "error.document-type.required": "K\u00f6telez\u0151 megadnia a dokumentum t\u00edpus\u00e1t",
    "error.email-campaign-template.htmlBody.required": "A kamp\u00e1ny sablonja nem maradhat \u00fcresen",
    "error.email-campaign-template.name.required": "A n\u00e9v nem maradhat \u00fcresen",
    "error.email-campaign.date.invalid_past": "Az elkezd\u00e9si id\u0151 nem lehet m\u00faltbeli cselekm\u00e9ny",
    "error.email-campaign.date.required": "A d\u00e1tum nem maradhat \u00fcresen",
    "error.email-campaign.endDatetime.post_startDatetime": "A befejez\u00e9s d\u00e1tumnak a kezd\u00e9si d\u00e1tum ut\u00e1n kell megt\u00f6rt\u00e9nnie",
    "error.email-campaign.htmlBody.required": "A kamp\u00e1ny sablonja nem maradhat \u00fcresen",
    "error.email-campaign.name.required": "A n\u00e9v nem maradhat \u00fcresen",
    "error.email-campaign.segment.required": "A szegmens ter\u00fclete nem maradhat \u00fcresen",
    "error.email-campaign.subject.required": "Az e-mail t\u00e1rgya nem maradhat \u00fcresen",
    "error.email.invalid": "\u00c9rv\u00e9nytelen e-mail c\u00edm",
    "error.file.commerces.required": "V\u00e1lasszon ki legal\u00e1bb egy \u00fczletet",
    "error.file.name.required": "A n\u00e9v nem maradhat \u00fcresen",
    "error.file.roles.required": "V\u00e1lassza ki a feladatot",
    "error.file.size.invalid": "\u00c9rv\u00e9nytelen f\u00e1jl m\u00e9ret",
    "error.file.type.extension-allowed": "Ez a f\u00e1jlt\u00edpus nem enged\u00e9lyzett. A k\u00f6vetkez\u0151 kiterjeszt\u00e9sek a j\u00f3v\u00e1hagyottak: jpg, jpeg, gif, png, xls, xlsx, doc, docx and pdf",
    "error.file.type.not_allowed": "Ez a f\u00e1jl t\u00edpus nem enged\u00e9lyezett",
    "error.file.url.required": "A f\u00e1jl megad\u00e1sa k\u00f6telez\u0151",
    "error.job-title.required": "A poz\u00edci\u00f3 megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.brand.activity.required": "Tev\u00e9kenys\u00e9g megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.brand.country.required": "Az orsz\u00e1g megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.brand.name.required": "A n\u00e9 megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.brand.optimal-area.required": "Optim\u00e1lis ter\u00fclet kiv\u00e1laszt\u00e1sa k\u00f6telez\u0151",
    "error.leasing.center.country.required": "Az orsz\u00e1g megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.center.gla.required": "GLA k\u00f6telez\u0151",
    "error.leasing.center.name.required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.center.staff.required": "A portf\u00f3li\u00f3kezel\u0151 megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.center.type.required": "A t\u00edpus megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.contact.brand.required": "A m\u00e1rka megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.contact.country.required": "Az orsz\u00e1g megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.contact.email.required": "Az e-mail c\u00edm megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.contact.last-name.required": "A keresztn\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.contact.mobile-phone.required": "A mobil telefonsz\u00e1m megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.contact.name.required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.area.required": "A ter\u00fclet megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.category.required": "A kateg\u00f3ria megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.code.required": "A k\u00f3d megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.erv.required": "ERV megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.leasing-center.required": "K\u00f6telez\u0151 megadnia a bev\u00e1s\u00e1rl\u00f3k\u00f6zpontot",
    "error.leasing.unit.required": "Az egys\u00e9g megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.service-charge.required": "A k\u00f6z\u00f6s k\u00f6lts\u00e9g megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.unit.type.required": "A t\u00edpus megad\u00e1sa k\u00f6telez\u0151",
    "error.leasing.units.2.units.required": "Az egys\u00e9gek megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.owner.code.required": "A k\u00f3d megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.owner.name.required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.property.name.required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.property.price.required": "Az \u00e1r megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.property.reference.required": "A hivatkoz\u00e1s kit\u00f6lt\u00e9se k\u00f6telez\u0151",
    "error.residential.property.rental.required": "A b\u00e9rleti d\u00edj megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.property.residentialDevelopment.required": "A fejleszt\u00e9s kit\u00f6lt\u00e9se k\u00f6telez\u0151",
    "error.residential.property.residentialPropertyType.required": "A t\u00edpus megad\u00e1sa k\u00f6telez\u0151",
    "error.residential.property.state.required": "\u00c1llapot megad\u00e1sa k\u00f6telez\u0151",
    "error.sale.date.invalid_future": "A j\u00f6v\u0151beni d\u00e1tumokhoz nem lehet elad\u00e1st t\u00e1rs\u00edtani.",
    "error.server": "Szerver hiba",
    "error.signature.not_exist": "A felhaszn\u00e1l\u00f3nak nincsen al\u00e1\u00edr\u00e1sa",
    "error.tickets.code.required": "A k\u00f3d megad\u00e1sa k\u00f6telez\u0151",
    "error.tickets.commerce.required": "Forgalom megad\u00e1sa k\u00f6telez\u0151",
    "error.tickets.date.required": "A d\u00e1tum kit\u00f6lt\u00e9se k\u00f6telez\u0151",
    "error.tickets.price.required": "Az \u00e1r megad\u00e1sa k\u00f6telez\u0151",
    "error.user.cp.required": "Az ir\u00e1ny\u00edt\u00f3sz\u00e1m megad\u00e1sa k\u00f6telez\u0151",
    "error.user.email.required": "Az e-mail c\u00edm megad\u00e1sa k\u00f6telez\u0151",
    "error.user.gdpr": "A GDPR elfogad\u00e1sa k\u00f6telez\u0151",
    "error.user.lastName.required": "A keresztn\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.user.lopd": "A GDPR elfogad\u00e1sa k\u00f6telez\u0151",
    "error.user.name.required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "error.user.personType.required": "Person type is required",
    "error.user.phone.required": "A telefon megad\u00e1sa k\u00f6telez\u0151",
    "error.user.registration-date.required": "A regisztr\u00e1ci\u00f3 d\u00e1tum\u00e1nak megad\u00e1sa k\u00f6telez\u0151",
    "error.vat-number.required": "Szem\u00e9lyi sz\u00e1m megad\u00e1sa k\u00f6telez\u0151",
    "error_browser_iframe": "Az \u00d6n b\u00f6ng\u00e9sz\u0151je nem t\u00e1mogatja az iframes-t",
    "erv": "ERV",
    "estate": "Ingatlan",
    "estimated-stay-time": "Becs\u00fclt tart\u00f3zkod\u00e1si id\u0151 (\u00d3\u00d3:PP)",
    "eula": "V\u00e9gfelhaszn\u00e1l\u00f3i liszenszszerz\u0151d\u00e9s",
    "euros": "Eur\u00f3 \u20ac",
    "excel": "Excel",
    "exit-children": "Gyermek kiv\u00e9tele a j\u00e1tsz\u00f3h\u00e1zb\u00f3l",
    "exit-time": "T\u00e1voz\u00e1s ideje",
    "expansion-process-stopped": "A kiterjeszt\u00e9si folyamat le\u00e1llt",
    "experience": "Tapasztalat",
    "export": "Export\u00e1l",
    "external-api": "K\u00fcls\u0151 API",
    "external-collaborator-fee": "K\u00fcls\u0151s munkat\u00e1rs d\u00edja",
    "extra-info": "Extra inform\u00e1ci\u00f3",
    "facade-template-approval-date": "Homlokzati sablon elfogad\u00e1si ideje",
    "facade-template-delivery-date": "Homlokzati sablon teljes\u00edt\u00e9si ideje",
    "factory-outlet": "Factory outlet",
    "family": "Csal\u00e1ddal",
    "family-&-fun": "Csal\u00e1d & sz\u00f3rakoz\u00e1s",
    "featured": "Jelleg\u0171",
    "feb": "Feb",
    "february": "Febru\u00e1r",
    "february-short": "Feb",
    "file": "F\u00e1jl",
    "file.creator_id": "L\u00e9trehoz\u00f3",
    "file.description_optional": "Le\u00edr\u00e1s (opcion\u00e1lis)",
    "file.edit": "F\u00e1jl szerkeszt\u00e9se",
    "file.name": "N\u00e9v",
    "file.new": "\u00daj f\u00e1jl",
    "file.not_found": "Nem tal\u00e1lhat\u00f3 f\u00e1jl",
    "file.roles.all": "Mind",
    "file.roles.commerce": "\u00dczlet",
    "file.roles.management": "Vezet\u0151s\u00e9g",
    "file.roles.owner": "Tulajdonos",
    "file.roles_with_access": "Hozz\u00e1f\u00e9r\u00e9si szerepk\u00f6r",
    "file.shop_with_access": "\u00dczletek hozz\u00e1f\u00e9r\u00e9ssel",
    "file.size": "M\u00e9ret",
    "file.type": "T\u00edpus",
    "file.updated_at": "D\u00e1tum",
    "file.visibility": "L\u00e1that\u00f3s\u00e1g",
    "file_max_allowed": "A maximum, enged\u00e9lyezett m\u00e9ret {{size}}",
    "filter.select": "V\u00e1lassza ki a sz\u0171r\u0151t",
    "final-audit": "V\u00e9gs\u0151 audit",
    "fire-detection": "T\u0171zjelz\u0151",
    "fire-extinction": "T\u0171zolt\u00e1s",
    "first-invoice-amount": "Az els\u0151 sz\u00e1mla \u00f6sszege",
    "first-invoice-date": "Az els\u0151 sz\u00e1mla d\u00e1tuma",
    "first-property": "Az els\u0151 ingatlan",
    "fiscal-name": "C\u00e9gjegyz\u00e9k szerinti n\u00e9v",
    "fit-out-coordinator": "Fit-out koordin\u00e1tor",
    "fit-out-coordinator-cbre": "CBRE-s fit-out koordin\u00e1tor",
    "fit-out-coordinator-external": "K\u00fcls\u0151s fit-out koordin\u00e1tor",
    "fit-out-guarantee": "Fit out garancia",
    "fit-out-guarantee-return": "Fit-out garanci\u00e1lis visszat\u00e9r\u00edt\u00e9s",
    "floor": "Emelet",
    "floor-plans": "Tervrajz",
    "folder": "Mappa",
    "folder.add": "Mappa l\u00e9trehoz\u00e1sa",
    "folder.new.current_dir": "\u00daj mappa l\u00e9trehoz\u00e1sa",
    "folder.warning_delete": "A mappa t\u00f6rl\u00e9s\u00e9vel az \u00f6sszes elemet el fogja t\u00e1vol\u00edtani.",
    "folder_name": "Mappa elnevez\u00e9se",
    "forecast-date": "El\u0151re jelzett id\u0151pont",
    "forgot-password": "Elfelejtette a jelszav\u00e1t?",
    "form-dashboard.bime-dashboard": "Bime dashboard",
    "form-dashboard.button.clear": "T\u00f6rl\u00e9s",
    "form-dashboard.button.submit": "Beny\u00fajt",
    "form-dashboard.category": "Kateg\u00f3ria",
    "form-dashboard.header.available_graphs": "El\u00e9rhet\u0151 grafikon",
    "form-dashboard.name": "Dashboard elnevez\u00e9se",
    "form-dashboard.parameters": "Param\u00e9terek",
    "form-email-campaign.error.import_file": "A f\u00e1jl form\u00e1tum\u00e1nak HTML -nek kell lennie",
    "form-email-campaign.warning.import-template": "Biztosan kicser\u00e9li a jelenlegi sablont?",
    "friday": "P\u00e9ntek",
    "friday-short": "P",
    "from-starting-date": "Kezd\u00e9si d\u00e1tumt\u00f3l",
    "from-signing-date": "Al\u00e1\u00edr\u00e1si d\u00e1tumt\u00f3l",
    "from-unit-delivery-date": "Az egys\u00e9g \u00e1tad\u00e1si d\u00e1tum\u00e1t\u00f3l",
    "from-unit-opening-date": "Az egys\u00e9g nyit\u00e1si d\u00e1tum\u00e1t\u00f3l",
    "full-price": "Teljes \u00e1r",
    "garage-plan": "Gar\u00e1zs tervek",
    "garages-number": "Gar\u00e1zs sz\u00e1ma",
    "general": "\u00c1ltal\u00e1nos",
    "general-data": "\u00c1ltal\u00e1nos adatok",
    "general-info": "\u00c1ltal\u00e1nos inform\u00e1ci\u00f3",
    "general-terms-of-online-contracting": "Az online szerz\u0151d\u00e9sk\u00f6t\u00e9s \u00e1ltal\u00e1nos felt\u00e9telei",
    "girl": "L\u00e1ny",
    "general_terms_of_online_contracting": "Az online szerz\u0151d\u00e9sk\u00f6t\u00e9s \u00e1ltal\u00e1nos felt\u00e9telei",
    "gla": "GLA",
    "grandchild": "Unoka",
    "guard": "\u0150r",
    "guard.chose_staff_member": "V\u00e1lasszon ki egy alkalmazottat...",
    "guard.no_assigned_this_day": "Erre a napra nincsen \u0151r alkalmazva.",
    "has-email": "Van e-mail c\u00edme",
    "header": "Fejl\u00e9c",
    "hotel": "Hotel",
    "how-to-arrive-plan": "\u00c9rkez\u00e9s megtervez\u00e9se",
    "hs-plan": "T\u0171z- \u00e9s munkav\u00e9delmi terv",
    "hvac": "HVAC",
    "id": "Azonos\u00edt\u00f3",
    "image": "K\u00e9p",
    "image-required": "K\u00e9p megad\u00e1sa k\u00f6telez\u0151",
    "images": "K\u00e9pek",
    "info.default.error.message": "Hiba t\u00f6rt\u00e9nt. K\u00e9rem k\u00e9s\u0151bb pr\u00f3b\u00e1lja \u00fajra ",
    "info.default.error.title": "Hiba",
    "info.default.succes.title": "Siker",
    "info.default.success.message": "A m\u0171velet sikeresen v\u00e9gre lett hajtva.",
    "informative-message.change-password.error.message": "\u00c9rv\u00e9nytelen jelsz\u00f3 vagy \u00e9rv\u00e9nytelen form\u00e1tum",
    "informative-message.change-password.success.message": "A jelszava megv\u00e1ltozott. \u00c1t fogjuk ir\u00e1ny\u00edtani \u00d6nt a bejelentkez\u00e9si oldalra.",
    "in_construction": "\u00c9p\u00edtkez\u00e9s folyamatban van",
    "informative-message.close-month.error.message": "Hiba l\u00e9pett fel a h\u00f3napz\u00e1r\u00e1s elutas\u00edt\u00e1sakor. K\u00e9s\u0151bb pr\u00f3b\u00e1lja meg \u00fajra. ",
    "informative-message.close-month.success.message": "A h\u00f3napot sikeresen lez\u00e1rta.",
    "informative-message.default.error.message": "Hiba t\u00f6rt\u00e9nt. K\u00e9rem k\u00e9s\u0151bb pr\u00f3b\u00e1lja \u00fajra ",
    "informative-message.default.error.title": "Hiba",
    "informative-message.default.succes.title": "Siker",
    "informative-message.default.success.message": "A m\u0171velet sikeresen v\u00e9gre lett hajtva.",
    "informative-message.export-crm-csv.success.message": "Egy CSV f\u00e1jlt tartalmaz\u00f3 e-mailt fog kapni.",
    "informative-message.reject-close-month.error.message": "Hiba l\u00e9pett fel a h\u00f3napz\u00e1r\u00e1s elutas\u00edt\u00e1sakor. K\u00e9s\u0151bb pr\u00f3b\u00e1lja meg \u00fajra. ",
    "informative-message.reject-close-month.success.message": "A h\u00f3napz\u00e1r\u00e1s sikeresen el lett utas\u00edtva. Az \u00e9rtes\u00edt\u00e9st elk\u00fcldt\u00fck az \u00fczletnek.",
    "informative-message.upload-yardi.error": "A Yardi f\u00e1jlt nem siker\u00fclt helyesen felt\u00f6lteni. K\u00e9rem pr\u00f3b\u00e1lja meg \u00fajra vagy vegye fel a kapcsolatot a rendszergazd\u00e1val.",
    "informative-message.upload-yardi.success": "A Yardi f\u00e1jl helyesen lett felt\u00f6ltve.",
    "initial-value": "Kezd\u0151\u00e9rt\u00e9k",
    "insert-email": "Illessze be az e-mail c\u00edm\u00e9t",
    "installed.apps": "Regisztr\u00e1lt appok",
    "internal": "Bels\u0151",
    "intranet.close_month-message": "Le fogja z\u00e1rni a {{shop}} a {{month}} h\u00f3napra vonatkoz\u00f3 elad\u00e1sait.",
    "intranet.communications.accept-eula": "Igen, szeretn\u00e9k egy harmadik f\u00e9lt\u0151l, elektronikus \u00faton \u00e9rtes\u00edt\u00e9seket kapni.",
    "intranet.communications.contact-it": "Hiba l\u00e9pett fel a harmadik f\u00e9lt\u0151l sz\u00e1rmaz\u00f3 adatok fogad\u00e1sakor. K\u00e9rem vegye fel a kapcsolatot az Informatikai oszt\u00e1llyal \u00e9s k\u00fcldje el a k\u00f6vetkez\u0151 hibak\u00f3dot:",
    "intranet.communications.error": "Hiba t\u00f6rt\u00e9nt az alkalmaztottak sz\u00e1m\u00e1nak felt\u00f6lt\u00e9sekor",
    "intranet.communications.eula-communications-description": "Az intranet kommunik\u00e1ci\u00f3s rendszer\u00e9nek haszn\u00e1lat\u00e1hoz el kell fogadnia a felhaszn\u00e1l\u00e1si felt\u00e9teleket.",
    "intranet.communications.not-contactable-staff": "Jelenleg a rendszerben nincs el\u00e9rhet\u0151 munkat\u00e1rs. K\u00e9rem t\u00f6lts\u00f6n fel legal\u00e1bb egy el\u00e9rhet\u0151 munkat\u00e1rsat.",
    "intranet.communications.resent-success": "A kommunik\u00e1ci\u00f3 sikeresen \u00fajra lett k\u00fcldve.",
    "intranet.communications.terms-and-conditions": "Haszn\u00e1lati felt\u00e9telek",
    "intranet.communications.title-eula": "Intranetes t\u00e1j\u00e9koztat\u00e1s - GDPR elfogad\u00e1sa",
    "intranet.communicatios.resend-staff-members": "A kommunik\u00e1ci\u00f3 el\u00e9r\u00e9s\u00e9hez v\u00e1lasszon ki egy k\u00fcls\u0151 munkat\u00e1rsat",
    "intranet.sales.import": "CSV import\u00e1l\u00e1sa",
    "intranet.sales.import-sales": "Elad\u00e1sok import\u00e1l\u00e1sa",
    "invalid-token": "\u00c9rv\u00e9nytelen token",
    "investor": "Befektet\u0151",
    "invoice-amount": "Sz\u00e1mla \u00f6sszege",
    "invoices": "Sz\u00e1ml\u00e1k",
    "is": "van",
    "is-in-play-center": "A j\u00e1tsz\u00f3h\u00e1zban van",
    "is_not": "nincs",
    "jan": "Jan",
    "january": "Janu\u00e1r",
    "january-short": "Jan",
    "job-title": "Munkak\u00f6r",
    "job-title-required": "A munkak\u00f6r megad\u00e1sa k\u00f6telez\u0151",
    "jul": "J\u00fal",
    "july": "J\u00falius",
    "july-short": "J\u00fal",
    "jun": "J\u00fan",
    "june": "J\u00fanius",
    "june-short": "J\u00fan",
    "kpi": "KPI",
    "kpi-tech": "KPI Tech",
    "kpi-tech-supervisor": "KPI Tech supervisor",
    "kpi-tech-user": "KPI Tech felhaszn\u00e1l\u00f3",
    "kpi-tech.change-year-warning": "A ment\u00e9s n\u00e9lk\u00fcli v\u00e1ltoztat\u00e1sok el fognak veszni. Biztosan folytatja?",
    "kpi-tech.close-month-warning": "A kiv\u00e1lasztott h\u00f3napra vonatkoz\u00f3 KPI-ok \u00e9s k\u00f6z\u00f6s k\u00f6lts\u00e9gek lez\u00e1r\u00e1sra ker\u00fclnek",
    "kpi-tech.see-data-sheet": "Adatlap",
    "kpi.tooltip.active-users": "Egy adott id\u0151szakban, az alkalmaz\u00e1st akt\u00edvan haszn\u00e1l\u00f3k sz\u00e1ma",
    "kpi.tooltip.app-users": "Egy adott id\u0151szakban, az alkalmaz\u00e1son kereszt\u00fcl regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma ",
    "kpi.tooltip.average-visit-time": "Egy adott id\u0151szakon bel\u00fcl, a v\u00e1s\u00e1rl\u00f3k \u00e1tlagosan \u00e1ruh\u00e1zban t\u00f6lt\u00f6tt ideje.",
    "kpi.tooltip.downloads": "Egy kiv\u00e1lasztott id\u0151szakra vonatkoz\u00f3 app let\u00f6lt\u00e9sek",
    "kpi.tooltip.dwell-time": "Egy kiv\u00e1lasztott id\u0151szakban, az \u00e1ruh\u00e1zban t\u00f6lt\u00f6tt \u00e1tlag id\u0151tartam",
    "kpi.tooltip.installed-apps": "Egy kiv\u00e1laszott id\u0151szakban a regisztr\u00e1lt app-ok sz\u00e1ma",
    "kpi.tooltip.play-center-users": "Egy kiv\u00e1laszott id\u0151szakban a j\u00e1tsz\u00f3h\u00e1zon kereszt\u00fcl regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "kpi.tooltip.recurrence": "Egy kiv\u00e1laszott id\u0151szakban a felhaszn\u00e1l\u00f3k l\u00e1togat\u00e1sainak a sz\u00e1ma",
    "kpi.tooltip.registered-app-users": "Egy kiv\u00e1laszott id\u0151szakban, az applik\u00e1ci\u00f3n kereszt\u00fcl \u00fajonnan regisztr\u00e1lt felhaszn\u00e1l\u00f3k",
    "kpi.tooltip.registered-users-total": "Egy kiv\u00e1laszott id\u0151szakban a regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "kpi.tooltip.rother-users": "Egy kiv\u00e1lasztott id\u0151szakban m\u00e1s csatorn\u00e1n kereszt\u00fcl regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "kpi.tooltip.social-users": "Egy kiv\u00e1laszott id\u0151szakban a WIFI h\u00e1l\u00f3zaton kereszt\u00fcl regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "kpi.tooltip.visits": "Egy kiv\u00e1laszott id\u0151szakban a bev\u00e1s\u00e1rl\u00f3 k\u00f6zpont l\u00e1togat\u00f3inak sz\u00e1ma ",
    "kpi.tooltip.web-users": "Egy kiv\u00e1laszott id\u0151szakban a webes fel\u00fcleten kereszt\u00fcl regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "kpi.vs-last-month": "vs utols\u00f3 h\u00f3nap",
    "kpi.vs-same-month-last-year": "vs ugyanaz a h\u00f3nap egy \u00e9vvel kor\u00e1bban",
    "kpis": "KPIs",
    "kpitech.GLA": "GLA",
    "kpitech.GLA-food-court-terraces": "GLA \u00e9tel udvar teraszok",
    "kpitech.GLA-hiper\/supermarket": "GLA hiper\/szupermarket",
    "kpitech.GLA-leisure": "GLA szabadid\u0151",
    "kpitech.GLA-offices": "GLA irod\u00e1k",
    "kpitech.GLA-others": "GLA egyebek",
    "kpitech.GLA-restaurants": "GLA \u00e9ttermek",
    "kpitech.GLA-retail": "GLA kiskereskedelem",
    "kpitech.GLA-terraces": "GLA teraszok",
    "kpitech.GLA-warehouse": "GLA rakt\u00e1rak",
    "kpitech.Others": "Egyebek",
    "kpitech.P1": "P1",
    "kpitech.P2": "P2",
    "kpitech.P3": "P3",
    "kpitech.P4": "P4",
    "kpitech.P5": "P5",
    "kpitech.P6": "P6",
    "kpitech.access-tariff": "Hozz\u00e1f\u00e9r\u00e9si d\u00edjszab\u00e1s",
    "kpitech.accesses": "Hozz\u00e1f\u00e9r\u00e9sek",
    "kpitech.annual-ammount": "\u00c9ves \u00f6sszeg",
    "kpitech.annual-amount": "\u00c9ves \u00f6sszeg",
    "kpitech.asset": "Ingatlan",
    "kpitech.automatic-door-maintenance": "Automata ajt\u00f3 karbantart\u00e1sa",
    "kpitech.bicycle": "Bicikli",
    "kpitech.bms": "BMS",
    "kpitech.boilers": "Bojlerek",
    "kpitech.brand\/model": "M\u00e1rka\/Model",
    "kpitech.breastfeeding-rooms": "Szoptat\u00e1sra kijel\u00f6lt helys\u00e9gek",
    "kpitech.breeam-certification": "Breeam tanus\u00edtv\u00e1ny",
    "kpitech.business-activity-coordination": "\u00dczleti tev\u00e9kenys\u00e9gek koordin\u00e1l\u00e1sa",
    "kpitech.cbre-people": "CBRE munkat\u00e1rsak",
    "kpitech.cctv": "CCTV",
    "kpitech.cctv-cameras": "CCTV kamer\u00e1k",
    "kpitech.cctv-ip-cameras": "CCTV ip kamer\u00e1k",
    "kpitech.certificactions": "Tanus\u00edtv\u00e1nyok",
    "kpitech.city": "V\u00e1ros",
    "kpitech.cleaning": "Takar\u00edt\u00e1s",
    "kpitech.cluster": "Csoport",
    "kpitech.cmms": "CMMS",
    "kpitech.co2-detection-maintenance": "CO2 \u00e9rz\u00e9kel\u0151 karbantart\u00e1sa",
    "kpitech.complaints-filed-to-police": "Rend\u0151rs\u00e9gnek \u00e1tadott panaszok",
    "kpitech.compost": "Komposzt",
    "kpitech.consumables": "\u00c9lelmiszerek",
    "kpitech.contract-number": "Szerz\u0151d\u00e9s sz\u00e1ma",
    "kpitech.contractor": "Megb\u00edz\u00f3",
    "kpitech.conventional": "Hagyom\u00e1nyos",
    "kpitech.country": "Orsz\u00e1g",
    "kpitech.covered": "Fedett",
    "kpitech.crane-maintenance": "Daru karbantart\u00e1s",
    "kpitech.cups": "Cs\u00e9sz\u00e9k",
    "kpitech.distributor": "Eloszt\u00f3",
    "kpitech.disturbance": "Zavar",
    "kpitech.doors": "Ajt\u00f3k",
    "kpitech.ducts-cleaning": "Csatorna tiszt\u00edt\u00e1s",
    "kpitech.electric-supply": "Elektromos energiaell\u00e1t\u00e1s",
    "kpitech.emergency-drill": "V\u00e9szhelyzeti elj\u00e1r\u00e1s",
    "kpitech.ems": "EMS",
    "kpitech.energy-performance-certificate-epc": "Energetikai tanus\u00edtv\u00e1ny EPC",
    "kpitech.escalators": "Mozg\u00f3l\u00e9pcs\u0151k",
    "kpitech.evacuation-drill": "Evaku\u00e1ci\u00f3s elj\u00e1r\u00e1s",
    "kpitech.expiration-date": "Lej\u00e1rat d\u00e1tuma",
    "kpitech.expulsions": "Kitilt\u00e1s",
    "kpitech.family-boxes": "Csal\u00e1di mosd\u00f3k",
    "kpitech.fire-detection-maintenance": "T\u0171z\u00e9rz\u00e9kel\u0151 karbantart\u00e1sa",
    "kpitech.fire-protection": "T\u0171zv\u00e9delem",
    "kpitech.fire-protection-maintenance": "T\u0171zv\u00e9delmi rendszer karbantart\u00e1sa",
    "kpitech.footfall": "L\u00e1togat\u00f3sz\u00e1m",
    "kpitech.footfall-maintenance": "L\u00e1togat\u00f3sz\u00e1m m\u00e9r\u0151 rendszer karbantart\u00e1sa",
    "kpitech.fountains-maintenance": "Iv\u00f3kutak karbantart\u00e1sa",
    "kpitech.free-parking": "Ingyenes parkol\u00e1s",
    "kpitech.gardening": "Kert\u00e9szet",
    "kpitech.gardening-special-works": "Kert\u00e9szeti speci\u00e1lis munk\u00e1k",
    "kpitech.gas-supply": "G\u00e1zell\u00e1t\u00e1s",
    "kpitech.general-maintenance": "\u00c1ltal\u00e1nos karbantart\u00e1s",
    "kpitech.gla-error": "GLA - A sz\u00e1mnak egyeznie kell a Ingatlan szekci\u00f3ban l\u00e9v\u0151 GLA sz\u00e1mmal",
    "kpitech.glass": "\u00dcveg",
    "kpitech.green-areas": "Z\u00f6ld ter\u00fcletek",
    "kpitech.green-energy-100-certified": "Z\u00f6ld energia 100 hiteles\u00edtve",
    "kpitech.handicapped": "Mozg\u00e1skorl\u00e1tozott",
    "kpitech.handicapped-boxes": "Mozg\u00e1skorl\u00e1tozott mosd\u00f3k",
    "kpitech.high-voltage": "Magas fesz\u00fclts\u00e9g",
    "kpitech.high-voltage-maintenance": "Magas fesz\u00fclts\u00e9g karbantart\u00e1sa",
    "kpitech.hours-per-year": "\u00c9venk\u00e9nti ledolgozott \u00f3r\u00e1k sz\u00e1ma",
    "kpitech.hours-per-year-remote-security-center": "A t\u00e1voli biztons\u00e1gi k\u00f6zpont \u00e9venk\u00e9nti ledolgozott \u00f3r\u00e1inak sz\u00e1ma",
    "kpitech.hours-per-year-security-assistants": "A biztons\u00e1gi c\u00e9g asszisztenseinek \u00e9venk\u00e9nti ledolgozott \u00f3r\u00e1inak sz\u00e1ma",
    "kpitech.hours-per-year-security-guards": "Biztons\u00e1gi \u0151r\u00f6k \u00e9venk\u00e9nti ledolgozott \u00f3r\u00e1inak sz\u00e1ma",
    "kpitech.hvac": "HVAC",
    "kpitech.hvac-maintenance": "HVAC karbantart\u00e1s",
    "kpitech.ibi": "IBI",
    "kpitech.iso-14001-certification": "ISO 14001 tanus\u00edtv\u00e1ny",
    "kpitech.iso-22320-certification": "ISO 22320 tanus\u00edtv\u00e1ny",
    "kpitech.iso-9001-certifcation": "ISO 9001 tanus\u00edtv\u00e1ny",
    "kpitech.kW-power": "KW teljes\u00edtm\u00e9ny",
    "kpitech.kiosks": "Kioszkok",
    "kpitech.led-lighting": "Led vil\u00e1g\u00edt\u00e1s",
    "kpitech.legal-maintenance-expiring-dates": "K\u00f6telez\u0151 karbantart\u00e1s lej\u00e1rati d\u00e1tuma",
    "kpitech.legionella-maintenance": "Legionella karbantart\u00e1sa",
    "kpitech.lifelines-maintenance": "\u00c9letciklus szerinti karbantart\u00e1s",
    "kpitech.lifts": "Liftek",
    "kpitech.lighting-indoor-common-areas": "K\u00f6z\u00f6s ter\u00fcletek bels\u0151 vil\u00e1g\u00edt\u00e1sa",
    "kpitech.lighting-outdoor-common-areas": "K\u00f6z\u00f6s ter\u00fcletek k\u00fcls\u0151 vil\u00e1g\u00edt\u00e1sa",
    "kpitech.lighting-outdoor-parking": "K\u00fcls\u0151 parkol\u00f3 vil\u00e1g\u00edt\u00e1sa",
    "kpitech.lighting-rods-maintenance": "Vil\u00e1g\u00edt\u00f3 oszlopok karbantart\u00e1sa",
    "kpitech.lighting-underground-parking": "A f\u00f6ldalatti parkol\u00f3 vil\u00e1g\u00edt\u00e1sa",
    "kpitech.loading-docks": "Rakod\u00f3 r\u00e1mp\u00e1k",
    "kpitech.loading-docks-maintenance": "Rakod\u00f3 r\u00e1mp\u00e1k karbantart\u00e1sa",
    "kpitech.low-voltage": "Kis fesz\u00fclts\u00e9g",
    "kpitech.lrp": "LRP",
    "kpitech.machinery": "G\u00e9p\u00e9szet",
    "kpitech.maintenance": "Karbantart\u00e1s",
    "kpitech.management-team-on-site": "On-site management csapat",
    "kpitech.maximum-number-of-cleaners-per-shift": "A takar\u00edt\u00f3k m\u0171szakonk\u00e9nti maximum l\u00e9tsz\u00e1ma",
    "kpitech.maximum-number-of-gardeners-per-shift": "A kert\u00e9szek m\u0171szakonk\u00e9nti maximum l\u00e9tsz\u00e1ma",
    "kpitech.maximum-number-of-security-assistants-per-shift": "A biztons\u00e1gi asszisztensek m\u0171szakonk\u00e9nti maxim\u00e1lis l\u00e9tsz\u00e1ma",
    "kpitech.maximum-number-of-security-guards-per-shift": "A biztons\u00e1gi \u0151r\u00f6k m\u0171szakonk\u00e9nti maximum l\u00e9tsz\u00e1ma",
    "kpitech.maximum-number-of-technicians-per-shift": "A karbantart\u00f3k m\u0171szakonk\u00e9nti maxim\u00e1lis l\u00e9tsz\u00e1ma",
    "kpitech.men-boxes-urinaries": "A f\u00e9rfi mosd\u00f3k piszo\u00e1rjai",
    "kpitech.meter-number": "M\u00e9r\u0151\u00f3ra sz\u00e1ma",
    "kpitech.motorcycle": "Motorbicikli",
    "kpitech.multiannual-plan": "T\u00f6bb\u00e9ves terv",
    "kpitech.name": "N\u00e9v",
    "kpitech.no-parking-sign": "Nincs parkol\u00f3jel",
    "kpitech.notice-date": "\u00c9rtes\u00edt\u00e9s napja",
    "kpitech.number": "Sz\u00e1m",
    "kpitech.number-of-cleaners": "Takar\u00edt\u00f3k sz\u00e1ma",
    "kpitech.number-of-gardeners": "Kert\u00e9szek sz\u00e1ma",
    "kpitech.number-of-security-assistants": "Biztons\u00e1gi asszisztensek sz\u00e1ma",
    "kpitech.number-of-security-guards": "Biztons\u00e1gi \u0151r\u00f6k sz\u00e1ma",
    "kpitech.number-of-technicians": "Karbantart\u00f3k sz\u00e1ma",
    "kpitech.number-opening-days": "Nyitva tartott napok sz\u00e1ma",
    "kpitech.organic": "Organikus",
    "kpitech.oshas-180001-certifcation": "Oshas 180001 tanus\u00edtv\u00e1ny",
    "kpitech.other-certificates": "Egy\u00e9b tanus\u00edtv\u00e1nyok",
    "kpitech.other-special": "Egy\u00e9b speci\u00e1lis jellemz\u0151",
    "kpitech.other-vertical-transportation-devices": "Egy\u00e9b vertik\u00e1lis k\u00f6zleked\u0151 eszk\u00f6z",
    "kpitech.others": "Egy\u00e9b",
    "kpitech.outdoor-parking": "Kinti parkol\u00e1s",
    "kpitech.outlet-full-price": "Outlet teljes \u00e1r",
    "kpitech.owner": "Tulajdonos",
    "kpitech.pa-system-maintenance": "PA rendszer karbantart\u00e1sa",
    "kpitech.panoramic-lifts": "Panor\u00e1ma liftek",
    "kpitech.paperboard": "Pap\u00edrt\u00e1bla",
    "kpitech.parking": "Parkol\u00e1s",
    "kpitech.parking-access-control-system-maintenance": "Parkol\u00e1s ir\u00e1ny\u00edt\u00e1si rendszer karbantart\u00e1sa",
    "kpitech.parking-canopies": "Parkol\u00f3 pavilonok",
    "kpitech.parking-slots": "Parkol\u00f3hely",
    "kpitech.pedestrian": "Gyalogos",
    "kpitech.pedestrians": "Gyalogosok",
    "kpitech.performance": "Teljes\u00edtm\u00e9ny",
    "kpitech.pest-control": "K\u00e1rtev\u0151irt\u00e1s",
    "kpitech.photovoltaic-solar-system-maintenance": "Fotoelektronos napenergiarendszer karbantart\u00e1sa",
    "kpitech.plants-replacement": "N\u00f6v\u00e9ny p\u00f3tl\u00e1s",
    "kpitech.plastic": "M\u0171anyag",
    "kpitech.power-generator-maintenance": "\u00c1ramfejleszt\u0151 karbantart\u00e1sa",
    "kpitech.power-indoor-common-areas": "Bels\u0151 ter\u00fcletek energiaell\u00e1t\u00e1sa",
    "kpitech.product": "Term\u00e9k",
    "kpitech.pruning": "Fametsz\u00e9s",
    "kpitech.recycled-waste": "\u00dajrahasznos\u00edtott hullad\u00e9k",
    "kpitech.recycledWaste": "\u00dajrahasznos\u00edtott hullad\u00e9k",
    "kpitech.remote-alarm-center": "T\u00e1voli k\u00f6zponti riaszt\u00e1s",
    "kpitech.remote-security-center": "T\u00e1voli biztons\u00e1gi k\u00f6zpont",
    "kpitech.revolving": "Forg\u00f3ajt\u00f3",
    "kpitech.roof": "Tet\u0151",
    "kpitech.seccurity-incidents": "Biztons\u00e1gi esem\u00e9ny",
    "kpitech.security": "\u0150rz\u00e9s",
    "kpitech.security-assistants": "Biztons\u00e1gi asszisztensek",
    "kpitech.security-facilities-maintenance": "Biztons\u00e1gi l\u00e9tes\u00edtm\u00e9nyek karbantart\u00e1sa",
    "kpitech.security-guards": "Biztons\u00e1gi \u0151r\u00f6k",
    "kpitech.security-technical-equipment": "Biztons\u00e1gtechnikai eszk\u00f6z\u00f6k",
    "kpitech.select": "Kiv\u00e1laszt",
    "kpitech.select-performance": "Teljes\u00edtm\u00e9ny kiv\u00e1laszt\u00e1sa",
    "kpitech.select-product": "Term\u00e9k",
    "kpitech.select-smartcx": "Smartcx",
    "kpitech.self-protection-plan": "\u00d6nv\u00e9delmi terv",
    "kpitech.service-charges": "K\u00f6z\u00f6s k\u00f6lts\u00e9g",
    "kpitech.service-lifts": "Szerv\u00edzliftek",
    "kpitech.sewer-cleaning": "Csatorna tiszt\u00edt\u00e1s",
    "kpitech.sliding": "Fot\u00f3cell\u00e1s ajt\u00f3",
    "kpitech.smart-parking": "Okos parkol\u00e1s",
    "kpitech.smart-parking-maintenance": "Okos parkol\u00e1s karbantart\u00e1sa",
    "kpitech.smoke-evacuation-system-maintenance": "F\u00fcst elvezet\u0151 rendszer karbantart\u00e1sa",
    "kpitech.solid-urban-waste": "Szil\u00e1rd h\u00e1ztart\u00e1si hullad\u00e9k",
    "kpitech.special-cleanings": "Speci\u00e1lis takar\u00edt\u00e1s",
    "kpitech.specialty-leasing": "Speci\u00e1lis l\u00edzing",
    "kpitech.supplier": "Besz\u00e1ll\u00edt\u00f3",
    "kpitech.supply-pressure": "Bej\u00f6v\u0151 nyom\u00e1s",
    "kpitech.supply-voltage": "Bej\u00f6v\u0151 fesz\u00fclts\u00e9g",
    "kpitech.surface": "Fel\u00fclet",
    "kpitech.surfaces": "Fel\u00fcletek",
    "kpitech.system": "Rendszer",
    "kpitech.taxes": "Illet\u00e9kek",
    "kpitech.tesla-electric-charger": "Tesla elektromos t\u00f6lt\u0151",
    "kpitech.thefts": "Lop\u00e1s",
    "kpitech.thermal-solar-system-maintenance": "Szol\u00e1rtermikus rendszerek karbantart\u00e1sa",
    "kpitech.tilting": "D\u0151l\u00e9s",
    "kpitech.toilets": "Mosd\u00f3k",
    "kpitech.toilets-number": "Mosd\u00f3k sz\u00e1ma",
    "kpitech.toilets-surface": "Mosd\u00f3k fel\u00fclete",
    "kpitech.total": "\u00d6sszes",
    "kpitech.total-annual-ammount": "\u00c9ves \u00f6sszmennyis\u00e9g",
    "kpitech.total-automatic": "Teljesen automata",
    "kpitech.total-common-areas": "\u00d6sszes k\u00f6z\u00f6s ter\u00fclet",
    "kpitech.total-gla": "\u00d6sszes GLA",
    "kpitech.total-parking-areas": "\u00d6sszes parkol\u00f3ter\u00fclet",
    "kpitech.total-people": "\u00d6sszes ember",
    "kpitech.total-security-amount": "Teljes \u0151rz\u00e9s \u00f6sszege",
    "kpitech.total-security-cameras": "Biztons\u00e1gi kamer\u00e1k teljes sz\u00e1ma",
    "kpitech.total-security-incidents": "\u00d6sszes biztons\u00e1gi incidens",
    "kpitech.total-vertical-transportation-devices": "\u00d6sszes vertik\u00e1lis k\u00f6zleked\u0151 eszk\u00f6z",
    "kpitech.total-water": "\u00d6sszes v\u00edzmennyis\u00e9g",
    "kpitech.tourism": "Turizmus",
    "kpitech.travelators": "Mozg\u00f3j\u00e1rda",
    "kpitech.uncovered": "Fedetlen",
    "kpitech.underground-parking": "F\u00f6ldalatti parkol\u00e1s",
    "kpitech.une-170001-certification": "UNE 170001 tanus\u00edtv\u00e1ny",
    "kpitech.units": "Egys\u00e9gek",
    "kpitech.universal-electric-charger": "Univerz\u00e1lis elektromos t\u00f6lt\u0151",
    "kpitech.ups-maintenance": "UPS karbantart\u00e1s",
    "kpitech.urban": "V\u00e1rosi",
    "kpitech.vandalism": "Vandalizmus",
    "kpitech.vehicles": "J\u00e1rm\u0171vek",
    "kpitech.vertical-transportation": "Vertik\u00e1lis k\u00f6zleked\u00e9s",
    "kpitech.vertical-transportation-maintenance": "Vertik\u00e1lis k\u00f6zleked\u00e9s karbantart\u00e1sa",
    "kpitech.waste-management": "Hullad\u00e9kgazd\u00e1lkod\u00e1s",
    "kpitech.waste-rate": "Hullad\u00e9kgazd\u00e1lkod\u00e1si r\u00e1ta",
    "kpitech.water-common-areas": "K\u00f6z\u00f6s ter\u00fcletek v\u00edzfogyaszt\u00e1sa",
    "kpitech.water-fountains": "Sz\u00f6k\u0151kutak",
    "kpitech.water-hvac": "HVAC v\u00edzfogyaszt\u00e1sa",
    "kpitech.water-irrigation": "\u00d6nt\u00f6z\u00e9s",
    "kpitech.water-others": "Egy\u00e9b v\u00edzfogyaszt\u00e1s",
    "kpitech.water-supply": "V\u00edzell\u00e1t\u00e1s",
    "kpitech.wifi-maintenance": "WIFI karbantart\u00e1s",
    "kpitech.women-boxes": "N\u0151i mosd\u00f3k",
    "kpitech.wood": "Fa",
    "kpitech.workforce": "Munkaer\u0151",
    "kpitech.year": "\u00c9v",
    "kyc": "KYC",
    "lack-of-founds": "Forr\u00e1s hi\u00e1ny",
    "lack-of-franchisee": "Franchise partner hi\u00e1nya",
    "last-activity": "Utols\u00f3 aktivit\u00e1s",
    "last-contact-date": "Utols\u00f3 kapcsolatfelv\u00e9tel id\u0151pontja",
    "last-month-closed": "Utols\u00f3 lez\u00e1rt h\u00f3nap",
    "last-name": "Vezet\u00e9kn\u00e9v",
    "last-name-required": "Vezet\u00e9kn\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "last-units": "Utols\u00f3 egys\u00e9gek",
    "last-year": "Utols\u00f3 \u00e9v",
    "lease-payments": "B\u00e9rleti d\u00edjfizet\u00e9sek",
    "leasing": "B\u00e9rbead\u00e1s",
    "lastName-required": "A vezet\u00e9kn\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "leasing-agent": "B\u00e9rbead\u00f3 \u00fcgyn\u00f6k",
    "leasing-brand.edit": "Szerkeszt\u00e9s",
    "leasing-brand.new": "\u00daj",
    "leasing-center-type": "T\u00edpus",
    "leasing-center.edit": "Szerkeszt\u00e9s",
    "leasing-center.new": "\u00daj b\u00e9rbead\u00e1si k\u00f6zpont",
    "leasing-commercial-proposal": "B\u00e9rleti d\u00edj javaslat",
    "leasing-contact.edit": "Szerkeszt\u00e9s",
    "leasing-contact.new": "\u00daj",
    "leasing-operation.edit": "Szerkeszt\u00e9s",
    "leasing-operation.new": "\u00daj",
    "leasing-opeartion.edit": "Szerkeszt\u00e9s",
    "leasing-opeartion.new": "\u00daj",
    "leasing-owner": "Tulajdonos",
    "last-year-period": "Az el\u0151z\u0151 \u00e9v ezen id\u0151szaka ",
    "leasing-owner.edit": "Szerkeszt\u00e9s",
    "leasing-owner.new": "\u00daj",
    "leasing-unit": "Egys\u00e9g",
    "leasing-unit-storage-room": "T\u00e1rol\u00f3",
    "leasing-unit-terrace": "Terasz",
    "leasing-unit.edit": "B\u00e9relt egys\u00e9g szerkeszt\u00e9se",
    "leasing-unit.new": "\u00daj b\u00e9relt egys\u00e9g",
    "leasing-unit.select-type": "T\u00edpus kiv\u00e1laszt\u00e1sa",
    "leasing-unit.type": "T\u00edpus",
    "leasing-units": "Egys\u00e9gek",
    "leasing.accessories": "Kell\u00e9kek",
    "leasing.activity": "Tev\u00e9kenys\u00e9g",
    "leasing.advanced-negotiation": "El\u0151rehaladott t\u00e1rgyal\u00e1s",
    "leasing.agent": "\u00dcgyn\u00f6k",
    "leasing.books": "K\u00f6nyvek",
    "leasing.bowling": "Bowling",
    "leasing.brand": "M\u00e1rka",
    "leasing.brand.activity": "Tev\u00e9kenys\u00e9g",
    "leasing.brand.country": "Orsz\u00e1g",
    "leasing.brand.filters": "Sz\u0171r\u0151k",
    "leasing.brand.name": "N\u00e9v",
    "leasing.brand.optimal-area": "Optim\u00e1lis ter\u00fclet",
    "leasing.brand.select-activity": "Tev\u00e9kenys\u00e9g kiv\u00e1laszt\u00e1sa",
    "leasing.brand.select-country": "Orsz\u00e1g kiv\u00e1laszt\u00e1sa",
    "leasing.brand.select-optimal-area": "Optim\u00e1lis ter\u00fclet kiv\u00e1laszt\u00e1sa",
    "leasing.brands.see-contacts": "Menjen a kapcsolatokba",
    "leasing.center": "K\u00f6zpont",
    "leasing.center.CBRE-legal-service": "CBRE Jogi szolg\u00e1ltat\u00e1s",
    "leasing.center.attach-logo": "Logo hozz\u00e1ad\u00e1sa (png,jpeg,gif or jpg)",
    "leasing.center.contractType": "Szerz\u0151d\u00e9s t\u00edpusa",
    "leasing.center.group-data": "Csoport adatok",
    "leasing.center.logo": "Log\u00f3",
    "leasing.center.country": "Orsz\u00e1g",
    "leasing.center.name": "N\u00e9v",
    "leasing.center.portfolio-manager": "Portfoli\u00f3 menedzser",
    "leasing.center.select_country": "Orsz\u00e1g kiv\u00e1laszt\u00e1sa",
    "leasing.center.select_manager": "Menedzser kiv\u00e1laszt\u00e1sa",
    "leasing.centers.filters": "Sz\u0171r\u0151k",
    "leasing.centers.name": "N\u00e9v",
    "leasing.centers.total_units": "\u00d6sszes egys\u00e9g",
    "leasing.childrens-apparel": "Gyermek ruh\u00e1zat",
    "leasing.cinema": "Mozi",
    "leasing.coffeeshop-icecream": "K\u00e1v\u00e9z\u00f3 fagylaltoz\u00f3",
    "leasing.contact": "Kapcsolat",
    "leasing.contact.brand": "M\u00e1rka",
    "leasing.contact.brands": "M\u00e1rk\u00e1k",
    "leasing.contact.country": "Orsz\u00e1g",
    "leasing.contact.email": "E-mail",
    "leasing.contact.last-name": "Vezet\u00e9kn\u00e9v",
    "leasing.contact.mobile-phone": "Mobil telefon",
    "leasing.contact.name": "N\u00e9v",
    "leasing.contact.office-phone": "Irodai telefon",
    "leasing.contact.postalAddress": "Post\u00e1z\u00e1si c\u00edm",
    "leasing.contact.select_country": "Orsz\u00e1g kiv\u00e1laszt\u00e1sa",
    "leasing.contact.title": "Titulus",
    "leasing.contacts.filters": "Sz\u0171r\u0151k",
    "leasing.counter-offer": "Ellenaj\u00e1nlat",
    "leasing.country": "Orsz\u00e1g",
    "leasing.economic-proposal-sent": "Gazdas\u00e1gi javaslat elk\u00fcldve",
    "leasing.electronics": "Elektronika",
    "leasing.emea-report": "EMEA riport export\u00e1l\u00e1sa",
    "leasing.fast-food": "Gyors\u00e9tterem",
    "leasing.first-contact": "Els\u0151 kapcsolat",
    "leasing.food": "\u00c9tel",
    "leasing.furniture": "B\u00fator",
    "leasing.hairdressers-and-beauty-services": "Fodr\u00e1szat \u00e9s kozmetika",
    "leasing.handbags": "K\u00e9zit\u00e1ska",
    "leasing.home": "F\u0151oldal",
    "leasing.home-apparel": "Lakberendez\u00e9s, Ruh\u00e1zati cikkek",
    "leasing.initial-proposal": "Kezdeti javaslat",
    "leasing.jewelry-watches": "\u00c9kszer, \u00f3ra",
    "leasing.last-offer": "Utols\u00f3 aj\u00e1nlat",
    "leasing.lingerie": "Feh\u00e9rnem\u0171",
    "leasing.mens-apparel": "F\u00e9rfi ruh\u00e1zat",
    "leasing.new-lease": "\u00daj b\u00e9rbead\u00e1s",
    "leasing.operation": "M\u0171k\u00f6d\u00e9s",
    "leasing.operation.approval-date": "J\u00f3v\u00e1hagy\u00e1s d\u00e1tuma",
    "leasing.operation.approved": "J\u00f3v\u00e1hagyva",
    "leasing.operation.brochureSent": "Prospektus elk\u00fcldve?",
    "leasing.operation.comments": "Kommentek",
    "leasing.operation.current-passing-rent": "Jelenlegi\/\u00e1tmeneti b\u00e9rl\u00e9s",
    "leasing.operation.fee": "D\u00edj",
    "leasing.operation.first-contact-date": "Els\u0151 kapcsolatfelv\u00e9tel d\u00e1tuma",
    "leasing.operation.general-info": "\u00c1ltal\u00e1nos inform\u00e1ci\u00f3",
    "leasing.operation.inspectionDone": "Vizsg\u00e1lat elv\u00e9gezve",
    "leasing.operation.landlord-comments": "B\u00e9rbead\u00f3 kommentjei",
    "leasing.operation.last-contact-date": "Utols\u00f3 kapcsolatfelv\u00e9tel d\u00e1tuma",
    "leasing.operation.leasing-brand": "M\u00e1rka",
    "leasing.operation.leasing-center": "K\u00f6zpont",
    "leasing.operation.mutualBreak": "Felmond\u00e1s k\u00f6z\u00f6s megegyez\u00e9ssel",
    "leasing.operation.leasing-unit": "Egys\u00e9g",
    "leasing.operation.negotiationType": "T\u00e1rgyal\u00e1s t\u00edpusa",
    "leasing.operation.netRent": "Nett\u00f3 b\u00e9rleti d\u00edj",
    "leasing.operation.occupancy-cost": "Birtokbav\u00e9tel k\u00f6lts\u00e9ge (utols\u00f3 12 h)",
    "leasing.operation.portfolio-average-area": "Portfolio \u00e1tlagos ter\u00fclete",
    "leasing.operation.portfolio-sales-density": "Portf\u00f3li\u00f3 elad\u00e1si s\u0171r\u0171s\u00e9ge",
    "leasing.operation.precedentConditionClause": "Felf\u00fcggeszt\u0151 felt\u00e9telek",
    "leasing.operation.rejection-reason": "Elutas\u00edt\u00e1s indokl\u00e1sa",
    "leasing.operation.rent": "B\u00e9rl\u00e9s",
    "leasing.operation.rent-storage-room": "T\u00e1rol\u00f3 b\u00e9rl\u00e9s",
    "leasing.operation.rent-terrace": "Terasz b\u00e9rl\u00e9s",
    "leasing.operation.rentFreePeriodOnlyRent": "Rent free id\u0151szak (csak b\u00e9rleti d\u00edj)",
    "leasing.operation.rentFreePeriodRentSch": "Rent free id\u0151szak (B\u00e9rleti d\u00edj + k\u00f6z\u00f6s k\u00f6lts\u00e9g)",
    "leasing.operation.rentFreePeriodStart": "Rent free id\u0151szak kezdete",
    "leasing.operation.rentReduction": "B\u00e9rleti d\u00edj kedvezvezm\u00e9ny",
    "leasing.operation.rentReductionYear1": "Els\u0151 \u00e9v",
    "leasing.operation.rentReductionYear2": "M\u00e1sodik \u00e9v",
    "leasing.operation.rentReductionYear3": "Harmadik \u00e9v",
    "leasing.operation.rentReductionYear4": "Negyedik \u00e9v",
    "leasing.operation.rentReview": "B\u00e9rleti d\u00edj fel\u00fclvizsg\u00e1lat: (IPC+...)",
    "leasing.operation.reporting-status": "Riport\u00e1l\u00e1s \u00e1llapota",
    "leasing.operation.sales-density": "\u00c9rt\u00e9kes\u00edt\u00e9s s\u0171r\u0171s\u00e9ge (utols\u00f3 12h)",
    "leasing.operation.schCap": "K\u00f6z\u00f6s k\u00f6lts\u00e9g maximuma",
    "leasing.operation.select-leasing-brand": "V\u00e1lasszon ki egy m\u00e1rk\u00e1t",
    "leasing.operation.select-leasing-center": "K\u00f6zpont kiv\u00e1laszt\u00e1sa",
    "leasing.operation.select-leasing-unit": "Egys\u00e9g kiv\u00e1laszt\u00e1sa",
    "leasing.operation.select-negotiationType": "V\u00e1lassza ki a t\u00e1rgyal\u00e1s t\u00edpus\u00e1t",
    "leasing.operation.select-rejection-reason": "V\u00e1lassza ki az elutas\u00edt\u00e1s ok\u00e1t",
    "leasing.operation.select-rentFreePeriodStart": "V\u00e1lassza ki az id\u0151szakot",
    "leasing.operation.select-reporting-status": "V\u00e1lassza ki a riport\u00e1l\u00e1s \u00e1llapot\u00e1t",
    "leasing.operation.select-staff-member": "V\u00e1lassza ki a b\u00e9rbead\u00f3 \u00fcgyn\u00f6k\u00f6t",
    "leasing.operation.select-status": "V\u00e1lassza ki az \u00e1llapotot",
    "leasing.operation.sendLandlord": "K\u00fcldje el a b\u00e9rbe ad\u00f3nak?",
    "leasing.operation.shoppingCenterVisited": "Megl\u00e1togatott bev\u00e1s\u00e1rl\u00f3k\u00f6zpont",
    "leasing.operation.signing-date": "Al\u00e1\u00edr\u00e1s d\u00e1tuma",
    "leasing.operation.staff-member": "B\u00e9rbead\u00f3 \u00fcgyn\u00f6k",
    "leasing.operation.status": "\u00c1llapot",
    "leasing.operation.tenant-occupancy-cost": "B\u00e9rl\u0151i b\u00e9rleti d\u00edj",
    "leasing.operation.tenant-performance": "B\u00e9rl\u0151i teljes\u00edtm\u00e9ny",
    "leasing.operation.tennant-annual-sales-density": "A b\u00e9rl\u0151 elad\u00e1si s\u0171r\u0171s\u00e9ge",
    "leasing.operation.term": "Id\u0151szak",
    "leasing.operation.turn-over-rent": "B\u00e9rleti jog \u00e1tad\u00e1sa",
    "leasing.operation.unit-size-proportion": "Egys\u00e9gm\u00e9ret ar\u00e1nya",
    "leasing.operation.unitDelivered": "Egys\u00e9g \u00e1tadva",
    "leasing.operation.units": "Egys\u00e9gek",
    "leasing.operations.create-work": "Munk\u00e1lat l\u00e9trehoz\u00e1sa",
    "leasing.operations.error-create-work": "Probl\u00e9ma ad\u00f3dott az \u00faj munk\u00e1lat l\u00e9trehoz\u00e1s\u00e1n\u00e1l. K\u00e9rem l\u00e9pjen kapcsolatba a fejleszt\u0151vel a probl\u00e9ma megold\u00e1sa \u00e9rdek\u00e9ben",
    "leasing.operations.error-generate-contract": "Probl\u00e9ma ad\u00f3dott az \u00faj munk\u00e1lat l\u00e9trehoz\u00e1s\u00e1n\u00e1l. K\u00e9rem l\u00e9pjen kapcsolatba a fejleszt\u0151vel a probl\u00e9ma megold\u00e1sa \u00e9rdek\u00e9ben",
    "leasing.operations.generate-contract": "Szerz\u0151d\u00e9s l\u00e9trehoz\u00e1sa",
    "leasing.operations.warning-create-work": "ECOP munk\u00e1latot fog l\u00e9trehozni a m\u0171velet ment\u00e9se el\u0151tt. Val\u00f3ban folytatni szeren\u00e9?",
    "leasing.operations.warning-generate-contract": "A m\u0171velete ment\u00e9sre ker\u00fcl az \u00faj szerz\u0151d\u00e9se l\u00e9trehoz\u00e1sa el\u0151tt. Biztosan folytatni szeretn\u00e9?",
    "leasing.opticians-farmacy": "Optikus, Gy\u00f3gyszert\u00e1r",
    "leasing.other-leisure": "Egy\u00e9b szabadid\u0151",
    "leasing.other-services": "Egy\u00e9b szolg\u00e1ltat\u00e1sok",
    "leasing.outlet": "Outlet",
    "leasing.owner.vat-number": "Szem\u00e9lyi Igazolv\u00e1ny Sz\u00e1m",
    "leasing.pending-approval": "F\u00fcgg\u0151ben lev\u0151 enged\u00e9ly",
    "leasing.perfumery-beauty-store": "Parf\u00fcm\u00e9ria, Sz\u00e9ps\u00e9g\u00e1pol\u00e1s",
    "leasing.pets": "Kis\u00e1llatok",
    "leasing.phone-photo-games": "Telefonos fot\u00f3 j\u00e1t\u00e9kok",
    "leasing.rejected": "Elutas\u00edtva",
    "leasing.renewal": "Meg\u00fajul\u00f3",
    "leasing.restaurants": "\u00c9ttermek",
    "leasing.shoes": "Cip\u0151k",
    "leasing.signed": "Al\u00e1\u00edrva",
    "leasing.special-food": "K\u00fcl\u00f6nleges \u00e9tel",
    "leasing.speciality-gift": "K\u00fcl\u00f6nleges aj\u00e1nd\u00e9k",
    "leasing.sports": "Sportok",
    "leasing.storage-room": "T\u00e1rol\u00f3",
    "leasing.supermarket": "Szupermarket",
    "leasing.terrace": "Terasz",
    "leasing.toys": "J\u00e1t\u00e9kok",
    "leasing.travels": "Utaz\u00e1sok",
    "leasing.under-signing-process": "Al\u00e1\u00edr\u00e1s alatt",
    "leasing.unit": "Egys\u00e9g",
    "leasing.unit.area": "Ter\u00fclet",
    "leasing.unit.area-storage-room": "T\u00e1rol\u00f3 ter\u00fclete",
    "leasing.unit.area-terrace": "Terasz ter\u00fclete",
    "leasing.unit.category": "Kateg\u00f3ria",
    "leasing.unit.center": "K\u00f6zpont",
    "leasing.unit.code": "K\u00f3d",
    "leasing.unit.country": "Orsz\u00e1g",
    "leasing.unit.erv": "ERV",
    "leasing.unit.filters": "Sz\u0171r\u0151k",
    "leasing.unit.leasing-center": "L\u00edzing k\u00f6zpont",
    "leasing.unit.real-state-tax": "Ingatlanad\u00f3",
    "leasing.unit.select_leasing_center": "L\u00edzing k\u00f6zpont kiv\u00e1laszt\u00e1sa",
    "leasing.unit.service-charge": "K\u00f6z\u00f6s k\u00f6lts\u00e9g",
    "leasing.unit.type": "T\u00edpus",
    "leasing.womens-apparel": "N\u0151i ruh\u00e1zat",
    "leasing.womens-mens-apparel": "N\u0151i- \u00e9s f\u00e9rfi ruh\u00e1zat",
    "leasing.young-apparel": "Gyermek ruh\u00e1zat",
    "legal": "Jogi",
    "legal-deposit": "K\u00f6telesp\u00e9ld\u00e1ny",
    "legal-person": "Jogi szem\u00e9ly",
    "letting-agreement-date": "B\u00e9rbead\u00e1si szerz\u0151d\u00e9s d\u00e1tuma",
    "letting-agreement-signing-date": "B\u00e9rbead\u00e1si szerz\u0151d\u00e9s al\u00e1\u00edr\u00e1s\u00e1nak d\u00e1tuma",
    "letting-data": "B\u00e9rbead\u00e1si adatok",
    "level": "Szint",
    "level-required": "Szint megad\u00e1sa k\u00f6telez\u0151",
    "lineitem": "Sor",
    "lineitem.edit": "Sor szerkeszt\u00e9se",
    "lineitem.new": "\u00daj sor",
    "location": "Elhelyezked\u00e9s",
    "lock": "T\u00f6mb",
    "logistics-warehouse": "Logisztikai rakt\u00e1r",
    "logo": "Log\u00f3",
    "loyalty-card": "H\u0171s\u00e9gk\u00e1rtya",
    "m2": "m2",
    "mail-notifications": "Elektronikus kereskedelmi kommunik\u00e1ci\u00f3",
    "mail_notifications": "Elektronikus kereskedelmi kommunik\u00e1ci\u00f3",
    "mail_partners_notifications": "Egy harmadik f\u00e9lt\u0151l sz\u00e1rmaz\u00f3 elektronikus kereskedelmi kommunik\u00e1ci\u00f3",
    "maintenance-contracts": "Karbantart\u00f3i szerz\u0151d\u00e9sek",
    "mall": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpont",
    "man": "F\u00e9rfi",
    "managed-states": "V\u00e9grehajtott \u00e1llapot",
    "management": "Vezet\u0151s\u00e9g",
    "manager": "Vezet\u0151",
    "mar": "M\u00e1r",
    "march": "M\u00e1rcius",
    "march-short": "M\u00e1r",
    "marketing": "Marketing",
    "marketing-analytics.campaign.available-crm": "CRM-en el\u00e9rhet\u0151",
    "marketing-analytics.campaign.max-capacity": "Maximum kapacit\u00e1s",
    "marketing-analytics.campaign.public": "Nyilv\u00e1nos kamp\u00e1ny",
    "marketing-analytics.campaigns.kpi": "KPI",
    "marketing-analytics.kpi.active-users": "DMI - Akt\u00edv felhaszn\u00e1l\u00f3k",
    "marketing-analytics.kpi.average-price-receipt": "Esem\u00e9ny - \u00c1tlagos blokk \u00f6sszege",
    "marketing-analytics.kpi.average_price_receipt.tooltip": "A kamp\u00e1ny alatt leadott blokkok \u00e1ltagos \u00f6sszege versenyz\u0151re vet\u00edtve",
    "marketing-analytics.kpi.contestants": "Esem\u00e9ny - Versenyz\u0151k",
    "marketing-analytics.kpi.contestants.tooltip": "A versenyz\u0151k sz\u00e1ma az esem\u00e9nyen",
    "marketing-analytics.kpi.coupon-percent": "DMI - Kupon (%)",
    "marketing-analytics.kpi.impressions-number": "DMI - Megjelen\u00edt\u00e9s",
    "marketing-analytics.kpi.coupon-percent.tooltip": "A felaj\u00e1nlott kuponok felhaszn\u00e1l\u00e1si ar\u00e1nya",
    "marketing-analytics.kpi.new-registered-users": "Esem\u00e9ny- \u00dajonnan regisztr\u00e1lt felhaszn\u00e1l\u00f3k",
    "marketing-analytics.kpi.new-registered-users-percent": "DMI - \u00dajonnan Regisztr\u00e1lt Felhaszn\u00e1l\u00f3k",
    "marketing-analytics.kpi.new_registered_users.tooltip": "\u00dajonnan regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "marketing-analytics.kpi.percent-new-registered-users": "Esem\u00e9ny - \u00dajonnan regisztr\u00e1lt felhaszn\u00e1l\u00f3k (%)",
    "marketing-analytics.kpi.percent-new-registered-users.tooltip": "\u00dajonnan regisztr\u00e1lt felhaszn\u00e1l\u00f3k ar\u00e1nya a kor\u00e1bban regisztr\u00e1lt felhaszn\u00e1l\u00f3khoz k\u00e9pest",
    "marketing-analytics.kpi.previous-registered-users": "Esem\u00e9ny - Kor\u00e1bban regisztr\u00e1lt felhaszn\u00e1l\u00f3k",
    "marketing-analytics.kpi.receipt-average-price": "Esem\u00e9ny - \u00c1tlagos blokk \u00f6sszege",
    "marketing-analytics.kpi.tooltip.active-users": "A mobilon bejelentkezett akt\u00edv felhaszn\u00e1l\u00f3k sz\u00e1ma a kamp\u00e1ny id\u0151szak alatt",
    "marketing-analytics.kpi.tooltip.contestants": "Esem\u00e9ny - Versenyz\u0151k",
    "marketing-analytics.kpi.tooltip.coupon-percent": "DMI- Kuponok (%)",
    "marketing-analytics.kpi.tooltip.eceipt-average-price": "Az  egy versenyz\u0151re vet\u00edttet blokkok \u00e1tlagos \u00e9rt\u00e9ke a kamp\u00e1ny alatt",
    "marketing-analytics.kpi.tooltip.impressions-number": "Megjelen\u00edt\u00e9sek sz\u00e1ma a mobil alkalmaz\u00e1son kereszt\u00fcl a kamp\u00e1ny id\u0151szak alatt",
    "marketing-analytics.kpi.tooltip.new-registered-users": "A kamp\u00e1ny alatt \u00fajonnan regisztr\u00e1l\u00f3k sz\u00e1ma",
    "marketing-analytics.kpi.tooltip.new-registered-users-percent": "A kamp\u00e1ny alatt \u00fajonnan regisztr\u00e1l\u00f3k sz\u00e1ma a kor\u00e1bban regisztr\u00e1lt felhaszn\u00e1l\u00f3kkal \u00f6sszevetve",
    "marketing-analytics.kpi.tooltip.previous-registered-users": "A kamp\u00e1ny sor\u00e1n akt\u00edv kor\u00e1bbi regisztr\u00e1l\u00f3k sz\u00e1ma",
    "marketing-analytics.kpi.tooltip.total-coupons": "Bev\u00e1ltott kuponok sz\u00e1ma",
    "marketing-analytics.kpi.tooltip.total-receipts": "A regisztr\u00e1lt blokkok sz\u00e1ma \u00e9s \u00f6sszege",
    "marketing-analytics.kpi.tooltip.visits": "A mobil alkalmaz\u00e1ssal t\u00f6rt\u00e9nt l\u00e1togat\u00e1sok sz\u00e1ma",
    "marketing-analytics.kpi.previous_registered_users.tooltip": "A m\u00e1r kor\u00e1bban regisztr\u00e1lt felhaszn\u00e1l\u00f3k sz\u00e1ma",
    "marketing-analytics.kpi.total-coupons": "DMI - Kupon haszn\u00e1lat",
    "marketing-analytics.kpi.total-receipts": "DMI - Blokkok",
    "marketing-analytics.kpi.visits": "DMI - L\u00e1togat\u00e1sok",
    "marketing-analytics.kpi.total_coupons.tooltip": "Bev\u00e1ltott kuponok sz\u00e1ma",
    "marketing-analytics.kpi.total_receipts.tooltip": "Regisztr\u00e1lt nyugt\u00e1k sz\u00e1ma",
    "marketing-dossier": "Marketing Mappa",
    "masonry": "K\u0151m\u0171vesmunka",
    "match-pass": "A jelsz\u00f3 mez\u0151k nem egyeznek",
    "materials-sample-approval-date": "Anyagmint\u00e1k j\u00f3v\u00e1hagy\u00e1si d\u00e1tuma",
    "materials-sample-delivery-date": "Anyagmint\u00e1k k\u00e9zbes\u00edt\u00e9si d\u00e1tuma",
    "max": "Max",
    "max-image-size-1440-800": "A fejl\u00e9c javasolt m\u00e9rete 1440 x 800px",
    "max-image-size-300-232": "A n\u00e9z\u0151k\u00e9p javasolt m\u00e9rete 300 x 232px",
    "may": "M\u00e1jus",
    "may-short": "M\u00e1j",
    "married": "H\u00e1zas",
    "hour": "\u00d3ra",
    "inbox": "Be\u00e9rkez\u0151",
    "management.files": "F\u00e1jlkezel\u00e9s",
    "menu.navbar.analytics": "Elemz\u00e9s",
    "menu.navbar.bi": "Marketingelemz\u00e9s",
    "menu.navbar.bi.campaign.index": "Kamp\u00e1nyok",
    "menu.navbar.bi.lineitem.index": "Sor elemek",
    "menu.navbar.bi.service.index": "Szolg\u00e1ltat\u00e1sok",
    "menu.navbar.bi.supplier.index": "Besz\u00e1ll\u00edt\u00f3k",
    "menu.navbar.category.new": "\u00daj kateg\u00f3ria",
    "menu.navbar.category.show": "Kateg\u00f3ri\u00e1k",
    "menu.navbar.center.new": "\u00daj k\u00f6zpont",
    "menu.navbar.channel.show": "Csatorn\u00e1k",
    "menu.navbar.cms": "CMS",
    "menu.navbar.companies.show": "V\u00e1llalatok",
    "menu.navbar.configuration": "Konfigur\u00e1ci\u00f3",
    "menu.navbar.configuration.catalog": "Katal\u00f3gus",
    "menu.navbar.configuration.center": "A bev\u00e1s\u00e1rl\u00f3k\u00f6zponttal kapcsolatos inform\u00e1ci\u00f3k",
    "menu.navbar.configuration.dashboards": "Dashboards",
    "menu.navbar.configuration.extra": "Extra attrib\u00fatum",
    "menu.navbar.configuration.influencezone": "Befoly\u00e1s z\u00f3n\u00e1k",
    "menu.navbar.configuration.module": "Modulok",
    "menu.navbar.configuration.password": "Jelsz\u00f3 v\u00e1ltoztat\u00e1s",
    "menu.navbar.configuration.shop.import": "\u00dczletek behozatala",
    "menu.navbar.configuration.zone": "Z\u00f3n\u00e1k",
    "menu.navbar.consumption.show": "Fogyaszt\u00e1s",
    "menu.navbar.coupon.report": "Kuponok",
    "menu.navbar.crm": "CRM",
    "menu.navbar.customer.import": "V\u00e1s\u00e1rl\u00f3k import\u00e1l\u00e1sa",
    "menu.navbar.customer.new": "\u00daj v\u00e1s\u00e1rl\u00f3",
    "menu.navbar.customer.show": "V\u00e1s\u00e1rl\u00f3 lista",
    "menu.navbar.customertype.show": "V\u00e1s\u00e1rl\u00f3i t\u00edpusok",
    "menu.navbar.desktop": "Asztal",
    "menu.navbar.ecop": "ECOP",
    "menu.navbar.dashboard": "Dashboard",
    "menu.navbar.email-template.show": "E-mail sablonok",
    "menu.navbar.emailing": "E-mailez\u00e9s",
    "menu.navbar.emailing.campaign.index": "E-mail kamp\u00e1nyok",
    "menu.navbar.intranet": "Intranet",
    "menu.navbar.intranet.communication.index": "Kommunik\u00e1ci\u00f3k",
    "menu.navbar.intranet.file.index": "F\u00e1jlok",
    "menu.navbar.intranet.guard.index": "\u0150r\u00f6k",
    "menu.navbar.intranet.sale.index": "\u00c9rt\u00e9kes\u00edt\u00e9s",
    "menu.navbar.intranet.subtype.index": "Kommunik\u00e1ci\u00f3 alfajt\u00e1i",
    "menu.navbar.kpi-tech": "KPI Tech",
    "menu.navbar.kpi-tech.centers": "K\u00f6zpontok",
    "menu.navbar.leasing-ecop": "B\u00e9rbead\u00e1s \/ ECOP",
    "menu.navbar.leasing": "B\u00e9rbead\u00e1s",
    "menu.navbar.leasing.brands": "M\u00e1rk\u00e1k",
    "menu.navbar.leasing.centers": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpontok",
    "menu.navbar.leasing.contacts": "Kapcsolatok",
    "menu.navbar.leasing.operations": "M\u0171veletek",
    "menu.navbar.leasing.owners": "Tulajdonosok",
    "menu.navbar.leasing.units": "Egys\u00e9gek",
    "menu.navbar.leasing.works": "Munk\u00e1latok",
    "menu.navbar.logout": "Kijelentkez\u00e9s",
    "menu.navbar.marketing-analytics": "Marketing Analitika",
    "menu.navbar.marketing-analytics.campaign.index": "Kamp\u00e1nyok",
    "menu.navbar.marketing-analytics.campaigntype.index": "Kamp\u00e1ny t\u00edpusok",
    "menu.navbar.marketing-analytics.lineitem.index": "Sor elemek",
    "menu.navbar.marketing-analytics.service.index": "Szolg\u00e1ltat\u00e1sok",
    "menu.navbar.marketing-analytics.supplier-contacts.index": "Besz\u00e1ll\u00edt\u00f3i kapcsolatok",
    "menu.navbar.marketing-analytics.supplier.index": "Besz\u00e1ll\u00edt\u00f3k",
    "menu.navbar.message.new": "\u00daj \u00fczenet",
    "menu.navbar.message.show": "\u00dczenet lista",
    "menu.navbar.playcenter.show": "J\u00e1tsz\u00f3h\u00e1z",
    "menu.navbar.proposal.new": "\u00daj javaslat",
    "menu.navbar.proposal.show.active": "Akt\u00edv javaslatok",
    "menu.navbar.proposal.show.draft": "Javaslatok v\u00e1zlatai",
    "menu.navbar.proposal.show.expired": "Lej\u00e1rt javaslatok",
    "menu.navbar.proposal.show.pending": "F\u00fcgg\u0151ben lev\u0151 javaslatok",
    "menu.navbar.proposal.show.scheduled": "Tervezett javaslatok",
    "menu.navbar.recommendation.show": "Javaslatok",
    "menu.navbar.residential": "Lak\u00f3ingatlan",
    "menu.navbar.residential.campaigns.index": "Kamp\u00e1nyok",
    "menu.navbar.residential.contacts": "Kapcsolatok",
    "menu.navbar.residential.developments.index": "Fejleszt\u00e9sek",
    "menu.navbar.residential.owners.index": "Tulajdonosok",
    "menu.navbar.residential.properties.index": "Ingatlanok",
    "menu.navbar.shop.new": "\u00daj \u00fczlet",
    "menu.navbar.shop.show": "\u00dczletek list\u00e1ja",
    "menu.navbar.staff.new": "\u00daj felhaszn\u00e1l\u00f3",
    "menu.navbar.staff.show": "Felhaszn\u00e1l\u00f3k list\u00e1ja",
    "menu.navbar.traffic.blacklist": "Felhaszn\u00e1l\u00f3k k\u00f6vet\u00e9s n\u00e9lk\u00fcl",
    "menu.navbar.traffic.show": "Forgalom",
    "min": "Min",
    "monday": "H\u00e9tf\u0151",
    "monday-short": "H",
    "month": "H\u00f3nap",
    "month.state": "H\u00f3nap \u00e1llapota",
    "monthly-report": "Havi riport",
    "months": "H\u00f3napok",
    "mstatus": "Csal\u00e1di \u00e1llapot",
    "multi_family": "T\u00f6bbcsal\u00e1dos ",
    "multimedia": "Multim\u00e9di\u00e1s",
    "multimedia-files": "Multim\u00e9dia f\u00e1jlok",
    "minute": "Perc",
    "name": "N\u00e9v",
    "name-required": "A n\u00e9v megad\u00e1sa k\u00f6telez\u0151",
    "name_required": "A n\u00e9v nem maradhat \u00fcresen",
    "nationality": "Nemzetis\u00e9g",
    "nationality_id": "Nemzetis\u00e9g",
    "nephew": "Unoka\u00f6ccs",
    "new-company": "\u00daj v\u00e1llalat",
    "new-lease-form": "B\u00e9rleti formanyomtatv\u00e1ny",
    "new-staff": "\u00daj munkat\u00e1rsak",
    "new_folder": "\u00daj mappa",
    "new_name": "\u00daj n\u00e9v",
    "news": "H\u00edrek",
    "next_month": "K\u00f6vetkez\u0151 h\u00f3nap",
    "nif": "NIF",
    "no": "Nem",
    "no-data": "A \u00d6n \u00e1ltal kiv\u00e1lasztott sz\u0171r\u0151k alapj\u00e1n nincs el\u00e9rhet\u0151 adat.",
    "north": "\u00c9szak",
    "not-found": "Nem tal\u00e1lhat\u00f3",
    "not_between": "nincs k\u00f6z\u00f6tte",
    "not_contains": "nem tartalmazza",
    "nov": "Nov",
    "november": "November",
    "november-short": "Nov",
    "notes": "Le\u00edr\u00e1s",
    "occupation-percentage": "Foglalts\u00e1gi ar\u00e1ny",
    "oct": "Oct",
    "october": "Okt\u00f3ber",
    "october-short": "Okt",
    "office": "Iroda",
    "on-signing-date": "Az al\u00e1\u00edr\u00e1s napj\u00e1n",
    "ongoing": "Folyamatban lev\u0151",
    "not_result_found": "Nincs tal\u00e1lat",
    "open": "Nyitva",
    "opening": "Nyit\u00e1s",
    "opening-guarantee": "Nyit\u00e1si garancia",
    "operation-analysis": "M\u0171k\u00f6d\u00e9si elemz\u00e9s",
    "operation-analysis.warning": "A m\u0171k\u00f6d\u00e9si elemz\u00e9s mez\u0151i a Gazdas\u00e1gi javaslat elk\u00fcld\u00f6tt st\u00e1tusz\u00e1b\u00f3l lesznek mutatva",
    "optimal-area": "Optim\u00e1lis ter\u00fclet",
    "open.new-tab.message": "Megnyit\u00e1s \u00faj lapon",
    "options_not_found": "Nem tal\u00e1lhat\u00f3 opci\u00f3",
    "or_less": "vagy kevesebb",
    "or_more": "vagy t\u00f6bb",
    "original": "Eredeti",
    "other": "Egy\u00e9b",
    "other-data": "Egy\u00e9b adatok",
    "other-documentation": "Egy\u00e9b dokumentum",
    "others": "Egy\u00e9b",
    "outlet": "Outlet",
    "overview": "\u00c1ttekint\u00e9s",
    "owner": "Tulajdonos",
    "owner-code": "Tulajdonosi k\u00f3d",
    "owner-find": "Tal\u00e1lja meg a tulajdonost",
    "pager.out_of": "elfogyott",
    "pass-required": "Adjon me 10 vagy t\u00f6bb karaktert, ami tartalmaz legal\u00e1bb 1 sz\u00e1mott, 1 nagy bet\u0171t \u00e9s 1 kisbet\u0171t",
    "passport": "\u00datlev\u00e9l",
    "password": "Jelsz\u00f3",
    "password-format": "K\u00e9rem hozzon l\u00e9tre egy er\u0151s jelsz\u00f3t, amely legal\u00e1bb 10 karakterb\u0151l \u00e1ll. Tartalmazzon 1 nagy bet\u0171t, 1 kis bet\u0171t \u00e9s egy sz\u00e1mot.",
    "payment-plan": "Fizet\u00e9si terv",
    "payment_plan": "Fizet\u00e9si terv",
    "payslip": "Fizet\u00e9si jegyz\u00e9k",
    "pdf": "PDF",
    "pending": "F\u00fcgg\u0151ben lev\u0151",
    "person-type": "Term\u00e9szetes vagy jogi szem\u00e9ly",
    "person_type": "Term\u00e9szetes vagy jogi szem\u00e9ly",
    "phone": "Telefon",
    "physical-person": "Term\u00e9szetes szem\u00e9ly",
    "physical_person": "Term\u00e9szetes szem\u00e9ly",
    "play-center-stays": "J\u00e1tsz\u00f3h\u00e1z",
    "play-center.time": "Id\u0151",
    "play-center.tutor": "Oktat\u00f3",
    "poor-image-quality": "Gyenge k\u00e9pmin\u0151s\u00e9g",
    "portfolio-manager": "Portfolio manager",
    "position": "Poz\u00edci\u00f3",
    "postal-code": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "powerpoint": "Powerpoint",
    "premises-authorizations": "\u00dczlethelyis\u00e9g enged\u00e9lyek",
    "presentation": "Prezent\u00e1ci\u00f3",
    "presale": "El\u0151v\u00e9tel",
    "previous": "El\u0151z\u0151",
    "previous_month": "El\u0151z\u0151 h\u00f3nap",
    "process": "Folyamat",
    "project-approval": "Projekt enged\u00e9lyez\u00e9se",
    "project-approval-shopfront": "Kirakati projekt j\u00f3v\u00e1hagy\u00e1sa",
    "project-approval-unit": "Egys\u00e9gre vonatkoz\u00f3 projekt j\u00f3v\u00e1hagy\u00e1sa",
    "projects-delivery-unit-and-shopfront": "Projekt \u00e1tad\u00e1s (egys\u00e9g & kirakat)",
    "properties": "Ingatlanok",
    "properties-number": "Ingatlanok sz\u00e1ma",
    "property": "Ingatlan",
    "property-filter.bathrooms_number": "F\u00fcrd\u0151szob\u00e1k",
    "property-filter.bedrooms_number": "H\u00e1l\u00f3szob\u00e1k",
    "property-filter.built_surface": "Built surface",
    "property-filter.city": "V\u00e1ros",
    "property-filter.price": "\u00c1r",
    "property-filter.property_type": "Ingatlan t\u00edpusa",
    "property-filter.province": "Tartom\u00e1ny",
    "property-filter.residential_development": "Fejleszt\u00e9s",
    "property-filter.toilets_number": "Mosd\u00f3k",
    "property-filter.useful_surface": "Hasznos fel\u00fclet",
    "property-types": "t\u00edpusok",
    "property.bathrooms-number": "F\u00fcrd\u0151szob\u00e1k",
    "property.bedrooms-number": "H\u00e1l\u00f3szob\u00e1k",
    "property.block": "T\u00f6mb",
    "property.built-surface": "Built surface",
    "property.buyer": "V\u00e1s\u00e1rl\u00f3",
    "property.buyer-search": "V\u00e1s\u00e1rl\u00f3 keres\u00e9s",
    "property.cadastral-reference": "Kataszteri referenciadatok",
    "property.common-zones-surface": "K\u00f6z\u00f6s z\u00f3n\u00e1k fel\u00fclete",
    "property.contact": "Kapcsolat",
    "property.data": "Ingatlan adatok",
    "property.detailed-spaces": "R\u00e9szletezett ter\u00fcletek",
    "property.doorway": "Kapubej\u00e1rat",
    "property.estate": "Ingatlan",
    "property.floor": "Emelet",
    "property.garages-number": "Gar\u00e1zsok",
    "property.ladder": "L\u00e9tra",
    "property.letter": "Lev\u00e9l",
    "property.name": "Ingatlan elnevez\u00e9se",
    "property.orientation": "Ir\u00e1ny",
    "property.price": "\u00c1r",
    "property.propertyType": "Ingatlan t\u00edpusa",
    "property.reference": "Hivatkoz\u00e1s",
    "property.rental": "B\u00e9rleti d\u00edj",
    "property.reserved-date": "Foglalt d\u00e1tum",
    "property.room.name": "Szoba elnevez\u00e9se",
    "property.room.select_type": "Szoba t\u00edpus\u00e1nak kiv\u00e1laszt\u00e1sa",
    "property.room.surface": "Fel\u00fclet",
    "property.room.type": "T\u00edpus",
    "property.select_orientation": "Kiv\u00e1laszt...",
    "property.select_propertyState": "Ingatlan \u00e1llapot\u00e1nak kiv\u00e1laszt\u00e1sa",
    "property.select_propertyType": "Ingatlan t\u00edpus\u00e1nak kiv\u00e1laszt\u00e1sa",
    "property.select_residential-development": "Fejleszt\u00e9s kiv\u00e1laszt\u00e1sa",
    "property.state": "\u00c1llapot kiv\u00e1laszt\u00e1sa",
    "property.storage-rooms-number": "T\u00e1rol\u00f3k",
    "property.technical-data": "Technikai adatok",
    "property.terrace": "Terasz \/ Kert",
    "property.toilets-number": "Mosd\u00f3k",
    "property.useful-surface": "Hasznos fel\u00fclet",
    "property.user": "Felhaszn\u00e1l\u00f3",
    "province": "Tartom\u00e1ny",
    "province_id": "Tartom\u00e1ny",
    "publish": "K\u00f6zz\u00e9t\u00e9tel",
    "published": "K\u00f6zz\u00e9t\u00e9ve",
    "purchase-agreement": "V\u00e1s\u00e1rl\u00e1si meg\u00e1llapod\u00e1s",
    "purchaser-type": "V\u00e1s\u00e1rl\u00e1s t\u00edpusa",
    "quality-specifications": "Min\u0151s\u00e9gi specifik\u00e1ci\u00f3k",
    "real-date": "Val\u00f3s d\u00e1tum",
    "real-shopfront-date": "Kirakat val\u00f3di d\u00e1tuma",
    "real-unit-date": "Egys\u00e9g val\u00f3di d\u00e1tuma",
    "receipt": "Nyugta",
    "receipt.campaigns": "Kamp\u00e1nyok",
    "receipt.create-receipt": "Nyugta l\u00e9trehoz\u00e1sa",
    "receipt.receipts": "Nyugt\u00e1k",
    "receipt.save-message": "A nyugta mentve lett",
    "receipt.select-campaigns": "Kamp\u00e1ny kiv\u00e1laszt\u00e1sa",
    "receipt.tickets": "Jegyek",
    "receipts": "Nyugt\u00e1k",
    "receipts.generate-tickets": "Jegyek l\u00e9trehoz\u00e1sa",
    "reference": "Hivatkoz\u00e1s",
    "regional": "Region\u00e1lis",
    "register-date": "Regisztr\u00e1ci\u00f3 d\u00e1tuma",
    "purchaseAgreement": "V\u00e1s\u00e1rl\u00e1si meg\u00e1llapod\u00e1s",
    "quality_specifications": "Min\u0151s\u00e9gi specifik\u00e1ci\u00f3k",
    "reject": "Elutas\u00edt",
    "reject-close-month-modal.specify-reason": "Hat\u00e1rozza meg a bolt sz\u00e1m\u00e1ra az okot",
    "reject-close-month-modal.title": "H\u00f3napz\u00e1r\u00e1s elutas\u00edt\u00e1sa",
    "relocation": "\u00c1thelyez\u00e9s",
    "remove": "T\u00f6rl\u00e9s",
    "rent": "B\u00e9rl\u00e9s",
    "rental": "B\u00e9rlet",
    "renting-agreement-date": "B\u00e9rl\u00e9si d\u00e1tum",
    "required": "{{attr}} k\u00f6telez\u0151 megadnia",
    "resend": "\u00dajra k\u00fcld\u00e9s",
    "reset-password": "Jelsz\u00f3 helyre\u00e1ll\u00edt\u00e1sa",
    "residence_card": "Tart\u00f3zkod\u00e1si k\u00e1rtya",
    "residential-development": "Lak\u00f3ingatlan fejleszt\u00e9s",
    "residential-development.development": "Fejleszt\u00e9s",
    "residential-development.not_found": "Lak\u00f3ingatlan fejleszt\u00e9s nem tal\u00e1lhat\u00f3",
    "residential-documentation-sent.deed-warning-text": "Az okirat d\u00e1tum\u00e1nak megad\u00e1sa ut\u00e1n az aj\u00e1nlat lez\u00e1r\u00e1sra ker\u00fcl. A tov\u00e1bbiakban nem lesz lehet\u0151s\u00e9ge a m\u00f3dos\u00edt\u00e1sra",
    "residential-offer-close.rejected-common-zones": "K\u00f6z\u00f6s ter\u00fcletek",
    "residential-offer-close.rejected-delivery-date": "Teljes\u00edt\u00e9s d\u00e1tuma",
    "residential-offer-close.rejected-other": "Egy\u00e9b",
    "residential-offer-close.rejected-other-reason": "Egy\u00e9b okok, amelyek nem tal\u00e1lhat\u00f3ak a list\u00e1n",
    "residential-offer-close.rejected-pay-form": "Fizet\u00e9s form\u00e1ja",
    "residential-offer-close.rejected-price": "\u00c1r",
    "residential-offer-close.rejected-project": "Projekt",
    "residential-offer-close.rejected-reasons": "Az elutas\u00edt\u00e1s okai",
    "residential-offer.accepted": "Elfogadott",
    "residential-offer.closing-notes": "Z\u00e1r\u00f3 jegyzetek",
    "residential-offer.closingDate": "Z\u00e1r\u00e1s d\u00e1tuma",
    "residential-offer.info_sent": "Inform\u00e1ci\u00f3 elk\u00fcldve",
    "residential-offer.notes": "Jegyzetek",
    "residential-offer.open": "Nyitva",
    "residential-offer.openingDate": "Nyit\u00e1s d\u00e1tuma",
    "residential-offer.pending": "F\u00fcgg\u0151ben van",
    "residential-offer.price": "\u00c1r",
    "residential-offer.rejected": "Elutas\u00edtva",
    "residential-offer.select_new_state": "\u00daj \u00e1llapot kiv\u00e1laszt\u00e1sa",
    "residential-offer.sent_info_dossier": "Mappa",
    "residential-offer.sent_info_other": "Egy\u00e9b",
    "residential-offer.sent_info_property_map": "Ingatlan t\u00e9rk\u00e9p",
    "residential-offer.sent_info_qualities_note": "Tulajdons\u00e1g jegyzetek",
    "residential-offer.signed": "Al\u00e1\u00edrva",
    "residential-offers.price": "\u00c1r",
    "residential-owner": "Lak\u00f3ingatlan tulajdonosa",
    "residential-owner.not_found": "Lak\u00f3ingatlan tulajdonosa nem tal\u00e1lhat\u00f3",
    "residential-properties.bedrooms": "H\u00e1l\u00f3szob\u00e1k",
    "residential-properties.location": "Elhelyezked\u00e9s",
    "residential-properties.price": "\u00c1r",
    "residential-properties.property": "Ingatlan",
    "residential-properties.rooms": "Szob\u00e1k",
    "residential-properties.surface": "Fel\u00fclet",
    "residential-properties.type": "T\u00edpus",
    "residential-property.booking-documentation": "K\u00f6nyvel\u00e9si dokument\u00e1ci\u00f3",
    "residential-property.booking-payment": "K\u00f6nyvelt fizet\u00e9s",
    "residential-property.deed": "Okirat",
    "residential-property.edit": "Ingatlan szerkeszt\u00e9se",
    "residential-property.monthly-payment-system": "Havi fizet\u00e9si rendszer",
    "residential-property.new": "\u00daj ingatlan",
    "residential-property.state.blocked": "Blokkolva",
    "residential-property.state.free": "El\u00e9rhet\u0151",
    "residential-property.state.reserved": "Lefoglalva",
    "residential-property.state.sold": "Eladva",
    "residential-property.trading": "Kereskedelem",
    "residential-proposal.close_offer": "Z\u00e1r\u00f3 aj\u00e1nlat",
    "residential-request": "K\u00e9r\u00e9s",
    "residential-room.type.bathroom": "F\u00fcrd\u0151szoba",
    "residential-room.type.bedroom": "H\u00e1l\u00f3szoba",
    "residential-room.type.dressing-room": "\u00d6lt\u00f6z\u0151",
    "residential-room.type.garage": "Gar\u00e1zs",
    "residential-room.type.hall": "El\u0151szoba",
    "residential-room.type.kitchen": "Konyha",
    "residential-room.type.laundry-room": "Mos\u00f3konyha",
    "residential-room.type.living-room": "Nappali",
    "residential-room.type.lobby": "Lobby",
    "residential-room.type.storage": "T\u00e1rol\u00f3",
    "residential-room.type.toilet": "Mosd\u00f3",
    "residential-rooms-required": "K\u00f6telez\u0151 megadnia az \u00f6sszes szoba elnevez\u00e9s\u00e9t, t\u00edpus\u00e1t \u00e9s fel\u00fclet\u00e9t",
    "residential-sale-process.document_sent": "Dokumentum elk\u00fcldve",
    "residential-user-state.accepted": "Elfogadott",
    "residential-user-state.new": "\u00daj",
    "residential-user-state.open": "Nyitva",
    "residential-user-state.rejected": "Elutas\u00edtva",
    "residential-user-state.request": "K\u00e9r\u00e9s",
    "residential-user-state.signed": "Al\u00e1\u00edrva",
    "residential.add_files_button": "F\u00e1jlok hozz\u00e1ad\u00e1sa",
    "residential.add_files_title": "Szeretne hozz\u00e1adni f\u00e1jlokat?",
    "residential.add_link_button": "Link hozz\u00e1ad\u00e1sa",
    "residential.add_video_link": "Vide\u00f3 URL hozz\u00e1ad\u00e1sa",
    "residential.campaign.edit": "Kamp\u00e1ny szerkeszt\u00e9se",
    "residential.campaign.name": "N\u00e9v",
    "residential.campaign.new": "\u00daj kamp\u00e1ny",
    "residential.building_state": "Kivitelez\u00e9s \u00e1llapota",
    "residential.commercialization": "\u00c9rt\u00e9kes\u00edt\u00e9s",
    "residential.community_expenses": "K\u00f6z\u00fczemi k\u00f6lts\u00e9gek",
    "residential.countries": "Orsz\u00e1gok",
    "residential.currency": "Valuta",
    "residential.delivery_date": "K\u00e9zbes\u00edt\u00e9s ideje",
    "residential.developer-contact": "Fejleszt\u0151i kapcsolat",
    "residential.development": "Fejleszt\u00e9s",
    "residential.development.buildingState": "Kivitelez\u00e9s \u00e1llapota",
    "residential.development.location": "Elhelyezked\u00e9s",
    "residential.development.name": "N\u00e9v",
    "residential.development.owner": "Tulajdonos",
    "residential.development.reference": "Fejleszt\u00e9si hivatkoz\u00e1s",
    "residential.development.tu": "T.P.",
    "residential.development.zone": "Ter\u00fclet",
    "residential.development_maps": "Szintfejleszt\u00e9si tervez\/tervezet",
    "residential.developments": "Fejleszt\u00e9sek",
    "residential.discharge_date": "Elbocs\u00e1t\u00e1s ideje",
    "residential.documentation": "Dokument\u00e1ci\u00f3",
    "residential.dossiers": "Marketing Mappa",
    "residential.end_of_construction": "\u00c9p\u00edtkez\u00e9s befejez\u00e9se",
    "residential.energy_certification": "Energia tanus\u00edtv\u00e1ny",
    "residential.energy_certification.a": "A",
    "residential.energy_certification.b": "B",
    "residential.energy_certification.c": "C",
    "residential.energy_certification.d": "D",
    "residential.energy_certification.e": "E",
    "residential.energy_certification.f": "F",
    "residential.energy_certification.g": "G",
    "residential.general_data": "\u00c1ltal\u00e1nos adatok",
    "residential.general_info": "\u00c1ltal\u00e1nos infrom\u00e1ci\u00f3",
    "residential.how_to_arrive_map": "\u00datvonal megtervez\u00e9se",
    "residential.images": "K\u00e9pek",
    "residential.in_construction": "\u00c9p\u00edtkez\u00e9s folyamatban van",
    "residential.location": "Elhelyezked\u00e9s",
    "residential.management": "Vezet\u0151s\u00e9g",
    "residential.maps": "Emeleti tervek",
    "residential.multimedia": "F\u00e1jlok \u00e9s mulitm\u00e9dia",
    "residential.other_data": "Egy\u00e9b adatok",
    "residential.owner": "Tulajdonos",
    "residential.owner.code": "K\u00f3d",
    "residential.owner.edit": "Lak\u00f3ingatlan tulajdonos\u00e1nak szerkeszt\u00e9se",
    "residential.owner.fiscal-name": "C\u00e9g n\u00e9v",
    "residential.owner.logo": "Log\u00f3",
    "residential.owner.name": "N\u00e9v",
    "residential.owner.new": "\u00daj lak\u00f3ingatlan tulajdonos",
    "residential.owner.vat-number": "Szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "residential.owner_code": "Tulajdonosi k\u00f3d",
    "residential.post_sale": "Kor\u00e1bbi \u00e9rt\u00e9kes\u00edt\u00e9s",
    "residential.presale": "El\u0151v\u00e9tel",
    "residential.properties": "Ingatlanok",
    "residential.property": "Ingatlan",
    "residential.property-types": "T\u00edpusok",
    "residential.property.development_documentation": "Fejleszt\u00e9si dokument\u00e1ci\u00f3",
    "residential.property.development_multimedia_files": "Feljeszt\u00e9s Multimedia f\u00e1jlok",
    "residential.property.reserved-date": "Lefoglalt d\u00e1tum",
    "residential.property.reserved-notes": "Jegyzetek",
    "residential.property_maps": "Ingatlan emeleti tervek",
    "residential.proposal.send-info": "Inform\u00e1ci\u00f3 k\u00fcld\u00e9se",
    "residential.proposal.send-info.error-sending-message": "Hiba t\u00f6rt\u00e9nt az inform\u00e1ci\u00f3t tartalmaz\u00f3 e-mail elk\u00fcld\u00e9se k\u00f6zben. K\u00e9rem k\u00e9s\u0151bb pr\u00f3b\u00e1lja meg \u00fajra",
    "residential.proposal.send-info.error-updating-checks-message": "A javaslat helytelen\u00fcl lett felt\u00f6ltve",
    "residential.proposal.send-info.success-message": "Az inform\u00e1ci\u00f3t tartalmaz\u00f3 e-mail sikeresen el lett k\u00fcldve.",
    "residential.province": "Tartom\u00e1ny",
    "residential.provinces": "Tartom\u00e1nyok",
    "residential.qualities": "Tulajdons\u00e1gok",
    "residential.responsible": "Felel\u0151s",
    "residential.rooms": "Szob\u00e1k",
    "residential.sale": "\u00c9rt\u00e9kes\u00edt\u00e9s",
    "residential.select_building_state": "Kivitelez\u00e9s \u00e1llapot\u00e1nak kiv\u00e1laszt\u00e1sa",
    "residential.select_commercialization": "\u00c9rt\u00e9kes\u00edt\u00e9s kiv\u00e1laszt\u00e1sa",
    "residential.select_currency": "Valuta kiv\u00e1laszt\u00e1sa",
    "residential.select_energy_certification": "Energia tanus\u00edtv\u00e1ny kiv\u00e1laszt\u00e1sa",
    "residential.select_management": "Vezet\u0151s\u00e9g kiv\u00e1laszt\u00e1sa",
    "residential.select_owner": "Tulajdonos kiv\u00e1laszt\u00e1sa",
    "residential.select_province": "Tartom\u00e1ny kiv\u00e1laszt\u00e1sa",
    "residential.select_unit_type": "Egys\u00e9g t\u00edpus\u00e1nak kiv\u00e1laszt\u00e1sa",
    "residential.services": "Szolg\u00e1ltat\u00e1sok",
    "residential.services_and_qualities": "Szolg\u00e1ltat\u00e1sok \u00e9s Tulajdons\u00e1gok",
    "residential.start_work": "Munkakezd\u00e9s",
    "residential.technical_data": "Technikai adatok",
    "residential.unit_type": "Egys\u00e9g t\u00edpusa",
    "residential.video": "Vide\u00f3",
    "residential.zone_services": "Ter\u00fcletenk\u00e9nti szolg\u00e1ltat\u00e1sok",
    "residential_offers.closing": "Z\u00e1r\u00f3 aj\u00e1nlat",
    "residential_offers.documentation-procedure": "Dokument\u00e1lt elj\u00e1r\u00e1s",
    "residential_offers.document-sent": "Dokumentum elk\u00fcldve",
    "residential_offers.new": "\u00daj aj\u00e1nlat",
    "residential_property": "Ingatlan",
    "residential_proposal": "Javaslat",
    "response": "V\u00e1lasz",
    "retail-park": "Kiskereskedelmi Park",
    "retail-space": "\u00dczlethelyis\u00e9g",
    "role": "Szerep",
    "sale": "\u00c9rt\u00e9kes\u00edt\u00e9s",
    "sale-process.add-request": "K\u00e9r\u00e9s hozz\u00e1ad\u00e1sa",
    "sale-process.closing": "Z\u00e1r\u00e1s",
    "sale-process.proposal": "Javaslat",
    "sale-process.proposals": "Javaslatok",
    "sale-process.request.date": "D\u00e1tum",
    "sale-process.request.name": "N\u00e9v",
    "sale-process.request.properties-available": "El\u00e9rhet\u0151",
    "sale-process.request.properties-blocked": "Blokkolva",
    "sale-process.requests": "K\u00e9r\u00e9sek",
    "sale.amount": "Mennyis\u00e9g",
    "sale.concept": "Koncepci\u00f3 elad\u00e1s",
    "sale.commerce": "\u00dczlet",
    "sale.date": "D\u00e1tum",
    "sale.edit": "\u00c9rt\u00e9kes\u00edt\u00e9s szerkeszt\u00e9se",
    "sale.name": "N\u00e9v",
    "sale.new": "\u00daj elad\u00e1s",
    "sale.not_found": "Nem tal\u00e1lhat\u00f3 elad\u00e1s",
    "sales": "\u00c9rt\u00e9kes\u00edt\u00e9s",
    "sales.back": "Vissza",
    "sales.csv": "XLSX",
    "sales.csv-error-format": "\u00c9rv\u00e9nytelen a f\u00e1jl form\u00e1tuma. K\u00e9rem n\u00e9zze \u00e1t \u00fajra, hogy k\u00f6vetni tudja az instrukci\u00f3kat.",
    "sales.csv-format": "A CSV-nek ugyanannak a form\u00e1tum\u00e1nak kell lennie mint az export\u00e1lt CSV-nek. A k\u00f6vetkez\u0151 form\u00e1tumot kellene k\u00f6vetnie:",
    "sales.csv-sales-with-errors": "N\u00e9h\u00e1ny elad\u00e1s a CSV-ben nem ker\u00fclt import\u00e1l\u00e1sra. K\u00e9rem n\u00e9zze \u00e1t a CSV adatait.",
    "sales.csv.amount-format": "Az \u00e9rt\u00e9knek egy sz\u00e1mnak kellene lennie vessz\u0151vel (\",\"), hogy a tizedesek elv\u00e1laszt\u00e1sra ker\u00fcljenek",
    "sales.csv.columns": "N\u00e9gy oszlopot kellene tartalmaznia, a d\u00e1tumot az els\u0151 oszlopban \u00e9s az \u00e9rt\u00e9knek a negyedik oszlopban kell felt\u00fcntetni",
    "sales.csv.date-format": "Az \u00e9v-h\u00f3nap-nap d\u00e1tumot sz\u00e1mmal adja meg.",
    "sales.csv.separators": "Az oszlopokat vessz\u0151vel (\",\") kell elv\u00e1lasztani",
    "sales.error": "Hiba",
    "sales.import-csv": "CSV kiv\u00e1laszt\u00e1sa",
    "sales.import-xlsx": "XLSX import\u00e1l\u00e1sa",
    "sales.info": "Inform\u00e1ci\u00f3",
    "sales.net": "* nett\u00f3 elad\u00e1sok (\u00c1FA n\u00e9lk\u00fcl)",
    "sales.new": "\u00daj \u00e9rt\u00e9kes\u00edt\u00e9s hozz\u00e1ad\u00e1sa",
    "sales.sale-concepts": "\u00c9rt\u00e9kes\u00edt\u00e9si koncepci\u00f3k",
    "sales.select_a_shop": "Az \u00faj \u00e9rt\u00e9kes\u00edt\u00e9s hozz\u00e1ad\u00e1s\u00e1hoz v\u00e1lasszon ki egy \u00fczletet",
    "sales.select_valid_date": "Nem lehet az az \u00e9rt\u00e9kes\u00edt\u00e9shez j\u00f6v\u0151beni d\u00e1tumot megadni. K\u00e9rem v\u00e1lasszon ki egy \u00e9rv\u00e9nyes d\u00e1tumot.",
    "sales.shops_not_found": "Az \u00faj \u00e9rt\u00e9kes\u00edt\u00e9s hozz\u00e1ad\u00e1s\u00e1hoz v\u00e1lasszon ki egy \u00fczletet",
    "sales.upload-xlsx-error": "Az \u00d6n \u00e1ltal felt\u00f6lt\u00f6tt f\u00e1jl form\u00e1tuma \u00e9rv\u00e9nytelen. K\u00e9rem ellen\u0151rizze a f\u00e1jlt \u00e9s pr\u00f3b\u00e1lja meg \u00fajra.",
    "sales.upload-xlsx-success": "Az XLSX-et sikeresen felt\u00f6lt\u00f6tte",
    "sales.xlsx-format": "Az XLSX-nek a k\u00f6vetkez\u0151 form\u00e1tum\u00fanak kell lennie:",
    "sales.xlsx.amount-format": "A mennyis\u00e9get az Excelen kereszt\u00fcl, a \"valutanem\" megad\u00e1s\u00e1val \u00e1ll\u00edtsa be.",
    "sales.xlsx.columns": "H\u00e1rom oszloposnak kell lennie, az els\u0151 oszlopban a d\u00e1tummal, az elad\u00e1si koncepci\u00f3t a m\u00e1sodik oszlopban \u00e9s az \u00f6sszeg a harmadikban. Az els\u0151 sorban pedig az oszlop nev\u00e9t kell megadni.",
    "sales.xlsx.concept-format": "Ezt koncepci\u00f3t a Yardi k\u00f3dja hat\u00e1rozza meg. Az alap\u00e9rtelmezett \u00e9rt\u00e9knek 'gm'-nek kellene lennie",
    "sales.xlsx.date-format": "A d\u00e1tumot az Excel-ben d\u00e1tum mez\u0151 megad\u00e1s\u00e1val r\u00f6gz\u00edtse.",
    "sales.xlsx.download-example": "Template let\u00f6lt\u00e9se",
    "same-period-last": "Ugyanaz a peri\u00f3dus el\u0151z\u0151 {{period}}",
    "sanitation": "Higi\u00e9nia",
    "saturday": "Szombat",
    "saturday-short": "Szom",
    "save": "Ment\u00e9s {{modelName}}",
    "save.datetime.cancel": "M\u00e9gse",
    "save.datetime.ok": "OK\u00c9",
    "scmanager": "SC vezet\u0151",
    "sc-manager": "SC vezet\u0151",
    "search": "Keres\u00e9s",
    "second-invoice-amount": "A m\u00e1sodik sz\u00e1mla \u00f6sszege",
    "second-invoice-date": "A m\u00e1sodik sz\u00e1mla d\u00e1tuma",
    "second-property": "M\u00e1sodik ingatlan",
    "segment": "Szegmens",
    "segment.filters-message-first-part": "Tal\u00e1lja meg a felt\u00e9teleknek megfelel\u0151 v\u00e1s\u00e1rl\u00f3kat",
    "segment.filters-message-second-part": "a k\u00f6vetkez\u0151 felt\u00e9telek:",
    "segment.not_found": "Szegmens nem tal\u00e1lhat\u00f3 ",
    "segment.save_segment": "Szegmens ment\u00e9se",
    "segment.total_users_reached": "Az \u00f6sszes el\u00e9rt felhaszn\u00e1l\u00f3",
    "segment.type_find": "Sz\u0171k\u00edtse le a szegmensek keres\u00e9s\u00e9t",
    "segment.warning_update_users": "Ez a kamp\u00e1ny, a szegmens felt\u00e9teleinek megfelel\u0151 felhaszn\u00e1l\u00f3knak naponta ki lesz k\u00fcldve",
    "segments.filters_needed": "Haszn\u00e1ljon fel legal\u00e1bb egyet a k\u00f6vetkez\u0151 sz\u0171r\u0151kb\u0151l:",
    "segments.save_as": "Mentse el {{modelName}}",
    "segments.save_changes": "V\u00e1ltoztat\u00e1sok ment\u00e9se",
    "segments.save_new": "Ment\u00e9s, mint \u00faj {{modelName}}",
    "segments.type_name": "Adja meg {{modelName}} nev\u00e9t",
    "segments.warning": "Figyelem!",
    "segments.type_segment_name": "\u00cdrja be a szegmens nev\u00e9t",
    "select": "Kiv\u00e1laszt",
    "select-brand": "M\u00e1rka kiv\u00e1laszt\u00e1sa",
    "select-building": "\u00c9p\u00fclet kiv\u00e1laszt\u00e1sa",
    "select-category": "Kateg\u00f3ria kiv\u00e1laszt\u00e1sa",
    "select-center": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpont kiv\u00e1laszt\u00e1sa",
    "select-company": "V\u00e1llalat kiv\u00e1laszt\u00e1sa",
    "select-country": "Orsz\u00e1g kiv\u00e1laszt\u00e1sa",
    "select-fit-out-coordinator-cbre": "CBRE-s, fit-out munkat\u00e1rs kiv\u00e1laszt\u00e1sa",
    "select-fit-out-coordinator-external": "K\u00fcls\u0151s, fit-out munkat\u00e1rs kiv\u00e1laszt\u00e1sa",
    "select-leasing-center-type": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpont t\u00edpus\u00e1nak kiv\u00e1laszt\u00e1sa",
    "select-level": "Szint kiv\u00e1laszt\u00e1sa",
    "select-range": "Ter\u00fclet kiv\u00e1laszt\u00e1sa",
    "select-fit-out-coordinator": "Fit-out munkat\u00e1rs kiv\u00e1laszt\u00e1sa",
    "select-shop": "\u00dczlet kiv\u00e1laszt\u00e1sa",
    "select-state": "\u00c1llapot kiv\u00e1laszt\u00e1sa",
    "select-visibility": "L\u00e1that\u00f3s\u00e1g kiv\u00e1laszt\u00e1sa",
    "select-zones": "Z\u00f3na kiv\u00e1laszt\u00e1sa",
    "select_all": "\u00d6sszes kijel\u00f6l\u00e9se",
    "select_communication_required_attention": "Ellen\u0151rizze, amikor a kommunik\u00e1ci\u00f3 azonnali figyelmet \u00e9rdemel",
    "select_one_option": "Opci\u00f3 kiv\u00e1laszt\u00e1sa",
    "send": "K\u00fcld\u00e9s",
    "send-email-template.help.multipleEmails": "\u00cdrjon be t\u00f6bb e-mail c\u00edmet amelyet vessz\u0151vel v\u00e1laszt el",
    "send_economic_proposal": "Gazdas\u00e1gi javaslat k\u00fcld\u00e9se",
    "send_email": "E-mail k\u00fcld\u00e9se",
    "sep": "Szept",
    "september": "Szeptember",
    "september-short": "Szept",
    "service": "Szolg\u00e1ltat\u00e1s",
    "service-charges": "K\u00f6z\u00f6sk\u00f6lts\u00e9gek",
    "service.edit": "Szolg\u00e1ltat\u00e1s szerkeszt\u00e9se",
    "service.new": "\u00daj szolg\u00e1ltat\u00e1s",
    "services": "Szolg\u00e1ltat\u00e1sok",
    "services-and-qualities": "Szolg\u00e1ltat\u00e1sok \u00e9s Tulajdons\u00e1gok",
    "services-charges": "K\u00f6zszolg\u00e1ltat\u00e1sokhoz k\u00f6t\u0151d\u0151 d\u00edjak",
    "sex": "Neme",
    "sent": "Elk\u00fcldve",
    "shop.all": "Mind",
    "shopMonth.state.closed": "Z\u00e1rva",
    "shopMonth.state.unclosed": "Nyitva",
    "shopMonth.state.validated": "J\u00f3v\u00e1hagyva",
    "shopMonth.state.without_sales": "Kirakat",
    "shopfront": "Kirakat",
    "shops": "\u00dczletek",
    "shops.not_found": "Nem tal\u00e1lhat\u00f3 \u00fczlet",
    "show-children": "Mutassa a gyermekeket",
    "show-details": "Mutassa a r\u00e9szleteket",
    "show-development": "Mutassa a fejleszt\u00e9seket",
    "show-price": "Mutassa az \u00e1rat",
    "sign": "Kijel\u00f6l\u00e9s",
    "signing-date": "Al\u00e1\u00edr\u00e1s d\u00e1tuma",
    "single": "Egyed\u00fcl\u00e1ll\u00f3",
    "single_family": "Csonka csal\u00e1d",
    "sold": "Eladva",
    "south": "D\u00e9l",
    "spending_receipts": "Nyugt\u00e1k",
    "spreadsheet": "T\u00e1bl\u00e1zat",
    "staff": "Alkalmazottak",
    "staff-manager": "Menedzsment",
    "staff-member": "Alkalmazott",
    "staff-members": "Alkalmazottak",
    "staff-members.new": "\u00daj alkalmazott",
    "staff.level.admin": "Rendszergazda",
    "staff.level.commerce": "\u00dczlet",
    "staff.level.crmmanager": "CRM vezet\u0151",
    "staff.level.crmoperator": "CRM ir\u00e1ny\u00edt\u00f3ja",
    "staff.level.manager": "Vezet\u0151",
    "staff.level.owner": "Tulajdonos",
    "staff.level.scmanager": "SC vezet\u0151",
    "staff.level.storeManager": "\u00dczletvezet\u0151",
    "staff.level.weboperator": "Web kezel\u0151",
    "start-date": "Kezd\u00e9s d\u00e1tuma",
    "start_date": "Kezd\u00e9s d\u00e1tuma",
    "start_datetime": "Kezd\u00e9s d\u00e1tumideje",
    "state": "\u00c1llapot",
    "status": "Beoszt\u00e1s",
    "storage-plan": "Rakt\u00e1rtervez\u00e9s",
    "storage-rooms-number": "T\u00e1rol\u00f3 helyis\u00e9gek sz\u00e1ma",
    "store-manager": "\u00dczletvezet\u0151",
    "start_work": "Munkakezd\u00e9s",
    "subtype": "Alt\u00edpus",
    "subtype.edit": "Alt\u00edpus szerkeszt\u00e9se",
    "subtype.name": "N\u00e9v",
    "subtype.new": "\u00daj alt\u00edpus",
    "subtype.type": "T\u00edpus",
    "subway": "Metr\u00f3",
    "sunday": "Vas\u00e1rnap",
    "sunday-short": "Vas",
    "supplier": "Besz\u00e1ll\u00edt\u00f3",
    "supplier-contact": "Besz\u00e1ll\u00edt\u00f3i kapcsolat",
    "supplier-contact.edit": "Besz\u00e1ll\u00edt\u00f3i kapcsolat szerkeszt\u00e9se",
    "supplier-contact.filters": "Sz\u0171r\u0151k",
    "supplier-contact.new": "\u00daj besz\u00e1ll\u00edt\u00f3i kapcsolat",
    "supplier.edit": "Besz\u00e1ll\u00edt\u00f3 szerkeszt\u00e9se",
    "supplier.new": "\u00daj besz\u00e1ll\u00edt\u00f3",
    "suppliers.active": "Akt\u00edv",
    "suppliers.filters": "Sz\u0171r\u0151k",
    "suppliers.local": "Helyi",
    "suppliers.national": "Nemzetk\u00f6zi",
    "supply-contracts": "\u00c1rubeszerz\u00e9si szerz\u0151d\u00e9s",
    "table-list.not_found": "Nincs el\u00e9rhet\u0151 elem",
    "taxpayer_number": "Ad\u00f3fizet\u0151 sz\u00e1ma",
    "technical-data": "Technikai adatok",
    "technical-direction": "Technikai ir\u00e1ny",
    "temporary-hoarding": "\u00c1tmeneti felhalmoz\u00e1s",
    "terrace-template-approval-date": "A terasz mint\u00e1j\u00e1nak elfogad\u00e1si ideje",
    "terrace-template-delivery-date": "A terasz mint\u00e1j\u00e1nak teljes\u00edt\u00e9si ideje",
    "terrace-template-reviews": "A terasz mint\u00e1inak \u00e1ttekint\u00e9se",
    "thecnical-data": "Technikai adatok",
    "third-invoice-amount": "A harmadik sz\u00e1mla \u00f6sszege",
    "third-invoice-date": "A harmadik sz\u00e1mla d\u00e1tuma",
    "thumbnail": "El\u0151n\u00e9zeti k\u00e9p",
    "thursday": "Cs\u00fct\u00f6rt\u00f6k",
    "thursday-short": "Cs\u00fct",
    "ticket.campaign": "Kamp\u00e1ny",
    "ticket.campaign-name": "Kamp\u00e1ny",
    "ticket.code": "K\u00f3d",
    "ticket.created-at": "L\u00e9trehozva a",
    "ticket.generate-error-message": "A jegyek l\u00e9trehoz\u00e1sakor hiba l\u00e9pett fel, ez\u00e9rt a m\u0171veletet nem siker\u00fclt befejezni. K\u00e9rem vegye fel a kapcsolatot a t\u00e1mogat\u00e1si csoporttal.",
    "ticket.generate-success-message": "A jegy helyesen l\u00e9tre lett hozva.",
    "ticket.label": "Szab\u00e1ly",
    "ticket.pending-balance": "F\u00fcgg\u0151ben l\u00e9v\u0151 egyenleg",
    "ticket.preview-message": "Jegy \u00f6sszes\u00edt\u0151",
    "ticket.rule": "Szab\u00e1ly",
    "ticket.tickets-number": "Jegyek",
    "tickets.can-not-generate-message": "A felhaszn\u00e1l\u00f3nak nincsen bev\u00e1lthat\u00f3 nyugt\u00e1ja.",
    "tickets.code": "K\u00f3d",
    "tickets.date": "D\u00e1tum",
    "tickets.not-enough-tickets": "Ennek a felhaszn\u00e1l\u00f3nak nincs el\u00e9g f\u00fcgg\u0151ben l\u00e9v\u0151 egyenlege, hogy jegyeket gener\u00e1ljon",
    "tickets.price": "\u00c1r",
    "tickets.resume": "Folytat\u00e1s",
    "tickets.salesman": "\u00c9rt\u00e9kes\u00edt\u0151",
    "tickets.shop-search": "\u00dczlet keres\u00e9se",
    "title.communication.subtypes": "Kommunik\u00e1ci\u00f3s alt\u00edp\u00fasok",
    "title.header.communication": "Kommunik\u00e1ci\u00f3",
    "title.header.configuration": "Konfigur\u00e1ci\u00f3",
    "title.header.configuration.dashboard": "Dashboard be\u00e1ll\u00edt\u00e1sok",
    "title.header.configuration.staff-members": "Alkalmazottak",
    "title.header.crm.email-templates": "E-mail Sablonok",
    "title.header.crm.users": "\u00dcgyfelek",
    "title.header.dashboards.show": "Dashboard",
    "title.header.desktop": "Asztal",
    "title.header.ecop.works": "ECOP Munk\u00e1latok",
    "title.header.emailing.campaigns": "E-mail kamp\u00e1nyok",
    "title.header.files": "F\u00e1jlkezel\u00e9s",
    "title.header.guards": "\u0150r\u00f6k",
    "title.header.kpi-tech.centers": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpontok",
    "title.header.kpi-tech.years": "KPI Tech adatok lap",
    "title.header.leasing.brands": "M\u00e1rk\u00e1k",
    "title.header.leasing.centers": "Bev\u00e1s\u00e1rl\u00f3k\u00f6zpontok",
    "title.header.leasing.contacts": "Kapcsolatok",
    "title.header.leasing.operations": "M\u0171veletek",
    "title.header.leasing.owners": "Tulajdonosok",
    "title.header.leasing.units": "Egys\u00e9gek",
    "title.header.marketing-analytics.campaigns": "Kamp\u00e1nyok",
    "title.header.marketing-analytics.campaigntypes": "Kamp\u00e1ny t\u00edpusok",
    "title.header.marketing-analytics.lineitems": "Darabsz\u00e1m",
    "title.header.marketing-analytics.services": "Szolg\u00e1ltat\u00e1sok",
    "title.header.marketing-analytics.suppliercontacts": "Besz\u00e1ll\u00edt\u00f3i kapcsolatok",
    "title.header.marketing-analytics.suppliers": "Besz\u00e1ll\u00edt\u00f3k",
    "title.header.residential.campaigns": "Kamp\u00e1nyok",
    "title.header.residential.developments": "Fejleszt\u00e9sek",
    "title.header.residential.owners": "Tulajdonosok",
    "title.header.residential.properties": "Ingatlanok",
    "title.header.sales": "\u00c9rt\u00e9kes\u00edt\u00e9sek vezet\u00e9se",
    "title.header.subtypes": "Kommunik\u00e1c\u00f3s t\u00edpusok",
    "title.header.testing": "Teszt f\u0151c\u00edm",
    "tittle.communication.subtypes": "Kommunik\u00e1ci\u00f3 alfajt\u00e1i",
    "tittle.header.configuration": "Konfigur\u00e1ci\u00f3",
    "tittle.header.configuration.dashboard": "Dashboard konfigur\u00e1ci\u00f3",
    "tittle.header.dashboards.show": "Dashboard",
    "tittle.header.crm.email-templates": "E-mail sablonok",
    "tittle.header.emailing.campaigns": "E-mail kamp\u00e1nyok",
    "tittle.header.files": "F\u00e1jlkezel\u00e9s",
    "tittle.header.guards": "\u0150r\u00f6k",
    "tittle.header.sales": "\u00c9rt\u00e9kes\u00edt\u00e9sek vezet\u00e9se",
    "tittle.header.subtypes": "Kommunik\u00e1ci\u00f3 t\u00edpusai",
    "today": "Ma",
    "token": "Zseton",
    "total": "\u00d6sszes",
    "total-costs": "\u00d6sszes k\u00f6lts\u00e9gek",
    "total-units": "\u00d6sszes egys\u00e9g",
    "total_sales": "\u00d6sszes elad\u00e1s",
    "train": "Vonat",
    "transportHub": "K\u00f6zleked\u00e9si csom\u00f3pont",
    "trendy-&-lifestyle": "Divat & \u00e9letm\u00f3d",
    "tuesday": "Kedd",
    "tuesday-short": "Kedd",
    "type": "T\u00edpus",
    "type-find": "T\u00edpus keres\u00e9se",
    "unassigned": "Hat\u00e1rozatlan",
    "unit": "Egys\u00e9g",
    "unit-handover": "Egys\u00e9g \u00e1tad\u00e1s",
    "unit-or-location-rejected": "Az egys\u00e9g vagy a helyzet el lett utas\u00edtva",
    "unit-type": "Egys\u00e9g t\u00edpusa",
    "unpublish": "Nem nyilv\u00e1nos",
    "upcoming-commercialization": "K\u00f6zelg\u0151 Commercialisation",
    "update-password.server-problem": "Probl\u00e9ma ad\u00f3dott a szerver m\u0171k\u00f6d\u00e9s\u00e9vel. K\u00e9rem l\u00e9pjen kapcsolatba a t\u00e1mogat\u00e1si csoporttal",
    "upload": "Felt\u00f6lt",
    "upsize-downsize": "Felt\u00f6lt",
    "urban": "V\u00e1rosi",
    "type_find_commerce": "G\u00e9peljen az \u00fczletek sz\u00fcr\u00e9s\u00e9hez...",
    "urgent": "S\u00fcrg\u0151s",
    "useful-surface": "Useful sur.",
    "user-error": "Valami hiba t\u00f6rt\u00e9nt. K\u00e9rem ellen\u0151rizze a k\u00f6vetkez\u0151ket:",
    "user.actions": "M\u0171veletek",
    "user.address": "C\u00edm",
    "user.birthdate": "Sz\u00fclet\u00e9si d\u00e1tum",
    "user.campaigns": "Kamp\u00e1nyok",
    "user.channels": "Csatorn\u00e1k",
    "user.birthday": "Sz\u00fclet\u00e9snap",
    "user.children_number": "Gyermekek sz\u00e1ma",
    "user.city": "V\u00e1ros",
    "user.code": "V\u00e1s\u00e1rl\u00f3i k\u00f3d",
    "user.company": "V\u00e1llalat",
    "user.countries": "Orsz\u00e1gok",
    "user.cp": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "user.created_at": "Regisztr\u00e1ci\u00f3 d\u00e1tuma {{mandatory}}",
    "user.dni": "Szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "user.document_type": "Dokumentum t\u00edpusa",
    "user.electronic_communications_phrase": "Szeretn\u00e9k elektronikus \u00faton kereskedelmi \u00e9rtes\u00edt\u00e9seket kapni.",
    "user.email": "Email c\u00edm",
    "user.gdpr_phrase": "Elfogadom a GDPR szab\u00e1lyzat\u00e1t",
    "user.general_data": "\u00c1ltal\u00e1nos adatok",
    "user.last_name": "Vezet\u00e9kn\u00e9v",
    "user.lopd_phrase": "Elfogadom az LOPD szab\u00e1lyzat\u00e1t",
    "user.marital_status": "Csal\u00e1di \u00e1llapot",
    "user.marketing_data": "Marketing",
    "user.nacionality": "Nemzetis\u00e9g",
    "user.name": "N\u00e9v",
    "user.nationality": "Nemzetis\u00e9g",
    "user.notes": "Jegyzetek",
    "user.other_data": "Egy\u00e9b adatok",
    "user.partners_electronic_communications_phrase": "Szeretn\u00e9k egy harmadik f\u00e9lt\u0151l, elektronikus \u00faton kereskedelmi \u00e9rtes\u00edt\u00e9seket kapni.",
    "user.person_type": "Term\u00e9szetes vagy jogi szem\u00e9ly",
    "user.phone": "Telefon",
    "user.province": "Tartom\u00e1ny",
    "user.purchaser-type": "R\u00e9szletv\u00e1s\u00e1rl\u00e1s t\u00edpusa",
    "user.reference-building": "Hivatkoz\u00f3 \u00e9p\u00fclet",
    "user.secondary_phone": "M\u00e1sodlagos telefon",
    "user.select_document_type": "V\u00e1lassza ki a dokumentum t\u00edpus\u00e1t",
    "user.select_marital_status": "V\u00e1lassza ki a csal\u00e1di \u00e1llapot\u00e1t",
    "user.select_nationality": "V\u00e1lassza ki a nemzetis\u00e9get",
    "user.select_nacionality": "Nemzetis\u00e9g kiv\u00e1laszt\u00e1sa",
    "user.select_person_type": " ",
    "user.select_province": "Tartom\u00e1ny kiv\u00e1laszt\u00e1sa",
    "user.select_sex": "V\u00e1lassza ki a nem\u00e9t",
    "user.sex": "Neme",
    "user.user-types": "Felhaszn\u00e1l\u00f3i t\u00edpusok",
    "user.valid_datetime": "\u00c9rv\u00e9nyes d\u00e1tumid\u0151",
    "user_types": "Felhaszn\u00e1l\u00f3i t\u00edpusok",
    "users.campaigns": "Kamp\u00e1nyok",
    "users.code": "K\u00f3d",
    "users.commerce": "Forgalom",
    "users.date": "D\u00e1tum",
    "users.edit": "V\u00e1s\u00e1rl\u00f3 szerkeszt\u00e9se",
    "users.general_data": "\u00c1ltal\u00e1nos adatok",
    "users.new": "\u00daj v\u00e1s\u00e1rl\u00f3",
    "users.not-has-email-warning-message": "Javasoljuk, hogy adja meg a felhaszn\u00e1l\u00f3i e-mail c\u00edm\u00e9t.",
    "users.price": "\u00c1r",
    "users.redeemable-receipt": "A jegy bev\u00e1lthat\u00f3",
    "users.other_data": "Egy\u00e9b adatok",
    "users.sale_process": "\u00c9rt\u00e9kes\u00edt\u00e9si folyamat",
    "users.tickets": "Jegyek",
    "users.warning": "Figyelem!",
    "usertypes": "Felhaszn\u00e1l\u00f3 t\u00edpusok",
    "value": "\u00c9rt\u00e9k",
    "vat-number": "Szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "vat_number": "szem\u00e9lyi igazolv\u00e1ny sz\u00e1ma",
    "ventilation": "Ventill\u00e1ci\u00f3",
    "video": "Vide\u00f3",
    "vinyl": "Vinyl",
    "vinyl-design": "Vinyl diz\u00e1jn",
    "visibility": "L\u00e1that\u00f3s\u00e1g",
    "visit_building": "\u00c9p\u00fclet megtekint\u00e9se",
    "visits": "L\u00e1togat\u00e1sok",
    "visits.recurrence": "Ism\u00e9tl\u0151d\u00e9s",
    "want_export": "Adatok kiv\u00e1laszt\u00e1sa az export\u00e1l\u00e1shoz {{modelName}}",
    "want_remove": "Biztosan t\u00f6r\u00f6lni szeretn\u00e9 ezt {{modelName}} ?",
    "want_save_without_gdpr_or_electronic_communications": "Val\u00f3ban folytatni k\u00edv\u00e1nja a felhaszn\u00e1l\u00e1si felt\u00e9telek elfogad\u00e1sa n\u00e9lk\u00fcl?",
    "want_save_without_electronic_communications": "Val\u00f3ban el szeretn\u00e9 menteni a felhaszn\u00e1l\u00f3t e-mail c\u00edm n\u00e9lk\u00fcl?",
    "warning": "Figyelem!",
    "warning-residential-change-text": "A menteni k\u00edv\u00e1nt v\u00e1ltoztat\u00e1sok k\u00fcls\u0151 szolg\u00e1ltat\u00e1sokat is befoly\u00e1solnak. Val\u00f3ban k\u00edv\u00e1nja folytatni?",
    "warning-title": "Figyelem!",
    "warning_enter_sales": "A nett\u00f3 elad\u00e1s (\u00c1fa n\u00e9lk\u00fcl) amit az adott \u00fczlet a megjel\u00f6lt peri\u00f3dusban igazolt, amely pontosan megegyezik a t\u00e1mogat\u00f3 dokumentumokkal \u00e9s k\u00f6nyvel\u00e9si jegyzetekkel a c\u00e9g okm\u00e1nyaiban",
    "waterkmark": "V\u00edzjel",
    "web": "Web",
    "web-operator": "Web kezel\u0151",
    "wednesday": "Szerda",
    "wednesday-short": "Szer",
    "week": "H\u00e9t",
    "weekday.friday": "P\u00e9ntek",
    "weekday.monday": "H\u00e9tf\u0151",
    "weekday.saturday": "Szombat",
    "weekday.sunday": "Vas\u00e1rnap",
    "weekday.thursday": "Cs\u00fct\u00f6rt\u00f6k",
    "weekday.tuesday": "Kedd",
    "weekday.wednesday": "Szerda",
    "west": "Nyugat",
    "without-vat": "\u00c1fa n\u00e9lk\u00fcl",
    "wizard.next_step": "K\u00f6vetkez\u0151",
    "wizard.prev_step": "El\u0151z\u0151",
    "woman": "N\u0151",
    "work-budget": "Munka k\u00f6lts\u00e9gvet\u00e9se",
    "work-permit": "Munka enged\u00e9ly",
    "work-permit-approval": "Munka enged\u00e9ly j\u00f3v\u00e1hagy\u00e1sa",
    "work-permit-request": "Munka enged\u00e9ly ig\u00e9nyl\u00e9se",
    "work-planning": "Munka tervez\u00e9se",
    "work-start": "Munka kezdete",
    "workplace-permit": "Munkahelyi enged\u00e9ly",
    "yardi": "Yardi",
    "yardi-code": "Yardi k\u00f3d",
    "yardi.continue_export": "Export\u00e1l\u00e1s folytat\u00e1sa",
    "yardi.shop_property_error": "Az \u00fczlethez bev\u00e1s\u00e1rl\u00f3k\u00f6zpontot kell rendelni.",
    "yardi.tenant_code_error": "Az \u00fczletnek b\u00e9rl\u0151i k\u00f3dra van sz\u00fcks\u00e9ge.",
    "yardi.warn_errors_exporting": "Az exporban hib\u00e1k keletkeztek. K\u00e9rem n\u00e9zze \u00e1t folytat\u00e1s el\u0151tt.",
    "year": "\u00c9v",
    "yen": "Yen \u00a5",
    "yes": "Igen",
    "youtube.url": "YouTube URL",
    "zip-code": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
    "zone": "Z\u00f3na",
    "zone-services": "Z\u00f3na szolg\u00e1ltat\u00e1sok",
    "select_month": "H\u00f3nap kiv\u00e1laszt\u00e1sa",
    "select_year": "\u00c9v kiv\u00e1laszt\u00e1sa",
    "legal_person": "Jogi szem\u00e9ly",
    "residential.multimedia_files": "Multim\u00e9dia f\u00e1jlok",
    "segments.save_new_segment": "\u00daj szegmens ment\u00e9se",
    "save_segment": "Szegmens ment\u00e9se",
    "segments.save_as_segment": "Ment\u00e9s szegmensk\u00e9nt",
    "close_months": "H\u00f3napok z\u00e1r\u00e1sa",
    "user.age": "Kor",
    "users.age.under-35": "35 alatt",
    "users.age.35-45": "35-45",
    "users.age.50-65": "50-65",
    "users.age.over-65": "65 felett",
    "user.select_age": "Kor kiv\u00e1laszt\u00e1sa",
    "users.nationality.spanish": "Spanyol",
    "users.nationality.foreigner": "K\u00fclf\u00f6ldi",
    "user.select_nationality": "Nemzetis\u00e9g kiv\u00e1laszt\u00e1sa",
    "user.civil_status": "Csal\u00e1",
    "user.select_civil_status": "Csal\u00e1di \u00e1llapot kiv\u00e1laszt\u00e1sa",
    "users.civil-status.single": "Egyed\u00fcl\u00e1ll\u00f3",
    "users.civil-status.married": "H\u00e1zas",
    "users.civil-status.divorced": "Elv\u00e1lt",
    "users.civil-status.widower": "K\u00f6r\u00f6s",
    "user.lead_origin": "Lead origin",
    "user.select_lead_origin": "Select lead origin",
    "users.lead-origin.web_cbre": "Web CBRE",
    "users.lead-origin.landing_origami": "Landing Origami",
    "users.lead-origin.valla": "Valla",
    "users.lead-origin.referenced": "Referenced",
    "users.lead-origin.event": "Event",
    "users.lead-origin.social_network": "Social network",
    "users.lead-origin.emailing": "Emailing",
    "users.lead-origin.developer_website": "Developer website",
    "users.lead-origin.press": "Press",
    "users.lead-origin.selling_point": "Selling point",
    "users.lead-origin.idealista": "Idealista",
    "user.typology": "Typology",
    "user.select_typology": "Select typology",
    "users.typology.multi-family": "Multi-family",
    "users.typology.single-family": "Multi-family",
    "user.reason_for_purchase": "Reason for purchase",
    "user.select_reason_for_purchase": "Select reason for purchase",
    "users.reason-for-purchase.first-home": "First home",
    "users.reason-for-purchase.second-home": "Second home",
    "users.reason-for-purchase.investment": "Investment",
    "users.reason-for-purchase.replacement": "Replacement",
    "user.state": "State",
    "user.select_state": "Select state",
    "users.state.monitoring": "Monitoring",
    "users.state.discarded": "Discarded",
    "users.state.pre-registration": "Pre-registration",
    "users.state.incorporation": "Incorporation",
    "users.state.writing": "Writing",
    "user.visit": "Visit",
    "user.source_of_funds": "Source of funds",
    "user.reason_for_discard": "Reason for discard",
    "user.select_reason_for_discard": "Select reason for discard",
    "users.reason-for-discard.location": "Location",
    "users.reason-for-discard.price": "Price",
    "users.reason-for-discard.surface": "Surface",
    "users.reason-for-discard.deadline": "Deadline",
    "users.reason-for-discard.buy_competition": "Buy competition",
    "users.reason-for-discard.other": "Other",
    "users.source-of-funds.own": "Own",
    "users.source-of-funds.loan": "Loan",
    "crm.users.created-at": "Created at",
};