export default Object.freeze([
  {
    label: i18n.t('intranet.sales-years.status.unrequested'),
    value: 'unrequested',
    color: '#f8b827',
  },
  {
    label: i18n.t('intranet.sales-years.status.pending'),
    value: 'pending',
    color: '#2477b5',
  },
  {
    label: i18n.t('intranet.sales-years.status.rejected'),
    value: 'rejected',
    color: '#e80042',
  },
  {
    label: i18n.t('intranet.sales-years.status.validated'),
    value: 'validated',
    color: '#028a3d',
  },
]);
