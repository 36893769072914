import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';

import dashboardCategories from 'neuro-frontend/enums/dashboard-categories';
import dashboardPanels from 'neuro-frontend/enums/dashboard-panels';

export default class extends Controller {
  categories = dashboardCategories;

  panels = dashboardPanels;

  @computed('model.dashboard.config.components')
  get components() {
    return JSON.stringify(this.model.dashboard?.config?.components.content);
  }

  set components(field) {
    set(this, 'model.dashboard.config.components', JSON.parse(field));
  }

  @computed('model.dashboard.config.filters')
  get filters() {
    return this.model.dashboard?.config?.filters.toString();
  }

  set filters(field) {
    if (field) {
      set(this, 'model.dashboard.config.filters', field.split(','));
    } else {
      set(this, 'model.dashboard.config.filters', []);
    }
  }

  @action
  initializeConfig() {
    const { dashboard } = this.model;

    if ('custom' === dashboard.panel && !dashboard.config) {
      set(dashboard, 'config', {});
      set(dashboard, 'config.styles', '');
      set(dashboard, 'config.filters', []);

      set(dashboard, 'config.components', []);
    }
  }
}
