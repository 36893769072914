import { get, computed } from '@ember/object';

import ModuleIntranet from './module-intranet';

export default class extends ModuleIntranet {
  roles = [
    'admin',
    'manager',
    'scmanager',
  ]

  @computed('auth.center', 'auth.user.role')
  get canRead() {
    const role = get(this, 'auth.user.role');
    return get(this, 'canAccess')
      && this.roles.includes(role);
  }
}
