// https://gist.github.com/Artistan/8ee82f6753dca632ca3307917b4e6034
import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

const joinStyles = (object, glue = ':', separator = ';') => Object.getOwnPropertyNames(object)
  .map((k) => [k, object[k]].join(glue))
  .join(separator);

export function style(params, hash) {
  let styles = '';

  if (params !== undefined) {
    styles = joinStyles(params);
  }

  if (hash !== undefined) {
    styles = joinStyles(hash);
  }

  return htmlSafe(styles);
}

export default helper(style);
