import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';

import download from 'downloadjs';
import 'intersection-observer';
import fetch from 'fetch';

import fileTypeAssets from 'neuro-frontend/enums/file-type-assets';
import fileExtensions from 'neuro-frontend/enums/file-extensions';
import { isImage, getFileExtension } from 'neuro-frontend/utils/file-extension';

export default class extends Component.extend({
  file: {},
}) {
  @service() apiFiles

  @service() session

  aspectRatio = 4 / 3

  attributeBindings = ['style']

  classNames = ['nf-file']

  classNameBindings = ['isImage::nf-file--is-not-image']

  width = 0

  @computed('aspectRatio', 'width')
  get height() {
    const {
      aspectRatio,
      width,
    } = this;

    return width / aspectRatio;
  }

  @computed('file')
  get isImage() {
    const {
      file,
    } = this;

    return 'object' === typeof file && isImage(get(file, 'url'));
  }

  @computed('file', 'isImage')
  get src() {
    const isImageFile = get(this, 'isImage');
    const file = get(this, 'file');

    if (isImageFile) {
      return get(file, 'url');
    }

    return (file && get(file, 'url'))
      ? get(
        fileTypeAssets.findBy(
          'type',
          get(
            fileExtensions.findBy(
              'value',
              getFileExtension(get(file, 'url')),
            ),
            'type',
          ),
        ),
        'value',
      )
      : '';
  }

  @computed('src', 'blob', 'height')
  get style() {
    const {
      blob,
      src,
    } = this;
    const backgroundUrl = this.isImage ? blob : src;
    const styles = {
      height: `${this.height}px`,
    };

    if (backgroundUrl) {
      styles['background-image'] = `url('${backgroundUrl}')`;
    }

    return htmlSafe(Object.keys(styles)
      .map((selector) => `${selector}:${styles[selector]}`)
      .join(';'));
  }

  async didReceiveAttrs() {
    const file = get(this, 'file');

    if ('object' === typeof file && isImage(get(file, 'url'))) {
      set(this, 'blob', await get(this, 'apiFiles').getImageBlob(get(file, 'url')));
    }
  }

  didRender() {
    const setWidth = () => {
      if (!this.isDestroyed) {
        set(this, 'width', this.element ? this.element.offsetWidth : 0);
      }
    };
    const observer = new IntersectionObserver(setWidth);

    observer.observe(this.element);
  }

  @action
  delete() {
    if ('function' === typeof get(this, 'onDelete')) {
      get(this, 'onDelete')();
    }
  }

  @action
  download() {
    return fetch(
      get(this, 'file.url'),
      {
        credentials: 'include',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${get(this, 'session.data.authenticated.token')}`,
        },
      },
    )
      .then((response) => response.blob())
      .then((blob) => download(blob, get(this, 'file.name')));
  }

  @action
  select() {
    if (get(this, 'isImage') && ('function' === typeof get(this, 'onSelect'))) {
      get(this, 'onSelect')(get(this, 'file'));
    }
  }
}
