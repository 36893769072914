import { get } from '@ember/object';
import { assign } from '@ember/polyfills';

export default function parseFilters(filters) {
  return filters.reduce(
    (prev, cur) => {
      const filter = assign({}, prev);
      const value = get(cur, 'type.value');

      if (value) {
        // we need JavaScript Native Objects instead of EmberObjects
        // That's because we can have keys like 'property.residential_development'
        if (!prev[value]) {
          filter[value] = [];
        }

        filter[value].push(cur.toFilter());
      }

      return filter;
    },
    {},
  );
}
