import { computed } from '@ember/object';

import ModuleCMS from './module-cms';

export default class extends ModuleCMS {
  @computed()
  get canRead() {
    return this.canAccessNewMenu;
  }
}
