import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';
import { hash } from 'rsvp';

import Route from 'neuro-frontend/routes/authenticated';

export default class extends Route {
  @service auth

  @service features

  @service pageHeader

  beforeModel(...args) {
    super.beforeModel(...args);

    set(get(this, 'pageHeader'), 'title', get(this, 'i18n').t('title.header.desktop'));

    // loads the current user
    // we know we are authenticated so it should work
    return get(this, 'auth').load();
  }

  async model() {
    // in case the dashboards or the center haven't been loaded yet
    // (it usually happens after logging in)
    const store = get(this, 'store');
    const {
      dashboards,
    } = await hash({
      dashboards: get(this, 'auth.dashboards') || store.findAll('dashboard'),
    });
    const dashboard = dashboards.findBy('category', 'home');

    if (dashboard) {
      return hash({
        dashboard,
      });
    }

    return {};
  }
}
