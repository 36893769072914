import Component from '@ember/component';
import { computed } from '@ember/object';

export default class extends Component {
  @computed('filterType.format')
  get format() {
    return this.filterType && this.filterType.format
      ? this.filterType.format
      : 'YYYY-MM-DD';
  }
}
