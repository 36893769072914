/* eslint-disable max-statements */
import { set, get } from '@ember/object';
import { assign } from '@ember/polyfills';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

import Filter from 'neuro-frontend/utils/filter';
import parseFilters from 'neuro-frontend/utils/parse-filters';
import Route from 'neuro-frontend/routes/base';

export default class extends Route {
  @service auth

  @service can

  queryParams = false

  models = [
    'province',
  ]

  async model() {
    const store = get(this, 'store');
    const ctrl = this.controllerFor('residential.properties.index');
    const centerId = get(this, 'auth.centerId');
    const canReadAll = this.can.can('read all residential-development');
    const developmentsFilter = canReadAll
      ? {
        center_id: centerId,
      }
      : {
        staff_id: get(this, 'auth.user.id'),
      };

    const developments = await store.query(
      'residentialDevelopment',
      {
        filter: developmentsFilter,
      },
    );

    if (!ctrl.hasFilters()) {
      if (!canReadAll) {
        set(
          ctrl,
          'defaultFilter',
          assign(
            {},
            parseFilters(developments.map((development) => new Filter({
              operation: 'is',
              type: {
                value: 'residential_development',
              },
              values: {
                value: get(development, 'id'),
              },
            }))),
            {
              type: 'or',
            },
          ),
        );
      }
      ctrl.resetFilters();
    }

    return hash({
      residentialProperties: ctrl.loadCollection(),
      residentialDevelopments: developments,
    });
  }
}
