import Service from '@ember/service';
import { computed } from '@ember/object';

export default class extends Service {
  host = ''

  namespace = ''

  @computed('host', 'namespace')
  get route() {
    if (!this.host && !this.namespace) {
      return '';
    }

    return `${this.host}/${this.namespace}`;
  }
}
