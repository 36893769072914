export default [
  {
    name: 'Coupon percent',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Coupon percent tooptip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
  {
    name: 'Name',
    initialValue: '1',
    currentValue: '4',
    tooltip: 'Tooltip',
  },
];
