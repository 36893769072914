import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

export default [
  {
    groupName: i18n.t('commerce-stay-alert.filters'),
    options: [
      {
        label: i18n.t('alert-type'),
        model: [{
          label: i18n.t('cms.commerce-stay-alert.stays-full'),
          value: 'stays_full',
        },
        {
          label: i18n.t('cms.commerce-stay-alert.correction'),
          value: 'correction',
        }],
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'alert-type',
      },

      {
        label: i18n.t('date'),
        operation: new FilterBetween({
          appendDates: 'Datetime',
        }),
        value: 'created-at',
      },

      {
        label: i18n.t('commerce'),
        model: 'commerces',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'commerce',
      },
    ],
  },
];
