export default Object.freeze([
  {
    actionType: 'sent_marketing_info',
    color: '#ADD8E6',
    label: i18n.t('residential-offer.open'),
    type: 'opening',
    value: 'open',
  },
  {
    actionType: 'reservation',
    color: '#80cbc4',
    label: i18n.t('residential-offer.accepted'),
    type: 'closing',
    value: 'accepted',
  },
  {
    actionType: 'rejection',
    color: '#EF9A9A',
    label: i18n.t('residential-offer.rejected'),
    type: 'closing',
    value: 'rejected',
  },
  {
    actionType: 'signed',
    color: '#b39ddb',
    label: i18n.t('residential-offer.signed'),
    type: 'signing',
    value: 'signed',
  },
  {
    color: '#ffcc80',
    label: i18n.t('residential-offer.pending'),
    value: 'pending',
  },
]);
