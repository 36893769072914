import { Factory, association } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  depreciation() {
    return faker.random.number();
  },
  employeeCost() {
    return faker.finance.amount();
  },
  employeeHours() {
    return faker.random.number();
  },
  exercise() {
    return faker.random.number();
  },
  franchisee() {
    return faker.random.boolean();
  },
  franchiseeExpenses() {
    return faker.finance.amount();
  },
  otherExpenses() {
    return faker.finance.amount();
  },
  qInStudy() {
    return faker.random.arrayElement([
      'q1',
      'q2',
      'q3',
      'q4',
    ]);
  },
  qSales() {
    return faker.finance.amount();
  },
  sales() {
    return faker.finance.amount();
  },
  salesCost() {
    return faker.finance.amount();
  },
  supplies() {
    return faker.finance.amount();
  },

  bonusShop: association(),
});
