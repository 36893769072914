export default Object.freeze([
  {
    label: i18n.t('singular'),
    value: 'singular',
  },
  {
    label: i18n.t('branded_residential'),
    value: 'branded_residential',
  },
]);
