import DS from 'ember-data';

export default DS.NumberTransform.extend({
  deserialize(serialized) {
    return this._super(serialized);
  },

  serialize(deserialized) {
    const transformed = this._super(deserialized);
    return transformed || 0;
  },
});
