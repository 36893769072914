import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { action, computed, set } from '@ember/object';

import { inject as service } from '@ember/service';

import { getFileExtension } from 'neuro-frontend/utils/file-extension';

@classNames('nf-input-file')
export default class NfInputFileComponent extends Component.extend({
    maxSize: '20 MB',
  }) {
  @service apiFiles

  errorFile = false

  @computed('accept')
  get validExtensions() {
    const ext = [];
    if (this.accept && 'string' === typeof this.accept) {
      const extensions = this.accept.split(',').filter((e) => e);
      extensions.forEach((e) => {
        ext.push(e.substr(1, e.length));
      });
    }
    return ext;
  }

  @action
  async addFile(ev) {
    if (ev.target.files) {
      const file = ev.target.files[0];

      set(this, 'errorFile', false);

      if (this.hasValidExtension(file)) {
        if ('function' === typeof this.onChange) {
          this.onChange(file);
        } else {
          this.uploadFile(file);
        }
      } else {
        set(this, 'errorFile', i18n.t('error.file.type.not_allowed'));
      }
    }
  }

  async uploadFile(file) {
    const {
      file_url: fileUrl,
      image_url: imageUrl,
      error: errorUploadFile,
    } = await this.apiFiles.uploadFile(file, this.isPublic);

    if (errorUploadFile) {
      set(this, 'errorFile', i18n.t('error.file.not_allowed'));
    }
    set(this, 'value', fileUrl || imageUrl);
  }

  @action
  removeFile() {
    // @TODO upgrade for files list
    set(this, 'value', null);
  }

  hasValidExtension(file) {
    const fileName = file.name;
    return (!this.validExtensions.length
        || this.validExtensions.includes(getFileExtension(fileName))
    );
  }
}
