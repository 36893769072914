import FilterOperation from '../filter-operation';

export default class FilterOrMore extends FilterOperation {
  options = [
    {
      label: i18n.t('or_more'),
      value: 'or_more',
    },
    {
      label: i18n.t('or_less'),
      value: 'or_less',
    },
  ]
}
