import Component from '@ember/component';
import { computed } from '@ember/object';

export default class extends Component {
  classNames = ['nf-wizard-tab', 'tab-content']

  classNameBindings = ['isCurrentStep::hide']

  @computed('currentStep', 'stepId')
  get isCurrentStep() {
    const {
      currentStep,
      stepId,
    } = this;
    return Number(currentStep) === Number(stepId);
  }
}
