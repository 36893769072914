import MdInput from 'ember-cli-materialize/components/md-input';
import { alias } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { computed, action, set } from '@ember/object';

import formatDatetime from 'neuro-frontend/utils/dates/format-date';

@classNames('nf-input-datetime', 'input-field')
export default class extends MdInput.extend({
    format: 'HH:mm:ss DD/MM/YYYY',
  }) {
  classNameBindings = ['isDisabled:nf-input-datetime--is-disabled']

  @alias('disabled') isDisabled

  @computed('value')
  get date() {
    const {
      value,
    } = this;

    return value
      ? new Date(formatDatetime(value))
      : null;
  }

  @action
  changeDate(selectedDate) {
    set(this, 'value', selectedDate[0]);
  }
}
