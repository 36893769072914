import Component from '@ember/component';
import { action, get, set } from '@ember/object';

import { pluralize } from 'ember-inflector';

import ListMixin from 'neuro-frontend/mixins/nf-list';

export default class extends Component.extend(ListMixin, {}) {
  init(...args) {
    super.init(...args);

    set(this, `model.${pluralize(this.modelName)}`, []);
    set(this, `${this.modelName}`, {});
    set(this, 'collection', []);
    this.loadCollection();
    this.reset();
  }

  @action
  cancel() {
    get(this, `${this.modelName}`).rollbackAttributes();
    this.reset();
  }

  @action
  edit(model) {
    if (!model || !get(model, 'id')) {
      throw new TypeError(`'model' is not a valid ${get(this, 'modelName')}`);
    }
    set(this, `${this.modelName}`, model);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  @action
  reload() {
    this.reset();
    this.loadCollection();

    if ('function' === typeof this.onUpdate) {
      this.onUpdate(this.collection);
    }
  }

  @action
  reset(defaultModel) {
    const store = get(this, 'store');
    const newModel = store.createRecord(
      `${this.modelName}`,
      defaultModel || this.defaultModel,
    );
    set(this, `${this.modelName}`, newModel);
  }

  async loadCollection(...args) {
    await super.loadCollection(...args);

    this.collection.clear();
    this.collection.pushObjects(get(this, `model.${pluralize(this.modelName)}`));
  }
}
