import Model from 'ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';
import { attr, belongsTo } from '@ember-data/model';

export default class extends Model.extend(Validator, {}) {
  @attr('string') code

  @attr('string') label

  // Relationships
  @belongsTo('center') center
}
