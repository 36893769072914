import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  usefulSurface() {
    return faker.random.number();
  },

  price() {
    return faker.random.number();
  },

  residentialRooms() {
    return [];
  },

  // dashed associations doesn't work with ember-cli-mirage#0.3.4
  // https://github.com/samselikoff/ember-cli-mirage/pull/1170
  residentialDevelopment: association(),
});
