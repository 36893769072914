import AbilityModule from './module';

export default class extends AbilityModule {
  moduleName = 'residential'

  roles = [
    'admin',
    'manager',
    'crmmanager',
  ]
}
