export default function communicationStates() {
  return [
    {
      label: i18n.t('communication.state.pending'),
      value: 'pending',
      icon: 'access_time',
      color: '',
    },
    {
      label: i18n.t('communication.state.accepted'),
      value: 'accepted',
      icon: 'check_circle',
      color: 'blue',
    },
    {
      label: i18n.t('communication.state.rejected'),
      value: 'rejected',
      icon: 'not_interested',
      color: 'red',
    },
    {
      label: i18n.t('communication.state.solved'),
      value: 'solved',
      icon: 'tag_faces',
      color: 'green',
    },
  ];
}
