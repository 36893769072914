import Model from 'ember-data/model';
import { attr } from '@ember-data/model';

export default class extends Model {
  @attr('base64') file

  @attr('string') name

  @attr('number') centerId
}
