import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import ModuleIntranet from './module-intranet';

export default class extends ModuleIntranet {
  @service auth

  roles = [
    'admin',
    'manager',
    'commerce',
    'storeManager',
    'scmanager',
    'services',
  ]

  @computed('auth.center', 'auth.user.role')
  get canRead() {
    return this.canAccess;
  }

  @computed('auth.center', 'auth.user.role')
  get canResend() {
    const {
      auth: {
        user: {
          role,
        },
      },
    } = this;

    return this.canAccess
      && ['admin', 'manager'].includes(role);
  }
}
