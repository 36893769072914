import { set, getProperties, computed } from '@ember/object';

import moment from 'moment';
import IllegalArgumentError from '../errors/illegal-argument-error';

export default class Week {
  @computed('startDate')
  get weekNumber() {
    const {
      startDate,
    } = this;
    return `${moment(startDate).isoWeekYear()}${moment(startDate).isoWeek()}`;
  }

  @computed('startDate')
  get yearNumber() {
    const {
      startDate,
    } = this;
    return `${moment(startDate).isoWeekYear()}`;
  }

  constructor(date = new Date()) {
    if (!(date instanceof Date)) {
      throw new TypeError(`${date} isn't of type Date`);
    }

    if (Number.isNaN(date.getTime())) {
      throw new IllegalArgumentError('param "date" is an invalid Date');
    }

    set(this, 'startDate', moment(date).startOf('isoWeek').format('YYYY-MM-DD'));
    set(this, 'endDate', moment(date).endOf('isoWeek').format('YYYY-MM-DD'));
    set(this, 'previousStartDate', moment(date).subtract(1, 'year').startOf('isoWeek').format('YYYY-MM-DD'));
    set(this, 'previousEndDate', moment(date).subtract(1, 'year').endOf('isoWeek').format('YYYY-MM-DD'));
  }

  subtract(n, str) {
    const {
      startDate,
    } = this;

    return new Week(new Date(moment(startDate, 'YYYY-MM-DD').subtract(n, str)));
  }

  add(n, str) {
    const {
      startDate,
    } = this;

    return new Week(new Date(moment(startDate, 'YYYY-MM-DD').add(n, str)));
  }

  isBefore(anotherWeek) {
    const {
      startDate,
    } = this;
    return moment(startDate).isBefore(anotherWeek.startDate, 'week');
  }

  isSame(anotherWeek) {
    const {
      startDate,
    } = this;
    return moment(startDate).isSame(anotherWeek.startDate, 'week');
  }

  isInWeek(date) {
    const {
      startDate,
      endDate,
    } = getProperties(
      this,
      ['startDate', 'endDate'],
    );
    return date >= startDate && date <= endDate;
  }

  getWeekDay(weekDay) {
    const date = moment(this.startDate).isoWeekday(weekDay);

    return new Date(date.format('YYYY-MM-DD'));
  }

  getWeekYear(weekyear) {
    const date = moment(this.startDate).isoWeeks(weekyear);

    return new Week(new Date(date.format('YYYY-MM-DD')));
  }
}
