import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  sliderColors: ['#445963', '#708690', '#1b3039'],
  headData: service(),
  session: service(),
  auth: service(),
  pageHeader: service(),
});
