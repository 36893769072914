import { get, computed } from '@ember/object';

import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('string') code

  @attr('date') date

  @attr('number') price

  @attr('string') salesman

  @attr('string', { defaultValue: 'redeemable' }) state

  @belongsTo('commerce') commerce

  @belongsTo('user') user

  @hasMany('campaign') campaigns

  @computed('state')
  get isRedeemed() {
    return 'redeemed' === this.state;
  }

  @computed()
  get validations() {
    return {
      date: {
        presence: {
          message: get(this, 'i18n').t('error.tickets.date.required'),
        },
      },
      price: {
        presence: {
          message: get(this, 'i18n').t('error.tickets.amount.required'),
        },
      },
      commerce: {
        presence: {
          message: get(this, 'i18n').t('error.tickets.commerce.required'),
        },
      },
    };
  }
}
