import {
  set, get, getProperties, computed,
} from '@ember/object';

import 'number.isinteger';
import moment from 'moment';

import IllegalArgumentError from '../errors/illegal-argument-error';
/**
 * Month
 * --
 *
 * A class that represents an internal month. It needs a Date and it extracts
 * their month metadata.
 *
 * It handles the following data:
 * - id -         created as YYYYMM of the Month.
 * - start_date - first day of the month at 00:00:00 hour in RFC3339.
 * - end_date -   first day of the following month at 00:00:00 hour in RFC3339.
 * - label -      user readable string that identifies the Month.
 * - value -      internal identifier of the Month.
 *
 * @class     Month
 * @namespace NF
 * @extends   Ember.Object
 * @public
 */
// https://github.com/offirgolan/ember-light-table/blob/master/addon/classes/Column.js#L13
export default class Month {
  @computed('value')
  get endDate() {
    return moment(this.value, 'YYYY-MM')
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  @computed('value')
  get endDatetime() {
    return moment(this.value, 'YYYY-MM')
      .endOf('month')
      .format();
  }

  @computed('value')
  get id() {
    return this.value.replace('-', '');
  }

  @computed('value')
  get name() {
    const {
      startDate,
    } = this;
    return moment(startDate).locale('en').format('MMMM');
  }

  @computed('value')
  get startDate() {
    return moment(this.value, 'YYYY-MM')
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  @computed('value')
  get startDatetime() {
    return moment(this.value, 'YYYY-MM')
      .startOf('month')
      .format();
  }

  addMonths(months) {
    if (!Number.isInteger(months)) {
      throw new TypeError(`addMonths: parameter ${months} isn't an Integer`);
    }

    set(
      this,
      'value',
      moment(get(this, 'value'), 'YYYY-MM')
        .add(months, 'M')
        .format('YYYY-MM'),
    );
  }

  addYears(years) {
    if (!Number.isInteger(years)) {
      throw new TypeError(`addYears: parameter ${years} isn't an Integer`);
    }

    set(
      this,
      'value',
      moment(get(this, 'value'), 'YYYY-MM')
        .add(years, 'y')
        .format('YYYY-MM'),
    );
  }

  toString() {
    return moment(get(this, 'value'), 'YYYY-MM')
      .format('MMMM YYYY');
  }

  constructor(date = new Date()) {
    if (!(date instanceof Date)) {
      throw new TypeError(`${date} isn't of type Date`);
    }

    if (Number.isNaN(date.getTime())) {
      throw new IllegalArgumentError('param "date" is an invalid Date');
    }

    set(this, 'value', moment(date).format('YYYY-MM'));
  }

  subtract(n, str) {
    const {
      startDate,
      endDate,
    } = getProperties(
      this,
      ['startDate', 'endDate'],
    );

    return {
      startDate: moment(startDate, 'YYYY-MM-DD')
        .subtract(n, str)
        .format('YYYY-MM-DD'),
      endDate: moment(endDate, 'YYYY-MM-DD')
        .subtract(n, str)
        .format('YYYY-MM-DD'),
    };
  }

  add(n, str) {
    const {
      startDate,
    } = this;

    return new Month(new Date(moment(startDate, 'YYYY-MM').add(n, str)));
  }

  isSame(anotherMonth) {
    const {
      startDate,
    } = this;
    return moment(startDate).isSame(anotherMonth.startDate, 'month');
  }

  isAfter(anotherMonth) {
    const {
      startDate,
    } = this;
    return moment(startDate).isAfter(anotherMonth.startDate, 'month');
  }
}
