import { layout } from '@ember-decorators/component';

import template from 'neuro-frontend/templates/components/nf-chart-timeseries';

import NfChartTimeseries from './nf-chart-timeseries';

@layout(template)
export default class extends NfChartTimeseries {
  type = 'area-spline'
}
