import RSVP from 'rsvp';

export default function loadScriptResource(uniqueName, path) {
  return new RSVP.Promise((resolve, reject) => {
    if (document.getElementById(uniqueName)) {
      resolve();
    }

    const element = document.createElement('script');
    element.async = true;
    element.id = uniqueName;
    element.src = path;
    element.type = 'text/javascript';
    element.addEventListener('load', () => resolve());
    element.addEventListener('error', () => reject(`Failed to load ${uniqueName} (${path})`));
    document.getElementsByTagName('head')[0].appendChild(element);
  }, `Loading external script resource ${uniqueName} (${path})`);
}
