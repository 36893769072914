import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

import propertyFilters from 'neuro-frontend/enums/property-filters';
import beautifyFilters from 'neuro-frontend/utils/beautify-filters';

export default class extends Component {
  classNames = ['search-interests-container']

  showAdvancedSearch = false

  @service auth

  @computed('auth.center')
  get propertyFilters() {
    const center = get(this, 'auth.center');

    return beautifyFilters(propertyFilters, center);
  }
}
