export default Object.freeze([
  {
    label: i18n.t('single_family'),
    value: 'single_family',
  },
  {
    label: i18n.t('multi_family'),
    value: 'multi_family',
  },
  {
    label: i18n.t('both'),
    value: 'both',
  },
]);
