import EmberObject, { get, set, computed } from '@ember/object';

/**
 * @module    nf
 * @submodule nf-utils
 */
export default class FilterOperation extends EmberObject.extend({
  baseValues: [
    {
      type: 'text',
      value: 'value',
    },
  ],
}) {
  appendDates = false

  options = []

  separator = i18n.t('and')

  @computed('appendDates')
  get appendedDates() {
    const {
      appendDates,
    } = this;

    if ('Date' === appendDates || 'Datetime' === appendDates) {
      return [
        {
          type: appendDates.toLowerCase(),
          value: 'start_date',
        },
        {
          type: appendDates.toLowerCase(),
          value: 'end_date',
        },
      ];
    }

    return [];
  }

  @computed('baseValues')
  get values() {
    return this.baseValues.concat(get(this, 'appendedDates'));
  }

  set values(value) {
    set(this, 'baseValues', value);

    return value.concat(get(this, 'appendedDates'));
  }

  constructor({
    appendDates,
    onlyPositive,
    values,
    ...rest
  } = {}) {
    super({ ...rest });

    set(this, 'appendDates', appendDates);
    set(this, 'onlyPositive', onlyPositive);

    if (Array.isArray(values)) {
      set(this, 'values', values);
    }
  }
}
