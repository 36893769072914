import Service, { inject as service } from '@ember/service';

import { set } from '@ember/object';
import Pusher from 'pusher-js';

const event = (id) => `user:${id}:updated:signature_url`;

export default class extends Service {
  @service auth

  appKey = null

  channel = null

  env = null

  // this would need a refactoring about how we use pusher
  // but the refactoring should come the second (or the third) time
  // we need the library
  connectToChannel(userId, cb) {
    this.channel.bind(event(userId), ({ signature_url: signatureUrl }) => {
      cb(signatureUrl);
    });
  }

  disconnectToChannel(userId) {
    this.channel.unbind(event(userId));
  }

  initialize() {
    // maybe we need to add here appKey and env as parameters
    const pusher = new Pusher(this.appKey, {
      encrypted: true,
    });
    set(
      this,
      'channel',
      pusher.subscribe(`${this.env}-${this.auth.centerId}`),
    );
  }
}
