import Component from '@ember/component';
import {
  get, set, setProperties, action,
} from '@ember/object';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

import { alias } from '@ember/object/computed';
import moment from 'moment';

import Week from 'neuro-frontend/utils/week';
import formatDate from 'neuro-frontend/utils/dates/format-date';

export default class extends Component {
  @service auth

  @service stats

  @service store

  chartData = null

  classNames = ['dashboard-cms']

  @alias('fetchData.isRunning') isLoading

  didReceiveAttrs() {
    const week = new Week();

    setProperties(
      this,
      {
        startDate: formatDate(get(week, 'startDate')),
        endDate: formatDate(get(week, 'endDate')),
        previousStartDate: formatDate(get(week, 'previousStartDate')),
        previousEndDate: formatDate(get(week, 'previousEndDate')),
      },
    );

    get(this, 'fetchData').perform();
    get(this, 'fetchDownload').perform();
    get(this, 'fetchUsers').perform();
  }

  @restartableTask
  fetchData = function* () {
    const centerId = get(this, 'auth.centerId');
    const stats = get(this, 'stats');
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(centerId)],
      building_type: ['main'],
    });

    const data = yield hash({
      visits: hash({
        current: stats.getData(
          'statsactiva/visits',
          getQuery(get(this, 'startDate'), get(this, 'endDate')),
        ),
        previous: stats.getData(
          'statsactiva/visits',
          getQuery(get(this, 'previousStartDate'), get(this, 'previousEndDate')),
        ),
        perDay: stats.getData(
          'statsactiva/visits',
          {
            group_by: ['day'],
            ...getQuery(get(this, 'startDate'), get(this, 'endDate')),
          },
        ),
      }),
      userActivity: hash({
        current: stats.getData(
          'statsactiva/user_activity',
          getQuery(get(this, 'startDate'), get(this, 'endDate')),
        ),
        previous: stats.getData(
          'statsactiva/user_activity',
          getQuery(get(this, 'previousStartDate'), get(this, 'previousEndDate')),
        ),
        perDay: stats.getData(
          'statsactiva/user_activity',
          {
            group_by: ['day'],
            ...getQuery(get(this, 'startDate'), get(this, 'endDate')),
          },
        ),
      }),
    });
    // TODO refactor to a util function
    const mergeStats = ({ current, previous }) => {
      const newData = {};
      const currentData = current?.firstObject;
      const previousData = previous?.firstObject;

      if (currentData) {
        Object.entries(currentData).forEach(([key, value]) => {
          newData[key] = {
            current: value,
            previous: previousData ? previousData[key] : 0,
          };
        });
      }

      return newData;
    };

    const formatDataChart = (visits, users) => {
      const getHeaders = (startDate, endDate) => {
        const headers = [];
        let currentDate = startDate;
        while (moment(currentDate).isBefore(endDate)) {
          headers.push(moment(currentDate).format('YYYY-MM-DD'));
          currentDate = moment(currentDate).add(1, 'day').format();
        }
        return headers;
      };
      const headers = getHeaders(get(this, 'startDate'), get(this, 'endDate'));

      const visitsData = headers.map((date) => {
        const visit = visits?.find((v) => date === v.date);
        return visit ? visit.visits : 0;
      });

      const userActivityData = headers.map((date) => {
        const user = users.find((u) => date === u.date);
        return user ? user.active_users : 0;
      });

      if (!visitsData.length || !userActivityData.length) {
        return null;
      }

      const newChartData = [
        ['x', ...headers],
        ['Visits', ...visitsData],
        ['Active users', ...userActivityData],
      ];

      return newChartData;
    };

    set(
      this,
      'data',
      {

        ...mergeStats(data.userActivity),
        ...mergeStats(data.visits),
      },
    );

    set(
      this,
      'chartData',
      formatDataChart(data.visits.perDay, data.userActivity.perDay),
    );
  }

  @restartableTask
  fetchDownload = function* () {
    const centerId = get(this, 'auth.centerId');
    const stats = get(this, 'stats');
    const getQuery = (startDate, so) => ({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      center_id: Number(centerId),
      building_type: ['main'],
      platform: so,
    });
    const dataAndroid = yield hash({
      sales: hash({
        current: stats.getData(
          'appannie/sales',
          getQuery(get(this, 'startDate'), 'android'),
        ),
        previous: stats.getData(
          'appannie/sales',
          getQuery(get(this, 'previousStartDate'), 'android'),
        ),
      }),
    });
    const dataIos = yield hash({
      sales: hash({
        current: stats.getData(
          'appannie/sales',
          getQuery(get(this, 'startDate'), 'ios'),
        ),
        previous: stats.getData(
          'appannie/sales',
          getQuery(get(this, 'previousStartDate'), 'ios'),
        ),
      }),
    });

    const totalDownloads = {
      current: this.getDownloads(dataAndroid.sales).downloads.current
         + this.getDownloads(dataIos.sales).downloads.current,
      previous: this.getDownloads(dataAndroid.sales).downloads.previous
          + this.getDownloads(dataIos.sales).downloads.previous,
    };
    set(
      this,
      'appData',
      totalDownloads,
    );
  }

  @restartableTask
  fetchUsers = function* () {
    const centerId = get(this, 'auth.centerId');
    const store = get(this, 'store');
    const channelId = yield this.getChannelId(centerId);
    const filter = (startDate, endDate) => ({
      filter: {
        center_id: centerId,
        channels: [
          {
            op: 'is',
            value: channelId,
          },
        ],
        register_dates: [
          {
            end_date: endDate,
            op: 'between',
            start_date: startDate,
          },
        ],
      },
      page: {
        number: 1,
        size: 1,
      },
    });
    const data = yield hash({
      current: store.query(
        'user',
        filter(get(this, 'startDate'), get(this, 'endDate')),
      ).then((result) => (result.meta.page
        ? result.meta.page.total
        : 0)),
      previous: store.query(
        'user',
        filter(get(this, 'previousStartDate'), get(this, 'previousEndDate')),
      ).then((result) => (result.meta.page
        ? result.meta.page.total
        : 0)),
    });

    set(
      this,
      'installedApps',
      data,
    );
  }

  @action
  changeDates({
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
  }) {
    // @TODO check if empty entry param
    setProperties(
      this,
      {
        startDate,
        endDate,
        previousStartDate,
        previousEndDate,
      },
    );

    get(this, 'fetchData').perform();
    get(this, 'fetchDownload').perform();
    get(this, 'fetchUsers').perform();
  }

  @action
  refresh() {
    get(this, 'fetchData').perform();
    get(this, 'fetchDownload').perform();
    get(this, 'fetchUsers').perform();
  }

  getDownloads(sales) {
    const getDownloads = (data) => {
      if (!data.sales_list || !data.sales_list.length) {
        return 0;
      }
      return data.sales_list[0].units.product.downloads;
    };

    return {
      downloads: {
        current: getDownloads(sales.current),
        previous: getDownloads(sales.previous),
      },
    };
  }

  getChannelId(centerId) {
    return get(this, 'store').query('channel', {
      filter: {
        center_id: centerId,
      },
    }).then((result) => {
      const channel = result.findBy('name', 'App');
      return channel
        ? Number(get(channel, 'id'))
        : 0;
    });
  }
}
