import { isEmpty, isEqual } from '@ember/utils';
import { get } from '@ember/object';

import fileRoles from 'neuro-frontend/enums/intranet-file-roles';
import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';
import IllegalStateError from 'neuro-frontend/errors/illegal-state-error';

/**
 *
 * It returns a list of roles which the `level` can contact through the module
 * of files.
 *
 * @method  getRolesByLevel
 * @param  {String} level   Internal database value of the actual role of the
 *                          staff member.
 * @return {Array[Object]}  An Array of Objects with the available roles.
 * @throws {Error}          If `level` isn't a valid type or it doesn't have
 *                          access to the file list.
 */
export function getRoleListByLevel(level) {
  return fileRoles.filter((role) => role.levels.includes(level));
}
/**
 *
 * It returns a label from the role associated.
 *
 * @method  getLabelByRole
 * @param  {String} role    Internal database value of the actual role of the
 *                          file role.
 * @return {String}         A string with the label.
 * @throws {Error}          If `role` is not a valid role.
 */
export function getLabelByRole(roleValue) {
  if ('string' !== typeof roleValue) {
    throw new TypeError(`getLabelByRole(): param 'role' with value ${roleValue} invalid`);
  }

  const roleFound = fileRoles.find((role) => roleValue === role.value);

  if (!roleFound) {
    return '';
  }
  return roleFound.label;
}

function checkCenterIdExist(centerId, fname) {
  if (!centerId || 1 > centerId) {
    throw new IllegalArgumentError(`${fname}()): parameter 'centerId' with value ${centerId} is not valid`);
  }
}

function checkFileErrors(file, fname) {
  if (isEmpty(file)) {
    throw new IllegalArgumentError(`${fname}(): parameter 'file' is empty`);
  }
  if (get(file, 'roles.isEmpty')) {
    throw new IllegalArgumentError(`${fname}(): parameter 'file.roles' with value ${file.roles} is not valid`);
  }
  if (get(file, 'creator_id.isEmpty')) {
    throw new IllegalArgumentError(`${fname}(): parameter 'file.creator_id' is empty`);
  }
  if ('commerce' === get(file, 'roles')) {
    if (get(file, 'commerce_ids.isEmpty')) {
      throw new IllegalStateError(`${fname}(): parameter 'file.commerce_ids' with value ${file.commerce_ids} is not valid`);
    }
  }
}

function checkCanSeeFileErrors(user, file, fname) {
  checkFileErrors(file, fname);
  if (isEmpty(user)) {
    throw new IllegalArgumentError(`${fname}(): parameter 'user' is empty`);
  }

  if ('commerce' === file.get('roles') && 'commerce' === user.get('level')) {
    if (!user.get('commerce_ids')) {
      throw new IllegalStateError(`${fname}(): parameter 'user.commerce_ids' with value ${user.get('commerce_ids')} is not valid`);
    }
  }
}

function isSharingStores(userStores, fileStores) {
  let isSharing = false;
  fileStores.forEach((commerceId) => {
    if (userStores.includes(commerceId)) isSharing = true;
  });
  return isSharing;
}
/**
 *
 * It returns true if a user can see a file.
 *
 * @method  canSeeFile
 * @param  {Object} user    A model staff member.
 * @param {Object} file     A model file
 * @param number centerId   The center id
 * @return {boolean}        True if the current user has read permissions
 * @throws {Error}          If centerId, file, user or any of their required properties
                              are missing.
 */
export function canSeeFile(user, file, centerId) {
  checkCanSeeFileErrors(user, file, 'canSeeFile');
  checkCenterIdExist(centerId, 'canSeeFile');

  if (Number(centerId) !== Number(file.get('center_id'))) {
    // with diff center, only admin level can see the file
    return 'admin' === user.get('level');
  }
  if ('all' === file.get('roles')) return true;
  if (['admin', 'manager', 'scmanager'].includes(user.get('level'))) return true;
  if ('owner' === file.get('roles') && 'owner' === user.get('level')) return true;
  if ('commerce' === file.get('roles') && 'commerce' === user.get('level')) {
    return isSharingStores(user.get('commerce_ids'), file.get('commerce_ids'));
  }
  return false;
}
/**
 *
 * It returns true if a user can edit a file.
 *
 * @method  canEditFile
 * @param  {Object} user    A model staff member.
 * @param {Object} file     A model file
 * @param number centerId   The center id
 * @return {boolean}        True if the current user has edit permissions
 * @throws {Error}          If centerId, file, user or any of their required properties
                              are missing.
 */
export function canEditFile(user, file, centerId) {
  checkCanSeeFileErrors(user, file, 'canEditFile');
  checkCenterIdExist(centerId, 'canEditFile');

  return canSeeFile(user, file, centerId) && (
    'admin' === user.get('level')
    || 'manager' === user.get('level')
    || isEqual(user, file.get('creator_id'))
  );
}
/**
 *
 * It returns true if a user can create a folder.
 *
 * @method  canCreateFolder
 * @param  {string} level   A staff member level.
 * @return {boolean}        True if the current user has creation folder permissions
 * @throws {Error}          If `level` isn't a valid type or it doesn't have
 *                          access to the file list.
 */
export function canCreateFolder(level) {
  return ['manager', 'admin', 'scmanager'].includes(level);
}
