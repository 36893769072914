import { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import Model from 'ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('number') rating

  @belongsTo('campaign') campaign

  @belongsTo('supplier') supplier

  @computed()
  get validations() {
    return {
      rating: {
        presence: {
          message: i18n.t('required', { attr: i18n.t('rating') }),
        },
      },
    };
  }
}
