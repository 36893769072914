import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import ListMixin from 'neuro-frontend/mixins/list';
import SegmentFiltersMixin from 'neuro-frontend/mixins/segment-filters';

const MixinController = Controller.extend(ListMixin, SegmentFiltersMixin, {});

export default class extends MixinController {
    @service() auth

    modelName = 'residentialOwner'

    @action
    editResidentialOwner(model) {
      if (!model || !model.get('id')) {
        throw new TypeError('`model` is not a valid residential-owner');
      }

      this.transitionToRoute('residential.owners.edit', model.get('id'));
    }

    @action
    deleteResidentialOwner(model) {
      if (!model || !model.get('id')) {
        throw new TypeError('`model` is not a valid residential-owner');
      }

      return model.destroyRecord();
    }
}
