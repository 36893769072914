import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import Cell from 'ember-light-table/components/cells/base';
import { Column } from 'ember-light-table';

import currencies from 'neuro-frontend/enums/currencies';

export default Cell.extend({
  column: new Column({ currency: 'EUR' }),

  // @TODO decorator for alias isn't working :(
  currency: alias('column.currency'),

  symbol: computed('currency', function () {
    const currency = get(this, 'currency');
    const currencyFound = currencies.findBy('value', currency);
    return currencyFound
      ? get(currencyFound, 'symbol')
      : '€';
  }),
});
