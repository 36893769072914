import { get, computed } from '@ember/object';

import { CanMixin } from 'ember-can';

import TableList from 'neuro-frontend/components/table-list';

const MixinComponent = TableList.extend(CanMixin, {});

export default class extends MixinComponent {
  @computed()
  get columns() {
    return [
      {
        cellType: 'image',
        valuePath: 'logo',
        width: '8%',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('residential.owner.code'),
        valuePath: 'code',
      },
      {
        label: get(this, 'i18n').t('residential.owner.name'),
        valuePath: 'name',
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: get(this, 'i18n').t('edit'),
        sortable: false,
        align: 'center',
        width: '4%',
        cellClassNames: 'qa-edit-icon',
      },
      {
        label: '',
        cellAction: 'delete',
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: get(this, 'i18n').t('delete'),
        sortable: false,
        align: 'center',
        width: '4%',
        cellClassNames: 'qa-delete-icon',
        hidden: !this.can('delete residential-owner'),
      },
    ];
  }
}
