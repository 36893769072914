import { get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormElement from '../base/form-element';

export default class extends FormElement {
  @service router

  @service session

  // Fix bug Chrome that set values after render and labels doesn't go up. Force active labels.
  didRender() {
    document.getElementsByTagName('label')[0].classList.add('active');
    document.getElementsByTagName('label')[1].classList.add('active');
  }

  @action
  authenticate() {
    if (!get(this, 'loading')) {
      set(this, 'loading', true);
      const email = get(this, 'email');
      const pass = get(this, 'pass');
      get(this, 'session')
        .authenticate('authenticator:custom', email, pass)
        .catch(({ errors }) => {
          const error = errors[0];

          set(this, 'apiErrors', error.detail);
          set(this, 'loading', false);

          if ('403' === error.status) {
            get(this, 'router').transitionTo('user.reset-password');
          }
        });
    }
  }
}
