import { hash } from 'rsvp';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import Route from 'neuro-frontend/routes/base';

export default class ConfigurationStaffMembersIndexRoute extends Route {
  @service pageHeader

  model() {
    const controller = this.controllerFor('configuration.staff-members.index');
    return hash({
      staffMembers: controller.loadCollection(),
    });
  }

  setupController(ctrl, model) {
    super.setupController(ctrl, model);

    set(this, 'pageHeader.action.route', 'configuration.staff-members.new');
    set(this, 'pageHeader.tooltip', i18n.t('staff-members.new'));
  }
}
