import { hash } from 'rsvp';

import BaseRoute from 'neuro-frontend/routes/base';

export default class extends BaseRoute {
  templateName = 'configuration/dashboards/new';

  async model({ dashboard_id: dashboardId }) {
    const { store } = this;

    const dashboard = dashboardId
      ? await store.findRecord(
        'dashboard',
        dashboardId,
      )
      : store.createRecord('dashboard');

    return hash({
      dashboard,
    });
  }

  setupController(ctrl, model) {
    super.setupController(ctrl, model);
  }
}
