import moment from 'moment';
import EmberObject, { get } from '@ember/object';
import { typeOf } from '@ember/utils';

export default class Filter extends EmberObject.extend({
  values: {},
}) {
  toFilter() {
    if (!get(this, 'operation')) {
      return null;
    }

    if (!Object.keys(get(this, 'values')).length) {
      return get(this, 'operation');
    }

    return {
      op: get(this, 'operation'),
      ...Object.entries(get(this, 'values'))
        .reduce(
          (prev, [key, value]) => ({

            ...prev,
            [key]: ('date' === typeOf(value))
              ? moment(value).format('YYYY-MM-DDTHH:mm:ssZ')
              : value,
          }),
          {},
        ),
    };
  }
}
