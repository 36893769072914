import Component from '@ember/component';
import { set, action } from '@ember/object';

import { classNames } from '@ember-decorators/component';

@classNames('nf-autocomplete')
export default class extends Component.extend({
    noMatchesMessage: i18n.t('not-found'),
  }) {
  @action
  clear() {
    set(this, 'selected', null);
  }

  @action
  search(term) {
    // @TODO deprecate this.search
    const {
      onSearch,
    } = this;

    if ('function' === typeof onSearch) {
      return onSearch(term);
    }

    return Promise.resolve();
  }
}
