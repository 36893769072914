import { hash } from 'rsvp';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import Route from 'neuro-frontend/routes/base';

export default class ConfigurationEmailCampaignsIndexRoute extends Route {
  @service pageHeader

  model() {
    const controller = this.controllerFor('emailing.campaigns.index');
    return hash({
      emailCampaigns: controller.loadCollection(),
    });
  }

  setupController(ctrl, model) {
    super.setupController(ctrl, model);

    set(this, 'pageHeader.action.route', 'emailing.campaigns.new');
    set(this, 'pageHeader.tooltip', i18n.t('campaigns.new'));
  }
}
