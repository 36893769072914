import { set, get } from '@ember/object';
import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize(serialized) {
    return serialized;
  },

  serialize(deserialized) {
    const serializedRooms = deserialized
      .filter((room) => Object.keys(room).length)
      .map((room) => {
        set(room, 'surface', Number(get(room, 'surface')));
        return room;
      });
    return serializedRooms;
  },
});
