import $ from 'jquery';
import { alias } from '@ember/object/computed';
import { get, action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { typeOf } from '@ember/utils';
import MdNavbar from 'ember-cli-materialize/components/md-navbar';

import layout from 'neuro-frontend/templates/components/nf-navbar';
import getSideNavMenu from 'neuro-frontend/utils/get-side-nav-menu';
import staffLevels from 'neuro-frontend/utils/staff-levels';
import loadScriptResource from 'neuro-frontend/utils/external-script-loader';

export default class extends MdNavbar {
  @service auth

  @service can

  @service headData;

  classNames = ['nf-navbar']

  layout = layout

  staffLevel = ''

  @alias('auth.user') currentUser

  _isFrogedConfigSetted = false;

  @computed('auth.center', 'auth.user.role')
  get menu() {
    const center = get(this, 'auth.center');
    const role = get(this, 'auth.user.role');

    if (!center || !role || !staffLevels().filterBy('value', role).length) {
      return [];
    }
    const filterByLevel = (section) => section.levels.includes(role);
    const filterByModule = (section) => (
      !section.module
        || get(center, `has${(section.module).capitalize()}`)
    )
      && !get(center, `has${(String(section.disabledByModule)).capitalize()}`);
    // use abilities instead of
    const filter = (section) => {
      if (section.ability) {
        return this.can.can(section.ability);
      }

      return filterByLevel(section) && filterByModule(section);
    };

    const dashboardsList = get(this, 'auth.user.dashboards').map((dashboard) => ({
      name: get(dashboard, 'name'),
      url: 'analytics.dashboards.show',
      modelId: get(dashboard, 'id'),
      component: 'NF',
      levels: [
        'admin',
        'owner',
        'manager',
        'commerce',
        'crmmanager',
        'storeManager',
        'scmanager',
      ],
    }));

    this._setFrogedConfig();

    return getSideNavMenu()
      .filter(filter)
      .map((section) => {
        let children = get(section, 'children');
        if ('menu.navbar.analytics' === get(section, 'id')) {
          children = children.concat(dashboardsList);
        }

        return {
          ...section,
          children: children.filter(filter),
        };
      });
  }

  didInsertElement(...args) {
    super.didInsertElement(...args);

    loadScriptResource('headwayappjs', '//cdn.headwayapp.co/widget.js');
  }

  @action
  logout() {
    const outerFunction = get(this, 'onLogout');
    const { Froged } = window;
    if (outerFunction) {
      outerFunction();
    }
    if (Froged) {
      Froged('logout');
    }
  }

  _setFrogedConfig() {
    const { Froged } = window;
    if (Froged && !this._isFrogedConfigSetted) {
      this._isFrogedConfigSetted = false;
      Froged('set', {
        userId: this.currentUser.gid,
        email: this.currentUser.email,
        name: this.currentUser.name,
        Empresa: this.headData.title,
        Centro: this.auth.center.name,
      });
    }
  }

  _setupNavbar() {
    if ('function' === typeOf($('.button-collapse').sideNav)) {
      this.notifyPropertyChange('_sideNavId');
      this.$('.button-collapse').sideNav();
      this.$('.collapsible').collapsible();
    }
  }
}
