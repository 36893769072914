import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

export default class extends Model.extend(Validator, {}) {
  @attr('string') address

  @attr('trimmed-string') city

  @attr('trimmed-string') name

  @attr('trimmed-string') type

  @attr('number') zipCode

  @belongsTo('center') center

  @belongsTo('province') province

  @hasMany('zone') zones
}
