// Inspirated in Google's Guava
// https://github.com/google/guava/wiki/PreconditionsExplained
function IllegalArgumentError(message) {
  this.name = 'IllegalArgumentError';
  this.message = (message || 'Invalid arguments');
  this.stack = new Error().stack;
}

IllegalArgumentError.prototype = Object.create(Error.prototype);

export default IllegalArgumentError;
