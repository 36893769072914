import Component from '@ember/component';
import { get, computed } from '@ember/object';

import Day from '../../utils/day';
/**
 * ## CalendarMonth.DayComponent
 *
 * ```hbs
 * {{calendar-month/day
 *   cellType=<String>
 *   day=<Day>
 *   month=<Month>
 *   rawValue=<Array>
 *   selectedDay=<Day>
 *   valuePath=<String>
 * }}
 * ```
 *
 * Where cellType should be a a valid component on calendar-month/value/<cellType>.
 * If there isn't an specific cellType, it should print the value as it is.
 *
 * @class DayComponent
 * @namespace NF
 * @extends Ember.Component
 * @public
 */
export default Component.extend({
  classNames: ['calendar-month__day'],

  classNameBindings: [
    'belongsToDifferentMonth:calendar-month__day--month_distinct',
    'belongsToDifferentMonth::calendar-month__day--month_current',
    'isCurrentDay:calendar-month__day--is_current',
    'isSelected:calendar-month__day--is_selected',
    'hasValue:calendar-month__day--has_value',
    'hasValue:qa-calendar__day--has-value',
  ],

  tagName: 'td',

  belongsToDifferentMonth: computed('day', 'month', function () {
    return (get(this, 'day') && get(this, 'month'))
      ? !get(this, 'day').belongsToMonth(get(this, 'month'))
      : false;
  }),

  isCurrentDay: computed('day', function () {
    return get(this, 'day.value') === get(new Day(), 'value');
  }),

  isSelected: computed('day', 'selectedDay', function () {
    return get(this, 'day.value') === get(this, 'selectedDay.value');
  }),

  hasValue: computed('value', function () {
    return (get(this, 'value') instanceof Array)
      ? get(this, 'value.length')
      : get(this, 'value');
  }),

  value: computed('rawValue', 'valuePath', function () {
    return (get(this, 'valuePath') && (get(this, 'rawValue') instanceof Array))
      ? get(this, 'rawValue').map((value) => get(value, get(this, 'valuePath')))
      : get(this, 'rawValue');
  }),
});
