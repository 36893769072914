import Component from '@ember/component';
import { set, get } from '@ember/object';

export default Component.extend({
  classNames: ['nf-confirm-modal'],

  isOpen: false,

  message: '',

  onConfirm: null,

  onCancel: null,

  closeModal() {
    set(this, 'isOpen', false);
  },

  actions: {
    confirm() {
      const outerFunction = get(this, 'onConfirm');
      this.toggleProperty('isOpen');
      if (outerFunction) {
        outerFunction();
      }
    },

    cancel() {
      const outerFunction = get(this, 'onCancel');
      this.toggleProperty('isOpen');
      if (outerFunction) {
        outerFunction();
      }
    },
  },
});
