import Controller from '@ember/controller';
import {
  get, set, action, computed,
} from '@ember/object';

import { inject as service } from '@ember/service';
import { filterBy } from '@ember/object/computed';
import { assign } from '@ember/polyfills';

import buildingStates from 'neuro-frontend/enums/building-states';
import currencies from 'neuro-frontend/enums/currencies';
import commercializations from 'neuro-frontend/enums/residential-commercializations';
import energyCertification from 'neuro-frontend/enums/energy-certification';
import callToActionTypes from 'neuro-frontend/enums/residential-development-call-to-action-types';
import managements from 'neuro-frontend/enums/residential-managements';
import unitTypes from 'neuro-frontend/enums/residential-unit-types';
import visibilities from 'neuro-frontend/enums/visibilities';
import housingTypes from 'neuro-frontend/enums/residential-housing-types';

export default class extends Controller {
  @service auth

  @service can

  @service nfMap

  @service store

  @service router

  buildingStates = buildingStates

  commercializations = commercializations

  currencies = currencies

  energyCertification = energyCertification

  housingTypes = housingTypes

  callToActionTypes = callToActionTypes

  managements = managements

  unitTypes = unitTypes

  visibilities = visibilities

  // @TODO refactor the queryParams system
  propertiesParams = {
    filter: {},
    page: {
      number: 1,
      size: 15,
    },
  }

  @filterBy('model.staffMembers', 'level', 'crmmanager') crmManagers

  @computed()
  get tabs() {
    const {
      t,
    } = i18n;

    return [
      {
        icon: 'location_city',
        label: t('general-data'),
        name: 'general',
      },
      {
        icon: 'description',
        label: t('thecnical-data'),
        name: 'technical',
      },
      {
        icon: 'photo_library',
        label: t('multimedia'),
        name: 'multimedia',
      },
      {
        icon: 'store_mall_directory',
        label: t('properties'),
        name: 'properties',
      },
      {
        icon: 'language',
        label: t('web'),
        name: 'web',
      },
    ];
  }

  @computed('model.languages')
  get steps() {
    return get(this, 'model.languages')
      .map((l, index) => ({
        stepId: 1 + index,
        label: l.name,
        language: l,
      }));
  }

  @action
  attachFiles(model, files, category = '') {
    if (!(files instanceof Array)) {
      throw new TypeError(`attachFiles: ${files} should be of type Array`);
    }

    const newFiles = files.map((file) => {
      set(file, 'category', category);

      return file;
    });

    if (!(get(model, 'files') instanceof Array)) {
      set(model, 'files', newFiles);
    } else {
      get(model, 'files').pushObjects(newFiles);
    }
  }

  @action
  changePropertiesParams(propertiesParams, field = null) {
    if (field && 'string' !== typeof field) {
      throw new TypeError(`${field} is not a string.`);
    }

    const newQueryParams = {};
    assign(
      newQueryParams,
      get(this, 'propertiesParams'),
      propertiesParams,
    );
    set(this, 'propertiesParams', newQueryParams);
    this.loadProperties();
  }

  @action
  changeVisibility() {
    const visibility = get(this, 'model.visibility');
    if ('bookable' === visibility) {
      set(this, 'model.webPermissions', {
        'residential-properties-show': true,
        'residential-properties-price-show': true,
        'residential-properties-details-show': true,
      });
    }
  }

  @action
  async createMarker() {
    const {
      model: { development },
      nfMap,
    } = this;
    const marker = await nfMap.createMarker(development.completeAddress);

    set(development, 'latitude', marker.lat);
    set(development, 'longitude', marker.lng);
  }

  @action
  deleteResidentialProperty(model) {
    if (!model || !model.get('id')) {
      throw new TypeError('`model` is not a valid residential-property');
    }

    return model.destroyRecord();
  }

  @action
  editResidentialProperty(model) {
    if (!model || !model.get('id')) {
      throw new TypeError('`model` is not a valid residential-property');
    }

    this.router.transitionTo('residential.properties.edit', model.get('id'));
  }

  @action
  removeFile(files, file) {
    files.removeObject(file);
  }

  @action
  saveDevelopment(saveAction) {
    if ('function' === typeof saveAction) {
      set(this, 'isLoading', true);
      saveAction()
        .finally(() => {
          set(this, 'isLoading', false);
          this.toggleProperty('isOpenModal');
        });
    }
  }

  @action
  searchCountries(term) {
    set(this, 'model.development.province', null);

    if (term && 2 <= term.length) {
      return get(this, 'store').query('country', {
        filter: {
          q: term,
        },
      });
    }
    return [];
  }

  @action
  searchProvince(term) {
    if (term && 2 <= term.length) {
      return get(this, 'store').query('province', {
        filter: {
          q: term,
          country_id: get(this, 'model.development.country.id'),
        },
      });
    }
    return [];
  }

  @action
  submitDevelopment() {
    const hasErrors = this.model.development.errors.length;

    if (!hasErrors) {
      const development = get(this, 'model.development');

      this.toggleProperty('isOpenModal');

      if (!this.can.can('modify staff residential-development')) {
        get(development, 'staff').pushObject(get(this, 'auth.user'));
      }
    }

    return false;
  }

  loadProperties() {
    set(this, 'model.isLoading', true);

    get(this, 'store')
      .query('residentialProperty', get(this, 'propertiesParams')).then((properties) => {
        set(this, 'model.properties', properties);
        set(this, 'model.isLoading', false);
      });
  }
}
