import Component from '@ember/component';
import { computed, get } from '@ember/object';
import IllegalArgumentError from '../errors/illegal-argument-error';

export default Component.extend({
  classNames: ['nf-paginator-jsonapi'],

  isPreviousDisabled: computed('page.current-page', 'page.total', function () {
    return !get(this, 'page.total') || 1 >= get(this, 'page.current-page');
  }),

  isNextDisabled: computed('page.current-page', 'page.total', 'page.last-page', function () {
    return !get(this, 'page.total')
    || get(this, 'page.current-page') === get(this, 'page.last-page');
  }),

  changePage(number) {
    if ('number' !== typeof number) {
      throw new TypeError('parameter `page` must be of type Number');
    }

    if (Number.isNaN(number) || 0 >= number) {
      throw new IllegalArgumentError(`parameter 'page' value ${number} isn't a valid Number`);
    }
    get(this, 'onChangedPage')({
      page: {
        number,
        size: get(this, 'page.per-page'),
      },
    });
  },

  actions: {
    nextPage(currentPage) {
      this.changePage(currentPage + 1);
    },

    previousPage(currentPage) {
      this.changePage(currentPage - 1);
    },
  },
});
