import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { translationMacro as t } from 'ember-i18n';

export default Component.extend({
  i18n: service(),

  classNames: ['nf-dropdown__cell--overflow'],

  content: null,

  onSelect: null,

  label: t('select_one_option'),

  noOptionsMessage: t('options_not_found'),

  selected: null,

  optionLabelPath: 'content',

  optionValuePath: 'content',

  icon: false,

  showTooltip: false,

  _prompt: computed('label', 'selected', function () {
    const labelPath = this._parsePath(get(this, 'optionLabelPath'));
    const selected = get(this, 'selected');
    if (selected) {
      return labelPath ? get(selected, labelPath) : selected;
    }
    return get(this, 'label');
  }),

  _parsedContent: computed(
    'optionLabelPath',
    'optionValuePath',
    'content.[]',
    function () {
      const optionValuePath = this._parsePath(get(this, 'optionValuePath'));
      const optionLabelPath = this._parsePath(get(this, 'optionLabelPath'));

      return (get(this, 'content') || []).map((option) => ({
        value: optionValuePath ? get(option, optionValuePath) : option,
        label: optionLabelPath ? get(option, optionLabelPath) : option,
      }));
    },
  ),

  _parsePath(path) {
    const contentRegex = /(content\.|^content$)/;
    return (path || '').replace(contentRegex, '');
  },

  didRender() {
    this.$('.dropdown-button').dropdown();

    if (get(this, 'showTooltip')) {
      this.$('.tooltipped').tooltip();
    }
  },

  actions: {
    select(option) {
      const valuePath = this._parsePath(get(this, 'optionValuePath'));
      const selectedOption = valuePath
        ? get(this, 'content').findBy(valuePath, option.value)
        : option.value;

      set(this, 'selected', selectedOption);

      if ('function' === typeof get(this, 'onChange')) {
        get(this, 'onChange')(selectedOption);
      }
    },
  },
});
