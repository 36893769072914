import Service, { inject as service } from '@ember/service';
import { set, get } from '@ember/object';

import { alias } from '@ember/object/computed';
import { hash, Promise } from 'rsvp';

import NoCookieError from 'neuro-frontend/errors/no-cookie-error';

/**
 * Service used to obtain parameters of authentication process such us cookies, current center id
 * or the current user logged id.
 */
export default class extends Service {
  @service raven

  @service session

  @service store

  user = null

  centers = null

  dashboards = null

  isAuthenticated = false;

  get centerId() {
    let cid;

    try {
      cid = this.getCookie('cid');
    } catch (error) {
      cid = '';
    }

    return cid;
  }

  set centerId(centerId) {
    // TODO use localStorage or session instead
    document.cookie = `cid=${centerId}; path=/; max-age=86400`;
  }

  get center() {
    return get(this, 'centers')
      ? get(this, 'centers').findBy('id', get(this, 'centerId'))
      : null;
  }

  set center(center) {
    set(this, 'centerId', get(center, 'id'));
  }

  @alias('user.centers') centersAvailable

  @alias('session.data.authenticated.token') token

  getCookie(name) {
    const regex = new RegExp(`${name}=([^;]+)`);
    const str = document.cookie.match(regex);

    if (!str || 2 > str.length) {
      throw new NoCookieError(`getCookie: ${name} is not available`);
    }
    return str[1];
  }

  changeCenter(centerId) {
    let currentCenter = null;

    if (!centerId) {
      throw new TypeError('changeCenter: invalid center ID');
    }
    currentCenter = get(this, 'centers').findBy('id', centerId);
    if (!currentCenter) {
      throw new TypeError('changeCenter: center not found');
    }
    set(this, 'centerId', centerId);

    // @TODO: legacy code deprecate when panel is migrated
    document.cookie = `cid=${centerId}; path=/; max-age=86400`;
  }

  isValidCenterId() {
    const centers = get(this, 'centers');
    const centerId = get(this, 'centerId');

    return centers && centers.findBy('id', centerId);
  }

  load() {
    const store = get(this, 'store');

    if (get(this, 'session.isAuthenticated')) {
      // loads the centers to have access to them (load them all)
      // but we use the ones related to the user becuase these are the centers
      // where the user has credentials
      return hash({
        centers: store.findAll('center'),
        dashboards: store.findAll('dashboard'),
        user: store.queryRecord('staff-member', { me: true }),
      }).then(async ({ user }) => {
        set(this, 'centers', get(user, 'centers'));
        set(this, 'dashboards', get(user, 'dashboards'));
        set(this, 'user', user);

        // TODO legacy code deprecate when panel is migrated
        // refresh cookie for WebActiva access
        document.cookie = `tkn=${get(this, 'token')}; path=/; max-age=86400`;
        this.changeCenter(this.isValidCenterId() ? this.centerId : get(this, 'centers.firstObject.id'));

        this.setRaven();
        // After we get the commerces of the current center to calculate
        // staff member role with precision.
        // We need to refactor this with the change of roles system.
        if ('commerce' === user.level) {
          await store.query(
            'commerce',
            {
              filter: {
                center_id: get(this, 'centerId'),
              },
            },
          );
        }

        set(this, 'isAuthenticated', true);

        return Promise.resolve();
      });
    }

    return Promise.reject();
  }

  setRaven() {
    get(this, 'raven').callRaven(
      'setUserContext',
      {
        id: get(this, 'user.id'),
        email: get(this, 'user.email'),
        role: get(this, 'user.level'),
        username: `${get(this, 'user.name')} ${get(this, 'user.lastName')}`,
      },
    );
  }
}
