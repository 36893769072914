import { Ability } from 'ember-can';
import { computed } from '@ember/object';

export default class extends Ability {
  @computed('canEdit')
  get canDelete() {
    return this.canEdit;
  }

  @computed('model.state')
  get canEdit() {
    return ['scheduled', 'draft'].includes(this.model.state);
  }

  @computed('model.state')
  get canStop() {
    return ['scheduled', 'active'].includes(this.model.state);
  }
}
