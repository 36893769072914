import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  name() {
    return faker.name.firstName();
  },

  lastName() {
    return faker.name.lastName();
  },

  bdate() {
    return faker.date.past();
  },

  notes() {
    return faker.random.words();
  },

  sex: null,

  relationship: null,

  user: association(),

  afterCreate(_child) {
    const child = _child;
    child.save();
  },
});
