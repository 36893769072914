import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';

@classNames('nf-breadcrumbs')
export default class extends Component {
  @computed('model', 'route')
  get collection() {
    const collection = [];
    let {
      model,
    } = this;

    if (model) {
      collection.unshift({
        id: get(model, 'id'),
        name: get(model, 'name'),
        route: `${this.route}.show`,
      });

      while (get(model, 'parent') && get(model, 'parent.id')) {
        model = get(model, 'parent');

        collection.unshift({
          id: get(model, 'id'),
          name: get(model, 'name'),
          route: `${this.route}.show`,
        });
      }
    }

    collection.unshift({
      route: this.route,
      name: i18n.t('breadcrumbs.main'),
    });

    return collection;
  }
}
