import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Component.extend({
  campaigns: [],

  currentStep: 1,
}) {
  @service api

  @service auth

  @service can

  @computed
  get loyaltyCardCampaigns() {
    return this.campaigns.filter((campaign) => campaign.isLoyaltyCard);
  }

  @computed
  get raffleCampaigns() {
    return this.campaigns.filter((campaign) => !campaign.isLoyaltyCard && campaign.isRaffle);
  }

  @computed
  get stepsInfo() {
    const steps = [];
    if (this.can.can('access to module-tickets')) {
      steps.push({
        label: i18n.t('ticket.raffle'),
      });
    }
    if (this.can.can('access to module-loyalty-cards')) {
      steps.push({
        label: i18n.t('ticket.loyalty-card'),
      });
    }
    return steps.map((step, i) => ({ ...step, stepId: (i + 1) }));
  }
}
