import { Ability } from 'ember-can';
import { computed } from '@ember/object';

export default class extends Ability {
  @computed('model.isRedeemed')
  get canRedeem() {
    const {
      model,
    } = this;

    return !model.isRedeemed;
  }
}
