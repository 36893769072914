export default Object.freeze([
  {
    color: '#e80042',
    icon: 'block',
    label: i18n.t('shopMonth.state.without_sales'),
    value: 'without_sales',
  },
  {
    color: '#f8b827',
    icon: 'content_paste',
    label: i18n.t('shopMonth.state.unclosed'),
    value: 'unclosed',
  },
  {
    color: '#2477b5',
    icon: 'check',
    label: i18n.t('shopMonth.state.closed'),
    value: 'closed',
  },
  {
    color: '#028a3d',
    icon: 'done_all',
    label: i18n.t('shopMonth.state.validated'),
    value: 'validated',
  },
]);
