import { inject as service } from '@ember/service';
import Component from '@ember/component';
import {
  get, set, action, computed,
} from '@ember/object';

export default class extends Component.extend({
  onChangeCenter: null,
}) {
  @service auth

  classNames = ['select-center']

  @computed('auth.center', 'auth.centerId')
  get centerSelected() {
    return get(this, 'auth.center');
  }

  set centerSelected(center) {
    return set(this, 'auth.center', center);
  }

  @computed('auth.centersAvailable', 'auth.user.level', 'auth.centers')
  get centers() {
    const centers = get(this, 'auth.centers');
    const availableCenters = get(this, 'auth.centersAvailable');
    const level = get(this, 'auth.user.level');

    return 'admin' === level ? centers : availableCenters;
  }

  @action
  changeCenter(center) {
    const centerId = center && center.id;

    if (!centerId) {
      return;
    }

    get(this, 'auth').changeCenter(centerId);

    if (this.onChangeCenter) {
      this.onChangeCenter();
    }
  }
}
