import { Factory, association } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  category: association(),

  appImage() {
    const file = new Blob(['logo'], { type: 'image/jpeg' });
    file.name = 'logo.jpg';
    return file;
  },

  endDate() {
    return faker.random.date;
  },

  startDate() {
    return faker.random.date;
  },
});
