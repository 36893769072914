import { get } from '@ember/object';
import { hash } from 'rsvp';

import Route from 'neuro-frontend/routes/residential/owners/new';

export default class extends Route {
  model(params) {
    const id = params['residential-owner_id'];
    return hash({
      residentialOwner: get(this, 'store').findRecord('residentialOwner', id),
    });
  }
}
