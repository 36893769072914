export default function (server) {
  const centers = server.createList('center', 1, { modules: '{"crm":{"play_center":"true","new-panel":"true","tickets":"true","loyalty_cards":"true","user_required":"","crm_config":"standard"},"marketing_analytics":{}}' });
  const commerces = server.createList('commerce', 20, { center: centers[0] });
  const campaignTypes = server.createList('campaigntype', 10, { center: centers[0] });

  server.createList('user', 10, { center: centers[0] });
  server.createList(
    'campaign',
    1,
    {
      crm: true,
      endDate: new Date('2035-01-01'),
      startDate: new Date('2019-01-01'),
      status: 'open',
      campaigntype: campaignTypes[0],
      commerces,
    },
  );

  return centers;
}
