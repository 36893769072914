import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';

export default class ExtrasModel extends Model {
  @attr('string') code

  @attr('boolean') channelWeb

  @attr('boolean') channelApp

  @attr('string') extraType

  @attr('string') linkedModel

  @attr('array') languages

  @belongsTo('center') center

  @computed('languages')
  get label() {
    const {
      languages,
    } = this;

    const language = languages.findBy('code', i18n.locale.slice(0, 2));
    return (language && language.label)
      ? language.label
      : languages.findBy('code').label;
  }
}
