/* eslint array-callback-return: "off", func-names: "off", max-statements: "off" */
import EmberRouter from '@ember/routing/router';
import config from './config/environment';

/**
 * @module    nf
 * @submodule nf-routing
 */

/**
 *
 * URLs management
 * --
 *
 * With the Router class we can define the different URLs of the WebApp, you
 * can get more information in the API of the Ember framework.
 *
 * In NeuroFrontend we're going to use a convention of the different URLs
 * of the system in order to make it easier to add a new resource.
 *
 * A proposal of the URL format can be like this:
 *
 * ```
 * /:module:/:model_name:/:action:
 * ```
 *
 * Where action can be one of the following:
 * - *index* - List with the collection model
 * - *new* - Form where we can create a new model
 * - *edit* - Form where we can edit a specific model
 * - *show* - May be useful if we need to show a specific model
 *
 * We must not confuse the URLs format with the different API points.
 *
 * If we need to add a different action for a model, we should use a
 * characteristic name to identify the resource. For example, if we need to
 * show a collection with the favourite proposals in the webApp a possible
 * solution of URL may be:
 *
 * ```
 *
 * @class     Router
 * @namespace NF
 * @extends   Ember.Router
 * @public
 */
const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,
});

Router.map(function () {
  this.route('emailing', function () {
    this.route('campaigns', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:email-campaign_id' });
    });
  });
  this.route('configuration', function () {
    this.route('dashboards', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:dashboard_id' });
    });
    this.route('password', function () {
      this.route('index', { path: '/' });
    });

    this.route('staff-members', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:staff-member_id' });
    });
  });

  this.route('analytics', function () {
    this.route('dashboards', function () {
      this.route('show', { path: 'show/:dashboard_id' });
    });
  });

  this.route('residential', function () {
    this.route('users', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:user_id' });
    });

    this.route('developments', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:residential-development_id' });
    });

    this.route('owners', function () {
      this.route('new');
      this.route('edit', { path: 'edit/:residential-owner_id' });
    });

    this.route('properties', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:residential-property_id' });
    });
    this.route('campaigns', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:campaign_id' });
    });
  });

  this.route('user', function () {
    this.route('login');
    this.route('reset-password');
  });
  this.route('update_password', { path: 'update_password/:token' });

  this.route('play-center', function () {
    this.route('children', function () {
      this.route('index', { path: '/' });
    });
  });

  this.route('marketing-analytics', function () {
    this.route('campaigntypes', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:campaigntype_id' });
    });

    this.route('lineitems', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:lineitem_id' });
    });
    this.route('services', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:service_id' });
    });
    this.route('suppliers', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:supplier_id' });
    });
    this.route('supplier-contacts', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:supplier-contact_id' });
    });
    this.route('campaigns', function () {
      this.route('index', { path: '/' });
      this.route('new');
      this.route('edit', { path: 'edit/:campaign_id' });
      this.route('close', { path: 'close/:campaign_id' });
      this.route('report', { path: 'report/:campaign_id' });
    });
  });
});

export default Router;
