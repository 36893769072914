import DS from 'ember-data';
import attr from 'ember-data/attr';
import { belongsTo } from 'ember-data/relationships';
import Validator from 'ember-model-validator/mixins/model-validator';

export default DS.Model.extend(Validator, {
  center_id: attr('number'),

  staff_id: belongsTo('staff-member'),

  date: attr('string'), // @TODO fix to be a date
});
