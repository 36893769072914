import {
  get, set, setProperties, action,
} from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';

import config from 'neuro-frontend/config/environment';
import LoadingSliderMixin from 'neuro-frontend/mixins/loading-slider';

export default class extends Route.extend(ApplicationRouteMixin, LoadingSliderMixin, {}) {
  @service auth

  @service api

  @service headData

  @service pusher

  @service stats

  baseUrl = ''

  async beforeModel() {
    super.beforeModel();
    // chaining JQuery promises and Ember RSVP promises
    // http://stackoverflow.com/questions/39039047/composing-promises-in-ember
    const data = await Promise.resolve($.getJSON('config.json'));
    // Headwayapp changelog configuration
    window.HW_config = {
      selector: '.js-badgeCont',
      trigger: '.js-toggleWidget',
      account: data.headwayapp.account,
    };

    setProperties(this, {
      'api.host': data.api.slice(0, -1),
      'api.namespace': 'v3',
      // it is needed in order to show bonus request only in cbre enviroment.
      'auth.env': data.env,
      baseUrl: data.base_url,
      'headData.theme': data.theme || config.modulePrefix,
      'headData.title': data.env_name,
      'pusher.appKey': data.pusher ? data.pusher.app_key : '',
      'pusher.env': data.pusher ? data.env : '',
    });

    this.pusher.initialize();

    // tries to load currentUser
    // - if not, it transitions to user.login
    // - if it's, it gets the currentUser data

    return this._loadCurrentUser();
  }

  model() {
    return {};
  }

  setupController(ctrl, model) {
    super.setupController(ctrl, model);

    set(ctrl, 'title', get(this, 'headData.title'));
  }

  @action
  logout() {
    get(this, 'session').invalidate();
  }

  @action
  refreshRoute() {
    // FIXME: With Ember.refresh() ember reload (native) the routes but this
    // breaks some routes (permissions, centerId static in filters attrs...).
    // So, eventually we'll fix this problems. Now we do a F5 reload.

    // this.refresh();
    window.location.reload();
  }

  invalidateSession() {
    get(this, 'session').invalidate();
  }

  sessionAuthenticated(...args) {
    super.sessionAuthenticated(...args);
    this._loadCurrentUser();
  }

  _loadCurrentUser() {
    return get(this, 'auth').load().catch(() => {
      this.invalidateSession();
    });
  }
}
