import { get, computed } from '@ember/object';

import { isEmpty } from '@ember/utils';
import { isArray } from '@ember/array';

import ModuleAnalytics from './module-analytics';

export default class extends ModuleAnalytics {
  @computed('user.level', 'user.dashboards', 'dashboardId')
  get canRead() {
    const dashboardId = get(this, 'dashboardId');
    const dashboards = get(this, 'user.dashboards');

    if ('admin' === get(this, 'user.level')) {
      return true;
    }

    return get(this, 'canAccess')
      && isArray(dashboards)
      && dashboards
        .find((dashboard) => Number(dashboardId) === Number(get(dashboard, 'id')));
  }

  @computed('user', 'user.level', 'model')
  get canDelete() {
    return !isEmpty(get(this, 'model'))
      && ('admin' === get(this, 'auth.user.level'));
  }
}
