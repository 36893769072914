import { isArray } from '@ember/array';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  /* eslint-disable no-param-reassign */
  normalizeResponse(...args) {
    if (isArray(args[2].data)) {
      args[2].data = args[2].data.map((campaignrule) => this.normalizeRule(campaignrule));
    } else {
      args[2].data = this.normalizeRule(args[2].data);
    }
    return this._super(...args);
  },
  /* eslint-enable no-param-reassign */

  serialize(snapshot, options) {
    const json = this._super(snapshot, options);
    const type = json.data.relationships.commerces ? 'stores' : 'base';
    const rule = {
      type,
      amount: json.data.attributes.amount,
    };

    if (json.data.relationships.commerces) {
      rule.store_ids = json.data.relationships.commerces.data.map((commerce) => commerce.id);
    }

    json.data.attributes.rule = JSON.stringify(rule);

    delete json.data.attributes.amount;
    delete json.data.attributes.type;
    delete json.data.relationships.commerces;
    return json;
  },

  normalizeRule(campaignrule) {
    const rule = JSON.parse(campaignrule.attributes.rule);
    const attributes = {
      ...campaignrule.attributes,
      ...rule,
    };
    const {
      relationships,
    } = campaignrule;
    delete attributes.rule;

    if ('stores' === attributes.type) {
      relationships.commerces = {
        data: {},
      };
      relationships.commerces.data = this.normalizeStores(attributes);
      delete attributes.store_ids;
    }

    return {
      ...campaignrule,

      attributes,

      relationships,
    };
  },

  normalizeStores(attributes) {
    const stores = [];
    // Because of the unsupported commas, it's neccesary to stringify before parse
    // It'll also help turning storesResponse into an array even if it's not initially
    const storesResponse = JSON.parse(JSON.stringify(attributes.store_ids));
    storesResponse.forEach((storeId) => stores.push({
      type: 'commerce',
      id: storeId,
    }));
    return stores;
  },

});
