import { computed, get } from '@ember/object';

import { inject as service } from '@ember/service';

import ModuleMarketingAnalytics from './module-marketing-analytics';

export default class extends ModuleMarketingAnalytics {
  @service can

  @service features

  @computed('auth.user.role')
  get canRead() {
    return get(this, 'canAccess') || this.can.can('access to module-crm');
  }

  @computed('auth.user.role')
  get canCreate() {
    const role = get(this, 'auth.user.role');

    return get(this, 'canRead')
      && ['admin', 'manager'].includes(role);
  }

  @computed('auth.user.role', 'model.status')
  get canClose() {
    const status = get(this, 'model.status');

    return get(this, 'canEdit') && ('finished' === status);
  }

  @computed('auth.user.role', 'model.status')
  get canEdit() {
    const {
      auth: {
        user: {
          role,
        },
      },
      model: {
        status,
      },
    } = this;

    return get(this, 'canRead')
      && (
        'admin' === role
        || (
          ['manager', 'scmanager'].includes(role)
          && !['closed', 'finished'].includes(status)
        )
      );
  }

  @computed('auth.user.role', 'model.status')
  get canDelete() {
    const {
      auth: {
        user: {
          role,
        },
      },
      model: {
        status,
      },
    } = this;

    return get(this, 'canCreate')
      && (
        !['closed', 'finished'].includes(status)
        || 'admin' === role
      );
  }

  @computed('model', 'model.isRaffle', 'model.campaignrules')
  get canEditRaffle() {
    const campaign = this.model;
    const rules = get(campaign, 'campaignrules');

    return !campaign.isRaffle
      || !rules.any((rule) => get(rule, 'tickets.length'));
  }

  @computed('auth.user.role', 'model.status')
  get canReport() {
    return get(this, 'canAccess');
  }
}
