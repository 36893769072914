// @TODO doc menu
// id: moduleName-childName
const menu = Object.freeze([
  {
    id: 'menu.navbar.analytics',
    name: i18n.t('menu.navbar.analytics'),
    icon: 'assessment',
    levels: ['admin', 'owner', 'manager', 'scmanager', 'crmmanager', 'commerce', 'storeManager'],
    children: [
      {
        id: 'menu.navbar.consumption.show',
        name: i18n.t('menu.navbar.consumption.show'),
        url: '/consumption/view',
        component: 'WA', // WA || NF
        levels: ['admin', 'manager'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.traffic.show',
        name: i18n.t('menu.navbar.traffic.show'),
        url: '/traffic/view',
        component: 'WA',
        levels: ['admin', 'manager'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.coupon.report',
        name: i18n.t('menu.navbar.coupon.report'),
        url: '/coupons/report',
        component: 'WA',
        levels: ['admin', 'manager'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.recommendation.show',
        name: i18n.t('menu.navbar.recommendation.show'),
        url: '/recommendation/view',
        component: 'WA',
        levels: ['admin'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.traffic.blacklist',
        name: i18n.t('menu.navbar.traffic.blacklist'),
        url: '/traffic/blacklist',
        component: 'WA',
        levels: ['admin'],
        module: 'CMS',
      },
    ],
  },

  {
    id: 'menu.navbar.cms',
    name: i18n.t('menu.navbar.cms'),
    icon: 'edit',
    levels: [
      'admin',
      'commerce',
      'manager',
      'storeManager',
      'weboperator',
      'crmmanager',
    ],
    module: 'CMS',
    children: [
      {
        id: 'menu.navbar.proposal.show',
        name: i18n.t('menu.navbar.proposal.show'),
        module: 'NewCMS',
        url: 'cms.proposals',
        component: 'NF',
        levels: ['admin', 'manager', 'commerce', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.proposal.new',
        name: i18n.t('menu.navbar.proposal.new'),
        url: '/proposal/create',
        component: 'WA',
        ability: 'access oldMenuProposal module-cms',
        levels: ['admin', 'manager', 'commerce', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.proposal.show.draft',
        name: i18n.t('menu.navbar.proposal.show.draft'),
        url: '/proposal/view/draft',
        disabledByModule: 'NewCMS',
        component: 'WA',
        ability: 'access oldMenuProposal module-cms',
        levels: ['admin', 'commerce', 'manager', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.proposal.show.pending',
        name: i18n.t('menu.navbar.proposal.show.pending'),
        url: '/proposal/view/pending',
        disabledByModule: 'NewCMS',
        component: 'WA',
        ability: 'access oldMenuProposal module-cms',
        levels: ['admin', 'commerce', 'manager', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.proposal.show.scheduled',
        name: i18n.t('menu.navbar.proposal.show.scheduled'),
        url: '/proposal/view/scheduled',
        disabledByModule: 'NewCMS',
        component: 'WA',
        ability: 'access oldMenuProposal module-cms',
        levels: ['admin', 'commerce', 'manager', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.proposal.show.active',
        name: i18n.t('menu.navbar.proposal.show.active'),
        url: '/proposal/view/active',
        disabledByModule: 'NewCMS',
        component: 'WA',
        ability: 'access oldMenuProposal module-cms',
        levels: ['admin', 'commerce', 'manager', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.proposal.show.expired',
        name: i18n.t('menu.navbar.proposal.show.expired'),
        url: '/proposal/view/expired',
        disabledByModule: 'NewCMS',
        component: 'WA',
        ability: 'access oldMenuProposal module-cms',
        levels: ['admin', 'commerce', 'manager', 'storeManager', 'weboperator', 'crmmanager'],
      },
      {
        id: 'menu.navbar.push-notification.show',
        name: i18n.t('menu.navbar.push-notification.index'),
        url: 'cms.push-notifications',
        component: 'NF',
        ability: 'read push-notification',
      },
      {
        id: 'menu.navbar.shop.show',
        name: i18n.t('menu.navbar.shop.show'),
        url: 'cms.commerces',
        component: 'NF',
        ability: 'read commerce',
      },
      {
        id: 'menu.navbar.cms.commerce-stay-alert.index',
        name: i18n.t('menu.navbar.cms.commerce-stay-alert.index'),
        url: 'cms.commerce-stay-alerts.index',
        component: 'NF',
        ability: 'read commerce-stay-alert',
      },
      {
        id: 'menu.navbar.category.new',
        name: i18n.t('menu.navbar.category.new'),
        url: '/category/create',
        component: 'WA',
        levels: ['admin'],
      },
      {
        id: 'menu.navbar.category.show',
        name: i18n.t('menu.navbar.category.show'),
        url: '/category/view',
        component: 'WA',
        levels: ['admin'],
      },
    ],
  },
  {
    id: 'menu.navbar.emailing',
    name: i18n.t('menu.navbar.emailing'),
    icon: 'drafts',
    levels: ['admin', 'manager', 'crmmanager'],
    module: 'mailing',
    children: [
      {
        id: 'menu.navbar.emailing.campaign.index',
        name: i18n.t('menu.navbar.emailing.campaign.index'),
        url: 'emailing.campaigns.index',
        component: 'NF',
        levels: ['admin', 'manager', 'crmmanager'],
      },
    ],
  },
  {
    id: 'menu.navbar.bi',
    name: i18n.t('menu.navbar.bi'),
    icon: 'business',
    levels: ['admin', 'manager'],
    module: 'BI',
    children: [
      {
        id: 'menu.navbar.bi.campaign.index',
        name: i18n.t('menu.navbar.bi.campaign.index'),
        url: '/campaign',
        component: 'WA',
        levels: ['admin', 'manager'],
      },
      {
        id: 'menu.navbar.bi.supplier.index',
        name: i18n.t('menu.navbar.bi.supplier.index'),
        url: '/bi/supplier',
        component: 'WA',
        levels: ['admin', 'manager'],
      },
      {
        id: 'menu.navbar.bi.lineitem.index',
        name: i18n.t('menu.navbar.bi.lineitem.index'),
        url: '/bi/lineitem',
        component: 'WA',
        levels: ['admin'],
      },
      {
        id: 'menu.navbar.bi.service.index',
        name: i18n.t('menu.navbar.bi.service.index'),
        url: '/bi/service',
        component: 'WA',
        levels: ['admin'],
      },
    ],
  },
  {
    name: i18n.t('menu.navbar.residential'),
    icon: 'location_city',
    id: 'menu.navbar.residential',
    levels: ['admin', 'manager', 'crmmanager'],
    module: 'Residential',
    children: [
      {
        id: 'menu.navbar.customer.show',
        name: i18n.t('menu.navbar.customer.show'),
        url: 'residential.users',
        component: 'NF',
        levels: ['admin', 'manager', 'crmoperator', 'crmmanager'],
      },
      {
        name: i18n.t('menu.navbar.residential.developments.index'),
        url: 'residential.developments',
        component: 'NF',
        levels: ['admin', 'manager', 'crmmanager'],
      },
      {
        name: i18n.t('menu.navbar.residential.properties.index'),
        url: 'residential.properties',
        component: 'NF',
        levels: ['admin', 'manager', 'crmmanager'],
      },
      {
        name: i18n.t('menu.navbar.residential.owners.index'),
        url: 'residential.owners',
        component: 'NF',
        levels: ['admin', 'manager', 'crmmanager'],
      },
      {
        name: i18n.t('menu.navbar.residential.campaigns.index'),
        url: 'residential.campaigns',
        component: 'NF',
        levels: ['admin', 'manager', 'crmmanager'],
      },
    ],
  },
  {
    id: 'menu.navbar.configuration',
    name: i18n.t('menu.navbar.configuration'),
    icon: 'settings',
    levels: [
      'admin',
      'commerce',
      'crmoperator',
      'crmmanager',
      'manager',
      'owner',
      'storeManager',
      'weboperator',
      'scmanager',
      'sales',
      'services',
    ],
    children: [
      {
        name: i18n.t('menu.navbar.staff.new'),
        url: '/staff_member/create',
        component: 'WA',
        levels: ['scmanager'],
      },
      {
        name: i18n.t('menu.navbar.staff.show'),
        url: '/staff_member/view',
        component: 'WA',
        levels: ['scmanager'],
      },
      {
        name: i18n.t('menu.navbar.staff.show'),
        url: 'configuration.staff-members.index',
        component: 'NF',
        levels: ['admin', 'manager'],
      },
      {
        id: 'menu.navbar.configuration.catalog',
        name: i18n.t('menu.navbar.configuration.catalog'),
        url: '/configuration/catalog',
        component: 'WA',
        levels: ['admin', 'manager'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.configuration.center',
        name: i18n.t('menu.navbar.configuration.center'),
        url: '/configuration/center',
        component: 'WA',
        levels: ['admin', 'manager'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.configuration.extra',
        name: i18n.t('menu.navbar.configuration.extra'),
        url: '/configuration/extra',
        component: 'WA',
        levels: ['admin'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.configuration.password',
        name: i18n.t('menu.navbar.configuration.password'),
        url: 'configuration.password',
        component: 'NF',
        levels: [
          'admin',
          'commerce',
          'crmmanager',
          'crmoperator',
          'manager',
          'owner',
          'storeManager',
          'weboperator',
          'scmanager',
          'sales',
          'services',
        ],
      },
      {
        id: 'menu.navbar.configuration.shop.import',
        name: i18n.t('menu.navbar.configuration.shop.import'),
        url: '/center/commerces',
        component: 'WA',
        levels: ['admin'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.center.new',
        ability: 'create center',
        name: i18n.t('menu.navbar.center.new'),
        url: '/center/create',
        component: 'WA',
        levels: ['admin'],
      },
      {
        id: 'menu.navbar.configuration.zone',
        name: i18n.t('menu.navbar.configuration.zone'),
        url: '/admin/zones',
        component: 'WA',
        levels: ['admin'],
        module: 'CMS',
      },
      {
        id: 'menu.navbar.configuration.dashboards',
        name: i18n.t('menu.navbar.configuration.dashboards'),
        url: 'configuration.dashboards',
        component: 'NF',
        levels: ['admin'],
      },
      {
        id: 'menu.navbar.configuration.module',
        ability: 'create center',
        name: i18n.t('menu.navbar.configuration.module'),
        url: '/configuration/modules',
        component: 'WA',
        levels: ['admin'],
      },
      {
        id: 'menu.navbar.configuration.influencezone',
        name: i18n.t('menu.navbar.configuration.influencezone'),
        url: '/configuration/influence_zones',
        component: 'WA',
        levels: ['admin', 'manager'],
        module: 'CMS',
      },
    ],
  },
]);

export default function getSideNavMenu() {
  return menu;
}
