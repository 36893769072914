export default Object.freeze([
  {
    label: i18n.t('boy'),
    value: 'H',
  },
  {
    label: i18n.t('girl'),
    value: 'M',
  },
]);
