import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterContains from 'neuro-frontend/utils/filter-operation/filter-contains';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

import residentialPropertyStates from './residential-property-states';

export default [
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('province_id'),
    model: 'provinces',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    value: 'province',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('city'),
    modules: ['residential'],
    operation: new FilterContains({
      onlyPositive: true,
    }),
    value: 'city',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property.price'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'price',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('development.built_surface'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'built_surface',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('development.useful_surface'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'useful_surface',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property.propertyType'),
    model: 'residentialPropertyTypes',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    value: 'residential_property_type',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property.bedrooms-number'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'bedrooms_number',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property.bathrooms-number'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'bathrooms_number',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('property.toilets-number'),
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'toilets_number',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('residential.development'),
    model: 'residentialDevelopments',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    required: true,
    value: 'residential_development',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('state'),
    model: residentialPropertyStates,
    modules: ['residential'],
    operation: new FilterIs({
      onlyPositive: true,
    }),
    value: 'state',
  },
  {
    groupName: i18n.t('crm.filters-section.requests'),
    label: i18n.t('floor'),
    model: 'residentialDevelopments',
    modules: ['residential'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'floor',
  },
];
