import { inject as service } from '@ember/service';
import { get, computed } from '@ember/object';
import { Ability } from 'ember-can';

export default class extends Ability {
  @service auth

  @computed('auth.user.level')
  get canAccess() {
    return 'admin' === get(this, 'auth.user.level');
  }
}
