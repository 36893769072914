import { computed } from '@ember/object';

import buildingStates from 'neuro-frontend/enums/building-states';
import TableList from 'neuro-frontend/components/table-list';

export default class extends TableList {
  @computed()
  get columns() {
    return [
      {
        label: i18n.t('residential.development.name'),
        valuePath: 'name',
      },
      {
        label: i18n.t('residential.development.owner'),
        valuePath: 'residentialOwner.name',
        sortable: false,
      },
      {
        label: i18n.t('residential.development.zone'),
        valuePath: 'zone',
        sortable: false,
        cellClassNames: ['table-list__cell--ellipsis'],
      },
      {
        label: i18n.t('residential.development.buildingState'),
        valuePath: 'buildingState',
        cellType: 'enum',
        enum: buildingStates,
        sortable: false,
      },
      {
        label: i18n.t('residential.development.tu'),
        valuePath: 'propertiesNumber',
        sortable: false,
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: i18n.t('edit'),
        sortable: false,
        align: 'center',
        width: '4%',
        cellClassNames: 'qa-edit-icon',
      },
      {
        label: '',
        cellAction: 'delete',
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: i18n.t('delete'),
        sortable: false,
        align: 'center',
        width: '4%',
        cellClassNames: 'qa-delete-icon',
      },
    ];
  }
}
