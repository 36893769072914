import { get, computed } from '@ember/object';

import Month from 'neuro-frontend/utils/month';
import ModuleSales from './intranet-sale';

export default class extends ModuleSales {
  @computed('month', 'shop', 'shop.intranetSales.[]', 'shop.closedMonths.[]')
  get canCreate() {
    const {
      month,
      shop,
    } = this;

    if (!shop && !month) {
      return get(this, 'canAccess');
    }

    if (shop ? !month : month) {
      return false;
    }

    return get(month, 'value') < get(new Month(), 'value')
      && !get(shop, 'closedMonths')
        .any((closedMonth) => get(month, 'value') === get(closedMonth, 'month'))
      && get(get(shop, 'intranetSales').filterBy('month', get(month, 'value')), 'length');
  }
}
