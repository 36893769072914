import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';
import { computed, set } from '@ember/object';

const getChannel = (channels, name) => {
  const channel = channels.find((ch) => (name === ch.name));
  return (channel) ? channel['image-url'] : '';
};
const setChannel = (channels, name, imageUrl) => {
  const channel = channels.find((ch) => (name === ch.name));

  if (!channel) {
    channels.push({ name, 'image-url': imageUrl });
  } else {
    set(channel, 'image-url', imageUrl);
  }
};

export default class extends MF.Fragment {
  @attr('array', { defaultValue() { return []; } }) channels

  @attr('string') code

  @attr('string') description

  @attr('string') name

  @computed('channels')
  get appImage() {
    const {
      channels,
    } = this;
    return getChannel(channels, 'app');
  }

  set appImage(imageUrl) {
    const {
      channels,
    } = this;
    setChannel(channels, 'app', imageUrl);
  }

  @computed('channels')
  get webImage() {
    const {
      channels,
    } = this;
    return getChannel(channels, 'web');
  }

  set webImage(imageUrl) {
    const {
      channels,
    } = this;
    setChannel(channels, 'web', imageUrl);
  }
}
