import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';

import { inject as service } from '@ember/service';

export default class extends Component {
  @service apiFiles

  classNames = ['nf-file-manager-single']

  // https://medium.com/@isaacezer/neat-facts-about-javascript-ember-2a3c4b3bb40b
  init(...args) {
    super.init(...args);

    set(this, 'files', get(this, 'files') || []);
    set(this, 'tempFiles', get(this, 'tempFiles') || []);
  }

  @computed('files.[]', 'category')
  get file() {
    const files = get(this, 'files');
    const {
      category,
    } = this;

    return get(files.filterBy('category', category), 'firstObject');
  }

  @action
  addNewFiles() {
    const files = get(this, 'files').filter((file) => get(file, 'category') !== get(this, 'category'));
    set(this, 'files', files.concat(get(this, 'tempFiles')));
    set(this, 'tempFiles', []);
  }

  @action
  downloadFile(file) {
    return get(this, 'apiFiles').downloadFile(get(file, 'url'), get(file, 'name'));
  }

  @action
  removeFile(file) {
    get(this, 'files').removeObject(file);
  }
}
