import Component from '@ember/component';

class NfBackComponent extends Component {
  classNames = ['nf-back']

  params = []
}

NfBackComponent.reopenClass({
  positionalParams: 'params',
});

export default NfBackComponent;
