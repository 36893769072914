import { get, set, action } from '@ember/object';

import { all } from 'rsvp';

import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';
import parseFilters from 'neuro-frontend/utils/parse-filters';
import UsersNewRoute from './new';

export default class extends UsersNewRoute {
  afterModel(model) {
    if (!this.can.can('edit user', model.user)) {
      this.transitionTo('residential.users');
    }
  }

  async setupController(controller, model) {
    const store = get(this, 'store');

    super.setupController(controller, model);
    set(controller, 'state', '');

    // @TODO refactor this
    store.query(
      'residential-proposal',
      {
        filter: {
          user_id: get(model, 'user.id'),
        },
      },
    )
      .then((proposals) => {
        all(proposals.getEach('residentialOffers'))
          .then((offers) => {
            set(
              controller,
              'residentialOffers',
              offers.reduce(
                (arr, curValue) => arr.concat(...curValue.toArray()),
                [],
              ),
            );

            set(
              controller,
              'residentialProposals',
              proposals,
            );
          });
      });

    set(
      controller,
      'residentialRequests',
      store.query(
        'residential-request',
        {
          filter: {
            user_id: get(model, 'user.id'),
          },
          sort: '-created-at',
        },
      ),
    );

    set(
      controller,
      'model.propertyTypes',
      store.findAll('residential-property-type'),
    );

    set(
      controller,
      'userActions',
      store.query(
        'action',
        {
          filter: {
            user_id: get(model, 'user.id'),
            staff_id: get(this, 'auth.user.id'),
          },
        },
      ),
    );

    set(
      controller,
      'model.residentialDevelopments',
      this.can.can('read all residential-development')
        ? await store.findAll('residentialDevelopment')
        : await store.query(
          'residentialDevelopment',
          {
            filter: {
              staff_id: get(this, 'auth.user.id'),
            },
          },
        ),
    );
  }

  @action
  saveRequest(form) {
    const residentialRequest = get(this, 'store').createRecord(
      'residential-request',
      {
        filters: get(form, 'filters'),
        name: get(form, 'name'),
        requestType: 'and',
        user: get(this, 'controller.model.user'),
        staff: get(this, 'auth.user'),
      },
    );

    return residentialRequest.save().then(() => {
      const residentialRequests = get(this, 'controller.residentialRequests').slice();
      residentialRequests.unshift(residentialRequest);
      set(this, 'controller.residentialRequests', residentialRequests);
      set(this, 'controller.showAdvancedSearch', false);
      set(this, 'controller.selectedSegment', null);
    });
  }

  @action
  saveRequestChanges(segment) {
    const selectedSegment = get(this, 'controller.selectedSegment');

    if (!selectedSegment) {
      throw new IllegalArgumentError(`saveRequestChanges: ${selectedSegment} must be set before saving it`);
    }

    Object.entries(segment).forEach(([key, value]) => set(selectedSegment, key, value));

    return selectedSegment.save().then(() => {
      set(this, 'controller.showAdvancedSearch', false);
      set(this, 'controller.selectedSegment', null);
    });
  }

  @action
  handleSelectedRequest(request) {
    set(this, 'controller.propertiesLoading', true);
    const filters = parseFilters(request.getFilters());
    filters.state = [{
      op: 'is',
      value: 'free',
    }];

    return get(this, 'store')
      .query('residential-property', { filter: filters })
      .then((residentialProperties) => {
        set(this, 'controller.selectedRequest', request);
        set(this, 'controller.residentialProperties', residentialProperties);
        set(this, 'controller.propertiesLoading', false);
      });
  }

  @action
  createProposals(residentialProperties) {
    const residentialProposals = get(this, 'controller.residentialProposals').slice();
    const promises = [];
    residentialProperties.forEach((residentialProperty) => {
      const alreadyExist = residentialProposals.filter((proposal) => (
        proposal.get('residentialProperty.id') === get(residentialProperty, 'id')
      )).length;

      if (!alreadyExist) {
        const residentialProposal = get(this, 'store').createRecord(
          'residential-proposal',
          {
            residentialProperty,
            residentialRequest: get(this, 'controller.selectedRequest'),
          },
        );
        residentialProposals.unshift(residentialProposal);
        promises.push(residentialProposal.save());
      }
    });

    all(promises).then(() => {
      set(this, 'controller.residentialProposals', residentialProposals);
    });
  }
}
