import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  total: computed('model', 'model.length', function () {
    if (!this.get('model.length')) {
      return 0;
    }

    return this.get('model')
      .map((model) => {
        if (!model.get || !model.get('amount') || 'number' !== typeof model.get('amount')) {
          return 0;
        }

        return model.get('amount');
      })
      .reduce((prev, cur) => prev + cur, 0);
  }),
});
