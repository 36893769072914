import Controller from '@ember/controller';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

import ListMixin from 'neuro-frontend/mixins/list';
import SegmentFiltersMixin from 'neuro-frontend/mixins/segment-filters';

const MixinController = Controller.extend(ListMixin, SegmentFiltersMixin, {});

export default class extends MixinController {
  @service() auth

  modelName = 'residentialDevelopment'

  init(...args) {
    super.init(...args);

    set(
      this,
      'fixedFilters',
      {
        center_id: get(this, 'auth.centerId'),
      },
    );
  }
}
