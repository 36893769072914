export default Object.freeze([
  {
    label: 'shares',
    value: 'shares',
  },
  {
    label: 'likes',
    value: 'likes',
  },
]);
