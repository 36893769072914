import { get, getProperties, computed } from '@ember/object';

import layout from 'neuro-frontend/templates/components/light-table/cells/amount';
import Month from 'neuro-frontend/utils/month';
import formatDate from 'neuro-frontend/utils/dates/format-date';
import Cell from './amount';

export default class extends Cell {
  layout = layout

  @computed('row.content', 'column.month')
  get value() {
    const model = get(this, 'row.content');
    const month = get(this, 'column.month') || new Month();
    const format = (date) => formatDate(date, 'DD/MM/YYYY');

    if (!model) {
      return 0;
    }

    const {
      startDate,
      endDate,
    } = getProperties(month, 'startDate', 'endDate');

    return get(model, 'intranetSales')
      .filter((sale) => formatDate(startDate) <= format(sale.date)
        && formatDate(endDate) >= format(sale.date))
      .reduce((prev, sale) => prev + get(sale, 'amount'), 0);
  }
}
