import { layout } from '@ember-decorators/component';
import { computed } from '@ember/object';

import NfKpi from 'neuro-frontend/components/nf-kpi';
import template from 'neuro-frontend/templates/components/nf-kpi';

@layout(template)
export default class extends NfKpi {
  @computed('value')
  get mainValue() {
    return `${this.value} %`;
  }
}
