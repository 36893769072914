import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  code() {
    return faker.address.countryCode();
  },

  name() {
    return faker.address.country();
  },
});
