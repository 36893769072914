import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import ModuleCms from './module-cms';

export default class extends ModuleCms {
  @service features

  roles = [
    'admin',
    'manager',
    'crmmanager',
  ]

  @computed('auth.user.level')
  get canCreate() {
    return this.roles.includes(this.auth.user.level);
  }

  @computed('canEdit')
  get canDelete() {
    return this.canEdit;
  }

  @computed('model.state', 'model.frequency', 'canCreate')
  get canEdit() {
    const {
      model: {
        state,
        frequency,
      },
    } = this;

    return this.canCreate
      && ('archived' !== state && ('active' !== state || 'single-sending' !== frequency));
  }

  @computed('model.state', 'canCreate')
  get canPublish() {
    const {
      model: {
        state,
      },
    } = this;

    return this.canCreate
      && 'draft' === state;
  }

  @computed('auth.user.level')
  get canRead() {
    return this.roles.includes(this.auth.user.level);
  }
}
