import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo } from 'ember-data/relationships';
import { get } from '@ember/object';

import Filter from 'neuro-frontend/utils/filter';

export default Model.extend({
  user: belongsTo('user'),

  staff: belongsTo('staffMember'),

  name: attr('trimmed-string'),

  requestType: attr('string'),

  filters: attr('json'),

  createdAt: attr('date'),

  propertiesCount: attr('number'),

  blockedPropertiesCount: attr('number'),

  getFilters() {
    if (!get(this, 'filters')) {
      return [];
    }

    return Object.entries(get(this, 'filters'))
      .reduce(
        (prev, [key, value]) => prev.concat(...value.map((filter) => new Filter({
          type: {
            value: key,
          },
          operation: filter.op,
          values: Object.entries(filter).reduce(
            (ojete, [a, b]) => {
              if ('op' !== a) {
                return {

                  ...ojete,
                  [a]: b,
                };
              }

              return ojete;
            },
            {},
          ),
        }))),
        [],
      );
  },
});
