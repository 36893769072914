import { get, computed } from '@ember/object';

import AbilityModule from './module';

export const roles = [
  'admin',
  'manager',
  'scmanager',
  'sales',
  'storeManager',
];
export default class extends AbilityModule {
  roles = roles

  moduleName = 'externalSales'

  @computed('auth.center', 'auth.user.level')
  get canAccessSalesState() {
    const center = get(this, 'auth.center');
    const level = get(this, 'auth.user.level');

    return center
      && get(center, 'hasExternalSales')
      && ['admin', 'manager', 'scmanager', 'sales'].includes(level);
  }

  @computed('auth.center', 'auth.user.role')
  get canAccessSalesIndex() {
    const center = get(this, 'auth.center');
    const role = get(this, 'auth.user.role');

    return center
      && get(center, 'hasExternalSales')
      && ['admin', 'manager', 'storeManager', 'scmanager', 'sales'].includes(role);
  }

  @computed('auth.center', 'auth.user.level')
  get canAccessYardi() {
    const center = get(this, 'auth.center');
    const level = get(this, 'auth.user.level');

    return center
      && get(center, 'hasExternalSalesYardi')
      && ['admin', 'manager', 'scmanager', 'sales'].includes(level);
  }

  @computed('auth.user.role', 'commerce', 'intranetSalesYear', 'month', 'commerce.closedMonths.[]')
  get canCreate() {
    const role = get(this, 'auth.user.role');
    const commerce = get(this, 'commerce');
    const month = get(this, 'month');
    const intranetSalesYearState = get(this, 'intranetSalesYear.state');
    const salesYearsActive = get(this, 'auth.center.active_sales_year_statement') || false;

    return [
      'admin',
      'manager',
      'scmanager',
      'sales',
    ].includes(role)
    || (['storeManager'].includes(role)
      && (['unclosed', 'without_sales'].includes(commerce?.getMonthState(month))
      || (salesYearsActive && ['rejected', 'unrequested'].includes(intranetSalesYearState))));
  }

  @computed('auth.user.role', 'model', 'month', 'commerce')
  get canDelete() {
    const role = get(this, 'auth.user.role');
    const model = get(this, 'model');
    const intranetSalesYearState = get(this, 'intranetSalesYear.state');
    const salesYearsActive = get(this, 'auth.center.active_sales_year_statement') || false;

    return model && (
      ['admin', 'manager', 'scmanager', 'sales'].includes(role)
      || (['storeManager'].includes(role)
        && ((!get(model, 'closed')
        || (salesYearsActive && ['rejected', 'unrequested'].includes(intranetSalesYearState)))))
    );
  }

  @computed('auth.center', 'auth.user.role')
  get canRead() {
    const role = get(this, 'auth.user.role');
    return get(this, 'canAccess')
      && this.roles.includes(role);
  }

  @computed('auth.center', 'auth.user.role')
  get canConfirmCloseMonth() {
    const center = get(this, 'auth.center');
    const role = get(this, 'auth.user.role');

    return this.canConfirmIntranetCloseMonth(center, role)
      || this.canConfirmExternalSalesCloseMonth(center, role);
  }

  canConfirmIntranetCloseMonth(center, role) {
    return get(center, 'hasIntranet')
      && ['admin', 'manager', 'scmanager', 'sales'].includes(role);
  }

  canConfirmExternalSalesCloseMonth(center, role) {
    return get(center, 'hasExternalSales')
      && ['admin', 'manager', 'scmanager', 'sales'].includes(role);
  }
}
