export default Object.freeze([
  {
    label: i18n.t('users.state.monitoring'),
    value: 'monitoring',
  },
  {
    label: i18n.t('users.state.discarded'),
    value: 'discarded',
  },
  {
    label: i18n.t('users.state.pre-registration'),
    value: 'pre-registration',
  },
  {
    label: i18n.t('users.state.incorporation'),
    value: 'incorporation',
  },
  {
    label: i18n.t('users.state.writing'),
    value: 'writing',
  },
]);
