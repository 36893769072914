import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import fetch from 'fetch'; // Using polyfill for testing purposes
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

import FormRoute from 'neuro-frontend/routes/form';

const Route = FormRoute.extend(UnauthenticatedRouteMixin, {});

export default class extends Route {
  @service api

  async model({ token }) {
    const checkToken = await fetch(
      `${get(this, 'api.host')}/api/v4/staff_members/check_token?token=${token}`,
      {
        credentials: 'include',
        mode: 'cors',
      },
    );
    const {
      data,
    } = await checkToken.json();

    return {
      isValidToken: 200 === checkToken.status,
      staffMemberId: data && data.id,
      token,
    };
  }
}
