import Cell from 'ember-light-table/components/cells/base';
import { set, action, computed } from '@ember/object';

export default class extends Cell {
  @computed('column.inputType')
  get type() {
    return this.column?.inputType || 'text';
  }

  @action
  changeValue() {
    set(this.row.content, this.column.valuePath, this.value);
  }
}
