import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default class extends Component.extend({
  value: {},
}) {
  classNames = ['nf-tag']

  tagName = 'span'

  @computed('value')
  get label() {
    const {
      value,
    } = this;

    if (value && value.label) {
      return value.label;
    }

    return 'string' === typeof value ? value : '';
  }

  didRender() {
    const value = get(this, 'value');

    if (value && 'object' === typeof value) {
      this.$().css('background-color', get(value, 'color'));
    }
  }
}
