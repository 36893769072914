import Component from '@ember/component';
import { action, computed, get } from '@ember/object';

import dashboardRanges from 'neuro-frontend/enums/dashboard-ranges';
import Year from 'neuro-frontend/utils/year';
import Month from 'neuro-frontend/utils/month';
import Week from 'neuro-frontend/utils/week';
import formatDate from 'neuro-frontend/utils/dates/format-date';

export default class extends Component {
  ranges = dashboardRanges

  value = 'week'

  @computed('value')
  get range() {
    const value = get(this, 'value');

    switch (value) {
      case 'month': {
        return new Month();
      }
      case 'year': {
        return new Year();
      }
      default: {
        return new Week();
      }
    }
  }

  @action
  changeRange() {
    const onChangedRange = get(this, 'onChangedRange');

    if ('function' === typeof onChangedRange) {
      onChangedRange({
        startDate: formatDate(get(get(this, 'range'), 'startDate')),
        endDate: formatDate(get(get(this, 'range'), 'endDate')),
        previousStartDate: formatDate(get(get(this, 'range').subtract(1, 'year'), 'startDate')),
        previousEndDate: formatDate(get(get(this, 'range').subtract(1, 'year'), 'endDate')),
      });
    }
  }
}
