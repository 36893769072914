import { computed, set } from '@ember/object';
import moment from 'moment';

import IllegalArgumentError from '../errors/illegal-argument-error';

export default class Year {
  @computed('value')
  get endDate() {
    return moment(this.value, 'YYYY')
      .endOf('year')
      .format('YYYY-MM-DD');
  }

  @computed('value')
  get endDatetime() {
    return moment(this.value, 'YYYY')
      .endOf('year')
      .format();
  }

  @computed('value')
  get startDate() {
    return moment(this.value, 'YYYY')
      .startOf('year')
      .format('YYYY-MM-DD');
  }

  @computed('value')
  get startDatetime() {
    return moment(this.value, 'YYYY')
      .startOf('year')
      .format();
  }

  @computed('value')
  get months() {
    const {
      value,
    } = this;
    const months = [];

    for (let i = 0; 12 > i; i += 1) {
      months.push(moment(new Date(value, i, 1)).format('YYYY-MM-DD'));
    }

    return months;
  }

  constructor(date = new Date()) {
    if (!(date instanceof Date)) {
      throw new TypeError(`${date} isn't of type Date`);
    }

    if (Number.isNaN(date.getTime())) {
      throw new IllegalArgumentError('param "date" is an invalid Date');
    }

    set(this, 'value', moment(date).format('YYYY'));
  }

  subtract(n, str) {
    const {
      startDate,
    } = this;

    return new Year(new Date(moment(startDate, 'YYYY-MM-DD').subtract(n, str)));
  }

  isSame(anotherYear) {
    const {
      startDate,
    } = this;
    return moment(startDate).isSame(anotherYear.startDate, 'year');
  }
}
