import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';

import residentialOfferStates from 'neuro-frontend/enums/residential-offer-states';

export default class extends Component {
  states = residentialOfferStates.filterBy('type', 'closing')

  rejectedStates = residentialOfferStates.filterBy('value', 'rejected')

  didReceiveAttrs() {
    this._super();
    if (get(this, 'model.onlyReject')) {
      set(this, 'model.state', 'rejected');
    }
  }

  @computed('model.state')
  get showRejectedReasons() {
    return 'rejected' === get(this, 'model.state');
  }

  clearForm() {
    set(this, 'model.rejectedCommonZones', false);
    set(this, 'model.rejectedDeliveryDate', false);
    set(this, 'model.rejectedPayForm', false);
    set(this, 'model.rejectedPrice', false);
    set(this, 'model.rejectedProject', false);
    set(this, 'model.rejectedOtherReasons', null);
  }

  @action
  handleChange(state) {
    set(this, 'model.state', get(state, 'value'));
    if ('accepted' === get(this, 'model.state')) {
      this.clearForm();
    }
  }
}
