import Service from '@ember/service';
/**
 * @module    nf
 * @submodule nf-services
 */

/**
 * ShowConfirmModal Service
 * --
 *
 * The goal of this service is to use a common dialog as it is a confirmation
 * dialog in every view that it's needed. We have started with a "confirm
 * deletion" modal but the objective must be use it too for different simple
 * dialogs.
 *
 * ### How to use
 *
 * 1. Inject the service in the Route where you'are going to use it:
 *
 * ```
 * export default Ember.Route.extend({
 *   showConfirm: Ember.inject.service('show-confirm-deletion'),
 * });
 * ```
 *
 * 2. Load the component `delete-modal` in the route view. You'll need to
 * specify two parameters: the *modelName* (only need to construct the
 * delete message) and the binding of the route-action.
 *
 * ```
 * {{delete-modal
 *  modelName="subtype"
 *  onConfirm=(route-action "deleteModel")}}
 * ```
 *
 * @todo  catch server errors and show them in the screen (we may need an extra
 *        component)
 * @todo  refactor to use the same service and component for other types of
 *        confirmation dialog (i.e. expire a proposal or add a child in the
 *        play center)
 *
 * @class     ShowConfirmDeletion
 * @namespace NF
 * @extends   Ember.Service
 * @public
 */
export default Service.extend({
  show(model) {
    this.set('modelToDelete', model);
    this.set('isConfirming', true);
  },

  hide() {
    this.set('modelToDelete', false);
    this.set('isConfirming', false);
  },
});
