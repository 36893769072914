import Component from '@ember/component';
import { set, get } from '@ember/object';

export default Component.extend({
  classNames: ['nf-tabs'],

  selection: null,

  tabs: [],

  init(...args) {
    this._super(...args);

    set(
      this,
      'selection',
      get(this, 'tabs')[0],
    );
  },

  didRender() {
    const tabs = get(this, 'tabs');
    tabs.forEach((tab) => {
      const invalidElems = document
        .querySelectorAll(`.js-nf-tabs__section-${tab.name} .invalid`);

      set(tab, 'error', Boolean(invalidElems.length));
    });

    window.dispatchEvent(new Event('resize'));
  },
});
