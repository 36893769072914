import Component from '@ember/component';
import { set, action } from '@ember/object';
import { classNames } from '@ember-decorators/component';

@classNames('nf-rating')
export default class NfRatingComponent extends Component {
  classNameBindings = ['disabled:nf-rating__disabled']

  precision = 0.5

  stars = 5

  // hooks
  didInsertElement(...args) {
    super.didInsertElement(...args);

    set(this, '$content', this.element.getElementsByClassName('js-rating-content')[0]);
    set(this, '$value', this.element.getElementsByClassName('js-rating-value')[0]);

    if (!this.disabled) {
      this.element.addEventListener('mousemove', this.handleMouseMove.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    }
  }

  didRender() {
    this.renderStars(this.value);
  }

  willDestroyElement(...args) {
    super.willDestroyElement(...args);

    this.element.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    this.element.removeEventListener('mouseleave', this.handleMouseLeave.bind(this));
  }

  @action
  changeRating() {
    set(this, 'value', this.currentRating);
    if ('function' === typeof this.onChange) {
      this.onChange(this.value);
    }
  }

  // private functions
  calculateRating(position) {
    // translate rating to an integer to simplify calculations
    const steps = this.stars / this.precision;

    set(this, 'currentRating', Math.ceil(steps * position) * this.precision);

    return this.currentRating;
  }

  getMousePosition(x) {
    const offsetLeft = this.$content.getBoundingClientRect().left;
    const elementWidth = parseInt(getComputedStyle(this.$content).width, 10);

    return (x - offsetLeft) / elementWidth;
  }

  handleMouseLeave() {
    this.renderStars(this.value);
  }

  handleMouseMove(ev) {
    this.renderStars(this.calculateRating(this.getMousePosition(ev.clientX)));
  }

  renderStars(rating = 0) {
    const width = (rating * 100) / this.stars;
    this.$value.style.width = `${100 < width ? 100 : width}%`;
  }
}
