export default {
    "app-notifications": "Notificaciones app",
    "campaigns": "Campa\u00f1as",
    "center-id": "ID del centro",
    "children-number": "N\u00famero de ni\u00f1os",
    "crm.filters-section.stats": "Estad\u00edstica",
    "dashboards.active-users": "Usuarios activos",
    "dashboards.active-users-chart": "Usuarios activos vs % usuarios activos (mes)",
    "dashboards.competition-dwell-time": "Tiempo de permanencia de la competencia",
    "dashboards.competition-recurrence": "Visita frecuente de la competencia",
    "dashboards.competition-visits": "Visita de la competencia",
    "dashboards.dwell-chart": "Tiempo de permanencia vs Competencia (mes)",
    "dashboards.dwell-time": "Tiempo de permanencia",
    "dashboards.frequency": "Frecuencia de visita",
    "dashboards.frequency-chart": "Frecuencia de visita frente a Competencia (mes)",
    "dashboards.new-app-users": "Usuarios registrados en la App",
    "dashboards.new-registered-users": "Usuarios registrados",
    "dashboards.per-active-users": "Porcentaje de usuarios activos",
    "dashboards.recurrence": "Frecuencia de visita",
    "dashboards.registered-total-users-chart": "Registros en la App frente a Registros totales (suma)",
    "dashboards.registered-users-chart": "Registros en la App frente a Registros totales (mes)",
    "dashboards.registration-rate": "Tasa de registro",
    "dashboards.registration-rate-chart": "Tasa de registro (mes)",
    "dashboards.total-app-users": "Total usuarios registrados en la App",
    "dashboards.total-registered-users": "Total usuarios registrados",
    "dashboards.visits-competition-chart": "Visitas frente a competencia (mes)",
    "dni": "DNI",
    "eula": "T\u00e9rminos y condiciones",
    "id": "id",
    "last-activity": "\u00daltima actividad",
    "leasing.brands.see-contacts": "Ir a contacto",
    "leasing.emea-report": "Informe de exportaci\u00f3n EMEA",
    "loyalty-card": "Tarjeta de fidelizaci\u00f3n",
    "mail-notifications": "Notificaciones de email",
    "nationality": "Nacionalidad",
    "person-type": "Tipo de persona",
    "receipts": "Facturas",
    "register-date": "Fecha de registro",
    "usertypes": "Tipo de usuario",
    "visit_building": "Visita al edificio",
    "dashboards.user-profiles-age-chart": "Edad",
    "dashboards.user-profiles-catchment-area-chart": "\u00c1rea de captaci\u00f3n",
    "dashboards.user-profiles-family-chart": "Familia",
    "dashboards.user-profiles-gender-chart": "G\u00e9nero",
    "info.default.error.message": "Se ha producido un error. Por favor int\u00e9ntelo mas tarde",
    "info.default.error.title": "Error",
    "info.default.succes.title": "\u00c9xito",
    "info.default.success.message": "La operaci\u00f3n se realiz\u00f3 con \u00e9xito",
    "intranet.sales.import": "Importar CSV",
    "intranet.sales.import-sales": "Importar ventas",
    "sales.csv-error-format": "El archivo no tiene un formato v\u00e1lido. Por favor, revise el archivo para seguir las instrucciones",
    "sales.csv-format": "El CSV debe tener el mismo formato que el csv exportado. Debe seguir el siguiente formato:",
    "sales.csv-sales-with-errors": "Alguna de las ventas en el CSV no se han conseguido importar. Por favor, revise los datos del  CSV",
    "sales.csv.amount-format": "La cantidad debe ser un n\u00famero con una coma (`,\u00b4) para separar los decimales",
    "sales.csv.columns": "Debe tener cuatro columnas, con la fecha en la primera columna y la cantidad en la cuarta",
    "sales.csv.date-format": "La fecha debe ser por a\u00f1o-mes-d\u00edas con n\u00fameros",
    "sales.csv.separators": "Las columnas deben estar separadas por comas (`,\u00b4)",
    "sales.error": "Error",
    "sales.import-csv": "Seleccionar CSV",
    "sales.info": "Informaci\u00f3n",
    "GLA": "GLA",
    "High Street": "High Street",
    "KYC": "KYC",
    "Outlet centre": "Outlet center",
    "Retail Park": "Retail Park",
    "Shopping centre": "Shopping center",
    "TPWC": "TPWC (Team for Private Works Coordination)",
    "Webactiva": "WebActiva",
    "accept": "Aceptar",
    "access_data": "Datos de acceso",
    "access_date": "Fecha de acceso",
    "access_head": "Contacto principal",
    "action.check_as_done": "Marcar acci\u00f3n como completada",
    "actions.action_type": "Tipo de acci\u00f3n",
    "actions.add": "A\u00f1adir acci\u00f3n",
    "actions.call": "Llamada",
    "actions.date": "Fecha",
    "actions.datetime": "Fecha",
    "actions.edit": "Editar acci\u00f3n",
    "actions.email": "Email",
    "actions.form": "Formulario",
    "actions.new": "Nueva acci\u00f3n",
    "actions.notes": "Notas",
    "actions.rejection": "Rechazo",
    "actions.rejection_title": "Oferta rechazada",
    "actions.reservation": "Reserva",
    "actions.reservation_title": "Inmueble reservado",
    "actions.select_action_type": "Selecciona tipo de acci\u00f3n",
    "actions.sent_marketing_info": "Informaci\u00f3n de marketing enviada",
    "actions.sent_marketing_info_title": "Informaci\u00f3n enviada",
    "actions.signed": "Firmado",
    "actions.signed_title": "Inmueble firmado",
    "actions.staff": "Usuario",
    "actions.title": "T\u00edtulo",
    "actions.visit": "Visita",
    "active.users": "Usuarios activos",
    "add": "A\u00f1adir",
    "additional-guarantee": "Garant\u00eda Adicional",
    "address": "Direcci\u00f3n",
    "admin": "Admin",
    "advanced_search": "B\u00fasqueda avanzada",
    "analytics.no-dashboard": "No hay elementos disponibles",
    "and": "y",
    "answer": "Respuesta",
    "api-error": "Algo est\u00e1 fallando y no es culpa tuya. Ponte en contacto con soporte e indica la siguiente informaci\u00f3n",
    "apply_filters": "Aplicar filtros",
    "approved-date": "Fecha Aprobaci\u00f3n",
    "approved-documentation": "Documentaci\u00f3n aprobada",
    "are_you_sure": "\u00bfEst\u00e1s seguro?",
    "as-built-documentation": "Documentaci\u00f3n",
    "attach_files": "Adjuntar archivos",
    "attach_image": "Adjuntar imagen",
    "attr.required": "Atributo requerido",
    "available-in": "Disponible en",
    "average": "Media",
    "average.visit.time": "Tiempo medio de visita",
    "back": "Volver",
    "bank-account-number": "IBAN",
    "bdate": "Cumplea\u00f1os",
    "before-starting-works": "Antes de empezar a trabajar",
    "between": "entre",
    "billing": "Facturaci\u00f3n",
    "bime": "BIME",
    "bime_dashboard.not_found": "Panel Bime no encontrado",
    "bime_dashboard.type_find": "Escribe para encontrar paneles Bime",
    "bookable": "Reservable",
    "booking-contract": "Contrato reserva",
    "booking-price": "Precio Reserva",
    "booking_contract": "Contrato reserva",
    "both": "Ambos",
    "boy": "Ni\u00f1o",
    "brand": "Brand",
    "breadcrumbs.main": "Principal",
    "budget": "Presupuesto",
    "building": "Edificio",
    "building-state": "Estado Construcci\u00f3n",
    "built-surface": "Superficie Construida",
    "bus": "Bus",
    "business-civilliability-insurance": "Seguro de responsabilidad civil empresarial",
    "business-license": "Licencia de negocios",
    "business-license-approval": "Aprobaci\u00f3n de licencia de negocio",
    "business-license-request": "Solicitud de licencia de negocio",
    "cadastral-reference": "Ref. Catastral",
    "calendar": "Calendario",
    "calibrator": "Calibrator",
    "call-to-action": "Call to action",
    "campaign": "Campa\u00f1a",
    "campaign_id": "Campa\u00f1a",
    "cancel": "Cancelar",
    "car-insurance": "Seguro C.A.R",
    "categories": "Categor\u00edas",
    "category": "Categor\u00eda",
    "cbre-contact": "Contacto CBRE",
    "ceiling": "L\u00edmite",
    "center": "Centro",
    "center.not_found": "No se han encontrado centros",
    "center.select_one": "Selecciona un centro",
    "centers": "Centros",
    "centers-required": "Centers is required",
    "change-password.confirm-password": "Confirma password",
    "change-password.new-password": "Nueva password",
    "change-password.old-password": "Password Actual",
    "change-password.title": "Cambio password",
    "channel_id": "Canal",
    "check-in": "Entrada",
    "child": "Hijo\/a",
    "check-out": "",
    "child.add-user-dni": "Ir a perfil de usuario",
    "child.bdate": "Fecha de nacimiento",
    "child.check-out": "Salida",
    "child.checking-out": "Verificar",
    "child.checkn": "Entrada",
    "child.edit": "Editar",
    "child.last-name": "Apellidos",
    "child.name": "Nombre",
    "child.new": "Nuevo",
    "child.not-dni-error-message": "El usuario no tiene DNI. Por favor, vaya al perfil de usuario para configurarlo",
    "child.notes": "Notas",
    "child.register-entry": "Registro de entrada",
    "child.relationship": "Parentesco",
    "child.select-relationship": "Seleccione parentesco",
    "child.select-sex": "Selecciona g\u00e9nero",
    "child.sex": "G\u00e9nero",
    "children": "Hijos",
    "children_number": "N\u00famero de ni\u00f1os",
    "cif": "CIF",
    "city": "Ciudad",
    "civilliability-insurance": "Seguro responsabilidad civil",
    "clear": "Limpiar",
    "clear_filters": "Borrar filtros",
    "client": "Cliente",
    "close": "Cerrar",
    "close-month.button.confirm": "Confirmar cierre del mes",
    "close-month.button.reject": "Rechazar cierre del mes",
    "close-month.message.pending-request": "Hay una solicitud pendiente este mes de cierre de mes",
    "close_month": "Cerrar mes",
    "closed": "Cerrado",
    "closed_month_pending": "Pendiente",
    "closed_previous_month": "Cerrar todos los meses anteriores",
    "combo": "Combo",
    "comments": "Comentarios",
    "commerce": "Tienda",
    "commercialization": "Comercializaci\u00f3n",
    "commercialization-start": "Inicio de comercializaci\u00f3n",
    "common-zones-surface": "Superficie Zona",
    "communication.label.to_roles": "A",
    "communication.message": "Mensaje",
    "communication.new": "Nueva comunicaci\u00f3n",
    "communication.not_found": "No se han encontrado comunicaciones",
    "communication.reject-close-month.subject": "{{shopName}} cierre {{month}} rechazado",
    "communication.roles.commerce": "Tienda",
    "communication.roles.management": "Gesti\u00f3n",
    "communication.roles.owner": "Propietario",
    "communication.state.accepted": "Aceptado",
    "communication.state.pending": "Pendiente",
    "communication.state.rejected": "Rechazado",
    "communication.state.solved": "Resuelto",
    "communication.subject": "Asunto",
    "communication.subtype": "Subtipo",
    "communication.to_commerces": "A tiendas",
    "communication.type": "Tipo",
    "communication.type.access": "Acceso",
    "communication.type.issues": "Incidencias",
    "communication.type.news": "Noticias",
    "communication.type.offers": "Ofertas",
    "communication.type.tabs.inbox": "Bandeja de entrada",
    "communication.type.tabs.sent": "Enviadas",
    "community-expenses": "Gastos comunidad",
    "companies": "Companies",
    "companion.add": "Agregar acompa\u00f1ante",
    "companion.vat_number": "D.N.I.",
    "companion_name": "Nombre del acompa\u00f1ante",
    "companions": "Acompa\u00f1antes",
    "company": "Empresa",
    "company-reference-number": "Company reference number",
    "concepts.airplane_tickets": "Tickets de avi\u00f3n",
    "concepts.cancellations": "Cancelaciones",
    "concepts.coffee": "Caf\u00e9",
    "concepts.equipment": "Equipamiento",
    "concepts.free_devices": "Dispositivos libres",
    "concepts.game": "Game",
    "concepts.general": "General",
    "concepts.general_variable": "Renta Variable de Merchandise general",
    "concepts.gm1": "General Merchandise 1",
    "concepts.gm2": "General Merchandise 2",
    "concepts.magazines": "Peri\u00f3dicos y revistas",
    "concepts.phonecard": "Tarjeta telef\u00f3nica",
    "concepts.pub": "Pub",
    "concepts.receipts": "Facturas",
    "concepts.school_mat": "Material escolar",
    "concepts.stamps_and_values": "sellos y valores",
    "concepts.telecommunications": "Servicios de telecomunicaciones",
    "concepts.tobacco": "Tabaco",
    "concepts.tobacco_game": "Tabaco y juegos",
    "concepts.trans_currency": "Transacciones monetarias",
    "concepts.uploads_of_credit": "Cargas de cr\u00e9dito",
    "confirm": "Confirmar",
    "confirm-modal.cancel": "Cancelar",
    "confirm-modal.ok": "Aceptar",
    "confirm-password": "Confirmar password",
    "contact-people": "Persona de contacto",
    "contactable": "Contactable",
    "contains": "contiene",
    "countries": "Paises",
    "country": "Country",
    "country-required": "Country is required",
    "couple": "Con pareja",
    "cp": "C\u00f3digo postal",
    "create-staff-message": "SC Manager, Calibration and Operator should be created in",
    "created_at": "Fecha de registro",
    "crm-manager": "CRM Manager",
    "crm-operator": "CRM Operator",
    "crm.children.bdate": "Fecha de nacimiento",
    "crm.children.gender": "G\u00e9nero",
    "crm.children.name": "Nombre",
    "crm.filter.not_found": "No se han encontrado filtros",
    "crm.filter.type_find": "Escribe para encontrar filtros",
    "crm.filters-section.demographic": "Demogr\u00e1fico",
    "crm.filters-section.requests": "Peticiones",
    "crm.segments": "Segmentos",
    "crm.users": "Clientes",
    "crm.users.children-list": "Lista de ni\u00f1os",
    "crm.users.code": "C\u00f3digo",
    "crm.users.action": "Acci\u00f3n",
    "crm.users.email": "Email",
    "crm.users.last-action": "\u00daltima acci\u00f3n",
    "crm.users.last-action-datetime": "Fecha \u00faltima acci\u00f3n",
    "crm.users.last_name": "Apellido",
    "crm.users.mstatus": "Estado civil",
    "crm.users.name": "Nombre",
    "crm.users.phone": "Tel\u00e9fono",
    "crm.users.state": "Estado",
    "crm.users.vat-number": "DNI",
    "crm.users.person_type": "Tipo de persona",
    "crm.users.postal_code": "C\u00f3digo postal",
    "crm.users.sex": "G\u00e9nero",
    "csv": "CSV",
    "currency": "Divisa",
    "current-pass-required": "Contrase\u00f1a actual obligator\u00eda",
    "dashboard": "Panel",
    "dashboard-cms": "Panel cms",
    "dashboard-list.dashboards.not-found": "No se encontraron paneles",
    "dashboard-list.title.active-graphs": "Gr\u00e1ficos activos",
    "dashboard-panel": "Panel dashboard",
    "dashboard.category.general": "General",
    "dashboard.category.home": "Home",
    "dashboard.category.not_found": "No se encontraron categor\u00edas",
    "dashboard.category.traffic": "Tr\u00e1fico",
    "dashboard.error.not-found-bime": "No se encontr\u00f3  panel Bime",
    "dashboard.index.no-home-dashboard": "Bienvenido a Neuromobile Back Office",
    "dashboards": "Dashboards",
    "dashboards.registered-app-users": "Usuarios registrados en la App",
    "dashboards.registered-users-total": "Total Usuarios Registrados",
    "dashboards.visits": "Visitas",
    "dashboards-required": "Dashboards is required",
    "date": "Fecha",
    "date.from": "Desde",
    "date.from-to": "Desde el {{from}} hasta el {{to}}",
    "date.to": "Hasta",
    "day": "D\u00eda",
    "delete": "Eliminar",
    "delivery": "Entrega",
    "delivery-date": "Fecha Entrega",
    "description": "Descripci\u00f3n",
    "developer-contact": "Contacto Propiedad",
    "development": "Promoci\u00f3n",
    "development-maps": "Plano Promoci\u00f3n",
    "development.address": "Direcci\u00f3n",
    "development.built_surface": "Sup. construida",
    "development.cadastral_reference": "Referencia catastral",
    "development.city": "Ciudad",
    "development.common_zones_surface": "Sup. zonas comunes",
    "development.edit": "Editar promoci\u00f3n",
    "development.energeticCertification": "Certificaci\u00f3n energ\u00e9tica",
    "development.estate": "Finca",
    "development.garages_number": "N\u00ba plazas de garaje",
    "development.name": "Promoci\u00f3n",
    "development.new": "Nueva promoci\u00f3n",
    "development.notes": "Descripci\u00f3n",
    "development.postalCode": "C\u00f3digo postal",
    "development.properties_number": "N\u00ba de inmuebles",
    "development.reference": "Referencia",
    "development.responsible_email": "Email",
    "development.responsible_name": "Nombre",
    "development.responsible_position": "Cargo",
    "development.staff": "Staff managers",
    "development.storage_rooms_number": "N\u00ba trasteros",
    "development.useful_surface": "Sup. \u00fatil",
    "discharge-date": "Inicio de obra",
    "division": "Divisi\u00f3n",
    "doc": "Doc.",
    "document": "Documento",
    "documentation": "Documentaci\u00f3n",
    "dollars": "Dolares $",
    "done": "Completado",
    "download": "Descargar",
    "downloads": "Descargas",
    "draft": "Borrador",
    "draft-bookable": "Borrador Reserva",
    "duplicate": "Duplicar",
    "east": "Este",
    "economic-conditions": "Condiciones Econ\u00f3micas",
    "ecop": "ECOP",
    "ecop-work.edit": "Editar tarea",
    "ecop-work.new": "Nueva tarea",
    "ecop.work": "Tarea",
    "edit": "Editar",
    "edit-company": "Editar compa\u00f1ia",
    "electricity": "Electricidad",
    "email": "Email",
    "email-campaign": "Campa\u00f1a mailing",
    "email-campaign.date": "Fecha",
    "email-campaign.date.is-unique-sent": "Es env\u00edo \u00fanico",
    "email-campaign.edit": "Editar campa\u00f1a de e-mail",
    "email-campaign.end-date": "Fecha de fin",
    "email-campaign.filter.active": "Activa",
    "email-campaign.filter.archived": "Enviada",
    "email-campaign.filter.draft": "Borrador",
    "email-campaign.filter.scheduled": "Programada",
    "email-campaign.form.desing_template": "Dise\u00f1ar plantilla",
    "email-campaign.form.general_info": "Informaci\u00f3n general",
    "email-campaign.form.summary": "Resumen",
    "email-campaign.htmlBody.design": "Dise\u00f1a tu e-mail",
    "email-campaign.htmlBody.file_import": "Importar plantilla",
    "email-campaign.htmlBody.init_greeting": "Edita tu e-mail aqu\u00ed :)",
    "email-campaign.htmlBody.params": "Par\u00e1metros de e-mail",
    "email-campaign.htmlBody.preview": "Previsualizaci\u00f3n",
    "email-campaign.name": "Nombre campa\u00f1a",
    "email-campaign.new": "Nueva campa\u00f1a de e-mailing",
    "email-campaign.not_found": "Campa\u00f1as de e-mailing no encontradas",
    "email-campaign.params.help.html": "Introduce estos c\u00f3digos para a\u00f1adir variables en la plantilla HTML",
    "email-campaign.params.help.subject": "Introduce estos c\u00f3digos para a\u00f1adir variables en el asunto del e-mail",
    "email-campaign.params.html.center_name": "A\u00f1ade el nombre del centro",
    "email-campaign.params.html.unsub_link": "A\u00f1ade un enlace para permitir al usuario darse de baja de las comunicaciones por correo electr\u00f3nico",
    "email-campaign.params.html.user_email": "A\u00f1ade el e-mail del usuario",
    "email-campaign.params.html.user_name": "A\u00f1ade el nombre del usuario",
    "email-campaign.params.subject.center_name": "A\u00f1ade el nombre del centro",
    "email-campaign.params.subject.user_code": "A\u00f1adir el c\u00f3digo de usuario",
    "email-campaign.params.subject.user_email": "A\u00f1ade el e-mail del usuario",
    "email-campaign.params.subject.user_name": "A\u00f1ade el nombre del usuario",
    "email-campaign.segment": "Segmento",
    "email-campaign.segment.list": "Lista de segmentos",
    "email-campaign.segment.segmentName": "Nombre del segmento",
    "email-campaign.send.save_and_test": "Guardar y enviar prueba",
    "email-campaign.sentDatetime": "Fecha de env\u00edo",
    "email-campaign.start-date": "Fecha de inicio",
    "email-campaign.statistic.blocked": "Bloqueados",
    "email-campaign.statistic.bounced": "Rebotados",
    "email-campaign.statistic.clicked": "Clicados",
    "email-campaign.statistic.delivered": "Entregados",
    "email-campaign.statistic.opened": "Abiertos",
    "email-campaign.statistic.spam": "Correo no deseado",
    "email-campaign.subject": "Asunto",
    "email-required": "Email obligatorio",
    "email-template.not_found": "No se han encontrado plantillas",
    "email-template.select_one": "Selecciona una plantilla",
    "email-template.send.select_mail": "Introduce la direcci\u00f3n de correo a la que enviar el e-mail de prueba",
    "email-template.send.test": "Enviar e-mail de prueba",
    "email-template.type.send_contact": "Mensaje desde la aplicaci\u00f3n m\u00f3vil",
    "email-template.type.send_intranet_communication_email": "Notificaci\u00f3n de comunicaci\u00f3n de Intranet",
    "email-template.type.send_proposals_report": "Informe de cupones",
    "email-template.type.send_reprint_tickets": "Notificaci\u00f3n de reimpresi\u00f3n de boleta",
    "email-template.type.send_staff_connection_warning": "Aviso de conexi\u00f3n",
    "email-template.type.send_staff_reset_password_email": "Enviar mail reset contrase\u00f1a",
    "email-template.type.send_user_activate_email": "Mensaje de activaci\u00f3n del usuario de la aplicaci\u00f3n m\u00f3vil",
    "email-template.type.send_user_child_birthday_reminder": "Recordatorio de cumplea\u00f1os de ni\u00f1o asociado a cliente",
    "email-template.type.send_user_child_birthday_visit_ack": "Agradecimiento de visita tras el cumplea\u00f1os de un ni\u00f1o asociado a cliente",
    "email-template.type.send_user_registration_email": "Mensaje de registro de cliente",
    "email-template.type.send_user_reset_password_email": "Mensaje de reseteo de contrase\u00f1a a usuario de la aplicaci\u00f3n m\u00f3vil",
    "email.not-exist": "El correo no existe",
    "email.recovery-sent": "El correo de recuperaci\u00f3n ha sido enviado",
    "end-date": "Fecha final",
    "end_date": "Fecha fin",
    "end_datetime": "Fin de la fecha",
    "energy-certification": "Certificaci\u00f3n Energ\u00e9tica",
    "entry-time": "Hora de entrada",
    "error.channels.required": "Canal requerido",
    "error.child.bdate.required": "Se requiere fecha de nacimiento",
    "error.child.last-name.required": "Apellido requerido",
    "error.child.name.required": "Nombre requerido",
    "error.child.relationship.required": "Campo requerido",
    "error.child.sex.required": "G\u00e9nero requerido",
    "error.commerce.building.required": "Edificio obligatorio",
    "error.commerce.categories.required": "Categor\u00eda obligatoria",
    "error.commerce.email.required": "Email obligatorio",
    "error.commerce.fiscalName.required": "Nombre fiscal obligatorio",
    "error.commerce.imageUrl.required": "Url imagen obligatorio",
    "error.commerce.name.required": "Nombre obligatorio",
    "error.commerce.required": "Comercio obligatorio",
    "error.commerce.vatNumber.required": "El DNI es obligatorio",
    "error.commerce.zone.required": "Zona obligatoria",
    "end_of_construction": "Fin de obra",
    "error.communication.commerce.required": "Selecciona al menos una tienda",
    "error.communication.companion.required": "Algunos acompa\u00f1antes tienen datos en blanco",
    "error.crm.children.entryTime.required": "Se requiere hora de entrada",
    "error.crm.children.expectedExitTime.required": "Es necesario el tiempo de salida esperado",
    "error.dashboard.bimeId.required": "Es necesario panel Bime",
    "error.dashboard.category.required": "Es necesaria la categor\u00eda",
    "error.dashboard.name.required": "Es necesario el nombre",
    "error.dashboard.panel.required": "Es necesario el panel dashboard",
    "error.document-type.required": "Tipo documento obligatorio",
    "error.email-campaign-template.htmlBody.required": "La plantilla de la campa\u00f1a no puede quedar vac\u00eda",
    "error.email-campaign-template.name.required": "El nombre no puede quedar vac\u00edo",
    "error.email-campaign.date.invalid_past": "La fecha de inicio no puede tener lugar en el pasado",
    "error.email-campaign.date.required": "La fecha no puede quedar vac\u00eda",
    "error.email-campaign.endDatetime.post_startDatetime": "La fecha de fin debe ser posterior a la de inicio",
    "error.email-campaign.htmlBody.required": "La plantilla de campa\u00f1a no puede quedar vac\u00eda",
    "error.email-campaign.name.required": "El nombre no puede quedar vac\u00edo",
    "error.email-campaign.segment.required": "El campo de segmento no puede quedar vac\u00edo",
    "error.email-campaign.subject.required": "El asunto del e-mail no puede quedar vac\u00edo",
    "error.email.invalid": "Direcci\u00f3n de correo no v\u00e1lida",
    "error.file.commerces.required": "Selecciona al menos una tienda",
    "error.file.name.required": "El nombre no puede estar en blanco",
    "error.file.roles.required": "Selecciona un rol",
    "error.file.size.invalid": "El tama\u00f1o del archivo no es v\u00e1lido",
    "error.file.type.extension-allowed": "Tipo de archivo no permitido. Extensiones permitidas: jpg, jpeg, gif, png, xls, xlsx, doc, docx y pdf",
    "error.file.type.not_allowed": "Tipo de archivo no permitido",
    "error.file.url.required": "Se requiere un archivo",
    "error.job-title.required": "Cargo obligatorio",
    "error.leasing.brand.activity.required": "Activity is required",
    "error.leasing.brand.country.required": "Country is required",
    "error.leasing.brand.name.required": "Name is required",
    "error.leasing.brand.optimal-area.required": "Optimal area is required",
    "error.leasing.center.country.required": "Country is required",
    "error.leasing.center.gla.required": "GLA is required",
    "error.leasing.center.name.required": "Name is required",
    "error.leasing.center.staff.required": "Porfolio manager is required",
    "error.leasing.center.type.required": "Type is required",
    "error.leasing.contact.brand.required": "Brand is required",
    "error.leasing.contact.country.required": "Country is required",
    "error.leasing.contact.email.required": "Email is required",
    "error.leasing.contact.last-name.required": "Last name is required",
    "error.leasing.contact.mobile-phone.required": "Mobile phone is required",
    "error.leasing.contact.name.required": "Name is required",
    "error.leasing.unit.area.required": "Area is required",
    "error.leasing.unit.category.required": "Es necesaria la categor\u00eda",
    "error.leasing.unit.code.required": "Code is required",
    "error.leasing.unit.erv.required": "ERV is required",
    "error.leasing.unit.leasing-center.required": "Center is required",
    "error.leasing.unit.required": "Unit is required",
    "error.leasing.unit.service-charge.required": "Service charge is required",
    "error.leasing.unit.type.required": "Type is required",
    "error.leasing.units.2.units.required": "Units are required",
    "error.residential.owner.code.required": "El c\u00f3digo es obligatorio",
    "error.residential.owner.name.required": "El nombre es obligatorio",
    "error.residential.property.name.required": "El nombre es obligatorio",
    "error.residential.property.price.required": "El precio es obligatorio",
    "error.residential.property.reference.required": "La referencia es obligatoria",
    "error.residential.property.rental.required": "El precio de alquiler es obligatorio",
    "error.residential.property.residentialDevelopment.required": "Promoci\u00f3n es obligatorio",
    "error.residential.property.residentialPropertyType.required": "El tipo es obligatorio",
    "error.residential.property.state.required": "El estado es obligatorio",
    "error.sale.date.invalid_future": "No se pueden a\u00f1adir ventas en fechas futuras",
    "error.server": "Error del servidor",
    "error.user.cp.required": "El c\u00f3digo postal obligatorio",
    "error.user.email.required": "El email es obligatorio",
    "error.user.gdpr": "El check GDPR debe ser aceptado",
    "error.user.lastName.required": "El apellido es obligatorio",
    "error.user.lopd": "Debe aceptar la LOPD",
    "error.user.name.required": "El nombre es obligatorio",
    "error.user.personType.required": "El tipo de persona es obligatorio",
    "error.user.phone.required": "El tel\u00e9fono es obligatorio",
    "error.user.registration-date.required": "La fecha de registro es obligatoria",
    "error.vat-number.required": "El DNI es obligatorio",
    "error_browser_iframe": "Tu navegador no soporta iframes",
    "erv": "ERV",
    "estate": "Estado",
    "estimated-stay-time": "Tiempo estimado de permanencia",
    "euros": "Euros \u20ac",
    "excel": "Exel",
    "exit-children": "Salida de hijos",
    "exit-time": "Tiempo de salida",
    "expansion-process-stopped": "Expansion process stopped",
    "export": "Exportar",
    "external-api": "External API",
    "external-collaborator-fee": "Tarifa colaborador externo",
    "extra-info": "Extra info",
    "facade-template-approval-date": "Fecha de aprobaci\u00f3n del modelo de terraza",
    "facade-template-delivery-date": "Fecha de entrega de plantilla",
    "family": "Con familia",
    "featured": "Destacada",
    "file": "Archivo",
    "file.creator_id": "Creador",
    "file.description_optional": "Descripci\u00f3n (opcional)",
    "file.edit": "Editar archivo",
    "file.name": "Nombre",
    "file.new": "Nuevo archivo",
    "file.not_found": "No se han encontrado archivos",
    "file.roles.all": "Todos",
    "file.roles.commerce": "Tienda",
    "file.roles.management": "Gesti\u00f3n",
    "file.roles.owner": "Propietario",
    "file.roles_with_access": "Roles con acceso",
    "file.shop_with_access": "Tiendas con acceso",
    "file.size": "Tama\u00f1o",
    "file.type": "Tipo",
    "file.updated_at": "Fecha",
    "file.visibility": "Visibilidad",
    "file_max_allowed": "El tama\u00f1o m\u00e1ximo permitido es {{size}}",
    "filter.select": "Selecciona un filtro",
    "final-audit": "Revisi\u00f3n final",
    "fire-detection": "Detecci\u00f3n de incendios",
    "fire-extinction": "Extinci\u00f3n de incendios",
    "first-invoice-amount": "Primer importe facturado",
    "first-invoice-date": "Fecha de la primera factura",
    "fiscal-name": "Nombre fiscal",
    "fit-out-coordinator": "Acondicionamiento del coordinador",
    "fit-out-coordinator-cbre": "Acondicionamiento del coordinador de CBRE",
    "fit-out-coordinator-external": "Ajuste del coordinador externo",
    "fit-out-guarantee": "Garant\u00eda de acondicionamiento",
    "fit-out-guarantee-return": "Garant\u00eda de devoluci\u00f3n de acondicionamiento",
    "floor": "Planta",
    "floor-plans": "Planos planta",
    "folder": "Carpeta",
    "folder.add": "Crear carpeta",
    "folder.new.current_dir": "Crear una nueva carpeta",
    "folder.warning_delete": "La eliminaci\u00f3n de esta carpeta eliminar\u00e1 todos los elementos de la misma",
    "folder_name": "Nombre de la carpeta",
    "forecast-date": "Fecha de pron\u00f3stico",
    "forgot-password": "se te ha olvidado tu contrase\u00f1a?",
    "form-dashboard.bime-dashboard": "Panel Bime",
    "form-dashboard.button.clear": "Borrar",
    "form-dashboard.button.submit": "Enviar",
    "form-dashboard.category": "Categor\u00eda",
    "form-dashboard.header.available_graphs": "Gr\u00e1ficos disponibles",
    "form-dashboard.name": "Nombre del panel",
    "form-dashboard.parameters": "Par\u00e1metros",
    "form-email-campaign.error.import_file": "El formato del archivo debe ser HTML",
    "form-email-campaign.warning.import-template": "Vas a reemplazar la plantilla actual. \u00bfEst\u00e1s seguro?",
    "from-starting-date": "Desde la fecha de inicio",
    "from-signing-date": "From signing date",
    "from-unit-delivery-date": "From unit delivery date",
    "from-unit-opening-date": "From unit opening date",
    "garage-plan": "Planos garaje",
    "garages-number": "N\u00ba Garajes",
    "general": "General",
    "general-data": "Datos generales",
    "general-info": "Info genera",
    "general-terms-of-online-contracting": "Terminos generales de contrato online",
    "girl": "Ni\u00f1a",
    "general_terms_of_online_contracting": "General terms of online contracting",
    "gla": "GLA",
    "grandchild": "Nieto\/a",
    "guard": "Guardia",
    "guard.chose_staff_member": "Elige un miembro del personal del centro...",
    "guard.no_assigned_this_day": "No hay guardia asignada para este d\u00eda",
    "header": "Cabecera",
    "how-to-arrive-plan": "Como llegar",
    "hs-plan": "Plan H & S",
    "hvac": "HVAC",
    "image": "Imagen",
    "image-required": "Imagen obligatoria",
    "images": "Im\u00e1genes",
    "informative-message.change-password.error.message": "Formato password incorrecto",
    "informative-message.change-password.success.message": "Tu password ha sido cambiada. Te redirigimos a la pantalla de login",
    "in_construction": "En construcci\u00f3n",
    "informative-message.close-month.error.message": "Ocurri\u00f3 un error al cerrar el mes. Por favor int\u00e9ntalo de nuevo m\u00e1s tarde",
    "informative-message.close-month.success.message": "El mes fue cerrado con \u00e9xito",
    "informative-message.default.error.message": "Ocurri\u00f3 un error. Por favor int\u00e9ntalo de nuevo m\u00e1s tarde",
    "informative-message.default.error.title": "Error",
    "informative-message.default.succes.title": "\u00c9xito",
    "informative-message.default.success.message": "La operaci\u00f3n se realiz\u00f3 con \u00e9xito.",
    "informative-message.export-crm-csv.success.message": "Recibir\u00e1s el fichero CSV a trav\u00e9s de tu correo electr\u00f3nico",
    "informative-message.reject-close-month.error.message": "Ocurri\u00f3 un error al rechazar cerrar el mes. Por favor int\u00e9ntalo m\u00e1s tarde.",
    "informative-message.reject-close-month.success.message": "El cierre del mes fue rechazado con \u00e9xito. Se ha enviado una comunicaci\u00f3n a la tienda",
    "informative-message.upload-yardi.error": "El archivo de Yardi no se ha subido correctamente. Por favor, intente de nuevo o contacte con un admin.",
    "informative-message.upload-yardi.success": "El archivo de Yardi se ha subido correctamente.",
    "insert-email": "Escribe tu direcci\u00f3n de email",
    "installed.apps": "Apps instaladas",
    "internal": "Interno",
    "intranet.close_month-message": "Vas a cerrar los registros de venta de {{shop}} de {{month}}.",
    "invalid-token": "El token no es v\u00e1lido",
    "invoice-amount": "Importe de la factura",
    "invoices": "Facturas",
    "is": "es",
    "is-in-play-center": "Est\u00e1 en la ludoteca",
    "is_not": "no es",
    "job-title": "Cargo Empresa",
    "job-title-required": "Cargo empresa obligatorio",
    "kyc": "KYC",
    "lack-of-founds": "Lack of founds",
    "lack-of-franchisee": "Lack of franchisee",
    "last-contact-date": "Last contact date",
    "last-name": "Last name",
    "last-name-required": "Last name is required",
    "last-units": "\u00daltimas unidades",
    "lease-payments": "Lease payments",
    "leasing": "Leasing",
    "lastName-required": "Last name is required",
    "leasing-agent": "Leasing agent",
    "leasing-brand.edit": "Edit",
    "leasing-brand.new": "New",
    "leasing-center-type": "Type",
    "leasing-center.edit": "Edit",
    "leasing-center.new": "New leasing center",
    "leasing-commercial-proposal": "Lease economic proposal",
    "leasing-contact.edit": "Edit",
    "leasing-contact.new": "New",
    "leasing-opeartion.edit": "Edit",
    "leasing-opeartion.new": "New",
    "leasing-owner": "Propietario",
    "last-year-period": "Mismo periodo del a\u00f1o pasado",
    "leasing-owner.edit": "Editar",
    "leasing-owner.new": "Nuevo",
    "leasing-unit": "Unit",
    "leasing-unit-storage-room": "Sala de almacenamiento",
    "leasing-unit-terrace": "Terraza",
    "leasing-unit.edit": "Edit leasing unit",
    "leasing-unit.new": "New leasing unit",
    "leasing-unit.select-type": "Select a type",
    "leasing-unit.type": "Type",
    "leasing-units": "Units",
    "leasing.accessories": "Accesories",
    "leasing.activity": "Activity",
    "leasing.advanced-negotiation": "Advanced negotiation",
    "leasing.agent": "Agent",
    "leasing.books": "Books",
    "leasing.bowling": "Bowling",
    "leasing.brand": "Brand",
    "leasing.brand.activity": "Activity",
    "leasing.brand.country": "Country",
    "leasing.brand.filters": "Filtros",
    "leasing.brand.name": "Name",
    "leasing.brand.optimal-area": "Optimal area",
    "leasing.brand.select-activity": "Select an activity",
    "leasing.brand.select-country": "Select a country",
    "leasing.brand.select-optimal-area": "Select an optimal area",
    "leasing.center": "Center",
    "leasing.center.logo": "Logotipo",
    "leasing.center.country": "Country",
    "leasing.center.name": "Name",
    "leasing.center.portfolio-manager": "Portfolio manager",
    "leasing.center.select_country": "Select a country",
    "leasing.center.select_manager": "Select a manager",
    "leasing.centers.filters": "Filters",
    "leasing.centers.name": "Name",
    "leasing.centers.total_units": "Total units",
    "leasing.childrens-apparel": "Childrens apparel",
    "leasing.cinema": "Cinema",
    "leasing.coffeeshop-icecream": "Coffeeshop icecream",
    "leasing.contact": "Contact",
    "leasing.contact.brand": "Brand",
    "leasing.contact.brands": "Brands",
    "leasing.contact.country": "Country",
    "leasing.contact.email": "Email",
    "leasing.contact.last-name": "Last name",
    "leasing.contact.mobile-phone": "Mobile phone",
    "leasing.contact.name": "Name",
    "leasing.contact.office-phone": "Office phone",
    "leasing.contact.postalAddress": "Postal address",
    "leasing.contact.select_country": "Select a country",
    "leasing.contact.title": "Title",
    "leasing.contacts.filters": "Filters",
    "leasing.counter-offer": "Counter offer",
    "leasing.country": "Country",
    "leasing.economic-proposal-sent": "Economic proposal sent",
    "leasing.electronics": "Electronics",
    "leasing.fast-food": "Fast food",
    "leasing.first-contact": "First contact",
    "leasing.food": "Food",
    "leasing.furniture": "Furniture",
    "leasing.hairdressers-and-beauty-services": "Hairdressers and beauty services",
    "leasing.handbags": "Handbags",
    "leasing.home": "Home",
    "leasing.home-apparel": "Home apparel",
    "leasing.initial-proposal": "Initial proposal",
    "leasing.jewelry-watches": "Jewelry watches",
    "leasing.last-offer": "Last offer",
    "leasing.lingerie": "Lingerie",
    "leasing.mens-apparel": "Mens apparel",
    "leasing.new-lease": "New lease",
    "leasing.operation": "Operation",
    "leasing.operation.approval-date": "Approval date",
    "leasing.operation.approved": "Approved",
    "leasing.operation.brochureSent": "Brochure sent?",
    "leasing.operation.comments": "Comments",
    "leasing.operation.current-passing-rent": "Current\/Passing rent",
    "leasing.operation.fee": "Fee",
    "leasing.operation.first-contact-date": "First contact date",
    "leasing.operation.general-info": "Informaci\u00f3n general",
    "leasing.operation.inspectionDone": "Inspection done",
    "leasing.operation.landlord-comments": "Landlord comments",
    "leasing.operation.last-contact-date": "Last contact date",
    "leasing.operation.leasing-brand": "Brand",
    "leasing.operation.leasing-center": "Center",
    "leasing.operation.mutualBreak": "Mutual break",
    "leasing.operation.leasing-unit": "Unit",
    "leasing.operation.negotiationType": "Negotiation type",
    "leasing.operation.netRent": "Net rent",
    "leasing.operation.occupancy-cost": "Occupancy cost (last 12m)",
    "leasing.operation.portfolio-average-area": "Portfolio average area",
    "leasing.operation.portfolio-sales-density": "Portfolio sales density",
    "leasing.operation.precedentConditionClause": "Precedent condition clause",
    "leasing.operation.rejection-reason": "Rejection reason",
    "leasing.operation.rent": "Rent",
    "leasing.operation.rent-storage-room": "Alquiler sala de almacenamiento",
    "leasing.operation.rent-terrace": "Alquiler de terraza",
    "leasing.operation.rentFreePeriodOnlyRent": "Rent free period (only rent)",
    "leasing.operation.rentFreePeriodRentSch": "Rent free period (Rent + Sch)",
    "leasing.operation.rentFreePeriodStart": "Rent free period start",
    "leasing.operation.rentReduction": "Rent reduction",
    "leasing.operation.rentReductionYear1": "Year 1",
    "leasing.operation.rentReductionYear2": "Year 2",
    "leasing.operation.rentReductionYear3": "Year 3",
    "leasing.operation.rentReductionYear4": "Year 4",
    "leasing.operation.rentReview": "Rent Review: (IPC+...)",
    "leasing.operation.reporting-status": "Reporting status",
    "leasing.operation.sales-density": "Sales density (last 12m)",
    "leasing.operation.schCap": "Sch Cap",
    "leasing.operation.select-leasing-brand": "Select brand",
    "leasing.operation.select-leasing-center": "Select center",
    "leasing.operation.select-leasing-unit": "Select unit",
    "leasing.operation.select-negotiationType": "Select negotiation type",
    "leasing.operation.select-rejection-reason": "Select rejection reason",
    "leasing.operation.select-rentFreePeriodStart": "Select period",
    "leasing.operation.select-reporting-status": "Select reporting status",
    "leasing.operation.select-staff-member": "Select leasing agent",
    "leasing.operation.select-status": "Select status",
    "leasing.operation.sendLandlord": "Send to landlord?",
    "leasing.operation.shoppingCenterVisited": "Shopping center visited",
    "leasing.operation.signing-date": "Signing date",
    "leasing.operation.staff-member": "Leasing agent",
    "leasing.operation.status": "Status",
    "leasing.operation.tenant-occupancy-cost": "Tenant occupancy cost",
    "leasing.operation.tenant-performance": "Tenant performance",
    "leasing.operation.tennant-annual-sales-density": "Tenant annual sales density",
    "leasing.operation.term": "Term",
    "leasing.operation.turn-over-rent": "Turn over rent",
    "leasing.operation.unit-size-proportion": "Unit size proportion",
    "leasing.operation.unitDelivered": "Unit delivered",
    "leasing.operation.units": "Unidades",
    "leasing.opticians-farmacy": "Opticians farmacy",
    "leasing.other-leisure": "Other leisure",
    "leasing.other-services": "Other services",
    "leasing.outlet": "Outlet",
    "leasing.owner.vat-number": "IVA",
    "leasing.pending-approval": "Pending approval",
    "leasing.perfumery-beauty-store": "Perfumery beauty store",
    "leasing.pets": "Pets",
    "leasing.phone-photo-games": "Phone photo games",
    "leasing.rejected": "Rejected",
    "leasing.renewal": "Renewal",
    "leasing.restaurants": "Restaurants",
    "leasing.shoes": "Shoes",
    "leasing.signed": "Signed",
    "leasing.special-food": "Special food",
    "leasing.speciality-gift": "Speciality gift",
    "leasing.sports": "Sports",
    "leasing.storage-room": "Sala de almacenamiento",
    "leasing.supermarket": "Supermarket",
    "leasing.terrace": "Terraza",
    "leasing.toys": "Toys",
    "leasing.travels": "Travels",
    "leasing.under-signing-process": "Under signing process",
    "leasing.unit": "Unit",
    "leasing.unit.area": "Area",
    "leasing.unit.area-storage-room": "Zona de almacenamiento",
    "leasing.unit.area-terrace": "Zona de terraza",
    "leasing.unit.category": "Categor\u00eda",
    "leasing.unit.center": "Center",
    "leasing.unit.code": "Code",
    "leasing.unit.country": "Country",
    "leasing.unit.erv": "ERV",
    "leasing.unit.filters": "Filters",
    "leasing.unit.leasing-center": "Leasing center",
    "leasing.unit.real-state-tax": "Real state tax",
    "leasing.unit.select_leasing_center": "Select leasing center",
    "leasing.unit.service-charge": "Service charge",
    "leasing.unit.type": "Type",
    "leasing.womens-apparel": "Womens apparel",
    "leasing.womens-mens-apparel": "Womens & mens apparel",
    "leasing.young-apparel": "Young apparel",
    "legal": "Legal",
    "legal-deposit": "Legal deposit",
    "legal_person": "Persona jur\u00eddica",
    "letting-agreement-date": "Fecha de contrato del alquiler",
    "letting-agreement-signing-date": "Fecha de firma del contrato de alquiler",
    "letting-data": "Datos de alquiler",
    "level": "Level",
    "level-required": "Level is required",
    "location": "Direcci\u00f3n",
    "lock": "Bloquear",
    "logo": "Logo",
    "m2": "m2",
    "mail_notifications": "Notificaciones de email",
    "mail_partners_notifications": "Envio mails terceros",
    "maintenance-contracts": "Contratos de mantenimiento",
    "man": "Hombre",
    "management": "Gesti\u00f3n",
    "manager": "Manager",
    "marketing": "Marketing",
    "marketing-dossier": "Marketing dossier",
    "masonry": "Mamposter\u00eda",
    "match-pass": "Las password no coincide",
    "materials-sample-approval-date": "Fecha de aprobaci\u00f3n de materiales de muestra",
    "materials-sample-delivery-date": "Fecha de entrega de materiales de muestra",
    "max": "Max.",
    "max-image-size-1440-800": "Tama\u00f1o imagen 1440 x 800px",
    "max-image-size-300-232": "Imagen miniatura es 300 x 232px",
    "married": "Casado",
    "hour": "Hora",
    "inbox": "Bandeja de entrada",
    "management.files": "Gesti\u00f3n de archivos",
    "menu.navbar.analytics": "Gr\u00e1ficas",
    "menu.navbar.bi": "Marketing analytics",
    "menu.navbar.bi.campaign.index": "Campa\u00f1as",
    "menu.navbar.bi.lineitem.index": "Partidas",
    "menu.navbar.bi.service.index": "Servicios",
    "menu.navbar.bi.supplier.index": "Proveedores",
    "menu.navbar.category.new": "Nueva categor\u00eda",
    "menu.navbar.category.show": "Categor\u00edas",
    "menu.navbar.center.new": "Nuevo centro",
    "menu.navbar.channel.show": "Canales",
    "menu.navbar.cms": "CMS",
    "menu.navbar.companies.show": "Compa\u00f1ias",
    "menu.navbar.configuration": "Configuraci\u00f3n",
    "menu.navbar.configuration.catalog": "Cat\u00e1logo",
    "menu.navbar.configuration.center": "Informaci\u00f3n del centro",
    "menu.navbar.configuration.dashboards": "Paneles",
    "menu.navbar.configuration.extra": "Atributos extra",
    "menu.navbar.configuration.influencezone": "Zonas de influencia",
    "menu.navbar.configuration.module": "M\u00f3dulos",
    "menu.navbar.configuration.password": "Cambiar contrase\u00f1a",
    "menu.navbar.configuration.shop.import": "Importar tiendas",
    "menu.navbar.configuration.zone": "Zonas",
    "menu.navbar.consumption.show": "Consumo",
    "menu.navbar.coupon.report": "Cupones",
    "menu.navbar.crm": "CRM",
    "menu.navbar.customer.import": "Importar clientes",
    "menu.navbar.customer.new": "Nuevo cliente",
    "menu.navbar.customer.show": "Lista de clientes",
    "menu.navbar.customertype.show": "Tipos de cliente",
    "menu.navbar.desktop": "Escritorio",
    "menu.navbar.ecop": "ECOP",
    "menu.navbar.dashboard": "Dashboard",
    "menu.navbar.email-template.show": "Plantillas de correo electr\u00f3nico",
    "menu.navbar.emailing": "Mailing",
    "menu.navbar.emailing.campaign.index": "Campa\u00f1as de e-mailing",
    "menu.navbar.intranet": "Intranet",
    "menu.navbar.intranet.communication.index": "Comunicaciones",
    "menu.navbar.intranet.file.index": "Archivos",
    "menu.navbar.intranet.guard.index": "Guardias",
    "menu.navbar.intranet.sale.index": "Ventas",
    "menu.navbar.intranet.subtype.index": "Subtipos de comunicaciones",
    "menu.navbar.leasing-ecop": "Leasing \/ ECOP",
    "menu.navbar.leasing": "Leasing",
    "menu.navbar.leasing.brands": "Brands",
    "menu.navbar.leasing.centers": "Centers",
    "menu.navbar.leasing.contacts": "Contacts",
    "menu.navbar.leasing.operations": "Operations",
    "menu.navbar.leasing.owners": "Propietarios",
    "menu.navbar.leasing.units": "Units",
    "menu.navbar.leasing.works": "Trabajos",
    "menu.navbar.logout": "Cerrar sesi\u00f3n",
    "menu.navbar.message.new": "Nuevo mensaje",
    "menu.navbar.message.show": "Lista de mensajes",
    "menu.navbar.playcenter.show": "Ludoteca",
    "menu.navbar.proposal.new": "Nueva propuesta",
    "menu.navbar.proposal.show.active": "Propuestas activas",
    "menu.navbar.proposal.show.draft": "Propuestas en borradores",
    "menu.navbar.proposal.show.expired": "Propuestas expiradas",
    "menu.navbar.proposal.show.pending": "Propuestas pendientes ",
    "menu.navbar.proposal.show.scheduled": "Propuestas planificadas",
    "menu.navbar.recommendation.show": "Recomendaciones",
    "menu.navbar.residential": "Residencial",
    "menu.navbar.residential.campaigns.index": "Campa\u00f1as",
    "menu.navbar.residential.contacts": "Contactos",
    "menu.navbar.residential.developments.index": "Promociones",
    "menu.navbar.residential.owners.index": "Propietarios",
    "menu.navbar.residential.properties.index": "Inmuebles",
    "menu.navbar.shop.new": "Nueva tienda",
    "menu.navbar.shop.show": "Lista de tiendas",
    "menu.navbar.staff.new": "Nuevo usuario",
    "menu.navbar.staff.show": "Lista de usuarios",
    "menu.navbar.traffic.blacklist": "Usuarios sin seguimiento",
    "menu.navbar.traffic.show": "Tr\u00e1fico",
    "min": "Min.",
    "month": "Mes",
    "month.state": "Estado Mes",
    "monthly-report": "Informe mensual",
    "months": "Months",
    "mstatus": "Estado civil",
    "multi_family": "Plurifamiliar",
    "multimedia": "Multimedia",
    "multimedia-files": "Archivos Multimedia",
    "minute": "Minuto",
    "name": "Nombre",
    "name-required": "Nombre obligatorio",
    "name_required": "El nombre no puede estar en blanco",
    "nationality_id": "Nacionalidad",
    "nephew": "Sobrino\/a",
    "new-company": "Nueva compa\u00f1ia",
    "new-lease-form": "Lease form",
    "new-staff": "New staff",
    "new_folder": "Nueva carpeta",
    "new_name": "Nuevo nombre",
    "news": "Noticias",
    "next_month": "Mes siguiente",
    "nif": "NIF",
    "no": "No",
    "north": "Norte",
    "not-found": "No encontrado",
    "not_between": "no entre",
    "not_contains": "no contiene",
    "notes": "Descripci\u00f3n",
    "occupation-percentage": "% ocupaci\u00f3n",
    "on-signing-date": "Fecha firma",
    "ongoing": "En curso",
    "not_result_found": "No se ha encontrado ning\u00fan resultado",
    "open": "Abierta",
    "opening": "Apertura",
    "opening-guarantee": "Opening guarantee",
    "operation-analysis": "Operation analysis",
    "operation-analysis.warning": "Operation analysis fields will be shown from Economic proposal sent status",
    "optimal-area": "Optimal area",
    "open.new-tab.message": "Abrir en una nueva pesta\u00f1a",
    "options_not_found": "No se han encontrado opciones",
    "or_less": "o menos",
    "or_more": "o m\u00e1s",
    "original": "Original",
    "other": "Otros",
    "other-data": "Otros Datos",
    "other-documentation": "Otra documentaci\u00f3n",
    "others": "Otros",
    "owner": "Propietario",
    "owner-code": "C\u00f3digo propietario",
    "owner-find": "Encontrar propietario",
    "pager.out_of": "de",
    "pass-required": "Debe haber al menos 10 caracteres, 1 may\u00fascula, 1 min\u00fascula y 1 n\u00famero",
    "passport": "Pasaporte",
    "password": "Password",
    "password-format": "Please make a strong password that contains at least 10 characters, one capital letter, one lower letter and one number",
    "payment-plan": "Plan de pago",
    "payment_plan": "Plan de pago",
    "payslip": "N\u00f3mina",
    "pdf": "PDF",
    "pending": "Pendiente",
    "person_type": "Tipo de persona",
    "phone": "Tel\u00e9fono",
    "physical_person": "Persona f\u00edsica",
    "play-center-stays": "Ludoteca",
    "play-center.time": "Tiempo",
    "play-center.tutor": "Tutor",
    "poor-image-quality": "Imagen poca calidad",
    "portfolio-manager": "Gestor portfolio",
    "position": "Posici\u00f3n",
    "postal-code": "C. Postal",
    "powerpoint": "Powerpoint",
    "premises-authorizations": "Autorizaciones de locales",
    "presentation": "Presentaci\u00f3n",
    "presale": "Precomercializaci\u00f3n",
    "previous": "Anterior",
    "previous_month": "Mes anterior",
    "process": "Proceso",
    "project-approval": "Aprobaci\u00f3n del proyecto",
    "project-approval-shopfront": "Aprobaci\u00f3n del proyecto de tienda",
    "project-approval-unit": "Unidad de aprobaci\u00f3n de proyectos",
    "projects-delivery-unit-and-shopfront": "Entrega de proyecto (local y tienda)",
    "properties": "Propiedades",
    "properties-number": "N\u00ba Inmuebles",
    "property": "Inmueble",
    "property-filter.bathrooms_number": "Ba\u00f1os",
    "property-filter.bedrooms_number": "Dormitorios",
    "property-filter.built_surface": "Superficie construida",
    "property-filter.city": "Ciudad",
    "property-filter.price": "Precio",
    "property-filter.property_type": "Tipo de inmueble",
    "property-filter.province": "Provincia",
    "property-filter.residential_development": "Promoci\u00f3n",
    "property-filter.toilets_number": "Aseos",
    "property-filter.useful_surface": "Superficie \u00fatil",
    "property-types": "Tipos",
    "property.bathrooms-number": "Ba\u00f1os",
    "property.bedrooms-number": "Dormitorios",
    "property.block": "Bloque",
    "property.built-surface": "Superficie construida",
    "property.buyer": "Comprador",
    "property.buyer-search": "Buscar compradores",
    "property.cadastral-reference": "Referencia catastral",
    "property.common-zones-surface": "Superficie de zonas comunes",
    "property.contact": "Contacto",
    "property.data": "Datos del inmueble",
    "property.detailed-spaces": "Espacios detallados",
    "property.doorway": "Portal",
    "property.estate": "Finca",
    "property.floor": "Piso",
    "property.garages-number": "Garajes",
    "property.ladder": "Escalera",
    "property.letter": "Letra",
    "property.name": "Nombre del inmueble",
    "property.orientation": "Orientaci\u00f3n",
    "property.price": "Precio",
    "property.propertyType": "Tipo de inmueble",
    "property.reference": "Referencia",
    "property.rental": "Precio de alquiler",
    "property.reserved-date": "Fecha reservada",
    "property.room.name": "Nombre del espacio",
    "property.room.select_type": "Selecciona un tipo de espacio",
    "property.room.surface": "Superficie",
    "property.room.type": "Tipo",
    "property.select_orientation": "Selecciona",
    "property.select_propertyState": "Selecciona un estado del inmueble",
    "property.select_propertyType": "Selecciona tipo de inmueble",
    "property.select_residential-development": "Selecciona una promoci\u00f3n",
    "property.state": "Estado",
    "property.storage-rooms-number": "Trastero",
    "property.technical-data": "Datos t\u00e9cnicos",
    "property.terrace": "Terraza \/ Jard\u00edn",
    "property.toilets-number": "Aseos",
    "property.useful-surface": "Superficie \u00fatil",
    "property.user": "Usuario",
    "province": "Provincia",
    "province_id": "Provincia",
    "publish": "Publicar",
    "published": "Publicado",
    "purchase-agreement": "Contrato compra",
    "quality-specifications": "Memoria calidad",
    "real-date": "Fecha real",
    "real-shopfront-date": "Fecha aut\u00e9ntica  de la tienda",
    "real-unit-date": "Unidad de fecha aut\u00e9ntica",
    "reference": "Referencia",
    "purchaseAgreement": "Contrato compraventa",
    "quality_specifications": "Memoria de calidades",
    "reject": "Rechazar",
    "reject-close-month-modal.specify-reason": "Especifique la raz\u00f3n a la tienda",
    "reject-close-month-modal.title": "Rechazo del cierre de mes",
    "relocation": "Relocation",
    "remove": "Borrar",
    "rent": "Alquiler",
    "rental": "Alquiler",
    "required": "{{attr}} obligatorio",
    "reset-password": "Resetear Password",
    "residence_card": "Tarjeta de residencia",
    "residential-development": "En promoci\u00f3n",
    "residential-development.development": "Promoci\u00f3n",
    "residential-development.not_found": "En promoci\u00f3n no encontrado",
    "residential-documentation-sent.deed-warning-text": "Tras introducir la fecha de la escritura la oferta se cerrar\u00e1.  No podr\u00e1s modificar ninguna de las fechas anteriores",
    "residential-offer-close.rejected-common-zones": "Zonas comunes",
    "residential-offer-close.rejected-delivery-date": "Fecha de entrega",
    "residential-offer-close.rejected-other": "Otro",
    "residential-offer-close.rejected-other-reason": "Otras razones que no estaban en la lista",
    "residential-offer-close.rejected-pay-form": "Formulario de pago",
    "residential-offer-close.rejected-price": "Precio",
    "residential-offer-close.rejected-project": "Proyecto",
    "residential-offer-close.rejected-reasons": "Razones rechazadas",
    "residential-offer.accepted": "Aceptado",
    "residential-offer.closing-notes": "Notas de cierre",
    "residential-offer.closingDate": "Fecha de cierre",
    "residential-offer.info_sent": "Informaci\u00f3n enviada",
    "residential-offer.notes": "Notas",
    "residential-offer.open": "Abierto",
    "residential-offer.openingDate": "Fecha de apertura",
    "residential-offer.pending": "Pendiente",
    "residential-offer.price": "Precio",
    "residential-offer.rejected": "Rechazado",
    "residential-offer.select_new_state": "Seleccionar nuevo estado",
    "residential-offer.sent_info_dossier": "Dossier",
    "residential-offer.sent_info_other": "Otro",
    "residential-offer.sent_info_property_map": "Mapa del inmueble",
    "residential-offer.sent_info_qualities_note": "Nota de  calidades",
    "residential-offer.signed": "Firmado",
    "residential-offers.price": "Precio",
    "residential-owner": "Propietario",
    "residential-owner.not_found": "Propietarios no encontrado",
    "residential-properties.bedrooms": "Dormitorios",
    "residential-properties.location": "Ubicaci\u00f3n",
    "residential-properties.price": "Precio",
    "residential-properties.property": "Inmueble",
    "residential-properties.rooms": "Espacios",
    "residential-properties.surface": "Superficie",
    "residential-properties.type": "Tipo",
    "residential-property.booking-documentation": "Documentaci\u00f3n de  reserva",
    "residential-property.booking-payment": "Pago de reserva",
    "residential-property.deed": "Escritura",
    "residential-property.edit": "Editar inmueble",
    "residential-property.monthly-payment-system": "Sistema de pago mensual",
    "residential-property.new": "Nuevo inmueble",
    "residential-property.state.blocked": "Bloqueado",
    "residential-property.state.free": "Disponible",
    "residential-property.state.reserved": "Reservado",
    "residential-property.state.sold": "Vendido",
    "residential-property.trading": "CCV",
    "residential-proposal.close_offer": "Cerrar oferta",
    "residential-request": "Solicitud",
    "residential-room.type.bathroom": "Ba\u00f1o",
    "residential-room.type.bedroom": "Dormitorio",
    "residential-room.type.dressing-room": "Vestidor",
    "residential-room.type.garage": "Garaje",
    "residential-room.type.hall": "Entrada",
    "residential-room.type.kitchen": "Cocina",
    "residential-room.type.laundry-room": "Lavadero",
    "residential-room.type.living-room": "Sal\u00f3n",
    "residential-room.type.lobby": "Vest\u00edbulo",
    "residential-room.type.storage": "Trastero",
    "residential-room.type.toilet": "Aseo",
    "residential-rooms-required": "Name, type and surface of each room are required",
    "residential-sale-process.document_sent": "Documentaci\u00f3n enviada",
    "residential-user-state.accepted": "Aceptado",
    "residential-user-state.new": "Nuevo",
    "residential-user-state.open": "Abierto",
    "residential-user-state.rejected": "Rechazado",
    "residential-user-state.request": "Inter\u00e9s",
    "residential-user-state.signed": "Firmado",
    "residential.add_files_button": "A\u00f1adir archivos",
    "residential.add_files_title": "A\u00f1adir archivos",
    "residential.add_link_button": "A\u00f1adir link",
    "residential.add_video_link": "A\u00f1adir url video",
    "residential.campaign.edit": "Editar campa\u00f1a",
    "residential.campaign.name": "Nombre",
    "residential.campaign.new": "Nueva Campa\u00f1a",
    "residential.building_state": "Estado de la construcci\u00f3n",
    "residential.commercialization": "Comercializaci\u00f3n",
    "residential.community_expenses": "Gastos de comunidad",
    "residential.countries": "Pa\u00edses",
    "residential.currency": "Divisa",
    "residential.delivery_date": "Fin de obra",
    "residential.developer-contact": "Contacto del promotor",
    "residential.development": "Promoci\u00f3n",
    "residential.development.buildingState": "Estado de la construcci\u00f3n",
    "residential.development.location": "Localizaci\u00f3n",
    "residential.development.name": "Nombre",
    "residential.development.owner": "Propietario",
    "residential.development.reference": "Referencia de la  promoci\u00f3n",
    "residential.development.tu": "T.U.",
    "residential.development.zone": "Zona",
    "residential.development_maps": "Plano de promoci\u00f3n",
    "residential.developments": "Promociones",
    "residential.discharge_date": "Inicio de obra",
    "residential.documentation": "Documentaci\u00f3n",
    "residential.dossiers": "Informe de Marketing",
    "residential.end_of_construction": "Fin de obra",
    "residential.energy_certification": "Certificaci\u00f3n Energ\u00e9tica",
    "residential.energy_certification.a": "A",
    "residential.energy_certification.b": "B",
    "residential.energy_certification.c": "C",
    "residential.energy_certification.d": "D",
    "residential.energy_certification.e": "E",
    "residential.energy_certification.f": "F",
    "residential.energy_certification.g": "G",
    "residential.general_data": "Datos generales",
    "residential.general_info": "Informaci\u00f3n general",
    "residential.how_to_arrive_map": "Plan \"C\u00f3mo llegar\"",
    "residential.images": "Im\u00e1genes",
    "residential.in_construction": "En construcci\u00f3n",
    "residential.location": "Ubicaci\u00f3n",
    "residential.management": "Gesti\u00f3n",
    "residential.maps": "Planos de planta",
    "residential.multimedia": "Archivos y multimedia",
    "residential.other_data": "Otros datos",
    "residential.owner": "Propietario",
    "residential.owner.code": "C\u00f3digo",
    "residential.owner.edit": "Editar propietario residencial",
    "residential.owner.fiscal-name": "Nombre fiscal",
    "residential.owner.logo": "Logotipo",
    "residential.owner.name": "Nombre",
    "residential.owner.new": "Nuevo propietario residencial",
    "residential.owner.vat-number": "NIF\/CIF",
    "residential.owner_code": "C\u00f3digo del propietario",
    "residential.post_sale": "Post-venta",
    "residential.presale": "Precomercializaci\u00f3n",
    "residential.properties": "Inmuebles",
    "residential.property": "Vivienda",
    "residential.property-types": "Tipos",
    "residential.property.development_documentation": "Documentaci\u00f3n de la promoci\u00f3n",
    "residential.property.development_multimedia_files": "Archivos multimedia de la promoci\u00f3n",
    "residential.property.reserved-date": "Fecha reservada",
    "residential.property.reserved-notes": "Notas",
    "residential.property_maps": "Planos del Inmueble",
    "residential.province": "Provincia",
    "residential.provinces": "Provincias",
    "residential.qualities": "Calidades",
    "residential.responsible": "Contacto",
    "residential.rooms": "Espacios",
    "residential.sale": "Venta",
    "residential.select_building_state": "Selecciona estado de la construcci\u00f3n",
    "residential.select_commercialization": "Selecciona comercializaci\u00f3n",
    "residential.select_currency": "Selecciona divisa",
    "residential.select_energy_certification": "Selecciona certificaci\u00f3n energ\u00e9tica",
    "residential.select_management": "Selecciona gesti\u00f3n",
    "residential.select_owner": "Selecciona propietario",
    "residential.select_province": "Selecciona provincia",
    "residential.select_unit_type": "Selecciona tipo de unidad",
    "residential.services": "Servicios",
    "residential.services_and_qualities": "Servicios y calidades",
    "residential.start_work": "Inicio de obra",
    "residential.technical_data": "Datos t\u00e9cnicos",
    "residential.unit_type": "Tipo de unidad",
    "residential.video": "V\u00eddeo",
    "residential.zone_services": "Zona de servicio",
    "residential_offers.closing": "Oferta de cierre",
    "residential_offers.documentation-procedure": "Tr\u00e1mite documentaci\u00f3n",
    "residential_offers.document-sent": "Documentaci\u00f3n enviada",
    "residential_offers.new": "Nueva oferta",
    "residential_property": "Inmueble",
    "residential_proposal": "Propuesta",
    "response": "Respuesta",
    "sale": "Venta",
    "sale-process.add-request": "A\u00f1adir solicitud",
    "sale-process.closing": "Cierre",
    "sale-process.proposal": "Propuesta",
    "sale-process.proposals": "Propuestas",
    "sale-process.request.date": "Fecha",
    "sale-process.request.name": "Nombre",
    "sale-process.request.properties-available": "Disponible",
    "sale-process.request.properties-blocked": "Bloqueados",
    "sale-process.requests": "Peticiones",
    "sale.amount": "Cantidad",
    "sale.concept": "Conceptos de venta",
    "sale.commerce": "Tienda",
    "sale.date": "Fecha",
    "sale.edit": "Editar ventas",
    "sale.name": "Nombre",
    "sale.new": "Nueva venta",
    "sale.not_found": "No se han encontrado ventas",
    "sales": "Ventas",
    "sales.back": "Volver",
    "sales.net": "* Ventas netas (IVA no inclu\u00eddo)",
    "sales.select_a_shop": "Selecciona una tienda para a\u00f1adir una nueva venta",
    "sales.select_valid_date": "No puedes a\u00f1adir ventas para fechas futuras. Por favor, selecciona un d\u00eda v\u00e1lido en el calendario.",
    "sales.shops_not_found": "Selecciona una tienda para a\u00f1adir una nueva venta",
    "same-period-last": "Mismo periodo pasado {{period}}",
    "sanitation": "Saneamiento",
    "save": "Guardar {{modelName}}",
    "save.datetime.cancel": "Cancelar",
    "save.datetime.ok": "Aceptar",
    "scmanager": "SC Manager",
    "sc-manager": "SC Manager",
    "search": "Buscar",
    "second-invoice-amount": "Segundo importe facturado",
    "second-invoice-date": "Fecha de la segunda factura",
    "segment": "Segmento",
    "segment.not_found": "No se encontraron segmentos",
    "segment.save_segment": "Guardar segmento",
    "segment.total_users_reached": "Total de usuarios alcanzados",
    "segment.type_find": "Escribe para encontrar segmentos",
    "segment.warning_update_users": "La campa\u00f1a ser\u00e1 enviada diariamente a los nuevos usuarios que cumplan las siguientes condiciones",
    "segments.filters_needed": "Al menos un filtro es obligatorio",
    "segments.save_as": "Guardar como {{modelName}}",
    "segments.save_changes": "Guardar cambios",
    "segments.save_new": "Guardar como nuevo {{modelName}}",
    "segments.type_name": "Escribe el {{modelName}} nombre",
    "segments.warning": "Aviso!",
    "segments.type_segment_name": "Escribe el nombre del segmento...",
    "select": "Seleccionar",
    "select-brand": "Seleccionar marca",
    "select-category": "Seleccionar una categor\u00eda",
    "select-center": "Seleccionar un centro",
    "select-building": "Selecciona edificio",
    "select-company": "Selecciona compa\u00f1ia",
    "select-fit-out-coordinator-cbre": "Seleccionar coordinador de CBRE",
    "select-fit-out-coordinator-external": "Seleccionar un coordinador externo",
    "select-range": "Seleccionar intervalo",
    "select-fit-out-coordinator": "Seleccionar coordinador de ajuste",
    "select-shop": "Select shop",
    "select-state": "Select state",
    "select-leasing-center-type": "Select center type",
    "select-level": "Selecciona planta",
    "select-visibility": "Selecciona visibilidad",
    "select-zones": "Selecciona zonas",
    "select_all": "Seleccionar todo",
    "select_communication_required_attention": "Selecciona si la comunicaci\u00f3n requiere atenci\u00f3n urgente",
    "select_month": "Selecciona un mes",
    "select_one_option": "Selecciona una opci\u00f3n",
    "select_year": "Selecciona un a\u00f1o",
    "send": "Enviar",
    "send-email-template.help.multipleEmails": "Introduce m\u00faltiples direcciones de e-mail separadas por comas",
    "send_economic_proposal": "Env\u00edar propuesta econ\u00f3mica",
    "send_email": "Enviar e-mail",
    "services-and-qualities": "Servicios y calidades",
    "sex": "G\u00e9nero",
    "sent": "Enviados",
    "shop.all": "Todas",
    "shopMonth.state.closed": "Cerrado",
    "shopMonth.state.unclosed": "Abierto",
    "shopMonth.state.validated": "Validado",
    "shopMonth.state.without_sales": "Sin ventas",
    "shopfront": "Tienda",
    "shops": "Tiendas",
    "shops.not_found": "No se encontraron tiendas",
    "show-children": "Mostrar hijos",
    "show-details": "Mostrar detalle",
    "show-development": "Mostrar promoci\u00f3n",
    "show-price": "Mostrar precio",
    "sign": "Firma",
    "signing-date": "Fecha de firma",
    "single": "Soltero",
    "single_family": "Unifamiliar",
    "sold": "Vendido",
    "south": "Sur",
    "spreadsheet": "Hoja de c\u00e1lculo",
    "staff": "Equipo",
    "staff-manager": "Staff managers",
    "staff.level.admin": "Administrador",
    "staff.level.commerce": "Tienda",
    "staff.level.crmmanager": "Gestor CRM",
    "staff.level.crmoperator": "Operador CRM",
    "staff.level.manager": "Gestor",
    "staff.level.owner": "Propietario",
    "staff.level.scmanager": "SC Manager",
    "staff.level.storeManager": "Gestor tienda",
    "staff.level.weboperator": "Operador web",
    "start-date": "Fecha inicio",
    "start_date": "Fecha de inicio",
    "start_datetime": "Inicio de la fecha",
    "state": "Estado",
    "status": "Estado",
    "storage-plan": "Planos trasteros",
    "storage-rooms-number": "N\u00ba Trasteros",
    "store-manager": "Gestor Tienda",
    "start_work": "Inicio de trabajo",
    "subtype": "Subtipo",
    "subtype.edit": "Editar subtipo",
    "subtype.name": "Nombre",
    "subtype.new": "Nuevo subtipo",
    "subtype.type": "Tipo",
    "subway": "Metro",
    "supply-contracts": "Contratos de suministros",
    "table-list.not_found": "No hay elementos disponibles",
    "taxpayer_number": "N\u00famero de identificaci\u00f3n fiscal",
    "technical-data": "Dato t\u00e9cnico",
    "technical-direction": "Direcci\u00f3n t\u00e9cnica",
    "temporary-hoarding": "Acumulaci\u00f3n temporal",
    "terrace-template-approval-date": "Fecha de aprobaci\u00f3n del modelo de terraza",
    "terrace-template-delivery-date": "Fecha de entrega del modelo de la terraza",
    "terrace-template-reviews": "Revisi\u00f3n sobre el modelo de terraza",
    "thecnical-data": "Datos t\u00e9cnicos",
    "third-invoice-amount": "Tercer importe facturado",
    "third-invoice-date": "Fecha de la tercera factura",
    "thumbnail": "Miniatura",
    "title.communication.subtypes": "Subtipos de comunicaci\u00f3n",
    "title.header.communication": "Comunicaciones",
    "title.header.configuration": "Configuraci\u00f3n",
    "title.header.configuration.dashboard": "Configuraci\u00f3n del panel",
    "title.header.crm.email-templates": "Plantillas de e-mail",
    "title.header.crm.users": "Clientes",
    "title.header.dashboards.show": "Panel",
    "title.header.desktop": "Escritorio",
    "title.header.ecop.works": "Trabajos de ECOP",
    "title.header.emailing.campaigns": "Campa\u00f1as de e-mail",
    "title.header.files": "Gesti\u00f3n de archivos",
    "title.header.guards": "Guardias",
    "title.header.leasing.brands": "Brands",
    "title.header.leasing.centers": "Centers",
    "title.header.leasing.contacts": "Contacts",
    "title.header.leasing.operations": "Operations",
    "title.header.leasing.owners": "Propietarios",
    "title.header.leasing.units": "Unidades",
    "title.header.residential.campaigns": "Campa\u00f1as",
    "title.header.residential.developments": "Promociones",
    "title.header.residential.owners": "Propietarios",
    "title.header.residential.properties": "Inmuebles",
    "title.header.sales": "Gesti\u00f3n de ventas",
    "title.header.subtypes": "Tipos de comunicaci\u00f3n",
    "title.header.testing": "T\u00edtulo de prueba",
    "tittle.communication.subtypes": "Subtipos de comunicaciones",
    "tittle.header.configuration": "Configuraci\u00f3n",
    "tittle.header.configuration.dashboard": "Configuraci\u00f3n del panel de control",
    "tittle.header.dashboards.show": "Panel de control",
    "tittle.header.crm.email-templates": "Plantillas de correo electr\u00f3nico",
    "tittle.header.emailing.campaigns": "Campa\u00f1as de e-mailing",
    "tittle.header.files": "Gesti\u00f3n de archivos",
    "tittle.header.guards": "Guardias",
    "tittle.header.sales": "Gesti\u00f3n de ventas",
    "tittle.header.subtypes": "Tipos de comunicaci\u00f3n",
    "today": "Hoy",
    "token": "Token",
    "total": "Total",
    "total-costs": "Costes totales",
    "total-units": "Total unidades",
    "total_sales": "Total en ventas",
    "train": "Tren",
    "type": "Tipo",
    "type-find": "Buscar tipo",
    "unit": "Unidad",
    "unit-handover": "Unidad de traspaso",
    "unit-or-location-rejected": "Unidad rechazada",
    "unit-type": "Tipo unidad",
    "unpublish": "Retirar",
    "upcoming-commercialization": "Pr\u00f3xima comercializaci\u00f3n",
    "update-password.server-problem": "Hay un problema con el servidor. Contacta con el equipo de soporte. Gracias",
    "upload": "Upload",
    "upsize-downsize": "Upsize-Downsize",
    "type_find_commerce": "Escribe para filtrar por tiendas",
    "urgent": "Urgente",
    "useful-surface": "Sup. \u00datil",
    "user-error": "Something is wrong. Please check the following errors: ",
    "user.actions": "Acciones",
    "user.address": "Direcci\u00f3n",
    "user.birthdate": "Fecha de nacimiento",
    "user.campaigns": "Campa\u00f1as",
    "user.channels": "Canales",
    "user.birthday": "Cumplea\u00f1os",
    "user.children_number": "N\u00famero de ni\u00f1os",
    "user.city": "Ciudad",
    "user.code": "C\u00f3digo de cliente",
    "user.company": "Empresa",
    "user.countries": "Pa\u00edses",
    "user.cp": "C\u00f3digo postal",
    "user.created_at": "Fecha de registro{{mandatory}}",
    "user.dni": "C.I.F.",
    "user.document_type": "Tipo de documento",
    "user.electronic_communications_phrase": "Quiero recibir comunicaciones electr\u00f3nicas comerciales",
    "user.email": "Email",
    "user.gdpr_phrase": "Acepto politicas de uso y privacidad",
    "user.general_data": "Datos generales",
    "user.last_name": "Apellido",
    "user.lopd_phrase": "Acepto la LOPD",
    "user.marital_status": "Estado civil",
    "user.marketing_data": "Marketing",
    "user.nacionality": "Nacionalidad",
    "user.name": "Nombre",
    "user.nationality": "Nacionalidad",
    "user.notes": "Notas",
    "user.other_data": "Otros datos",
    "user.partners_electronic_communications_phrase": "Quiero recibir comunicaciones electr\u00f3nicas de terceros",
    "user.person_type": "Tipo de persona",
    "user.phone": "Tel\u00e9fono",
    "user.province": "Provincia",
    "user.secondary_phone": "Tel\u00e9fono secundario",
    "user.select_document_type": "Selecciona tipo de documento",
    "user.select_marital_status": "Selecciona estado civil",
    "user.select_nationality": "Selecciona Nacionalidad",
    "user.select_nacionality": "selecciona nacionalidad",
    "user.select_person_type": "Un formulario vac\u00edo",
    "user.select_province": "Selecciona provincia",
    "user.select_sex": "Selecciona g\u00e9nero",
    "user.sex": "G\u00e9nero",
    "user.valid_datetime": "Valid datetime",
    "users.edit": "Editar cliente",
    "users.general_data": "Datos generales",
    "users.new": "Nuevo cliente",
    "users.other_data": "Otros datos",
    "users.sale_process": "Proceso de venta",
    "value": "Valor",
    "vat-number": "NIF\/CIF",
    "vat_number": "D.N.I.",
    "ventilation": "Ventilaci\u00f3n",
    "video": "Video",
    "vinyl": "Vinilo",
    "vinyl-design": "Dise\u00f1o de vinilo",
    "visibility": "Visibilidad",
    "visits": "Visitas",
    "visits.recurrence": "Recurrencia",
    "want_export": "Select additional data to export {{modelName}}",
    "want_remove": "\u00bfEst\u00e1s seguro de querer eliminar este {{modelName}}?",
    "want_save_without_gdpr_or_electronic_communications": "No se ha marcado \"T\u00e9rmino y condiciones\" o \"Env\u00edo de comunicaciones\". \u00bfQuieres continuar?",
    "want_save_without_electronic_communications": "Quieres guardar la ficha sin permitir comunicaciones electr\u00f3nicas?",
    "warning": "Aviso",
    "warning-residential-change-text": "Los cambios pueden afectar a servicios externos. Quiere continuar?",
    "warning-title": "Aviso",
    "warning_enter_sales": "Certificado de ventas netas (sin IVA) llevadas a cabo en el local de referencia durante el per\u00edodo se\u00f1alado, las cuales coinciden fielmente con los documentos justificativos y los apuntes contables reflejados en los libros de la empresa",
    "waterkmark": "Watermark",
    "web": "Web",
    "web-operator": "Operador web",
    "week": "Semana",
    "weekday.friday": "Viernes",
    "weekday.monday": "Lunes",
    "weekday.saturday": "S\u00e1bado",
    "weekday.sunday": "Domingo",
    "weekday.thursday": "Jueves",
    "weekday.tuesday": "Martes",
    "weekday.wednesday": "Mi\u00e9rcoles",
    "west": "Oeste",
    "without-vat": "Without vat",
    "wizard.next_step": "Siguiente",
    "wizard.prev_step": "Anterior",
    "woman": "Mujer",
    "work-budget": "Presupuesto de trabajo",
    "work-permit": "Permiso de trabajo",
    "work-permit-approval": "Aprobaci\u00f3n permiso de trabajo",
    "work-permit-request": "Solicitud permiso de trabajo",
    "work-planning": "Planificaci\u00f3n del trabajo",
    "work-start": "Inicio del trabajo",
    "workplace-permit": "Permiso lugar de trabajo",
    "yardi": "Yardi",
    "yardi-code": "C\u00f3digo Yardi",
    "yardi.continue_export": "Continuar con la exportaci\u00f3n",
    "yardi.shop_property_error": "La tienda necesita una propiedad vinculada",
    "yardi.tenant_code_error": "La tienda necesita un c\u00f3digo de inquilino espec\u00edfico",
    "yardi.warn_errors_exporting": "Hay errores en la exportaci\u00f3n. Por favor, rev\u00edselos antes de continuar.",
    "year": "A\u00f1o",
    "yen": "Yen \u00a5",
    "yes": "Si",
    "youtube.url": "URL Youtube",
    "zip-code": "C.Postal",
    "zone": "Zona",
    "zone-services": "Zona servicios",
    "residential.multimedia_files": "Archivos multimedia",
    "segments.save_new_segment": "Guardar como nuevo segmento",
    "save_segment": "Guardar segmento",
    "segments.save_as_segment": "Guardar como segmento",
    "close_months": "Cerrar meses"
};