import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

import formatDate from 'neuro-frontend/utils/dates/format-date';
import Day from 'neuro-frontend/utils/day';

export default class extends Component.extend({
  chartStart: null,
  chartEnd: null,
  chartData: [],
}) {
  colors = [
    '#c489a1',
    '#696e8c',
    '#a63165',
    '#5bc490',
    '#f8b827',
    '#367c86',
  ]

  @computed('startDate', 'endDate')
  get daysLength() {
    let firstDay = new Day(this.startDate);
    const endDay = new Day(this.endDate);
    let daysLength = 1;

    if (firstDay.isAfter(endDay)) {
      return 1;
    }

    while (!firstDay.isSame(endDay)) {
      firstDay = firstDay.add(1, 'day');
      daysLength = 1 + daysLength;
    }

    return daysLength;
  }

  @computed
  get firstChartDate() {
    const firstDate = new Day(this.startDate).subtract(2, 'day').value;

    return new Date(firstDate);
  }

  @computed('chartStart')
  get startDate() {
    const { chartStart } = this;
    const startDate = new Date(formatDate(chartStart, 'DD/MM/YYYY', 'YYYY-MM-DD'));

    return (chartStart && startDate instanceof Date)
      ? startDate
      : new Date();
  }

  @computed('chartEnd')
  get endDate() {
    const { chartEnd } = this;
    const endDate = new Date(formatDate(chartEnd, 'DD/MM/YYYY', 'YYYY-MM-DD'));

    return (chartEnd && endDate instanceof Date)
      ? endDate
      : new Date();
  }

  @computed('chartData')
  get project() {
    const {
      colors,
      endDate,
      startDate,
      chartData,
    } = this;

    const jobs = (chartData && chartData.length)
      ? chartData
        .map((data, index) => {
          const jobStartDate = formatDate(data.startDate, 'DD/MM/YYYY', 'YYYY-MM-DD');
          const jobEndDate = formatDate(data.endDate, 'DD/MM/YYYY', 'YYYY-MM-DD');
          return {
            name: data.name,
            startDate: new Date(jobStartDate),
            endDate: new Date(jobEndDate),
            color: colors[index % colors.length],
          };
        })
        .sort((a, b) => this.sortDateDesc(a, b))
      : [];

    return {
      collapsed: false,
      title: 'Gantt',
      startDate,
      endDate,
      jobs,
      sorting: null,
    };
  }

  didRender() {
    const elementWidth = get(this, 'element.scrollWidth');
    const dayWidth = (2 * elementWidth) / (3 * this.daysLength);

    set(this, 'dayWidth', dayWidth);
    window.dispatchEvent(new Event('resize'));
  }

  sortDateDesc(a, b) {
    const startDate1 = new Day(a.startDate);
    const startDate2 = new Day(b.startDate);

    if (startDate2.isAfter(startDate1)) return -1;
    if (startDate1.isAfter(startDate2)) return 1;
    return this.sortByEndDate(new Day(a.endDate), new Day(b.endDate));
  }

  sortByEndDate(a, b) {
    if (b.isAfter(a)) return -1;
    if (a.isAfter(b)) return 1;
    return 0;
  }
}
