import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';

@classNames('nf-chart-stacked')
export default class extends Component.extend({
    chartData: [],

    height: 500,

    rotated: true,

    type: 'bar',
  }) {
  bar = {
    width: {
      ratio: 0.3,
    },
  }

  @computed('categories')
  get axis() {
    const {
      categories,
    } = this;

    return {
      rotated: this.rotated,
      x: {
        type: 'category',
        categories,
        padding: {
          top: 0,
          bottom: 0,
        },
      },
      y: {
        tick: {
          format: (num) => num.toLocaleString('es-ES', { maximumFractionDigits: 2 }).concat('%'),
        },
        padding: {
          top: 0,
          bottom: 0,
        },
      },
    };
  }

  @computed('chartData')
  get categories() {
    const {
      chartData,
    } = this;

    if (!chartData.length) return [];

    return chartData.map((el) => el.category);
  }

  @computed('colors')
  get color() {
    const { colors } = this;
    const color = {
      pattern: colors || ['#876695', '#678AE0'],
    };
    return color;
  }

  @computed('chartData', 'groups')
  get data() {
    const {
      chartData,
      groups,
    } = this;

    if (!chartData.length || !groups.length) {
      return {};
    }

    return {
      columns: groups.map((group) => [group, ...chartData.map((el) => el[group] || 0)]),
      type: this.type,
      groups: [groups],
    };
  }

  @computed('chartData')
  get groups() {
    const {
      chartData,
    } = this;

    if (!chartData.length) {
      return [];
    }

    return Object.keys(chartData[0]).filter((key) => 'category' !== key);
  }

  @computed('height')
  get size() {
    return {
      height: this.height,
    };
  }

  @computed('zoomEnabled')
  get zoom() {
    const { zoomEnabled } = this;
    const zoom = {
      enabled: zoomEnabled || false,
    };
    return zoom;
  }
}
