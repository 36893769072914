/* global Inputmask */
import MdInput from 'ember-cli-materialize/components/md-input';

Inputmask.extendAliases({
  EUR: {
    alias: 'currency',
    prefix: '',
    suffix: ' €',
  },
  USD: {
    alias: 'currency',
    prefix: '$ ',
    suffix: '',
  },
  JPY: {
    alias: 'currency',
    prefix: '¥ ',
    suffix: '',
  },
});

export default class extends MdInput.extend({
  mask: 'EUR',
}) {
  validate = true
}
