import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';

export default class extends Component.extend({
  isSequential: false,

  showStepsButtons: true,

  currentStep: 1,
}) {
  classNames = ['nf-wizard']

  @computed('stepsInfo', 'currentStep', 'isSequential')
  get _parsedHeaderTabs() {
    const stepsInfo = get(this, 'stepsInfo');
    const currentStep = get(this, 'currentStep');
    const isSequential = get(this, 'isSequential');

    return (stepsInfo || []).map((step) => {
      set(step, 'isDisabled', isSequential ? step.stepId > currentStep : false);
      return step;
    });
  }

  @computed('stepsInfo', 'currentStep')
  get isFirstStep() {
    const stepsInfo = get(this, 'stepsInfo');
    const currentStep = get(this, 'currentStep');

    return get(stepsInfo, 'firstObject.stepId') === currentStep;
  }

  @computed('stepsInfo', 'currentStep')
  get isLastStep() {
    const stepsInfo = get(this, 'stepsInfo');
    const currentStep = get(this, 'currentStep');

    return get(stepsInfo, 'lastObject.stepId') === currentStep;
  }

  didRender() {
    const {
      stepsInfo,
    } = this;
    const tabs = document.querySelectorAll('.nf-wizard-tab');

    if (0 === tabs.length) {
      tabs.forEach((tab, i) => {
        set(stepsInfo[i], 'error', tab.querySelectorAll('.invalid').length);
      });
    } else {
      stepsInfo.forEach((step, i) => {
        set(stepsInfo[i], 'error', tabs[i].querySelectorAll('.invalid').length);
      });
    }

    window.dispatchEvent(new Event('resize'));
  }

  @action
  goToStep(stepId) {
    // @TODO Do we REALLY need an isValidStep?
    // it's an Array, we may move between the positions of the Array
    const isValidStep = get(this, 'stepsInfo').some((step) => stepId === step.stepId);

    if (isValidStep && this.onChangeStep) {
      // the action is send later because we may want to reset the currentStep counter
      this.onChangeStep(stepId);
    } else {
      set(this, 'currentStep', stepId);
    }
  }
}
