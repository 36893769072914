export default Object.freeze([
  {
    label: i18n.t('euros'),
    symbol: '€',
    value: 'EUR',
  },
  {
    label: i18n.t('dollars'),
    symbol: '$',
    value: 'USD',
  },
  {
    label: i18n.t('yen'),
    symbol: '¥',
    value: 'JPY',
  },
]);
