import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';

export default class NfInfoComponent extends Component.extend({
  canBeClosed: true,

  errorMessage: i18n.t('info.default.error.message'),

  errorTitle: i18n.t('info.default.error.title'),

  successMessage: i18n.t('info.default.success.message'),

  successTitle: i18n.t('info.default.succes.title'),

}) {
  classNameBindings = ['state']

  classNames = ['nf-info']

  @computed('state')
  get isVisible() {
    return ['error', 'success', 'warning', 'info'].includes(this.state);
  }

  @computed('state')
  get message() {
    return get(this, `${this.state}Message`);
  }

  @computed('state')
  get title() {
    return get(this, `${this.state}Title`);
  }

  @action
  closeMessage() {
    set(this, 'state', '');
  }
}
