import Component from '@ember/component';
import { restartableTask } from 'ember-concurrency-decorators';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import {
  action, computed, get, set, setProperties,
} from '@ember/object';

import moment from 'moment';
import $ from 'jquery';

import getLastMonths from 'neuro-frontend/utils/get-last-months';
import Year from 'neuro-frontend/utils/year';
import Month from 'neuro-frontend/utils/month';
import formatDate from 'neuro-frontend/utils/dates/format-date';

@classNames('monthly-report', 'row')
export default class DashboardMonthlyReportComponent extends Component {
  @service api

  @service auth

  @service stats

  chartData = {}

  activeUsers = {
    month: [],
    year: [],
  }

  registeredUsers = {
    month: [],
    year: [],
  }

  visits = {
    month: [],
    year: [],
  }

  competitionVisits = {
    month: [],
    year: [],
  }

  months = getLastMonths(12)

  selectedMonth = new Date();

  userProfiles = {
    year: [],
  }

  zoomEnabled

  axes = {
    [i18n.t('dashboards.active-users')]: 'y',
    [i18n.t('dashboards.per-active-users')]: 'y2',
  }

  activeUsersChartColors = ['#9F549D', '#2FA095'];

  ageChartColors = ['#B06B87', '#D59CCF', '#A490C0', '#51657A', '#BCC4DB', '#96A0A4'];

  catchmentAreaChartChartColors = ['#0095C3', '#736BAE', '#965095', '#A63165'];

  dwellChartColors = ['#A78FCB', '#8BF4A1'];

  familyChartColors = ['#8DCBC5', '#837DB5', '#3C4F76', '#96A0A4'];

  genderChartColors = ['#367C86', '#5BC490', '#4DD0E1', '#96A0A4'];

  recurrenceChartColors = ['#85779A', '#A2E27C'];

  registrationRateChartColors = ['#8378C9'];

  registeredTotalUsersChartColors = ['#9F549D', '#2FA095'];

  registeredUsersChartColors = ['#367C86', '#5BC490'];

  visitsCompetitionChartColors = ['#51657A', '#5BC490'];

  @computed(
    'fetchActiveUsersMonth.isRunning',
    'fetchActiveUsersYear.isRunning',
    'fetchRegisteredUsersMonth.isRunning',
    'fetchRegisteredUsersYear.isRunning',
    'fetchVisitsMonth.isRunning',
    'fetchVisitsYear.isRunning',
    'fetchUserProfilesYear.isRunning',
  )
  get isLoading() {
    return this.fetchActiveUsersMonth.isRunning
      || this.fetchActiveUsersYear.isRunning
      || this.fetchRegisteredUsersMonth.isRunning
      || this.fetchRegisteredUsersYear.isRunning
      || this.fetchVisitsMonth.isRunning
      || this.fetchVisitsYear.isRunning
      || this.fetchUserProfilesYear.isRunning;
  }

  @computed('activeUsers.year', 'registeredUsers.year', 'headers')
  get activeUsersChart() {
    const {
      activeUsers: {
        year: activeUsers,
      },
      headers,
      registeredUsers: {
        year: registeredUsers,
      },
    } = this;

    if (!activeUsers.length || !registeredUsers.length) {
      return null;
    }
    const perActiveUsers = registeredUsers.map((registered, i) => {
      if (!activeUsers[i]) {
        return 0;
      }

      return activeUsers[i].active_users / registered.total_app_users;
    });

    return [
      ['x', ...headers],
      [i18n.t('dashboards.active-users'), ...this.parseData(activeUsers, headers, 'active_users')], // sort by month
      [i18n.t('dashboards.per-active-users'), ...perActiveUsers], // sort by month
    ];
  }

  @computed('activeUsers.month')
  get activeUsersData() {
    return {
      value: this.getTotal(this.activeUsers.month, this.month, 'active_users'),
      prevValue: this.getTotal(this.activeUsers.month, this.prevMonth, 'active_users'),
      average: this.getAverage(this.activeUsers.month, this.month, 'active_users'),
    };
  }

  @computed('userProfiles.year', 'headers')
  get ageChart() {
    const {
      userProfiles: {
        year: userProfiles,
      },
      headers,
    } = this;

    if (!userProfiles.length) {
      return null;
    }

    const parsedData = this.parseData(userProfiles, headers, 'age_range');
    const total = parsedData.map((elem) => elem['0-17'] + elem['18-24'] + elem['25-34'] + elem['35-44'] + elem['45-99'] + elem.none || 0);

    return headers.map((header, i) => ({
      category: formatDate(header, 'YYYY-MM-DD', 'MMM'),
      [i18n.t('dashboards.0-17')]: (parsedData[i]['0-17'] / total[i]) * 100,
      [i18n.t('dashboards.18-24')]: (parsedData[i]['18-24'] / total[i]) * 100,
      [i18n.t('dashboards.25-34')]: (parsedData[i]['25-34'] / total[i]) * 100,
      [i18n.t('dashboards.35-44')]: (parsedData[i]['35-44'] / total[i]) * 100,
      [i18n.t('dashboards.45-99')]: (parsedData[i]['45-99'] / total[i]) * 100,
      [i18n.t('dashboards.none')]: (parsedData[i].none / total[i]) * 100,
    })) || [];
  }

  @computed('userProfiles.year', 'headers')
  get catchmentAreaChart() {
    const {
      userProfiles: {
        year: userProfiles,
      },
      headers,
    } = this;

    if (!userProfiles.length) {
      return null;
    }

    const parsedData = this.parseData(userProfiles, headers, 'influence_zone');
    const total = parsedData.map((elem) => elem['1'] + elem['2'] + elem['3'] + elem['4']);

    return headers.map((header, i) => ({
      category: formatDate(header, 'YYYY-MM-DD', 'MMM'),
      [i18n.t('dashboards.zone1')]: (parsedData[i]['1'] / total[i]) * 100,
      [i18n.t('dashboards.zone2')]: (parsedData[i]['2'] / total[i]) * 100,
      [i18n.t('dashboards.zone3')]: (parsedData[i]['3'] / total[i]) * 100,
      [i18n.t('dashboards.zone4')]: (parsedData[i]['4'] / total[i]) * 100,
    }));
  }

  @computed('visits.month')
  get dwellTime() {
    return {
      value: this.getTotal(this.visits.month, this.month, 'average'),
      prevValue: this.getTotal(this.visits.month, this.prevMonth, 'average'),
      average: this.getAverage(this.visits.month, this.month, 'average'),
    } || 0;
  }

  @computed('visits.year', 'competitionVisits.year', 'headers')
  get dwellChart() {
    const {
      visits: {
        year: visits,
      },
      headers,
      competitionVisits: {
        year: competitionVisits,
      },
    } = this;

    if (!visits.length || !Object.entries(competitionVisits).length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.dwell-time'), ...this.parseData(visits, headers, 'average')],
      ...Object.entries(competitionVisits)
        .map(([key, center]) => [key, ...this.parseData(center, headers, 'average')]),
    ];
  }

  @computed('userProfiles.year', 'headers')
  get familyChart() {
    const {
      userProfiles: {
        year: userProfiles,
      },
      headers,
    } = this;

    if (!userProfiles.length) {
      return null;
    }

    const parsedData = this.parseData(userProfiles, headers, 'marital_status');
    const total = parsedData.map((elem) => elem.F + elem.P + elem.S + elem.none);

    return headers.map((header, i) => ({
      category: formatDate(header, 'YYYY-MM-DD', 'MMM'),
      [i18n.t('dashboards.with-family')]: (parsedData[i].F / total[i]) * 100,
      [i18n.t('dashboards.with-partner')]: (parsedData[i].P / total[i]) * 100,
      [i18n.t('dashboards.single')]: (parsedData[i].S / total[i]) * 100,
      [i18n.t('dashboards.none')]: (parsedData[i].none / total[i]) * 100,
    }));
  }

  @computed('userProfiles.year', 'headers')
  get genderChart() {
    const {
      userProfiles: {
        year: userProfiles,
      },
      headers,
    } = this;

    if (!userProfiles.length) {
      return null;
    }

    const parsedData = this.parseData(userProfiles, headers, 'gender');
    const total = parsedData.map((elem) => elem.H + elem.M + elem.O + elem.none);

    return headers.map((header, i) => ({
      category: formatDate(header, 'YYYY-MM-DD', 'MMM'),
      [i18n.t('dashboards.male')]: (parsedData[i].H / total[i]) * 100,
      [i18n.t('dashboards.female')]: (parsedData[i].M / total[i]) * 100,
      [i18n.t('dashboards.other')]: (parsedData[i].O / total[i]) * 100,
      [i18n.t('dashboards.none')]: (parsedData[i].none / total[i]) * 100,
    }));
  }

  @computed('year')
  get headers() {
    return this.year.months;
  }

  @computed('visits.month')
  get recurrence() {
    return {
      value: this.getTotal(this.visits.month, this.month, 'recurrence'),
      prevValue: this.getTotal(this.visits.month, this.prevMonth, 'recurrence'),
      average: this.getAverage(this.visits.month, this.month, 'recurrence'),
    };
  }

  @computed('visits.year', 'competitionVisits.year', 'headers')
  get recurrenceChart() {
    const {
      visits: {
        year: visits,
      },
      headers,
      competitionVisits: {
        year: competitionVisits,
      },
    } = this;

    if (!visits.length || !Object.entries(competitionVisits).length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.recurrence'), ...this.parseData(visits, headers, 'recurrence')], // sort by month
      ...Object.entries(competitionVisits)
        .map(([key, center]) => [key, ...this.parseData(center, headers, 'recurrence')]),
    ];
  }

  @computed('registeredUsers.year', 'headers')
  get registrationRateChart() {
    const {
      headers,
      registeredUsers: {
        year: registeredUsers,
      },
    } = this;

    if (!registeredUsers.length) {
      return null;
    }

    const headersMap = headers.map((month) => {
      const current = registeredUsers.find((data) => data.month === month);

      return (current)
        ? current.new_registered_users / current.total_registered_users
        : 0;
    });

    return [
      ['x', ...headers],
      [
        i18n.t('dashboards.registration-rate'),
        ...headersMap,
      ],
    ];
  }

  @computed('registeredUsers.year', 'headers')
  get registeredTotalUsersChart() {
    const {
      headers,
      registeredUsers: {
        year: registeredUsers,
      },
    } = this;

    if (!registeredUsers.length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.total-registered-users'), ...this.parseData(registeredUsers, headers, 'total_registered_users')], // sort by month
      [i18n.t('dashboards.total-app-users'), ...this.parseData(registeredUsers, headers, 'total_app_users')], // sort by month
    ];
  }

  @computed('registeredUsers.year', 'headers')
  get registeredUsersChart() {
    const {
      headers,
      registeredUsers: {
        year: registeredUsers,
      },
    } = this;

    if (!registeredUsers.length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.new-registered-users'), ...this.parseData(registeredUsers, headers, 'new_registered_users')], // sort by month
      [i18n.t('dashboards.new-app-users'), ...this.parseData(registeredUsers, headers, 'new_app_users')], // sort by month
    ];
  }

  @computed('registeredUsers.month')
  get registeredAppUsers() {
    return {
      value: this.getTotal(this.registeredUsers.month, this.month, 'new_app_users'),
      prevValue: this.getTotal(this.registeredUsers.month, this.prevMonth, 'new_app_users'),
      average: this.getAverage(this.registeredUsers.month, this.month, 'new_app_users'),
    };
  }

  @computed('registeredUsers.month')
  get registeredTotalUsers() {
    return {
      value: this.getTotal(this.registeredUsers.month, this.month, 'new_registered_users'),
      prevValue: this.getTotal(this.registeredUsers.month, this.prevMonth, 'new_registered_users'),
      average: this.getAverage(this.registeredUsers.month, this.month, 'new_registered_users'),
    };
  }

  @computed('visits.month')
  get visitsData() {
    return {
      value: this.getTotal(this.visits.month, this.month, 'visits'),
      prevValue: this.getTotal(this.visits.month, this.prevMonth, 'visits'),
      average: this.getAverage(this.visits.month, this.month, 'visits'),
    };
  }

  @computed('visits.year', 'competitionVisits.year', 'headers')
  get visitsCompetitionChart() {
    const {
      visits: {
        year: visits,
      },
      headers,
      competitionVisits: {
        year: competitionVisits,
      },
    } = this;

    if (!visits.length || !Object.entries(competitionVisits).length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('dashboards.visits'), ...this.parseData(visits, headers, 'visits')], // sort by month
      ...Object.entries(competitionVisits)
        .map(([key, center]) => [key, ...this.parseData(center, headers, 'visits')]),
    ];
  }

  didReceiveAttrs() {
    const prevMonth = new Month();

    prevMonth.addMonths(-1);

    setProperties(
      this,
      {
        month: new Month(),
        prevMonth,
        year: new Year(),
      },
    );

    this.fetchData();
  }

  @restartableTask
  fetchActiveUsersMonth = function* () {
    const { stats } = this;
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      group_by: [
        'center_id',
        'month',
      ],
    });

    const data = yield stats.getData(
      'statsactiva/user_activity',
      getQuery(this.prevMonth.startDatetime, this.month.endDatetime),
    );

    set(this, 'activeUsers.month', data.sort((elem, next) => (elem.date > next.date ? 1 : -1)));
  }

  @restartableTask
  fetchActiveUsersYear = function* () {
    const centerId = get(this, 'auth.centerId');
    const { stats } = this;
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(centerId)],
      group_by: ['month'],
    });

    const data = yield stats.getData(
      'statsactiva/user_activity',
      getQuery(this.year.startDatetime, this.year.endDatetime),
    );

    set(this, 'activeUsers.year', data.sort((elem, next) => (elem.date > next.date ? 1 : -1)));
  }

  @restartableTask
  fetchRegisteredUsersYear = function* () {
    const centerId = get(this, 'auth.centerId');
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(centerId)],
      group_by: ['month'],
    });
    const query = getQuery(this.year.startDatetime, this.year.endDatetime);

    const data = yield fetch(`${get(this, 'api.host')}/api/v4/stats/registered_users?${$.param(query)}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${get(this, 'auth.token')}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

    set(this, 'registeredUsers.year', data.sort((elem, next) => (elem.date > next.date ? 1 : -1)));
  }

  @restartableTask
  fetchRegisteredUsersMonth = function* () {
    const getQuery = (startDate, endDate) => ({
      end_date: endDate,
      group_by: [
        'center_id',
        'month',
      ],
      start_date: startDate,
    });
    const query = getQuery(this.prevMonth.startDatetime, this.month.endDatetime);
    const data = yield fetch(
      `${get(this, 'api.host')}/api/v4/stats/registered_users?${$.param(query)}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${get(this, 'auth.token')}`,
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json());
    set(this, 'registeredUsers.month', data);
  }

  @restartableTask
  fetchUserProfilesYear = function* () {
    const centerId = get(this, 'auth.centerId');
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(centerId)],
      group_by: ['month'],
    });
    const query = getQuery(this.year.startDatetime, this.year.endDatetime);

    const data = yield fetch(`${get(this, 'api.host')}/api/v4/stats/user_profiles?${$.param(query)}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${get(this, 'auth.token')}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

    set(this, 'userProfiles.year', data.sort((elem, next) => (elem.date > next.date ? 1 : -1)));
  }

  @restartableTask
  fetchVisitsMonth = function* () {
    const { stats } = this;
    const getQuery = (startDate, endDate) => ({
      end_date: endDate,
      group_by: [
        'center_id',
        'month',
        'building_type',
      ],
      start_date: startDate,
    });

    const data = yield stats.getData(
      'statsactiva/visits',
      getQuery(this.prevMonth.startDatetime, this.month.endDatetime),
    );

    set(this, 'visits.month', data.filter(((elem) => 'main' === elem.building_type)).sort((elem, next) => (elem.date > next.date ? 1 : -1)));
  }

  @restartableTask
  fetchVisitsYear = function* () {
    const centerId = get(this, 'auth.centerId');
    const { stats } = this;
    const getQuery = (startDate, endDate) => ({
      start_date: startDate,
      end_date: endDate,
      center_id: [Number(centerId)],
      group_by: ['month', 'building_id'],
    });

    const data = yield stats.getData(
      'statsactiva/visits',
      getQuery(this.year.startDatetime, this.year.endDatetime),
    );

    set(this, 'visits.year', data.filter(((elem) => 'main' === elem.building_type)).sort((elem, next) => (elem.date > next.date ? 1 : -1)));
    set(this, 'competitionVisits.year', this.groupByBuilding(data.filter(((elem) => 'competition' === elem.building_type)).sort((elem, next) => (elem.date > next.date ? 1 : -1))));
  }

  @action
  changeFilters(selectedMonth) {
    const prevMonth = new Month(new Date(selectedMonth.startDate));
    prevMonth.addMonths(-1);
    setProperties(
      this,
      {
        month: selectedMonth,
        prevMonth,
        year: new Year(moment(selectedMonth, 'YYYY').toDate()),
      },
    );
    this.fetchData();
  }

  fetchData() {
    this.fetchActiveUsersMonth.perform();
    this.fetchActiveUsersYear.perform();
    this.fetchRegisteredUsersMonth.perform();
    this.fetchRegisteredUsersYear.perform();
    this.fetchVisitsMonth.perform();
    this.fetchVisitsYear.perform();
    this.fetchUserProfilesYear.perform();
  }

  getAverage(data, month, attr) {
    const filteredData = data
      .filter((elem) => (1 !== elem.center_id) && (month.startDate === (elem.month || elem.date)));
    const total = filteredData
      .reduce((accumulator, currentValue) => accumulator + currentValue[attr], 0);
    const average = Math.round(total / filteredData.length);

    return Number.isNaN(average)
      ? '0'
      : average;
  }

  getTotal(data, month, attr) {
    const monthData = data.find((elem) => (Number(get(this, 'auth.centerId')) === elem.center_id)
      && (month.startDate === (elem.month || elem.date)));

    return !monthData
      ? null
      : monthData[attr];
  }

  groupByBuilding(data) {
    return data.reduce((acc, obj) => {
      const center = obj.building_name;
      if (!acc[center]) {
        acc[center] = [];
      }
      acc[center].push(obj);
      return acc;
    }, {});
  }

  parseData(data, tick, attr) {
    return tick.map((month) => data
      .filter((elem) => month === (elem.month || elem.date))
      .reduce((sum, elem) => ('object' !== typeof elem[attr] ? sum + elem[attr] : elem[attr]), 0));
  }
}
