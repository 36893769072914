import { get, computed } from '@ember/object';

import ModuleResidential from './module-residential';

export default class extends ModuleResidential {
  @computed('auth.user.level')
  get canCreate() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(level);
  }

  @computed('auth.user.level')
  get canRead() {
    const level = get(this, 'auth.user.level');

    return get(this, 'canAccess')
      && ['admin', 'manager', 'crmmanager'].includes(level);
  }

  @computed('auth.user.level', 'model')
  get canDelete() {
    const level = get(this, 'auth.user.level');
    const model = get(this, 'model');

    return get(this, 'canAccess') && model && 0 === get(model, 'residentialProposals').length
      && ['admin', 'manager', 'crmmanager'].includes(level);
  }

  @computed('canCreate')
  get canUpdate() {
    return get(this, 'canCreate');
  }

  @computed('canUpdate', 'model')
  get canBlock() {
    const canUpdate = get(this, 'canUpdate');
    const model = get(this, 'model');

    return model
      && canUpdate
      && 'free' === get(model, 'state');
  }

  @computed('canUpdate', 'model')
  get canPublish() {
    const canUpdate = get(this, 'canUpdate');
    const model = get(this, 'model');

    return model
      && canUpdate
      && 'draft' === get(model, 'visibility');
  }

  @computed('canUpdate', 'model')
  get canUnpublish() {
    const canUpdate = get(this, 'canUpdate');
    const model = get(this, 'model');

    return model
      && canUpdate
      && 'published' === get(model, 'visibility');
  }
}
