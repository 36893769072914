import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';

import { inject } from '@ember/service';

import parseFilters from 'neuro-frontend/utils/parse-filters';
import segementFiltersTypes from 'neuro-frontend/enums/segment-filters-types';

export default class extends Component.extend({
  segmentType: 'and',
}) {
  i18n = inject()

  classNames = ['segment-filters']

  defaultColumns = [
    'id',
    'email',
    'name',
    'last_name',
    'mstatus',
    'sex',
    'cp',
  ]

  segmentFiltersTypes = segementFiltersTypes

  @computed('requiredFilters', 'filters.@each.type')
  get hasValidFilters() {
    const filters = get(this, 'filters');
    const requiredFilters = get(this, 'requiredFilters');

    return !requiredFilters.length
      || requiredFilters.every((required) => filters && filters.some((filter) => get(filter, 'type.value') === get(required, 'value')));
  }

  @computed('modelName')
  get modelNameTranslated() {
    const {
      modelName,
    } = this;

    switch (modelName) {
      case 'residential-request':
        return i18n.t('residential-request');
      case 'segment':
        return i18n.t('segment');
      default:
        return '';
    }
  }

  @computed('modelFilters', 'filters.@each.type')
  get requiredFilters() {
    const {
      modelFilters,
    } = this;

    return modelFilters
      ? modelFilters.reduce(
        (prev, cur) => prev.concat(
          ...cur.options.filter((opt) => opt.required && !this._filtersIncludesType(opt.value)),
        ),
        [],
      )
      : [];
  }

  @action
  addFilter() {
    const onAddFilter = get(this, 'onAddFilter');

    if ('function' === typeof onAddFilter) {
      onAddFilter();
    }
  }

  @action
  applyFilters() {
    get(this, 'onApplyFilters')(
      {
        ...parseFilters(get(this, 'filters')),
        ...(() => (this.hasSegmentType ? { type: this.segmentType } : {}))(),
      },
      'filter',
    );
  }

  @action
  clearFilters() {
    const onClearFilters = get(this, 'onClearFilters');

    if ('function' === typeof onClearFilters) {
      onClearFilters();
    }
  }

  @action
  removeFilter(filter) {
    const onRemoveFilter = get(this, 'onRemoveFilter');

    if ('function' === typeof onRemoveFilter) {
      onRemoveFilter(filter);
    }
  }

  @action
  saveChanges() {
    const obj = {
      filters: parseFilters(get(this, 'filters')),
      segmentType: get(this, 'segmentType'),
    };

    set(this, 'isSavingChanges', true);

    get(this, 'onSaveChanges')(obj).then(() => {
      set(this, 'isSavingChanges', false);
      this.send('applyFilters');
    });
  }

  @action
  saveSegment() {
    const obj = {
      filters: parseFilters(get(this, 'filters')),
      name: get(this, 'segmentName'),
      type: get(this, 'segmentType'),
    };

    set(this, 'isSaving', true);

    get(this, 'onSaveSegment')(obj).then(() => {
      set(this, 'isSaving', false);
      set(this, 'isOpenSaveSegmentModal', false);
      set(this, 'segmentName', '');
      set(this, 'segmentType', 'and');
      this.send('applyFilters');
    });
  }

  _filtersIncludesType(type) {
    return this.filters?.some((filter) => filter?.type?.value === type);
  }
}
