import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

const salesYearStates = ['pending', 'rejected', 'validated'];

export default Factory.extend({
  january: faker.random.number,

  february: faker.random.number,

  march: faker.random.number,

  april: faker.random.number,

  may: faker.random.number,

  june: faker.random.number,

  july: faker.random.number,

  august: faker.random.number,

  september: faker.random.number,

  october: faker.random.number,

  november: faker.random.number,

  december: faker.random.number,

  state: salesYearStates[Math.floor(Math.random() * salesYearStates.length)],

  year: '2019',

  commerce: association(),
});
