import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

export default class extends Component {
  @computed('filterType.modelLabel')
  get modelLabel() {
    const modelLabel = get(this, 'filterType.modelLabel');

    return modelLabel || 'label';
  }

  @computed('filterType.modelValue')
  get modelValue() {
    const modelValue = get(this, 'filterType.modelValue');

    return modelValue || 'value';
  }

  // adapt nf-select to filter system:
  // - nf-select: handles an object as a value
  // - filter/select: handles an id as a value
  @computed('model', 'value')
  get selectedValue() {
    const {
      model,
      value,
    } = this;

    if (!model) {
      return null;
    }

    return model.findBy(this.modelValue, value)
      || model.findBy(this.modelValue, String(value));
  }

  set selectedValue(elem) {
    set(this, 'value', elem[this.modelValue]);
  }
}
