import { isArray } from '@ember/array';
import Component from '@ember/component';
import { get, set, computed } from '@ember/object';

export default class extends Component {
  classNames = ['filter']

  @computed('type.model')
  get model() {
    const model = get(this, 'type.model');

    if (!model) {
      return [];
    }

    return isArray(model)
      ? model
      : get(this, `models.${model}`);
  }

  @computed('filter.type', 'modelFilters')
  get type() {
    const type = get(this, 'filter.type');

    if ('object' !== typeof type) {
      return {};
    }

    return get(this, 'modelFilters').reduce((a, b) => a.concat(b.options), [])
      .findBy('value', type.value);
  }

  set type(value) {
    set(
      this,
      'filter.operation',
      get(value, 'operation.options.firstObject.value'),
    );

    set(
      this,
      'filter.values',
      {},
    );

    set(this, 'filter.type', value);
  }
}
