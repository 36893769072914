import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { get, computed } from '@ember/object';

@classNames('dashboard-crm__channels')
export default class extends Component.extend({
    userChannels: {
      headers: {},
      appUsers: {},
      webUsers: {},
      playCenterUsers: {},
      socialUsers: {},
      otherUsers: {},
    },

    userMail: {
      app: {},
      'play-center': {},
      'social-wifi': {},
      web: {},
      other: {},
    },

    prevMonthUserMail: {
      app: {},
      'play-center': {},
      'social-wifi': {},
      web: {},
      other: {},
    },

    prevYearUserMail: {
      app: {},
      'play-center': {},
      'social-wifi': {},
      web: {},
      other: {},
    },

  }) {
  userChannelsColors = ['#4DD0E1', '#2FA095', '#85779A', '#696E8C', '#C489A1'];

  @computed(
    'selectedMonth',
    'userChannels.appUsers',
    'userChannels.otherUsers',
    'userChannels.playCenterUsers',
    'userChannels.socialUsers',
    'userChannels.webUsers',
    'userChannels.headers',
  )
  get registersChart() {
    const {
      userChannels: {
        headers,
        appUsers,
        webUsers,
        playCenterUsers,
        socialUsers,
        otherUsers,
      },
    } = this;

    if (!headers || !headers.length) {
      return null;
    }

    return [
      ['x', ...headers],
      [i18n.t('channels.app'), ...this.setZeroEmptyData(appUsers, headers, ['day.value', 'count'])],
      [i18n.t('channels.play-center'), ...this.setZeroEmptyData(playCenterUsers, headers, ['day.value', 'count'])],
      [i18n.t('channels.web'), ...this.setZeroEmptyData(webUsers, headers, ['day.value', 'count'])],
      [i18n.t('channels.social-wifi'), ...this.setZeroEmptyData(socialUsers, headers, ['day.value', 'count'])],
      [i18n.t('channels.other'), ...this.setZeroEmptyData(otherUsers, headers, ['day.value', 'count'])],
    ];
  }

  @computed(
    'selectedMonth',
    'userMail.app',
    'userMail.play-center',
    'userMail.social-wifi',
    'userMail.web',
    'userMail.other',
  )
  get mailNotificationsPercentage() {
    const {
      userMail: {
        app,
        'play-center': playCenter,
        'social-wifi': social,
        web,
        other,
      },
    } = this;

    return {
      app: this.calculatePercentage(app, 'mailNotifications'),
      other: this.calculatePercentage(other, 'mailNotifications'),
      playCenter: this.calculatePercentage(playCenter, 'mailNotifications'),
      social: this.calculatePercentage(social, 'mailNotifications'),
      web: this.calculatePercentage(web, 'mailNotifications'),
    };
  }

  @computed(
    'selectedMonth',
    'prevMonthUserMail.app',
    'prevMonthUserMail.play-center',
    'prevMonthUserMail.social-wifi',
    'prevMonthUserMail.web',
    'prevMonthUserMail.other',
  )
  get prevMonthMailNotificationsPercentage() {
    const {
      prevMonthUserMail: {
        app,
        'play-center': playCenter,
        'social-wifi': social,
        web,
        other,
      },
    } = this;

    return {
      app: this.calculatePercentage(app, 'mailNotifications'),
      other: this.calculatePercentage(other, 'mailNotifications'),
      playCenter: this.calculatePercentage(playCenter, 'mailNotifications'),
      social: this.calculatePercentage(social, 'mailNotifications'),
      web: this.calculatePercentage(web, 'mailNotifications'),
    };
  }

  @computed(
    'selectedMonth',
    'prevYearUserMail.app',
    'prevYearUserMail.play-center',
    'prevYearUserMail.social-wifi',
    'prevYearUserMail.web',
    'prevYearUserMail.other',
  )
  get prevYearMailNotificationsPercentage() {
    const {
      prevYearUserMail: {
        app,
        'play-center': playCenter,
        'social-wifi': social,
        web,
        other,
      },
    } = this;

    return {
      app: this.calculatePercentage(app, 'mailNotifications'),
      other: this.calculatePercentage(other, 'mailNotifications'),
      playCenter: this.calculatePercentage(playCenter, 'mailNotifications'),
      social: this.calculatePercentage(social, 'mailNotifications'),
      web: this.calculatePercentage(web, 'mailNotifications'),
    };
  }

  @computed(
    'selectedMonth',
    'userMail.app',
    'userMail.play-center',
    'userMail.social-wifi',
    'userMail.web',
    'userMail.other',
  )
  get hasEmailPercentage() {
    const {
      userMail: {
        app,
        'play-center': playCenter,
        'social-wifi': social,
        web,
        other,
      },
    } = this;

    return {
      app: this.calculatePercentage(app, 'hasEmail'),
      other: this.calculatePercentage(other, 'hasEmail'),
      playCenter: this.calculatePercentage(playCenter, 'hasEmail'),
      social: this.calculatePercentage(social, 'hasEmail'),
      web: this.calculatePercentage(web, 'hasEmail'),
    };
  }

  @computed(
    'selectedMonth',
    'prevMonthUserMail.app',
    'prevMonthUserMail.play-center',
    'prevMonthUserMail.social-wifi',
    'prevMonthUserMail.web',
    'prevMonthUserMail.other',
  )
  get prevMonthHasEmailPercentage() {
    const {
      prevMonthUserMail: {
        app,
        'play-center': playCenter,
        'social-wifi': social,
        web,
        other,
      },
    } = this;

    return {
      app: this.calculatePercentage(app, 'hasEmail'),
      other: this.calculatePercentage(other, 'hasEmail'),
      playCenter: this.calculatePercentage(playCenter, 'hasEmail'),
      social: this.calculatePercentage(social, 'hasEmail'),
      web: this.calculatePercentage(web, 'hasEmail'),
    };
  }

  @computed(
    'selectedMonth',
    'prevYearUserMail.app',
    'prevYearUserMail.play-center',
    'prevYearUserMail.social-wifi',
    'prevYearUserMail.web',
    'prevYearUserMail.other',
  )
  get prevYearHasEmailPercentage() {
    const {
      prevYearUserMail: {
        app,
        'play-center': playCenter,
        'social-wifi': social,
        web,
        other,
      },
    } = this;

    return {
      app: this.calculatePercentage(app, 'hasEmail'),
      other: this.calculatePercentage(other, 'hasEmail'),
      playCenter: this.calculatePercentage(playCenter, 'hasEmail'),
      social: this.calculatePercentage(social, 'hasEmail'),
      web: this.calculatePercentage(web, 'hasEmail'),
    };
  }

  calculatePercentage(data, mailMode) {
    const channelData = get(data, mailMode);
    const {
      total,
    } = data;

    if (0 >= total) {
      return 0;
    }
    return Math.round((channelData / total) * 100, 2);
  }

  setZeroEmptyData(data, tick, attr) {
    return tick.map((e) => {
      const date = attr[0];
      const value = attr[1];
      const d = data.find((elem) => e === String(get(elem, `${date}`)));
      return d ? d[value] : 0;
    });
  }
}
