import Component from '@ember/component';
import {
  action, computed, get, set,
} from '@ember/object';

export default class extends Component.extend({
  // class properties can't be changed with overriding parameters :(
  // https://github.com/@ember-decorators/@ember-decorators/issues/123#issuecomment-315694098
  key: 'id',
}) {
  @computed('value', 'key')
  get valueId() {
    const {
      key,
      value,
    } = this;
    if ('enum' === get(this, 'type')) {
      return value || '';
    }
    return value
      ? get(value, key)
      : '';
  }

  set valueId(id) {
    if ('enum' === get(this, 'type')) {
      set(
        this,
        'value',
        id,
      );
    } else {
      set(
        this,
        'value',
        get(this, 'content').findBy(
          get(this, 'key'),
          id,
        ),
      );
    }
  }

  @action
  handleChange() {
    if ('function' === typeof this.onChange) {
      this.onChange(get(this, 'value'));
    }
  }

  @action
  onClick(e) {
    e.stopPropagation();
  }
}
