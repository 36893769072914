import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import BaseRoute from 'neuro-frontend/routes/base';

const Route = BaseRoute.extend(AuthenticatedRouteMixin, {});

export default class extends Route.extend({
  authenticationRoute: 'user.login',
}) {

}
