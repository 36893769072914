export default Object.freeze([{
  label: i18n.t('upcoming-commercialization'),
  value: 'upcoming-commercialization',
},
{
  label: i18n.t('commercialization-start'),
  value: 'commercialization-start',
},
{
  label: i18n.t('last-units'),
  value: 'last-units',
},
{
  label: i18n.t('sold'),
  value: 'sold',
},
{
  label: i18n.t('costa'),
  value: 'costa',
},
{
  label: i18n.t('available-premise'),
  value: 'available-premise',
},
]);
