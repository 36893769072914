import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  name() {
    return faker.random.word();
  },

  email() {
    return faker.random.word();
  },

  role() {
    return faker.random.word();
  },

  phone() {
    return faker.random.number();
  },

  supplier: association(),
});
