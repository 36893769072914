export default Object.freeze([
  {
    color: 'orange',
    label: i18n.t('communication.type.issues'),
    value: 'issue',
  },
  {
    color: 'teal',
    label: i18n.t('communication.type.news'),
    value: 'news',
  },
  {
    color: 'pink',
    label: i18n.t('communication.type.marketing'),
    value: 'marketing',
  },
  {
    color: 'indigo',
    label: i18n.t('communication.type.access'),
    value: 'access',
  },
]);
