import { Factory, association } from 'ember-cli-mirage';
import faker from 'faker';

const emailCampaignState = ['active', 'archived', 'scheduled', 'draft'];

export default Factory.extend({
  center: association(),

  endDatetime: faker.random.date,

  htmlBody: faker.random.word,

  name: faker.random.word,

  state: emailCampaignState[Math.floor(Math.random() * emailCampaignState.length)],

  segment: association(),

  startDatetime: faker.random.date,

  subject: faker.random.word,
});
