import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import ModuleIntranet from './module-intranet';

export default class extends ModuleIntranet {
  @service auth

  @computed('auth.center', 'auth.user.role')
  get canCreate() {
    const {
      auth: {
        user: {
          role,
        },
      },
    } = this;

    return this.canAccess
      && ['admin', 'manager', 'scmanager'].includes(role);
  }
}
