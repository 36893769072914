import Component from '@ember/component';
import { action, set, computed } from '@ember/object';

import actionTypes from 'neuro-frontend/enums/action-types';

export default class extends Component {
  actionTypes = actionTypes.filterBy('type', 'manual');

  @computed('model.errors.[]')
  get formErrors() {
    if (!this.model?.errors) return {};
    return this.model?.errors?.reduce((acc, { attribute, message }) => ({
      ...acc,
      [attribute]: [message],
    }));
  }

  @action
  handleChangeCheck({ checked }) {
    set(
      this,
      'model.doneDatetime',
      checked
        ? new Date()
        : false,
    );
  }
}
