import { get, computed } from '@ember/object';

import ModuleIntranet from './module-intranet';

export default class extends ModuleIntranet {
  roles = [
    'admin',
    'manager',
    'commerce',
    'storeManager',
    'scmanager',
  ]

  @computed('auth.user')
  get canCreateFolder() {
    const {
      auth: {
        user,
      },
    } = this;

    return ['admin', 'manager'].includes(user.level);
  }

  @computed('model', 'canEdit')
  get canDelete() {
    const {
      canEdit,
    } = this;

    return canEdit;
  }

  @computed('model')
  get canDownload() {
    const {
      model,
    } = this;

    return !model.isDir;
  }

  @computed('auth.user', 'auth.centerId', 'model')
  get canEdit() {
    const {
      auth: {
        centerId,
        user,
      },
      model,
    } = this;

    return get(model, 'center.id') === centerId
      && !model.isDir
      && (['admin', 'manager'].includes(user.level) || user.id === get(model, 'creator.id'));
  }

  @computed('auth.center', 'auth.user.role')
  get canRead() {
    return this.canAccess;
  }

  @computed('auth.user.role')
  get canSelectRole() {
    const {
      auth: {
        user: {
          role,
        },
      },
    } = this;

    return [
      'admin',
      'manager',
      'scmanager',
    ].includes(role);
  }
}
