import Component from '@ember/component';
import {
  set,
} from '@ember/object';

export default class extends Component {
  dismissible = true;

  didRender() {
    const modal = $(`#modal-${this.elementId}`);
    $('.modal').modal({
      dismissible: this.dismissible,
      inDuration: 1,
      outDuration: 1,
      complete: () => {
        if (!this.isDestroyed) {
          set(this, 'isOpened', false);
        }
      },
    });

    if (this.isOpened) {
      modal.modal('open');
    } else {
      modal.modal('close');
    }
  }

  willDestroyElement() {
    $(`#modal-${this.elementId}`).modal('close');
  }
}
