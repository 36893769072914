import {
  get, set, getProperties, action, computed,
} from '@ember/object';
import Component from '@ember/component';

import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

export default class extends Component {
  @service apiFiles

  classNameBindings = ['file::hide']

  size = {
    height: 0,
    width: 0,
  }

  @computed('size', 'top', 'left')
  get imgStyle() {
    const {
      size,
      top,
      left,
    } = getProperties(this, ['size', 'top', 'left']);
    const styles = {
      height: `${get(size, 'height')}px`,
      left: `${left}px`,
      top: `${top}px`,
      width: `${get(size, 'width')}px`,
    };

    return htmlSafe(Object.keys(styles)
      .map((selector) => `${selector}:${styles[selector]}`)
      .join(';'));
  }

  @computed('size.width', 'windowWidth')
  get left() {
    const width = get(this, 'size.width');
    const windowWidth = get(this, 'windowWidth');

    return (windowWidth - width) / 2;
  }

  @computed('windowHeight')
  get maxHeight() {
    return get(this, 'windowHeight') * 0.9;
  }

  @computed('windowWidth')
  get maxWidth() {
    return get(this, 'windowWidth') * 0.9;
  }

  @computed('windowHeight')
  get styleArrows() {
    return htmlSafe(`top: ${(get(this, 'windowHeight') / 2) - 35}px`);
  }

  @computed('size.height', 'windowHeight')
  get top() {
    const height = get(this, 'size.height');
    const windowHeight = get(this, 'windowHeight');

    return (windowHeight - height) / 2;
  }

  didReceiveAttrs() {
    super.didReceiveAttrs();

    set(this, 'size.width', 0);
    set(this, 'size.height', 0);
  }

  async didRender() {
    const file = get(this, 'file');
    let blob;

    set(this, 'windowHeight', window.innerHeight);
    set(this, 'windowWidth', window.innerWidth);

    if (file) {
      blob = await get(this, 'apiFiles').getImageBlob(get(file, 'url'));
      const {
        height,
        width,
      } = await get(this, 'apiFiles').getImageSize(blob);

      this.calculateSize(width, height);
    }
  }

  @action
  handleNext() {
    if ('function' === typeof get(this, 'onNext')) {
      get(this, 'onNext')();
    }
  }

  @action
  handlePrevious() {
    if ('function' === typeof get(this, 'onPrevious')) {
      get(this, 'onPrevious')();
    }
  }

  @action
  hideFile() {
    set(this, 'file', null);
  }

  calculateSize(width, height) {
    const widthPercent = width / get(this, 'windowWidth');
    const heightPercent = height / get(this, 'windowHeight');
    let ratio = 0;

    if (widthPercent > heightPercent) {
      ratio = height / width;
      set(this, 'size.width', get(this, 'maxWidth'));
      set(this, 'size.height', get(this, 'maxWidth') * ratio);
    } else {
      ratio = width / height;
      set(this, 'size.width', get(this, 'maxHeight') * ratio);
      set(this, 'size.height', get(this, 'maxHeight'));
    }
  }
}
