import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

import BaseRoute from 'neuro-frontend/routes/base';

export default class extends BaseRoute {
  @service pageHeader

  model() {
    const ctrl = this.controllerFor('configuration.dashboards.index');

    return hash({
      dashboards: ctrl.loadCollection(),
    });
  }

  setupController(ctrl, model) {
    super.setupController(ctrl, model);

    set(this, 'pageHeader.action.route', 'configuration.dashboards.new');
    set(this, 'pageHeader.tooltip', i18n.t('dashboards.new'));
  }
}
