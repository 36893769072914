define("neuro-frontend/components/md-default-column-header", ["exports", "ember-cli-materialize/components/md-default-column-header"], function (_exports, _mdDefaultColumnHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdDefaultColumnHeader.default;
    }
  });
});
