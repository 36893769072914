export default Object.freeze([
  {
    label: i18n.t('residential.energy_certification.a'),
    value: 'A',
  },
  {
    label: i18n.t('residential.energy_certification.b'),
    value: 'B',
  },
  {
    label: i18n.t('residential.energy_certification.c'),
    value: 'C',
  },
  {
    label: i18n.t('residential.energy_certification.d'),
    value: 'D',
  },
  {
    label: i18n.t('residential.energy_certification.e'),
    value: 'E',
  },
  {
    label: i18n.t('residential.energy_certification.f'),
    value: 'F',
  },
  {
    label: i18n.t('residential.energy_certification.g'),
    value: 'G',
  },
]);
