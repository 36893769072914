import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';
import { get } from '@ember/object';

import Filter from 'neuro-frontend/utils/filter';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('number') activeAppUsers

  @attr('number') activeEmailUsers

  @attr('json') filters

  @attr('boolean') isDeletable

  @attr('number') registeredEmailUsers

  @attr('number') registeredUsers

  @attr('trimmed-string') segmentName

  @attr('string', { defaultValue: 'and' }) segmentType

  @attr('boolean') visible

  // relationships
  @belongsTo('center') center

  getFilters() {
    if (!get(this, 'filters')) {
      return [];
    }

    return Object.entries(get(this, 'filters'))
      .reduce(
        (prev, [key, value]) => prev.concat(...value.map((filter) => new Filter({
          type: {
            value: key,
          },
          operation: filter.op || String(value[0]),
          values: Object.entries(filter).reduce(
            (values, [a, b]) => {
              if (filter.op && 'op' !== a) {
                return {
                  ...values,
                  [a]: b,
                };
              }

              return values;
            },
            {},
          ),
        }))),
        [],
      );
  }
}
