import { get } from '@ember/object';

import FilterOperation from '../filter-operation';

export default class FilterIs extends FilterOperation.extend({
  baseValues: [
    {
      label: i18n.t('value'),
      type: 'select',
      value: 'value',
    },
  ],
}) {
  options = [
    {
      label: i18n.t('is'),
      value: 'is',
    },
    {
      label: i18n.t('is_not'),
      value: 'is_not',
    },
  ]

  constructor(params) {
    super(params);

    if (get(this, 'onlyPositive')) {
      get(this, 'options').pop();
    }
  }
}
