import { get, computed } from '@ember/object';

import actionTypes from 'neuro-frontend/enums/action-types';
import TableList from './table-list';

export default class extends TableList {
  @computed
  get columns() {
    return [
      {
        cellType: 'material-icon-done',
        valuePath: 'isDone',
        width: '4%',
      },
      {
        cellType: 'enum',
        enum: actionTypes,
        label: get(this, 'i18n').t('actions.action_type'),
        valuePath: 'actionType',
        cellClassNames: ['table-list__cell--ellipsis'],
      },
      {
        cellType: 'datetime',
        label: get(this, 'i18n').t('actions.datetime'),
        valuePath: 'datetime',
      },
      {
        label: get(this, 'i18n').t('actions.title'),
        valuePath: 'title',
        cellClassNames: ['table-list__cell--ellipsis'],
      },
      {
        label: get(this, 'i18n').t('actions.notes'),
        valuePath: 'notes',
        cellClassNames: ['table-list__cell--ellipsis'],
      },
      {
        label: get(this, 'i18n').t('actions.staff'),
        valuePath: 'staff.name',
      },
      {
        label: '',
        cellAction: 'edit',
        cellType: 'material-icon',
        materialIcon: 'edit',
        tooltip: get(this, 'i18n').t('edit'),
        sortable: false,
        align: 'center',
        width: '4%',
      },
      {
        label: '',
        cellAction: 'delete',
        cellType: 'material-icon',
        materialIcon: 'delete',
        tooltip: i18n.t('delete'),
        sortable: false,
        align: 'center',
        width: '4%',
      },
    ];
  }
}
