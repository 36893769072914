import Transform from 'ember-data/transform';
import moment from 'moment';

export default Transform.extend({
  deserialize(serialized) {
    const date = moment(serialized);

    // with 'undefined' as parameter it returns current date and... that's kind
    // of not correct :/
    //
    // the same happens with an object without any information (i.e. an empty object)
    if (
      !serialized
      || 'string' !== typeof serialized
      || !date.isValid()
    ) {
      return null;
    }

    return date.toDate();
  },

  serialize(deserialized) {
    const date = moment(deserialized);

    if (
      !deserialized
      || !(deserialized instanceof Date)
      || !date.isValid()
    ) {
      return null;
    }

    return date.format('YYYY-MM-DDTHH:mm:ssZ');
  },
});
