import {
  get, set, action, computed,
} from '@ember/object';

import IndexController from './index';

export default class extends IndexController {
  @computed('model.residentialDevelopments.length', 'model.selectedResidentialDevelopments.length')
  get residentialDevelopmentsOptions() {
    const options = this.model.residentialDevelopments?.filter(
      (development) => !this.model.selectedResidentialDevelopments?.includes(development) ?? false,
    );
    return options;
  }

  @action
  create(modelName, obj, attrName) {
    set(
      this,
      attrName,
      get(
        this,
        'store',
      ).createRecord(
        modelName,
        obj,
      ),
    );
  }

  @action
  createOffer(offer = {}) {
    set(
      this,
      'newOffer',
      get(
        this,
        'store',
      ).createRecord(
        'residential-offer',
        offer,
      ),
    );
  }

  @action
  saveAction(fn) {
    const userActions = get(this, 'userActions').toArray();

    if (!userActions.includes(fn)) {
      userActions.unshift(fn);
    }

    set(this, 'userActions', userActions);
  }

  @action
  addDevelopments() {
    set(this, 'isOpenModal', true);
  }

  @action
  returnToUsersList() {
    return this.transitionToRoute('residential.users.index');
  }
}
