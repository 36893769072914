import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize(serialized) {
    return atob(serialized);
  },

  serialize(deserialized) {
    return btoa(deserialized);
  },
});
