import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import Cell from 'ember-light-table/components/cells/base';

import { isEmpty } from '@ember/utils';

export default class extends Cell {
  @alias('column.list') list

  @alias('column.percent') percent

  @alias('elem.currentValue') current

  @alias('elem') label

  @computed('value', 'list.@each.currentValue')
  get elem() {
    const kpi = get(this, 'row.content.kpi');
    const listerator = get(this, 'list');
    const percent = get(this, 'percent');

    if (Array.isArray(listerator) && !isEmpty(listerator)) {
      return percent
        ? this.getPercentValue(listerator)
        : listerator.findBy('kpi', kpi);
    }

    return '';
  }

  getPercentValue(listerator) {
    const value = get(this, 'value');

    const kpi = get(this, 'row.content.kpi');
    let currentValue = listerator.findBy('kpi', kpi)?.currentValue;

    currentValue = currentValue.toString().includes('%')
      ? currentValue.replace('%', '')
      : currentValue;

    return this.isValidParams(currentValue, value)
      ? {
        label: `${this.formatPercent((currentValue * 100) / value)} %`,
        color: this.getPercentColor((currentValue * 100) / value),
      }
      : this.getInvalidParamsResponse(currentValue, value);
  }

  getPercentColor(percent) {
    if (100 <= percent) return '#028a3d';
    if (90 < percent) return '#f8b827';
    if (90 > percent) return '#e80042';
    return '';
  }

  formatPercent(percent) {
    if (0 !== percent % 1) {
      return percent.toFixed(2);
    }
    return percent;
  }

  // Revisar esto, el NaN entra porque hay algo que falla aquí
  isValidParams(currentValue, value) {
    return Number.isFinite(currentValue)
      && Number.isFinite(value)
      && 0 !== value;
  }

  getInvalidParamsResponse(currentValue, value) {
    if (0 < currentValue && 0 === value) {
      return {
        label: '100 %',
        color: '#028a3d',
      };
    }

    return {
      label: '-- %',
    };
  }
}
