import Cell from 'ember-light-table/components/cells/base';
import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';

export default class LinkTo extends Cell {
  @alias('column.link') link

  @alias('column.target') target

  @computed('column.id', 'row')
  get id() {
    if (this.column.id) {
      return get(this.row, this.column.id) || this.column.id;
    }

    return '';
  }

  @computed('column.isDisabled')
  get isDisabled() {
    const {
      isDisabled,
    } = this.column;

    return 'function' === typeof isDisabled
      ? isDisabled(this.row.content)
      : isDisabled;
  }

  @computed('link')
  get params() {
    const params = [this.link];

    if (this.id) {
      params.push(this.id);
    }

    if (this.queryParams) {
      params.push(this.queryParams);
    }

    return params;
  }

  @computed('column.queryParams', 'row')
  get queryParams() {
    const {
      queryParams,
    } = this.column;

    if (!queryParams) {
      return null;
    }

    return {
      isQueryParams: true,
      values: 'function' === typeof queryParams
        ? queryParams(this.row.content)
        : queryParams,
    };
  }
}
