export default Object.freeze([
  {
    label: i18n.t('first-property'),
    value: 'first-property',
  },
  {
    label: i18n.t('second-property'),
    value: 'second-property',
  },
  {
    label: i18n.t('investor'),
    value: 'investor',
  },

]);
