import Model from 'ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

export default class IntranetFlowModel extends Model.extend(Validator, {}) {
  @attr('number') cars

  @attr('number') cinemas

  /* @TODO date must be a date attribute
    this mean refactor calendar month in intranet flows and intranet guards
    needs tests too
  */
  @attr('string') date

  @attr('number') footfall

  @belongsTo('center') center
}
