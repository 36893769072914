export default Object.freeze([
  {
    label: i18n.t('push-notification.single-sending'),
    value: 'single-sending',
  },
  {
    label: i18n.t('push-notification.hourly'),
    value: 'hourly',
  },
  {
    label: i18n.t('push-notification.daily'),
    value: 'daily',
  },
  {
    label: i18n.t('push-notification.weekly'),
    value: 'weekly',
  },
  {
    label: i18n.t('push-notification.on-start-visit'),
    value: 'on-start-visit',
  },
  {
    label: i18n.t('push-notification.on-end-visit'),
    value: 'on-end-visit',
  },
]);
