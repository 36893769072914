import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';

export default Route.extend({
  pageHeader: service(),

  beforeModel() {
    set(
      get(this, 'pageHeader'),
      'title',
      get(this, 'i18n').t('title.header.residential.developments'),
    );
  },
});
