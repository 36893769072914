import { pluralize } from 'ember-inflector';
import { get, set, action } from '@ember/object';
import Route from '@ember/routing/route';
import { CanMixin } from 'ember-can';
import { inject as service } from '@ember/service';

const RouteMixin = Route.extend(CanMixin, {});

export default class extends RouteMixin.extend({
  models: [],
}) {
  @service pageHeader

  beforeModel() {
    // clears nf-add data
    set(this, 'pageHeader.action', {});
  }

  setupController(ctrl, model) {
    const store = get(this, 'store');

    super.setupController(ctrl, model);

    get(this, 'models').map((modelName) => set(ctrl, `model.${pluralize(modelName)}`, store.findAll(modelName)));
  }

  @action
  willTransition() {
    // @TODO remove this action after all the routes has been migrated to ES6 class and
    // call to parent beforeModel
    set(this, 'pageHeader.action', {});
  }
}
