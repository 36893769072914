import { attr, belongsTo } from '@ember-data/model';
import { computed, get } from '@ember/object';

import Model from 'ember-data/model';
import Validator from 'ember-model-validator/mixins/model-validator';

export default class extends Model.extend(Validator, {}) {
  @attr('string') name

  // Relationships
  @belongsTo('center') center

  @computed()
  get validations() {
    return {
      name: {
        presence: {
          message: get(this, 'i18n').t('required', { attr: i18n.t('name') }),
        },
      },
    };
  }
}
