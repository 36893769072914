import { Factory, association } from 'ember-cli-mirage';
import faker from 'faker';
import moment from 'moment';

export default Factory.extend({
  amount() {
    return faker.random.number();
  },

  date() {
    return moment(faker.date.recent()).format('YYYY-MM-DD');
  },

  commerce: association(),
});
