import Component from '@ember/component';
import {
  action, computed, set, get,
} from '@ember/object';

export default class extends Component {
  classNames = ['form-residential-documentation-sent']

  didReceiveAttrs() {
    this._super();
    set(this, 'isSigned', !!get(this, 'model.deedDatetime'));
  }

  @computed(
    'model',
    'model.bookingDocumentDatetime',
    'model.bookingPaymentDatetime',
    'model.tradingDatetime',
    'model.monthlyPaymentSystemDatetime',
    'model.kycDatetime',
    'model.salaryDatetime',
    'model.vatNumberDatetime',
    'isSigned',
  )
  get canSignDeed() {
    const model = get(this, 'model');

    return !get(this, 'isSigned')
      && get(model, 'bookingDocumentDatetime')
      && get(model, 'bookingPaymentDatetime')
      && get(model, 'tradingDatetime')
      && get(model, 'monthlyPaymentSystemDatetime')
      && get(model, 'kycDatetime')
      && get(model, 'salaryDatetime')
      && get(model, 'vatNumberDatetime');
  }

  setDatetime(checked, field) {
    if (!checked) {
      set(this, `model.${field}`, null);
      set(this, 'model.deedDatetime', null);
    } else {
      set(this, `model.${field}`, new Date());
    }
  }

  @action
  handleBookingDocumentDatetime({ checked }) {
    this.setDatetime(checked, 'bookingDocumentDatetime');
  }

  @action
  handleBookingPaymentDatetime({ checked }) {
    this.setDatetime(checked, 'bookingPaymentDatetime');
  }

  @action
  handleTradingDatetime({ checked }) {
    this.setDatetime(checked, 'tradingDatetime');
  }

  @action
  handleMonthlyPaymentSystemDatetime({ checked }) {
    this.setDatetime(checked, 'monthlyPaymentSystemDatetime');
  }

  @action
  handleDeedDatetime({ checked }) {
    this.setDatetime(checked, 'deedDatetime');
  }

  @action
  handleKycDatetime({ checked }) {
    this.setDatetime(checked, 'kycDatetime');
  }

  @action
  handleSalaryDatetime({ checked }) {
    this.setDatetime(checked, 'salaryDatetime');
  }

  @action
  handleVatNumberDatetime({ checked }) {
    this.setDatetime(checked, 'vatNumberDatetime');
  }
}
