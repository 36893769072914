import { Factory, association } from 'ember-cli-mirage';

import faker from 'faker';

export default Factory.extend({
  center: association(),

  local: faker.random.boolean,

  name() {
    return faker.random.word();
  },

  afterCreate(_supplier, server) {
    const supplier = _supplier;
    supplier.services = server.createList('service', 2);

    supplier.save();
  },
});
