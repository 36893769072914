import { get, set } from '@ember/object';
import { later } from '@ember/runloop';
import RSVP from 'rsvp';

import FormRoute from '../form';

export default FormRoute.extend({
  rules: { only: ['passOld', 'pass', 'passConfirmation'] },

  model() {
    const store = get(this, 'store');

    return RSVP.hash({
      staff: store.findRecord(
        'staff-member',
        get(this, 'auth.user.id'),
      ),
    });
  },

  didSaveModel() {
    set(this.controller, 'state', 'success');
    set(
      this.controller,
      'successMessage',
      get(this, 'i18n').t('informative-message.change-password.success.message'),
    );
    later(() => {
      this.transitionTo('user.login');
    }, 2000);
  },

  afterValidate(model, returnRoute) {
    return this._super(model, returnRoute).catch(() => {
      set(this.controller, 'state', 'error');
      set(this.controller, 'errorMessage', get(this, 'errorMessage'));
    });
  },
});
