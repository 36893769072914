export function recalculateSize(img = {}, width = 0, height = 0) {
  const ratio = Math.max(img.width / width, img.height / height);
  let adjWidth = img.width || 0;
  let adjHeight = img.height || 0;
  if (1 < ratio) {
    adjWidth /= ratio;
    adjHeight /= ratio;
  }
  return [adjWidth, adjHeight];
}

export default function resizeImage(image, width, height) {
  return new Promise((resolve) => {
    const img = new Image();
    const formattedImg = document.createElement('canvas');
    formattedImg.width = width;
    formattedImg.height = height;
    const ctx = formattedImg.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    img.onload = (() => {
      const [adjWidth, adjHeight] = recalculateSize(img, width, height);
      const xPos = (width - adjWidth) / 2;
      const yPos = (height - adjHeight) / 2;
      ctx.drawImage(img, xPos, yPos, adjWidth, adjHeight);
      const url = ctx.canvas.toDataURL('image/png');
      resolve(url);
    });

    img.src = URL.createObjectURL(image);
  });
}
