import { get, computed } from '@ember/object';
import Model from 'ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';

import { inject as service } from '@ember/service';
import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @service can

  @service auth

  @attr('string') address

  @attr('boolean') appNotifications

  @attr('date') bdate

  @attr('number') childrenNumber

  @attr('string') city

  @attr('string') code

  @attr('string') company

  @attr('string') cp

  @attr('datetime') createdAt

  @attr('string') dni

  @attr('string') documentType

  @attr('string') email

  @attr('boolean') eula

  @attr('boolean', { defaultValue: true }) hasEmail

  @attr('string') jobTitle

  @attr('string') lastName

  @attr('boolean') mailNotifications

  @attr('boolean') mailPartnersNotifications

  @attr('string') mstatus

  @attr('string') name

  @attr('string') notes

  @attr('string', { defaultValue() { return 'natural'; } }) personType

  @attr('string', { defaultValue() { return 'first-property'; } }) purchaserType

  @attr('string') phone

  @attr('string') residentialState

  @attr('string') salesman

  @attr('string') secondaryPhone

  @attr('string') sex

  @attr('string') signatureUrl

  @attr('datetime') validDatetime

  @attr('json', { defaultValue() { return {}; } }) extraFields

  // relationships
  @belongsTo('building') building

  @belongsTo('center') center

  @belongsTo('commerce') commerce

  @belongsTo('action', { inverse: null }) lastAction

  // @belongsTo('country') nationality

  @belongsTo('province') province

  @belongsTo('country') country

  @belongsTo('staffMember') staff

  @hasMany('action') actions

  @hasMany('campaign') campaigns

  @hasMany('channel') channels

  @hasMany('segment') segments

  @hasMany('userType') userTypes

  // computed
  @computed('name', 'lastName')
  get fullName() {
    return `${get(this, 'name')} ${get(this, 'lastName') || ''}`;
  }

  @computed('eula', 'mailNotifications', 'mailPartnersNotifications')
  get hasNotifications() {
    const {
      eula,
      mailNotifications,
      mailPartnersNotifications,
    } = this;

    return eula && mailNotifications && mailPartnersNotifications;
  }

  @computed('lastAction.datetime')
  get lastActionDate() {
    const datetime = get(this, 'lastAction.datetime');

    return datetime || undefined;
  }

  @computed()
  get validations() {
    const extraRules = this.can.can('access to module-residential')
      ? {
        personType: {
          presence: {
            message: i18n.t('error.user.personType.required'),
          },
        },

        lastName: {
          presence: {
            message: i18n.t('error.user.lastName.required'),
          },
        },

        phone: {
          presence: {
            message: i18n.t('error.user.phone.required'),
          },
        },
      }
      : {};

    const validations = {
      createdAt: {
        presence: {
          message: i18n.t('error.user.registration-date.required'),
        },
      },

      email: {
        presence: {
          if: (key, value, _this) => get(_this, 'hasEmail'),
          message: i18n.t('error.user.email.required'),
        },
      },

      name: {
        presence: {
          message: i18n.t('error.user.name.required'),
        },
      },

      cp: {
        presence: {
          message: i18n.t('error.user.cp.required'),
        },
      },

      // channels: {
      //   presence: {
      //     message: i18n.t('error.channels.required'),
      //   },
      // },

      eula: {
        custom: {
          validation(key, value, model) {
            return get(model, 'eula');
          },
          message: i18n.t('error.user.gdpr'),
        },
      },

      ...extraRules,
    };

    const userRequired = get(this, 'auth.center.userRequired');
    const translations = { commerce: i18n.t('commerce') };

    if (userRequired.length) {
      userRequired.forEach((req) => {
        if (undefined === validations[req]) {
          validations[req] = {
            presence: {
              message: i18n.t('required', { attr: translations[`${req}`] }),
            },
          };
        }
      });
    }

    return validations;
  }
}
