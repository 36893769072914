export default Object.freeze([
  {
    label: i18n.t('campaign.status.active'),
    value: 'active',
    color: '#028a3d',
  },
  {
    label: i18n.t('campaign.status.closed'),
    value: 'closed',
    color: '#2477b5',
  },
  {
    label: i18n.t('campaign.status.finished'),
    value: 'finished',
    color: '#e80042',
  },
  {
    label: i18n.t('campaign.status.planned'),
    value: 'planned',
    color: '#f8b827',
  },
]);
