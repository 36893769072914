import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { htmlSafe } from '@ember/string';

export default Component.extend({

  classNames: ['email-template-preview'],

  templateHtml: null,

  _templateHtmlSafe: computed('templateHtml', function () {
    return htmlSafe(get(this, 'templateHtml'));
  }),

  actions: {
    changeLinks() {
      this.$().find('#iframe-template-preview')
        .contents()
        .find('a')
        .attr('target', '_blank');
    },
  },
});
