import { get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';

import parseErrors from 'neuro-frontend/utils/parse-errors-array-to-errors-object';
import RSVP from 'rsvp';
import { CanMixin } from 'ember-can';

import FormRoute from 'neuro-frontend/routes/form';

const Route = FormRoute.extend(CanMixin, {});

export default class extends Route {
  @service auth

  templateName = 'residential/owners/new'

  beforeModel() {
    if (!this.can('access to module-residential')) {
      this.transitionTo('index');
    }
  }

  model() {
    return RSVP.hash({
      residentialOwner: get(this, 'store').createRecord('residentialOwner', {
        center: get(this, 'auth.center'),
      }),
    });
  }

  afterValidate() {
    this.controller.toggleProperty('isOpenModal');
  }

  @action
  submitModel(record) {
    this.controller.toggleProperty('isLoading');

    return record
      .save()
      .then(() => this.didSaveModel(record, get(this, 'returnRoute')))
      .catch((errors) => {
        set(this.controller, 'errors', parseErrors(errors));
      })
      .finally(() => {
        this.controller.toggleProperty('isOpenModal');
        this.controller.toggleProperty('isLoading');
      });
  }
}
