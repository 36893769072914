import Service, { inject as service } from '@ember/service';

import { get } from '@ember/object';

import actionTypes from 'neuro-frontend/enums/action-types';
import IllegalArgumentError from 'neuro-frontend/errors/illegal-argument-error';

export default class extends Service {
  @service auth

  @service store

  create(actionType, user) {
    if ('string' !== typeof actionType) {
      throw new TypeError(`create: ${actionType} is not of type String`);
    }

    if (!actionTypes.findBy('value', actionType)) {
      throw new IllegalArgumentError(`create: ${actionType} isn't a valid actionType`);
    }

    const userAction = get(this, 'store').createRecord(
      'action',
      {
        actionType,
        doneDatetime: new Date(),
        scheduledDatetime: new Date(),
        staff: get(this, 'auth.user'),
        title: get(actionTypes.findBy('value', actionType), 'defaultTitle'),
        user,
      },
    );

    return userAction.save();
  }
}
