import Model from 'ember-data/model';
import { computed, get } from '@ember/object';
import { attr, belongsTo, hasMany } from '@ember-data/model';

import Validator from 'ember-model-validator/mixins/model-validator';

const MixinModel = Model.extend(Validator, {});

export default class extends MixinModel {
  @attr('number') usageLimit

  @attr('string') text

  @attr('number') exchanges

  @attr('boolean') enabled

  // Relationships
  @belongsTo('proposal') proposal

  @hasMany('commerce') commerces

  @computed()
  get validations() {
    return {
      usageLimit: {
        presence: {
          if: () => get(this, 'enabled'),
          message: i18n.t('error.coupon.usageLimit.required'),
        },
      },
      text: {
        presence: {
          if: () => get(this, 'enabled'),
          message: i18n.t('error.coupon.text.required'),
        },
      },
      commerces: {
        presence: {
          if: () => 'center' === get(this.proposal, 'proposalType') && get(this, 'enabled'),
          message: i18n.t('error.coupon.commerces.required'),
        },
      },
    };
  }
}
