import Component from '@ember/component';
import { computed } from '@ember/object';
import communicationStates from '../utils/communication-states';

export default Component.extend({
  classNames: ['communication-state'],

  states: communicationStates(),

  communicationState: computed('value', function () {
    return this.get('states').findBy('value', this.get('value'));
  }),
});
