import Component from '@ember/component';
import {
  action, computed, set, get,
} from '@ember/object';

// Based on file-gallery component
export default class extends Component {
  classNames = ['video-gallery']

  tempValue = null

  @computed('value')
  get embeddedUrl() {
    const {
      value: url,
    } = this;

    if (!url) {
      return false;
    }

    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && 11 === match[7].length) ? `https://www.youtube.com/embed/${match[7]}` : false;
  }

  @action
  openModal() {
    set(this, 'tempValue', get(this, 'value'));
    set(
      this,
      'modalIsOpen',
      true,
    );
  }

  @action
  closeModal(accepting) {
    if (!accepting) {
      set(this, 'value', get(this, 'tempValue'));
    }
    set(
      this,
      'modalIsOpen',
      false,
    );
  }

  @action
  delete() {
    set(this, 'value', '');
  }
}
