import Component from '@ember/component';
import {
  set, get, action, computed,
} from '@ember/object';

export default class extends Component {
  isLoading = false

  @computed('commerces')
  get commercesWithErrors() {
    const commerces = get(this, 'commerces');

    if (!Array.isArray(commerces)) {
      return [];
    }

    return commerces
      .filter((commerce) => !get(commerce, 'tenantCode') || !get(commerce, 'shopProperty.id'))
      .map((commerce) => (
        {
          commerce,
          errors: {
            shopProperty: !get(commerce, 'shopProperty.id'),
            tenantCode: !get(commerce, 'tenantCode'),
          },
        }
      ));
  }

  @action
  closeModal() {
    set(this, 'isOpen', false);
  }

  @action
  async exportYardi() {
    set(this, 'isLoading', true);

    await this.onConfirm();

    set(this, 'isLoading', false);
    this.closeModal();
  }
}
