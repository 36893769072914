import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default class extends Component {
  classNames = ['nf-tabs__button']

  classNameBindings = ['isActive:nf-tabs__button--is_active']

  tagName = 'li'

  @computed('selection', 'tab')
  get isActive() {
    const selection = get(this, 'selection');
    const tab = get(this, 'tab');

    return selection
      && tab
      && selection.name === tab.name;
  }

  click() {
    this.sendAction('onClick', get(this, 'tab'));
  }

  didRender() {
    this.$('.tooltipped').tooltip();
  }
}
