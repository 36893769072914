import Component from '@ember/component';
import { set, action } from '@ember/object';

import { classNames } from '@ember-decorators/component';
import WeekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';

@classNames('nf-calendar', 'input-field')
export default class NfCalendarComponent extends Component.extend({
    dateFormat: 'd/m/Y',
    plugins: [],
    value: null,
  }) {
  locale = {
    firstDayOfWeek: 1,
  }

  didReceiveAttrs() {
    if ('week' === this.type) {
      set(this, 'plugins', [new WeekSelect()]);
    }
  }

  didRender() {
    window.dispatchEvent(new Event('resize'));
  }

  @action
  changeDate(date, dateStr, instance) {
    if ('week' === this.type) {
      set(
        this,
        'value',
        [
          instance.weekStartDay,
          instance.weekEndDay,
        ],
      );
    } else {
      set(this, 'value', date);
    }

    if (this.onChange) {
      this.onChange(this.value[0], this.value[1]);
    }
  }
}
