export default function dashboardCategories() {
  return [
    {
      label: i18n.t('dashboard.category.traffic'),
      value: 'traffic',
    },
    {
      label: i18n.t('dashboard.category.general'),
      value: 'general',
    },
    {
      label: i18n.t('dashboard.category.home'),
      value: 'home',
    },
  ];
}
