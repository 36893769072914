import Component from '@ember/component';
import { computed } from '@ember/object';
import IllegalArgumentError from '../errors/illegal-argument-error';

export default Component.extend({

  sizeStr: computed('value', function () {
    const value = this.get('value');
    if ('undefined' === typeof value || null === value) return '';

    if ('number' !== typeof value) {
      throw new IllegalArgumentError(`The value '${this.get('value')}' is not a valid number.`);
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Number(Math.floor(Math.log(value) / Math.log(1024)));
    const retSize = Math.round(value / (1024 ** i), 2);

    if (0 === value) return '0 Byte';
    return `${retSize} ${sizes[i]}`;
  }),
});
