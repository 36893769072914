import Service from 'ember-cli-data-export/services/csv';
import PapaParse from 'papaparse';

export default class CsvService extends Service {
  parseCSV(file, config = {}) {
    return new Promise((resolve, reject) => PapaParse.parse(
      file,
      {
        complete(csv) {
          resolve(csv);
        },

        error(errors) {
          reject(errors);
        },
        ...config,
      },
    ));
  }
}
