import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { Ability } from 'ember-can';

export function canAccess(auth, roles, moduleName) {
  return auth.center
  && auth.user.role
  && get(auth.center, `has${moduleName.classify()}`)
  && (!roles.length || roles.includes(auth.user.role));
}
export default class extends Ability {
  @service auth

  moduleName = ''

  roles = []

  @computed('auth.center', 'auth.user.role')
  get canAccess() {
    const auth = get(this, 'auth');
    const roles = get(this, 'roles');
    const moduleName = get(this, 'moduleName');

    return canAccess(auth, roles, moduleName);
  }
}
