import {
  action, computed, set, get, setProperties,
} from '@ember/object';
import Controller from '@ember/controller';

import ListMixin from 'neuro-frontend/mixins/nf-list';
import { inject as service } from '@ember/service';
import { classify } from '@ember/string';

import emailCampaignFilters from 'neuro-frontend/enums/emailing-campaign-filters';
import SegmentFiltersMixin from 'neuro-frontend/mixins/segment-filters';
import campaignStatus from 'neuro-frontend/enums/emailing-campaign-status';
import Day from 'neuro-frontend/utils/day';

export default class extends Controller.extend(ListMixin, SegmentFiltersMixin, {}) {
  @service auth

  duplicating = false

  stopping = false

  editRoute = 'emailing.campaigns.edit'

  modelFilters = emailCampaignFilters

  modelName = 'emailCampaign'

  selectedCampaign = {}

  sort = '-updated-at'

  @computed
  get columns() {
    return [
      {
        label: i18n.t('email-campaign.name'),
        valuePath: 'name',
        sortable: true,
      },
      {
        label: i18n.t('email-campaign.subject'),
        valuePath: 'subject',
        sortable: true,
      },
      {
        label: i18n.t('email-campaign.segment.segmentName'),
        valuePath: 'segment.segmentName',
        sortable: false,
      },
      {
        label: i18n.t('email-campaign.sentDatetime'),
        valuePath: 'startDatetime',
        cellType: 'mailing-sent-datetime',
        cellClassNames: 'mailing-sent-datetime',
        width: '17%',
      },
      {
        label: i18n.t('status'),
        cellType: 'tag',
        enum: campaignStatus,
        sortable: false,
        valuePath: 'state',
      },
      {
        cellType: 'material-icon',
        materialIcon: 'done',
        tooltip: i18n.t('email-campaign.statistic.delivered'),
        valuePath: 'delivered',
        width: '5%',
        sortable: false,
      },
      {
        cellType: 'material-icon',
        materialIcon: 'done_all',
        tooltip: i18n.t('email-campaign.statistic.opened'),
        valuePath: 'opened',
        width: '5%',
        sortable: false,
      },
      {
        cellType: 'material-icon',
        materialIcon: 'touch_app',
        tooltip: i18n.t('email-campaign.statistic.clicked'),
        valuePath: 'clicked',
        width: '5%',
        sortable: false,
      },
      {
        cellType: 'material-icon',
        materialIcon: 'block',
        tooltip: i18n.t('email-campaign.statistic.blocked'),
        valuePath: 'blocked',
        width: '5%',
        sortable: false,
      },
      {
        cellType: 'material-icon',
        materialIcon: 'call_missed',
        tooltip: i18n.t('email-campaign.statistic.bounced'),
        valuePath: 'bounced',
        width: '5%',
        sortable: false,
      },
      {
        cellType: 'material-icon',
        materialIcon: 'report',
        tooltip: i18n.t('email-campaign.statistic.spam'),
        valuePath: 'spam',
        width: '5%',
        sortable: false,
      },
      {
        align: 'right',
        cellClassNames: 'qa-button-group',
        cellType: 'button-group',
        label: '',
        buttons: [{
          action: 'duplicate',
          className: 'qa-duplicate-campaign',
          icon: 'content_copy',
          tooltip: i18n.t('duplicate'),
        },
        {
          action: 'stop',
          className: 'qa-stop-campaign',
          icon: 'do_not_disturb_on',
          resource: 'email-campaign',
          tooltip: i18n.t('stop'),
        },
        {
          action: 'edit',
          className: 'qa-edit-campaign',
          icon: 'edit',
          resource: 'email-campaign',
          tooltip: i18n.t('edit'),
        },
        {
          action: 'delete',
          icon: 'delete',
          resource: 'email-campaign',
          tooltip: i18n.t('delete'),
        }],
        sortable: false,
      },
    ];
  }

  init(...args) {
    super.init(...args);

    set(
      this,
      'fixedFilters',
      {
        center: get(this, 'auth.centerId'),
      },
    );
  }

  @action
  async duplicate() {
    const errorRejection = () => {
      set(this, 'state', 'error');
      return i18n.t('emailing.campaigns.duplicate-error');
    };

    if (!this.duplicating) {
      set(this, 'duplicating', true);
      const duplicated = await get(this, 'selectedCampaign').copy({
        ignoreAttributes: ['state', 'startDatetime', 'endDatetime'],
      });

      const startDatetime = new Date((new Day().add(30, 'day')).value);

      setProperties(
        duplicated,
        {
          name: `${i18n.t('emailing.campaigns.copy-of')} ${duplicated.name}`,
          state: 'draft',
          startDatetime,
          endDatetime: duplicated.isUniqueSent
            ? startDatetime
            : new Date((new Day().add(60, 'day')).value),
        },
      );

      return duplicated
        .save()
        .then(() => this.transitionToRoute('emailing.campaigns.edit', get(duplicated, 'id')))
        .catch(() => set(this, 'errorMessage', errorRejection))
        .finally(() => {
          set(this, 'duplicating', false);
          this.toggleProperty('isOpenDuplicateModal');
        });
    }

    return Promise.resolve();
  }

  @action
  openModal(type, { content: model }) {
    set(this, 'selectedCampaign', model);
    set(this, 'error', null);
    this.toggleProperty(`isOpen${classify(type)}Modal`);
  }

  @action
  async stop() {
    const selectedCampaign = get(this, 'selectedCampaign');
    const errorRejection = () => {
      set(this, 'state', 'error');
      return i18n.t('emailing.campaigns.stop-error');
    };

    if (!this.stopping) {
      set(this, 'stopping', true);
      set(selectedCampaign, 'state', 'draft');

      return selectedCampaign
        .save()
        .then(() => this.loadCollection())
        .catch(() => set(this, 'errorMessage', errorRejection))
        .finally(() => {
          set(this, 'stopping', false);
          this.toggleProperty('isOpenStopModal');
        });
    }

    return Promise.resolve();
  }
}
