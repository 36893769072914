import Transform from 'ember-data/transform';
import { typeOf } from '@ember/utils';

export default class extends Transform {
  deserialize(serialized) {
    return ('array' === typeOf(serialized)
      ? serialized
      : []);
  }

  serialize(deserialized) {
    const type = typeOf(deserialized);
    if ('array' === type) {
      return deserialized;
    } if ('string' === type) {
      return deserialized.split(',').map((e) => e.trim());
    } return deserialized;
  }
}
