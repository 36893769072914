import Component from '@ember/component';
import { set, get } from '@ember/object';

export default Component.extend({

  classNames: ['quick-filter'],

  tagName: 'ul',

  options: [],

  actions: {
    optionClicked(option) {
      set(this, 'selectedOption', get(this, 'selectedOption') === option ? '' : option);

      if ('function' === typeof get(this, 'onChangedFilter')) {
        get(this, 'onChangedFilter')(get(this, 'selectedOption'));
      }
    },
  },
});
