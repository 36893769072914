import { computed, get } from '@ember/object';

import Table from 'ember-light-table';

import TableList from '../table-list';

export default class extends TableList {
  @computed('month', 'sales')
  get columns() {
    const month = get(this, 'month');

    return [
      {
        cellType: 'image',
        valuePath: 'imageUrl',
        width: '8%',
        sortable: false,
      },
      {
        label: get(this, 'i18n').t('name'),
        valuePath: 'name',
      },
      {
        cellType: 'month-state',
        label: get(this, 'i18n').t('state'),
        month,
        sortable: false,
      },
      {
        cellType: 'month-sales',
        label: get(this, 'i18n').t('sales'),
        month,
        sortable: false,
      },
    ];
  }

  @computed('model', 'columns')
  get table() {
    const model = get(this, 'model');
    const columns = get(this, 'columns');

    return new Table(
      columns,
      model,
      {
        enableSync: true,
      },
    );
  }
}
